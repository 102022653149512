import React, { useContext } from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import styled from 'styled-components'
import { WatchersWatchMutation } from '../__generated__/WatchersWatchMutation.graphql'
import { useFragment } from 'react-relay/hooks'
import useNiceMutation from '../mutations/useNiceMutation'
import { AvatarGroup } from '@material-ui/lab'
import { Avatar, IconButton, Paper, Divider } from '@material-ui/core'
import { Star, StarBorder } from '@material-ui/icons'
import SessionContext from '../SessionContext'
import { WatchersUnwatchMutation } from '../__generated__/WatchersUnwatchMutation.graphql'
import useLazyLoadQueryWithSubscription from '../hooks/useLazyLoadQueryWithSubscription'
import { Watchers_watches$key } from '../__generated__/Watchers_watches.graphql'
import { WatchersShipmentQuery } from '../__generated__/WatchersShipmentQuery.graphql'
import { WatchersContainerQuery } from '../__generated__/WatchersContainerQuery.graphql'

interface Props {
  entityType: string
  entitySlug: string
}

const RootPaper = styled(Paper)`
  display: flex;
  align-items: center;
`

const StyledAvatarGroup = styled(AvatarGroup)`
  & > .MuiAvatarGroup-avatar {
    height: 30px;
    width: 30px;
    font-size: 1rem;
  }
`

const Watchers: React.FC<Props> = ({ entitySlug, entityType }) => {
  const query = entityType === 'shipments' ? shipmentQuery : containerQuery
  const result = useLazyLoadQueryWithSubscription<WatchersShipmentQuery | WatchersContainerQuery>(
    query,
    {
      slug: entitySlug,
    },
    // @ts-ignore
    { subscriptionOptions: { entityType, entitySlug } }
  )

  const data = 'shipmentShow' in result ? result.shipmentShow : result.containerShow

  const watches = useFragment(fragment, data.watches as Watchers_watches$key)
  const [commitWatch] = useNiceMutation<WatchersWatchMutation>(watchMutation)
  const [commitUnwatch] = useNiceMutation<WatchersUnwatchMutation>(unwatchMutation)
  const handleWatch = () => {
    commitWatch({
      variables: {
        input: {
          entitySlug: entitySlug,
          entityType: entityType,
        },
      },
    })
  }
  const handleUnwatch = () => {
    commitUnwatch({
      variables: {
        input: {
          entitySlug: entitySlug,
          entityType: entityType,
        },
      },
    })
  }
  const { user } = useContext(SessionContext)
  const watchedByMe = watches.nodes && watches.nodes.filter((node) => node && node.contactSlug === user?.username).length > 0

  return (
    <RootPaper variant='outlined'>
      <IconButton onClick={watchedByMe ? handleUnwatch : handleWatch} size='small'>
        {watchedByMe ? <Star /> : <StarBorder />}
      </IconButton>
      <Divider orientation='vertical' />
      <StyledAvatarGroup max={2}>
        {watches.nodes?.map(
          (itemWatch) =>
            itemWatch && (
              <Avatar key={itemWatch?.contactSlug} alt={itemWatch.contactItem.title} src={itemWatch?.contactItem?.image?.url as string} />
            )
        )}
      </StyledAvatarGroup>
    </RootPaper>
  )
}

const fragment = graphql`
  fragment Watchers_watches on ItemWatchConnection {
    nodes {
      contactSlug
      contactItem {
        title
        image {
          url
        }
      }
    }
  }
`

const watchMutation = graphql`
  mutation WatchersWatchMutation($input: infoWatchItemInput!) {
    infoWatchItem(input: $input) {
      clientMutationId
    }
  }
`

const unwatchMutation = graphql`
  mutation WatchersUnwatchMutation($input: infoUnwatchItemInput!) {
    infoUnwatchItem(input: $input) {
      clientMutationId
    }
  }
`

const shipmentQuery = graphql`
  query WatchersShipmentQuery($slug: String!) {
    shipmentShow(slug: $slug) {
      watches {
        ...Watchers_watches
      }
    }
  }
`

const containerQuery = graphql`
  query WatchersContainerQuery($slug: String!) {
    containerShow(slug: $slug) {
      watches {
        ...Watchers_watches
      }
    }
  }
`

export default Watchers
