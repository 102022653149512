import { Typography, Paper, IconButton, Link as IconLink } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import DraftsIcon from '@material-ui/icons/Drafts'
import { graphql } from 'babel-plugin-relay/macro'
import React, { useState, useEffect, unstable_useTransition } from 'react'
import { useFragment } from 'react-relay/hooks'
import { Link } from 'react-router-dom'
import { useLocation, useNavigate } from 'react-router'
import styled from 'styled-components'
import { RelatedItemListContent_data$key } from '../../__generated__/RelatedItemListContent_data.graphql'
import ShowPageListItem from '../../components/ShowPageListItem'
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt'
import SendEmailDialog from '../../components/Email/SendEmailDialog'
import axios from 'axios'
import fileDownload from 'js-file-download'
import moment from 'moment'
import { getToken } from '../../api/server'
import { useSnackbar } from 'notistack'
import { parse, stringify } from 'qs'
import LoadingDots from '../../components/LoadingDots'
import Button from '../../components/Button'
import DateRangePickerFilter from '../../components/DateRangePickerFilter'

interface Props {
  data: RelatedItemListContent_data$key
  type: string
  contactSlug: string
}

const NoResultsText = styled(Typography)`
  color: ${(props) => props.theme.palette.grey[300]};
  text-align: center;
  padding: 32px;
  padding-bottom: 8px;
  font-weight: 700;
`
const ResetFiltersLink = styled(Link)`
  text-decoration: none;
  display: block;
  text-align: center;
`
const ActionLinks = styled.div`
  display: flex;
  justify-content: flex-end;
`

const RelatedItemListContent: React.FC<Props> = ({ data, type, contactSlug }) => {
  const workSpace = useLocation().pathname.split('/')[1]
  const { items, downloadToken } = useFragment(fragment, data)
  const { enqueueSnackbar } = useSnackbar()
  const [emailNewOpen, setEmailNewOpen] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)
  const location = useLocation()
  const navigate = useNavigate()
  const queryVariables = parse(location.search, { ignoreQueryPrefix: true })
  const [startTransition] = unstable_useTransition()
  const [voyageDateRange, setVoyageDateRange] = React.useState([])
  const [handoverDateRange, setHandoverDateRange] = React.useState([])
  const [dischargeDateRange, setDischargeDateRange] = React.useState([])
  const [filterType, setFilterType] = React.useState(null)
  const url = contactSlug
    ? `${process.env.REACT_APP_API_URL}/${type}/download?download_token=${downloadToken}&workspace=${workSpace}&contact=${contactSlug}`
    : `${process.env.REACT_APP_API_URL}/${type}/download?download_token=${downloadToken}&workspace=${workSpace}`

  useEffect(() => {
    const { voyageEtaStart, voyageEtaEnd, handoverDateStart, handoverDateEnd, dischargeDateStart, dischargeDateEnd } = queryVariables

    if (voyageEtaStart && voyageEtaEnd) {
      const startDate = moment(voyageEtaStart as string, 'YYYY-MM-DD').toDate()
      const endDate = moment(voyageEtaEnd as string, 'YYYY-MM-DD').toDate()
      if (startDate && endDate) {
        setVoyageDateRange([startDate, endDate])
      }
    }

    if (handoverDateStart && handoverDateEnd) {
      const startDate = moment(handoverDateStart as string, 'YYYY-MM-DD').toDate()
      const endDate = moment(handoverDateEnd as string, 'YYYY-MM-DD').toDate()
      if (startDate && endDate) {
        setHandoverDateRange([startDate, endDate])
      }
    }

    if (dischargeDateStart && dischargeDateEnd) {
      const startDate = moment(dischargeDateEnd as string, 'YYYY-MM-DD').toDate()
      const endDate = moment(dischargeDateEnd as string, 'YYYY-MM-DD').toDate()
      if (startDate && endDate) {
        setDischargeDateRange([startDate, endDate])
      }
    }
  }, [])

  useEffect(() => {
    dateFilter()
  }, [voyageDateRange, handoverDateRange, dischargeDateRange])

  const authorizeDownload = () => {
    setLoading(true)
    const filename = `${contactSlug}-${type}-${moment().format('YYYY-MM-DD-HH:mm:ss')}.xlsx`
    const token = getToken()
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(res.data, filename)
        setLoading(false)
      })
      .catch((error) => {
        error.response.data.text().then(function (text: string) {
          enqueueSnackbar(JSON.parse(text as string).errors, { variant: 'error' })
        })
        setLoading(false)
      })
  }

  const dateFilter = () => {
    const tempVars = Object.assign({}, queryVariables)
    const startKey = filterType === 'voyage' ? 'voyageEtaStart' : `${filterType}DateStart`
    const endKey = filterType === 'voyage' ? 'voyageEtaEnd' : `${filterType}DateEnd`
    let dateRange
    if (filterType == 'voyage') {
      dateRange = voyageDateRange
    } else if (filterType == 'handover') {
      dateRange = handoverDateRange
    } else if (filterType == 'discharge') {
      dateRange = dischargeDateRange
    }

    if (dateRange && Array.isArray(dateRange) && dateRange.length == 2) {
      const validateStart = moment(dateRange[0], 'YYYY-MM-DD', true).isValid()
      const validateEnd = moment(dateRange[1], 'YYYY-MM-DD', true).isValid()

      if (validateStart && validateEnd) {
        tempVars[startKey] = moment(dateRange[0]).format('YYYY-MM-DD')
        tempVars[endKey] = moment(dateRange[1]).format('YYYY-MM-DD')
      } else {
        delete tempVars[startKey]
        delete tempVars[endKey]
      }
    } else {
      delete tempVars[startKey]
      delete tempVars[endKey]
    }
    startTransition(() => {
      navigate(`?${stringify(tempVars)}`)
    })
  }

  const handleDateChange = (filterType, newDateRange) => {
    setFilterType(filterType)
    if (filterType == 'voyage') {
      setVoyageDateRange(newDateRange)
    } else if (filterType == 'handover') {
      setHandoverDateRange(newDateRange)
    } else if (filterType == 'discharge') {
      setDischargeDateRange(newDateRange)
    }
  }

  const handleClear = () => {
    delete queryVariables.voyageEtaStart
    delete queryVariables.voyageEtaEnd
    delete queryVariables.handoverDateEnd
    delete queryVariables.handoverDateStart
    delete queryVariables.dischargeDateEnd
    delete queryVariables.dischargeDateStart
    navigate(`?`)

    setVoyageDateRange([])
    setHandoverDateRange([])
    setDischargeDateRange([])
  }

  return (
    <>
      <ActionLinks>
        {Object.keys(queryVariables).length > 0 && (
          <Button
            style={{ flexShrink: 0, height: 32, marginTop: 7 }}
            variant='outlined'
            size='small'
            endIcon={<Close fontSize='small' />}
            onClick={handleClear}
          >
            Clear filters
          </Button>
        )}
        <DateRangePickerFilter filterType='voyage' handleDateChange={handleDateChange} heading='Voyage ETA' dateRange={voyageDateRange} />
        {type === 'shipments' && (
          <>
            <DateRangePickerFilter
              filterType='handover'
              handleDateChange={handleDateChange}
              heading='Handover Date'
              dateRange={handoverDateRange}
            />
            <DateRangePickerFilter
              filterType='discharge'
              handleDateChange={handleDateChange}
              heading='Discharge Date'
              dateRange={dischargeDateRange}
            />
          </>
        )}
        {(type === 'shipments' || type === 'containers') && (
          <IconButton color='primary'>
            <IconLink onClick={authorizeDownload}>{loading ? <LoadingDots variant='dark' /> : <SystemUpdateAltIcon />}</IconLink>
          </IconButton>
        )}
        {type === 'containers' && contactSlug && (
          <>
            <IconButton color='primary'>
              <IconLink onClick={() => setEmailNewOpen(true)}>
                <DraftsIcon />
              </IconLink>
            </IconButton>
            <SendEmailDialog
              open={emailNewOpen}
              onClose={() => setEmailNewOpen(false)}
              entityType='contacts'
              entitySlug={contactSlug}
              initialTemplateSlug='notify-warehouse'
            />
          </>
        )}
      </ActionLinks>
      <Paper variant='outlined'>
        {items && items.edges && items.edges.length > 0 ? (
          items.edges.map((edge) => {
            const item = edge && edge.node
            if (item) return <ShowPageListItem key={item.slug} listItem={item} />
            return <></>
          })
        ) : (
          <>
            <NoResultsText variant='h5'>No results</NoResultsText>
            <ResetFiltersLink to={`${window.location.pathname}`}>Reset Filters</ResetFiltersLink>
          </>
        )}
      </Paper>
    </>
  )
}

const fragment = graphql`
  fragment RelatedItemListContent_data on ItemList {
    items {
      edges {
        node {
          slug
          ...ShowPageListItem_listItem
        }
      }
    }
    downloadToken
  }
`

export default RelatedItemListContent
