/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type infoAutomaticPickupDocumentInput = {
    clientMutationId?: string | null;
    slug: string;
};
export type ContainerPickSentDocumentGeneratMutationVariables = {
    input: infoAutomaticPickupDocumentInput;
};
export type ContainerPickSentDocumentGeneratMutationResponse = {
    readonly infoAutomaticPickupDocument: {
        readonly clientMutationId: string | null;
    } | null;
};
export type ContainerPickSentDocumentGeneratMutation = {
    readonly response: ContainerPickSentDocumentGeneratMutationResponse;
    readonly variables: ContainerPickSentDocumentGeneratMutationVariables;
};



/*
mutation ContainerPickSentDocumentGeneratMutation(
  $input: infoAutomaticPickupDocumentInput!
) {
  infoAutomaticPickupDocument(input: $input) {
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "infoAutomaticPickupDocumentPayload",
    "kind": "LinkedField",
    "name": "infoAutomaticPickupDocument",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ContainerPickSentDocumentGeneratMutation",
    "selections": (v1/*: any*/),
    "type": "ApplicationCommand",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ContainerPickSentDocumentGeneratMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "78ec1dd606b91756f4e6e11fa0899fb4",
    "id": null,
    "metadata": {},
    "name": "ContainerPickSentDocumentGeneratMutation",
    "operationKind": "mutation",
    "text": "mutation ContainerPickSentDocumentGeneratMutation(\n  $input: infoAutomaticPickupDocumentInput!\n) {\n  infoAutomaticPickupDocument(input: $input) {\n    clientMutationId\n  }\n}\n"
  }
};
})();
(node as any).hash = 'decb372bf58e7da400a698e98606591e';
export default node;
