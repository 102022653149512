import { Pagination as MuiPagination, PaginationProps } from '@material-ui/lab'
import React from 'react'
import styled from 'styled-components'

interface Props {
  size?: PaginationProps['size']
  isFirst: boolean
  isLast: boolean
  pageCount: number
  currentPage: number
  onChange: (page: number) => void
}

const RootContainer = styled.div`
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .MuiPagination-root {
    color: ${(props) => props.theme.palette.text.primary};
  }

  .MuiPagination-text {
    color: ${(props) => props.theme.palette.text.secondary};
  }

  & .MuiPaginationItem-textPrimary {
    color: ${(props) => props.theme.palette.text.primary};
  }
  & .MuiPaginationItem-textSecondary {
    color: ${(props) => props.theme.palette.text.secondary};
  }
`

const Pagination: React.FC<Props> = ({ size = 'medium', pageCount, currentPage, onChange }) => {
  return (
    <RootContainer>
      <MuiPagination shape='rounded' color='primary' size={size} count={pageCount} page={currentPage} onChange={(e, page) => onChange(page)} />
    </RootContainer>
  )
}

export default Pagination
