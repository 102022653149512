import React, { Suspense, useState } from 'react'
import { graphql } from 'babel-plugin-relay/macro'

import styled from 'styled-components'
import { Paper, Typography, DialogTitle, DialogContent, IconButton } from '@material-ui/core'
import { useFragment } from 'react-relay/hooks'
import Folder from './Folder'
import Button from '../Button'
import { FoldersCard_folders$key } from '../../__generated__/FoldersCard_folders.graphql'
import useNiceMutation from '../../mutations/useNiceMutation'
import { FoldersCardNewMutation, AttachmentEntityTypeEnum } from '../../__generated__/FoldersCardNewMutation.graphql'
import { Alert, Skeleton } from '@material-ui/lab'
import { DisplayMode, FolderType, ViewModeT } from './types'
import DialogFormActionBar from '../Form/DialogFormActionBar'
import NiceFormik from '../Form/NiceFormik'
import { useLazyLoadQuery } from 'react-relay/hooks'
import { FoldersCardQuery } from '../../__generated__/FoldersCardQuery.graphql'
import InputField from '../Form/InputField'
import { media } from '../../theme'
import { useLocation, useNavigate } from 'react-router'
import OdysseyDialog from '../OdysseyDialog'
import { ViewList, ViewModule } from '@material-ui/icons'

interface Props {
  title: string
  folders: FoldersCard_folders$key
  entityType: AttachmentEntityTypeEnum
  folderType: FolderType
  viewMode?: ViewModeT
  entitySlug: string
  documentType?: string
  isExternal?: boolean
}

const Root = styled(Paper)`
  padding: 16px;
  margin-bottom: 16px;
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`

const FieldsContainer = styled.div`
  margin-bottom: 16px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 16px;

  ${media.medium`
    grid-template-columns: repeat(1, 1fr);
  `}

  ${media.small`
    grid-template-columns: auto;
  `}
`

const FoldersCard: React.FC<Props> = ({
  folders: data,
  title,
  entityType,
  folderType,
  entitySlug,
  viewMode = 'home',
  documentType,
  isExternal,
}) => {
  const foldersConnection = useFragment(fragment, data)
  const [newFolderOpen, setNewFolderOpen] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const closeNewFolderDialog = () => {
    setNewFolderOpen(false)
  }
  const [displayMode, setDisplayMode] = useState<DisplayMode>(viewMode === 'tab' && folderType !== 'photos' ? 'list' : 'grid')

  return (
    <>
      <Root variant='outlined'>
        <TitleContainer>
          <Typography variant='h6'>{title}</Typography>
          <div>
            {viewMode === 'tab' && (
              <>
                <IconButton color={displayMode === 'grid' ? 'primary' : 'default'} onClick={() => setDisplayMode('grid')}>
                  <ViewModule />
                </IconButton>
                <IconButton color={displayMode === 'list' ? 'primary' : 'default'} onClick={() => setDisplayMode('list')}>
                  <ViewList />
                </IconButton>
              </>
            )}
            <Button onClick={() => setNewFolderOpen(true)}>New Folder</Button>
            {viewMode === 'home' && (
              <Button onClick={() => navigate(`/${location.pathname.split('/').slice(0, 4).join('/')}/folders`)}>See All</Button>
            )}
          </div>
        </TitleContainer>
        {foldersConnection.nodes
          ?.filter((f) => f?.parentType === folderType)
          .map(
            (node) =>
              node && (
                <Folder
                  key={node.slug}
                  folder={node}
                  entityType={entityType}
                  entitySlug={entitySlug}
                  viewMode={viewMode}
                  folderType={folderType}
                  displayMode={displayMode}
                  documentType={documentType}
                  isExternal={isExternal}
                />
              )
          )}
      </Root>
      <OdysseyDialog
        open={newFolderOpen}
        maxWidth='sm'
        fullWidth
        onClose={closeNewFolderDialog}
        scroll='paper'
        style={{ maxWidth: '640px', margin: 'auto', height: '500px', minHeight: '100%' }}
      >
        <Suspense fallback={<AddFolderForm.Skeleton onClose={() => closeNewFolderDialog()} />}>
          <AddFolderForm entityType={entityType} entitySlug={entitySlug} folderType={folderType} onClose={() => closeNewFolderDialog()} />
        </Suspense>
      </OdysseyDialog>
    </>
  )
}

interface AddFolderProps {
  entityType: string
  entitySlug: string
  folderType: string
  onClose: () => void
}
const AddFolderForm: React.FC<AddFolderProps> & { Skeleton: React.FC<{ onClose?: () => void }> } = ({
  entityType,
  entitySlug,
  folderType,
  onClose,
}) => {
  const [commit] = useNiceMutation<FoldersCardNewMutation>(mutation)
  const [mutationError, setMutationError] = useState<null | string>(null)

  const formData = useLazyLoadQuery<FoldersCardQuery>(query, { entitySlug: entitySlug, entityType: entityType })
  const fields = formData.infoCreateFolderForm.formSchema.fieldSet
  const fieldMaster = Object.fromEntries(fields.map((field) => [field.name, field]))

  return (
    <>
      <NiceFormik
        initialValues={{ entityType: entityType, entitySlug: entitySlug, parentType: folderType, name: '' }}
        onSubmit={(values, actions) => {
          commit({
            variables: {
              input: values as any,
            },
            onError: () => actions.setSubmitting(false),
            onCompleted: (res, errors) => {
              actions.setSubmitting(false)
              if (errors) {
                setMutationError(errors.map((err) => err.message).join(', '))
              } else {
                onClose()
              }
            },
          })
        }}
        formSchema={fields}
      >
        {({ submitForm }) => (
          <>
            <DialogTitle>New Folder</DialogTitle>
            <DialogContent dividers>
              <FieldsContainer>
                {mutationError && <Alert severity='error'>{mutationError}</Alert>}
                <InputField of={'name'} field={fieldMaster['name']} />
              </FieldsContainer>
            </DialogContent>
            <DialogFormActionBar
              onCancel={() => onClose()}
              onSubmit={() => {
                submitForm()
              }}
              cancelCta='Cancel'
              saveCta='Save'
            />
          </>
        )}
      </NiceFormik>
    </>
  )
}

AddFolderForm.Skeleton = ({ onClose }) => (
  <>
    <DialogTitle>New Folder</DialogTitle>
    <DialogContent>
      <Skeleton variant='rect' height='50px' />
    </DialogContent>
    <DialogFormActionBar.Skeleton onCancel={onClose} />
  </>
)

const query = graphql`
  query FoldersCardQuery($entitySlug: String!, $entityType: String!) {
    infoCreateFolderForm(entitySlug: $entitySlug, entityType: $entityType) {
      formSchema {
        fieldSet {
          name
          type
          required
          title
          description
          format
          enum
          enumTitles
        }
      }
    }
  }
`
const fragment = graphql`
  fragment FoldersCard_folders on FolderConnection {
    nodes {
      slug
      parentType
      ...Folder_folder
    }
  }
`
const mutation = graphql`
  mutation FoldersCardNewMutation($input: infoCreateFolderInput!) {
    infoCreateFolder(input: $input) {
      clientMutationId
    }
  }
`

export default FoldersCard
