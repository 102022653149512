/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ExchangeRatesCardQueryVariables = {};
export type ExchangeRatesCardQueryResponse = {
    readonly exchangeRatesList: ReadonlyArray<{
        readonly code: string | null;
        readonly eurExchangeRate: string | null;
        readonly lastUpdatedAt: string | null;
        readonly displayLastUpdatedAt: string | null;
    }>;
};
export type ExchangeRatesCardQuery = {
    readonly response: ExchangeRatesCardQueryResponse;
    readonly variables: ExchangeRatesCardQueryVariables;
};



/*
query ExchangeRatesCardQuery {
  exchangeRatesList {
    code
    eurExchangeRate
    lastUpdatedAt
    displayLastUpdatedAt
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "eurExchangeRate",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastUpdatedAt",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayLastUpdatedAt",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ExchangeRatesCardQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ExchangeRates",
        "kind": "LinkedField",
        "name": "exchangeRatesList",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ExchangeRatesCardQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ExchangeRates",
        "kind": "LinkedField",
        "name": "exchangeRatesList",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6d36a6aeb47c79c3725e83fa5ee8726f",
    "id": null,
    "metadata": {},
    "name": "ExchangeRatesCardQuery",
    "operationKind": "query",
    "text": "query ExchangeRatesCardQuery {\n  exchangeRatesList {\n    code\n    eurExchangeRate\n    lastUpdatedAt\n    displayLastUpdatedAt\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = 'd952d796f992a4bcef46699d51fd64af';
export default node;
