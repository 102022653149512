import React from 'react'
import { Typography } from '@material-ui/core'
import styled from 'styled-components'
import { isSmall, media } from '../theme'
import LoginForm from '../components/LoginForm'

const Root = styled.div`
  width: 100%;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 8px;
  grid-template-rows: auto;
  grid-template-areas: 'te te te te te te . lo lo lo lo lo';
  justify-items: center;
`
const MobileRoot = styled.div`
  width: 100%;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto;
  justify-items: center;
`

const TextContainer = styled.div`
  grid-area: te;
`

const FormContainer = styled.div`
  grid-area: lo;
  max-width: 512px;
`

const LandingTitle = styled(Typography)`
  color: white;
  font-weight: bold;
  padding-left: 16px;
  border-left: 4px solid #00abe8;
  margin: 32px 0;

  ${media.mobile`
    font-size: 26px;
  `}
`

const LandingBody = styled(Typography)`
  color: white;
  display: block;
  font-size: 18px;
  font-weight: 400;
  margin: 32px 0;

  ${media.giant`
    width: 80%;
  `}

  ${media.desktop`
    width: 80%;
  `}

  ${media.mobile`
    font-size: 14px;
  `}
`

const LandingPage: React.FC = () => {
  return (
    <>
      {isSmall() ? (
        <MobileRoot>
          <FormContainer>
            <LandingTitle variant='h6'>Full transparency in every step of the process</LandingTitle>
            <LoginForm />
          </FormContainer>
        </MobileRoot>
      ) : (
        <Root>
          <TextContainer>
            <LandingTitle variant='h4'>Full transparency in every step of the process</LandingTitle>
            <LandingBody variant='body1'>
              Portagent performs customs clearance of vehicles from the USA, Canada, Japan, United Arab Emirates and South Korea
            </LandingBody>
            <LandingBody variant='body1'>
              Our <b>Odyssey</b> platform enables us to provide the highest level of competence and partnership in port logistics and customs
              clearance.
            </LandingBody>
          </TextContainer>
          <FormContainer>
            <LoginForm />
          </FormContainer>
        </Root>
      )}
    </>
  )
}

export default LandingPage
