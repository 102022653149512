import React from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import styled from 'styled-components'

import { ExternalStage_stage$key } from '../../__generated__/ExternalStage_stage.graphql'
import { useFragment } from 'react-relay/hooks'
import { media, isSmall } from '../../theme'
import { Typography } from '@material-ui/core'
import { StageStatusEnum } from '../../__generated__/ShipmentWorkflow_shipment.graphql'

interface Props {
  stage: ExternalStage_stage$key
  index: number
  isFirst?: boolean
  isLast?: boolean
}

export const StepRoot = styled.div<{ $status: StageStatusEnum; $isFirst?: boolean; $isLast?: boolean }>`
  --step-bg-color: ${(props) => (props.$status === 'completed' ? '#BDF0D1' : props.$status === 'active' ? '#BDE4F0' : 'white')};
  --step-bg-color-hover: ${(props) => (props.$status === 'completed' ? '#BDF0D1' : props.$status === 'active' ? '#BDE4F0' : '#eaeaea')};
  --step-text-color: ${(props) => (props.$status === 'completed' ? '#1A7734' : props.$status === 'active' ? '#1A4A77' : '#646464')};
  color: var(--step-text-color);
  flex-grow: 1;
  position: relative;
  margin: 0;
  margin-left: ${(props) => (props.$isFirst ? '0px' : '24px')};
  margin-right: ${(props) => (props.$isLast ? '0px' : '24px')};

  height: 64px;
  display: flex;
  align-items: center;
`

export const StepWithArrow = styled.div<{ $status: StageStatusEnum; $isFirst?: boolean; $isLast?: boolean }>`
  cursor: pointer;
  padding: 8px 16px;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--step-bg-color);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 64px;
  z-index: 1;

  ${media.tablet`
    margin: 0 14px;
  `}

  ${media.mobile`
    margin: 0 14px;
  `}

  &:before {
    content: ${(props) => (props.$isFirst ? 'none' : "''")};
    position: absolute;
    left: -23px;
    display: block;
    width: 0;
    height: 0;
    border-top: 32px solid var(--step-bg-color);
    border-bottom: 32px solid var(--step-bg-color);
    border-left: 24px solid transparent;
  }

  &:after {
    content: ${(props) => (props.$isLast ? 'none' : "''")};
    position: absolute;
    right: -23px;
    display: block;
    width: 0;
    height: 0;
    border-top: 32px solid transparent;
    border-bottom: 32px solid transparent;
    border-left: 24px solid var(--step-bg-color);
  }

  &:hover {
    --step-bg-color: var(--step-bg-color-hover);
  }

  & .loading-dots--dot {
    background-color: var(--step-text-color);
  }
`

const ContentRoot = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  z-index: 1;
  pointer-events: none;
`

const HorizontalFlex = styled.div`
  display: flex;
  align-items: center;
`

const StepNumber = styled(Typography)`
  font-weight: 600;
`

const StepLabel = styled.div`
  padding-left: 16px;
`

const StepTitle = styled(Typography)`
  font-size: 1.1rem;
  line-height: 1;
  white-space: nowrap;
`

const StepCaption = styled(Typography)`
  white-space: nowrap;
`

const ExternalStage: React.FC<Props> = ({ stage: data, index, isFirst, isLast }) => {
  const stage = useFragment(fragment, data)

  return (
    <StepRoot $status={stage.status} $isFirst={isFirst} $isLast={isLast}>
      <StepWithArrow key={stage.value} $status={stage.status} $isFirst={isFirst} $isLast={isLast} />
      <ContentRoot>
        <HorizontalFlex>
          <StepNumber variant='h5'>{index}</StepNumber>
          {(!isSmall() || stage.status === 'active') && (
            <StepLabel>
              <StepTitle variant='h6'>{stage.label}</StepTitle>
              {stage.ownedBy && <StepCaption variant='caption'>@{stage.ownedBy}</StepCaption>}
            </StepLabel>
          )}
        </HorizontalFlex>
      </ContentRoot>
    </StepRoot>
  )
}

const fragment = graphql`
  fragment ExternalStage_stage on WorkflowStage {
    value
    status
    label
    completedAt
    ownedBy
  }
`

export default ExternalStage
