/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type FeedItem_data = {
    readonly slug: string;
    readonly entity: string;
    readonly title: string;
    readonly titleImage: {
        readonly url: string | null;
    };
    readonly happenedAt: string;
    readonly images: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly url: string | null;
            } | null;
        } | null> | null;
        readonly " $fragmentRefs": FragmentRefs<"FeedImages_data">;
    } | null;
    readonly contentLines: ReadonlyArray<{
        readonly key: string;
        readonly text: string;
    }> | null;
    readonly attachments: {
        readonly nodes: ReadonlyArray<{
            readonly slug: string;
        } | null> | null;
        readonly " $fragmentRefs": FragmentRefs<"FeedAttachments_data">;
    } | null;
    readonly keyFields: ReadonlyArray<{
        readonly key: string;
        readonly " $fragmentRefs": FragmentRefs<"KeyField_data">;
    }> | null;
    readonly " $refType": "FeedItem_data";
};
export type FeedItem_data$data = FeedItem_data;
export type FeedItem_data$key = {
    readonly " $data"?: FeedItem_data$data;
    readonly " $fragmentRefs": FragmentRefs<"FeedItem_data">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeedItem_data",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "titleImage",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "happenedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ImageConnection",
      "kind": "LinkedField",
      "name": "images",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ImageEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Image",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "FeedImages_data"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SemanticString",
      "kind": "LinkedField",
      "name": "contentLines",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AttachmentConnection",
      "kind": "LinkedField",
      "name": "attachments",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Attachment",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "FeedAttachments_data"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "KeyField",
      "kind": "LinkedField",
      "name": "keyFields",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "KeyField_data"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "FeedItem",
  "abstractKey": null
};
})();
(node as any).hash = 'd7d21271bb8c2a9c5d3fd1e0e2c1601b';
export default node;
