/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type FormFieldTypeEnum = "array" | "boolean" | "boolean_switch" | "container" | "container_image_slug" | "date" | "datetime" | "doc_slug" | "file_slug" | "image_slug" | "linked_contacts" | "multi_select" | "number" | "parent_shipment" | "port" | "rate_card" | "rich_text" | "searchable" | "shipment_image_slug" | "shipping_line" | "string" | "vessel" | "voyage" | "%future added value";
export type ExternalTicketAddQueryVariables = {
    entityType?: string | null;
};
export type ExternalTicketAddQueryResponse = {
    readonly infoNewTicketForm: {
        readonly formSchema: {
            readonly fieldSet: ReadonlyArray<{
                readonly name: string;
                readonly type: FormFieldTypeEnum;
                readonly required: boolean;
                readonly title: string | null;
                readonly description: string | null;
                readonly format: string | null;
                readonly enum: ReadonlyArray<string | null> | null;
                readonly enumTitles: ReadonlyArray<string | null> | null;
                readonly arrayItems: ReadonlyArray<{
                    readonly name: string;
                    readonly type: FormFieldTypeEnum;
                    readonly title: string | null;
                    readonly required: boolean;
                    readonly description: string | null;
                    readonly format: string | null;
                    readonly enum: ReadonlyArray<string | null> | null;
                    readonly enumTitles: ReadonlyArray<string | null> | null;
                }> | null;
            }>;
        };
    };
};
export type ExternalTicketAddQuery = {
    readonly response: ExternalTicketAddQueryResponse;
    readonly variables: ExternalTicketAddQueryVariables;
};



/*
query ExternalTicketAddQuery(
  $entityType: String
) {
  infoNewTicketForm(entityType: $entityType) {
    formSchema {
      fieldSet {
        name
        type
        required
        title
        description
        format
        enum
        enumTitles
        arrayItems {
          name
          type
          title
          required
          description
          format
          enum
          enumTitles
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "entityType"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "required",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "format",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "enum",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "enumTitles",
  "storageKey": null
},
v9 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "entityType",
        "variableName": "entityType"
      }
    ],
    "concreteType": "InfoNewTicketFormResponse",
    "kind": "LinkedField",
    "name": "infoNewTicketForm",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "FormSchema",
        "kind": "LinkedField",
        "name": "formSchema",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FormField",
            "kind": "LinkedField",
            "name": "fieldSet",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "FormField",
                "kind": "LinkedField",
                "name": "arrayItems",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v4/*: any*/),
                  (v3/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ExternalTicketAddQuery",
    "selections": (v9/*: any*/),
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ExternalTicketAddQuery",
    "selections": (v9/*: any*/)
  },
  "params": {
    "cacheID": "b4f67a16b1c1e7855b7632a0ff885c52",
    "id": null,
    "metadata": {},
    "name": "ExternalTicketAddQuery",
    "operationKind": "query",
    "text": "query ExternalTicketAddQuery(\n  $entityType: String\n) {\n  infoNewTicketForm(entityType: $entityType) {\n    formSchema {\n      fieldSet {\n        name\n        type\n        required\n        title\n        description\n        format\n        enum\n        enumTitles\n        arrayItems {\n          name\n          type\n          title\n          required\n          description\n          format\n          enum\n          enumTitles\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '513f86609aff601d8e324697a7b950e8';
export default node;
