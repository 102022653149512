import { graphql } from 'babel-plugin-relay/macro'
import React from 'react'
import styled from 'styled-components'
import Pagination from '../../components/Pagination'
import { ViewMode } from '../../viewTypes'
import { ExternalItemListFooterQueryVariables, ExternalItemListFooterQuery } from '../../__generated__/ExternalItemListFooterQuery.graphql'
import { useLazyLoadQuery, useFragment } from 'react-relay/hooks'

interface Props {
  variables: ExternalItemListFooterQueryVariables
  viewMode: ViewMode
  setViewMode?: (mode: ViewMode) => void
  onPageChange: (page: number) => void
}

const RootContainer = styled.div`
  width: 100%;
  height: 100%;
`

const ExternalItemListFooter: React.FC<Props> = ({ variables, onPageChange }) => {
  const data = useLazyLoadQuery<ExternalItemListFooterQuery>(query, variables)
  // @ts-ignore
  const { paginationMeta } = useFragment(fragment, data.externalItemList)

  return (
    <RootContainer>
      <>
        {paginationMeta && (
          <Pagination
            isFirst={!paginationMeta.hasPrevPage}
            isLast={!paginationMeta.hasNextPage}
            currentPage={paginationMeta.page}
            pageCount={paginationMeta.totalPages}
            onChange={(page) => onPageChange(page)}
          />
        )}
      </>
    </RootContainer>
  )
}

const query = graphql`
  query ExternalItemListFooterQuery(
    $type: ExternalListItemTypeEnum
    $filters: [FilterInput!]
    $sortKey: String
    $page: Int
    $perPage: Int
    $searchQuery: String
    $voyageEtaStart: Date
    $voyageEtaEnd: Date
    $dischargeDateStart: Date
    $dischargeDateEnd: Date
  ) {
    externalItemList(
      type: $type
      filters: $filters
      sortKey: $sortKey
      page: $page
      perPage: $perPage
      searchQuery: $searchQuery
      voyageEtaStart: $voyageEtaStart
      voyageEtaEnd: $voyageEtaEnd
      dischargeDateStart: $dischargeDateStart
      dischargeDateEnd: $dischargeDateEnd
    ) {
      ...ExternalItemListFooter_data
    }
  }
`

const fragment = graphql`
  fragment ExternalItemListFooter_data on ExternalItemList {
    paginationMeta {
      hasNextPage
      hasPrevPage
      page
      perPage
      totalPages
    }
  }
`

export default ExternalItemListFooter
