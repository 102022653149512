import { Divider, Typography, DialogTitle, DialogActions, DialogContent } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import React, { useState } from 'react'
import styled from 'styled-components'
import { media } from '../theme'
import Button from './Button'
import { SlideInDrawer } from './SlideInDrawer'
import EmailIcon from '@material-ui/icons/Email'
import { graphql } from 'babel-plugin-relay/macro'
import { LiveShareDrawerMutation } from '../__generated__/LiveShareDrawerMutation.graphql'
import useNiceMutation from '../mutations/useNiceMutation'
import { Alert } from '@material-ui/lab'
import LoadingDots from './LoadingDots'
import { LiveShareDrawer_liveshare$key } from '../__generated__/LiveShareDrawer_liveshare.graphql'
import { useFragment } from 'react-relay/hooks'
import VisibilityIcon from '@material-ui/icons/Visibility'
import PreviewLiveShare from './PreviewLiveShare'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import OdysseyDialog from './OdysseyDialog'
interface Props {
  liveShareItem: LiveShareDrawer_liveshare$key
  open: boolean
  onClose: () => void
  entitySlug: string
}

const ShowContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 16px;
`

const Header = styled.div`
  background-color: ${(props) => props.theme.palette.background.default};
  display: flex;
`

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const Titlebar = styled.div`
  flex-grow: 1;
`

const ShowFieldsContainer = styled.div`
  margin: 4px 0 16px 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 8px;
  width: 100%;

  ${media.small`
    grid-template-columns: repeat(2, 1fr);
  `}
`

const Container = styled.div`
  max-width: 200px;
  width: 100%;
  margin: 2px 0;
`

const Label = styled(Typography)`
  font-size: 12px;
  color: #333;
`

const Value = styled(Typography)`
  color: #333;
  font-weight: 500;
  box-sizing: content-box;
  white-space: nowrap;
  overflow: hidden;
  line-height: 24px;
  text-overflow: ellipsis;
`
const BodyFieldValue = styled(Typography)`
  color: #333;
  font-weight: 500;
  box-sizing: content-box;
  white-space: nowrap;
  overflow: hidden;
  line-height: 24px;
  white-space: pre-wrap;
`

const BodyFieldsContainer = styled.div`
  max-width: 95%;
  margin: 20px 0;
`
const DividerLine = styled(Divider)`
  margin: 7px 0px;
`
const LiveShareDrawer: React.FC<Props> = ({ liveShareItem: data, open, onClose }) => {
  const liveShareItem = useFragment(fragment, data)

  const [commitResendMail, resendMailIsInFlight] = useNiceMutation<LiveShareDrawerMutation>(resendMailMutation)
  const [resendMailError, setResendMailError] = useState<null | string>(null)
  const [openResendMailDialog, setOpenResendMailDialog] = useState(false)
  const [openPreview, setOpenPreview] = useState(false)
  const closeResendMailDialog = () => {
    setResendMailError(null)
    setOpenResendMailDialog(false)
  }
  const resendMail = () => {
    commitResendMail({
      variables: {
        input: {
          slug: liveShareItem.slug,
        },
      },
      onCompleted: (res, errors) => {
        if (errors) {
          setResendMailError(errors.map((err) => err.message).join(', '))
        } else {
          closeResendMailDialog()
        }
      },
    })
  }

  return (
    <>
      <OdysseyDialog open={openResendMailDialog} onClose={closeResendMailDialog}>
        <>
          <DialogTitle>Are you sure you wanna resend mail to `{liveShareItem.contact.email}`?</DialogTitle>
          {resendMailError && <Alert severity='error'>{resendMailError}</Alert>}
          <DialogActions>
            <Button onClick={closeResendMailDialog}>Cancel</Button>
            <Button variant='contained' onClick={() => resendMail()}>
              {resendMailIsInFlight ? <LoadingDots variant='light' /> : 'OK'}
            </Button>
          </DialogActions>
        </>
      </OdysseyDialog>

      <OdysseyDialog open={openPreview} onClose={() => setOpenPreview(false)}>
        <DialogContent>
          <PreviewLiveShare slug={liveShareItem.slug} />
        </DialogContent>
      </OdysseyDialog>

      <SlideInDrawer size='small' anchor='right' open={open} elevation={0} onClose={onClose}>
        <ShowContainer>
          <Header>
            <Titlebar>
              <Typography variant='h5'>{liveShareItem.contact.firstName}</Typography>
              {liveShareItem?.viewedAt && liveShareItem?.viewedAt[0] ? (
                <Typography variant='subtitle2'>Last viewed {liveShareItem?.viewedAt[0]}</Typography>
              ) : (
                <Typography variant='subtitle2'>Not yet viewed</Typography>
              )}
            </Titlebar>
            <Button endIcon={<VisibilityIcon />} onClick={() => setOpenPreview(true)}>
              Preview
            </Button>
            <Button endIcon={<EmailIcon />} onClick={() => setOpenResendMailDialog(true)}>
              Resend Email
            </Button>
            <Button endIcon={<Close />} onClick={onClose}>
              Close
            </Button>
          </Header>
          <DividerLine variant='fullWidth' />

          <Footer>
            <ShowFieldsContainer>
              <Container>
                <Label variant='caption' color='textSecondary'>
                  Generated By
                </Label>
                <Value variant='body1'>{liveShareItem.generatedBy === null ? '--' : liveShareItem.generatedBy}</Value>
              </Container>
              <Container>
                <Label variant='caption' color='textSecondary'>
                  Generated At
                </Label>
                <Value variant='body1'>{liveShareItem.generatedAt === null ? '--' : liveShareItem.generatedAt}</Value>
              </Container>
              <Container>
                <Label variant='caption' color='textSecondary'>
                  Expires
                </Label>
                <Value variant='body1'>{liveShareItem.expiresAt === null ? '--' : liveShareItem.expiresAt}</Value>
              </Container>
            </ShowFieldsContainer>
          </Footer>
          <DividerLine variant='fullWidth' />

          <BodyFieldsContainer>
            <Label variant='caption' color='textSecondary'>
              Email Preview
            </Label>
            <BodyFieldValue variant='body1'>{liveShareItem.email === null ? '--' : liveShareItem.email}</BodyFieldValue>
          </BodyFieldsContainer>
          <BodyFieldsContainer>
            <DividerLine variant='fullWidth' />
            <Container>
              <Label>Viewed At</Label>
              <Value variant='body1'>
                {liveShareItem.viewedAt !== null &&
                  liveShareItem.viewedAt.map((view) => (
                    <>
                      <AccessTimeIcon style={{ color: '#0080f6', marginBottom: '-7px' }} />
                      <Typography variant='subtitle1' style={{ display: 'inline' }}>
                        {' '}
                        {view}
                      </Typography>
                      <br />
                    </>
                  ))}
              </Value>
            </Container>
          </BodyFieldsContainer>
        </ShowContainer>
      </SlideInDrawer>
    </>
  )
}

const resendMailMutation = graphql`
  mutation LiveShareDrawerMutation($input: infoSendLiveShareEmailInput!) {
    infoSendLiveShareEmail(input: $input) {
      clientMutationId
    }
  }
`
const fragment = graphql`
  fragment LiveShareDrawer_liveshare on LiveShare {
    name
    slug
    password
    url
    contact {
      firstName
      lastName
      email
    }
    email
    generatedBy
    generatedAt
    requestedBy
    requestedAt
    viewedAt
    expiresAt
  }
`
export default LiveShareDrawer
