import { TextField, Typography } from '@material-ui/core'
import { AutocompleteRenderInputParams, Skeleton } from '@material-ui/lab'
import { graphql } from 'babel-plugin-relay/macro'
import { Field } from 'formik'
import { Autocomplete } from 'formik-material-ui-lab'
import React from 'react'
import { useLazyLoadQuery } from 'react-relay/hooks'
import styled from 'styled-components'
import { useFieldFast } from '../../hooks/useFieldFast'
import { media } from '../../theme'
import { RateCardFieldQuery } from '../../__generated__/RateCardFieldQuery.graphql'
import KeyField from '../KeyField'
import { FieldT, FieldVariantEnum } from './types'

interface Props {
  field: FieldT
  variant?: FieldVariantEnum
}

const TitleContainer = styled.div`
  margin-bottom: 16px;
`

const TitleText = styled(Typography)`
  margin-bottom: 16px;
`

const FieldsContainer = styled.div`
  padding: 0 16px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  margin-bottom: 16px;

  ${media.medium`
    grid-template-columns: repeat(4, 1fr);
  `}

  ${media.small`
    grid-template-columns: repeat(2, 1fr);
  `}
`

const RateCardField: React.FC<Props> & { Skeleton: React.FC } = ({ field }) => {
  const [, meta, helpers] = useFieldFast(field.name)
  const data = useLazyLoadQuery<RateCardFieldQuery>(query, { type: 'rate_cards' })
  const options = data.settingList?.items?.edges?.map((edge) => edge?.node) || []
  const selectedOption = options?.find((opt) => opt?.slug === meta.value)

  return (
    <div>
      <TitleContainer>
        <TitleText variant='body1'>Rate Card: </TitleText>
        <Field
          name={field.name}
          component={Autocomplete}
          options={options}
          getOptionLabel={(option: any) => option.title}
          getOptionsSelected={(option: any, value: string) => option.slug === value}
          value={selectedOption || { slug: meta.value, title: meta.value }}
          onChange={(_: any, value: any) => (value ? helpers.setValue(value.slug) : helpers.setValue(null))}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <TextField
              {...params}
              error={meta.touched && !!meta.error}
              helperText={meta.touched ? meta.error : null}
              label={field.title}
              variant='outlined'
            />
          )}
          renderOption={(option: any) => option.title}
        />
      </TitleContainer>
      <FieldsContainer>
        {selectedOption?.keyFields?.map((keyField) => {
          return <KeyField key={keyField.key} data={keyField} />
        })}
      </FieldsContainer>
    </div>
  )
}

RateCardField.Skeleton = () => (
  <div>
    <TitleContainer>
      <TitleText variant='body1'>Rate Card: </TitleText>
      <Skeleton variant='rect' height='50px' />
    </TitleContainer>
  </div>
)

const query = graphql`
  query RateCardFieldQuery($type: SettingTypeEnum!) {
    settingList(type: $type) {
      items {
        edges {
          node {
            slug
            title
            keyFields {
              key
              ...KeyField_data
            }
          }
        }
      }
    }
  }
`

export default RateCardField
