import {
  Avatar,
  Card,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Box,
  CircularProgress,
} from '@material-ui/core'
import { ArrowDropDown, Edit, MoreVert, ExpandLess, ExpandMore } from '@material-ui/icons'
import { graphql } from 'babel-plugin-relay/macro'
import React, { useRef, useState, Suspense } from 'react'
import styled from 'styled-components'
import Badge from '../../components/Badge'
import Button from '../../components/Button'
import Hero from '../../components/Hero'
import KeyField from '../../components/KeyField'
import KeyValueField from '../../components/KeyValueField'
import LinkCard from '../../components/LinkSet/LinkCard'
import StickyNote from '../../components/StickyNote'
import SubStage from '../../components/SubStage'
import TabsUI from '../../components/TabsUI/TabsUI'
import Watchers from '../../components/Watchers'
import ContainerWorkflow from '../../components/Workflows/ContainerWorkflow'
import useWorkspaceNavigate from '../../hooks/useWorkspaceNavigate'
import { isMedium, isSmall, media } from '../../theme'
import { ContainerShowHeroCardQuery } from '../../__generated__/ContainerShowHeroCardQuery.graphql'
import { tabs } from './tabs'
import { Skeleton } from '@material-ui/lab'
import useLazyLoadQueryWithSubscription from '../../hooks/useLazyLoadQueryWithSubscription'
import ArchiveActions from '../../components/ArchiveActions'
import { ShowPageRenderContext } from '../../viewTypes'
import { Title as ReactHeadTitle } from 'react-head'
import ArchivedBanner from '../../components/ArchivedBanner'
import SemanticLine from '../../components/SemanticLine'
import { ContainerShowHeroCardContainerQuery } from '../../__generated__/ContainerShowHeroCardContainerQuery.graphql'

interface Props {
  slug: string
  activeTab: string
  onEdit: () => void
  renderContext: ShowPageRenderContext
}

const ContentRoot = styled.div`
  width: 100%;
  min-height: 100px;
`

const NonStageContent = styled.div`
  width: 100%;
  display: grid;
  padding: 0 64px;
  grid-template-columns: 3fr 1fr;

  ${media.medium`
    padding: 0 32px;
    grid-template-columns: 3fr 1fr;
  `}

  ${media.small`
    grid-template-columns: 100%;
    padding-left: 8px;
    padding-right: 8px;
  `}
`

const TitleContent = styled.div`
  width: 100%;
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${media.small`
    padding-bottom: 8px;
  `}
`

const MetaContent = styled.div`
  width: 100%;
  padding: 34px 20px 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.small`
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    align-items: stretch;
  `}
`

const StyledStickyNote = styled(StickyNote)`
  min-width: 220px;
`

const StagesContainer = styled.div`
  width: 100%;
  padding-bottom: 16px;
`

const TabContainer = styled.div`
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const DetailsCard = styled(Card)`
  width: 100%;
  padding: 16px;
`

const KeyFieldsContainer = styled.div`
  margin-bottom: 8px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 190px);
  grid-gap: 8px;
  width: 100%;

  ${media.small`
    grid-template-columns: repeat(auto-fill, 47.5%);
  `}
`

const ProfileImage = styled(Avatar)`
  margin-bottom: 8px;
  height: 100px;
  width: 100px;
  margin-right: 16px;
  border: 4px solid ${(props) => props.theme.palette.background.paper};
  border-radius: 8px;

  ${media.small`
    height: 80px;
    width: 80px;
    margin-bottom: 0;
  `}
`

const Titlebar = styled(Paper)`
  position: relative;
  width: 100%;
  height: 72px;
  display: flex;
  align-items: center;
  padding: 8px;
  margin-bottom: 20px;
  margin-top: 30px;

  ${media.small`
    height: 90px;
  `}
`

const TitlebarContent = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
`

const Title = styled(Typography)`
  margin-right: 8px;
  font-weight: 700;
`

const TruncatedTitle = styled(Title)`
  ${media.small`
    width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;
  `}
`

const ShipmentTitleContainer = styled.div`
  display: flex;
  flex-grow: 0;
  align-items: center;
  justify-content: flex-end;
`

const ShipmentTitleContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`

const NormalActions = styled(FlexContainer)`
  display: flex;
  align-items: center;
  ${media.small`
    display: none;
  `}
`

const CollapsedActions = styled(FlexContainer)`
  display: none;

  ${media.small`
    display: flex;
  `}
`

const LinkKeyValueField = styled(KeyValueField)`
  grid-column-end: span 2;
`

interface NestedListItemProps {
  item: ContainerShowHeroCardContainerQuery['response']['containerShow']['shipmentItems'][number]
  style?: Record<any, any>
}

const NestedListItem = ({ item, style }: NestedListItemProps) => {
  const [open, setOpen] = useState(true)
  const wsNavigate = useWorkspaceNavigate()

  const toggle = (e: React.MouseEvent) => {
    e.stopPropagation()
    setOpen((o) => !o)
  }

  const vin = item.lines?.find((line) => line.key === 'vin')

  return (
    <>
      <ListItem
        style={style}
        button
        onClick={() => {
          wsNavigate(`/shipment/${item.slug}/home`)
        }}
      >
        <ListItemIcon>
          {item.badges?.map((badge) => (
            <Badge key={badge.key} data={badge} />
          ))}{' '}
        </ListItemIcon>
        <ListItemText primary={item.title} secondary={vin && <SemanticLine key={'vin'} data={vin} />} />
        {!!item.children?.length && <IconButton onClick={toggle}>{open ? <ExpandLess /> : <ExpandMore />}</IconButton>}
      </ListItem>
      {!!item.children?.length && (
        <Collapse in={open} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            {item.children?.map((childItem) => (
              <NestedListItem style={{ paddingLeft: 32 }} item={childItem as any} key={childItem.slug} />
            ))}
          </List>
        </Collapse>
      )}
    </>
  )
}

const ShipmentsMenu: React.FC<any> = ({ slug, shipmentMenuRef }: any) => {
  const [open, setOpen] = useState(false)
  const handleClose = () => {
    setOpen(false)
  }

  const { containerShow: container } = useLazyLoadQueryWithSubscription<ContainerShowHeroCardContainerQuery>(
    containerQuery,
    {
      slug: slug,
    },
    { subscriptionOptions: { entityType: 'containers', entitySlug: slug } }
  )

  return container.shipmentItems.length > 0 ? (
    <ShipmentTitleContainer>
      <ShipmentTitleContent />
      <IconButton onClick={() => setOpen(true)}>
        <ArrowDropDown />
      </IconButton>
      <Menu
        PaperProps={{ style: { width: shipmentMenuRef.current?.clientWidth, maxHeight: 'calc(100vh - 184px)' } }}
        open={open}
        anchorEl={shipmentMenuRef.current}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handleClose}
      >
        <List onClick={handleClose}>
          {container.shipmentItems?.map((shipment) => (
            <NestedListItem key={shipment.slug} item={shipment} />
          ))}
        </List>
      </Menu>
    </ShipmentTitleContainer>
  ) : null
}

const ContainerShowHeroCard: React.FC<Props> & { Skeleton: React.FC } = ({ slug, activeTab, onEdit, renderContext }) => {
  const maxFields = isSmall() ? 4 : isMedium() ? 9 : 15
  const shipmentMenuRef = useRef<null | HTMLElement>(null)

  const { itemShow: item, containerShow: container } = useLazyLoadQueryWithSubscription<ContainerShowHeroCardQuery>(
    query,
    {
      slug: slug,
      itemType: 'container',
    },
    { subscriptionOptions: { entityType: 'containers', entitySlug: slug } }
  )

  const value = tabs.findIndex((t) => t.value === activeTab)
  const wsNavigate = useWorkspaceNavigate()

  const onTabChange = (e: React.ChangeEvent<unknown>, newValue: number) => {
    wsNavigate(`/container/${item.slug}/${tabs[newValue].value}`)
  }

  const [actionMenuAnchorEl, setActionMenuAnchorEl] = useState<null | HTMLElement>(null)
  const closeActionMenu = () => setActionMenuAnchorEl(null)
  const voyageLink = (item.linkSets?.map((linkSet) => linkSet.links?.find((link) => link.type === 'voyage'))?.filter((link) => link) || [])[0]
  const voyageKey = container.procedure === 'Export' ? 'etd' : 'eta'

  return (
    <>
      {renderContext === 'page' && <ReactHeadTitle>Container - {item.title}</ReactHeadTitle>}
      <ArchivedBanner isArchived={container.isArchived} />
      <Hero backgroundColor='#7d7d7d'>
        <ContentRoot>
          <NonStageContent>
            <TitleContent>
              <Titlebar ref={shipmentMenuRef} variant='outlined'>
                <ProfileImage src={item?.image?.url as string} />
                <TitlebarContent>
                  <TruncatedTitle variant='h5'>{item.title}</TruncatedTitle>
                  <Box display='flex' alignItems='center'>
                    {item.badges?.map((badge) => (
                      <Badge key={badge.key} data={badge} />
                    ))}
                  </Box>
                </TitlebarContent>
                <Suspense
                  fallback={
                    <IconButton disabled>
                      <CircularProgress size={20} />
                    </IconButton>
                  }
                >
                  <ShipmentsMenu slug={slug} shipmentMenuRef={shipmentMenuRef} />
                </Suspense>
              </Titlebar>
              <DetailsCard variant='outlined'>
                {item.keyFields && (
                  <KeyFieldsContainer>
                    {item.keyFields?.slice(0, maxFields)?.map((field) => (
                      <KeyField key={field.key} data={field} />
                    ))}
                    <LinkKeyValueField label='Voyage' value={voyageLink ? <LinkCard data={voyageLink} voyageKey={voyageKey} /> : null} />
                  </KeyFieldsContainer>
                )}
              </DetailsCard>
            </TitleContent>
            <MetaContent>
              <SubStage data={container.subStage} entityType='containers' entitySlug={slug} />
              {item.stickyNoteContent && <StyledStickyNote note={item.stickyNoteContent} />}
            </MetaContent>
          </NonStageContent>
          <StagesContainer>
            <ContainerWorkflow container={container} />
          </StagesContainer>
        </ContentRoot>
      </Hero>
      <Hero backgroundColor='transparent'>
        <TabContainer>
          <TabsUI isWhiteBackground tabs={tabs} value={value} onChange={onTabChange} />
          <NormalActions>
            <Watchers entityType='containers' entitySlug={slug} />
            {container.stage === 'finished' && <ArchiveActions isArchive={container.isArchived} entityType='containers' entitySlug={slug} />}
            {!container.isArchived && (
              <Button variant='contained' startIcon={<Edit />} onClick={onEdit}>
                Edit
              </Button>
            )}
          </NormalActions>
          <CollapsedActions>
            <IconButton onClick={(e) => setActionMenuAnchorEl(e.currentTarget)}>
              <MoreVert />
            </IconButton>
            <Menu id='simple-menu' anchorEl={actionMenuAnchorEl} keepMounted open={Boolean(actionMenuAnchorEl)} onClose={closeActionMenu}>
              <MenuItem onClick={onEdit}>Edit</MenuItem>
            </Menu>
          </CollapsedActions>
        </TabContainer>
      </Hero>
    </>
  )
}

const query = graphql`
  query ContainerShowHeroCardQuery($slug: String!, $itemType: ShowItemTypeEnum!) {
    itemShow(slug: $slug, type: $itemType) {
      slug
      title
      stickyNoteContent
      image {
        url
        alt
        ...ItemImage_data
      }
      badges {
        key
        ...Badge_data
      }
      lines {
        key
        ...SemanticLine_data
      }
      keyFields {
        key
        ...KeyField_data
      }
      linkSets {
        links {
          key
          type
          ...LinkCard_data
        }
      }
    }
    containerShow(slug: $slug) {
      slug
      isArchived
      stage
      procedure
      ...ContainerWorkflow_container
      subStage {
        ...SubStage_data
      }
    }
  }
`

const containerQuery = graphql`
  query ContainerShowHeroCardContainerQuery($slug: String!) {
    containerShow(slug: $slug) {
      slug
      shipmentItems {
        slug
        title
        children {
          slug
          title
          badges {
            key
            ...Badge_data
          }
          lines {
            key
            parts {
              ...SemanticString_data
            }
            ...SemanticLine_data
          }
        }
        badges {
          key
          ...Badge_data
        }
        image {
          url
        }
        lines {
          key
          parts {
            ...SemanticString_data
          }
          ...SemanticLine_data
        }
      }
    }
  }
`

ContainerShowHeroCard.Skeleton = () => (
  <>
    <Hero backgroundColor='transparent'>
      <ContentRoot>
        <NonStageContent>
          <TitleContent>
            <Titlebar variant='outlined'>
              <Skeleton variant='rect' height='100px' width='100px' style={{ borderRadius: '8px', marginRight: '16px' }} />
              <TitlebarContent>
                <Skeleton>
                  <Title variant='h5'>..................</Title>
                </Skeleton>
              </TitlebarContent>
            </Titlebar>
            <DetailsCard variant='outlined'>
              <KeyFieldsContainer>
                {[...Array(4)].map((idx) => (
                  <Skeleton key={idx} height='44px' />
                ))}
              </KeyFieldsContainer>
            </DetailsCard>
          </TitleContent>
          <MetaContent>
            <Skeleton variant='rect' height='36px' width='200px' style={{ marginBottom: '8px' }} />
          </MetaContent>
        </NonStageContent>
        <StagesContainer>
          <div style={{ height: '64px' }} />
        </StagesContainer>
      </ContentRoot>
    </Hero>
    <Hero backgroundColor='transparent'>
      <TabContainer>
        {/* no TabsUI skeleton */}
        <span></span>
        <NormalActions>
          <Button variant='contained' startIcon={<Edit />} disabled>
            Edit
          </Button>
        </NormalActions>
        <CollapsedActions>
          <IconButton disabled>
            <MoreVert />
          </IconButton>
        </CollapsedActions>
      </TabContainer>
    </Hero>
  </>
)

export default ContainerShowHeroCard
