import React, { useState } from 'react'
import { graphql } from 'babel-plugin-relay/macro'

import { useFragment } from 'react-relay/hooks'
import styled from 'styled-components'
import { Typography, Divider } from '@material-ui/core'
import { EmailItem_email$key } from '../../__generated__/EmailItem_email.graphql'
import { SlideInDrawer } from '../SlideInDrawer'
import SmallAttachment from '../File/SmallAttachment'
import Attachment from '../File/Attachment'
import { useDateTimeDisplay } from '../../hooks/timeZoneDisplay'

interface Props {
  email: EmailItem_email$key
}

const Root = styled.div`
  padding: 16px;
  cursor: pointer;
  background-color: ${(props) => props.theme.palette.background.paper};

  &:hover {
    filter: brightness(0.95);
  }
`

const EmailSubtitle = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const AttachmentsContainer = styled.div`
  margin-top: 8px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 160px);
  grid-auto-rows: 30px;
  grid-gap: 8px;
`

const ShowRoot = styled.div`
  padding: 16px;
`

const ShowTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ShowToText = styled(Typography)`
  display: inline-block;
`

const ShowBodyText = styled(Typography)`
  margin-top: 8px;
  margin-bottom: 16px;
  white-space: pre-wrap;
`

const ShowAttachmentsContainer = styled.div`
  margin-top: 16px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 190px);
  grid-auto-rows: 120px;
  grid-gap: 8px;
`

const EmailItem: React.FC<Props> = ({ email: data }) => {
  const email = useFragment(fragment, data)
  const [showOpen, setShowOpen] = useState(false)

  return (
    <>
      <Root onClick={() => setShowOpen(true)}>
        <Typography variant='body1'>{email.subject}</Typography>
        <EmailSubtitle variant='body2' color='textSecondary'>
          {email.to.join(', ')} -- {email.body}
        </EmailSubtitle>
        <AttachmentsContainer>
          {email.attachments.map((attachment) => (
            <SmallAttachment key={attachment.name} attachment={attachment} />
          ))}
        </AttachmentsContainer>
      </Root>
      <SlideInDrawer size='small' anchor='right' open={showOpen} elevation={0} onClose={() => setShowOpen(false)}>
        <ShowRoot>
          <ShowTitleContainer>
            <Typography variant='h5'>{email.subject}</Typography>
            <Typography variant='caption' color='textSecondary'>
              {useDateTimeDisplay(email.sentAt)}
            </Typography>
          </ShowTitleContainer>
          <ShowToText variant='body1'>to {email.to.join(', ')}</ShowToText>
          <Typography variant='body2' color='textSecondary'>
            from {email.from}
          </Typography>
          <ShowBodyText variant='body1'>{email.body}</ShowBodyText>
          <Divider />
          <ShowAttachmentsContainer>
            {email.attachments.map((attachment) => (
              <Attachment key={attachment.name} attachment={attachment} />
            ))}
          </ShowAttachmentsContainer>
        </ShowRoot>
      </SlideInDrawer>
    </>
  )
}

const fragment = graphql`
  fragment EmailItem_email on Email {
    slug
    subject
    from
    to
    body
    sentAt
    attachments {
      name
      ...SmallAttachment_attachment
      ...Attachment_attachment
    }
  }
`

export default EmailItem
