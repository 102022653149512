/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type FormFieldTypeEnum = "array" | "boolean" | "boolean_switch" | "container" | "container_image_slug" | "date" | "datetime" | "doc_slug" | "file_slug" | "image_slug" | "linked_contacts" | "multi_select" | "number" | "parent_shipment" | "port" | "rate_card" | "rich_text" | "searchable" | "shipment_image_slug" | "shipping_line" | "string" | "vessel" | "voyage" | "%future added value";
export type ExternalContainerEditFormQueryVariables = {
    slug: string;
};
export type ExternalContainerEditFormQueryResponse = {
    readonly infoUpdateContainerForm: {
        readonly formSchema: {
            readonly fieldSet: ReadonlyArray<{
                readonly name: string;
                readonly type: FormFieldTypeEnum;
                readonly required: boolean;
                readonly title: string | null;
                readonly description: string | null;
                readonly format: string | null;
                readonly enum: ReadonlyArray<string | null> | null;
                readonly enumTitles: ReadonlyArray<string | null> | null;
                readonly placeholder: string | null;
            }>;
        };
        readonly initialValue: {
            readonly slug: string;
            readonly containerNumber: string;
            readonly containerSize: string | null;
            readonly profileImageSlug: string | null;
            readonly voyageSlug: string;
            readonly containerType: string;
            readonly atb: string | null;
            readonly bookingNumber: string | null;
            readonly pickupLocationSlug: string | null;
            readonly turninLocationSlug: string | null;
            readonly terminalDate: string | null;
            readonly dischargeDate: string | null;
            readonly latestPickupDate: string | null;
            readonly pickupDate: string | null;
            readonly thcPaidDate: string | null;
            readonly latestTurninDate: string | null;
            readonly pickupReference: string | null;
            readonly turninReference: string | null;
            readonly billOfLadingNumber: string | null;
            readonly shippingLineSlug: string | null;
            readonly linkedContacts: {
                readonly nodes: ReadonlyArray<{
                    readonly role: string;
                    readonly contactSlug: string;
                } | null> | null;
            };
        } | null;
    };
};
export type ExternalContainerEditFormQuery = {
    readonly response: ExternalContainerEditFormQueryResponse;
    readonly variables: ExternalContainerEditFormQueryVariables;
};



/*
query ExternalContainerEditFormQuery(
  $slug: String!
) {
  infoUpdateContainerForm(slug: $slug) {
    formSchema {
      fieldSet {
        name
        type
        required
        title
        description
        format
        enum
        enumTitles
        placeholder
      }
    }
    initialValue {
      slug
      containerNumber
      containerSize
      profileImageSlug
      voyageSlug
      containerType
      atb
      bookingNumber
      pickupLocationSlug
      turninLocationSlug
      terminalDate
      dischargeDate
      latestPickupDate
      pickupDate
      thcPaidDate
      latestTurninDate
      pickupReference
      turninReference
      billOfLadingNumber
      shippingLineSlug
      linkedContacts {
        nodes {
          role
          contactSlug
        }
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "FormSchema",
  "kind": "LinkedField",
  "name": "formSchema",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "FormField",
      "kind": "LinkedField",
      "name": "fieldSet",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "required",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "format",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "enum",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "enumTitles",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "placeholder",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "containerNumber",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "containerSize",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profileImageSlug",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voyageSlug",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "containerType",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "atb",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bookingNumber",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pickupLocationSlug",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "turninLocationSlug",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "terminalDate",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dischargeDate",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "latestPickupDate",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pickupDate",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thcPaidDate",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "latestTurninDate",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pickupReference",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "turninReference",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "billOfLadingNumber",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shippingLineSlug",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "concreteType": "ContactLinkConnection",
  "kind": "LinkedField",
  "name": "linkedContacts",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ContactLink",
      "kind": "LinkedField",
      "name": "nodes",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "role",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "contactSlug",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ExternalContainerEditFormQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "InfoUpdateContainerFormResponse",
        "kind": "LinkedField",
        "name": "infoUpdateContainerForm",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Container",
            "kind": "LinkedField",
            "name": "initialValue",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/),
              (v23/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ExternalContainerEditFormQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "InfoUpdateContainerFormResponse",
        "kind": "LinkedField",
        "name": "infoUpdateContainerForm",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Container",
            "kind": "LinkedField",
            "name": "initialValue",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/),
              (v23/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0970cfce5d95a1039f5dd43e133c2596",
    "id": null,
    "metadata": {},
    "name": "ExternalContainerEditFormQuery",
    "operationKind": "query",
    "text": "query ExternalContainerEditFormQuery(\n  $slug: String!\n) {\n  infoUpdateContainerForm(slug: $slug) {\n    formSchema {\n      fieldSet {\n        name\n        type\n        required\n        title\n        description\n        format\n        enum\n        enumTitles\n        placeholder\n      }\n    }\n    initialValue {\n      slug\n      containerNumber\n      containerSize\n      profileImageSlug\n      voyageSlug\n      containerType\n      atb\n      bookingNumber\n      pickupLocationSlug\n      turninLocationSlug\n      terminalDate\n      dischargeDate\n      latestPickupDate\n      pickupDate\n      thcPaidDate\n      latestTurninDate\n      pickupReference\n      turninReference\n      billOfLadingNumber\n      shippingLineSlug\n      linkedContacts {\n        nodes {\n          role\n          contactSlug\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '5ec15da4f7d367980e6ba7d74ed8fd03';
export default node;
