/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type FeedAttachments_data = {
    readonly nodes: ReadonlyArray<{
        readonly slug: string;
        readonly " $fragmentRefs": FragmentRefs<"SmallAttachment_attachment">;
    } | null> | null;
    readonly " $refType": "FeedAttachments_data";
};
export type FeedAttachments_data$data = FeedAttachments_data;
export type FeedAttachments_data$key = {
    readonly " $data"?: FeedAttachments_data$data;
    readonly " $fragmentRefs": FragmentRefs<"FeedAttachments_data">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeedAttachments_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Attachment",
      "kind": "LinkedField",
      "name": "nodes",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SmallAttachment_attachment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AttachmentConnection",
  "abstractKey": null
};
(node as any).hash = '1c0bf405489ce6cb33843214c7f8e5ae';
export default node;
