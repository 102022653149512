import React, { Suspense, useContext } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import Appbar from '../components/Appbar'
import useWorkspace from '../hooks/useWorkspace'
import ContainerShow from '../pages/ExternalContainer/ContainerShow'
import Dashboard from '../pages/ExternalDashboard/ExternalDashboard'
import Profile from '../pages/Profile/Profile'
import ShipmentShow from '../pages/ExternalShipment/ShipmentShow'
import { media } from '../theme'
import { ErrorBoundaryWithBox } from '../utils/ErrorBoundary'
import ItemList from '../pages/ExternalItemList/ExternalItemList'
import TicketShow from '../pages/ExternalTicket/ExternalTicketShow'
import TicketAdd from '../pages/ExternalTicket/ExternalTicketAdd'
import SessionContext from '../SessionContext'
import ExternalContactShow from '../pages/ExternalContact/ExternalContactShow'
import ExternalShipmentsTable from '../pages/v2/ExternalShipmentTable'

interface AppContentProps {
  subNavIsActive?: boolean
}

const AppContent = styled.div<AppContentProps>`
  width: 100%;
  height: ${(props) => (props.subNavIsActive ? `calc(100vh - 64px - 48px)` : `calc(100vh - 64px)`)};
  margin-top: ${(props) => (props.subNavIsActive ? `calc(64px + 48px)` : '64px')};
  overflow-y: auto;

  ${(props) => media.small`
    height: calc(100vh - 56px);
    margin-top: ${props.subNavIsActive ? `calc(56px + 48px)` : '56px'};
  `}
`

export interface NavItemT {
  label: string
  path: string
  activePath?: RegExp
}

interface Props {
  roles?: []
}

const External: React.FC<Props> = ({ roles = [] }) => {
  const [workspace] = useWorkspace()
  const { user } = useContext(SessionContext)
  const location = useLocation()
  const currentPath = location.pathname.split('/').slice(2).join('/')

  const navItems: NavItemT[] = [
    { label: 'Home', path: '/', activePath: /^$/ },
    { label: 'Shipments', path: '/shipments', activePath: /^shipment/ },
    { label: 'Containers', path: '/containers', activePath: /^container/ },
    { label: 'Contacts', path: '/contacts', activePath: /^contacts/ },
    { label: 'Tickets', path: '/tickets', activePath: /^tickets/ },
  ]

  const activeNavLabel = navItems.filter((item) => item.activePath?.test(currentPath))[0]?.label || ''

  return (
    <>
      <Appbar
        navItems={navItems}
        subNavItems={[]}
        activeNavLabel={activeNavLabel}
        activeSubNavLabel=''
        workspace={workspace}
        showSearch={true}
      />
      <AppContent subNavIsActive={false}>
        <ErrorBoundaryWithBox>
          <Suspense fallback={<>Loading...</>}>
            <Routes>
              <Route path='/:workspace'>
                <Route path='/@:handle' element={<Dashboard />} />
                <Navigate to={`/${workspace}/@${user?.username}`} />
                <Route path='/search' element={<ItemList type='item' title='Search' />} />
                <Route path='/containers' element={<ItemList type='containers' title='Containers' entity='Container' roles={roles} />} />
                <Route path='/container/:slug'>
                  <Route path='/:tab' element={<ContainerShow />} />
                  <Route path='' element={<Navigate to='home' replace={true} />} />
                </Route>

                <Route path='/shipments' element={<ItemList type='shipments' title='Shipments' entity='Shipment' roles={roles} />} />
                <Route path='/contacts' element={<Navigate to={`/${workspace}/contacts/external`} replace />} />

                <Route path='/shipment/:slug'>
                  <Route path='/:tab' element={<ShipmentShow />} />
                  <Route path='' element={<Navigate to='home' replace={true} />} />
                </Route>

                <Route
                  path='/tickets'
                  element={
                    <ItemList
                      type='tickets'
                      title='Tickets'
                      entity='Tickets'
                      description='Requests raised by Team Portagent or External Users'
                      addItemUrl={`/${workspace}/tickets/add`}
                      roles={roles}
                    />
                  }
                />
                <Route
                  path='/contacts/external'
                  element={<ItemList type='external_contacts' title='External Contacts' entity='External Contact' />}
                />

                <Route path='/ticket/:slug/:tab' element={<TicketShow />} />
                <Route path='/shipments/v3' element={<ExternalShipmentsTable />} />
                <Route
                  path='/tickets/add'
                  element={
                    <Suspense fallback={<TicketAdd.Skeleton />}>
                      <TicketAdd />
                    </Suspense>
                  }
                />
                <Route path='/contact/:slug'>
                  <Route path='/:tab' element={<ExternalContactShow />} />
                  <Route path='' element={<Navigate to='home' replace={true} />} />
                </Route>
                <Route path='/profile' element={<Profile />} />
              </Route>
            </Routes>
          </Suspense>
        </ErrorBoundaryWithBox>
      </AppContent>
    </>
  )
}

export default External
