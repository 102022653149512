import React, { useEffect, useRef, useState } from 'react'
import useWorkspaceNavigate from '../../hooks/useWorkspaceNavigate'
import Bar from './Bar'
import BarH from './BarH'
import Pie from './Pie'
import RevenueBar from './RevenueBar'
import { Data, RevenueData } from './types'

interface CommonProps {
  prefix?: string
  colors?: readonly string[] | null
  roughness?: number | null
  strokeWidth?: number | null
  onClick?: (path: string) => void

  [key: string]: any
}

interface RegularProps extends CommonProps {
  data: Data
}

interface RevenueBarProps extends CommonProps {
  data: RevenueData
}

let lastId = 0

const generateId = (prefix: string): string => `${prefix}${'' + ++lastId}`

const wrap =
  <T extends CommonProps>(rvComp: any): React.FC<T> =>
  ({ prefix, ...props }) => {
    const ref = useRef<HTMLDivElement>(null)
    const [id] = useState(generateId(prefix || 'roughviz'))
    useEffect(() => {
      const { current: node } = ref
      if (node) {
        new rvComp({
          element: '#' + id,
          ...props,
        })
      }
      return () => {
        if (node) {
          while (node.firstChild) {
            node.removeChild(node.firstChild)
          }
        }
      }
    }, [id, props, ref])

    return <div style={{ position: 'relative' }} id={id} ref={ref} />
  }

export const WrappedBar = wrap<RegularProps>(Bar)
export const WrappedBarH = wrap<RegularProps>(BarH)
export const WrappedPie = wrap<RegularProps>(Pie)
export const WrappedRevenueBar = wrap<RevenueBarProps>(RevenueBar)

export const PieChart: React.FC<RegularProps> = ({ data, colors, roughness, strokeWidth }) => {
  const wsNavigate = useWorkspaceNavigate()
  return (
    <WrappedPie
      data={data}
      colors={colors || ['red', 'orange', 'blue', 'skyblue']}
      roughness={roughness || 1}
      strokeWidth={strokeWidth || 0.75}
      onClick={(path) => wsNavigate(path)}
      radius={250 / 2}
      fillWeight={1.5}
    />
  )
}

export const BarChart: React.FC<RegularProps & { xLabel?: string; yLabel?: string }> = ({
  data,
  colors,
  roughness,
  strokeWidth,
  ...restProps
}) => {
  const wsNavigate = useWorkspaceNavigate()

  return (
    <WrappedBar
      data={data}
      colors={colors || ['red', 'orange', 'blue', 'skyblue']}
      roughness={roughness || 1}
      strokeWidth={strokeWidth || 0.75}
      onClick={(path) => wsNavigate(path)}
      {...restProps}
    />
  )
}

export const BarHChart: React.FC<RegularProps> = ({ data, colors, roughness, strokeWidth }) => {
  const wsNavigate = useWorkspaceNavigate()

  return (
    <WrappedBarH
      data={data}
      colors={colors || ['red', 'orange', 'blue', 'skyblue']}
      roughness={roughness || 1}
      strokeWidth={strokeWidth || 0.75}
      onClick={(path) => wsNavigate(path)}
    />
  )
}

export const RevenueBarChart: React.FC<RevenueBarProps & { xLabel?: string; yLabel?: string }> = ({
  data,
  colors,
  roughness,
  strokeWidth,
  ...restProps
}) => {
  const wsNavigate = useWorkspaceNavigate()

  return (
    <WrappedRevenueBar
      data={data}
      colors={colors || ['red', 'orange', 'blue', 'skyblue']}
      roughness={roughness || 1}
      strokeWidth={strokeWidth || 0.75}
      onClick={(path) => wsNavigate(path)}
      {...restProps}
    />
  )
}
