/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ImageInputFieldQueryVariables = {
    slug?: string | null;
};
export type ImageInputFieldQueryResponse = {
    readonly attachmentQuery: {
        readonly url: string;
    } | null;
};
export type ImageInputFieldQuery = {
    readonly response: ImageInputFieldQueryResponse;
    readonly variables: ImageInputFieldQueryVariables;
};



/*
query ImageInputFieldQuery(
  $slug: String
) {
  attachmentQuery(slug: $slug) {
    url
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "slug",
        "variableName": "slug"
      }
    ],
    "concreteType": "Attachment",
    "kind": "LinkedField",
    "name": "attachmentQuery",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ImageInputFieldQuery",
    "selections": (v1/*: any*/),
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ImageInputFieldQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b40ae0584cf3ce45343a0767bca443be",
    "id": null,
    "metadata": {},
    "name": "ImageInputFieldQuery",
    "operationKind": "query",
    "text": "query ImageInputFieldQuery(\n  $slug: String\n) {\n  attachmentQuery(slug: $slug) {\n    url\n  }\n}\n"
  }
};
})();
(node as any).hash = '6e06db96a46106e7539293b28223ebe6';
export default node;
