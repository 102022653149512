import React, { unstable_useTransition } from 'react'
import { Button as UIButton, Checkbox, IconButton, Typography, lighten } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import styled from 'styled-components'
import { graphql } from 'babel-plugin-relay/macro'
import { useFragment, useLazyLoadQuery, useRelayEnvironment } from 'react-relay/hooks'
import { useLocation, useNavigate } from 'react-router'
import useWorkspaceNavigate from '../../hooks/useWorkspaceNavigate'
import { parse, stringify } from 'qs'
import { Pagination } from '@material-ui/lab'
import { ArrowDropDown, ArrowDropUp, Description } from '@material-ui/icons'
import { useState } from 'react'
import SearchShipments from '../../components/SearchShipments'
import { UnlinkedTaxAssessmentsQuery } from '../../__generated__/UnlinkedTaxAssessmentsQuery.graphql'
import Button from '../../components/Button'
import { UnlinkedTaxAssessmentsWithParams_list$key } from '../../__generated__/UnlinkedTaxAssessmentsWithParams_list.graphql'
import { Skeleton } from '@material-ui/lab'
import { media } from '../../theme'
import { UnlinkedTaxAssessmentsVerifyMutation } from '../../__generated__/UnlinkedTaxAssessmentsVerifyMutation.graphql'
import useNiceMutation from '../../mutations/useNiceMutation'
import { useSnackbar } from 'notistack'
import LoadingDots from '../../components/LoadingDots'
import { fetchQuery } from 'react-relay'
import { useDateTimeDisplay } from '../../hooks/timeZoneDisplay'

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableHeader: {
    fontWeight: 700,
  },
})

const Root = styled.div`
  width: 1660px;
  margin: auto;
  margin-top: 8px;

  ${media.medium`
    width: 1140px;
  `}

  ${media.small`
    width: 100%;
    min-width: 360px;
    max-width: 625px;
  `}
`

const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 8px;
`
const NoResultsText = styled(Typography)`
  color: ${(props) => props.theme.palette.grey[300]};
  text-align: center;
  padding: 32px;
  padding-bottom: 8px;
  font-weight: 700;
`

const StyledIconButton = styled(UIButton)<{ $active?: boolean }>`
  background-color: ${(props) => (props.$active ? lighten(props.theme.palette.success.main, 1) : undefined)};
  &:hover {
    background-color: ${(props) => (props.$active ? lighten(props.theme.palette.success.main, 0.8) : undefined)};
  }
  color: ${(props) => (props.$active ? props.theme.palette.warning.main : undefined)};
`

const UnlinkedTaxAssessments: React.FC & { Skeleton: React.FC } = () => {
  const classes = useStyles()
  const location = useLocation()
  const navigate = useNavigate()
  const wsNavigate = useWorkspaceNavigate()
  const [startTransition] = unstable_useTransition()
  const queryVariables = parse(location.search, { ignoreQueryPrefix: true })
  const page = Number.parseInt(queryVariables.page as string) || 1
  const perPage = Number.parseInt(queryVariables.perPage as string) || 15
  const mode = (queryVariables.mode as string) || localStorage.getItem('unlinkedMode') || 'null'
  const changePageTo = (newPage: number) => wsNavigate.relative({ page: newPage, mode: mode })
  const [sort, setSort] = useState(queryVariables.created_at || 'desc')

  const handleSort = (sort: string) => {
    const tempVars = Object.assign({}, queryVariables)
    if (sort === 'desc') {
      tempVars['created'] = 'asc'
      setSort('asc')
    } else if (sort === 'asc') {
      tempVars['created'] = 'desc'
      setSort('desc')
    }
    startTransition(() => {
      navigate(`?${stringify(tempVars)}`)
    })
  }
  const data = useLazyLoadQuery<UnlinkedTaxAssessmentsQuery>(query, {
    page: page,
    perPage: perPage,
    mode: mode as string,
    sort: sort as string,
  })

  const handleAction = (action: string | null) => {
    localStorage.setItem('unlinkedMode', action as string)
    wsNavigate.relative({ page: 1, mode: action })
  }

  return (
    <Root>
      <div>
        <StyledIconButton $active={mode == 'null' ? true : false}>
          <UIButton color='inherit' size='small' onClick={() => handleAction('null')}>
            All
          </UIButton>
        </StyledIconButton>
        <StyledIconButton $active={mode == 'ATB' ? true : false}>
          <UIButton color='inherit' size='small' onClick={() => handleAction('ATB')}>
            ATB
          </UIButton>
        </StyledIconButton>
        <StyledIconButton $active={mode == 'ATC' ? true : false}>
          <UIButton color='inherit' size='small' onClick={() => handleAction('ATC')}>
            ATC
          </UIButton>
        </StyledIconButton>
        <StyledIconButton $active={mode == 'T1' ? true : false}>
          <UIButton color='inherit' size='small' onClick={() => handleAction('T1')}>
            T1
          </UIButton>
        </StyledIconButton>
        <StyledIconButton $active={mode == 'Others' ? true : false}>
          <UIButton color='inherit' size='small' onClick={() => handleAction('Others')}>
            Others
          </UIButton>
        </StyledIconButton>
      </div>

      <TableContainer component={Paper}>
        <Table className={classes.table} size='medium' aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeader}>Name</TableCell>
              <TableCell className={classes.tableHeader}>File</TableCell>
              <TableCell className={classes.tableHeader} onClick={() => handleSort(sort as string)}>
                Created
                <span>{sort == 'asc' ? <ArrowDropDown /> : <ArrowDropUp />}</span>
              </TableCell>
              <TableCell className={classes.tableHeader}>Action</TableCell>
              <TableCell className={classes.tableHeader}>Verified</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <UnlinkedTaxAssessmentsWithParams
              page={page}
              perPage={perPage}
              onPageChange={(newPage) => changePageTo(newPage)}
              list={data.unlinkedTaxAssessmentList}
              sort={sort as string}
              mode={mode as string}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </Root>
  )
}
interface Params {
  page: number
  perPage: number
  onPageChange: (page: number) => void
  list: UnlinkedTaxAssessmentsWithParams_list$key
  sort: string
  mode: string | null
}

const UnlinkedTaxAssessmentsWithParams: React.FC<Params> = ({ page, onPageChange, list: data, sort, mode }) => {
  const list = useFragment(fragmentTaxAssessment, data)
  const [openSearchDrawer, setOpenSearchDrawer] = useState(false)
  const [selectFile, setSelectFile] = useState<string>('')
  const [commit, loading] = useNiceMutation<UnlinkedTaxAssessmentsVerifyMutation>(verifyMutation)
  const { enqueueSnackbar } = useSnackbar()
  const environment = useRelayEnvironment()

  const refresh = () => {
    fetchQuery(environment, query, { page: page, perPage: 15, mode: mode, sort: sort })
  }

  const handleChange = (slug: string, checked: boolean) => {
    const status = checked ? 'Unverified' : 'Verified'

    commit({
      variables: { input: { slug: slug } },
      onCompleted: (_, errors) => {
        if (!errors) {
          enqueueSnackbar(`Document Successfully ${status}`, { variant: 'success' })
          refresh()
        }
      },
    })
  }

  return (
    <>
      {openSearchDrawer && <SearchShipments open={openSearchDrawer} onClose={() => setOpenSearchDrawer(false)} selectFile={selectFile} />}
      {list.unlinkedTaxAssessmentFiles?.length === 0 ? (
        <TableRow>
          <TableCell colSpan={4}>
            <NoResultsText variant='h5'>No results</NoResultsText>
          </TableCell>
        </TableRow>
      ) : (
        list.unlinkedTaxAssessmentFiles?.map((log) => (
          <>
            <TableRow key={log.slug}>
              <TableCell>{log.name}</TableCell>
              <TableCell>
                <IconButton onClick={() => window.open(log.callResponseUrl as string)} disabled={!log.callResponseUrl}>
                  <Description />
                </IconButton>
              </TableCell>
              <TableCell>{useDateTimeDisplay(log.createdAt)}</TableCell>
              <TableCell>
                <Button
                  onClick={() => {
                    setSelectFile(log.slug)
                    setOpenSearchDrawer(true)
                  }}
                >
                  Link
                </Button>
              </TableCell>
              <TableCell>
                {loading ? <LoadingDots /> : <Checkbox checked={log.verified} onChange={() => handleChange(log.slug, log.verified)} />}
              </TableCell>
            </TableRow>
          </>
        ))
      )}
      {list.paginationMeta && list.paginationMeta.totalPages > 1 && (
        <TableRow>
          <TableCell colSpan={5}>
            <PaginationContainer>
              <Pagination shape='rounded' count={list.paginationMeta.totalPages} page={page} onChange={(_, newPage) => onPageChange(newPage)} />
            </PaginationContainer>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}

const query = graphql`
  query UnlinkedTaxAssessmentsQuery($page: Int, $perPage: Int, $mode: String, $sort: String!) {
    unlinkedTaxAssessmentList(page: $page, perPage: $perPage, mode: $mode, sort: $sort) {
      ...UnlinkedTaxAssessmentsWithParams_list
    }
  }
`
const fragmentTaxAssessment = graphql`
  fragment UnlinkedTaxAssessmentsWithParams_list on UnlinkedTaxAssessmentList {
    unlinkedTaxAssessmentFiles {
      slug
      name
      createdAt
      callResponseUrl
      verified
    }
    paginationMeta {
      totalPages
    }
  }
`

const verifyMutation = graphql`
  mutation UnlinkedTaxAssessmentsVerifyMutation($input: infoDocumentVerifyInput!) {
    infoDocumentVerify(input: $input) {
      clientMutationId
    }
  }
`

UnlinkedTaxAssessments.Skeleton = () => (
  <Root>
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <Skeleton variant='rect' height='50px'></Skeleton>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <Skeleton variant='rect' height='80px'></Skeleton>
          </TableRow>
          <TableRow>
            <Skeleton variant='rect' height='80px'></Skeleton>
          </TableRow>
          <TableRow>
            <Skeleton variant='rect' height='80px'></Skeleton>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  </Root>
)
export default UnlinkedTaxAssessments
