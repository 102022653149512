/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type EmailItem_email = {
    readonly slug: string;
    readonly subject: string;
    readonly from: string;
    readonly to: ReadonlyArray<string>;
    readonly body: string;
    readonly sentAt: string;
    readonly attachments: ReadonlyArray<{
        readonly name: string;
        readonly " $fragmentRefs": FragmentRefs<"SmallAttachment_attachment" | "Attachment_attachment">;
    }>;
    readonly " $refType": "EmailItem_email";
};
export type EmailItem_email$data = EmailItem_email;
export type EmailItem_email$key = {
    readonly " $data"?: EmailItem_email$data;
    readonly " $fragmentRefs": FragmentRefs<"EmailItem_email">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EmailItem_email",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subject",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "from",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "to",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "body",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sentAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Attachment",
      "kind": "LinkedField",
      "name": "attachments",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SmallAttachment_attachment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Attachment_attachment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Email",
  "abstractKey": null
};
(node as any).hash = 'f743aa7295fb8cd7647bebe2254a6f93';
export default node;
