import { createContext } from 'react'
import { LayoutOrchestratorSessionQuery } from './__generated__/LayoutOrchestratorSessionQuery.graphql'

export interface SessionType {
  user: LayoutOrchestratorSessionQuery['response']['sessionMe'] | null
  isLoggedIn: boolean
  reloadSession: () => void
}

const initialSession: SessionType = {
  user: null,
  reloadSession: () => {
    /* empty */
  },
  isLoggedIn: false,
}

const SessionContext = createContext<SessionType>(initialSession)

export default SessionContext
