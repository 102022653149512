import React, { Suspense, useState } from 'react'
import { graphql } from 'babel-plugin-relay/macro'

import { DialogTitle, DialogContent, InputAdornment } from '@material-ui/core'
import { useLazyLoadQuery } from 'react-relay/hooks'
import { Skeleton, Alert } from '@material-ui/lab'
import { Form } from 'formik'
import styled from 'styled-components'
import { EditContainerFinanceDialogQuery } from '../../__generated__/EditContainerFinanceDialogQuery.graphql'
import useNiceMutation from '../../mutations/useNiceMutation'
import InputField from '../../components/Form/InputField'
import { media } from '../../theme'
import NiceFormik from '../../components/Form/NiceFormik'
import { EditContainerFinanceDialogMutation } from '../../__generated__/EditContainerFinanceDialogMutation.graphql'
import DialogFormActionBar from '../../components/Form/DialogFormActionBar'
import OdysseyDialog from '../../components/OdysseyDialog'
interface Props {
  containerSlug: string
  open: boolean
  title: string
  onClose: () => void
  onEdit: () => void
}

const StyledForm = styled(Form)`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`
const FieldsContainer = styled.div`
  margin-bottom: 16px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;

  ${media.medium`
    grid-template-columns: repeat(1, 1fr);
  `}

  ${media.small`
    grid-template-columns: auto;
  `}
`

const EditContainerFinanceDialog: React.FC<Props> = (props) => {
  return (
    <OdysseyDialog
      open={props.open}
      maxWidth='sm'
      fullWidth
      onClose={props.onClose}
      scroll='paper'
      style={{ maxWidth: '640px', margin: 'auto' }}
    >
      <Suspense fallback={<FormSkeleton {...props} />}>
        <UpdateForm {...props} />
      </Suspense>
    </OdysseyDialog>
  )
}

const UpdateForm: React.FC<Props> = ({ containerSlug, title, onClose, onEdit }) => {
  const data = useLazyLoadQuery<EditContainerFinanceDialogQuery>(query, { slug: containerSlug })
  const financeFields = [
    'costStorage',
    'costDemurrage',
    'costTransport',
    'costXray',
    'costThc',
    'costOther',
    'costCommission',
    'costCleaning',
    'estimatedCostStorage',
    'estimatedCostDemurrage',
  ]
  const fields = data.infoUpdateContainerFinanceForm.formSchema.fieldSet
  const fieldMaster = Object.fromEntries(fields.map((field) => [field.name, field]))
  const [mutationError, setMutationError] = useState<null | string>(null)
  const [commit] = useNiceMutation<EditContainerFinanceDialogMutation>(mutation)
  return (
    <NiceFormik
      initialValues={data.infoUpdateContainerFinanceForm.initialValue || {}}
      onSubmit={(values, actions) => {
        const financeValues = Object.fromEntries(Object.entries(values).map(([key, value]) => (value === '' ? [key, null] : [key, value])))
        commit({
          variables: {
            input: financeValues as any,
          },
          onError: () => actions.setSubmitting(false),
          onCompleted: (res, errors) => {
            actions.setSubmitting(false)
            if (errors) {
              setMutationError(errors.map((err) => err.message).join(', '))
            } else {
              onEdit()
              onClose()
            }
          },
        })
      }}
      formSchema={fields}
    >
      {({ submitForm }) => (
        <StyledForm>
          <DialogTitle>{title}</DialogTitle>
          {mutationError && <Alert severity='error'>{mutationError}</Alert>}
          <DialogContent dividers={true}>
            <FieldsContainer>
              {financeFields.map((keyField) => (
                <InputField
                  key={keyField}
                  of={keyField}
                  field={fieldMaster[keyField]}
                  InputProps={{
                    endAdornment: <InputAdornment position='end'>€</InputAdornment>,
                  }}
                />
              ))}
            </FieldsContainer>
          </DialogContent>
          <DialogFormActionBar
            onCancel={() => onClose()}
            onSubmit={() => {
              submitForm()
            }}
            cancelCta='Cancel'
            saveCta='Save'
          />
        </StyledForm>
      )}
    </NiceFormik>
  )
}

const FormSkeleton: React.FC<Props> = ({ title, onClose }) => (
  <>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <Skeleton variant='rect' height='50px' />
      <Skeleton variant='rect' height='50px' style={{ marginTop: '8px' }} />
    </DialogContent>
    <DialogFormActionBar.Skeleton onCancel={onClose} />
  </>
)

const mutation = graphql`
  mutation EditContainerFinanceDialogMutation($input: infoUpdateContainerFinanceInput!) {
    infoUpdateContainerFinance(input: $input) {
      container {
        slug
      }
    }
  }
`

const query = graphql`
  query EditContainerFinanceDialogQuery($slug: String!) {
    infoUpdateContainerFinanceForm(slug: $slug) {
      formSchema {
        fieldSet {
          name
          type
          required
          title
          description
          format
          enum
          enumTitles
        }
      }
      initialValue {
        slug
        costCleaning
        costCommission
        costDemurrage
        costOther
        costStorage
        costThc
        costTransport
        costXray
        estimatedCostDemurrage
        estimatedCostStorage
      }
    }
  }
`

export default EditContainerFinanceDialog
