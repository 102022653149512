/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type infoShipmentBulkActionsInput = {
    clientMutationId?: string | null;
    shipments: Array<BulkUpdateShipmentInput>;
    action: string;
};
export type BulkUpdateShipmentInput = {
    slug: string;
    containerSlug?: string | null;
    dischargeDate?: string | null;
    shipperReleaseCheck?: boolean | null;
    finalReleaseCheck?: boolean | null;
    stage?: string | null;
    expectedHandoverDate?: string | null;
    costWarehousing?: number | null;
    revenueWarehousing?: number | null;
    costDischarge?: number | null;
    revenueDischarge?: number | null;
    costTransport?: number | null;
    revenueTransport?: number | null;
    costCustomsInspection?: number | null;
    revenueCustomsInspection?: number | null;
    costClimaCertificate?: number | null;
    revenueClimaCertificate?: number | null;
    costOpinion?: number | null;
    revenueOpinion?: number | null;
    costContainerXray?: number | null;
    revenueContainerXray?: number | null;
    costContainerHandling?: number | null;
    revenueContainerHandling?: number | null;
    costShipping?: number | null;
    revenueShipping?: number | null;
    costCleaning?: number | null;
    revenueCleaning?: number | null;
    costSpecialHandling?: number | null;
    revenueSpecialHandling?: number | null;
    costStorage?: number | null;
    revenueStorage?: number | null;
    costForklift?: number | null;
    revenueForklift?: number | null;
    costOther?: number | null;
    revenueCustomsClearance?: number | null;
    customsDuties?: number | null;
    customsVat?: number | null;
    discount?: number | null;
    discountRemark?: string | null;
    receivedPayment?: number | null;
    linkedContacts?: Array<LinkedContactInput> | null;
};
export type LinkedContactInput = {
    role: string;
    contactSlug: string;
};
export type ShipmentsTableShipmentBulkActionsMutationVariables = {
    input: infoShipmentBulkActionsInput;
};
export type ShipmentsTableShipmentBulkActionsMutationResponse = {
    readonly infoShipmentBulkActions: {
        readonly result: string;
    } | null;
};
export type ShipmentsTableShipmentBulkActionsMutation = {
    readonly response: ShipmentsTableShipmentBulkActionsMutationResponse;
    readonly variables: ShipmentsTableShipmentBulkActionsMutationVariables;
};



/*
mutation ShipmentsTableShipmentBulkActionsMutation(
  $input: infoShipmentBulkActionsInput!
) {
  infoShipmentBulkActions(input: $input) {
    result
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "infoShipmentBulkActionsPayload",
    "kind": "LinkedField",
    "name": "infoShipmentBulkActions",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "result",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ShipmentsTableShipmentBulkActionsMutation",
    "selections": (v1/*: any*/),
    "type": "ApplicationCommand",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ShipmentsTableShipmentBulkActionsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "98f100be389500bd216e6841977cfb99",
    "id": null,
    "metadata": {},
    "name": "ShipmentsTableShipmentBulkActionsMutation",
    "operationKind": "mutation",
    "text": "mutation ShipmentsTableShipmentBulkActionsMutation(\n  $input: infoShipmentBulkActionsInput!\n) {\n  infoShipmentBulkActions(input: $input) {\n    result\n  }\n}\n"
  }
};
})();
(node as any).hash = 'bf6b769c27ccfb4faed00ffed0abef0d';
export default node;
