/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type AttachmentEntityTypeEnum = "contacts" | "containers" | "shipments" | "%future added value";
export type infoDeleteAttachmentInput = {
    clientMutationId?: string | null;
    entityType: AttachmentEntityTypeEnum;
    entitySlug: string;
    attachmentSlug: string;
};
export type EntityAttachmentListPreviewerDeleteMutationVariables = {
    input: infoDeleteAttachmentInput;
};
export type EntityAttachmentListPreviewerDeleteMutationResponse = {
    readonly infoDeleteAttachment: {
        readonly clientMutationId: string | null;
    } | null;
};
export type EntityAttachmentListPreviewerDeleteMutation = {
    readonly response: EntityAttachmentListPreviewerDeleteMutationResponse;
    readonly variables: EntityAttachmentListPreviewerDeleteMutationVariables;
};



/*
mutation EntityAttachmentListPreviewerDeleteMutation(
  $input: infoDeleteAttachmentInput!
) {
  infoDeleteAttachment(input: $input) {
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "infoDeleteAttachmentPayload",
    "kind": "LinkedField",
    "name": "infoDeleteAttachment",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EntityAttachmentListPreviewerDeleteMutation",
    "selections": (v1/*: any*/),
    "type": "ApplicationCommand",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EntityAttachmentListPreviewerDeleteMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5a6f7b24212f9182b3490ae1fc4dcbec",
    "id": null,
    "metadata": {},
    "name": "EntityAttachmentListPreviewerDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation EntityAttachmentListPreviewerDeleteMutation(\n  $input: infoDeleteAttachmentInput!\n) {\n  infoDeleteAttachment(input: $input) {\n    clientMutationId\n  }\n}\n"
  }
};
})();
(node as any).hash = 'e84a9b0e54f784b5fae1b210588dd7e7';
export default node;
