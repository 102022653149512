import React, { Suspense } from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import FoldersTab from '../../components/File/FoldersTab'
import ContainerFinance from './ContainerFinance'
import { ContainerFinanceShowQuery } from '../../__generated__/ContainerFinanceShowQuery.graphql'
import ExchangeRatesCard from '../../components/Finances/ExchangeRatesCard'
import Timeline from '../../components/Timeline/Timeline'
import ResponsiveGrid from '../../components/ResponsiveGrid'
import useLazyLoadQueryWithSubscription from '../../hooks/useLazyLoadQueryWithSubscription'

const ContainerFinanceShow: React.FC<{ slug: string }> & { Skeleton: React.FC } = ({ slug }) => {
  const queryData = useLazyLoadQueryWithSubscription<ContainerFinanceShowQuery>(
    containerQuery,
    { slug },
    { subscriptionOptions: { entityType: 'containers', entitySlug: slug } }
  )

  return (
    <ResponsiveGrid
      type='show-layout'
      highlight={[<ContainerFinance key='finance' container={queryData.containerShow} />]}
      right={[
        <Suspense key='exchange-rates' fallback={<ExchangeRatesCard.Skeleton />}>
          <ExchangeRatesCard />
        </Suspense>,
        <Suspense key='timeline' fallback={<Timeline.Skeleton />}>
          <Timeline type='containers' slug={slug} group='finances' />
        </Suspense>,
      ]}
    />
  )
}

const containerQuery = graphql`
  query ContainerFinanceShowQuery($slug: String!) {
    containerShow(slug: $slug) {
      ...ContainerFinance_container
    }
  }
`

ContainerFinanceShow.Skeleton = () => <FoldersTab.Skeleton />

export default ContainerFinanceShow
