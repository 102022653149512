import React from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import { useFragment } from 'react-relay/hooks'
import { SemanticString_data$key } from '../__generated__/SemanticString_data.graphql'
import TextHighlighter, { HighlightT } from './TextHighlighter'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

interface Props {
  data: SemanticString_data$key
  highlights?: HighlightT[]
}
const useStyles = makeStyles({
  success: {
    color: 'green',
  },
})

const SemanticString: React.FC<Props> = ({ data, highlights }) => {
  const semanticString = useFragment(semanticStringFragment, data)
  const classes = useStyles()

  return (
    <>
      {semanticString.text === 'true' ? (
        <Typography className={classes.success}>✓</Typography>
      ) : semanticString.text === 'false' ? (
        <Typography color='error'>✗</Typography>
      ) : (
        <TextHighlighter highlights={highlights} textToHighlight={semanticString.text} />
      )}
    </>
  )
}

const semanticStringFragment = graphql`
  fragment SemanticString_data on SemanticString {
    text
  }
`

export default SemanticString
