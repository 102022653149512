import React from 'react'
import styled, { keyframes } from 'styled-components'

type VariantT = 'dark' | 'light' | 'primary'
interface Props {
  variant?: VariantT
}

const blink = keyframes`
  50% { opacity: 1; transform: scale(1); }
`

const Dot = styled.div<{ variant: VariantT }>`
  height: 6px;
  width: 6px;
  display: inline-block;
  border-radius: 50%;
  margin-left: 4px;
  background-color: ${(props) =>
    props.variant === 'light' ? 'white' : props.variant === 'primary' ? props.theme.palette.primary.main : '#3a3a3a'};
  opacity: 0.3;
  transform: scale(0.7);
  animation-name: ${blink};
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
`

const DotsContainer = styled.div`
  ${Dot}:nth-child(1) {
    animation-delay: 0ms;
  }

  ${Dot}:nth-child(2) {
    animation-delay: 250ms;
  }

  ${Dot}:nth-child(3) {
    animation-delay: 500ms;
  }
`

const LoadingDots: React.FC<Props> = ({ variant = 'light' }) => {
  return (
    <DotsContainer className='loading-dots'>
      <Dot variant={variant} className='loading-dots--dot' />
      <Dot variant={variant} className='loading-dots--dot' />
      <Dot variant={variant} className='loading-dots--dot' />
    </DotsContainer>
  )
}

export default LoadingDots
