import { Typography, Divider } from '@material-ui/core'
import { graphql } from 'babel-plugin-relay/macro'
import React from 'react'
import { useFragment } from 'react-relay/hooks'
import styled from 'styled-components'
import { media } from '../../theme'
import { LiveShareFolder_folder$key } from '../../__generated__/LiveShareFolder_folder.graphql'
import LiveShareEntityAttachment from './LiveShareEntityAttachment'

interface Props {
  folder: LiveShareFolder_folder$key
}

const Root = styled.div`
  margin-bottom: 16px;
`

const FilesContainer = styled.div`
  padding: 0px 4px;
  margin-top: 8px;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-auto-rows: 120px;
  grid-gap: 16px;

  ${media.small`
    grid-template-columns: repeat(3, minmax(0, 1fr));
  `}
`

const LiveShareFolder: React.FC<Props> = ({ folder: data }) => {
  const folder = useFragment(fragment, data)

  return (
    <>
      <Root>
        <Typography variant='body1'>{folder.name}</Typography>
        <FilesContainer>
          {folder.attachments.map((attachment) => (
            <LiveShareEntityAttachment key={attachment.name} attachment={attachment} />
          ))}
        </FilesContainer>
        <Divider />
      </Root>
    </>
  )
}

const fragment = graphql`
  fragment LiveShareFolder_folder on LiveShareFolder {
    slug
    name
    attachments {
      name
      isImage
      ...LiveShareEntityAttachment_attachment
    }
  }
`

export default LiveShareFolder
