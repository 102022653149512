import React, { Suspense } from 'react'
import { graphql } from 'babel-plugin-relay/macro'

import styled from 'styled-components'
import { Paper, Typography } from '@material-ui/core'
import { useLazyLoadQuery } from 'react-relay/hooks'
import { ApiLogsQuery } from '../../__generated__/ApiLogsQuery.graphql'
import ApiLogItem from '../../components/ApiLogItem'
import { useLocation } from 'react-router'
import { parse } from 'qs'
import useWorkspaceNavigate from '../../hooks/useWorkspaceNavigate'
import { Pagination } from '@material-ui/lab'

const RootPaper = styled(Paper)``
const TitleContainer = styled.div`
  padding: 16px;
`
const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 8px;
`

const ApiLogs: React.FC = () => {
  const location = useLocation()
  const wsNavigate = useWorkspaceNavigate()
  const queryVariables = parse(location.search, { ignoreQueryPrefix: true })
  const page = Number.parseInt(queryVariables.page as string) || 1
  const perPage = Number.parseInt(queryVariables.perPage as string) || 10

  const changePageTo = (newPage: number) => wsNavigate.relative({ page: newPage })

  return (
    <RootPaper variant='outlined'>
      <TitleContainer>
        <Typography variant='h5'>API Logs</Typography>
        <Typography variant='body1'>All the calls we&apos;ve made to external systems</Typography>
      </TitleContainer>
      <Suspense fallback={<ApiLogsWithParams.Skeleton />}>
        <ApiLogsWithParams page={page} perPage={perPage} onPageChange={(newPage) => changePageTo(newPage)} />
      </Suspense>
    </RootPaper>
  )
}

interface Params {
  page: number
  perPage: number
  onPageChange: (page: number) => void
}

const ApiLogsWithParams: React.FC<Params> & { Skeleton: React.FC } = ({ page, perPage, onPageChange }) => {
  const data = useLazyLoadQuery<ApiLogsQuery>(query, { page: page, perPage: perPage })

  return (
    <>
      {data.apiLogList.apiLogs?.map((log) => (
        <ApiLogItem key={log.slug} apiLog={log} />
      ))}
      {data.apiLogList.paginationMeta && data.apiLogList.paginationMeta.totalPages > 1 && (
        <PaginationContainer>
          <Pagination
            shape='rounded'
            count={data.apiLogList.paginationMeta.totalPages}
            page={page}
            onChange={(_, newPage) => onPageChange(newPage)}
          />
        </PaginationContainer>
      )}
    </>
  )
}

ApiLogsWithParams.Skeleton = () => (
  <>
    <ApiLogItem.Skeleton />
    <ApiLogItem.Skeleton />
    <ApiLogItem.Skeleton />
  </>
)

const query = graphql`
  query ApiLogsQuery($type: String, $sortKey: String, $page: Int, $perPage: Int) {
    apiLogList(type: $type, sortKey: $sortKey, page: $page, perPage: $perPage) {
      apiLogs {
        slug
        ...ApiLogItem_apiLog
      }
      paginationMeta {
        totalPages
      }
    }
  }
`

export default ApiLogs
