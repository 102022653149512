import { Avatar, Badge, Breadcrumbs, Collapse, Grid, Paper, Typography } from '@material-ui/core'
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons'
import React, { useState } from 'react'
import styled from 'styled-components'
import Stages from '../components/Stages'
import { isMobile, media } from '../theme'

const HeaderContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  background-image: url('https://images.unsplash.com/photo-1561131506-3c48296e2c20?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: rgba(0, 0, 0, 0.5);
  background-blend-mode: multiply;
`

const ShipmentInfoContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 40%;
  margin: 48px auto 16px auto;

  ${media.laptop`
    width: 70%;
  `}

  ${media.desktop`
    width: 60%;
  `}

  ${media.tablet`
    width: 80%;
  `}

  ${media.mobile`
    width: 90%;
  `}
`

const ShipmentInfo = styled(Paper)`
  width: 100%;
  padding: 16px;
`

const ShipmentInfoHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
  padding-bottom: 16px;
  margin-bottom: 16px;
`

const ShipmentAvatar = styled(Avatar)`
  cursor: pointer;
  width: 64px;
  height: 64px;
  border: 2px solid ${(props) => props.theme.palette.divider};
`

const BreadcrumbsContainer = styled.div`
  width: 100%;
  margin-bottom: 8px;
  font-size: 12px;
`

const Status = styled.div`
  width: fit-content;
  padding: 8px 16px;
  background-color: #deedff;
  color: #207acd;
  font-weight: bold;
  text-align: center;
  border-radius: 4px;

  ${media.tablet`
    width: 100%;
  `}

  ${media.mobile`
    width: 100%;
  `}
`

const Label = styled.label`
  font-size: 12px;
  color: #646464;
`

const Note = styled.div`
  background-color: #fff9c2;
  padding: 8px 16px;
  border-radius: 4px;
  margin-bottom: 8px;
`

const VIN = styled.div`
  width: fit-content;
  background-color: #e4e4e4;
  color: #646464;
  padding: 4px 8px;
  text-decoration: uppercase;
  font-weight: bold;
  border-radius: 4px;
  margin-right: 8px;
  font-size: ${(props: VINProps) => (props.size === 'small' ? '12px' : '14px')};
`

interface VINProps {
  size?: 'small' | 'default'
}

VIN.defaultProps = {
  size: 'default',
}

const ETA = styled.div`
  width: fit-content;
  background-color: #bdf0d1;
  color: #1a7734;
  padding: 4px 8px;
  text-decoration: uppercase;
  font-weight: bold;
  border-radius: 4px;
  font-size: ${(props: ETAProps) => (props.size && props.size === 'small' ? '12px' : '14px')};
`

interface ETAProps {
  size?: 'small' | 'default'
}

ETA.defaultProps = {
  size: 'default',
}

const ShipmentInfoContent = styled.div`
  position: relative;
`

const ShipmentsDropDown = styled(Collapse)`
  /* position: absolute; */
  width: 100%;
  /* height: auto; */
  /* top: -16px; */
  /* overflow-y: auto; */
  background-color: ${(props) => props.theme.palette.background.paper};
`

const BadgeContent = styled.div`
  width: 22px;
  height: 22px;
  background: #212121;
  border-radius: 50%;
  border: 2px solid white;
`

const Shipment: React.FC = () => {
  const [open, setOpen] = useState(false)
  return (
    <>
      <HeaderContainer>
        <ShipmentInfoContainer>
          <ShipmentInfo>
            <BreadcrumbsContainer>
              <Breadcrumbs itemsBeforeCollapse={isMobile() ? 0 : 1} maxItems={isMobile() ? 1 : 2} style={{ fontSize: '13px' }}>
                <Typography variant='body1' color='textSecondary' style={{ fontSize: '13px' }}>
                  MERSK2921041
                </Typography>
                <Typography variant='body1' color='textPrimary' style={{ fontSize: '13px' }}>
                  Shipment 75004 - 2017 Mazda CX5
                </Typography>
              </Breadcrumbs>
            </BreadcrumbsContainer>
            <ShipmentInfoHeader>
              <Grid container alignItems='center' style={{ padding: '8px 0' }}>
                <Grid item xs={12} md={8} lg={8}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div>
                      <Badge
                        overlap='circle'
                        style={{ marginRight: '16px' }}
                        onClick={() => setOpen(!open)}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        badgeContent={
                          <BadgeContent>
                            {open ? (
                              <ArrowDropUp style={{ color: 'white', fontSize: '18px' }} />
                            ) : (
                              <ArrowDropDown style={{ color: 'white', fontSize: '18px' }} />
                            )}
                          </BadgeContent>
                        }
                      >
                        <ShipmentAvatar
                          alt='Shipment 75004 - 2017 Mazda CX5'
                          src='https://images.unsplash.com/photo-1561132570-67e4398dba33?ixlib=rb-1.2.1&auto=format&fit=crop&w=640&q=80'
                        />
                      </Badge>
                    </div>
                    <div>
                      <Typography variant='h6' style={{ fontWeight: 'bold' }}>
                        Shipment 75004 - 2017 Mazda CX5
                      </Typography>
                      <div style={{ display: 'flex' }}>
                        <VIN>VIN: 123121312</VIN>
                        <ETA>ETA: 27 Aug 2020</ETA>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={4} lg={4} style={{ marginTop: '8px', display: 'flex', justifyContent: 'flex-end' }}>
                  <Status>In progress by custom</Status>
                </Grid>
              </Grid>
            </ShipmentInfoHeader>
            <ShipmentInfoContent>
              <ShipmentsDropDown in={open}>
                <Grid container alignItems='center' style={{ padding: '8px 0' }}>
                  <Grid item xs={2} md={1} lg={1}>
                    <Avatar
                      style={{ marginRight: '16px' }}
                      alt='Shipment 75004 - 2017 Mazda CX5'
                      src='https://images.unsplash.com/photo-1561132570-67e4398dba33?ixlib=rb-1.2.1&auto=format&fit=crop&w=640&q=80'
                    />
                  </Grid>
                  <Grid item xs={10} md={7} lg={7}>
                    <div>
                      <Typography variant='body1' style={{ fontWeight: 'bold' }}>
                        Shipment 75004 - 2017 Mazda CX5
                      </Typography>
                      <div style={{ display: 'flex' }}>
                        <VIN>VIN: 123121312</VIN>
                        <ETA>ETA: 27 Aug 2020</ETA>
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    lg={4}
                    style={{ marginTop: '8px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
                  >
                    <Status>In progress by custom</Status>
                  </Grid>
                </Grid>
                <Grid container alignItems='center'>
                  <Grid item xs={2} md={1} lg={1}>
                    <Avatar
                      style={{ marginRight: '16px' }}
                      alt='Shipment 75004 - 2017 Mazda CX5'
                      src='https://images.unsplash.com/photo-1561132570-67e4398dba33?ixlib=rb-1.2.1&auto=format&fit=crop&w=640&q=80'
                    />
                  </Grid>
                  <Grid item xs={10} md={7} lg={7}>
                    <div>
                      <Typography variant='body1' style={{ fontWeight: 'bold' }}>
                        Shipment 75004 - 2017 Mazda CX5
                      </Typography>
                      <div style={{ display: 'flex' }}>
                        <VIN>VIN: 123121312</VIN>
                        <ETA>ETA: 27 Aug 2020</ETA>
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    lg={4}
                    style={{ marginTop: '8px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
                  >
                    <Status>In progress by custom</Status>
                  </Grid>
                </Grid>
                <Grid container alignItems='center' style={{ padding: '8px 0' }}>
                  <Grid item xs={2} md={1} lg={1}>
                    <Avatar
                      style={{ marginRight: '16px' }}
                      alt='Shipment 75004 - 2017 Mazda CX5'
                      src='https://images.unsplash.com/photo-1561132570-67e4398dba33?ixlib=rb-1.2.1&auto=format&fit=crop&w=640&q=80'
                    />
                  </Grid>
                  <Grid item xs={10} md={7} lg={7}>
                    <div>
                      <Typography variant='body1' style={{ fontWeight: 'bold' }}>
                        Shipment 75004 - 2017 Mazda CX5
                      </Typography>
                      <div style={{ display: 'flex' }}>
                        <VIN>VIN: 123121312</VIN>
                        <ETA>ETA: 27 Aug 2020</ETA>
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    lg={4}
                    style={{ marginTop: '8px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
                  >
                    <Status>In progress by custom</Status>
                  </Grid>
                </Grid>
              </ShipmentsDropDown>
              <Note>
                <Typography variant='body1'>Customer has filled the POA wrongly</Typography>
                <Typography variant='subtitle2' color='textSecondary'>
                  @natalja on Thu 28th Jun
                </Typography>
              </Note>
              <Grid container>
                <Grid item xs={6} lg={4} xl={3}>
                  <Label>Shipment Type</Label>
                  <Typography variant='body1'>Car</Typography>
                </Grid>
                <Grid item xs={6} lg={4} xl={3}>
                  <Label>Container</Label>
                  <Typography variant='body1'>MERSK314124</Typography>
                </Grid>
                <Grid item xs={6} lg={4} xl={3}>
                  <Label>Shipment Key</Label>
                  <Typography variant='body1'>20210302412</Typography>
                </Grid>
                <Grid item xs={6} lg={4} xl={3}>
                  <Label>Weight</Label>
                  <Typography variant='body1'>120kg</Typography>
                </Grid>
                <Grid item xs={6} lg={4} xl={3}>
                  <Label>Shipment Type</Label>
                  <Typography variant='body1'>Car</Typography>
                </Grid>
                <Grid item xs={6} lg={4} xl={3}>
                  <Label>Shipment Type</Label>
                  <Typography variant='body1'>Car</Typography>
                </Grid>
                <Grid item xs={6} lg={4} xl={3}>
                  <Label>Shipment Type</Label>
                  <Typography variant='body1'>Car</Typography>
                </Grid>
                <Grid item xs={6} lg={4} xl={3}>
                  <Label>Shipment Type</Label>
                  <Typography variant='body1'>Car</Typography>
                </Grid>
              </Grid>
            </ShipmentInfoContent>
          </ShipmentInfo>
        </ShipmentInfoContainer>

        <Stages
          stages={[
            { text: 'Document Collection', subText: '@iwona - Completed 3 days ago', status: 'completed' },
            { text: 'Customs Clearance', subText: 'Not yet picked', status: 'active' },
            { text: 'Billing', subText: 'Not yet picked', status: 'incomplete' },
            { text: 'Handover', subText: 'Not yet picked', status: 'incomplete' },
            { text: 'Done', subText: 'Not yet picked', status: 'incomplete' },
          ]}
        />
      </HeaderContainer>
    </>
  )
}

export default Shipment
