import { graphql } from 'babel-plugin-relay/macro'
import React from 'react'
import useLazyLoadQueryWithSubscription from '../hooks/useLazyLoadQueryWithSubscription'
import { ShowPageRenderContext } from '../viewTypes'
import { ContactShowHeroCardQuery } from '../__generated__/ContactShowHeroCardQuery.graphql'
import ContactShowHeroTabBar from './ContactShowHeroTabBar'
import ShowPageItem from './ShowPageItem'
import { Title as ReactHeadTitle } from 'react-head'

interface Props {
  slug: string
  onEdit: () => void
  renderContext: ShowPageRenderContext
}

const ContactShowHeroCard: React.FC<Props> & { Skeleton: React.FC } = ({ slug, onEdit, renderContext }) => {
  const { itemShow: item, contactShow: contact } = useLazyLoadQueryWithSubscription<ContactShowHeroCardQuery>(
    query,
    { slug: slug, itemType: 'contact' },
    { subscriptionOptions: { entityType: 'contacts', entitySlug: slug } }
  )

  return (
    <>
      {renderContext === 'page' && <ReactHeadTitle>Contact - {item.title}</ReactHeadTitle>}
      <ShowPageItem data={item} />
      <ContactShowHeroTabBar contact={contact} onEdit={onEdit} />
    </>
  )
}

const query = graphql`
  query ContactShowHeroCardQuery($slug: String!, $itemType: ShowItemTypeEnum!) {
    itemShow(slug: $slug, type: $itemType) {
      title
      ...ShowPageItem_data
    }

    contactShow(slug: $slug) {
      ...ContactShowHeroTabBar_contact
    }
  }
`

ContactShowHeroCard.Skeleton = () => <>Loading...</>

export default ContactShowHeroCard
