import { Edit } from '@material-ui/icons'
import { graphql } from 'babel-plugin-relay/macro'
import React from 'react'
import styled from 'styled-components'
import useLazyLoadQueryWithSubscription from '../hooks/useLazyLoadQueryWithSubscription'
import useWorkspace from '../hooks/useWorkspace'
import { tabs } from '../pages/Vessel/tabs'
import { ShowPageRenderContext } from '../viewTypes'
import { VesselShowHeroCardQuery } from '../__generated__/VesselShowHeroCardQuery.graphql'
import Button from './Button'
import Hero from './Hero'
import ShowPageItem from './ShowPageItem'
import TabsUI from './TabsUI/TabsUI'
import { Title as ReactHeadTitle } from 'react-head'

interface Props {
  slug: string
  activeTab: string
  onEdit: () => void
  renderContext: ShowPageRenderContext
}

const TabContainer = styled.div`
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const VesselShowHeroCard: React.FC<Props> & { Skeleton: React.FC } = ({ slug, activeTab, onEdit, renderContext }) => {
  const { itemShow: item } = useLazyLoadQueryWithSubscription<VesselShowHeroCardQuery>(
    query,
    { slug: slug, itemType: 'vessel' },
    { subscriptionOptions: { entityType: 'vessels', entitySlug: slug } }
  )

  const value = tabs.findIndex((t) => t.value === activeTab)
  const [, , wsNavigate] = useWorkspace()

  const onTabChange = (e: React.ChangeEvent<unknown>, newValue: number) => {
    wsNavigate(`/vessel/${item.slug}/${tabs[newValue].value}`)
  }

  return (
    <>
      {renderContext === 'page' && <ReactHeadTitle>Vessel - {item.title}</ReactHeadTitle>}
      <Hero backgroundColor='#fafafa'>
        <ShowPageItem data={item} />
      </Hero>
      <Hero backgroundColor='transparent'>
        <TabContainer>
          <TabsUI isWhiteBackground tabs={tabs} value={value} onChange={onTabChange} />
          <Button variant='contained' startIcon={<Edit />} onClick={() => onEdit()}>
            Edit
          </Button>
        </TabContainer>
      </Hero>
    </>
  )
}

const query = graphql`
  query VesselShowHeroCardQuery($slug: String!, $itemType: ShowItemTypeEnum!) {
    itemShow(slug: $slug, type: $itemType) {
      slug
      title
      ...ShowPageItem_data
    }
  }
`

VesselShowHeroCard.Skeleton = () => <>Loading...</>

export default VesselShowHeroCard
