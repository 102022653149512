import { DialogActions, IconButton, TextField, MenuItem, DialogContent, Typography } from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import { graphql } from 'babel-plugin-relay/macro'
import React, { useContext, useState } from 'react'
import { useFragment } from 'react-relay/hooks'
import styled from 'styled-components'
import useNiceMutation from '../mutations/useNiceMutation'
import SessionContext from '../SessionContext'
import { StageStatusEnum } from '../__generated__/ListPageItem_listItem.graphql'
import { SubStageChangeMutation } from '../__generated__/SubStageChangeMutation.graphql'
import { SubStage_data$key } from '../__generated__/SubStage_data.graphql'
import Button from './Button'
import OdysseyDialog from './OdysseyDialog'

interface Props {
  entityType: 'containers' | 'shipments'
  entitySlug: string
  data: SubStage_data$key
}

const StyledStatus = styled.div<{ $status: StageStatusEnum }>`
  --step-bg-color: ${(props) => (props.$status === 'completed' ? '#BDF0D1' : props.$status === 'active' ? '#BDE4F0' : 'white')};
  color: ${(props) => (props.$status === 'completed' ? '#1A7734' : props.$status === 'active' ? '#1A4A77' : '#646464')};
  background-color: var(--step-bg-color);
  border-radius: 8px;
  font-weight: bold;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 230px;
  margin-bottom: 8px;
`

const StageIndex = styled.div<{ $status: StageStatusEnum }>`
  --step-bg-color: ${(props) => (props.$status === 'completed' ? '#8beab1' : props.$status === 'active' ? '#a0d6e6' : '#fafafa')};
  color: ${(props) => (props.$status === 'completed' ? '#1A7734' : props.$status === 'active' ? '#1A4A77' : '#646464')};
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  display: flex;
  align-items: center;
  background-color: var(--step-bg-color);
  position: relative;
  padding-left: 8px;
  height: 36px;
  margin-right: 8px;

  &:after {
    content: '';
    position: absolute;
    right: -12px;
    display: block;
    width: 0;
    height: 0;
    border-top: 16px solid transparent;
    border-bottom: 16px solid transparent;
    border-left: 12px solid var(--step-bg-color);
  }
`

const StatusEditButton = styled(IconButton)<{ $status: StageStatusEnum }>`
  color: inherit;
  visibility: ${(props) => (props.$status !== 'active' ? 'hidden' : 'visible')};
`

const StyledDialogContent = styled(DialogContent)`
  display: grid;
  grid-gap: 8px;
  grid-template-columns: auto auto;
  align-items: center;
`

const StatusDropdownTextField = styled(TextField)`
  min-width: 100px;
`

const SubStage: React.FC<Props> = ({ data, entityType, entitySlug }) => {
  const { user } = useContext(SessionContext)
  const subStage = useFragment(fragment, data)
  const [stageChangeDialogOpen, setStageChangeDialogOpen] = useState(false)
  const [selectedSubStage, setSelectedSubStage] = useState(subStage.value)
  const [stageChangeError, setStageChangeError] = useState<null | string>(null)
  const resetStageChange = () => {
    setStageChangeDialogOpen(false)
    setStageChangeError(null)
  }
  const [commitSubStageChange, subStageChangeIsInFlight] = useNiceMutation<SubStageChangeMutation>(subStageChangeMutation)
  const changeSubStage = () => {
    if (subStage.validSubStages == null) return
    if (selectedSubStage == null) return

    commitSubStageChange({
      variables: {
        input: { entityType, entitySlug, subStage: selectedSubStage },
      },
      onCompleted: (res, errors) => {
        if (errors) {
          setStageChangeError(errors.map((err) => err.message).join(', '))
        } else {
          resetStageChange()
        }
      },
    })
  }

  return (
    <>
      <StyledStatus $status={subStage.status}>
        <StageIndex $status={subStage.status}>{subStage.stageIdx}</StageIndex>
        {subStage.displayValue || '--'}
        {user?.contact?.contactType === 'internal' && (
          <StatusEditButton $status={subStage.status} size='small' onClick={() => setStageChangeDialogOpen(true)}>
            <Edit fontSize='small' />
          </StatusEditButton>
        )}
        {user?.contact?.contactType === 'external' && <div></div>}
      </StyledStatus>
      <OdysseyDialog open={stageChangeDialogOpen} onClose={resetStageChange}>
        {stageChangeError && <Alert severity='error'>{stageChangeError}</Alert>}
        <StyledDialogContent>
          <Typography variant='body1'>Set {subStage.label} to</Typography>
          <StatusDropdownTextField
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            variant='outlined'
            select
            label='Status'
            value={selectedSubStage}
            onChange={(e) => setSelectedSubStage(e.target.value)}
          >
            {subStage.validSubStages?.map((validSubStage) => (
              <MenuItem key={validSubStage.value} value={validSubStage.value}>
                {validSubStage.displayValue}
              </MenuItem>
            ))}
          </StatusDropdownTextField>
        </StyledDialogContent>
        <DialogActions>
          <Button onClick={resetStageChange}>Cancel</Button>
          <Button variant='contained' onClick={changeSubStage} disabled={subStageChangeIsInFlight}>
            Save
          </Button>
        </DialogActions>
      </OdysseyDialog>
    </>
  )
}

const fragment = graphql`
  fragment SubStage_data on SubStage {
    stageIdx
    stage
    value
    displayValue
    status
    label
    validSubStages {
      value
      displayValue
    }
  }
`

const subStageChangeMutation = graphql`
  mutation SubStageChangeMutation($input: infoChangeEntitySubStageInput!) {
    infoChangeEntitySubStage(input: $input) {
      clientMutationId
    }
  }
`

export default SubStage
