import { TextField } from '@material-ui/core'
import { AutocompleteRenderInputParams } from '@material-ui/lab'
import { graphql } from 'babel-plugin-relay/macro'
import { Field, useField } from 'formik'
import { Autocomplete } from 'formik-material-ui-lab'
import React, { useEffect, useRef, useState } from 'react'
import { fetchQuery, useRelayEnvironment } from 'react-relay/hooks'
import { ContainerFieldQuery } from '../../__generated__/ContainerFieldQuery.graphql'
import ItemImage from '../ItemImage'
import { FieldT, FieldVariantEnum } from './types'

interface Props {
  field: FieldT
  variant?: FieldVariantEnum
  disabled?: boolean
}

const ContainerField: React.FC<Props> = ({ field, disabled }) => {
  const [, meta, helpers] = useField(field.name)
  const [searchQuery, setSearchQuery] = useState(meta.value || '')
  const searchQueryRef = useRef('')
  const environment = useRelayEnvironment()
  const [options, setOptions] = useState([])

  useEffect(() => {
    fetchQuery<ContainerFieldQuery>(environment, query, {
      type: 'containers',
      searchQuery: searchQuery,
      perPage: 10,
      requiredPagination: true,
    })
      .toPromise()
      .then((data) => data?.itemList.items.edges?.map((edge) => edge?.node) || [])
      .then((opts) => setOptions(opts as never[]))
  }, [searchQuery, environment])

  const onQueryChange = (newQuery: string) => {
    searchQueryRef.current = newQuery
    setTimeout(() => {
      if (searchQueryRef.current === newQuery) {
        setSearchQuery(newQuery)
      }
    }, 300)
  }

  return (
    <Field
      name={field.name}
      disabled={disabled}
      component={Autocomplete}
      options={options}
      getOptionLabel={(option: any) => (typeof option === 'string' ? option : option.title)}
      getOptionSelected={(option: any, value: string) => option.slug === value}
      value={meta.value}
      onChange={(_: any, value: any) => (value ? helpers.setValue(value.slug) : helpers.setValue(null))}
      onInputChange={(_e: any, value: string) => onQueryChange(value)}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          error={meta.touched && !!meta.error}
          helperText={meta.touched ? meta.error : null}
          label={field.title}
          variant='outlined'
        />
      )}
      renderOption={(option: any) => (
        <>
          <ItemImage data={option.image} variant='small' />
          {option.title}
        </>
      )}
    />
  )
}

const query = graphql`
  query ContainerFieldQuery($type: ListItemTypeEnum!, $searchQuery: String, $perPage: Int, $requiredPagination: Boolean) {
    itemList(type: $type, searchQuery: $searchQuery, perPage: $perPage, requiredPagination: $requiredPagination) {
      items {
        edges {
          node {
            slug
            title
            image {
              ...ItemImage_data
            }
          }
        }
      }
    }
  }
`

export default ContainerField
