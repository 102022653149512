/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type EmailEntityTypeEnum = "contacts" | "containers" | "shipments" | "voyages" | "%future added value";
export type SendEmailDialogFormQueryVariables = {
    entityType: EmailEntityTypeEnum;
    entitySlug: string;
    templateSlug?: string | null;
    bodyContentFilter?: Array<string> | null;
};
export type SendEmailDialogFormQueryResponse = {
    readonly emailForm: {
        readonly entityType: EmailEntityTypeEnum;
        readonly entitySlug: string;
        readonly templateSlug: string;
        readonly defaultLanguage: string;
        readonly emails: ReadonlyArray<{
            readonly language: string;
            readonly languageTitle: string;
            readonly subject: string;
            readonly to: ReadonlyArray<string>;
            readonly body: string;
            readonly attachments: ReadonlyArray<string>;
        }>;
        readonly possibleTemplates: ReadonlyArray<{
            readonly title: string;
            readonly slug: string;
        }>;
        readonly possibleReceivers: ReadonlyArray<{
            readonly email: string;
            readonly roles: ReadonlyArray<string>;
            readonly name: string;
            readonly group: string;
        }>;
        readonly attachmentFolderSets: ReadonlyArray<{
            readonly folders: ReadonlyArray<{
                readonly slug: string;
                readonly name: string;
                readonly attachments: ReadonlyArray<{
                    readonly attachmentSlug: string;
                    readonly " $fragmentRefs": FragmentRefs<"EntityAttachmentListPreviewer_attachment" | "EntityAttachment_attachment">;
                }>;
            }>;
        }>;
        readonly " $fragmentRefs": FragmentRefs<"AttachmentSelectionDialog_emailForm">;
    };
};
export type SendEmailDialogFormQuery = {
    readonly response: SendEmailDialogFormQueryResponse;
    readonly variables: SendEmailDialogFormQueryVariables;
};



/*
query SendEmailDialogFormQuery(
  $entityType: EmailEntityTypeEnum!
  $entitySlug: String!
  $templateSlug: String
  $bodyContentFilter: [String!]
) {
  emailForm(entityType: $entityType, entitySlug: $entitySlug, templateSlug: $templateSlug, bodyContentFilter: $bodyContentFilter) {
    entityType
    entitySlug
    templateSlug
    defaultLanguage
    emails {
      language
      languageTitle
      subject
      to
      body
      attachments
    }
    possibleTemplates {
      title
      slug
    }
    possibleReceivers {
      email
      roles
      name
      group
    }
    attachmentFolderSets {
      folders {
        slug
        name
        attachments {
          attachmentSlug
          ...EntityAttachmentListPreviewer_attachment
          ...EntityAttachment_attachment
          id
        }
        id
      }
    }
    ...AttachmentSelectionDialog_emailForm
  }
}

fragment AttachmentSelectionDialog_emailForm on EmailFormResponse {
  attachmentFolderSets {
    key
    title
    folders {
      slug
      entitySlug
      name
      attachments {
        attachmentSlug
        ...EntityAttachment_attachment
        id
      }
      id
    }
  }
}

fragment EntityAttachmentListPreviewer_attachment on EntityAttachment {
  url
  downloadUrl
  name
  contentType
  previewUrl
  documentType
  entityType
  entitySlug
  attachmentSlug
  thumbnailImage
  ...EntityAttachmentRoleVisibility_attachment
}

fragment EntityAttachmentRoleVisibility_attachment on EntityAttachment {
  name
  attachmentSlug
  entityType
  entitySlug
  roleVisibilities {
    role
    roleShortTitle
    roleTitle
    visible
  }
}

fragment EntityAttachment_attachment on EntityAttachment {
  url
  downloadUrl
  name
  contentType
  previewUrl
  documentType
  draftTicketStatus
  lexOfficeInvoiceStatus
  signedStatus
  rejectReason
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "bodyContentFilter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "entitySlug"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "entityType"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "templateSlug"
},
v4 = [
  {
    "kind": "Variable",
    "name": "bodyContentFilter",
    "variableName": "bodyContentFilter"
  },
  {
    "kind": "Variable",
    "name": "entitySlug",
    "variableName": "entitySlug"
  },
  {
    "kind": "Variable",
    "name": "entityType",
    "variableName": "entityType"
  },
  {
    "kind": "Variable",
    "name": "templateSlug",
    "variableName": "templateSlug"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityType",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entitySlug",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "templateSlug",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "defaultLanguage",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "EmailFormContent",
  "kind": "LinkedField",
  "name": "emails",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "language",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "languageTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subject",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "to",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "body",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "attachments",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "PossibleTemplate",
  "kind": "LinkedField",
  "name": "possibleTemplates",
  "plural": true,
  "selections": [
    (v10/*: any*/),
    (v11/*: any*/)
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "PossibleReceiver",
  "kind": "LinkedField",
  "name": "possibleReceivers",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "roles",
      "storageKey": null
    },
    (v13/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "group",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "attachmentSlug",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SendEmailDialogFormQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "EmailFormResponse",
        "kind": "LinkedField",
        "name": "emailForm",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v12/*: any*/),
          (v14/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "FolderSet",
            "kind": "LinkedField",
            "name": "attachmentFolderSets",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Folder",
                "kind": "LinkedField",
                "name": "folders",
                "plural": true,
                "selections": [
                  (v11/*: any*/),
                  (v13/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EntityAttachment",
                    "kind": "LinkedField",
                    "name": "attachments",
                    "plural": true,
                    "selections": [
                      (v15/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "EntityAttachmentListPreviewer_attachment"
                      },
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "EntityAttachment_attachment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AttachmentSelectionDialog_emailForm"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "SendEmailDialogFormQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "EmailFormResponse",
        "kind": "LinkedField",
        "name": "emailForm",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v12/*: any*/),
          (v14/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "FolderSet",
            "kind": "LinkedField",
            "name": "attachmentFolderSets",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Folder",
                "kind": "LinkedField",
                "name": "folders",
                "plural": true,
                "selections": [
                  (v11/*: any*/),
                  (v13/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EntityAttachment",
                    "kind": "LinkedField",
                    "name": "attachments",
                    "plural": true,
                    "selections": [
                      (v15/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "url",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "downloadUrl",
                        "storageKey": null
                      },
                      (v13/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "contentType",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "previewUrl",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "documentType",
                        "storageKey": null
                      },
                      (v5/*: any*/),
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "thumbnailImage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "RoleVisibility",
                        "kind": "LinkedField",
                        "name": "roleVisibilities",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "role",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "roleShortTitle",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "roleTitle",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "visible",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "draftTicketStatus",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lexOfficeInvoiceStatus",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "signedStatus",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "rejectReason",
                        "storageKey": null
                      },
                      (v16/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v16/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "key",
                "storageKey": null
              },
              (v10/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "79da2682ff50537a56526e87d148ebde",
    "id": null,
    "metadata": {},
    "name": "SendEmailDialogFormQuery",
    "operationKind": "query",
    "text": "query SendEmailDialogFormQuery(\n  $entityType: EmailEntityTypeEnum!\n  $entitySlug: String!\n  $templateSlug: String\n  $bodyContentFilter: [String!]\n) {\n  emailForm(entityType: $entityType, entitySlug: $entitySlug, templateSlug: $templateSlug, bodyContentFilter: $bodyContentFilter) {\n    entityType\n    entitySlug\n    templateSlug\n    defaultLanguage\n    emails {\n      language\n      languageTitle\n      subject\n      to\n      body\n      attachments\n    }\n    possibleTemplates {\n      title\n      slug\n    }\n    possibleReceivers {\n      email\n      roles\n      name\n      group\n    }\n    attachmentFolderSets {\n      folders {\n        slug\n        name\n        attachments {\n          attachmentSlug\n          ...EntityAttachmentListPreviewer_attachment\n          ...EntityAttachment_attachment\n          id\n        }\n        id\n      }\n    }\n    ...AttachmentSelectionDialog_emailForm\n  }\n}\n\nfragment AttachmentSelectionDialog_emailForm on EmailFormResponse {\n  attachmentFolderSets {\n    key\n    title\n    folders {\n      slug\n      entitySlug\n      name\n      attachments {\n        attachmentSlug\n        ...EntityAttachment_attachment\n        id\n      }\n      id\n    }\n  }\n}\n\nfragment EntityAttachmentListPreviewer_attachment on EntityAttachment {\n  url\n  downloadUrl\n  name\n  contentType\n  previewUrl\n  documentType\n  entityType\n  entitySlug\n  attachmentSlug\n  thumbnailImage\n  ...EntityAttachmentRoleVisibility_attachment\n}\n\nfragment EntityAttachmentRoleVisibility_attachment on EntityAttachment {\n  name\n  attachmentSlug\n  entityType\n  entitySlug\n  roleVisibilities {\n    role\n    roleShortTitle\n    roleTitle\n    visible\n  }\n}\n\nfragment EntityAttachment_attachment on EntityAttachment {\n  url\n  downloadUrl\n  name\n  contentType\n  previewUrl\n  documentType\n  draftTicketStatus\n  lexOfficeInvoiceStatus\n  signedStatus\n  rejectReason\n}\n"
  }
};
})();
(node as any).hash = 'c71842eeb9ebe07371fe1adeb0c5199e';
export default node;
