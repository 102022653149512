/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type EmailsTab_emails = {
    readonly " $fragmentRefs": FragmentRefs<"EmailsCard_emails">;
    readonly " $refType": "EmailsTab_emails";
};
export type EmailsTab_emails$data = EmailsTab_emails;
export type EmailsTab_emails$key = {
    readonly " $data"?: EmailsTab_emails$data;
    readonly " $fragmentRefs": FragmentRefs<"EmailsTab_emails">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EmailsTab_emails",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EmailsCard_emails"
    }
  ],
  "type": "EmailConnection",
  "abstractKey": null
};
(node as any).hash = 'dc27aa4ca3d4db9a959619d22021a550';
export default node;
