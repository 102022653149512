/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ContainerFoldersQueryVariables = {
    slug: string;
};
export type ContainerFoldersQueryResponse = {
    readonly containerShow: {
        readonly folders: {
            readonly " $fragmentRefs": FragmentRefs<"FoldersTab_folders">;
        };
    };
};
export type ContainerFoldersQuery = {
    readonly response: ContainerFoldersQueryResponse;
    readonly variables: ContainerFoldersQueryVariables;
};



/*
query ContainerFoldersQuery(
  $slug: String!
) {
  containerShow(slug: $slug) {
    folders {
      ...FoldersTab_folders
    }
    id
  }
}

fragment EntityAttachmentListItem_attachment on EntityAttachment {
  url
  downloadUrl
  name
  contentType
  previewUrl
  documentType
  roleVisibilities {
    role
    roleShortTitle
    roleTitle
    visible
  }
  uploadedAtDisplay
  uploadedAt
}

fragment EntityAttachmentListPreviewer_attachment on EntityAttachment {
  url
  downloadUrl
  name
  contentType
  previewUrl
  documentType
  entityType
  entitySlug
  attachmentSlug
  thumbnailImage
  ...EntityAttachmentRoleVisibility_attachment
}

fragment EntityAttachmentRoleVisibility_attachment on EntityAttachment {
  name
  attachmentSlug
  entityType
  entitySlug
  roleVisibilities {
    role
    roleShortTitle
    roleTitle
    visible
  }
}

fragment EntityAttachment_attachment on EntityAttachment {
  url
  downloadUrl
  name
  contentType
  previewUrl
  documentType
  draftTicketStatus
  lexOfficeInvoiceStatus
  signedStatus
  rejectReason
}

fragment FileCreationDialog_folder on Folder {
  slug
  name
}

fragment Folder_folder on Folder {
  slug
  name
  parentType
  entitySlug
  attachments {
    attachmentSlug
    name
    ...EntityAttachmentListPreviewer_attachment
    ...EntityAttachment_attachment
    ...EntityAttachmentListItem_attachment
    id
  }
  ...FileCreationDialog_folder
}

fragment FoldersCard_folders on FolderConnection {
  nodes {
    slug
    parentType
    ...Folder_folder
    id
  }
}

fragment FoldersTab_folders on FolderConnection {
  ...FoldersCard_folders
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entitySlug",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ContainerFoldersQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Container",
        "kind": "LinkedField",
        "name": "containerShow",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FolderConnection",
            "kind": "LinkedField",
            "name": "folders",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "FoldersTab_folders"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ContainerFoldersQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Container",
        "kind": "LinkedField",
        "name": "containerShow",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FolderConnection",
            "kind": "LinkedField",
            "name": "folders",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Folder",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "slug",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "parentType",
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EntityAttachment",
                    "kind": "LinkedField",
                    "name": "attachments",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "attachmentSlug",
                        "storageKey": null
                      },
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "url",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "downloadUrl",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "contentType",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "previewUrl",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "documentType",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "entityType",
                        "storageKey": null
                      },
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "thumbnailImage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "RoleVisibility",
                        "kind": "LinkedField",
                        "name": "roleVisibilities",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "role",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "roleShortTitle",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "roleTitle",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "visible",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "draftTicketStatus",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lexOfficeInvoiceStatus",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "signedStatus",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "rejectReason",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "uploadedAtDisplay",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "uploadedAt",
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f5246e50fb8b9012aa417dfe5127bd31",
    "id": null,
    "metadata": {},
    "name": "ContainerFoldersQuery",
    "operationKind": "query",
    "text": "query ContainerFoldersQuery(\n  $slug: String!\n) {\n  containerShow(slug: $slug) {\n    folders {\n      ...FoldersTab_folders\n    }\n    id\n  }\n}\n\nfragment EntityAttachmentListItem_attachment on EntityAttachment {\n  url\n  downloadUrl\n  name\n  contentType\n  previewUrl\n  documentType\n  roleVisibilities {\n    role\n    roleShortTitle\n    roleTitle\n    visible\n  }\n  uploadedAtDisplay\n  uploadedAt\n}\n\nfragment EntityAttachmentListPreviewer_attachment on EntityAttachment {\n  url\n  downloadUrl\n  name\n  contentType\n  previewUrl\n  documentType\n  entityType\n  entitySlug\n  attachmentSlug\n  thumbnailImage\n  ...EntityAttachmentRoleVisibility_attachment\n}\n\nfragment EntityAttachmentRoleVisibility_attachment on EntityAttachment {\n  name\n  attachmentSlug\n  entityType\n  entitySlug\n  roleVisibilities {\n    role\n    roleShortTitle\n    roleTitle\n    visible\n  }\n}\n\nfragment EntityAttachment_attachment on EntityAttachment {\n  url\n  downloadUrl\n  name\n  contentType\n  previewUrl\n  documentType\n  draftTicketStatus\n  lexOfficeInvoiceStatus\n  signedStatus\n  rejectReason\n}\n\nfragment FileCreationDialog_folder on Folder {\n  slug\n  name\n}\n\nfragment Folder_folder on Folder {\n  slug\n  name\n  parentType\n  entitySlug\n  attachments {\n    attachmentSlug\n    name\n    ...EntityAttachmentListPreviewer_attachment\n    ...EntityAttachment_attachment\n    ...EntityAttachmentListItem_attachment\n    id\n  }\n  ...FileCreationDialog_folder\n}\n\nfragment FoldersCard_folders on FolderConnection {\n  nodes {\n    slug\n    parentType\n    ...Folder_folder\n    id\n  }\n}\n\nfragment FoldersTab_folders on FolderConnection {\n  ...FoldersCard_folders\n}\n"
  }
};
})();
(node as any).hash = '5b50a717d5d37f1bf82798691f61c1be';
export default node;
