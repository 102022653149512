/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type TicketDirectionEnum = "incoming" | "outgoing" | "%future added value";
export type TicketStatusEnum = "closed" | "open" | "resolved" | "%future added value";
export type TicketTypeEnum = "add_documents_and_photos" | "misc" | "request_for_documents" | "request_for_shipment_release" | "request_to_add_contact_link" | "request_to_create_a_new_contact" | "request_to_create_a_new_container" | "request_to_create_a_new_poa" | "request_to_create_a_new_shipment" | "request_to_create_container_shipment" | "signed_document_details" | "%future added value";
export type LiveRequest_liveRequests = {
    readonly nodes: ReadonlyArray<{
        readonly slug: string;
        readonly title: string;
        readonly status: TicketStatusEnum;
        readonly direction: TicketDirectionEnum;
        readonly ticketType: TicketTypeEnum;
        readonly absoluteSlugs: ReadonlyArray<string>;
        readonly raisedAt: string;
        readonly displayStatus: string;
        readonly raisedBy: {
            readonly name: string;
        };
        readonly raisedFor: {
            readonly name: string;
        } | null;
    } | null> | null;
    readonly " $refType": "LiveRequest_liveRequests";
};
export type LiveRequest_liveRequests$data = LiveRequest_liveRequests;
export type LiveRequest_liveRequests$key = {
    readonly " $data"?: LiveRequest_liveRequests$data;
    readonly " $fragmentRefs": FragmentRefs<"LiveRequest_liveRequests">;
};



const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LiveRequest_liveRequests",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Ticket",
      "kind": "LinkedField",
      "name": "nodes",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "direction",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ticketType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "absoluteSlugs",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "raisedAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayStatus",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Contact",
          "kind": "LinkedField",
          "name": "raisedBy",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Contact",
          "kind": "LinkedField",
          "name": "raisedFor",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TicketConnection",
  "abstractKey": null
};
})();
(node as any).hash = 'aa9d02d5ad02258a88bba91ca0eb1e70';
export default node;
