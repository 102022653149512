import React, { Suspense, useContext } from 'react'
import Timeline from './Timeline/Timeline'
import { AddNotes, ShowNotes } from './Notes'
import styled from 'styled-components'
import { Grid } from '@material-ui/core'
import { NoteEntityTypeEnum } from '../__generated__/NotesAddMutation.graphql'
import { graphql } from 'babel-plugin-relay/macro'
import { useLazyLoadQuery } from 'react-relay/hooks'
import { NoteTabQuery } from '../__generated__/NoteTabQuery.graphql'
import { ExternalShowNotes } from './ExternalNotes'
import SessionContext from '../SessionContext'

interface Props {
  slug: string
  entityType: NoteEntityTypeEnum
}

const Container = styled(Grid)`
  max-width: 80%;
  margin: auto;
`

const NoteContent: React.FC<Props> & { Skeleton: React.FC } = ({ slug, entityType }) => {
  const data = useLazyLoadQuery<NoteTabQuery>(query, { entityType: entityType, entitySlug: slug })
  const { user } = useContext(SessionContext)
  return (
    <>
      <Container container spacing={2}>
        <Grid item xs={12} md={8} lg={8}>
          <Suspense key='addNotes' fallback={<AddNotes.Skeleton />}>
            <AddNotes entitySlug={slug} entityType={entityType} showLast={false} />
          </Suspense>
          <Suspense key='showNotes' fallback={<AddNotes.Skeleton />}>
            <ShowNotes entitySlug={slug} entityType={entityType} notes={data.noteList} />
          </Suspense>
          {user?.contact?.contactType == 'internal' && (
            <Suspense key='showNotes' fallback={<ExternalShowNotes.Skeleton />}>
              <ExternalShowNotes entitySlug={slug} entityType={entityType}></ExternalShowNotes>
            </Suspense>
          )}
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <Suspense key={5} fallback={<Timeline.Skeleton />}>
            <Timeline slug={slug} type={entityType} group='notes' />
          </Suspense>
        </Grid>
      </Container>
    </>
  )
}

NoteContent.Skeleton = () => <>Loading...</>

const query = graphql`
  query NoteTabQuery($entityType: NoteEntityTypeEnum!, $entitySlug: String!) {
    noteList(entityType: $entityType, entitySlug: $entitySlug) {
      slug
      content
      addedAt
      isSticky
      addedBy {
        username
      }
    }
  }
`

export default NoteContent
