/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ETACard_voyage = {
    readonly eta: string | null;
    readonly displayEta: string | null;
    readonly etaLastFetchedAt: string | null;
    readonly etaFetchStartDate: string | null;
    readonly shouldFetchEta: boolean;
    readonly " $refType": "ETACard_voyage";
};
export type ETACard_voyage$data = ETACard_voyage;
export type ETACard_voyage$key = {
    readonly " $data"?: ETACard_voyage$data;
    readonly " $fragmentRefs": FragmentRefs<"ETACard_voyage">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ETACard_voyage",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eta",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayEta",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "etaLastFetchedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "etaFetchStartDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shouldFetchEta",
      "storageKey": null
    }
  ],
  "type": "Voyage",
  "abstractKey": null
};
(node as any).hash = '00943a1b57ff2ed2143c0951e31dc71d';
export default node;
