/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type BulkActionEnum = "export" | "release" | "transport" | "update" | "%future added value";
export type infoContainerBulkActionsInput = {
    clientMutationId?: string | null;
    containers: Array<BulkUpdateContainerInput>;
    action: BulkActionEnum;
};
export type BulkUpdateContainerInput = {
    slug: string;
    voyageSlug?: string | null;
    shippingLineSlug?: string | null;
    bookingNumber?: string | null;
    turninLocationSlug?: string | null;
    pickupLocationSlug?: string | null;
    pickupComment?: string | null;
    latestPickupDate?: string | null;
    latestTurninDate?: string | null;
    linkedContacts?: Array<LinkedContactInput> | null;
    estimatedCostStorage?: number | null;
    estimatedCostDemurrage?: number | null;
    costStorage?: number | null;
    costDemurrage?: number | null;
    costTransport?: number | null;
    costXray?: number | null;
    costThc?: number | null;
    costOther?: number | null;
    costCommission?: number | null;
    costCleaning?: number | null;
};
export type LinkedContactInput = {
    role: string;
    contactSlug: string;
};
export type ContainersTableContainerBulkActionsMutationVariables = {
    input: infoContainerBulkActionsInput;
};
export type ContainersTableContainerBulkActionsMutationResponse = {
    readonly infoContainerBulkActions: {
        readonly result: string;
    } | null;
};
export type ContainersTableContainerBulkActionsMutation = {
    readonly response: ContainersTableContainerBulkActionsMutationResponse;
    readonly variables: ContainersTableContainerBulkActionsMutationVariables;
};



/*
mutation ContainersTableContainerBulkActionsMutation(
  $input: infoContainerBulkActionsInput!
) {
  infoContainerBulkActions(input: $input) {
    result
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "infoContainerBulkActionsPayload",
    "kind": "LinkedField",
    "name": "infoContainerBulkActions",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "result",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ContainersTableContainerBulkActionsMutation",
    "selections": (v1/*: any*/),
    "type": "ApplicationCommand",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ContainersTableContainerBulkActionsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b0f154ed5bb63dbf6accd598d082d406",
    "id": null,
    "metadata": {},
    "name": "ContainersTableContainerBulkActionsMutation",
    "operationKind": "mutation",
    "text": "mutation ContainersTableContainerBulkActionsMutation(\n  $input: infoContainerBulkActionsInput!\n) {\n  infoContainerBulkActions(input: $input) {\n    result\n  }\n}\n"
  }
};
})();
(node as any).hash = 'ff2abb415910b12eecc825134c51d614';
export default node;
