/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LinkEnum = "contact" | "container" | "shipment" | "ticket" | "vessel" | "voyage" | "%future added value";
export type VesselLinkCard_data = {
    readonly type: LinkEnum;
    readonly title: {
        readonly " $fragmentRefs": FragmentRefs<"SemanticString_data">;
    } | null;
    readonly " $refType": "VesselLinkCard_data";
};
export type VesselLinkCard_data$data = VesselLinkCard_data;
export type VesselLinkCard_data$key = {
    readonly " $data"?: VesselLinkCard_data$data;
    readonly " $fragmentRefs": FragmentRefs<"VesselLinkCard_data">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "VesselLinkCard_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SemanticString",
      "kind": "LinkedField",
      "name": "title",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SemanticString_data"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Link",
  "abstractKey": null
};
(node as any).hash = '36dd03f4653e0764562fe1bffe453e7a';
export default node;
