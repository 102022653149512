import { Card, Grid, Typography } from '@material-ui/core'
import React, { useState, FormEvent, useContext } from 'react'
import styled from 'styled-components'
import OdysseyLogo from '../assets/odyssey-logo.svg'
import StyledInput from './StyledInput'
import CTAButton from './CTAButton'
import SessionContext from '../SessionContext'
import useLogin from '../hooks/useLogin'
import LoadingDots from './LoadingDots'
import { media } from '../theme'
import ForgetPasswordForm from './ForgetPasswordForm'
import { useEffect } from 'react'

const LoginCard = styled(Card)`
  padding: 16px 24px;
  background-color: rgb(255, 255, 255, 0.95);
  ${media.mobile`
    background-color: rgb(255, 255, 255, 0.2);
  `}
`

const Link = styled(Typography)`
  cursor: pointer;
  text-align: center;
  color: #1d6aff;
  margin: 16px 0;

  &:hover {
    text-decoration: underline;
  }
`

const NewAccountLink = styled(Typography)`
  cursor: pointer;
  text-align: center;
  color: #aaaaaa;
  margin: 16px 0;

  &:hover {
    & > a {
      text-decoration: underline;
    }
  }

  & > a {
    color: #1d6aff;
    text-decoration: none;
  }
`

const Alert = styled.div`
  padding: 16px;
  background-color: #ff5858;
  color: white;
  border-radius: 10px;
  margin: 8px 0;
`

const Odyssey = styled.img`
  width: 100%;
  margin-top: 16px;
`

const LoginView: React.FC = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [otp, setOtp] = useState('')
  const { reloadSession } = useContext(SessionContext)
  const [otpInput, setOtpInput] = useState(false)

  const [{ loading, error, data }, { doLogin }] = useLogin({
    reloadSession,
  })
  useEffect(() => {
    if (data?.otp_required_for_login && data?.revoke_access == false) {
      setOtpInput(true)
    }
  }, [otpInput, data])

  const onLogin = (e: FormEvent) => {
    e.preventDefault()

    doLogin(username, password, otp)
  }

  return (
    <>
      {error && <Alert>{error.message}</Alert>}
      <form onSubmit={onLogin}>
        {otpInput ? (
          <StyledInput fullWidth placeholder='OTP' type='text' value={otp} onChange={(e) => setOtp(e.target.value)} />
        ) : (
          <>
            <StyledInput
              fullWidth
              placeholder='Username or Email id'
              type='text'
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <StyledInput fullWidth placeholder='Password' type='password' value={password} onChange={(e) => setPassword(e.target.value)} />
          </>
        )}

        <CTAButton type='submit' disabled={!username || !password || (otpInput && !otp)}>
          {loading ? <LoadingDots /> : 'Login'}
        </CTAButton>
      </form>
    </>
  )
}

const LoginForm: React.FC = () => {
  const [forgetPassword, setForgetPassword] = useState<boolean>(false)

  return (
    <LoginCard>
      <Grid container spacing={2}>
        <Grid item lg={3} xs={2} />
        <Grid item lg={6} xs={8}>
          <Odyssey src={OdysseyLogo} />
        </Grid>
        <Grid item xs={12}>
          {forgetPassword ? <ForgetPasswordForm /> : <LoginView />}
          <Link variant='body1' onClick={() => setForgetPassword(!forgetPassword)}>
            {forgetPassword ? 'Login' : 'Forgot Password?'}
          </Link>
          <NewAccountLink variant='body1'>
            No Account? <a href='mailto:info@portagent.eu'>Talk to us</a>
          </NewAccountLink>
        </Grid>
      </Grid>
    </LoginCard>
  )
}

export default LoginForm
