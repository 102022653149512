/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ApiLogsQueryVariables = {
    type?: string | null;
    sortKey?: string | null;
    page?: number | null;
    perPage?: number | null;
};
export type ApiLogsQueryResponse = {
    readonly apiLogList: {
        readonly apiLogs: ReadonlyArray<{
            readonly slug: string;
            readonly " $fragmentRefs": FragmentRefs<"ApiLogItem_apiLog">;
        }> | null;
        readonly paginationMeta: {
            readonly totalPages: number;
        } | null;
    };
};
export type ApiLogsQuery = {
    readonly response: ApiLogsQueryResponse;
    readonly variables: ApiLogsQueryVariables;
};



/*
query ApiLogsQuery(
  $type: String
  $sortKey: String
  $page: Int
  $perPage: Int
) {
  apiLogList(type: $type, sortKey: $sortKey, page: $page, perPage: $perPage) {
    apiLogs {
      slug
      ...ApiLogItem_apiLog
      id
    }
    paginationMeta {
      totalPages
    }
  }
}

fragment ApiLogItem_apiLog on ApiLog {
  title
  displayCallType
  httpStatus
  createdAt
  callResponseUrl
  callStatus
  callError
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "page"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "perPage"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sortKey"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "type"
},
v4 = [
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page"
  },
  {
    "kind": "Variable",
    "name": "perPage",
    "variableName": "perPage"
  },
  {
    "kind": "Variable",
    "name": "sortKey",
    "variableName": "sortKey"
  },
  {
    "kind": "Variable",
    "name": "type",
    "variableName": "type"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Paginate",
  "kind": "LinkedField",
  "name": "paginationMeta",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalPages",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ApiLogsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "ApiLogList",
        "kind": "LinkedField",
        "name": "apiLogList",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ApiLog",
            "kind": "LinkedField",
            "name": "apiLogs",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ApiLogItem_apiLog"
              }
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "ApiLogsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "ApiLogList",
        "kind": "LinkedField",
        "name": "apiLogList",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ApiLog",
            "kind": "LinkedField",
            "name": "apiLogs",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayCallType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "httpStatus",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "callResponseUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "callStatus",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "callError",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1122c47b095fb3df702ffb318a759134",
    "id": null,
    "metadata": {},
    "name": "ApiLogsQuery",
    "operationKind": "query",
    "text": "query ApiLogsQuery(\n  $type: String\n  $sortKey: String\n  $page: Int\n  $perPage: Int\n) {\n  apiLogList(type: $type, sortKey: $sortKey, page: $page, perPage: $perPage) {\n    apiLogs {\n      slug\n      ...ApiLogItem_apiLog\n      id\n    }\n    paginationMeta {\n      totalPages\n    }\n  }\n}\n\nfragment ApiLogItem_apiLog on ApiLog {\n  title\n  displayCallType\n  httpStatus\n  createdAt\n  callResponseUrl\n  callStatus\n  callError\n}\n"
  }
};
})();
(node as any).hash = 'af1a6642eebac70c88bc1ee5100cbfde';
export default node;
