import get from 'lodash/get'
import React, { useEffect, useMemo, useState } from 'react'

import styled from 'styled-components'
import Dropdown, { OptionType } from '../Dropdown'
import StageConfirmed from '../../assets/public/v2/stage_confirmed.svg'
import StageReleaseRequested from '../../assets/public/v2/stage_requested.svg'
import StageReleaseConfirmed from '../../assets/public/v2/stage_released.svg'
import StagePickupSent from '../../assets/public/v2/stage_pickup.svg'
import StageFinished from '../../assets/public/v2/stage_completed.svg'
import StageStarted from '../../assets/public/v2/stage_started.svg'
import StageCancelled from '../../assets/public/v2/stage_cancelled.svg'
import StageWaiting from '../../assets/public/v2/stage_waiting.svg'
import StageHandover from '../../assets/public/v2/stage_handover.svg'
import { Icon, RendererRoot } from './RendererHoc'
import { RendererOptions } from '../DataTable'
import { startCase } from 'lodash'
import { Typography } from '@material-ui/core'
import { useQuery } from 'urql'

const StyledText = styled(Typography)`
  font-size: inherit;
  color: ${(props) => props.theme.palette.text.primary};

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const ICON_ENUM_MAP: Record<string, Record<string, string>> = {
  container: {
    confirmed: StageConfirmed,
    release_requested: StageReleaseRequested,
    release_confirmed: StageReleaseConfirmed,
    pickup_sent: StagePickupSent,
    finished: StageFinished,
    started: StageStarted,
  },
  shipment: {
    waiting_for_approval: StageWaiting,
    approved: StageConfirmed,
    document_collection: StageReleaseRequested,
    customs_clearance: StageReleaseConfirmed,
    billing: StagePickupSent,
    handover: StageHandover,
    done: StageFinished,
    rejected: StageCancelled,
  },
}

function StageRenderer<T extends Record<string, any>>({
  anchorEl: outerAnchorEl,
  identifier,
  rowData,
  editable,
  onSubmit,
  value: externalValue,
  type: _type,
}: RendererOptions<T>) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(outerAnchorEl)
  const [search, setSearch] = React.useState('')
  const type = get(rowData, 'type') || (_type as T['type'])
  const name = `${startCase(type)}${startCase(identifier)}Enum`.replaceAll(' ', '')

  const [{ data }] = useQuery({
    query: StageQuery,
    variables: { name },
    pause: !type,
  })

  const enumValues = get(data, '__type.enumValues', [])

  const options = useMemo(
    () =>
      enumValues.map((en: any) => ({
        label: en.description || startCase(en.name),
        value: en.name,
        icon: ICON_ENUM_MAP[type][en.name],
      })),
    [enumValues]
  )

  const open = Boolean(anchorEl)

  const rendererValue = get(rowData, identifier)

  const stageValueOption: OptionType = options.find((opt: OptionType) => opt.value === rendererValue) || {
    label: startCase(rendererValue),
    value: rendererValue,
    icon: ICON_ENUM_MAP[type][rendererValue],
  }

  const [_value, setValue] = useState<OptionType>(stageValueOption)
  const value = options.find((s: OptionType) => s.value === externalValue) || _value

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    editable && setAnchorEl(event.currentTarget)
  }

  const handleSelect = async (newValue: OptionType) => {
    const prevValue = value
    setValue(newValue)
    onSubmit?.({ ...rowData, [identifier]: newValue.value })
      // @ts-ignore
      ?.catch(() => {
        setValue(prevValue)
      })
    handleClose()
  }

  useEffect(() => {
    const newValue = options.find((opt: OptionType) => opt.value === rendererValue)
    if (options.length > 0 && value?.value !== newValue?.value) {
      if (newValue?.value) setValue(newValue)
    }
  }, [options, rowData, identifier, value])

  return (
    <>
      <RendererRoot
        role='button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        data-disabled={rowData.isArchived}
      >
        <Icon src={value ? value.icon : StageStarted} />
        <StyledText title={value?.label}>{value?.label || '--'}</StyledText>
      </RendererRoot>
      <Dropdown
        title='Stage'
        search={search}
        onSearch={(value) => setSearch(value)}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        options={options}
        // onChange={handleOnChange}
        value={value?.value}
        onSelect={handleSelect}
      />
    </>
  )
}

const StageQuery = `
  query StageRendererQuery($name: String!) {
    __type(name: $name) {
      name
      enumValues {
        name
        description
      }
    }
  }
`

StageRenderer.operators = ['eq', 'neq', 'is_null', 'not_null']

export default StageRenderer
