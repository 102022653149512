/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type StageStatusEnum = "active" | "completed" | "incomplete" | "%future added value";
export type ShipmentWorkflow_shipment = {
    readonly slug: string;
    readonly automaticHandover: boolean | null;
    readonly workflowStages: ReadonlyArray<{
        readonly value: string;
        readonly label: string;
        readonly ownedBy: string | null;
        readonly status: StageStatusEnum;
        readonly " $fragmentRefs": FragmentRefs<"Stage_stage">;
    }>;
    readonly " $refType": "ShipmentWorkflow_shipment";
};
export type ShipmentWorkflow_shipment$data = ShipmentWorkflow_shipment;
export type ShipmentWorkflow_shipment$key = {
    readonly " $data"?: ShipmentWorkflow_shipment$data;
    readonly " $fragmentRefs": FragmentRefs<"ShipmentWorkflow_shipment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ShipmentWorkflow_shipment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "automaticHandover",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "WorkflowStage",
      "kind": "LinkedField",
      "name": "workflowStages",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ownedBy",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Stage_stage"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Shipment",
  "abstractKey": null
};
(node as any).hash = '84fce8985d0b613294e46d05770d14fd';
export default node;
