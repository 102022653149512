import React from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import { useFragment } from 'react-relay/hooks'
import { Action_data$key } from '../__generated__/Action_data.graphql'
import Button from './Button'
import useNiceMutation from '../mutations/useNiceMutation'
import { ActionSendInviteMutation } from '../__generated__/ActionSendInviteMutation.graphql'
import LoadingDots from './LoadingDots'

interface Props {
  data: Action_data$key
  itemSlug: string
}

const Action: React.FC<Props> = ({ data, itemSlug }) => {
  const action = useFragment(actionFragment, data)
  const [commitSendInvite, sendInviteIsInFlight] = useNiceMutation<ActionSendInviteMutation>(sendInviteMutation)

  const handleAction = () => {
    if (action.type !== 'send_invite') return
    if (sendInviteIsInFlight) return

    commitSendInvite({ variables: { input: { slug: itemSlug } } })
  }

  return (
    <Button variant={action.role === 'primary' ? 'contained' : 'outlined'} onClick={handleAction}>
      {sendInviteIsInFlight ? <LoadingDots variant='dark' /> : action.cta}
    </Button>
  )
}

const sendInviteMutation = graphql`
  mutation ActionSendInviteMutation($input: infoSendInvitationInput!) {
    infoSendInvitation(input: $input) {
      item {
        ...ListPageItem_listItem
      }
    }
  }
`

const actionFragment = graphql`
  fragment Action_data on Action {
    type
    role
    cta
  }
`

export default Action
