/* eslint-disable @typescript-eslint/no-unused-vars */
import { mouse, select, selectAll } from 'd3-selection'
import { arc, pie } from 'd3-shape'
import rough from 'roughjs/bundled/rough.esm.js'
import get from 'lodash.get'
import Chart from './Chart'
import { colors } from './utils/colors'
import { addLegend, LEGEND_LETTER_HEIGHT, LEGEND_LETTER_WIDTH } from './utils/addLegend'
import { roughCeiling } from './utils/roughCeiling'

class Pie extends Chart {
  constructor(opts) {
    super(opts)

    // load in arguments from config object
    // this.data = opts.data;
    this.margin = opts.margin || { top: 8, right: 16, bottom: 8, left: 16 }
    this.colors = get(opts, 'colors', colors)
    this.highlight = opts.highlight
    this.roughness = roughCeiling({ roughness: opts.roughness, ceiling: 30, defaultValue: 0 })
    this.strokeWidth = get(opts, 'strokeWidth', 0.75)
    this.innerStrokeWidth = get(opts, 'innerStrokeWidth', 1)
    this.fillWeight = get(opts, 'fillWeight', 0.5)
    this.data = opts.data
    if (this.data['labels'] === undefined || this.data['values'] === undefined) {
      console.log(`Error for ${this.el}: Must include labels and values when \
       instantiating Donut chart. Skipping chart.`)
      return
    }
    this.legend = opts.legend !== false
    this.legendPosition = get(opts, 'legendPosition', 'right')
    // new width
    this.initChartValues(opts)
    // resolve font
    this.resolveFont()
    // create the chart
    this.noData = false
    if (this.data['values'].filter((value) => value !== 0).length === 0) {
      this.noData = true
    }
    this.drawFromObject()
    this.onClick = opts.onClick
    if (opts.title !== 'undefined') this.setTitle(opts.title)
  }

  initChartValues(opts) {
    const width = opts.width ? opts.width + 20 : 450
    const height = opts.height ? opts.height : 300
    this.width = width - this.margin.left - this.margin.right
    this.height = height - this.margin.top - this.margin.bottom
    this.roughId = this.el + '_svg'
    this.graphClass = this.el.substring(1, this.el.length)
    this.interactionG = 'g.' + this.graphClass
    this.radius = opts.radius || Math.min(this.width, this.height) / 2
    this.pieCenterX = this.radius
    this.pieCenterY = this.height / 2
    this.setSvg()
  }

  // add this to abstract base
  resolveData(data) {
    return () => {
      this.data = data
      this.drawFromObject()
    }
  }

  setTitle(title) {
    this.svg
      .append('text')
      .attr('x', this.width / 2)
      .attr('y', 0 - this.margin.top / 3)
      .attr('class', 'title')
      .attr('text-anchor', 'middle')
      .style('font-size', this.titleFontSize === undefined ? `${Math.min(40, Math.min(this.width, this.height) / 4)}px` : this.titleFontSize)
      .style('font-family', this.fontFamily)
      .style('opacity', 0.8)
      .text(title)
  }

  addInteraction() {
    // create tooltip
    const Tooltip = select(this.el)
      .append('div')
      .style('opacity', 0)
      .attr('class', 'tooltip')
      .style('position', 'absolute')
      .style('background-color', 'white')
      .style('border', 'solid')
      .style('border-width', '1px')
      .style('border-radius', '5px')
      .style('padding', '3px')
      .style('z-index', '99')
      .style('font-family', this.fontFamily)
      .style('font-size', this.tooltipFontSize)
      .style('pointer-events', 'none')
      .style('left', 0)
      .style('top', 0)

    // event functions
    const mouseover = function (d) {
      Tooltip.style('opacity', 1)
    }

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this
    let thisColor

    const mousemove = function (d) {
      const attrX = select(this).attr('attrX')
      const attrY = select(this).attr('attrY')
      const mousePos = mouse(this)
      const tooltipX = mousePos[0] + that.margin.left + 16
      const tooltipY = mousePos[1] + that.margin.top

      Tooltip.html(`<b>${attrX}</b>: ${attrY}`).style('opacity', 0.95).style('transform', `translate(${tooltipX}px, ${tooltipY}px)`)
    }
    const mouseleave = function (d) {
      Tooltip.style('opacity', 0)
    }

    // d3 event handlers
    selectAll(this.interactionG).on('mouseover', function () {
      mouseover()
      const path = this.getAttribute('attrZ')
      const attrIdx = this.getAttribute('attrIdx')
      if (path !== 'undefined') select(this).style('cursor', 'pointer')
      thisColor = select(this).selectAll('path').style('stroke')
      that.highlight === undefined
        ? select(this).selectAll('path').style('opacity', 0.5)
        : select(this).selectAll('path').style('stroke', that.highlight)

      that.svg
        .selectAll(`.legend-row`)
        .filter(function () {
          return this.getAttribute('attrIdx') !== attrIdx
        })
        .style('opacity', 0.2)
    })

    selectAll(this.interactionG).on('mouseout', function () {
      mouseleave()
      select(this).selectAll('path').style('stroke', thisColor)
      select(this).selectAll('path').style('opacity', 1)

      that.svg.selectAll(`.legend-row`).style('opacity', 1)
    })

    selectAll(this.interactionG).on('mousemove', mousemove)
    selectAll(this.interactionG).on('click', function () {
      const path = this.getAttribute('attrZ')
      if (path !== 'undefined') that.onClick(path)
      else return null
    })
  }

  initRoughObjects() {
    this.roughSvg = document.getElementById(this.roughId)
    this.rcAxis = rough.svg(this.roughSvg, { options: { strokeWidth: this.strokeWidth >= 3 ? 3 : this.strokeWidth } })
    this.rc = rough.svg(this.roughSvg, {
      options: {
        fill: this.color,
        strokeWidth: this.innerStrokeWidth,
        roughness: this.roughness,
        bowing: this.bowing,
        fillStyle: this.fillStyle,
      },
    })
  }

  drawFromObject() {
    this.initRoughObjects()

    this.makePie = pie()

    this.makeArc = arc().innerRadius(0).outerRadius(this.radius)

    this.arcs = !this.noData ? this.makePie(this.data['values']) : this.makePie(new Array(this.data['values'].length).fill(1))

    this.arcs.forEach((d, i) => {
      if (d.value !== 0) {
        const node = this.rc.arc(
          this.pieCenterX, // x
          this.pieCenterY, // y
          2 * this.radius, // width
          2 * this.radius, // height
          d.startAngle - Math.PI / 2, // start
          d.endAngle - Math.PI / 2, // stop
          true,
          {
            fill: this.colors[i],
            stroke: this.colors[i],
          }
        )
        node.classList.add(this.graphClass)
        node.classList.add('pie-slice')

        const roughNode = this.roughSvg.appendChild(node)
        select(roughNode)
          .append('path')
          .attr('d', this.makeArc(d))
          .attr('transform', `translate(${this.pieCenterX}, ${this.pieCenterY})`)
          .attr('stroke-width', '0px')
          .attr('fill', 'transparent')
        roughNode.setAttribute('attrY', this.data['values'][i])
        roughNode.setAttribute('attrX', this.data['labels'][i])
        roughNode.setAttribute('attrZ', this.data['paths'][i])
        roughNode.setAttribute('attrIdx', i)
      }
    })

    selectAll(this.interactionG).selectAll('path:nth-child(2)').style('stroke-width', this.strokeWidth)

    const dataSources = this.data.labels
    // ADD LEGEND
    const legendItems = dataSources.map((key, i) => ({
      color: this.colors[i],
      text: key,
      path: this.data['paths'][i],
    }))
    // find length of longest text item
    const legendWidth = legendItems.reduce((pre, cur) => (pre > cur.text.length ? pre : cur.text.length), 0) * LEGEND_LETTER_WIDTH + 35
    const legendHeight = legendItems.length * LEGEND_LETTER_HEIGHT + 8

    if (this.legend === true) {
      addLegend(this, legendItems, legendWidth, legendHeight)
    }

    if (this.noData) {
      selectAll(`${this.el} .pie-slice`).style('opacity', 0.4)
      selectAll(`${this.el} .legend-container`).style('opacity', 0.4)

      this.drawNoDataRect()
    }

    // If desired, add interactivity
    if (this.interactive === true && !this.noData) {
      this.addInteraction()
    }
  }

  drawNoDataRect() {
    const noDataRectHeight = 50
    const noDataRectWidth = 150
    const noDataRectG = this.rc.rectangle(
      0,
      0,
      noDataRectWidth, // width
      noDataRectHeight, // height
      {
        fill: 'white',
        fillWeight: 1,
        fillStyle: 'solid',
        strokeWidth: 0.75,
        stroke: '#aaaaaa',
        roughness: 2,
      }
    )

    noDataRectG.setAttribute('transform', `translate(${this.width / 2 - noDataRectWidth / 2}, ${this.height / 2 - noDataRectHeight / 2})`)
    noDataRectG.classList.add('no-data-rect')
    this.roughSvg.appendChild(noDataRectG)

    select(`${this.el} .no-data-rect`)
      .append('text')
      .style('font-size', '1.2rem')
      .style('font-family', this.fontFamily)
      .style('text-anchor', 'middle')
      .style('dominant-baseline', 'text-before-edge')
      .attr('transform', `translate(${noDataRectWidth / 2}, 15)`)
      .text('No data')
  }
}

export default Pie
