import React from 'react'
import { MenuItem, TextField as StandardTextField, InputProps as StandardInputProps } from '@material-ui/core'
import { Field as FormikField } from 'formik'
import { TextField } from 'formik-material-ui'
import styled from 'styled-components'
import { FieldT, FieldVariantEnum } from './types'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Field, useField } from 'formik'
import { AutocompleteRenderInputParams } from '@material-ui/lab'

interface Props {
  field: FieldT
  variant?: FieldVariantEnum
  InputProps?: Partial<StandardInputProps>
  disabled?: boolean
  validate?: (value: string) => string | undefined
}

const StyledField = styled(FormikField)``

const CommonInputField: React.FC<Props> = ({ field, variant, InputProps, disabled, validate }) => {
  const [, meta, helpers] = useField(field.name)
  if (field.type === 'searchable') {
    const keys = field.enum === null ? (['Empty Search'] as string[]) : (field.enum as string[])
    const values = field.enumTitles === null ? (['Empty Search'] as string[]) : (field.enumTitles as string[])
    const titleMap = Object.fromEntries(keys.map((k, index) => [k, values[index]]))
    return (
      <Field
        name={field.name}
        component={Autocomplete}
        id={field.title}
        options={field.enum as string[]}
        value={meta.value}
        onChange={(_: any, value: any) => (value ? helpers.setValue(value) : helpers.setValue(null))}
        getOptionLabel={(option: string) => titleMap[option]}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <StandardTextField
            {...params}
            error={meta.touched && !!meta.error}
            helperText={meta.touched ? meta.error : null}
            label={field.title}
            variant='outlined'
          />
        )}
      />
    )
  } else if (field.enum) {
    return (
      <StyledField
        key={field.name}
        name={field.name}
        label={field.title}
        variant='outlined'
        disabled={disabled}
        fullWidth
        component={TextField}
        select={true}
      >
        {field.enum.map((val, idx) => (
          <MenuItem key={val} value={val}>
            {field.enumTitles ? field.enumTitles[idx] : val}
          </MenuItem>
        ))}
      </StyledField>
    )
  } else if (field.type === 'number') {
    return (
      <>
        <StyledField
          key={field.name}
          name={field.name}
          label={field.title}
          type='number'
          multiline={variant === 'large'}
          variant='outlined'
          fullWidth
          disabled={disabled}
          component={TextField}
          InputProps={InputProps}
          helperText={field.description}
        />
        {/* <ErrorMessage name={field.name} /> */}
      </>
    )
  } else {
    return (
      <>
        <StyledField
          key={field.name}
          name={field.name}
          label={field.title}
          multiline={variant === 'large'}
          variant='outlined'
          validate={validate}
          disabled={disabled}
          fullWidth
          component={TextField}
        />
      </>
    )
  }
}

export default CommonInputField
