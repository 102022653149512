import React, { Suspense } from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import { EmailsTab_emails$key } from '../../__generated__/EmailsTab_emails.graphql'
import { useFragment } from 'react-relay/hooks'
import ResponsiveGrid from '../ResponsiveGrid'
import Timeline from '../Timeline/Timeline'
import { isSmall } from '../../theme'
import EmailsCard from './EmailsCard'
import { EmailEntityEnum } from './types'

interface Props {
  entityType: EmailEntityEnum
  entitySlug: string
  emails: EmailsTab_emails$key
}

const EmailsTab: React.FC<Props> & { Skeleton: React.FC } = ({ emails: data, entityType, entitySlug }) => {
  const emails = useFragment(fragment, data)
  return (
    <ResponsiveGrid
      type='show-layout'
      highlight={[<EmailsCard key='emails' emails={emails} entityType={entityType} entitySlug={entitySlug} />]}
      right={
        isSmall()
          ? []
          : [
              <Suspense key='timeline' fallback={<Timeline.Skeleton />}>
                <Timeline type={entityType} slug={entitySlug} group='emails' />
              </Suspense>,
            ]
      }
    />
  )
}

const fragment = graphql`
  fragment EmailsTab_emails on EmailConnection {
    ...EmailsCard_emails
  }
`

EmailsTab.Skeleton = () => <>Loading...</>

export default EmailsTab
