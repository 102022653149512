/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ExternalListItemTypeEnum = "containers" | "external_contacts" | "item" | "shipments" | "tickets" | "voyages" | "%future added value";
export type FilterInput = {
    key: string;
    values: Array<string>;
};
export type ExternalRelatedItemListFiltersQueryVariables = {
    searchQuery?: string | null;
    type?: ExternalListItemTypeEnum | null;
    parentAbsoluteSlug?: string | null;
    filters?: Array<FilterInput> | null;
    includeArchived?: boolean | null;
    sortKey?: string | null;
    page?: number | null;
    perPage?: number | null;
};
export type ExternalRelatedItemListFiltersQueryResponse = {
    readonly externalItemList: {
        readonly " $fragmentRefs": FragmentRefs<"ExternalItemListFilters_data">;
    };
};
export type ExternalRelatedItemListFiltersQuery = {
    readonly response: ExternalRelatedItemListFiltersQueryResponse;
    readonly variables: ExternalRelatedItemListFiltersQueryVariables;
};



/*
query ExternalRelatedItemListFiltersQuery(
  $searchQuery: String
  $type: ExternalListItemTypeEnum
  $parentAbsoluteSlug: String
  $filters: [FilterInput!]
  $includeArchived: Boolean
  $sortKey: String
  $page: Int
  $perPage: Int
) {
  externalItemList(searchQuery: $searchQuery, type: $type, parentAbsoluteSlug: $parentAbsoluteSlug, filters: $filters, includeArchived: $includeArchived, sortKey: $sortKey, page: $page, perPage: $perPage, showFilters: true) {
    ...ExternalItemListFilters_data
  }
}

fragment ExternalItemListFilters_data on ExternalItemList {
  filters {
    key
    ...ListPageFilter_filter
  }
}

fragment ListPageFilter_filter on Filter {
  key
  name
  values {
    label
    displayLabel
    selected
    ...ListPageFilter_filterValue
  }
}

fragment ListPageFilter_filterValue on FilterValue {
  label
  displayLabel
  selected
  count
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includeArchived"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "page"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "parentAbsoluteSlug"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "perPage"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchQuery"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sortKey"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "type"
},
v8 = [
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  {
    "kind": "Variable",
    "name": "includeArchived",
    "variableName": "includeArchived"
  },
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page"
  },
  {
    "kind": "Variable",
    "name": "parentAbsoluteSlug",
    "variableName": "parentAbsoluteSlug"
  },
  {
    "kind": "Variable",
    "name": "perPage",
    "variableName": "perPage"
  },
  {
    "kind": "Variable",
    "name": "searchQuery",
    "variableName": "searchQuery"
  },
  {
    "kind": "Literal",
    "name": "showFilters",
    "value": true
  },
  {
    "kind": "Variable",
    "name": "sortKey",
    "variableName": "sortKey"
  },
  {
    "kind": "Variable",
    "name": "type",
    "variableName": "type"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ExternalRelatedItemListFiltersQuery",
    "selections": [
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "ExternalItemList",
        "kind": "LinkedField",
        "name": "externalItemList",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ExternalItemListFilters_data"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v5/*: any*/),
      (v7/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v6/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "ExternalRelatedItemListFiltersQuery",
    "selections": [
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "ExternalItemList",
        "kind": "LinkedField",
        "name": "externalItemList",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Filter",
            "kind": "LinkedField",
            "name": "filters",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "key",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "FilterValue",
                "kind": "LinkedField",
                "name": "values",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "label",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayLabel",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "selected",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "count",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "614425797c9af4121e3fb7c692add773",
    "id": null,
    "metadata": {},
    "name": "ExternalRelatedItemListFiltersQuery",
    "operationKind": "query",
    "text": "query ExternalRelatedItemListFiltersQuery(\n  $searchQuery: String\n  $type: ExternalListItemTypeEnum\n  $parentAbsoluteSlug: String\n  $filters: [FilterInput!]\n  $includeArchived: Boolean\n  $sortKey: String\n  $page: Int\n  $perPage: Int\n) {\n  externalItemList(searchQuery: $searchQuery, type: $type, parentAbsoluteSlug: $parentAbsoluteSlug, filters: $filters, includeArchived: $includeArchived, sortKey: $sortKey, page: $page, perPage: $perPage, showFilters: true) {\n    ...ExternalItemListFilters_data\n  }\n}\n\nfragment ExternalItemListFilters_data on ExternalItemList {\n  filters {\n    key\n    ...ListPageFilter_filter\n  }\n}\n\nfragment ListPageFilter_filter on Filter {\n  key\n  name\n  values {\n    label\n    displayLabel\n    selected\n    ...ListPageFilter_filterValue\n  }\n}\n\nfragment ListPageFilter_filterValue on FilterValue {\n  label\n  displayLabel\n  selected\n  count\n}\n"
  }
};
})();
(node as any).hash = '198b0a1dac3a0ad1737f72ae5e204998';
export default node;
