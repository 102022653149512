import { DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import React from 'react'
import Button from '../../components/Button'
import OdysseyDialog from '../../components/OdysseyDialog'

interface Props {
  open: boolean
  onClose: () => void
  onSwitch: () => void
}

const SwitchWorkspaceDialog: React.FC<Props> = ({ open, onClose, onSwitch }) => {
  return (
    <OdysseyDialog open={open} onClose={onClose}>
      <DialogTitle>Switch Workspace</DialogTitle>
      <DialogContent>Are you sure you want to switch workspace?</DialogContent>
      <DialogActions>
        <Button variant='text' onClick={onClose}>
          Cancel
        </Button>
        <Button variant='contained' onClick={onSwitch}>
          Switch
        </Button>
      </DialogActions>
    </OdysseyDialog>
  )
}

export default SwitchWorkspaceDialog
