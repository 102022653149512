/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ETDCard_voyage = {
    readonly displayEtd: string | null;
    readonly " $refType": "ETDCard_voyage";
};
export type ETDCard_voyage$data = ETDCard_voyage;
export type ETDCard_voyage$key = {
    readonly " $data"?: ETDCard_voyage$data;
    readonly " $fragmentRefs": FragmentRefs<"ETDCard_voyage">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ETDCard_voyage",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayEtd",
      "storageKey": null
    }
  ],
  "type": "Voyage",
  "abstractKey": null
};
(node as any).hash = 'c5bbd86165bb793e875fc49048f44018';
export default node;
