import { CircularProgress, DialogActions, DialogContent, DialogTitle, TextField, Typography, Divider, Paper } from '@material-ui/core'
import { Alert, Autocomplete } from '@material-ui/lab'
import { graphql } from 'babel-plugin-relay/macro'
import React, { useRef, useState, Suspense, DragEventHandler, useEffect } from 'react'
import { useFragment, useLazyLoadQuery, useRelayEnvironment } from 'react-relay/hooks'
import styled from 'styled-components'
import useNiceMutation from '../../mutations/useNiceMutation'
import Button from '../Button'
import { FileCreationDialog_folder$key } from '../../__generated__/FileCreationDialog_folder.graphql'
import TabsUI, { TabPanel } from '../TabsUI/TabsUI'
import { AttachmentEntityTypeEnum, FileCreationDialogUploadMutation } from '../../__generated__/FileCreationDialogUploadMutation.graphql'
import { FileCreationDialogGeneratableDocsQuery } from '../../__generated__/FileCreationDialogGeneratableDocsQuery.graphql'
import GeneratableDocItem from './GeneratableDocItem'
import OdysseyDialog from '../OdysseyDialog'
import { previewUrlForContentType, uploadableDocsQuery } from './helpers'
import { helpersFileUploadableDocsQuery } from '../../__generated__/helpersFileUploadableDocsQuery.graphql'
import { FolderType } from './types'
import { fetchQuery } from 'react-relay'

interface Props {
  folder: FileCreationDialog_folder$key
  entityType: AttachmentEntityTypeEnum
  entitySlug: string
  open: boolean
  onClose: () => void
  folderType: FolderType
  files?: FileList | null
  documentType?: string
  isExternal?: boolean
}

const HiddenFileInput = styled.input`
  display: none;
`

const StyledDialogTitle = styled(DialogTitle)`
  padding-bottom: 0px;
`

const UploadableItemsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
`

const UploadableItem = styled(Paper).attrs({ variant: 'outlined' })`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 8px;
  padding: 8px;
`

const UploadablePreviewContainer = styled.div`
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const UploadablePreview = styled.img<{ $isIcon: boolean }>`
  height: ${(props) => (props.$isIcon ? '50px' : '100%')};
  width: ${(props) => (props.$isIcon ? '50px' : '100%')};
  object-fit: contain;
`

const UploadableFieldsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 8px;
`

const CenterContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 64px 0px;
`

const StyledUploadDialogContent = styled(DialogContent)<{ $dropHover: boolean }>`
  position: relative;

  & * {
    ${(props) =>
      props.$dropHover &&
      `
      pointer-events: none;
    `}
  }
`

const DropHoverContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* margin: 16px; */
  border: 5px dashed ${(props) => props.theme.palette.primary.light};
  color: ${(props) => props.theme.palette.primary.light};
  border-radius: 8px;
  background-color: ${(props) => props.theme.palette.background.paper};
  display: flex;
  align-items: center;
  justify-content: center;
`

const DropHover: React.FC<{ title: string }> = ({ title }) => (
  <DropHoverContainer>
    <Typography variant='h6'>{title}</Typography>
  </DropHoverContainer>
)

interface Uploadable {
  file: File
  id: string
  name: string
  contentType: string
  documentType?: string
}

const tabs = [
  { label: 'Generate', value: 'generate' },
  { label: 'Upload', value: 'upload' },
]

const IMAGE_FILE_SIZE_LIMIT = 1.5
const VIDEO_FILE_SIZE_LIMIT = 15

const FileCreationDialog: React.FC<Props> = ({
  folder: data,
  entityType,
  entitySlug,
  open,
  onClose,
  folderType,
  files,
  documentType,
  isExternal = false,
}) => {
  const folder = useFragment(fragment, data)
  const [tabValue, setTabValue] = useState(folderType === 'photos' ? 1 : files ? 1 : 0)

  return (
    <>
      <OdysseyDialog open={open} fullWidth onClose={onClose} scroll='paper' style={{ maxWidth: '960px', margin: 'auto' }}>
        {folderType === 'photos' ? (
          <DialogTitle>Photo upload for folder &gt; {folder.name}</DialogTitle>
        ) : (
          <>
            <StyledDialogTitle>Document creation for folder &gt; {folder.name}</StyledDialogTitle>
            <TabsUI tabs={tabs} value={tabValue} onChange={(_, newValue) => setTabValue(newValue)} />
          </>
        )}
        <TabPanel value={tabValue} index={0} noPadding>
          <Suspense fallback={<GenerateTab.Skeleton />}>
            <GenerateTab folderSlug={folder.slug} entityType={entityType} entitySlug={entitySlug} onClose={onClose} isExternal={isExternal} />
          </Suspense>
        </TabPanel>
        <TabPanel value={tabValue} index={1} noPadding>
          <Suspense fallback={<>Loading ...</>}>
            <UploadTab
              folderSlug={folder.slug}
              entityType={entityType}
              entitySlug={entitySlug}
              onClose={onClose}
              folderType={folderType}
              files={files}
              documentType={documentType}
            />
          </Suspense>
        </TabPanel>
      </OdysseyDialog>
    </>
  )
}

interface UploadTabProps {
  folderSlug: string
  entityType: AttachmentEntityTypeEnum
  entitySlug: string
  onClose: () => void
  folderType: FolderType
  files?: FileList | null
  documentType?: string
}

interface DropState {
  dropHover: boolean
}

const UploadTab: React.FC<UploadTabProps> = ({ folderSlug, entityType, entitySlug, onClose, folderType, files, documentType }) => {
  const [commitUpload, uploadIsInProgress] = useNiceMutation<FileCreationDialogUploadMutation>(uploadMutation)
  const [uploadError, setUploadError] = useState<null | string>(null)
  const [dropState, setDropState] = useState<DropState>({ dropHover: false })
  const uploadAttachments = (uploadableFiles = uploadables) => {
    setUploadError(null)
    commitUpload({
      variables: {
        input: {
          entityType: entityType,
          entitySlug: entitySlug,
          attachments: uploadableFiles.map((uploadable) => ({
            identifier: uploadable.id,
            name: uploadable.name,
            documentType: uploadable.documentType,
            folderSlug: folderSlug,
          })),
        },
      },
      uploadables: Object.fromEntries(uploadableFiles.map((uploadable) => [uploadable.id, uploadable.file])),
      onCompleted: (res, errors) => {
        if (errors) {
          setUploadError(errors.map((err) => err.message).join(', '))
        } else {
          resetUpload()
        }
      },
    })
  }

  const [uploadables, setUploadables] = useState<Uploadable[]>([])
  const [previewUrls, setPreviewUrls] = useState<{ [id: string]: string }>({})
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [fileInputRefNull, setFileInputRefFlag] = useState(true)

  useEffect(() => {
    if (files) {
      onFileInputChangeRef.current(files)
    }
  }, [files])

  const onFileInputChange = (incomingFiles: FileList | null) => {
    const files = incomingFiles || []
    const newUploadables: Uploadable[] = []

    for (let i = 0; i < files.length; i++) {
      const file = files[i]

      const fileSize = Number.parseFloat((file.size / 1024 / 1024).toFixed(4))

      const isImage = file.type.startsWith('image/')
      const isVideo = file.type.startsWith('video/')

      if (fileSize > IMAGE_FILE_SIZE_LIMIT && isImage) {
        setUploadError('Image size cannot be greater than 1.5MB')
        continue
      }
      if (fileSize > VIDEO_FILE_SIZE_LIMIT && isVideo) {
        setUploadError('Image size cannot be greater than 15MB')
        continue
      }

      newUploadables.push({
        file: file,
        name: file.name,
        id: file.name,
        contentType: file.type,
        documentType: documentType,
      })

      if (file.type.startsWith('image/')) {
        const fileReader = new FileReader()
        fileReader.onloadend = (evt: ProgressEvent) => {
          const reader: FileReader = evt.target as FileReader
          setPreviewUrls((prevState) => ({ ...prevState, [file.name]: reader.result as string }))
        }
        fileReader.readAsDataURL(file)
      }
    }
    setUploadables((oldUploadables) => {
      const newState = [...oldUploadables, ...newUploadables]
      if (files && folderType === 'photos') {
        uploadAttachments(newState)
      }

      return newState
    })
    setFileInputRefFlag(false)
  }

  const onFileInputChangeRef = useRef(onFileInputChange)

  useEffect(() => {
    onFileInputChangeRef.current = onFileInputChange
  })
  const triggerFileSelection = () => {
    fileInputRef.current?.click()
  }
  const resetUpload = () => {
    setUploadables([])
    setUploadError(null)
    setFileInputRefFlag(false)
    onClose()
  }

  const onDragEnter: DragEventHandler = (evt) => {
    evt.dataTransfer.dropEffect = 'copy'
    evt.preventDefault()
    if (!uploadIsInProgress) {
      setDropState({ dropHover: true })
    }
  }

  const onDragOver: DragEventHandler = (evt) => {
    evt.dataTransfer.dropEffect = 'copy'
    evt.preventDefault()
  }

  const onDragLeave = () => {
    setDropState({ dropHover: false })
  }

  const onDrop: DragEventHandler = (evt) => {
    evt.dataTransfer.dropEffect = 'copy'
    evt.preventDefault()
    onFileInputChange(evt.dataTransfer.files)
    setDropState({ dropHover: false })
  }

  const data = useLazyLoadQuery<helpersFileUploadableDocsQuery>(uploadableDocsQuery, {
    entityType: entityType,
  })

  const onUploadableChange = (idx: number, field: 'name' | 'documentType', value?: string) => {
    setUploadables((prevState) => [...prevState.slice(0, idx), { ...prevState[idx], [field]: value }, ...prevState.slice(idx + 1)])
  }

  return (
    <>
      <HiddenFileInput ref={fileInputRef} type='file' multiple onChange={(evt) => onFileInputChange(evt.target.files)} />
      <StyledUploadDialogContent
        dividers={true}
        onDragOver={onDragOver}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
        $dropHover={dropState.dropHover}
      >
        {uploadError && <Alert severity='error'>{uploadError}</Alert>}
        {uploadIsInProgress ? (
          <CenterContentContainer>
            <CircularProgress />
            <Typography variant='body1'>Uploading {uploadables.length} files</Typography>
          </CenterContentContainer>
        ) : uploadables.length === 0 ? (
          <>
            <CenterContentContainer>
              <Button onClick={triggerFileSelection}>Select files from your device</Button>
              <Typography variant='body2' color='textSecondary'>
                or
              </Typography>
              <Typography variant='body2'>Drag and Drop</Typography>
            </CenterContentContainer>
            {dropState.dropHover && <DropHover title='Drop to select' />}
          </>
        ) : (
          <>
            <UploadableItemsContainer>
              {uploadables.map((uploadable, idx) => (
                <UploadableItem key={uploadable.id}>
                  <UploadablePreviewContainer>
                    <UploadablePreview
                      src={previewUrls[uploadable.id] || previewUrlForContentType(uploadable.contentType)}
                      alt={uploadable.name}
                      $isIcon={!previewUrls[uploadable.id]}
                    />
                  </UploadablePreviewContainer>
                  <UploadableFieldsContainer>
                    <TextField
                      value={uploadable.name}
                      variant='outlined'
                      fullWidth
                      size='small'
                      onChange={(e) => onUploadableChange(idx, 'name', e.target.value)}
                    />
                    {folderType !== 'photos' && (
                      <Autocomplete
                        options={data.uploadableDocuments as { documentName: string; description: string }[]}
                        getOptionLabel={(option) => option.documentName}
                        defaultValue={data.uploadableDocuments?.find((doc) => doc.documentName === documentType)}
                        fullWidth
                        size='small'
                        onChange={(e, value) => onUploadableChange(idx, 'documentType', value?.documentName)}
                        renderInput={(params) => <TextField {...params} label='Category' variant='outlined' />}
                        disabled={!!documentType}
                      />
                    )}
                  </UploadableFieldsContainer>
                </UploadableItem>
              ))}
            </UploadableItemsContainer>
            {dropState.dropHover && <DropHover title='Drop to add' />}
          </>
        )}
      </StyledUploadDialogContent>
      <DialogActions>
        <Button variant='text' onClick={resetUpload}>
          Cancel
        </Button>
        <Button variant='contained' onClick={() => uploadAttachments()} disabled={uploadIsInProgress || fileInputRefNull}>
          Upload
        </Button>
      </DialogActions>
    </>
  )
}

interface GenerateTabProps {
  folderSlug: string
  entityType: AttachmentEntityTypeEnum
  entitySlug: string
  onClose: () => void
  isExternal: boolean
}

export const GenerateTab: React.FC<GenerateTabProps> & { Skeleton: React.FC } = ({ folderSlug, entityType, entitySlug, onClose }) => {
  const data = useLazyLoadQuery<FileCreationDialogGeneratableDocsQuery>(generatableDocsQuery, {
    entityType: entityType,
    entitySlug: entitySlug,
  })
  const environment = useRelayEnvironment()

  const refetch = () => {
    fetchQuery(environment, generatableDocsQuery, {
      entityType: entityType,
      entitySlug: entitySlug,
    })
  }

  const generatableDocuments = data.generatableDocuments

  return (
    <>
      <Divider />
      {generatableDocuments.length ? (
        generatableDocuments.map((generatableDoc) => (
          <GeneratableDocItem
            key={generatableDoc.templateSlug}
            document={generatableDoc}
            entityType={entityType}
            entitySlug={entitySlug}
            folderSlug={folderSlug}
            onGenerated={refetch}
          />
        ))
      ) : (
        <Typography variant='body1'>No generatable documents available</Typography>
      )}
      <DialogActions>
        <Button variant='text' onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </>
  )
}

GenerateTab.Skeleton = () => (
  <>
    <Divider />
    {[...Array(2)].map((_, idx) => (
      <GeneratableDocItem.Skeleton key={idx} />
    ))}
    <DialogActions>
      <Button variant='text' disabled>
        Cancel
      </Button>
    </DialogActions>
  </>
)

const fragment = graphql`
  fragment FileCreationDialog_folder on Folder {
    slug
    name
  }
`

const uploadMutation = graphql`
  mutation FileCreationDialogUploadMutation($input: infoUploadAttachmentsToEntityInput!) {
    infoUploadAttachmentsToEntity(input: $input) {
      clientMutationId
    }
  }
`

const generatableDocsQuery = graphql`
  query FileCreationDialogGeneratableDocsQuery($entityType: TemplateEntityTypeEnum!, $entitySlug: String!) {
    generatableDocuments(entityType: $entityType, entitySlug: $entitySlug) {
      templateSlug
      ...GeneratableDocItem_document
    }
  }
`

export default FileCreationDialog
