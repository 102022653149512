/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type DateFilterEnum = "all_time" | "last_month" | "last_seven_days" | "last_three_month" | "last_year" | "today" | "%future added value";
export type DashboardMainContentShipmentsQueryVariables = {
    handle: string;
    includeArchived?: string | null;
    dateFilter: DateFilterEnum;
};
export type DashboardMainContentShipmentsQueryResponse = {
    readonly dashboardShipmentsQuery: {
        readonly chartShipmentsByStage: {
            readonly labels: ReadonlyArray<string>;
            readonly values: ReadonlyArray<number>;
            readonly paths: ReadonlyArray<string>;
        };
        readonly chartProcessOverdue: {
            readonly labels: ReadonlyArray<string>;
            readonly values: ReadonlyArray<number>;
            readonly paths: ReadonlyArray<string>;
        };
        readonly shipmentBillingOverdue: {
            readonly labels: any;
            readonly values: any;
        };
        readonly tsShipmentEta: {
            readonly labels: ReadonlyArray<string>;
            readonly values: ReadonlyArray<number>;
            readonly paths: ReadonlyArray<string>;
            readonly startIndex: number | null;
        };
        readonly tsCostsAndRevenues: {
            readonly labels: ReadonlyArray<string>;
            readonly values: ReadonlyArray<{
                readonly revenue: number;
                readonly containerCost: number;
                readonly shipmentCost: number;
            }>;
            readonly paths: ReadonlyArray<string>;
            readonly startIndex: number | null;
        };
        readonly chartShipmentUnpaidConsignee: {
            readonly labels: ReadonlyArray<string>;
            readonly values: ReadonlyArray<number>;
            readonly paths: ReadonlyArray<string>;
        };
    } | null;
};
export type DashboardMainContentShipmentsQuery = {
    readonly response: DashboardMainContentShipmentsQueryResponse;
    readonly variables: DashboardMainContentShipmentsQueryVariables;
};



/*
query DashboardMainContentShipmentsQuery(
  $handle: String!
  $includeArchived: String
  $dateFilter: DateFilterEnum!
) {
  dashboardShipmentsQuery(handle: $handle, includeArchived: $includeArchived, dateFilter: $dateFilter) {
    chartShipmentsByStage {
      labels
      values
      paths
    }
    chartProcessOverdue {
      labels
      values
      paths
    }
    shipmentBillingOverdue {
      labels
      values
    }
    tsShipmentEta {
      labels
      values
      paths
      startIndex
    }
    tsCostsAndRevenues {
      labels
      values {
        revenue
        containerCost
        shipmentCost
      }
      paths
      startIndex
    }
    chartShipmentUnpaidConsignee {
      labels
      values
      paths
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "dateFilter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "handle"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includeArchived"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "labels",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "values",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paths",
  "storageKey": null
},
v6 = [
  (v3/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startIndex",
  "storageKey": null
},
v8 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "dateFilter",
        "variableName": "dateFilter"
      },
      {
        "kind": "Variable",
        "name": "handle",
        "variableName": "handle"
      },
      {
        "kind": "Variable",
        "name": "includeArchived",
        "variableName": "includeArchived"
      }
    ],
    "concreteType": "DashboardShipments",
    "kind": "LinkedField",
    "name": "dashboardShipmentsQuery",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ChartData",
        "kind": "LinkedField",
        "name": "chartShipmentsByStage",
        "plural": false,
        "selections": (v6/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ChartData",
        "kind": "LinkedField",
        "name": "chartProcessOverdue",
        "plural": false,
        "selections": (v6/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "TableData",
        "kind": "LinkedField",
        "name": "shipmentBillingOverdue",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ChartData",
        "kind": "LinkedField",
        "name": "tsShipmentEta",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v7/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CostRevenueChartData",
        "kind": "LinkedField",
        "name": "tsCostsAndRevenues",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CostRevenueChartValue",
            "kind": "LinkedField",
            "name": "values",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "revenue",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "containerCost",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "shipmentCost",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v5/*: any*/),
          (v7/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ChartData",
        "kind": "LinkedField",
        "name": "chartShipmentUnpaidConsignee",
        "plural": false,
        "selections": (v6/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "DashboardMainContentShipmentsQuery",
    "selections": (v8/*: any*/),
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "DashboardMainContentShipmentsQuery",
    "selections": (v8/*: any*/)
  },
  "params": {
    "cacheID": "f769ebebab8d5bdcbbb422b34618e750",
    "id": null,
    "metadata": {},
    "name": "DashboardMainContentShipmentsQuery",
    "operationKind": "query",
    "text": "query DashboardMainContentShipmentsQuery(\n  $handle: String!\n  $includeArchived: String\n  $dateFilter: DateFilterEnum!\n) {\n  dashboardShipmentsQuery(handle: $handle, includeArchived: $includeArchived, dateFilter: $dateFilter) {\n    chartShipmentsByStage {\n      labels\n      values\n      paths\n    }\n    chartProcessOverdue {\n      labels\n      values\n      paths\n    }\n    shipmentBillingOverdue {\n      labels\n      values\n    }\n    tsShipmentEta {\n      labels\n      values\n      paths\n      startIndex\n    }\n    tsCostsAndRevenues {\n      labels\n      values {\n        revenue\n        containerCost\n        shipmentCost\n      }\n      paths\n      startIndex\n    }\n    chartShipmentUnpaidConsignee {\n      labels\n      values\n      paths\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'e88e6c7e932047481d22dad00024bb89';
export default node;
