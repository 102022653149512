/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ExternalItemLink_item = {
    readonly title: string;
    readonly path: string | null;
    readonly itemType: string;
    readonly slug: string;
    readonly image: {
        readonly url: string | null;
    } | null;
    readonly " $refType": "ExternalItemLink_item";
};
export type ExternalItemLink_item$data = ExternalItemLink_item;
export type ExternalItemLink_item$key = {
    readonly " $data"?: ExternalItemLink_item$data;
    readonly " $fragmentRefs": FragmentRefs<"ExternalItemLink_item">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExternalItemLink_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "path",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "itemType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ExternalListItem",
  "abstractKey": null
};
(node as any).hash = 'afa0733884fef9942f8a8514f55018d8';
export default node;
