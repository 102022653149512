import { graphql } from 'babel-plugin-relay/macro'
import React from 'react'
import { useFragment } from 'react-relay/hooks'
import styled from 'styled-components'
import { ExternalFeedAttachments_data$key } from '../../__generated__/ExternalFeedAttachments_data.graphql'
import SmallAttachment from '../File/SmallAttachment'
import { media } from '../../theme'

interface Props {
  data: ExternalFeedAttachments_data$key
}

const Root = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 30px;
  grid-gap: 8px;

  ${media.small`
    grid-template-columns: repeat(3, 1fr);
  `}
`

const ExternalFeedAttachments: React.FC<Props> = ({ data }) => {
  const attachments = useFragment(imageFragment, data)

  return <Root>{attachments.nodes?.map((node) => node && <SmallAttachment key={node.slug} attachment={node} />)}</Root>
}

const imageFragment = graphql`
  fragment ExternalFeedAttachments_data on AttachmentConnection {
    nodes {
      slug
      ...SmallAttachment_attachment
    }
  }
`

export default ExternalFeedAttachments
