import { TextField, Typography } from '@material-ui/core'
import { AutocompleteRenderInputParams } from '@material-ui/lab'
import { graphql } from 'babel-plugin-relay/macro'
import { Field, useField } from 'formik'
import { Autocomplete } from 'formik-material-ui-lab'
import React from 'react'
import { useLazyLoadQuery } from 'react-relay/hooks'
import { PortFieldQuery } from '../../__generated__/PortFieldQuery.graphql'
import { FieldT, FieldVariantEnum } from './types'

interface Props {
  field: FieldT
  variant?: FieldVariantEnum
}

const PortField: React.FC<Props> & { Skeleton: React.FC } = ({ field }) => {
  const data = useLazyLoadQuery<PortFieldQuery>(query, { type: 'ports' })
  const options = data.settingList.items?.edges?.map((edge) => edge?.node)
  const [, meta, helpers] = useField(field.name)

  return (
    <Field
      name={field.name}
      component={Autocomplete}
      options={options}
      getOptionLabel={(option: any) => option.title}
      getOptionsSelected={(option: any, value: string) => option.slug === value}
      value={{ slug: meta.value, title: meta.value }}
      onChange={(_: any, value: any) => (value ? helpers.setValue(value.slug) : helpers.setValue(null))}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          error={meta.touched && !!meta.error}
          helperText={meta.touched ? meta.error : null}
          label={field.title}
          variant='outlined'
        />
      )}
      renderOption={(option: any) => (
        <div>
          <Typography variant='body1'>{option.slug}</Typography>
          <Typography variant='body2' color='textSecondary'>
            {option.title}
          </Typography>
        </div>
      )}
    />
  )
}

PortField.Skeleton = () => <>Loading...</>

const query = graphql`
  query PortFieldQuery($type: SettingTypeEnum!) {
    settingList(type: $type) {
      items {
        edges {
          node {
            slug
            title
          }
        }
      }
    }
  }
`

export default PortField
