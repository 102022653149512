import { graphql } from 'babel-plugin-relay/macro'
import React from 'react'
import { useLazyLoadQuery } from 'react-relay/hooks'
import { useNavigate } from 'react-router'
import FormActionBar from '../../components/Form/FormActionBar'
import NiceFormik from '../../components/Form/NiceFormik'
import ShipmentForm from '../../forms/Shipment/ShipmentForm'
import useWorkspace from '../../hooks/useWorkspace'
import useNiceMutation from '../../mutations/useNiceMutation'
import { ExternalShipmentAddFormQuery } from '../../__generated__/ExternalShipmentAddFormQuery.graphql'
import { ExternalShipmentAddMutation } from '../../__generated__/ExternalShipmentAddMutation.graphql'
import { inputHashToLinkedContacts } from '../../components/Form/LinkedContactsField'
import FormContainer from '../../forms/FormContainer'

interface Props {
  containerSlug?: string
  onClose?: () => void
}

const ExternalShipmentAdd: React.FC<Props> & { Skeleton: React.FC } = ({ containerSlug, onClose }) => {
  const [workspace] = useWorkspace()
  const navigate = useNavigate()
  const data = useLazyLoadQuery<ExternalShipmentAddFormQuery>(query, {})
  const fields = data.infoAddShipmentForm.formSchema.fieldSet
  const fieldMaster = Object.fromEntries(fields.map((field) => [field.name, field]))
  const gotoShipment = (slug: string) => navigate(`/${workspace}/shipment/${slug}/home`)

  const [commit] = useNiceMutation<ExternalShipmentAddMutation>(mutation)

  return (
    <>
      <NiceFormik
        initialValues={containerSlug ? { containerSlug, description: '' } : { description: '' }}
        onSubmit={(values, actions) => {
          const processedValues = {
            ...values,
            linkedContacts: inputHashToLinkedContacts((values as any).linkedContacts),
          }
          commit({
            variables: {
              input: processedValues as any,
            },
            onError: () => actions.setSubmitting(false),
            onCompleted: (res, errors) => {
              actions.setSubmitting(false)
              if (!errors) {
                gotoShipment(res.infoAddShipment?.shipment.slug || '')
              }
            },
          })
        }}
        // formSchema={fields}
        formSchema={fields.filter((field) => field.name !== 'vin')}
      >
        {({ submitForm }) => (
          <FormContainer>
            <ShipmentForm mode='add' fieldMaster={fieldMaster} />
            <FormActionBar
              title={'New Shipment'}
              onCancel={() => (onClose === undefined ? navigate(-1) : onClose())}
              onSubmit={() => submitForm()}
            />
          </FormContainer>
        )}
      </NiceFormik>
    </>
  )
}

const query = graphql`
  query ExternalShipmentAddFormQuery {
    infoAddShipmentForm {
      formSchema {
        fieldSet {
          name
          type
          required
          title
          description
          format
          enum
          enumTitles
          placeholder
        }
      }
    }
  }
`

const mutation = graphql`
  mutation ExternalShipmentAddMutation($input: infoAddShipmentInput!) {
    infoAddShipment(input: $input) {
      shipment {
        slug
      }
    }
  }
`

ExternalShipmentAdd.Skeleton = () => (
  <>
    <ShipmentForm.Skeleton />
    <FormActionBar.Skeleton />
  </>
)

export default ExternalShipmentAdd
