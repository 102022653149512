/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LiveShareEntityTypeEnum = "shipments" | "%future added value";
export type infoAuthLiveShareInput = {
    clientMutationId?: string | null;
    token: string;
    password: string;
    entitySlug: string;
    entityType: LiveShareEntityTypeEnum;
};
export type LiveShareAuthFormMutationVariables = {
    input: infoAuthLiveShareInput;
};
export type LiveShareAuthFormMutationResponse = {
    readonly infoAuthLiveShare: {
        readonly clientMutationId: string | null;
        readonly result: {
            readonly " $fragmentRefs": FragmentRefs<"LiveShareShipmentShow_shipmentShow">;
        };
    } | null;
};
export type LiveShareAuthFormMutation = {
    readonly response: LiveShareAuthFormMutationResponse;
    readonly variables: LiveShareAuthFormMutationVariables;
};



/*
mutation LiveShareAuthFormMutation(
  $input: infoAuthLiveShareInput!
) {
  infoAuthLiveShare(input: $input) {
    clientMutationId
    result {
      ...LiveShareShipmentShow_shipmentShow
    }
  }
}

fragment ItemImage_data on Image {
  url
  alt
  highlight
  shape
  overlay
  overlayIcon
}

fragment LiveShareEntityAttachment_attachment on LiveShareEntityAttachment {
  url
  downloadUrl
  name
  contentType
  previewUrl
  documentType
}

fragment LiveShareFolder_folder on LiveShareFolder {
  slug
  name
  attachments {
    name
    isImage
    ...LiveShareEntityAttachment_attachment
    id
  }
}

fragment LiveShareFoldersCard_shipmentShow on LiveShareShowShipment {
  folders {
    slug
    parentType
    ...LiveShareFolder_folder
  }
}

fragment LiveShareShipmentShowHeroCardQuery_item on ListItem {
  image {
    url
    ...ItemImage_data
  }
}

fragment LiveShareShipmentShowHeroCardQuery_shipment on LiveShareShipment {
  slug
  shipmentKey
  description
  shipmentType
  vin
  weight
  containerNumber
  workflowStages {
    value
    ...Stage_stage
  }
}

fragment LiveShareShipmentShowHomeContentQuery_shipmentShow on LiveShareShowShipment {
  ...LiveShareFoldersCard_shipmentShow
}

fragment LiveShareShipmentShow_shipmentShow on LiveShareShowShipment {
  shipment {
    slug
    ...LiveShareShipmentShowHeroCardQuery_shipment
    id
  }
  item {
    slug
    ...LiveShareShipmentShowHeroCardQuery_item
    id
  }
  ...LiveShareShipmentShowHomeContentQuery_shipmentShow
}

fragment Stage_stage on WorkflowStage {
  value
  status
  label
  completedAt
  ownedBy
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientMutationId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LiveShareAuthFormMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "infoAuthLiveSharePayload",
        "kind": "LinkedField",
        "name": "infoAuthLiveShare",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "LiveShareShowShipment",
            "kind": "LinkedField",
            "name": "result",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "LiveShareShipmentShow_shipmentShow"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ApplicationCommand",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LiveShareAuthFormMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "infoAuthLiveSharePayload",
        "kind": "LinkedField",
        "name": "infoAuthLiveShare",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "LiveShareShowShipment",
            "kind": "LinkedField",
            "name": "result",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LiveShareShipment",
                "kind": "LinkedField",
                "name": "shipment",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "shipmentKey",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "shipmentType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "vin",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "weight",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "containerNumber",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WorkflowStage",
                    "kind": "LinkedField",
                    "name": "workflowStages",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "value",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "label",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "completedAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "ownedBy",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ListItem",
                "kind": "LinkedField",
                "name": "item",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Image",
                    "kind": "LinkedField",
                    "name": "image",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "alt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "highlight",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "shape",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "overlay",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "overlayIcon",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "LiveShareFolder",
                "kind": "LinkedField",
                "name": "folders",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "parentType",
                    "storageKey": null
                  },
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LiveShareEntityAttachment",
                    "kind": "LinkedField",
                    "name": "attachments",
                    "plural": true,
                    "selections": [
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isImage",
                        "storageKey": null
                      },
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "downloadUrl",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "contentType",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "previewUrl",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "documentType",
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2a8bf0e03c042a62456f9a97ced7533b",
    "id": null,
    "metadata": {},
    "name": "LiveShareAuthFormMutation",
    "operationKind": "mutation",
    "text": "mutation LiveShareAuthFormMutation(\n  $input: infoAuthLiveShareInput!\n) {\n  infoAuthLiveShare(input: $input) {\n    clientMutationId\n    result {\n      ...LiveShareShipmentShow_shipmentShow\n    }\n  }\n}\n\nfragment ItemImage_data on Image {\n  url\n  alt\n  highlight\n  shape\n  overlay\n  overlayIcon\n}\n\nfragment LiveShareEntityAttachment_attachment on LiveShareEntityAttachment {\n  url\n  downloadUrl\n  name\n  contentType\n  previewUrl\n  documentType\n}\n\nfragment LiveShareFolder_folder on LiveShareFolder {\n  slug\n  name\n  attachments {\n    name\n    isImage\n    ...LiveShareEntityAttachment_attachment\n    id\n  }\n}\n\nfragment LiveShareFoldersCard_shipmentShow on LiveShareShowShipment {\n  folders {\n    slug\n    parentType\n    ...LiveShareFolder_folder\n  }\n}\n\nfragment LiveShareShipmentShowHeroCardQuery_item on ListItem {\n  image {\n    url\n    ...ItemImage_data\n  }\n}\n\nfragment LiveShareShipmentShowHeroCardQuery_shipment on LiveShareShipment {\n  slug\n  shipmentKey\n  description\n  shipmentType\n  vin\n  weight\n  containerNumber\n  workflowStages {\n    value\n    ...Stage_stage\n  }\n}\n\nfragment LiveShareShipmentShowHomeContentQuery_shipmentShow on LiveShareShowShipment {\n  ...LiveShareFoldersCard_shipmentShow\n}\n\nfragment LiveShareShipmentShow_shipmentShow on LiveShareShowShipment {\n  shipment {\n    slug\n    ...LiveShareShipmentShowHeroCardQuery_shipment\n    id\n  }\n  item {\n    slug\n    ...LiveShareShipmentShowHeroCardQuery_item\n    id\n  }\n  ...LiveShareShipmentShowHomeContentQuery_shipmentShow\n}\n\nfragment Stage_stage on WorkflowStage {\n  value\n  status\n  label\n  completedAt\n  ownedBy\n}\n"
  }
};
})();
(node as any).hash = '2afa9fc5b70c53b16acd6c91b04f69f5';
export default node;
