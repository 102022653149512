/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ContainerShowHeroCardContainerQueryVariables = {
    slug: string;
};
export type ContainerShowHeroCardContainerQueryResponse = {
    readonly containerShow: {
        readonly slug: string;
        readonly shipmentItems: ReadonlyArray<{
            readonly slug: string;
            readonly title: string;
            readonly children: ReadonlyArray<{
                readonly slug: string;
                readonly title: string;
                readonly badges: ReadonlyArray<{
                    readonly key: string;
                    readonly " $fragmentRefs": FragmentRefs<"Badge_data">;
                }> | null;
                readonly lines: ReadonlyArray<{
                    readonly key: string;
                    readonly parts: ReadonlyArray<{
                        readonly " $fragmentRefs": FragmentRefs<"SemanticString_data">;
                    }> | null;
                    readonly " $fragmentRefs": FragmentRefs<"SemanticLine_data">;
                }> | null;
            }> | null;
            readonly badges: ReadonlyArray<{
                readonly key: string;
                readonly " $fragmentRefs": FragmentRefs<"Badge_data">;
            }> | null;
            readonly image: {
                readonly url: string | null;
            } | null;
            readonly lines: ReadonlyArray<{
                readonly key: string;
                readonly parts: ReadonlyArray<{
                    readonly " $fragmentRefs": FragmentRefs<"SemanticString_data">;
                }> | null;
                readonly " $fragmentRefs": FragmentRefs<"SemanticLine_data">;
            }> | null;
        }>;
    };
};
export type ContainerShowHeroCardContainerQuery = {
    readonly response: ContainerShowHeroCardContainerQueryResponse;
    readonly variables: ContainerShowHeroCardContainerQueryVariables;
};



/*
query ContainerShowHeroCardContainerQuery(
  $slug: String!
) {
  containerShow(slug: $slug) {
    slug
    shipmentItems {
      slug
      title
      children {
        slug
        title
        badges {
          key
          ...Badge_data
        }
        lines {
          key
          parts {
            ...SemanticString_data
          }
          ...SemanticLine_data
        }
        id
      }
      badges {
        key
        ...Badge_data
      }
      image {
        url
      }
      lines {
        key
        parts {
          ...SemanticString_data
        }
        ...SemanticLine_data
      }
      id
    }
    id
  }
}

fragment Badge_data on Badge {
  type
}

fragment SemanticLine_data on SemanticLine {
  parts {
    key
    ...SemanticString_data
  }
}

fragment SemanticString_data on SemanticString {
  text
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Badge",
  "kind": "LinkedField",
  "name": "badges",
  "plural": true,
  "selections": [
    (v4/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Badge_data"
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "SemanticLine",
  "kind": "LinkedField",
  "name": "lines",
  "plural": true,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "SemanticString",
      "kind": "LinkedField",
      "name": "parts",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SemanticString_data"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SemanticLine_data"
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "Image",
  "kind": "LinkedField",
  "name": "image",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "Badge",
  "kind": "LinkedField",
  "name": "badges",
  "plural": true,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "SemanticLine",
  "kind": "LinkedField",
  "name": "lines",
  "plural": true,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "SemanticString",
      "kind": "LinkedField",
      "name": "parts",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        },
        (v4/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ContainerShowHeroCardContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Container",
        "kind": "LinkedField",
        "name": "containerShow",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ListItem",
            "kind": "LinkedField",
            "name": "shipmentItems",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ListItem",
                "kind": "LinkedField",
                "name": "children",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/),
              (v7/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ContainerShowHeroCardContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Container",
        "kind": "LinkedField",
        "name": "containerShow",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ListItem",
            "kind": "LinkedField",
            "name": "shipmentItems",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ListItem",
                "kind": "LinkedField",
                "name": "children",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/),
              (v7/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/)
            ],
            "storageKey": null
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "341e1c0cc991241926b5e773874368d0",
    "id": null,
    "metadata": {},
    "name": "ContainerShowHeroCardContainerQuery",
    "operationKind": "query",
    "text": "query ContainerShowHeroCardContainerQuery(\n  $slug: String!\n) {\n  containerShow(slug: $slug) {\n    slug\n    shipmentItems {\n      slug\n      title\n      children {\n        slug\n        title\n        badges {\n          key\n          ...Badge_data\n        }\n        lines {\n          key\n          parts {\n            ...SemanticString_data\n          }\n          ...SemanticLine_data\n        }\n        id\n      }\n      badges {\n        key\n        ...Badge_data\n      }\n      image {\n        url\n      }\n      lines {\n        key\n        parts {\n          ...SemanticString_data\n        }\n        ...SemanticLine_data\n      }\n      id\n    }\n    id\n  }\n}\n\nfragment Badge_data on Badge {\n  type\n}\n\nfragment SemanticLine_data on SemanticLine {\n  parts {\n    key\n    ...SemanticString_data\n  }\n}\n\nfragment SemanticString_data on SemanticString {\n  text\n}\n"
  }
};
})();
(node as any).hash = 'c8e5e2718d73fba098b464432ce38037';
export default node;
