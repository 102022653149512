/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type infoRevokeAccessToggleInput = {
    clientMutationId?: string | null;
    revokeAccess: boolean;
    username: string;
};
export type RevokeAccessMutationVariables = {
    input: infoRevokeAccessToggleInput;
};
export type RevokeAccessMutationResponse = {
    readonly infoRevokeAccessToggle: {
        readonly clientMutationId: string | null;
    } | null;
};
export type RevokeAccessMutation = {
    readonly response: RevokeAccessMutationResponse;
    readonly variables: RevokeAccessMutationVariables;
};



/*
mutation RevokeAccessMutation(
  $input: infoRevokeAccessToggleInput!
) {
  infoRevokeAccessToggle(input: $input) {
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "infoRevokeAccessTogglePayload",
    "kind": "LinkedField",
    "name": "infoRevokeAccessToggle",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RevokeAccessMutation",
    "selections": (v1/*: any*/),
    "type": "ApplicationCommand",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RevokeAccessMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e1174dde657cb56430d7c2da7eb7ab1a",
    "id": null,
    "metadata": {},
    "name": "RevokeAccessMutation",
    "operationKind": "mutation",
    "text": "mutation RevokeAccessMutation(\n  $input: infoRevokeAccessToggleInput!\n) {\n  infoRevokeAccessToggle(input: $input) {\n    clientMutationId\n  }\n}\n"
  }
};
})();
(node as any).hash = '932d6f278e980a5c07438a727925fc73';
export default node;
