/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type infoDeleteAllAttachmentsInput = {
    clientMutationId?: string | null;
    folderSlug: string;
};
export type FolderDeleteAllAttactmentsMutationVariables = {
    input: infoDeleteAllAttachmentsInput;
};
export type FolderDeleteAllAttactmentsMutationResponse = {
    readonly infoDeleteAllAttachments: {
        readonly clientMutationId: string | null;
    } | null;
};
export type FolderDeleteAllAttactmentsMutation = {
    readonly response: FolderDeleteAllAttactmentsMutationResponse;
    readonly variables: FolderDeleteAllAttactmentsMutationVariables;
};



/*
mutation FolderDeleteAllAttactmentsMutation(
  $input: infoDeleteAllAttachmentsInput!
) {
  infoDeleteAllAttachments(input: $input) {
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "infoDeleteAllAttachmentsPayload",
    "kind": "LinkedField",
    "name": "infoDeleteAllAttachments",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FolderDeleteAllAttactmentsMutation",
    "selections": (v1/*: any*/),
    "type": "ApplicationCommand",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FolderDeleteAllAttactmentsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5ddcc4c1bc428f1d21a7efd97950f6b0",
    "id": null,
    "metadata": {},
    "name": "FolderDeleteAllAttactmentsMutation",
    "operationKind": "mutation",
    "text": "mutation FolderDeleteAllAttactmentsMutation(\n  $input: infoDeleteAllAttachmentsInput!\n) {\n  infoDeleteAllAttachments(input: $input) {\n    clientMutationId\n  }\n}\n"
  }
};
})();
(node as any).hash = '135eb017814deb5a2786f6147a08e0c4';
export default node;
