import { Button } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import React from 'react'
import { useLocation } from 'react-router'
import pluralize from 'pluralize'

// import Button from './Button'

interface Props {
  error: Error
  onRetry?: () => void
}

type Severity = 'error' | 'warning' | 'info' | 'success' | undefined

const ErrorBox: React.FC<Props> = ({ error, onRetry }) => {
  let title = error.name.replace('_', ' ')
  let body = error.message
  let severity: Severity = 'error'
  const workSpace = useLocation().pathname.split('/')[1]
  const entity = useLocation().pathname.split('/')[2]

  switch (error.name) {
    case 'HTTP_0': {
      severity = 'warning'
      title = 'Failed to connect'
      body = 'Please check your network settings.  If your network is fine, the server is probably down...'
      break
    }
    // case 'HTTP_404': {
    //   severity = 'warning'
    //   title = 'Not found'
    //   body = 'The page you are looking for was not found.'
    //   break
    // }
    case 'HTTP_503': {
      severity = 'info'
      title = 'Server is likely under maintenance'
      body = "The server is unavailable.  It's either crashed or under scheduled maintenance"
      break
    }
  }

  if (error.message.search('500') !== -1) {
    severity = 'error'
    title = 'Internal server error.'
    body = 'If the problems continues to occur please contact the team.'
  }

  // if (error.message.search('Cannot return null') !== -1) {
  //   severity = 'warning'
  //   title = 'Page not found.'
  //   body = 'The page you are looking for was not found.'
  // }

  if (error.message.search('Not authorized') !== -1) {
    severity = 'error'
    title = 'Not authorized'
    body = 'access denied'
    window.location.reload()
  }
  if (error.message.search('Record not found') !== -1) {
    severity = 'error'
    title = 'Record not found'
    body = `${entity} not found`
    window.setTimeout(function () {
      window.location.href = `/${workSpace}/${pluralize(entity)}`
    }, 3000)
  }

  return (
    <Alert
      severity={severity}
      style={{ whiteSpace: 'pre-wrap' }}
      action={
        onRetry && (
          <Button onClick={onRetry} color='inherit' size='small'>
            RETRY
          </Button>
        )
      }
    >
      <AlertTitle>{title}</AlertTitle>
      {body}
      {/* <div>
        {onRetry && <Button onClick={onRetry}>Retry</Button>}
      </div> */}
    </Alert>
  )
}

export default ErrorBox
