/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type StageStatusEnum = "active" | "completed" | "incomplete" | "%future added value";
export type SubStage_data = {
    readonly stageIdx: number | null;
    readonly stage: string | null;
    readonly value: string | null;
    readonly displayValue: string | null;
    readonly status: StageStatusEnum;
    readonly label: string;
    readonly validSubStages: ReadonlyArray<{
        readonly value: string;
        readonly displayValue: string;
    }> | null;
    readonly " $refType": "SubStage_data";
};
export type SubStage_data$data = SubStage_data;
export type SubStage_data$key = {
    readonly " $data"?: SubStage_data$data;
    readonly " $fragmentRefs": FragmentRefs<"SubStage_data">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayValue",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SubStage_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "stageIdx",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "stage",
      "storageKey": null
    },
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ValidSubStage",
      "kind": "LinkedField",
      "name": "validSubStages",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "SubStage",
  "abstractKey": null
};
})();
(node as any).hash = 'fa6c2d5aa8481863c754b78863f2c1f1';
export default node;
