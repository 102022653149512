import React from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import styled from 'styled-components'
import { List, ListItem, ListItemText, Paper, Typography } from '@material-ui/core'
import { useLazyLoadQuery } from 'react-relay/hooks'
import { ExchangeRatesCardQuery } from '../../__generated__/ExchangeRatesCardQuery.graphql'
import { Skeleton } from '@material-ui/lab'
import { useDateTimeDisplay } from '../../hooks/timeZoneDisplay'

const MetricContainer = styled.div`
  padding: 16px;
`
const RootPaper = styled(Paper)`
  margin-bottom: 16px;
  ${MetricContainer}:first-of-type {
    border-right: 1px solid ${(props) => props.theme.palette.divider};
  }
`

const ExchangeRatesCard: React.FC & { Skeleton: React.FC } = () => {
  const { exchangeRatesList: exchangeRates } = useLazyLoadQuery<ExchangeRatesCardQuery>(query, {})

  return (
    <RootPaper variant='outlined'>
      <MetricContainer>
        <Typography variant='h6'>Exchange Rates</Typography>
        <Typography variant='subtitle2'>
          Last Updated at:{' '}
          {exchangeRates[1].lastUpdatedAt === null ? 'never' : useDateTimeDisplay(exchangeRates[1].displayLastUpdatedAt as string)}{' '}
        </Typography>
        <List>
          <ListItem>
            {exchangeRates &&
              exchangeRates.map((rate) => (
                <>
                  <ListItemText primary={rate.code} secondary={rate.eurExchangeRate} />
                </>
              ))}
          </ListItem>
        </List>
      </MetricContainer>
    </RootPaper>
  )
}

const query = graphql`
  query ExchangeRatesCardQuery {
    exchangeRatesList {
      code
      eurExchangeRate
      lastUpdatedAt
      displayLastUpdatedAt
    }
  }
`

ExchangeRatesCard.Skeleton = () => (
  <RootPaper variant='outlined'>
    <MetricContainer>
      <Typography variant='h6'>Exchange Rates</Typography>
      <Skeleton>
        <Typography variant='subtitle2'>Last Updated at:</Typography>
      </Skeleton>
      <List>
        <ListItem>
          <ListItemText primary={<Skeleton />} secondary={<Skeleton />} />
          <ListItemText primary={<Skeleton />} secondary={<Skeleton />} />
          <ListItemText primary={<Skeleton />} secondary={<Skeleton />} />
          <ListItemText primary={<Skeleton />} secondary={<Skeleton />} />
        </ListItem>
      </List>
    </MetricContainer>
  </RootPaper>
)

export default ExchangeRatesCard
