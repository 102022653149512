import { useCallback, useEffect, useMemo, useState } from 'react'
import { Data, RevenueData } from '../types'

interface PaginationState {
  startIdx: number
  endIdx: number
  perPage: number
}

interface PaginatedBarI<DataT> {
  paginatedData: DataT
  hasNextPage: boolean
  hasPrevPage: boolean
  canZoomIn: boolean
  canZoomOut: boolean
  paginate: PaginationFunction
  zoom: ZoomFunction
}

type PaginationFunction = (direction: 'forward' | 'backward') => void
type ZoomFunction = (direction: 'in' | 'out') => void

const usePaginatedBarChart = <DataT extends Data | RevenueData>(data: DataT): PaginatedBarI<DataT> => {
  const dataLength = data.values?.length || 0
  const clamped = useCallback((value: number) => Math.max(Math.min(value, dataLength), 0), [dataLength])
  const [paginationState, setPaginationState] = useState<PaginationState>({
    startIdx: data.startIndex || 0,
    endIdx: data.startIndex ? clamped(data.startIndex + 10) : 10,
    perPage: 10,
  })
  const paginatedData = useMemo(() => {
    const { startIdx, endIdx } = paginationState
    return {
      ...data,
      labels: data.labels?.slice(startIdx, endIdx) || null,
      values: data.values?.slice(startIdx, endIdx) || null,
      paths: data.paths?.slice(startIdx, endIdx) || null,
    }
  }, [paginationState, data])

  const paginate: PaginationFunction = (direction) => {
    setPaginationState((prevState) => {
      const startIdx = direction === 'forward' ? prevState.endIdx : clamped(prevState.startIdx - prevState.perPage)
      const endIdx = direction === 'forward' ? clamped(prevState.endIdx + prevState.perPage) : prevState.startIdx
      return {
        ...prevState,
        startIdx: startIdx,
        endIdx: endIdx,
      }
    })
  }

  const zoom: ZoomFunction = (direction) =>
    setPaginationState((prevState) => ({ ...prevState, perPage: direction === 'in' ? prevState.perPage - 10 : prevState.perPage + 10 }))

  useEffect(() => {
    setPaginationState((prevState) => {
      const startIdx = clamped(prevState.endIdx - prevState.perPage)
      const endIdx = startIdx + prevState.perPage
      return {
        ...prevState,
        startIdx: startIdx,
        endIdx: endIdx,
      }
    })
  }, [paginationState.perPage, clamped])

  return {
    paginatedData: paginatedData,
    hasNextPage: paginationState.endIdx < dataLength,
    hasPrevPage: paginationState.startIdx > 0,
    canZoomIn: paginationState.perPage > 10,
    canZoomOut: paginationState.perPage < dataLength,
    paginate: paginate,
    zoom: zoom,
  }
}

export default usePaginatedBarChart
