import React from 'react'
import { Field as FormikField } from 'formik'
import { TextField } from 'formik-material-ui'
import styled from 'styled-components'
import { FieldVariantEnum } from './types'

interface Props {
  of?: string | number
  variant?: FieldVariantEnum
}

const StyledField = styled(FormikField)`
  max-width: 400px;
  border: 1px solid red;
`

const BadInputField: React.FC<Props> = ({ of, variant }) => {
  return (
    <StyledField
      key={of}
      name={of}
      label={of}
      disabled={true}
      multiline={variant === 'large'}
      variant='outlined'
      fullWidth
      component={TextField}
    />
  )
}

export default BadInputField
