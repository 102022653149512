import { Typography, Tooltip } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'
import { RemoveCircle, CheckCircle, Cancel } from '@material-ui/icons'
import { green, grey, red } from '@material-ui/core/colors'

interface Props {
  label: string
  value?: string | number | JSX.Element | null
  validated?: boolean | null
  validated_at?: string | null
}

const Container = styled.div`
  width: 100%;
`

const Label = styled(Typography)`
  font-size: 12px;
  color: #333;
  display: block;
`

const Value = styled(Typography)`
  color: #333;
  font-weight: 500;
  display: inline;
  box-sizing: content-box;
  white-space: nowrap;
  overflow: hidden;
  line-height: 24px;
  text-overflow: ellipsis;
  vertical-align: middle;
`

const ValidationField: React.FC<Props> = ({ label, value, validated, validated_at }) => {
  return (
    <Container>
      <Label variant='caption' color='textSecondary'>
        {label}
      </Label>
      <Value variant='body1'>{value === null || value === undefined || (typeof value === 'string' && value.length === 0) ? '--' : value}</Value>
      <Tooltip title={validated === null || validated === undefined ? 'Not Validated' : `Last validated on ${validated_at}`} arrow>
        {(validated === true && <CheckCircle style={{ color: green[500], fontSize: 18, verticalAlign: 'middle' }}></CheckCircle>) ||
          (validated === false && <Cancel style={{ color: red[500], fontSize: 18, verticalAlign: 'middle' }}></Cancel>) || (
            <RemoveCircle style={{ color: grey[500], fontSize: 18, verticalAlign: 'middle' }}></RemoveCircle>
          )}
      </Tooltip>
    </Container>
  )
}

export default ValidationField
