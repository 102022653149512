import React, { DragEventHandler, Suspense, useRef, useState } from 'react'
import { graphql } from 'babel-plugin-relay/macro'

import styled from 'styled-components'
import { Button, CircularProgress, DialogActions, DialogContent, DialogTitle, MenuItem, Paper, TextField, Typography } from '@material-ui/core'
import { useLazyLoadQuery } from 'react-relay/hooks'
import { SanctionListIdentifierTypeEnum, SanctionListQuery } from '../../__generated__/SanctionListQuery.graphql'
import PrimaryButton from '../../components/PrimaryButton'
import OdysseyDialog from '../../components/OdysseyDialog'
import useNiceMutation from '../../mutations/useNiceMutation'
import { SanctionListUploadMutation } from '../../__generated__/SanctionListUploadMutation.graphql'
import { Alert } from '@material-ui/lab'
import { previewUrlForContentType } from '../../components/File/helpers'
import DataTable from 'react-data-table-component'

const RootPaper = styled(Paper)``

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
`
const HiddenFileInput = styled.input`
  display: none;
`

const UploadableItemsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
`

const UploadableItem = styled(Paper).attrs({ variant: 'outlined' })`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 8px;
  padding: 8px;
`

const UploadablePreviewContainer = styled.div`
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const UploadablePreview = styled.img<{ $isIcon: boolean }>`
  height: ${(props) => (props.$isIcon ? '50px' : '100%')};
  width: ${(props) => (props.$isIcon ? '50px' : '100%')};
  object-fit: contain;
`

const UploadableFieldsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 8px;
`

const CenterContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 64px 0px;
`

const StyledUploadDialogContent = styled(DialogContent)<{ $dropHover: boolean }>`
  position: relative;

  & * {
    ${(props) =>
      props.$dropHover &&
      `
      pointer-events: none;
    `}
  }
`

const DropHoverContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* margin: 16px; */
  border: 5px dashed ${(props) => props.theme.palette.primary.light};
  color: ${(props) => props.theme.palette.primary.light};
  border-radius: 8px;
  background-color: ${(props) => props.theme.palette.background.paper};
  display: flex;
  align-items: center;
  justify-content: center;
`

const DropHover: React.FC<{ title: string }> = ({ title }) => (
  <DropHoverContainer>
    <Typography variant='h6'>{title}</Typography>
  </DropHoverContainer>
)

const SanctionList: React.FC = () => {
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false)
  return (
    <>
      <RootPaper variant='outlined'>
        <TitleContainer>
          <div>
            <Typography variant='h5'>Sanction List</Typography>
            <Typography variant='body1'>Sanction list records</Typography>
          </div>
          <PrimaryButton onClick={() => setUploadDialogOpen(true)}>Upload File</PrimaryButton>
        </TitleContainer>
        <Suspense fallback={<SanctionListWithParams.Skeleton />}>
          <SanctionListWithParams />
        </Suspense>
      </RootPaper>
      <UploadDialog open={uploadDialogOpen} onClose={() => setUploadDialogOpen(false)} />
    </>
  )
}

interface DialogProps {
  open: boolean
  onClose: () => void
}

interface DropState {
  dropHover: boolean
}

interface Uploadable {
  file: File
  id: string
  contentType: string
  identifier: SanctionListIdentifierTypeEnum
}

const identifierOptions: SanctionListIdentifierTypeEnum[] = ['EU', 'US', 'UN']

const UploadDialog = ({ open, onClose }: DialogProps) => {
  const [commitUpload, uploadIsInProgress] = useNiceMutation<SanctionListUploadMutation>(uploadMutation)
  const [uploadError, setUploadError] = useState<null | string>(null)
  const [dropState, setDropState] = useState<DropState>({ dropHover: false })
  const uploadAttachments = () => {
    setUploadError(null)
    commitUpload({
      variables: {
        input: {
          identifier: uploadable?.identifier || 'EU',
        },
      },
      /* eslint-disable  @typescript-eslint/no-non-null-assertion */
      uploadables: { file: uploadable!.file },
      onCompleted: (res, errors) => {
        if (errors) {
          setUploadError(errors.map((err) => err.message).join(', '))
        } else {
          resetUpload()
        }
      },
    })
  }

  const [uploadable, setUploadable] = useState<Uploadable | null>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const onFileInputChange = (incomingFiles: FileList | null) => {
    const file = incomingFiles?.[0]
    if (!file) return

    const newUploadable: Uploadable = {
      file: file,
      id: file.name,
      contentType: file.type,
      identifier: 'EU',
    }

    setUploadable(newUploadable)
  }

  const triggerFileSelection = () => {
    fileInputRef.current?.click()
  }

  const resetUpload = () => {
    setUploadable(null)
    setUploadError(null)
    onClose()
  }

  const onDragEnter: DragEventHandler = (evt) => {
    evt.dataTransfer.dropEffect = 'copy'
    evt.preventDefault()
    if (!uploadIsInProgress) {
      setDropState({ dropHover: true })
    }
  }

  const onDragOver: DragEventHandler = (evt) => {
    evt.dataTransfer.dropEffect = 'copy'
    evt.preventDefault()
  }

  const onDragLeave = () => {
    setDropState({ dropHover: false })
  }

  const onDrop: DragEventHandler = (evt) => {
    evt.dataTransfer.dropEffect = 'copy'
    evt.preventDefault()
    onFileInputChange(evt.dataTransfer.files)
    setDropState({ dropHover: false })
  }

  const onUploadableChange = (value: SanctionListIdentifierTypeEnum) => {
    setUploadable((prevState) => {
      if (!prevState) return null
      return {
        ...prevState,
        identifier: value,
      }
    })
  }

  return (
    <OdysseyDialog open={open} fullWidth onClose={onClose} scroll='paper' style={{ maxWidth: '960px', margin: 'auto' }}>
      <DialogTitle>Upload Documents</DialogTitle>
      <HiddenFileInput
        ref={fileInputRef}
        type='file'
        accept='text/csv,application/xml'
        onChange={(evt) => onFileInputChange(evt.target.files)}
      />
      <StyledUploadDialogContent
        dividers={true}
        onDragOver={onDragOver}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
        $dropHover={dropState.dropHover}
      >
        {uploadError && <Alert severity='error'>{uploadError}</Alert>}
        {uploadIsInProgress ? (
          <CenterContentContainer>
            <CircularProgress />
            <Typography variant='body1'>Uploading files</Typography>
          </CenterContentContainer>
        ) : !uploadable ? (
          <>
            <CenterContentContainer>
              <Button onClick={triggerFileSelection}>Select files from your device</Button>
              <Typography variant='body2' color='textSecondary'>
                or
              </Typography>
              <Typography variant='body2'>Drag and Drop</Typography>
            </CenterContentContainer>
            {dropState.dropHover && <DropHover title='Drop to select' />}
          </>
        ) : (
          <>
            <UploadableItemsContainer>
              <UploadableItem key={uploadable.id}>
                <UploadablePreviewContainer>
                  <UploadablePreview src={previewUrlForContentType(uploadable.contentType)} alt={uploadable.id} $isIcon={true} />
                </UploadablePreviewContainer>
                <UploadableFieldsContainer>
                  <TextField
                    value={uploadable.identifier}
                    variant='outlined'
                    select
                    fullWidth
                    size='small'
                    onChange={(e) => onUploadableChange(e.target.value as SanctionListIdentifierTypeEnum)}
                  >
                    {identifierOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </UploadableFieldsContainer>
              </UploadableItem>
            </UploadableItemsContainer>
            {dropState.dropHover && <DropHover title='Drop to add' />}
          </>
        )}
      </StyledUploadDialogContent>
      <DialogActions>
        <Button variant='text' onClick={resetUpload}>
          Cancel
        </Button>
        <Button variant='contained' onClick={uploadAttachments} disabled={uploadIsInProgress || uploadable === null}>
          Upload
        </Button>
      </DialogActions>
    </OdysseyDialog>
  )
}

const SanctionListWithParams: React.FC & { Skeleton: React.FC } = () => {
  const data = useLazyLoadQuery<SanctionListQuery>(query, {})

  return (
    <>
      <DataTable
        data-tag='allowRowEvents'
        data={data.sanctionList as any[]}
        columns={[
          {
            id: 'slug',
            name: 'Id #',
            width: '250px',
            sortable: false,
            selector: 'slug',
          },
          {
            id: 'identifier',
            name: 'List Type',
            width: '100px',
            sortable: false,
            selector: 'identifier',
          },
          {
            id: 'firstName',
            name: 'First Name',
            sortable: false,
            selector: 'firstName',
          },
          {
            id: 'lastName',
            name: 'Last Name',
            sortable: false,
            selector: 'lastName',
          },
        ]}
        pagination
        paginationPerPage={30}
        highlightOnHover
      />
      {/* {data.apiLogList.paginationMeta && data.apiLogList.paginationMeta.totalPages > 1 && (
        <PaginationContainer>
          <Pagination
            shape='rounded'
            count={data.apiLogList.paginationMeta.totalPages}
            page={page}
            onChange={(_, newPage) => onPageChange(newPage)}
          />
        </PaginationContainer>
      )} */}
    </>
  )
}

SanctionListWithParams.Skeleton = () => <></>

const query = graphql`
  query SanctionListQuery {
    sanctionList {
      slug
      identifier
      firstName
      lastName
    }
  }
`

const uploadMutation = graphql`
  mutation SanctionListUploadMutation($input: infoUploadSanctionListInput!) {
    infoUploadSanctionList(input: $input) {
      clientMutationId
    }
  }
`

export default SanctionList
