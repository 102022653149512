import { graphql } from 'babel-plugin-relay/macro'

export const previewUrlForContentType = (contentType: string | undefined) =>
  `https://drive-thirdparty.googleusercontent.com/64/type/${contentType}`

export const previewForAttachment = (attachment: { contentType: string; previewUrl: string | null }) => {
  const previewUrl = attachment.previewUrl ? attachment.previewUrl : previewUrlForContentType(attachment.contentType)
  const isIconPreview = attachment.previewUrl ? false : true

  return { previewUrl, isIconPreview }
}

export const uploadableDocsQuery = graphql`
  query helpersFileUploadableDocsQuery($entityType: TemplateEntityTypeEnum!) {
    uploadableDocuments(entityType: $entityType) {
      documentName
      description
    }
  }
`
