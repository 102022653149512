/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ItemListHeader_data = {
    readonly sortKeys: ReadonlyArray<{
        readonly key: string;
        readonly title: string;
    }>;
    readonly totalCount: number | null;
    readonly downloadToken: string;
    readonly " $refType": "ItemListHeader_data";
};
export type ItemListHeader_data$data = ItemListHeader_data;
export type ItemListHeader_data$key = {
    readonly " $data"?: ItemListHeader_data$data;
    readonly " $fragmentRefs": FragmentRefs<"ItemListHeader_data">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ItemListHeader_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SortKey",
      "kind": "LinkedField",
      "name": "sortKeys",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "key",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "downloadToken",
      "storageKey": null
    }
  ],
  "type": "ItemList",
  "abstractKey": null
};
(node as any).hash = '575d462b7f73a88b13c0a21e9976fb54';
export default node;
