import { Card, Divider, TablePagination, Typography } from '@material-ui/core'
import { graphql } from 'babel-plugin-relay/macro'
import React, { useState } from 'react'
import { fetchQuery } from 'react-relay'
import { useLazyLoadQuery, useRelayEnvironment } from 'react-relay/hooks'
import { useNavigate } from 'react-router'
import styled from 'styled-components'
import AddSettingDialog from '../../components/AddSettingDialog'
import Button from '../../components/Button'
import SettingsItem from '../../components/SettingsItem'
import useWorkspace from '../../hooks/useWorkspace'
import { SettingsCardQuery } from '../../__generated__/SettingsCardQuery.graphql'
import { SettingType, tabDefinitions } from './tabs'

interface Props {
  type: SettingType
  detailed?: 'detailed'
}

const Root = styled.div`
  margin-bottom: 30px;
`

const Header = styled.div`
  padding: 8px 16px 2px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const Caption = styled.div`
  padding: 1px 16px 8px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const SettingsCard: React.FC<Props> = ({ type, detailed }) => {
  const tab = tabDefinitions[type] || tabDefinitions.home
  const navigate = useNavigate()
  const [workspace] = useWorkspace()
  const environment = useRelayEnvironment()
  const [page, setPage] = useState(0)
  const [perPage, setPerPage] = useState(detailed ? 25 : 5)
  const { settingList } = useLazyLoadQuery<SettingsCardQuery>(query, { type: type, page: page + 1, perPage: perPage })
  const [addDialogOpen, setAddDialogOpen] = useState(false)

  const refresh = () => {
    fetchQuery(environment, query, { type: type, page: page + 1 || 1, perPage: detailed ? 25 : 8 })
  }

  const addActionTitle = `New ${tab.entity}`

  return (
    <Root>
      <AddSettingDialog type={type} open={addDialogOpen} onClose={() => setAddDialogOpen(false)} onAdd={refresh} title={addActionTitle} />
      <Card variant='outlined'>
        <Header>
          <Typography variant='h6'>{tab.label}</Typography>
          <div>
            <Button variant='text' onClick={() => setAddDialogOpen(true)}>
              {addActionTitle}
            </Button>
            {!detailed && (
              <Button variant='text' onClick={() => navigate(`/${workspace}/workspace/${tab.value}`)}>
                See All
              </Button>
            )}
          </div>
        </Header>
        <Caption>
          <div>
            <Typography variant='subtitle2'>{tab.description}</Typography>
          </div>
        </Caption>
        <Divider variant='fullWidth' component='hr' />

        {settingList &&
          settingList.items &&
          settingList.items.edges &&
          settingList.items.edges.map(
            (edge) => edge && edge.node && <SettingsItem key={edge.node.slug} settingsItem={edge.node} detailed={detailed} />
          )}
        <Footer>
          {settingList.paginate && settingList.paginate.totalPages > 0 && (
            <>
              <TablePagination
                component='div'
                count={settingList.totalCount}
                page={page}
                onChangePage={(_, page) => {
                  setPage(page)
                }}
                rowsPerPage={perPage}
                rowsPerPageOptions={detailed ? [25, 50, 100] : []}
                onChangeRowsPerPage={(e) => {
                  setPerPage(parseInt(e.target.value))
                  setPage(0)
                }}
              />
            </>
          )}
        </Footer>
      </Card>
    </Root>
  )
}

const query = graphql`
  query SettingsCardQuery($type: SettingTypeEnum!, $page: Int, $perPage: Int) {
    settingList(type: $type, page: $page, perPage: $perPage) {
      items {
        edges {
          node {
            slug
            ...SettingsItem_settingsItem
          }
        }
      }
      totalCount
      paginate {
        hasNextPage
        hasPrevPage
        page
        perPage
        totalPages
      }
    }
  }
`

export default SettingsCard
