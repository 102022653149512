import React, { useState } from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import { useFragment } from 'react-relay/hooks'
import { LinkCard_data$key } from '../../__generated__/LinkCard_data.graphql'
import styled from 'styled-components'
import { Avatar, Typography } from '@material-ui/core'
import SemanticString from '../SemanticString'
import { isSmall, media } from '../../theme'
import VoyageLinkCard from './VoyageLinkCard'
import VesselLinkCard from './VesselLinkCard'
import ShipmentLinkCard from './ShipmentLinkCard'
import ContainerLinkCard from './ContainerLinkCard'
import ContactLinkCard from './ContactLinkCard'
import ShowPageInDrawer from '../ShowPageInDrawer'
import WorkspacedLink from '../WorkspacedLink'

interface Props {
  data: LinkCard_data$key
  voyageKey?: string | null
}

const StyledRoot = styled.div`
  padding: 8px;
  border: 1px solid ${(props) => props.theme.customPalette.border.card};
  border-radius: 4px;

  &:hover {
    border-color: ${(props) => props.theme.palette.primary.main};
  }

  ${media.small`
    padding: unset;
    border: unset;
  `}
`

const LinkAvatar = styled(Avatar)`
  width: 32px;
  height: 32px;
  margin-right: 8px;

  ${media.small`
    margin-right: unset;
  `}
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`

const LinkCard: React.FC<Props> = ({ data, voyageKey = null }) => {
  const link = useFragment(linkFragment, data)
  const linkCardContent = () => {
    switch (link.type) {
      case 'voyage':
        return <VoyageLinkCard data={link} voyageKey={voyageKey} />
      case 'vessel':
        return <VesselLinkCard data={link} />
      case 'shipment':
        return <ShipmentLinkCard data={link} />
      case 'container':
        return <ContainerLinkCard data={link} />
      case 'contact':
        return <ContactLinkCard data={link} />
      default:
        return (
          <StyledRoot>
            <TitleContainer>
              {!!link.imageUrl && <LinkAvatar alt={link.type || ''} src={link.imageUrl} />}
              {link.title && !isSmall() && (
                <Typography variant='body1'>
                  <SemanticString data={link.title} />
                </Typography>
              )}
            </TitleContainer>
          </StyledRoot>
        )
    }
  }

  const [showPageOpen, setShowPageOpen] = useState(false)
  const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (e.ctrlKey) return

    e.preventDefault()
    setShowPageOpen(true)
  }

  return (
    <>
      <WorkspacedLink to={`/${link.type}/${link.entitySlug}`} onClick={handleLinkClick}>
        {linkCardContent()}
      </WorkspacedLink>
      <ShowPageInDrawer entityType={link.type} entitySlug={link.entitySlug} open={showPageOpen} onClose={() => setShowPageOpen(false)} />
    </>
  )
}

const linkFragment = graphql`
  fragment LinkCard_data on Link {
    type
    title {
      ...SemanticString_data
    }
    meta {
      ...SemanticString_data
    }
    lines {
      key
      ...SemanticLine_data
    }
    imageUrl
    entitySlug
    ...VoyageLinkCard_data
    ...VesselLinkCard_data
    ...ShipmentLinkCard_data
    ...ContainerLinkCard_data
    ...ContactLinkCard_data
  }
`

export default LinkCard
