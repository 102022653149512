/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type UploadDocumentsWizard_shipment = {
    readonly slug: string;
    readonly requiredDocuments: ReadonlyArray<string> | null;
    readonly " $refType": "UploadDocumentsWizard_shipment";
};
export type UploadDocumentsWizard_shipment$data = UploadDocumentsWizard_shipment;
export type UploadDocumentsWizard_shipment$key = {
    readonly " $data"?: UploadDocumentsWizard_shipment$data;
    readonly " $fragmentRefs": FragmentRefs<"UploadDocumentsWizard_shipment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UploadDocumentsWizard_shipment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "requiredDocuments",
      "storageKey": null
    }
  ],
  "type": "Shipment",
  "abstractKey": null
};
(node as any).hash = '80d6e700f81145b2e6b4e3187ef50b7b';
export default node;
