/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type WatchersShipmentQueryVariables = {
    slug: string;
};
export type WatchersShipmentQueryResponse = {
    readonly shipmentShow: {
        readonly watches: {
            readonly " $fragmentRefs": FragmentRefs<"Watchers_watches">;
        };
    };
};
export type WatchersShipmentQuery = {
    readonly response: WatchersShipmentQueryResponse;
    readonly variables: WatchersShipmentQueryVariables;
};



/*
query WatchersShipmentQuery(
  $slug: String!
) {
  shipmentShow(slug: $slug) {
    watches {
      ...Watchers_watches
    }
    id
  }
}

fragment Watchers_watches on ItemWatchConnection {
  nodes {
    contactSlug
    contactItem {
      title
      image {
        url
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WatchersShipmentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Shipment",
        "kind": "LinkedField",
        "name": "shipmentShow",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ItemWatchConnection",
            "kind": "LinkedField",
            "name": "watches",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "Watchers_watches"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WatchersShipmentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Shipment",
        "kind": "LinkedField",
        "name": "shipmentShow",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ItemWatchConnection",
            "kind": "LinkedField",
            "name": "watches",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ItemWatch",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "contactSlug",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ListItem",
                    "kind": "LinkedField",
                    "name": "contactItem",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Image",
                        "kind": "LinkedField",
                        "name": "image",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "url",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a5cf213e3a1c3972a50c0137bc072d72",
    "id": null,
    "metadata": {},
    "name": "WatchersShipmentQuery",
    "operationKind": "query",
    "text": "query WatchersShipmentQuery(\n  $slug: String!\n) {\n  shipmentShow(slug: $slug) {\n    watches {\n      ...Watchers_watches\n    }\n    id\n  }\n}\n\nfragment Watchers_watches on ItemWatchConnection {\n  nodes {\n    contactSlug\n    contactItem {\n      title\n      image {\n        url\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'eae716d7df6b1b2ed30883285729c9db';
export default node;
