import React from 'react'
import { Link, LinkProps } from 'react-router-dom'
import styled from 'styled-components'
import useWorkspace from '../hooks/useWorkspace'

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`

const WorkspacedLink: React.FC<LinkProps> = ({ to, ...rest }) => {
  const [workspace] = useWorkspace()
  return <StyledLink to={`/${workspace}${to || ''}`} {...rest} />
}

export default WorkspacedLink
