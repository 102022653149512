/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type SanctionJobLogsQueryVariables = {};
export type SanctionJobLogsQueryResponse = {
    readonly sanctionLogList: ReadonlyArray<{
        readonly lastRun: string;
        readonly status: boolean;
        readonly count: number;
        readonly contacts: ReadonlyArray<{
            readonly slug: string;
            readonly name: string;
        }> | null;
    }> | null;
};
export type SanctionJobLogsQuery = {
    readonly response: SanctionJobLogsQueryResponse;
    readonly variables: SanctionJobLogsQueryVariables;
};



/*
query SanctionJobLogsQuery {
  sanctionLogList {
    lastRun
    status
    count
    contacts {
      slug
      name
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastRun",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "count",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SanctionJobLogsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SanctionListLog",
        "kind": "LinkedField",
        "name": "sanctionLogList",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Contact",
            "kind": "LinkedField",
            "name": "contacts",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SanctionJobLogsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "SanctionListLog",
        "kind": "LinkedField",
        "name": "sanctionLogList",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Contact",
            "kind": "LinkedField",
            "name": "contacts",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "979aa41eeef2b8768da9d9e77d4deef2",
    "id": null,
    "metadata": {},
    "name": "SanctionJobLogsQuery",
    "operationKind": "query",
    "text": "query SanctionJobLogsQuery {\n  sanctionLogList {\n    lastRun\n    status\n    count\n    contacts {\n      slug\n      name\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '13b7564e1a0b30d76d292db9a0a64d7f';
export default node;
