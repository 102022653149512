/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type infoUploadImageInput = {
    clientMutationId?: string | null;
    category?: string | null;
    caption?: string | null;
};
export type ImageUploadMutationVariables = {
    input: infoUploadImageInput;
};
export type ImageUploadMutationResponse = {
    readonly infoUploadImage: {
        readonly imageSlug: string;
    } | null;
};
export type ImageUploadMutation = {
    readonly response: ImageUploadMutationResponse;
    readonly variables: ImageUploadMutationVariables;
};



/*
mutation ImageUploadMutation(
  $input: infoUploadImageInput!
) {
  infoUploadImage(input: $input) {
    imageSlug
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "infoUploadImagePayload",
    "kind": "LinkedField",
    "name": "infoUploadImage",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "imageSlug",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ImageUploadMutation",
    "selections": (v1/*: any*/),
    "type": "ApplicationCommand",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ImageUploadMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "49fbd598e1258bce68e93541d8c0e5db",
    "id": null,
    "metadata": {},
    "name": "ImageUploadMutation",
    "operationKind": "mutation",
    "text": "mutation ImageUploadMutation(\n  $input: infoUploadImageInput!\n) {\n  infoUploadImage(input: $input) {\n    imageSlug\n  }\n}\n"
  }
};
})();
(node as any).hash = 'a1b27124daea4c566094ac3b713ee304';
export default node;
