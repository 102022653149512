/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ListPageFilter_filterValue = {
    readonly label: string;
    readonly displayLabel: string;
    readonly selected: boolean;
    readonly count: string;
    readonly " $refType": "ListPageFilter_filterValue";
};
export type ListPageFilter_filterValue$data = ListPageFilter_filterValue;
export type ListPageFilter_filterValue$key = {
    readonly " $data"?: ListPageFilter_filterValue$data;
    readonly " $fragmentRefs": FragmentRefs<"ListPageFilter_filterValue">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ListPageFilter_filterValue",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayLabel",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "selected",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "count",
      "storageKey": null
    }
  ],
  "type": "FilterValue",
  "abstractKey": null
};
(node as any).hash = '1ffddedd4fb39a836b36cf0f22f09c84';
export default node;
