import { Typography } from '@material-ui/core'
import { graphql } from 'babel-plugin-relay/macro'
import React from 'react'
import { useFragment } from 'react-relay/hooks'
import styled from 'styled-components'
import { LinkSet_data$key, LinkEnum } from '../../__generated__/LinkSet_data.graphql'
import LinkCard from './LinkCard'

interface Props {
  data: LinkSet_data$key
  type?: LinkEnum
}

const Root = styled.div`
  width: 100%;
`

const TitleContainer = styled.div`
  position: relative;
  padding-left: 16px;
  margin-bottom: 4px;

  &::before {
    content: '';
    border-bottom: 1px solid ${(props) => props.theme.palette.divider};
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    z-index: 0;
  }
`

const Title = styled(Typography)`
  background-color: ${(props) => props.theme.palette.background.paper};
  padding: 0 8px;
  position: relative;
  z-index: 1;
`

const LinksContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 47.5%);
  grid-gap: 8px;
`

const LinkSet: React.FC<Props> = ({ data, type }) => {
  const linkSet = useFragment(linkSetFragment, data)
  return (
    <Root>
      {linkSet.inline !== true && (
        <TitleContainer>
          <Title variant='caption'>{linkSet.title}</Title>
        </TitleContainer>
      )}
      <LinksContainer>
        {linkSet.links
          ?.filter((link) => !type || link.type === type)
          .map((link, idx) => (
            <LinkCard key={`${link.key}-${idx}`} data={link} />
          ))}
      </LinksContainer>
    </Root>
  )
}

const linkSetFragment = graphql`
  fragment LinkSet_data on LinkSet {
    title
    inline
    links {
      key
      type
      ...LinkCard_data
    }
  }
`

export default LinkSet
