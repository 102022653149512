/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ShipmentFinanceMetricCard_shipment = {
    readonly slug: string;
    readonly totalCost: number;
    readonly totalRevenue: number;
    readonly totalProfit: number;
    readonly shipmentTypeDetails: {
        readonly vatChargePercent: number | null;
    } | null;
    readonly estimatedRevenueWarehousing: number | null;
    readonly vatCalculator: number | null;
    readonly lexofficeInvoiceAmountPaid: number;
    readonly " $refType": "ShipmentFinanceMetricCard_shipment";
};
export type ShipmentFinanceMetricCard_shipment$data = ShipmentFinanceMetricCard_shipment;
export type ShipmentFinanceMetricCard_shipment$key = {
    readonly " $data"?: ShipmentFinanceMetricCard_shipment$data;
    readonly " $fragmentRefs": FragmentRefs<"ShipmentFinanceMetricCard_shipment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ShipmentFinanceMetricCard_shipment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCost",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalRevenue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalProfit",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ShipmentTypeDetails",
      "kind": "LinkedField",
      "name": "shipmentTypeDetails",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "vatChargePercent",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "estimatedRevenueWarehousing",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vatCalculator",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lexofficeInvoiceAmountPaid",
      "storageKey": null
    }
  ],
  "type": "Shipment",
  "abstractKey": null
};
(node as any).hash = '43ea53cf25a35a76274f69aec6d7fc53';
export default node;
