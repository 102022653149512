import React, { useEffect } from 'react'
import { Field as FormikField, ErrorMessage } from 'formik'
import { CheckboxWithLabel, Switch } from 'formik-material-ui'
import styled from 'styled-components'
import { FieldT, FieldVariantEnum } from './types'
import { FormControlLabel } from '@material-ui/core'
import { useFieldFast } from '../../hooks/useFieldFast'

interface Props {
  field: FieldT
  variant?: FieldVariantEnum
  fieldVariant?: 'checkbox' | 'switch'
}

const StyledField = styled(FormikField)`
  max-width: 800px;
`

const StyledFormControlLabel = styled(FormControlLabel)`
  align-items: flex-start;
  justify-content: flex-end;
`

const BooleanField: React.FC<Props> = ({ field, fieldVariant = 'checkbox' }) => {
  const [, meta, helpers] = useFieldFast(field.name)

  useEffect(() => {
    if (meta.value !== true && meta.value !== false) {
      helpers.setValue(false)
    }
  }, [meta.value, helpers])

  return (
    <>
      {fieldVariant === 'checkbox' ? (
        <StyledField component={CheckboxWithLabel} type='checkbox' name={field.name} Label={{ label: field.title }} />
      ) : (
        <StyledFormControlLabel
          control={<StyledField component={Switch} type='checkbox' name={field.name} Label={{ label: field.title }} />}
          label={field.title}
          labelPlacement='top'
        />
      )}
      <ErrorMessage name={field.name} />
    </>
  )
}

export default BooleanField
