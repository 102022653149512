/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ShowLinkedContactsItem_linkedContact = {
    readonly role: string;
    readonly roles: ReadonlyArray<string>;
    readonly contactSlug: string;
    readonly contactItem: {
        readonly slug: string;
        readonly title: string;
        readonly isArchived: boolean;
        readonly path: string | null;
        readonly itemType: string;
        readonly statuses: ReadonlyArray<{
            readonly key: string;
            readonly value: {
                readonly " $fragmentRefs": FragmentRefs<"SemanticString_data">;
            };
        }> | null;
        readonly image: {
            readonly " $fragmentRefs": FragmentRefs<"ItemImage_data">;
        } | null;
        readonly lines: ReadonlyArray<{
            readonly key: string;
            readonly " $fragmentRefs": FragmentRefs<"SemanticLine_data">;
        }> | null;
        readonly keyFields: ReadonlyArray<{
            readonly key: string;
            readonly " $fragmentRefs": FragmentRefs<"KeyField_data">;
        }> | null;
        readonly stickyNoteContent: string | null;
    };
    readonly " $refType": "ShowLinkedContactsItem_linkedContact";
};
export type ShowLinkedContactsItem_linkedContact$data = ShowLinkedContactsItem_linkedContact;
export type ShowLinkedContactsItem_linkedContact$key = {
    readonly " $data"?: ShowLinkedContactsItem_linkedContact$data;
    readonly " $fragmentRefs": FragmentRefs<"ShowLinkedContactsItem_linkedContact">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ShowLinkedContactsItem_linkedContact",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "role",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "roles",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contactSlug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ListItem",
      "kind": "LinkedField",
      "name": "contactItem",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isArchived",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "path",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "itemType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Status",
          "kind": "LinkedField",
          "name": "statuses",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "SemanticString",
              "kind": "LinkedField",
              "name": "value",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "SemanticString_data"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Image",
          "kind": "LinkedField",
          "name": "image",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ItemImage_data"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SemanticLine",
          "kind": "LinkedField",
          "name": "lines",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SemanticLine_data"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "KeyField",
          "kind": "LinkedField",
          "name": "keyFields",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "KeyField_data"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "stickyNoteContent",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ContactLink",
  "abstractKey": null
};
})();
(node as any).hash = 'd8fcd8ed835367eed776d9a65b1e04b0';
export default node;
