/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ShowItemTypeEnum = "contact" | "container" | "shipment" | "vessel" | "voyage" | "%future added value";
export type ShipmentShowHeroCardQueryVariables = {
    slug: string;
    itemType: ShowItemTypeEnum;
};
export type ShipmentShowHeroCardQueryResponse = {
    readonly itemShow: {
        readonly slug: string;
        readonly title: string;
        readonly stickyNoteContent: string | null;
        readonly stickyNoteContentByExternal: string | null;
        readonly image: {
            readonly url: string | null;
            readonly " $fragmentRefs": FragmentRefs<"ItemImage_data">;
        } | null;
        readonly badges: ReadonlyArray<{
            readonly key: string;
            readonly " $fragmentRefs": FragmentRefs<"Badge_data">;
        }> | null;
        readonly lines: ReadonlyArray<{
            readonly key: string;
            readonly " $fragmentRefs": FragmentRefs<"SemanticLine_data">;
        }> | null;
        readonly keyFields: ReadonlyArray<{
            readonly key: string;
            readonly " $fragmentRefs": FragmentRefs<"KeyField_data">;
        }> | null;
    };
    readonly shipmentShow: {
        readonly slug: string;
        readonly isArchived: boolean;
        readonly stage: string;
        readonly shipmentKey: string;
        readonly subStages: ReadonlyArray<{
            readonly stageIdx: number | null;
            readonly " $fragmentRefs": FragmentRefs<"SubStage_data">;
        }>;
        readonly " $fragmentRefs": FragmentRefs<"ShipmentWorkflow_shipment">;
    };
};
export type ShipmentShowHeroCardQuery = {
    readonly response: ShipmentShowHeroCardQueryResponse;
    readonly variables: ShipmentShowHeroCardQueryVariables;
};



/*
query ShipmentShowHeroCardQuery(
  $slug: String!
  $itemType: ShowItemTypeEnum!
) {
  itemShow(slug: $slug, type: $itemType) {
    slug
    title
    stickyNoteContent
    stickyNoteContentByExternal
    image {
      url
      ...ItemImage_data
    }
    badges {
      key
      ...Badge_data
    }
    lines {
      key
      ...SemanticLine_data
    }
    keyFields {
      key
      ...KeyField_data
    }
    id
  }
  shipmentShow(slug: $slug) {
    slug
    isArchived
    stage
    shipmentKey
    subStages {
      stageIdx
      ...SubStage_data
    }
    ...ShipmentWorkflow_shipment
    id
  }
}

fragment Badge_data on Badge {
  type
}

fragment ItemImage_data on Image {
  url
  alt
  highlight
  shape
  overlay
  overlayIcon
}

fragment KeyField_data on KeyField {
  label
  value {
    key
    ...SemanticString_data
  }
}

fragment SemanticLine_data on SemanticLine {
  parts {
    key
    ...SemanticString_data
  }
}

fragment SemanticString_data on SemanticString {
  text
}

fragment ShipmentWorkflow_shipment on Shipment {
  slug
  automaticHandover
  workflowStages {
    value
    label
    ownedBy
    status
    ...Stage_stage
  }
}

fragment Stage_stage on WorkflowStage {
  value
  status
  label
  completedAt
  ownedBy
}

fragment SubStage_data on SubStage {
  stageIdx
  stage
  value
  displayValue
  status
  label
  validSubStages {
    value
    displayValue
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "itemType"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "slug"
},
v2 = {
  "kind": "Variable",
  "name": "slug",
  "variableName": "slug"
},
v3 = [
  (v2/*: any*/),
  {
    "kind": "Variable",
    "name": "type",
    "variableName": "itemType"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stickyNoteContent",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stickyNoteContentByExternal",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v10 = [
  (v2/*: any*/)
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isArchived",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stage",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shipmentKey",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stageIdx",
  "storageKey": null
},
v15 = [
  (v9/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "text",
    "storageKey": null
  }
],
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayValue",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ShipmentShowHeroCardQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "ListItem",
        "kind": "LinkedField",
        "name": "itemShow",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Image",
            "kind": "LinkedField",
            "name": "image",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ItemImage_data"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Badge",
            "kind": "LinkedField",
            "name": "badges",
            "plural": true,
            "selections": [
              (v9/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "Badge_data"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SemanticLine",
            "kind": "LinkedField",
            "name": "lines",
            "plural": true,
            "selections": [
              (v9/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SemanticLine_data"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "KeyField",
            "kind": "LinkedField",
            "name": "keyFields",
            "plural": true,
            "selections": [
              (v9/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "KeyField_data"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v10/*: any*/),
        "concreteType": "Shipment",
        "kind": "LinkedField",
        "name": "shipmentShow",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SubStage",
            "kind": "LinkedField",
            "name": "subStages",
            "plural": true,
            "selections": [
              (v14/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SubStage_data"
              }
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ShipmentWorkflow_shipment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ShipmentShowHeroCardQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "ListItem",
        "kind": "LinkedField",
        "name": "itemShow",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Image",
            "kind": "LinkedField",
            "name": "image",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "alt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "highlight",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "shape",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "overlay",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "overlayIcon",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Badge",
            "kind": "LinkedField",
            "name": "badges",
            "plural": true,
            "selections": [
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SemanticLine",
            "kind": "LinkedField",
            "name": "lines",
            "plural": true,
            "selections": [
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SemanticString",
                "kind": "LinkedField",
                "name": "parts",
                "plural": true,
                "selections": (v15/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "KeyField",
            "kind": "LinkedField",
            "name": "keyFields",
            "plural": true,
            "selections": [
              (v9/*: any*/),
              (v16/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SemanticString",
                "kind": "LinkedField",
                "name": "value",
                "plural": true,
                "selections": (v15/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v17/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v10/*: any*/),
        "concreteType": "Shipment",
        "kind": "LinkedField",
        "name": "shipmentShow",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SubStage",
            "kind": "LinkedField",
            "name": "subStages",
            "plural": true,
            "selections": [
              (v14/*: any*/),
              (v12/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v16/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ValidSubStage",
                "kind": "LinkedField",
                "name": "validSubStages",
                "plural": true,
                "selections": [
                  (v18/*: any*/),
                  (v19/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "automaticHandover",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "WorkflowStage",
            "kind": "LinkedField",
            "name": "workflowStages",
            "plural": true,
            "selections": [
              (v18/*: any*/),
              (v16/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ownedBy",
                "storageKey": null
              },
              (v20/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "completedAt",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v17/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "640b1bd229f68ded6cd44a0cb55b9ca5",
    "id": null,
    "metadata": {},
    "name": "ShipmentShowHeroCardQuery",
    "operationKind": "query",
    "text": "query ShipmentShowHeroCardQuery(\n  $slug: String!\n  $itemType: ShowItemTypeEnum!\n) {\n  itemShow(slug: $slug, type: $itemType) {\n    slug\n    title\n    stickyNoteContent\n    stickyNoteContentByExternal\n    image {\n      url\n      ...ItemImage_data\n    }\n    badges {\n      key\n      ...Badge_data\n    }\n    lines {\n      key\n      ...SemanticLine_data\n    }\n    keyFields {\n      key\n      ...KeyField_data\n    }\n    id\n  }\n  shipmentShow(slug: $slug) {\n    slug\n    isArchived\n    stage\n    shipmentKey\n    subStages {\n      stageIdx\n      ...SubStage_data\n    }\n    ...ShipmentWorkflow_shipment\n    id\n  }\n}\n\nfragment Badge_data on Badge {\n  type\n}\n\nfragment ItemImage_data on Image {\n  url\n  alt\n  highlight\n  shape\n  overlay\n  overlayIcon\n}\n\nfragment KeyField_data on KeyField {\n  label\n  value {\n    key\n    ...SemanticString_data\n  }\n}\n\nfragment SemanticLine_data on SemanticLine {\n  parts {\n    key\n    ...SemanticString_data\n  }\n}\n\nfragment SemanticString_data on SemanticString {\n  text\n}\n\nfragment ShipmentWorkflow_shipment on Shipment {\n  slug\n  automaticHandover\n  workflowStages {\n    value\n    label\n    ownedBy\n    status\n    ...Stage_stage\n  }\n}\n\nfragment Stage_stage on WorkflowStage {\n  value\n  status\n  label\n  completedAt\n  ownedBy\n}\n\nfragment SubStage_data on SubStage {\n  stageIdx\n  stage\n  value\n  displayValue\n  status\n  label\n  validSubStages {\n    value\n    displayValue\n  }\n}\n"
  }
};
})();
(node as any).hash = 'c57297da930c265b35b2223c8ccda36a';
export default node;
