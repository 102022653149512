import { graphql } from 'babel-plugin-relay/macro'
import React from 'react'
import styled from 'styled-components'
import LiveShareFoldersCard from '../../components/File/LiveShareFoldersCard'
import ResponsiveGrid from '../../components/ResponsiveGrid'
import { LiveShareShipmentShowHomeContentQuery_shipmentShow$key } from '../../__generated__/LiveShareShipmentShowHomeContentQuery_shipmentShow.graphql'
import { useFragment } from 'react-relay/hooks'

interface Props {
  shipmentShow: LiveShareShipmentShowHomeContentQuery_shipmentShow$key
}

const Root = styled.div`
  padding: 16px;
`

const LiveShareShipmentShowHomeContent: React.FC<Props> & { Skeleton: React.FC } = ({ shipmentShow: data }) => {
  const shipmentShow = useFragment(shipmentFragment, data)

  return (
    <Root>
      <ResponsiveGrid
        type='two-column-layout'
        left={[<LiveShareFoldersCard key='documents' folderType='documents' shipmentShow={shipmentShow} title='Documents' />]}
        right={[<LiveShareFoldersCard key='photos' folderType='photos' shipmentShow={shipmentShow} title='Photos' />]}
      />
    </Root>
  )
}
const shipmentFragment = graphql`
  fragment LiveShareShipmentShowHomeContentQuery_shipmentShow on LiveShareShowShipment {
    # slug
    ...LiveShareFoldersCard_shipmentShow
  }
`

LiveShareShipmentShowHomeContent.Skeleton = () => <>Loading...</>

export default LiveShareShipmentShowHomeContent
