import { Button, DialogActions, DialogContent, DialogTitle, Snackbar, TextField } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { graphql } from 'babel-plugin-relay/macro'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import styled from 'styled-components'
import useNiceMutation from '../../mutations/useNiceMutation'
import { UpdatePasswordDialogMutation } from '../../__generated__/UpdatePasswordDialogMutation.graphql'
import OdysseyDialog from '../../components/OdysseyDialog'

interface Props {
  open: boolean
  onClose: () => void
}

const PassInput = styled(TextField)`
  margin-bottom: 8px;
`

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const ChangePasswordDialog: React.FC<Props> = ({ open, onClose }) => {
  const [oldPass, setOldPass] = useState<string | null>(null)
  const [newPass, setNewPass] = useState<string | null>(null)
  const [confPass, setConfPass] = useState<string | null>(null)
  const [status, setStatus] = useState<string | null>(null)
  const [commit_pass] = useNiceMutation<UpdatePasswordDialogMutation>(password_mutation)
  const [error, setError] = useState<string | null>(null)
  const { enqueueSnackbar } = useSnackbar()

  const cleanInput = () => {
    setOldPass('')
    setNewPass('')
    setConfPass('')
  }

  const clearState = () => {
    setOldPass(null)
    setNewPass(null)
    setConfPass(null)
    setStatus(null)
  }

  const updatePassword = () => {
    if (newPass === confPass) {
      newPass &&
        oldPass &&
        commit_pass({
          variables: { input: { newPass: newPass, oldPass: oldPass } },
          onCompleted: (_, err) => {
            if (!err) {
              clearState()
              setStatus('success')
              enqueueSnackbar('Password updated successfully', { variant: 'success' })
              onClose()
            }
          },
          onError: (err) => {
            setStatus(`Failed to update password: ${err}`)
          },
        })
    } else {
      cleanInput()
      setStatus("New and confirm passwords don't match")
    }
  }

  return (
    <>
      <OdysseyDialog
        fullWidth
        open={open}
        maxWidth='xs'
        onClose={() => {
          clearState()
          onClose()
        }}
      >
        <DialogTitle>Change Password</DialogTitle>
        <DialogContent>
          <FormContainer>
            <PassInput
              required
              variant='outlined'
              value={oldPass}
              onChange={(e) => setOldPass(e.target.value)}
              label='Old Password'
              type='password'
            />
            <PassInput
              required
              error={!!error}
              helperText={error && error}
              variant='outlined'
              value={newPass}
              onChange={(e) => setNewPass(e.target.value)}
              label='New Password'
              type='password'
            />
            <PassInput
              required
              error={!!error}
              helperText={error && error}
              variant='outlined'
              value={confPass}
              onChange={(e) => setConfPass(e.target.value)}
              label='Confirm Password'
              type='password'
            />
          </FormContainer>
        </DialogContent>
        <DialogActions>
          <Button
            variant='text'
            onClick={() => {
              clearState()
              onClose()
            }}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            color='primary'
            disabled={!oldPass || !newPass || !confPass}
            onClick={() => {
              if (newPass === confPass) updatePassword()
              else setError("Password doesn't match")
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </OdysseyDialog>
      <Snackbar
        open={!!status && status !== 'success'}
        onClose={() => setStatus(null)}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert severity='error'>{status}</Alert>
      </Snackbar>
      <Snackbar
        open={!!status && status === 'success'}
        onClose={() => setStatus(null)}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert severity='success'>Password changed successfully!</Alert>
      </Snackbar>
    </>
  )
}
const password_mutation = graphql`
  mutation UpdatePasswordDialogMutation($input: infoUpdatePasswordInput!) {
    infoUpdatePassword(input: $input) {
      clientMutationId
    }
  }
`
