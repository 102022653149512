import { Avatar, Card, IconButton, Menu, MenuItem, Paper, Typography } from '@material-ui/core'
import { ArrowDropDown, ChevronRight, Edit } from '@material-ui/icons'
import { Skeleton } from '@material-ui/lab'
import { graphql } from 'babel-plugin-relay/macro'
import React, { unstable_useTransition, useRef, useState } from 'react'
import { Title as ReactHeadTitle } from 'react-head'
import styled from 'styled-components'
import ArchivedBanner from '../../components/ArchivedBanner'
import Badge from '../../components/Badge'
import Button from '../../components/Button'
import ExternalShipmentWorkflow from '../../components/ExternalWorkflows/ExternalShipmentWorkflow'
import Hero from '../../components/Hero'
import KeyField from '../../components/KeyField'
import LoadingDots from '../../components/LoadingDots'
import SemanticLine from '../../components/SemanticLine'
import StickyNote from '../../components/StickyNote'
import SubStage from '../../components/SubStage'
import TabsUI from '../../components/TabsUI/TabsUI'
import WorkspacedLink from '../../components/WorkspacedLink'
import useLazyLoadQueryWithSubscription from '../../hooks/useLazyLoadQueryWithSubscription'
import useWorkspaceNavigate from '../../hooks/useWorkspaceNavigate'
import { isMedium, isSmall, media } from '../../theme'
import { ShowPageRenderContext } from '../../viewTypes'
import { ExternalShipmentShowHeroCardQuery } from '../../__generated__/ExternalShipmentShowHeroCardQuery.graphql'
import { tabs } from './tabs'

interface Props {
  slug: string
  activeTab: string
  onEdit: () => void
  renderContext: ShowPageRenderContext
}

const ContentRoot = styled.div`
  width: 100%;
  min-height: 100px;
`

const NonStageContent = styled.div`
  width: 100%;
  display: grid;
  padding: 0 64px;
  grid-template-columns: 3fr 1fr;

  ${media.medium`
    padding: 0 32px;
    grid-template-columns: 3fr 1fr;
  `}

  ${media.small`
    grid-template-columns: 1fr;
    padding-left: 8px;
    padding-right: 8px;
  `}
`

const Titlebar = styled(Paper)`
  position: relative;
  width: 100%;
  height: 72px;
  display: flex;
  align-items: center;
  padding: 8px;
  margin-bottom: 24px;
  margin-top: 24px;

  ${media.small`
    height: 90px;
  `}
`

const TitlebarContent = styled.div`
  display: flex;
  align-items: center;
`

const TitleTypography = styled(Typography)`
  margin-right: 8px;
  font-weight: 700;
`

const ParentTitleContainer = styled(WorkspacedLink)`
  display: flex;
  align-items: center;
  padding: 4px;
  border-radius: 4px;
`

const SeperatorContainer = styled.div`
  margin: 0 8px;
`

const ShipmentTitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const ShipmentTitleContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

const TitleContent = styled.div`
  width: 100%;
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${media.small`
    padding-bottom: 8px;
  `}
`

const MetaContent = styled.div`
  width: 100%;
  padding: 24px 20px 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.small`
    padding-top: 0;
    padding-left: 0;
    align-items: stretch;
  `}
`

export const TabContainer = styled.div`
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const DetailsCard = styled(Card)`
  width: 100%;
  padding: 8px 16px;
`

const KeyFieldsContainer = styled.div`
  margin: 4px 0 16px 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, 190px);
  grid-gap: 8px;
  width: 100%;

  ${media.large`
    grid-template-columns: repeat(auto-fill, 216px);
  `}

  ${media.small`
    grid-template-columns: repeat(auto-fill, 110px);
  `}
`

const ContianerProfileImage = styled(Avatar)`
  margin-right: 8px;
  height: 40px;
  width: 40px;
  border: 4px solid ${(props) => props.theme.palette.background.paper};
  border-radius: 8px;
`

const ProfileImage = styled(Avatar)`
  margin-right: 16px;
  height: 100px;
  width: 100px;
  border: 4px solid ${(props) => props.theme.palette.background.paper};
`

const StagesContainer = styled.div`
  width: 100%;
  padding-bottom: 16px;
`

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
`
const StyledStickyNote = styled(StickyNote)`
  min-width: 220px;
`

const ExternalShipmentShowHeroCard: React.FC<Props> & { Skeleton: React.FC } = ({ slug, activeTab, renderContext }) => {
  const maxFields = isSmall() ? 4 : isMedium() ? 9 : 15
  const [open, setOpen] = useState(false)
  const shipmentMenuRef = useRef(null)

  const { externalItemShow: item, externalShipmentShow: shipment } = useLazyLoadQueryWithSubscription<ExternalShipmentShowHeroCardQuery>(
    query,
    {
      slug: slug,
      itemType: 'shipment',
    },
    { subscriptionOptions: { entityType: 'shipments', entitySlug: slug } }
  )

  const value = tabs.findIndex((t) => t.value === activeTab)
  const wsNavigate = useWorkspaceNavigate()
  const [startShipmentSwitchTransition, isShipmentSwitchPending] = unstable_useTransition()

  const handleClose = () => {
    setOpen(false)
  }

  const onTabChange = (_: React.ChangeEvent<unknown>, newValue: number) => {
    wsNavigate(`/shipment/${item.slug}/${tabs[newValue].value}`)
  }

  return (
    <>
      {renderContext === 'page' && <ReactHeadTitle>Shipment - {item.title}</ReactHeadTitle>}
      <ArchivedBanner isArchived={shipment.isArchived} />
      <Hero backgroundColor='transparent' imageUrl={item?.image?.url as string}>
        <ContentRoot>
          <NonStageContent>
            <TitleContent>
              <Titlebar ref={shipmentMenuRef} variant='outlined'>
                {!isSmall() && (
                  <>
                    <ParentTitleContainer to={`/container/${shipment.container.slug}/home`}>
                      <ContianerProfileImage src={shipment.container.imageUrl} />
                      <TitlebarContent>
                        <TitleTypography variant='subtitle1'>{shipment.container.containerNumber}</TitleTypography>
                      </TitlebarContent>
                    </ParentTitleContainer>
                    <SeperatorContainer>
                      <ChevronRight />
                    </SeperatorContainer>
                  </>
                )}
                <ShipmentTitleContainer>
                  <ShipmentTitleContent>
                    <ProfileImage src={item?.image?.url as string} />
                    <div>
                      <TitlebarContent>
                        <TitleTypography variant='h5'>{item.title}</TitleTypography>
                        {item.badges?.map((badge) => (
                          <Badge key={badge.key} data={badge} />
                        ))}
                      </TitlebarContent>
                      {item.lines?.map((line) => (
                        <SemanticLine key={line.key} data={line} />
                      ))}
                    </div>
                  </ShipmentTitleContent>
                  <IconButton onClick={() => setOpen(true)}>
                    {isShipmentSwitchPending ? <LoadingDots variant='dark' /> : <ArrowDropDown />}
                  </IconButton>
                  <Menu
                    open={open}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    onClose={handleClose}
                  >
                    {shipment.allShipments?.map((shipment) => (
                      <MenuItem
                        key={shipment.shipmentKey}
                        selected={slug === shipment.shipmentKey}
                        onClick={() => {
                          startShipmentSwitchTransition(() => {
                            wsNavigate(`/shipment/${shipment.shipmentKey}/home`)
                          })
                          handleClose()
                        }}
                      >
                        {shipment.description}
                      </MenuItem>
                    ))}
                  </Menu>
                </ShipmentTitleContainer>
              </Titlebar>
              <DetailsCard variant='outlined'>
                {item.keyFields && (
                  <KeyFieldsContainer>
                    {item.keyFields?.slice(0, maxFields)?.map((field) => (
                      <KeyField key={field.key} data={field} />
                    ))}
                  </KeyFieldsContainer>
                )}
              </DetailsCard>
            </TitleContent>
            <MetaContent>
              {shipment.subStages.map((subStage, idx) => (
                <SubStage key={subStage.stageIdx || idx} data={subStage} entityType='shipments' entitySlug={slug} />
              ))}
              {item.stickyNoteContentByExternal && <StyledStickyNote note={item.stickyNoteContentByExternal} type='external' />}
            </MetaContent>
          </NonStageContent>
          <StagesContainer>
            <ExternalShipmentWorkflow shipment={shipment} />
          </StagesContainer>
        </ContentRoot>
      </Hero>
      <Hero backgroundColor='transparent'>
        <>
          <TabContainer>
            <TabsUI isWhiteBackground tabs={tabs} value={value} onChange={onTabChange} />
            {/* <ActionsContainer>
              <Watchers watches={shipment.watches} entityType='shipments' entitySlug={slug} />
              {shipment.stage === 'done' && <ArchiveActions isArchive={shipment.isArchived} entityType='shipments' entitySlug={slug} />}
              {!shipment.isArchived && (
                <Button variant='contained' startIcon={<Edit />} onClick={onEdit}>
                  Edit
                </Button>
              )}
            </ActionsContainer> */}
          </TabContainer>
        </>
      </Hero>
    </>
  )
}

const query = graphql`
  query ExternalShipmentShowHeroCardQuery($slug: String!, $itemType: ExternalShowItemTypeEnum!) {
    externalItemShow(slug: $slug, type: $itemType) {
      slug
      title
      stickyNoteContentByExternal
      image {
        url
        ...ItemImage_data
      }
      badges {
        key
        ...Badge_data
      }
      keyFields {
        key
        ...KeyField_data
      }
      lines {
        key
        ...SemanticLine_data
      }
    }

    externalShipmentShow(slug: $slug) {
      slug
      isArchived
      stage
      shipmentKey
      subStages {
        stageIdx
        ...SubStage_data
      }
      allShipments {
        slug
        shipmentKey
        description
        shipmentType
        vin
      }
      container {
        slug
        containerNumber
        imageUrl
      }
      watches {
        ...Watchers_watches
      }
      ...ExternalShipmentWorkflow_shipment
    }
  }
`

ExternalShipmentShowHeroCard.Skeleton = () => {
  const numFields = isSmall() ? 4 : 9
  return (
    <>
      <Hero backgroundColor='transparent'>
        <ContentRoot>
          <NonStageContent>
            <TitleContent>
              <Titlebar variant='outlined'>
                {!isSmall() && (
                  <Skeleton style={{ marginRight: '16px' }}>
                    <TitleTypography variant='subtitle1'>...........................................</TitleTypography>
                  </Skeleton>
                )}
                <ShipmentTitleContainer>
                  <ShipmentTitleContent>
                    <Skeleton variant='circle' style={{ marginRight: '16px', height: '100px', width: '100px' }} />
                    <div>
                      <TitlebarContent>
                        <Skeleton>
                          <TitleTypography variant='h5'>..............................................</TitleTypography>
                        </Skeleton>
                      </TitlebarContent>
                      <SemanticLine.Skeleton />
                    </div>
                  </ShipmentTitleContent>
                </ShipmentTitleContainer>
              </Titlebar>
              <DetailsCard variant='outlined'>
                <KeyFieldsContainer>
                  {[...Array(numFields)].map((idx) => (
                    <Skeleton key={idx} height='44px' />
                  ))}
                </KeyFieldsContainer>
              </DetailsCard>
            </TitleContent>
            <MetaContent>
              <Skeleton variant='rect' height='36px' width='200px' style={{ marginBottom: '8px' }} />
              <Skeleton variant='rect' height='36px' width='200px' style={{ marginBottom: '8px' }} />
            </MetaContent>
          </NonStageContent>
          <StagesContainer>
            <div style={{ height: '64px' }} />
          </StagesContainer>
        </ContentRoot>
      </Hero>
      <Hero backgroundColor='transparent'>
        <TabContainer>
          {/* no TabsUI skeleton */}
          <span></span>
          <ActionsContainer>
            <Button variant='contained' startIcon={<Edit />} disabled>
              Edit
            </Button>
          </ActionsContainer>
        </TabContainer>
      </Hero>
    </>
  )
}

export default ExternalShipmentShowHeroCard
