import { Paper, Typography } from '@material-ui/core'
import { graphql } from 'babel-plugin-relay/macro'
import React from 'react'
import { useFragment } from 'react-relay/hooks'
import styled from 'styled-components'
import { ETDCard_voyage$key } from '../__generated__/ETDCard_voyage.graphql'

interface Props {
  voyage: ETDCard_voyage$key
}

const RootCard = styled(Paper)`
  padding: 16px;
  margin-bottom: 16px;
`

const ETDText = styled(Typography)`
  font-size: 36px;
  font-weight: 300;
  display: flex;
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const ETDCard: React.FC<Props> = ({ voyage: data }) => {
  const voyage = useFragment(fragment, data)

  return (
    <RootCard variant='outlined'>
      <TitleContainer>
        <Typography variant='h6'>ETD</Typography>
      </TitleContainer>
      <ETDText variant='h4'>{voyage.displayEtd || '--'}</ETDText>
    </RootCard>
  )
}

const fragment = graphql`
  fragment ETDCard_voyage on Voyage {
    displayEtd
  }
`

export default ETDCard
