import React, { useState, FormEvent } from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import styled from 'styled-components'
import { Card, Typography } from '@material-ui/core'
import { useParams, useNavigate } from 'react-router'
import { useLazyLoadQuery } from 'react-relay/hooks'
import { Skeleton } from '@material-ui/lab'
import StyledInput from './StyledInput'
import CTAButton from './CTAButton'
import OdysseyLogoImage from '../assets/odyssey-logo.svg'
import LoadingDots from './LoadingDots'
import { ChevronRight } from '@material-ui/icons'
import { ResetPasswordFormQuery } from '../__generated__/ResetPasswordFormQuery.graphql'
import { ResetPasswordFormMutation } from '../__generated__/ResetPasswordFormMutation.graphql'
import useNiceMutation from '../mutations/useNiceMutation'

const ResetPasswordCard = styled(Card)`
  padding: 16px 24px;
  max-width: 500px;
  margin: auto;
`

const Info = styled.div`
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 16px;
  background-color: #deedff;
`

const Title = styled(Typography)`
  margin-bottom: 8px;
`

const Subtext = styled(Typography)`
  white-space: pre;
`

const OdysseyLogoContainer = styled.div`
  width: 50%;
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
`

const ResetPasswordForm: React.FC = () => {
  const { token } = useParams()
  const navigate = useNavigate()
  const data = useLazyLoadQuery<ResetPasswordFormQuery>(query, { token: token })
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [resetSucceeded, setResetSucceeded] = useState(false)
  const resetValidity = data.resetPasswordValidity
  const [commit_pass, loading] = useNiceMutation<ResetPasswordFormMutation>(reset_password)

  const reset = (e: FormEvent) => {
    e.preventDefault()
    commit_pass({
      variables: { input: { token: token, newPassword: newPassword, confirmPassword: confirmPassword } },
      onCompleted: (_, err) => {
        if (!err) {
          setResetSucceeded(true)
        }
      },
    })
  }

  return (
    <ResetPasswordCard>
      <OdysseyLogoContainer>
        <img src={OdysseyLogoImage} alt='odyssey-logo' width='100%' />
      </OdysseyLogoContainer>
      <Info>
        <Title variant='h5'>{resetSucceeded ? 'All Done!' : resetValidity.title}</Title>
        <Subtext variant='body2'>{resetSucceeded ? 'Please login to continue' : resetValidity.subtext}</Subtext>
      </Info>
      {resetSucceeded ? (
        <>
          <CTAButton onClick={() => navigate('/')}>
            Login <ChevronRight />
          </CTAButton>
        </>
      ) : (
        <>
          {resetValidity.isValid && (
            <form onSubmit={reset}>
              <StyledInput
                fullWidth
                placeholder='New Password'
                type='password'
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <StyledInput
                fullWidth
                placeholder='Confirm Password'
                type='password'
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <CTAButton type='submit' disabled={!newPassword || !confirmPassword}>
                {loading ? <LoadingDots /> : ' Reset Password'}
              </CTAButton>
            </form>
          )}
        </>
      )}
    </ResetPasswordCard>
  )
}

const FormSkeleton = (
  <ResetPasswordCard>
    <OdysseyLogoContainer>
      <img src={OdysseyLogoImage} alt='odyssey-logo' width='100%' />
    </OdysseyLogoContainer>
    <Skeleton variant='rect' height={100} style={{ marginBottom: '16px' }} />
    <Skeleton variant='rect' height={50} style={{ marginBottom: '8px' }} />
    <CTAButton disabled>Reset Password</CTAButton>
  </ResetPasswordCard>
)

const query = graphql`
  query ResetPasswordFormQuery($token: String!) {
    resetPasswordValidity(token: $token) {
      isValid
      title
      subtext
    }
  }
`
const reset_password = graphql`
  mutation ResetPasswordFormMutation($input: infoResetPasswordInput!) {
    infoResetPassword(input: $input) {
      clientMutationId
    }
  }
`
export default Object.assign(ResetPasswordForm, { skeletion: FormSkeleton })
