import { Typography } from '@material-ui/core'
import { graphql } from 'babel-plugin-relay/macro'
import React from 'react'
import { useFragment } from 'react-relay/hooks'
import styled from 'styled-components'
import { KeyField_data$key } from '../__generated__/KeyField_data.graphql'
import SemanticString from './SemanticString'
import { HighlightT } from './TextHighlighter'

interface Props {
  data: KeyField_data$key
  highlights?: HighlightT[]
}

const Value = styled(Typography)`
  font-weight: 500;
  line-height: 24px;
  box-sizing: content-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Container = styled.div`
  width: 100%;
  margin: 2px 0;
  overflow: hidden;
  position: relative;

  &:hover {
    overflow: visible;
    z-index: 1;
    width: auto;
    display: inline-block;
    // position: absolute;
    border-radius: 4px;
    padding: 0px;
    margin-left: -4px;
    margin-top: -2px;
    background-color: ${(props) => props.theme.palette.grey[100]};
    // box-shadow: ${(props) => props.theme.shadows[2]};
  }

  // &:hover ${Value} {
  //   white-space: normal;
  // }
`

const Label = styled(Typography)`
  font-size: 12px;
`

const KeyField: React.FC<Props> = ({ data, highlights }) => {
  const field = useFragment(keyFieldFragment, data)
  const fieldValue =
    field.value === null || field.value.length === 0
      ? '--'
      : field.value.map((value) => <SemanticString key={value.key} data={value} highlights={highlights} />)

  return (
    <div>
      <Container>
        <Label variant='caption' color='textSecondary'>
          {field.label}
        </Label>
        <Value variant='body1'>{fieldValue}</Value>
      </Container>
    </div>
  )
}

const keyFieldFragment = graphql`
  fragment KeyField_data on KeyField {
    label
    value {
      key
      ...SemanticString_data
    }
  }
`

export default KeyField
