import axios from 'axios'
import React, { Suspense, useContext, useMemo, useState, useRef } from 'react'
import { Box, LinearProgress } from '@material-ui/core'
import { graphql } from 'babel-plugin-relay/macro'

import ContactRenderer from '../../components/Renderers/ContactRenderer'
import ShipmentIcon from '../../assets/public/v2/shipment.svg'
import DataTable, { IOrder } from '../../components/DataTable'
import DateRenderer from '../../components/Renderers/DateRenderer'
import fileDownload from 'js-file-download'
import LinkRenderer from '../../components/Renderers/LinkRenderer'
import DecoratedBooleanRenderer from '../../components/Renderers/BooleanRenderer'
import DecoratedNumberRenderer from '../../components/Renderers/NumberRenderer'
import moment from 'moment'
import SessionContext from '../../SessionContext'
import ShipmentAutomaticHandover from '../../components/Workflows/ShipmentAutomaticHandover'
import StageRenderer from '../../components/Renderers/StageRenderer'
import useNiceMutation from '../../mutations/useNiceMutation'
import usePager from '../../hooks/usePager'
import VinIcon from '../../assets/public/v2/vin.svg'
import DateBlankIcon from '../../assets/public/v2/date_blank.svg'
import ContactIcon from '../../assets/public/v2/contact.svg'
import WarehouseIcon from '../../assets/public/v2/warehouse.svg'
import TransporterIcon from '../../assets/public/v2/transporter.svg'
import PayerIcon from '../../assets/public/v2/payer.svg'
import StageWaiting from '../../assets/public/v2/stage_waiting.svg'
import ReleaseIcon from '../../assets/public/v2/release.svg'
import { asFormattedCurrency } from '../../components/Finances/utils'
import { useQuery } from 'urql'
import { useSnackbar } from 'notistack'
import { useLocation } from 'react-router'
import { ShipmentsTableMutation } from '../../__generated__/ShipmentsTableMutation.graphql'
import { ShipmentsTableStageChangeMutation } from '../../__generated__/ShipmentsTableStageChangeMutation.graphql'
import { ShipmentsTableShipmentBulkActionsMutation } from '../../__generated__/ShipmentsTableShipmentBulkActionsMutation.graphql'
import type { Column, IFilter, RendererOptions } from '../../components/DataTable'
import { getToken } from '../../api/server'

const VIEW_OPTIONS = [
  { label: 'Release', value: 'release' },
  { label: 'Billing', value: 'billing' },
]

const VIEWS = {
  billing: [
    'description',
    'stage',
    'eta',
    'vin',
    'consignee.name',
    'dischargeDate',
    'payer.name',
    'paymentDate',
    'totalRevenueWithVat',
    'costCleaning',
    'costClimaCertificate',
    'costContainerHandling',
    'costContainerXray',
    'costCustomsInspection',
    'costDischarge',
    'costForklift',
    'costOpinion',
    'costOther',
    'costShipping',
    'costSpecialHandling',
    'costStorage',
    'costTransport',
    'costWarehousing',
    'revenueCleaning',
    'revenueClimaCertificate',
    'revenueContainerHandling',
    'revenueContainerXray',
    'revenueCustomsClearance',
    'revenueCustomsInspection',
    'revenueDischarge',
    'revenueForklift',
    'revenueOpinion',
    'revenueShipping',
    'revenueSpecialHandling',
    'revenueStorage',
    'revenueTransport',
    'revenueWarehousing',
  ],
  release: [
    'description',
    'stage',
    'eta',
    'vin',
    'consignee.name',
    'warehouse.name',
    'transporter.name',
    'dischargeDate',
    'payer.name',
    'paymentDate',
    'expectedHandoverDate',
    'handoverDate',
    'shipperReleaseCheck',
    'finalReleaseCheck',
  ],
}

const BULK_EDITABLE_FIELDS = {
  release: ['stage', 'consignee.name', 'transporter.name', 'expectedHandoverDate'],
  billing: [
    'stage',
    'payer.name',
    'costCleaning',
    'revenueCleaning',
    'costContainerHandling',
    'revenueContainerHandling',
    'revenueContainerXray',
    'costContainerXray',
    'costDischarge',
    'revenueDischarge',
    'costForklift',
    'revenueForklift',
    'costOpinion',
    'revenueOpinion',
    'costShipping',
    'revenueShipping',
    'costSpecialHandling',
    'revenueSpecialHandling',
    'costStorage',
    'revenueStorage',
    'costTransport',
    'revenueTransport',
    'costWarehousing',
    'revenueWarehousing',
    'costClimaCertificate',
    'revenueClimaCertificate',
    'revenueCustomsClearance',
    'costCustomsInspection',
    'revenueCustomsInspection',
    'costOther',
  ],
}

const ShipmentsTable: React.FC = () => {
  const { user } = useContext(SessionContext)
  const [filters, setFilters] = React.useState<IFilter[]>([])
  const [search, setSearch] = React.useState('')
  const [order, setOrder] = React.useState<IOrder[]>([])
  const [page, pageSize, handlePageChange, handlePageSizeChange] = usePager()
  const [includeArchived, setIncludeArchived] = React.useState(false)

  const canEdit =
    user?.contact?.displayRoles.map((role) => role.value).includes('admin') ||
    user?.contact?.displayRoles.map((role) => role.value).includes('super-admin') ||
    user?.contact?.displayRoles.map((role) => role.value).includes('billing-agent')
  const [currentView, setCurrentView] = React.useState<'billing' | 'release'>('release')

  const releaseQueryResult = useQuery({
    requestPolicy: 'cache-and-network',
    query: releaseQuery,
    pause: currentView !== 'release',
    variables: {
      ...(search.length > 2 && { searchQuery: search }),
      filters,
      order,
      page,
      pageSize,
      includeArchived,
    },
  })

  const billingQueryResult = useQuery({
    requestPolicy: 'cache-and-network',
    query: billingQuery,
    pause: currentView !== 'billing',
    variables: {
      ...(search.length > 2 && { searchQuery: search }),
      filters,
      order,
      page,
      pageSize,
      includeArchived,
    },
  })

  const nestedContactsQueryResult = useQuery({
    requestPolicy: 'cache-and-network',
    query: nestedContactsQuery,
    variables: {
      ...(search.length > 2 && { searchQuery: search }),
      filters,
      order,
      page,
      pageSize,
      includeArchived,
    },
  })

  const data = currentView === 'release' ? releaseQueryResult[0].data : billingQueryResult[0].data
  const fetching =
    (currentView === 'release' ? releaseQueryResult[0].fetching : billingQueryResult[0].fetching) || nestedContactsQueryResult[0].fetching
  const refetch = currentView === 'release' ? releaseQueryResult[1] : billingQueryResult[1]

  const nestedItems = nestedContactsQueryResult[0].data?.v2ShipmentList?.response.edges.map((edge: any) => edge?.node) || []
  const items =
    data?.v2ShipmentList?.response.edges.map((edge: any) => ({
      ...edge.node,
      ...nestedItems.find((item: any) => item.slug === edge.node?.slug),
    })) || []

  const [commit, commitInFlight] = useNiceMutation<ShipmentsTableMutation>(mutation)
  const [bulkUpdate] = useNiceMutation<ShipmentsTableShipmentBulkActionsMutation>(bulkUpdateMutation)
  const [changeStage, changeStageInFlight] = useNiceMutation<ShipmentsTableStageChangeMutation>(changeStageMutation)
  const url = `${process.env.REACT_APP_API_URL}/v2/shipments/download`
  const token = getToken()
  const workspace = useLocation().pathname.split('/')[1]
  const [handoverDialoq, setHandoverDialoq] = useState<string | null>(null)

  const { enqueueSnackbar } = useSnackbar()
  const download = (selected?: number[]) => {
    const filename = `shipments-${moment().format('YYYY-MM-DD-HH:mm:ss')}.xlsx`
    return axios
      .post(
        url,
        {
          workspace,
          slugs: Array.isArray(selected) ? selected.map((i) => data?.v2ShipmentList?.response.edges[i].node.slug) : undefined,
          filters,
          order,
          search_query: search,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          responseType: 'blob',
        }
      )
      .then((res) => {
        fileDownload(res.data, filename)
      })
      .catch((error) => {
        error.response.data.text().then(function (text: string) {
          try {
            enqueueSnackbar(JSON.parse(text as string).errors, {
              variant: 'error',
            })
          } catch {
            enqueueSnackbar('Something went wrong', { variant: 'error' })
          }
        })
      })
  }

  const onSubmit = (item: any) => {
    const values = {
      slug: item.slug,
      ...item,
      ...(item?.linkedContacts && {
        linkedContacts: item.linkedContacts.map((contact: any) => ({ role: contact.role, contactSlug: contact.contactSlug })) || [],
      }),
    }

    commit({
      variables: {
        input: values,
      },
      // onError: () => actions.setSubmitting(false),
      onCompleted: (response) => {
        if (response.infoV2UpdateShipment?.shipment) {
          // refetch({ requestPolicy: 'network-only' })
          enqueueSnackbar('Shipment updated', { variant: 'success' })
        }
        // actions.setSubmitting(false)
        // if (!errors) {
        //   onClose()
        // }
      },
    })
  }

  const onBulkUpdate = async (items: any[]) => {
    const shipments = items.map((item) => ({
      slug: item?.slug,
      ...(item?.stage && { stage: item.stage }),
      ...(item?.linkedContacts && {
        linkedContacts: item.linkedContacts?.map((contact: any) => ({ role: contact.role, contactSlug: contact.contactSlug })) || [],
      }),

      ...(currentView === 'release' && {
        ...(item?.expectedHandoverDate && { expectedHandoverDate: item.expectedHandoverDate }),
      }),

      ...(currentView === 'billing' && {
        ...(item?.costCleaning && { costCleaning: item.costCleaning }),
        ...(item?.costClimaCertificate && { costClimaCertificate: item.costClimaCertificate }),
        ...(item?.costContainerHandling && { costContainerHandling: item.costContainerHandling }),
        ...(item?.costContainerXray && { costContainerXray: item.costContainerXray }),
        ...(item?.costCustomsInspection && { costCustomsInspection: item.costCustomsInspection }),
        ...(item?.costDischarge && { costDischarge: item.costDischarge }),
        ...(item?.costForklift && { costForklift: item.costForklift }),
        ...(item?.costOpinion && { costOpinion: item.costOpinion }),
        ...(item?.costOther && { costOther: item.costOther }),
        ...(item?.costShipping && { costShipping: item.costShipping }),
        ...(item?.costSpecialHandling && { costSpecialHandling: item.costSpecialHandling }),
        ...(item?.costStorage && { costStorage: item.costStorage }),
        ...(item?.costTransport && { costTransport: item.costTransport }),
        ...(item?.costWarehousing && { costWarehousing: item.costWarehousing }),
        ...(item?.revenueCleaning && { revenueCleaning: item.revenueCleaning }),
        ...(item?.revenueClimaCertificate && { revenueClimaCertificate: item.revenueClimaCertificate }),
        ...(item?.revenueContainerHandling && { revenueContainerHandling: item.revenueContainerHandling }),
        ...(item?.revenueContainerXray && { revenueContainerXray: item.revenueContainerXray }),
        ...(item?.revenueCustomsClearance && { revenueCustomsClearance: item.revenueCustomsClearance }),
        ...(item?.revenueCustomsInspection && { revenueCustomsInspection: item.revenueCustomsInspection }),
        ...(item?.revenueDischarge && { revenueDischarge: item.revenueDischarge }),
        ...(item?.revenueForklift && { revenueForklift: item.revenueForklift }),
        ...(item?.revenueOpinion && { revenueOpinion: item.revenueOpinion }),
        ...(item?.revenueShipping && { revenueShipping: item.revenueShipping }),
        ...(item?.revenueSpecialHandling && { revenueSpecialHandling: item.revenueSpecialHandling }),
        ...(item?.revenueStorage && { revenueStorage: item.revenueStorage }),
        ...(item?.revenueTransport && { revenueTransport: item.revenueTransport }),
        ...(item?.revenueWarehousing && { revenueWarehousing: item.revenueWarehousing }),
      }),
    }))

    return new Promise((resolve, reject) => {
      bulkUpdate({
        variables: {
          input: {
            shipments,
            action: 'update',
          } as any,
        },
        onError: reject,
        onCompleted: (response) => {
          if (response.infoShipmentBulkActions?.result) {
            refetch({ requestPolicy: 'network-only' })
            nestedContactsQueryResult[1]({ requestPolicy: 'network-only' })
            resolve(response)
            enqueueSnackbar('Shipments updated', { variant: 'success' })
          }
          // actions.setSubmitting(false)
          // if (!errors) {
          //   onClose()
          // }
        },
      })
    })
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  const stagePromiseRef = useRef<{ resolve: Function; reject: Function }>()
  const onStageSubmit = (item: any) => {
    const { stage, automaticHandover } = item

    if (stage === 'handover' && automaticHandover && !handoverDialoq) {
      setHandoverDialoq(item.slug)
      return new Promise((resolve, reject) => {
        stagePromiseRef.current = { resolve, reject }
      })
    }

    if (handoverDialoq) {
      return new Promise((resolve, reject) => {
        changeStage({
          variables: {
            input: {
              slug: item?.slug,
              stage: item?.stage,
              handoverDate: item?.handoverDate,
            },
          },
          onCompleted: (response, errors) => {
            if (response.infoChangeShipmentStage?.shipment) {
              refetch({ requestPolicy: 'network-only' })
              enqueueSnackbar('Shipment updated', { variant: 'success' })
              resolve(response)
              stagePromiseRef.current?.resolve(response)
            }
            if (errors?.length) {
              reject(errors)
              stagePromiseRef.current?.reject(errors)
            }
            setHandoverDialoq(null)
          },
          onError: (e) => {
            reject(e)
            stagePromiseRef.current?.reject(e)
          },
        })
      })
    }

    const values = {
      slug: item?.slug,
      stage: item?.stage,
    }

    if (stage) {
      return new Promise((resolve, reject) => {
        changeStage({
          variables: {
            input: values as any,
          },
          onCompleted: (response, errors) => {
            if (response.infoChangeShipmentStage?.shipment) {
              refetch({ requestPolicy: 'network-only' })
              enqueueSnackbar('Shipment updated', { variant: 'success' })
              resolve(response)
            }
            if (errors?.length) {
              reject(errors)
            }
          },
          onError: (e) => {
            reject(e)
          },
        })
      })
    }
  }

  const columns: Column[] = useMemo(
    () =>
      [
        {
          identifier: 'description',
          name: 'Shipment',
          sortable: true,
          editable: false,
          renderer: LinkRenderer,
          icon: ShipmentIcon,
          style: { maxWidth: 180 },
        },
        {
          identifier: 'stage',
          name: 'Stage',
          sortable: true,
          editable: true,
          filterable: true,
          icon: StageWaiting,
          style: { width: 150 },
          renderer: StageRenderer,
          onSubmit: onStageSubmit,
        },
        {
          identifier: 'eta',
          name: 'ETA',
          sortable: true,
          editable: false,
          filterable: true,
          icon: DateBlankIcon,
          style: { width: 150 },
          renderer: DateRenderer,
          onSubmit,
        },
        {
          identifier: 'vin',
          name: 'VIN',
          sortable: true,
          editable: false,
          icon: VinIcon,
          style: { width: 150 },
          onSubmit,
        },
        {
          identifier: 'consignee.name',
          name: 'Consignee',
          sortable: true,
          editable: true,
          filterable: true,
          icon: ContactIcon,
          style: { width: 150 },
          renderer: ContactRenderer,
          onSubmit,
        },
        {
          identifier: 'warehouse.name',
          name: 'Warehouse',
          sortable: true,
          editable: true,
          filterable: true,
          icon: WarehouseIcon,
          style: { width: 150 },
          renderer: ContactRenderer,
          onSubmit,
        },
        {
          identifier: 'transporter.name',
          name: 'Transporter',
          sortable: true,
          editable: true,
          filterable: true,
          icon: TransporterIcon,
          style: { width: 150 },
          renderer: ContactRenderer,
          onSubmit,
        },
        {
          identifier: 'dischargeDate',
          name: 'Discharge Date',
          sortable: true,
          editable: true,
          filterable: true,
          icon: DateBlankIcon,
          renderer: DateRenderer,
          onSubmit,
        },
        {
          identifier: 'payer.name',
          name: 'Payer',
          sortable: true,
          editable: true,
          filterable: true,
          icon: PayerIcon,
          style: { width: 150 },
          renderer: ContactRenderer,
          onSubmit,
        },
        {
          identifier: 'paymentDate',
          name: 'Payment Date',
          sortable: true,
          editable: true,
          filterable: true,
          icon: DateBlankIcon,
          renderer: DateRenderer,
          onSubmit,
        },
        {
          identifier: 'totalRevenueWithVat',
          name: 'Total REV + VAT',
          sortable: false,
          editable: false,
          filterable: false,
          icon: PayerIcon,
          renderer: (props: RendererOptions) => {
            return (
              <DecoratedNumberRenderer
                {...props}
                rowData={{
                  totalRevenueWithVat: asFormattedCurrency(
                    props.rowData.vatCalculator != null ? props.rowData.vatCalculator : props.rowData.totalRevenue
                  ),
                }}
              />
            )
          },
        },
        {
          identifier: 'costCleaning',
          name: 'Cost Cleaning',
          sortable: true,
          editable: true,
          renderer: DecoratedNumberRenderer,
          onSubmit,
        },
        {
          identifier: 'revenueCleaning',
          name: 'Revenue Cleaning',
          sortable: true,
          editable: true,
          renderer: DecoratedNumberRenderer,
          onSubmit,
        },
        {
          identifier: 'costClimaCertificate',
          name: 'Cost Clima Certificate',
          sortable: true,
          editable: true,
          renderer: DecoratedNumberRenderer,
          onSubmit,
        },
        {
          identifier: 'revenueClimaCertificate',
          name: 'Revenue Clima Certificate',
          sortable: true,
          editable: true,
          renderer: DecoratedNumberRenderer,
          onSubmit,
        },
        {
          identifier: 'costContainerHandling',
          name: 'Cost Container Handling',
          sortable: true,
          editable: true,
          renderer: DecoratedNumberRenderer,
          onSubmit,
        },
        {
          identifier: 'revenueContainerHandling',
          name: 'Revenue Container Handling',
          sortable: true,
          editable: true,
          renderer: DecoratedNumberRenderer,
          onSubmit,
        },
        {
          identifier: 'costContainerXray',
          name: 'Cost Container Xray',
          sortable: true,
          editable: true,
          renderer: DecoratedNumberRenderer,
          onSubmit,
        },
        {
          identifier: 'revenueContainerXray',
          name: 'Revenue Container Xray',
          sortable: true,
          editable: true,
          renderer: DecoratedNumberRenderer,
          onSubmit,
        },
        {
          identifier: 'costCustomsInspection',
          name: 'Cost Customs Inspection',
          sortable: true,
          editable: true,
          renderer: DecoratedNumberRenderer,
          onSubmit,
        },
        {
          identifier: 'revenueCustomsInspection',
          name: 'Revenue Customs Inspection',
          sortable: true,
          editable: true,
          renderer: DecoratedNumberRenderer,
          onSubmit,
        },
        {
          identifier: 'costDischarge',
          name: 'Cost Discharge',
          sortable: true,
          editable: true,
          renderer: DecoratedNumberRenderer,
          onSubmit,
        },
        {
          identifier: 'revenueDischarge',
          name: 'Revenue Discharge',
          sortable: true,
          editable: true,
          renderer: DecoratedNumberRenderer,
          onSubmit,
        },
        {
          identifier: 'costForklift',
          name: 'Cost Forklift',
          sortable: true,
          editable: true,
          renderer: DecoratedNumberRenderer,
          onSubmit,
        },
        {
          identifier: 'revenueForklift',
          name: 'Revenue Forklift',
          sortable: true,
          editable: true,
          renderer: DecoratedNumberRenderer,
          onSubmit,
        },
        {
          identifier: 'costOpinion',
          name: 'Cost Opinion',
          sortable: true,
          editable: true,
          renderer: DecoratedNumberRenderer,
          onSubmit,
        },
        {
          identifier: 'revenueOpinion',
          name: 'Revenue Opinion',
          sortable: true,
          editable: true,
          renderer: DecoratedNumberRenderer,
          onSubmit,
        },
        {
          identifier: 'costOther',
          name: 'Cost Other',
          sortable: true,
          editable: true,
          renderer: DecoratedNumberRenderer,
          onSubmit,
        },
        {
          identifier: 'revenueOther',
          name: 'Revenue Other',
          sortable: true,
          editable: true,
          renderer: DecoratedNumberRenderer,
          onSubmit,
        },
        {
          identifier: 'costShipping',
          name: 'Cost Shipping',
          sortable: true,
          editable: true,
          renderer: DecoratedNumberRenderer,
          onSubmit,
        },
        {
          identifier: 'revenueShipping',
          name: 'Revenue Shipping',
          sortable: true,
          editable: true,
          renderer: DecoratedNumberRenderer,
          onSubmit,
        },
        {
          identifier: 'costSpecialHandling',
          name: 'Cost Special Handling',
          sortable: true,
          editable: true,
          renderer: DecoratedNumberRenderer,
          onSubmit,
        },
        {
          identifier: 'revenueSpecialHandling',
          name: 'Revenue Special Handling',
          sortable: true,
          editable: true,
          renderer: DecoratedNumberRenderer,
          onSubmit,
        },
        {
          identifier: 'costStorage',
          name: 'Cost Storage',
          sortable: true,
          editable: true,
          renderer: DecoratedNumberRenderer,
          onSubmit,
        },
        {
          identifier: 'revenueStorage',
          name: 'Revenue Storage',
          sortable: true,
          editable: true,
          renderer: DecoratedNumberRenderer,
          onSubmit,
        },
        {
          identifier: 'costTransport',
          name: 'Cost Transport',
          sortable: true,
          editable: true,
          renderer: DecoratedNumberRenderer,
          onSubmit,
        },
        {
          identifier: 'revenueTransport',
          name: 'Revenue Transport',
          sortable: true,
          editable: true,
          renderer: DecoratedNumberRenderer,
          onSubmit,
        },
        {
          identifier: 'costWarehousing',
          name: 'Cost Warehousing',
          sortable: true,
          editable: true,
          renderer: DecoratedNumberRenderer,
          onSubmit,
        },
        {
          identifier: 'revenueWarehousing',
          name: 'Revenue Warehousing',
          sortable: true,
          editable: true,
          renderer: DecoratedNumberRenderer,
          onSubmit,
        },
        {
          identifier: 'revenueCustomsClearance',
          name: 'Revenue Custom Clearance',
          sortable: true,
          editable: true,
          renderer: DecoratedNumberRenderer,
          onSubmit,
        },
        {
          identifier: 'expectedHandoverDate',
          name: 'Expected Handover Date',
          filterable: true,
          sortable: true,
          editable: true,
          icon: DateBlankIcon,
          renderer: DateRenderer,
          onSubmit,
        },
        {
          identifier: 'handoverDate',
          name: 'Handover Date',
          sortable: true,
          editable: true,
          filterable: true,
          icon: DateBlankIcon,
          renderer: DateRenderer,
          onSubmit,
        },
        {
          identifier: 'shipperReleaseCheck',
          name: 'Shipper Release',
          sortable: true,
          editable: false,
          filterable: true,
          icon: ReleaseIcon,
          renderer: DecoratedBooleanRenderer,
          onSubmit,
        },
        {
          identifier: 'finalReleaseCheck',
          name: 'Final Release',
          sortable: true,
          editable: false,
          filterable: true,
          icon: ReleaseIcon,
          renderer: DecoratedBooleanRenderer,
          onSubmit,
        },
        // {
        //   identifier: 'customsRelease',
        //   name: 'Customs Release',
        //   sortable: true,
        //   editable: true,
        //   onSubmit,
        // },
      ].map((col) => ({
        ...col,
        editable: col.editable && user?.contact?.contactType === 'internal' && canEdit,
        hidden: !VIEWS[currentView].includes(col.identifier),
      })),
    [currentView]
  )

  return (
    <Box style={{ position: 'relative' }}>
      <Suspense key='item-list' fallback={<LinearProgress />}>
        <DataTable
          type='shipment'
          loading={fetching || commitInFlight || changeStageInFlight}
          filters={filters}
          setFilters={setFilters}
          search={search}
          setSearch={setSearch}
          includeArchived={includeArchived}
          setIncludeArchived={setIncludeArchived}
          order={order}
          setOrder={setOrder}
          actions={[]}
          bulkEditFields={BULK_EDITABLE_FIELDS}
          columns={columns}
          data={items}
          onSubmit={onSubmit}
          view={currentView}
          onViewChange={(view) => setCurrentView(view as keyof typeof VIEWS)}
          viewOptions={VIEW_OPTIONS}
          handlePageChange={handlePageChange}
          handlePageSizeChange={handlePageSizeChange}
          page={page - 1}
          pageSize={pageSize}
          count={data?.v2ShipmentList?.totalCount}
          onDownload={download}
          onBulkUpdate={onBulkUpdate}
        />
      </Suspense>
      {handoverDialoq && (
        <ShipmentAutomaticHandover
          open={!!handoverDialoq}
          onClose={() => {
            stagePromiseRef.current?.reject()
            setHandoverDialoq(null)
          }}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          entitySlug={handoverDialoq!}
          onAction={onStageSubmit}
        ></ShipmentAutomaticHandover>
      )}
    </Box>
  )
}

const nestedContactsQuery = `
  query ShipmentsTableQuery($searchQuery: String, $includeArchived: Boolean!, $filters: [V2FilterInput!], $order: [V2OrderInput!], $page: Int, $pageSize: Int) {
    v2ShipmentList(searchQuery: $searchQuery, includeArchived: $includeArchived, filters: $filters, order: $order, page: $page, pageSize: $pageSize) {
      response {
        edges {
          node {
            slug
            consignee {
              name
              slug
            }
            warehouse {
              slug
              name
            }
            transporter {
              name
              slug
            }
            payer {
              name
              slug
            }
            linkedContacts {
              contactSlug
              role
            }
          }
        }
      }
    }
  }
`

const releaseQuery = `
  query ShipmentsTableQuery($searchQuery: String, $includeArchived: Boolean!, $filters: [V2FilterInput!], $order: [V2OrderInput!], $page: Int, $pageSize: Int) {
    v2ShipmentList(searchQuery: $searchQuery, includeArchived: $includeArchived, filters: $filters, order: $order, page: $page, pageSize: $pageSize) {
      response {
        edges {
          node {
            automaticHandover
            description
            dischargeDate
            eta
            expectedHandoverDate
            finalReleaseCheck
            handoverDate
            id
            isArchived
            paymentDate
            shipperReleaseCheck
            slug
            stage
            type
            vin
          }
        }
      }
      paginate {
        hasNextPage
        hasPrevPage
        page
        perPage
        totalPages
      }
      totalCount
    }
  }
`

const billingQuery = `
query ShipmentsTableQuery($searchQuery: String, $includeArchived: Boolean!, $filters: [V2FilterInput!], $order: [V2OrderInput!], $page: Int, $pageSize: Int) {
  v2ShipmentList(searchQuery: $searchQuery, includeArchived: $includeArchived, filters: $filters, order: $order, page: $page, pageSize: $pageSize) {
    response {
      edges {
        node {
          automaticHandover
          costCleaning
          costClimaCertificate
          costContainerHandling
          costContainerXray
          costCustomsInspection
          costDischarge
          costForklift
          costOpinion
          costOther
          costShipping
          costSpecialHandling
          costStorage
          costTransport
          costWarehousing
          description
          dischargeDate
          eta
          id
          isArchived
          paymentDate
          revenueCleaning
          revenueClimaCertificate
          revenueContainerHandling
          revenueContainerXray
          revenueCustomsClearance
          revenueCustomsInspection
          revenueDischarge
          revenueForklift
          revenueOpinion
          revenueShipping
          revenueSpecialHandling
          revenueStorage
          revenueTransport
          revenueWarehousing
          slug
          stage
          type
          totalRevenue
          vatCalculator
          vin
        }
      }
    }
    paginate {
      hasNextPage
      hasPrevPage
      page
      perPage
      totalPages
    }
    totalCount
  }
}
`

const mutation = graphql`
  mutation ShipmentsTableMutation($input: infoV2UpdateShipmentInput!) {
    infoV2UpdateShipment(input: $input) {
      shipment {
        automaticHandover
        costCleaning
        costClimaCertificate
        costContainerHandling
        costContainerXray
        costCustomsInspection
        costDischarge
        costForklift
        costOpinion
        costOther
        costShipping
        costSpecialHandling
        costStorage
        costTransport
        costWarehousing
        description
        dischargeDate
        eta
        expectedHandoverDate
        finalReleaseCheck
        handoverDate
        id
        isArchived
        paymentDate
        revenueCleaning
        revenueClimaCertificate
        revenueContainerHandling
        revenueContainerXray
        revenueCustomsClearance
        revenueCustomsInspection
        revenueDischarge
        revenueForklift
        revenueOpinion
        revenueShipping
        revenueSpecialHandling
        revenueStorage
        revenueTransport
        revenueWarehousing
        shipperReleaseCheck
        slug
        stage
        totalRevenue
        type
        vatCalculator
        vin
        warehouse {
          slug
          name
        }
        transporter {
          name
          slug
        }
        payer {
          name
          slug
        }
        linkedContacts {
          contactSlug
          role
        }
      }
    }
  }
`

const changeStageMutation = graphql`
  mutation ShipmentsTableStageChangeMutation($input: infoChangeShipmentStageInput!) {
    infoChangeShipmentStage(input: $input) {
      clientMutationId
      shipment {
        slug
        automaticHandover
        stage
      }
    }
  }
`

const bulkUpdateMutation = graphql`
  mutation ShipmentsTableShipmentBulkActionsMutation($input: infoShipmentBulkActionsInput!) {
    infoShipmentBulkActions(input: $input) {
      result
    }
  }
`

export default ShipmentsTable
