import React, { useState } from 'react'
import { DetailsFieldsContainer, NewButtonPaper } from '../forms/Container/ContainerForm'
import InputField from './Form/InputField'
import styled from 'styled-components'
import { media } from '../theme'
import { FieldT } from './Form/types'
import { IconButton } from '@material-ui/core'
import { Add, Apps } from '@material-ui/icons'
import LoadingDots from './LoadingDots'
import { VinContainer, VinDetailsTypography } from '../forms/Shipment/ShipmentForm'
import { useFormikContext } from 'formik'
import useVinDetails from '../hooks/useVinDetails'
import ExternalLinkedContactsField from './Form/ExternalLinkedContactsField'
import { SubTitle, Title } from './DraftContainerForm'

const FieldsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 16px;

  ${media.medium`
    grid-template-columns: repeat(1, 1fr);
  `}

  ${media.small`
    grid-template-columns: auto;
  `}
`
interface Props {
  fieldMaster: {
    [key: string]: FieldT
  }
}

const DraftShipmentForm: React.FC<Props> = ({ fieldMaster }) => {
  const { values, setFieldValue } = useFormikContext()
  const [contactCreate, setContactCreate] = useState(false)
  const [fetchVin, vinFetchIsInProgress] = useVinDetails()
  const vinFields = ['vinOrigin', 'vinEngine', 'vinFuelType', 'vinBodyClass']
  const formValues = values as any

  const handleVinFetch = () => {
    fetchVin((values as any).vin, {
      onSuccess: (vinDetails) => {
        setFieldValue('description', vinDetails.description)
        setFieldValue('vinEngine', vinDetails.vinEngine)
        setFieldValue('vinFuelType', vinDetails.vinFuelType)
        setFieldValue('vinBodyClass', vinDetails.vinBodyClass)
        setFieldValue('vinOrigin', vinDetails.vinOrigin)
      },
    })
  }

  const handleShowContactForm = () => {
    setContactCreate(!contactCreate)
    setFieldValue('contactCreate', !contactCreate)
  }

  return (
    <>
      <FieldsContainer>
        <DetailsFieldsContainer>
          <InputField of='shipmentType' field={fieldMaster['shipmentType']} />
          <InputField of='containerSlug' field={fieldMaster['containerSlug']} />
          <InputField of='description' field={fieldMaster['description']} />
          <InputField of='weight' field={fieldMaster['weight']} />
          <VinContainer>
            <InputField of='vin' field={fieldMaster['vin']} />
            <IconButton>
              {vinFetchIsInProgress ? <LoadingDots variant='dark' /> : <Apps fontSize='large' onClick={handleVinFetch} />}
            </IconButton>
          </VinContainer>
          <VinDetailsTypography variant='subtitle2'>
            {vinFields.map((fieldName, idx) => (
              <>
                {fieldMaster[fieldName]?.title}:{' '}
                {formValues[fieldName] === undefined || formValues[fieldName] === null || formValues[fieldName] === ''
                  ? '__'
                  : formValues[fieldName]}
                {idx === vinFields.length - 1 ? '' : ', '}
              </>
            ))}
          </VinDetailsTypography>
        </DetailsFieldsContainer>
      </FieldsContainer>
      <FieldsContainer>
        <br />
        <Title variant='h6'>Contact</Title>
        {!contactCreate && <ExternalLinkedContactsField field={fieldMaster['linkedContacts']} />}
        <SubTitle>{contactCreate ? '**Add an existing contact.' : '**Create a new contact if not found.'}</SubTitle>
        <NewButtonPaper variant='outlined' onClick={handleShowContactForm}>
          <Add />
          {contactCreate ? 'Add existing contact.' : 'Add contact'}
        </NewButtonPaper>
        {contactCreate && (
          <>
            <DetailsFieldsContainer>
              <InputField of='salutation' field={fieldMaster['salutation']} />
              <InputField of='format' field={fieldMaster['format']} />
              <InputField of='firstName' field={fieldMaster['firstName']} />
              <InputField of='lastName' field={fieldMaster['lastName']} />
            </DetailsFieldsContainer>
            <FieldsContainer>
              <InputField of='address' field={fieldMaster['address']} />
            </FieldsContainer>
            <DetailsFieldsContainer>
              <InputField of='postcode' field={fieldMaster['postcode']} />
              <InputField of='place' field={fieldMaster['place']} />
              <InputField of='company' field={fieldMaster['company']} />
              <InputField of='state' field={fieldMaster['state']} />
              <InputField of='country' field={fieldMaster['country']} />
              <InputField of='language' field={fieldMaster['language']} />
              <InputField of='email' field={fieldMaster['email']} />
              <InputField of='businessPhone' field={fieldMaster['businessPhone']} />
              <InputField of='eori' field={fieldMaster['eori']} />
              <InputField of='vat' field={fieldMaster['vat']} />
            </DetailsFieldsContainer>
          </>
        )}
      </FieldsContainer>
    </>
  )
}

export default DraftShipmentForm
