import {
  Chip,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grow,
  IconButton,
  InputAdornment,
  InputBase,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core'
import { AttachFile, Clear, Send } from '@material-ui/icons'
import { Autocomplete, Skeleton } from '@material-ui/lab'
import { graphql } from 'babel-plugin-relay/macro'
import React, { Suspense, unstable_useTransition, useEffect, useMemo, useState } from 'react'
import { useLazyLoadQuery } from 'react-relay/hooks'
import { TransitionGroup } from 'react-transition-group'
import styled from 'styled-components'
import useNiceMutation from '../../mutations/useNiceMutation'
import { media } from '../../theme'
import { SendEmailDialogFormQuery, SendEmailDialogFormQueryResponse } from '../../__generated__/SendEmailDialogFormQuery.graphql'
import { infoSendEmailInput, SendEmailDialogMutation } from '../../__generated__/SendEmailDialogMutation.graphql'
import Button from '../Button'
import EntityAttachment from '../File/EntityAttachment'
import EntityAttachmentListPreviewer from '../File/EntityAttachmentListPreviewer'
import LoadingDots from '../LoadingDots'
import OdysseyDialog from '../OdysseyDialog'
import AttachmentSelectionDialog from './AttachmentSelectionDialog'
import { EmailEntityEnum } from './types'

interface Props {
  entitySlug: string
  entityType: EmailEntityEnum
  open: boolean
  onClose?: () => void
  initialTemplateSlug?: string | null
}

const HorizontalFlex = styled.div`
  display: flex;
  align-items: center;
`

const StyledDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  padding: 0;
`

const TitleOptionsFieldsContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
  min-height: 32px;
  margin-bottom: 8px;
  background-color: ${(props) => (props.theme.palette.type === 'light' ? props.theme.palette.grey[200] : props.theme.palette.grey[800])};
  padding: 0 24px;
`

const TitleOptionsSelect = styled(Select)`
  & > .MuiSelect-root {
    padding: 0 16px;
    height: 100%;
    display: flex;
    align-items: center;
    background-color: ${(props) => (props.theme.palette.type === 'light' ? props.theme.palette.grey[200] : props.theme.palette.grey[800])};
  }

  & > .MuiSelect-icon {
    right: 8px;
  }
`

const TemplateSelect = styled(TitleOptionsSelect)`
  border-right: 1px solid ${(props) => props.theme.palette.divider};
`

const StyledInputBase = styled(InputBase)`
  height: 100%;
  width: 100%;
`

const StyledAutoCompleteInputBase = styled(StyledInputBase)`
  height: 100%;
  width: 100%;
  padding: 0 16px;

  & > .MuiAutocomplete-endAdornment {
    right: 7px;
  }
`

const FieldContainer = styled.div`
  padding: 0 24px;
`

const FieldInputContainer = styled.div`
  display: flex;
  align-items: center;
`

const FieldLabel = styled(Typography).attrs({ variant: 'body2', color: 'textSecondary' })`
  margin-right: 16px;
`

const StyledDivider = styled(Divider)`
  padding-left: 24px;
  margin: 8px 0;
`

const AttachmentsContainer = styled.div`
  margin-top: 8px;
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  grid-auto-rows: 120px;
  grid-gap: 16px;
  padding: 0 24px;
  margin-bottom: 16px;

  ${media.small`
    grid-template-columns: repeat(3, minmax(0, 1fr));
  `}
`

const AttachmentWrapper = styled.div`
  position: relative;
`

const AttachmentRemoveIconBackdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  background: linear-gradient(to bottom right, rgba(0, 0, 0, 0.25) 0%, transparent 18%);
  border-radius: 4px;
`

const AttachmentRemoveIconButton = styled(IconButton)`
  position: absolute;
  top: 0;
  left: 0;
`

const StyledDialogActions = styled(DialogActions)`
  display: flex;
  justify-content: space-between;
`

const SendEmailDialog: React.FC<Props> = ({ entitySlug, entityType, open, onClose, initialTemplateSlug }) => {
  return (
    <OdysseyDialog open={open} onClose={onClose} fullWidth maxWidth='sm' style={{ maxWidth: '960px', margin: 'auto' }}>
      <DialogTitle>New Email</DialogTitle>
      <Suspense fallback={<SendEmailDialogInternal.Skeleton />}>
        <SendEmailDialogInternal entityType={entityType} entitySlug={entitySlug} onClose={onClose} initialTemplateSlug={initialTemplateSlug} />
      </Suspense>
    </OdysseyDialog>
  )
}

interface InternalProps {
  entitySlug: string
  entityType: EmailEntityEnum
  onClose?: () => void
  initialTemplateSlug?: string | null
  bodyContentFilter?: string[]
}

export const SendEmailDialogInternal: React.FC<InternalProps> & { Skeleton: React.FC } = ({
  entitySlug,
  entityType,
  onClose,
  initialTemplateSlug,
  bodyContentFilter,
}) => {
  const [templateSlug, setTemplateSlug] = useState<null | string>(initialTemplateSlug === undefined ? null : initialTemplateSlug)
  const [language, setLanguage] = useState<null | string>('en')
  const { emailForm } = useLazyLoadQuery<SendEmailDialogFormQuery>(query, {
    entitySlug: entitySlug,
    entityType: entityType,
    templateSlug: templateSlug,
    bodyContentFilter: bodyContentFilter,
  })
  const selectedEmail = useMemo(() => emailForm.emails.find((email) => email.language === language), [emailForm, language])
  const [startTemplateTransition, templateTransitionIsInProgress] = unstable_useTransition()

  const [newEmailInput, setNewEmailInput] = useState<infoSendEmailInput>({
    body: selectedEmail?.body || '',
    entitySlug: entitySlug,
    entityType: entityType,
    subject: selectedEmail?.subject || '',
    templateSlug: templateSlug || '',
    to: (selectedEmail?.to as string[]) || [],
    attachments: (selectedEmail?.attachments as string[]) || [],
  })
  useEffect(() => {
    setNewEmailInput({
      body: selectedEmail?.body || '',
      entitySlug: entitySlug,
      entityType: entityType,
      subject: selectedEmail?.subject || '',
      templateSlug: templateSlug || '',
      to: (selectedEmail?.to as string[]) || [],
      attachments: (selectedEmail?.attachments as string[]) || [],
    })
  }, [selectedEmail, entitySlug, entityType, templateSlug])
  const [attachmentSelectionOpen, setAttachmentSelectionOpen] = useState(false)
  const selectedAttachments = useMemo(
    () =>
      emailForm.attachmentFolderSets.flatMap((folderSet) =>
        folderSet.folders.flatMap((folder) => folder.attachments.filter((att) => newEmailInput.attachments?.includes(att.attachmentSlug)))
      ),
    [newEmailInput, emailForm]
  )
  const [previewedAttachmentSlug, setPreviewedAttachmentSlug] = useState<null | string>(null)
  useEffect(() => {
    setLanguage(emailForm.defaultLanguage)
  }, [emailForm.defaultLanguage])

  const [commit, sendEmailIsInFlight] = useNiceMutation<SendEmailDialogMutation>(mutation)

  const onSubmit = () => {
    commit({
      variables: {
        input: newEmailInput,
      },
      onCompleted: (_res, errors) => {
        if (!errors) {
          onClose && onClose()
        }
      },
    })
  }

  return (
    <>
      <StyledDialogContent dividers>
        <TitleOptionsFieldsContainer>
          <TemplateSelect
            value={templateSlug || emailForm.templateSlug}
            onChange={(e) => {
              startTemplateTransition(() => {
                setTemplateSlug(e.target.value as string)
              })
            }}
            input={
              <StyledInputBase
                fullWidth
                endAdornment={
                  templateTransitionIsInProgress ? (
                    <InputAdornment position='end' style={{ display: 'flex', marginRight: '42px' }}>
                      <LoadingDots variant='dark' />
                    </InputAdornment>
                  ) : null
                }
              />
            }
          >
            {emailForm.possibleTemplates.map((template) => (
              <MenuItem key={template.slug} value={template.slug}>
                {template.title}
              </MenuItem>
            ))}
          </TemplateSelect>
          <TitleOptionsSelect value={language} onChange={(e) => setLanguage(e.target.value as string)} input={<StyledInputBase fullWidth />}>
            {emailForm.emails.map((email) => (
              <MenuItem key={email.language} value={email.language}>
                {email.languageTitle}
              </MenuItem>
            ))}
          </TitleOptionsSelect>
        </TitleOptionsFieldsContainer>
        <FieldContainer>
          <Autocomplete
            multiple
            freeSolo
            disabled={sendEmailIsInFlight || templateTransitionIsInProgress}
            groupBy={(option) => (typeof option === 'string' ? '' : option.group)}
            options={emailForm.possibleReceivers as SendEmailDialogFormQueryResponse['emailForm']['possibleReceivers'][0][]}
            renderOption={(option) =>
              typeof option === 'string' ? (
                <div>
                  <Typography variant='body2'>{option}</Typography>
                </div>
              ) : (
                <div>
                  <Typography variant='body2'>
                    {option.name} - {option.email}
                  </Typography>
                  <Typography variant='caption' color='textSecondary'>
                    {option.roles.join(', ')}
                  </Typography>
                </div>
              )
            }
            getOptionLabel={(option) => (typeof option === 'string' ? option : `${option.name} - ${option.email}`)}
            // value={emailForm.possibleReceivers.filter(receiver => newEmailInput.to.includes(receiver.email))}
            value={newEmailInput.to.map((email) => emailForm.possibleReceivers.find((receiver) => receiver.email === email) || email)}
            renderInput={(params) => (
              <FieldInputContainer>
                <FieldLabel>To:</FieldLabel>
                <StyledAutoCompleteInputBase inputProps={params.inputProps} {...params.InputProps} />
              </FieldInputContainer>
            )}
            renderTags={(options, getTagProps) =>
              options.map((option, index) => (
                <Chip
                  key={typeof option === 'string' ? option : option.email}
                  variant='outlined'
                  label={typeof option === 'string' ? option : `${option.name} - ${option.email}`}
                  {...getTagProps({ index })}
                  deleteIcon={<Clear />}
                />
              ))
            }
            onChange={(event, newValue) => {
              setNewEmailInput((prev) => ({ ...prev, to: newValue.map((val) => (typeof val === 'string' ? val : val.email)) }))
            }}
          />
        </FieldContainer>
        <StyledDivider />
        <FieldContainer>
          <FieldInputContainer>
            <FieldLabel>Subject:</FieldLabel>
            <StyledInputBase
              value={newEmailInput.subject}
              disabled={sendEmailIsInFlight || templateTransitionIsInProgress}
              onChange={(evt) => setNewEmailInput((prevState) => ({ ...prevState, subject: evt.target.value }))}
            />
          </FieldInputContainer>
        </FieldContainer>
        <StyledDivider />
        <FieldContainer>
          <StyledInputBase
            multiline
            rows={20}
            rowsMin={20}
            placeholder='Type body here...'
            value={newEmailInput.body}
            disabled={sendEmailIsInFlight || templateTransitionIsInProgress}
            onChange={(evt) => setNewEmailInput((prevState) => ({ ...prevState, body: evt.target.value }))}
          />
        </FieldContainer>
        <AttachmentsContainer>
          <TransitionGroup component={null}>
            {selectedAttachments.map((attachment) => (
              <Grow in={true} key={attachment.attachmentSlug} style={{ transformOrigin: 'center top' }}>
                <AttachmentWrapper>
                  <EntityAttachment attachment={attachment} onPreview={() => setPreviewedAttachmentSlug(attachment.attachmentSlug)} />
                  <AttachmentRemoveIconBackdrop />
                  <AttachmentRemoveIconButton
                    size='small'
                    onClick={() =>
                      setNewEmailInput((prevState) => ({
                        ...prevState,
                        attachments: prevState.attachments?.filter((slug) => slug !== attachment.attachmentSlug),
                      }))
                    }
                  >
                    <Clear fontSize='small' htmlColor='white' />
                  </AttachmentRemoveIconButton>
                </AttachmentWrapper>
              </Grow>
            ))}
          </TransitionGroup>
        </AttachmentsContainer>
      </StyledDialogContent>
      <StyledDialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <HorizontalFlex>
          <IconButton onClick={() => setAttachmentSelectionOpen(true)} disabled={sendEmailIsInFlight}>
            <AttachFile />
          </IconButton>
          <Button variant='contained' onClick={onSubmit} startIcon={<Send />} disabled={sendEmailIsInFlight}>
            {sendEmailIsInFlight ? <LoadingDots /> : 'Send'}
          </Button>
        </HorizontalFlex>
      </StyledDialogActions>
      <AttachmentSelectionDialog
        emailForm={emailForm}
        selectedAttachmentSlugs={newEmailInput.attachments || []}
        open={attachmentSelectionOpen}
        onClose={() => setAttachmentSelectionOpen(false)}
        onSelection={(attachmentSlugs) => {
          setNewEmailInput((prevState) => ({ ...prevState, attachments: attachmentSlugs }))
          setAttachmentSelectionOpen(false)
        }}
      />
      <EntityAttachmentListPreviewer
        attachments={selectedAttachments}
        intialPreviewedAttachmentSlug={previewedAttachmentSlug}
        open={!!previewedAttachmentSlug}
        onClose={() => setPreviewedAttachmentSlug(null)}
        attachmentsEditable={false}
      />
    </>
  )
}

SendEmailDialogInternal.Skeleton = () => (
  <>
    <StyledDialogContent dividers>
      <TitleOptionsFieldsContainer>
        <div style={{ height: '31px' }}></div>
      </TitleOptionsFieldsContainer>
      <FieldContainer>
        <FieldInputContainer>
          <FieldLabel>To:</FieldLabel>
          <Skeleton>
            <Typography variant='body1'>................................</Typography>
          </Skeleton>
        </FieldInputContainer>
      </FieldContainer>
      <StyledDivider />
      <FieldContainer>
        <FieldInputContainer>
          <FieldLabel>Subject:</FieldLabel>
          <Skeleton>
            <Typography variant='body1'>................................</Typography>
          </Skeleton>
        </FieldInputContainer>
      </FieldContainer>
      <StyledDivider />
      <FieldContainer style={{ marginBottom: '16px' }}>
        <Skeleton variant='rect' height='190px'></Skeleton>
      </FieldContainer>
    </StyledDialogContent>
    <StyledDialogActions>
      <Button disabled>Cancel</Button>
      <HorizontalFlex>
        <IconButton disabled>
          <AttachFile />
        </IconButton>
        <Button variant='contained' startIcon={<Send />} disabled>
          Send
        </Button>
      </HorizontalFlex>
    </StyledDialogActions>
  </>
)

const query = graphql`
  query SendEmailDialogFormQuery(
    $entityType: EmailEntityTypeEnum!
    $entitySlug: String!
    $templateSlug: String
    $bodyContentFilter: [String!]
  ) {
    emailForm(entityType: $entityType, entitySlug: $entitySlug, templateSlug: $templateSlug, bodyContentFilter: $bodyContentFilter) {
      entityType
      entitySlug
      templateSlug
      defaultLanguage
      emails {
        language
        languageTitle
        subject
        to
        body
        attachments
      }
      possibleTemplates {
        title
        slug
      }

      possibleReceivers {
        email
        roles
        name
        group
      }

      attachmentFolderSets {
        folders {
          slug
          name
          attachments {
            attachmentSlug
            ...EntityAttachmentListPreviewer_attachment
            ...EntityAttachment_attachment
          }
        }
      }
      ...AttachmentSelectionDialog_emailForm
    }
  }
`

const mutation = graphql`
  mutation SendEmailDialogMutation($input: infoSendEmailInput!) {
    infoSendEmail(input: $input) {
      clientMutationId
    }
  }
`

export default SendEmailDialog
