import React, { Suspense } from 'react'
import ResponsiveGrid from '../../components/ResponsiveGrid'
import Timeline from '../../components/Timeline/Timeline'
import SettingsCard from './SettingsCard'
import { SettingType } from './tabs'

const SuspendedSettingCard: React.FC = ({ children }) => <Suspense fallback={<>Loading...</>}>{children}</Suspense>

interface Props {
  type: SettingType
}

const TabContent: React.FC<Props> = ({ type }) => {
  const firstCol = [
    <SuspendedSettingCard key={type}>
      <SettingsCard type={type} detailed='detailed' />
    </SuspendedSettingCard>,
  ]
  const timeline = (
    <Suspense key='timeline' fallback={<Timeline.Skeleton />}>
      <Timeline type={type} />
    </Suspense>
  )

  return <ResponsiveGrid type='show-layout' highlight={firstCol} right={[timeline]} />
}

export default TabContent
