import { Avatar, Badge, Typography } from '@material-ui/core'
import React, { Suspense, useContext, useState } from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components'
import ResponsiveGrid from '../../components/ResponsiveGrid'
import SessionContext from '../../SessionContext'
import DashboardHeader from './DashboardHeader'
import DashboardMainContent from './DashboardMainContent'
import { useLocation } from 'react-router'
import { parse } from 'qs'
import { isMobile } from '../../theme'
import { DateFilterEnum } from '../../__generated__/DashboardHeaderQuery.graphql'

const UserBadge = styled(Badge)`
  display: flex;
  align-items: center;
  justify-content: center;
`

const UserCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px 0;
  border-bottom-right-radius: 36px;
  border-bottom-left-radius: 36px;
  border-color: ${(props) => props.theme.palette.divider};
  background-color: ${(props) => props.theme.palette.background.paper};
`

const UserAvatar = styled(Avatar)`
  width: 100px;
  height: 100px;
  border: 4px solid white;
`

const Dashboard: React.FC = () => {
  const location = useLocation()
  const [dateFilter, setDateFilter] = useState<DateFilterEnum>('today')
  const queryVariables = parse(location.search, { ignoreQueryPrefix: true })
  const includeArchived = queryVariables.includeArchived as string
  const { handle } = useParams()
  const { user } = useContext(SessionContext)
  const my = user?.username === handle.toLowerCase() ? 'My' : `@${handle}'s`
  const myHandle = handle.length < 2 ? user?.username || handle : handle

  return (
    <>
      <ResponsiveGrid
        type='dashboard-layout'
        header={[
          <Suspense key='header' fallback={'Loading...'}>
            <DashboardHeader my={my} handle={myHandle} includeArchived={includeArchived} dateFilter={dateFilter} />
          </Suspense>,
        ]}
        headerProfile={[
          isMobile() ? (
            <></>
          ) : (
            <>
              <UserCard key='user-profile'>
                <UserBadge
                  overlap='circle'
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                >
                  <UserAvatar src={user?.contact?.profileUrl || ''} />
                </UserBadge>
                <Typography variant='h6'>{user?.contact?.name}</Typography>
                <Typography variant='body2' style={{ maxWidth: '200px', textAlign: 'center' }}>
                  {user?.contact?.displayRoles?.map((r) => r.name).join(', ')}{' '}
                  {user?.contact?.workspace !== 'live' ? `(${user?.contact?.workspace})` : ''}
                </Typography>
              </UserCard>
            </>
          ),
        ]}
      />
      <Suspense key='main' fallback={'Loading...'}>
        <DashboardMainContent
          my={my}
          handle={myHandle}
          includeArchived={includeArchived}
          dateFilter={dateFilter}
          setDateFilter={setDateFilter}
        />
      </Suspense>
    </>
  )
}

export default Dashboard
