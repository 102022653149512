import {
  Checkbox,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core'
import { Alert, Autocomplete } from '@material-ui/lab'
import { graphql } from 'babel-plugin-relay/macro'
import React, { DragEventHandler, Suspense, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import useNiceMutation from '../../mutations/useNiceMutation'
import { DraftFileCreationDialogUploadMutation } from '../../__generated__/DraftFileCreationDialogUploadMutation.graphql'
import { AttachmentEntityTypeEnum } from '../../__generated__/FileCreationDialogUploadMutation.graphql'
import Button from '../Button'
import OdysseyDialog from '../OdysseyDialog'
import TabsUI, { TabPanel } from '../TabsUI/TabsUI'
import { GenerateTab } from './FileCreationDialog'
import { previewUrlForContentType, uploadableDocsQuery } from './helpers'
import { FolderType } from './types'
import { useLazyLoadQuery } from 'react-relay/hooks'
import { helpersFileUploadableDocsQuery, TemplateEntityTypeEnum } from '../../__generated__/helpersFileUploadableDocsQuery.graphql'

interface Props {
  ticketSlug: string
  open: boolean
  folderSlug: string
  entitySlug?: string
  entityType: TemplateEntityTypeEnum
  onClose: () => void
  folderName: string
  files?: FileList | null
  folderType: FolderType
  documentType?: string
}

const HiddenFileInput = styled.input`
  display: none;
`

const UploadableItemsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
`

const UploadableItem = styled(Paper).attrs({ variant: 'outlined' })`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 8px;
  padding: 8px;
`

const UploadablePreviewContainer = styled.div`
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const UploadablePreview = styled.img<{ $isIcon: boolean }>`
  height: ${(props) => (props.$isIcon ? '50px' : '100%')};
  width: ${(props) => (props.$isIcon ? '50px' : '100%')};
  object-fit: contain;
`

const UploadableFieldsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 8px;
`

const CenterContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 64px 0;
`

const StyledUploadDialogContent = styled(DialogContent)<{ $dropHover: boolean }>`
  position: relative;

  & * {
    ${(props) =>
      props.$dropHover &&
      `
      pointer-events: none;
    `}
  }
`

const DropHoverContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* margin: 16px; */
  border: 5px dashed ${(props) => props.theme.palette.primary.light};
  color: ${(props) => props.theme.palette.primary.light};
  border-radius: 8px;
  background-color: ${(props) => props.theme.palette.background.paper};
  display: flex;
  align-items: center;
  justify-content: center;
`

const DropHover: React.FC<{ title: string }> = ({ title }) => (
  <DropHoverContainer>
    <Typography variant='h6'>{title}</Typography>
  </DropHoverContainer>
)

interface Uploadable {
  file: File
  id: string
  name: string
  contentType: string
  documentType?: string
  signed?: boolean
}

const tabs = [
  { label: 'Upload', value: 'upload' },
  { label: 'Generate', value: 'generate' },
]

const DraftFileCreationDialog: React.FC<Props> = (props) => {
  const [tabValue, setTabValue] = useState(0)
  return (
    <>
      <OdysseyDialog open={props.open} fullWidth onClose={props.onClose} scroll='paper' style={{ maxWidth: '960px', margin: 'auto' }}>
        <DialogTitle>Upload {props.folderName}</DialogTitle>
        <TabsUI tabs={tabs} value={tabValue} onChange={(_, newValue) => setTabValue(newValue)} />

        <TabPanel value={tabValue} index={0} noPadding>
          <Suspense fallback={<>Loading ...</>}>
            <UploadTab {...props} />
          </Suspense>
        </TabPanel>
        <TabPanel value={tabValue} index={1} noPadding>
          <Suspense fallback={<GenerateTab.Skeleton />}>
            <GenerateTab
              folderSlug={props.folderSlug}
              entityType={props.entityType as AttachmentEntityTypeEnum}
              entitySlug={props.entitySlug as string}
              onClose={props.onClose}
              isExternal={true}
            />
          </Suspense>
        </TabPanel>
      </OdysseyDialog>
    </>
  )
}
interface DropState {
  dropHover: boolean
}

const UploadTab: React.FC<Props> = ({ ticketSlug, onClose, folderSlug, entitySlug, entityType, files, folderType, documentType }) => {
  const [commitUpload, uploadIsInProgress] = useNiceMutation<DraftFileCreationDialogUploadMutation>(uploadMutation)
  const [uploadError, setUploadError] = useState<null | string>(null)
  const [dropState, setDropState] = useState<DropState>({ dropHover: false })
  const uploadAttachments = () => {
    setUploadError(null)
    commitUpload({
      variables: {
        input: {
          ticketSlug: ticketSlug,
          folderSlug: folderSlug,
          entitySlug: entitySlug,
          entityType: entityType,
          attachments: uploadables.map((uploadable) => ({
            identifier: uploadable.id,
            name: uploadable.name,
            signed: uploadable.signed,
            documentType: uploadable.documentType,
          })),
        },
      },
      uploadables: Object.fromEntries(uploadables.map((uploadable) => [uploadable.id, uploadable.file])),
      onCompleted: (res, errors) => {
        if (errors) {
          setUploadError(errors.map((err) => err.message).join(', '))
        } else {
          resetUpload()
        }
      },
    })
  }

  const [uploadables, setUploadables] = useState<Uploadable[]>([])
  const [previewUrls, setPreviewUrls] = useState<{ [id: string]: string }>({})
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [fileInputRefNull, setFileInputRefFlag] = useState(true)

  useEffect(() => {
    if (files) {
      onFileInputChangeRef.current(files)
    }
  }, [files])

  const onFileInputChange = (incomingFiles: FileList | null) => {
    const files = incomingFiles || []
    const newUploadables: Uploadable[] = []
    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      newUploadables.push({
        file: file,
        name: file.name,
        id: file.name,
        contentType: file.type,
        documentType: documentType,
      })

      if (file.type.startsWith('image/')) {
        const fileReader = new FileReader()
        fileReader.onloadend = (evt: ProgressEvent) => {
          const reader: FileReader = evt.target as FileReader
          setPreviewUrls((prevState) => ({ ...prevState, [file.name]: reader.result as string }))
        }
        fileReader.readAsDataURL(file)
      }
    }
    setUploadables((oldUploadables) => [...oldUploadables, ...newUploadables])
    setFileInputRefFlag(false)
  }

  const triggerFileSelection = () => {
    fileInputRef.current?.click()
  }
  const onFileInputChangeRef = useRef(onFileInputChange)

  useEffect(() => {
    onFileInputChangeRef.current = onFileInputChange
  })

  const resetUpload = () => {
    setUploadables([])
    setUploadError(null)
    setFileInputRefFlag(false)
    onClose()
  }

  const onDragEnter: DragEventHandler = (evt) => {
    evt.dataTransfer.dropEffect = 'copy'
    evt.preventDefault()
    if (!uploadIsInProgress) {
      setDropState({ dropHover: true })
    }
  }

  const onDragOver: DragEventHandler = (evt) => {
    evt.dataTransfer.dropEffect = 'copy'
    evt.preventDefault()
  }

  const onDragLeave = () => {
    setDropState({ dropHover: false })
  }

  const onDrop: DragEventHandler = (evt) => {
    evt.dataTransfer.dropEffect = 'copy'
    evt.preventDefault()
    onFileInputChange(evt.dataTransfer.files)
    setDropState({ dropHover: false })
  }

  const onUploadableChange = (idx: number, field: 'name' | 'documentType' | 'signed', value?: string | boolean) => {
    setUploadables((prevState) => [...prevState.slice(0, idx), { ...prevState[idx], [field]: value }, ...prevState.slice(idx + 1)])
  }

  const data = useLazyLoadQuery<helpersFileUploadableDocsQuery>(uploadableDocsQuery, {
    entityType: entityType,
  })

  return (
    <>
      <HiddenFileInput ref={fileInputRef} type='file' multiple onChange={(evt) => onFileInputChange(evt.target.files)} />
      <StyledUploadDialogContent
        dividers={true}
        onDragOver={onDragOver}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
        $dropHover={dropState.dropHover}
      >
        {uploadError && <Alert severity='error'>{uploadError}</Alert>}
        {uploadIsInProgress ? (
          <CenterContentContainer>
            <CircularProgress />
            <Typography variant='body1'>Uploading {uploadables.length} files</Typography>
          </CenterContentContainer>
        ) : uploadables.length === 0 ? (
          <>
            <CenterContentContainer>
              <Button onClick={triggerFileSelection}>Select files from your device</Button>
              <Typography variant='body2' color='textSecondary'>
                or
              </Typography>
              <Typography variant='body2'>Drag and Drop</Typography>
            </CenterContentContainer>
            {dropState.dropHover && <DropHover title='Drop to select' />}
          </>
        ) : (
          <>
            <UploadableItemsContainer>
              {uploadables.map((uploadable, idx) => (
                <UploadableItem key={uploadable.id}>
                  <UploadablePreviewContainer>
                    <UploadablePreview
                      src={previewUrls[uploadable.id] || previewUrlForContentType(uploadable.contentType)}
                      alt={uploadable.name}
                      $isIcon={!previewUrls[uploadable.id]}
                    />
                  </UploadablePreviewContainer>
                  <UploadableFieldsContainer>
                    <TextField
                      value={uploadable.name}
                      variant='outlined'
                      fullWidth
                      size='small'
                      onChange={(e) => onUploadableChange(idx, 'name', e.target.value)}
                    />
                    {folderType !== 'photos' && (
                      <Autocomplete
                        options={data.uploadableDocuments as { documentName: string; description: string }[]}
                        getOptionLabel={(option) => option.documentName}
                        fullWidth
                        size='small'
                        onChange={(e, value) => onUploadableChange(idx, 'documentType', value?.documentName)}
                        renderInput={(params) => <TextField {...params} label='Category' variant='outlined' />}
                      />
                    )}
                    <FormControlLabel
                      control={<Checkbox onChange={(e) => onUploadableChange(idx, 'signed', e.target.checked)} />}
                      label='All required signatures are present in this file'
                    />
                  </UploadableFieldsContainer>
                </UploadableItem>
              ))}
            </UploadableItemsContainer>
            {dropState.dropHover && <DropHover title='Drop to add' />}
          </>
        )}
      </StyledUploadDialogContent>
      <DialogActions>
        <Button variant='text' onClick={resetUpload}>
          Cancel
        </Button>
        <Button variant='contained' onClick={uploadAttachments} disabled={uploadIsInProgress || fileInputRefNull}>
          Upload
        </Button>
      </DialogActions>
    </>
  )
}

const uploadMutation = graphql`
  mutation DraftFileCreationDialogUploadMutation($input: infoUploadAttachmentsToDraftInput!) {
    infoUploadAttachmentsToDraft(input: $input) {
      clientMutationId
    }
  }
`
export default DraftFileCreationDialog
