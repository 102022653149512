import { Card, IconButton, Typography } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import React, { ChangeEvent, createRef, DragEventHandler, useState } from 'react'
import styled from 'styled-components'
import PrimaryButton from './PrimaryButton'
import SecondaryButton from './SecondaryButton'

interface Props {
  title: string
  onUpload: (fileData: string) => void
}

const UploadCardRoot = styled(Card)`
  padding: 16px;
  border-radius: 16px;
  margin-bottom: 8px;
  background-color: ${(props) => props.theme.palette.background.paper};
  border: 1px solid ${(props) => props.theme.palette.divider};
`

const FileInput = styled.input`
  display: none;
`

const Highlight = styled.div`
  background-color: #f5f5f5;
  height: 100px;
  border: 2px dashed rgb(187, 186, 186);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 16px;
`

const File = styled.div`
  padding: 4px 8px;
  background-color: #fafafa;
  border-left: 4px solid #0080f6;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const UploadCard: React.FC<Props> = ({ title, onUpload }) => {
  const [highlight, setHighlight] = useState<boolean>(false)
  const [filename, setFilename] = useState<string | null>(null)
  const [fileData, setFileData] = useState<string | null>(null)
  // const [fileType, setFileType] = useState<string | null>(null)
  // const [fileSize, setFileSize] = useState<number | null>(null)

  const inputRef = createRef<HTMLInputElement>()

  const readFile = (evt: ProgressEvent): void => {
    const reader: FileReader = evt.target as FileReader
    setFileData(reader.result as string)
  }

  const onFileChange = (file: File | null): void => {
    if (!file) {
      setFileData(null)
      return
    }
    const fileReader = new FileReader()
    fileReader.onloadend = readFile
    fileReader.readAsText(file)
    // setFileType(file.type)
    // setFileSize(file.size)
  }

  const openFileDialog = (): void => {
    if (!inputRef.current) return
    inputRef.current.click()
  }

  const onFileInputChange = (evt: ChangeEvent<HTMLInputElement>) => setFile(evt.target.files ? (evt.target.files[0] as File) : null)

  const onFileCancel = () => setFile(null)

  const setFile = (file: File | null): void => {
    if (onFileChange) onFileChange(file)
    setFilename(file ? file.name : null)
  }

  const onDragOver: DragEventHandler = (evt) => {
    evt.preventDefault()
    setHighlight(true)
  }

  const onDragLeave = () => setHighlight(false)

  const onDrop: DragEventHandler = (evt) => {
    evt.preventDefault()
    setFile(evt.dataTransfer ? (evt.dataTransfer.files[0] as File) : null)
    setHighlight(false)
  }

  return (
    <UploadCardRoot elevation={0} onDragOver={onDragOver} onDragLeave={onDragLeave} onDrop={onDrop}>
      <Typography variant='h6'>{title}</Typography>
      {highlight ? (
        <Highlight>
          <Typography variant='body1'>Drop it here to upload</Typography>
        </Highlight>
      ) : (
        <>
          {filename ? (
            <>
              <File>
                {filename}
                <IconButton onClick={onFileCancel}>
                  <Close />
                </IconButton>
              </File>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '16px' }}>
                <PrimaryButton onClick={() => fileData && onUpload(fileData)}>Upload</PrimaryButton>
              </div>
            </>
          ) : (
            <>
              <Typography variant='body1' color='textSecondary'>
                Drag and drop a file to upload
              </Typography>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '16px' }}>
                <SecondaryButton>Download Sample</SecondaryButton>
                <PrimaryButton onClick={openFileDialog}>Choose File</PrimaryButton>
                <FileInput ref={inputRef} type='file' onChange={onFileInputChange} />
              </div>
            </>
          )}
        </>
      )}
    </UploadCardRoot>
  )
}

export default UploadCard
