/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ShipmentFinanceDetails_shipment = {
    readonly slug: string;
    readonly stage: string;
    readonly shipmentType: string;
    readonly displayCustomsType: string | null;
    readonly freeWarehousingDays: number | null;
    readonly costWarehousingPerDay: number | null;
    readonly estimatedCostWarehousing: number | null;
    readonly estimatedRevenueWarehousing: number | null;
    readonly costWarehousing: number | null;
    readonly revenueWarehousing: number | null;
    readonly estimatedCustomsDuties: number | null;
    readonly customsDuties: number | null;
    readonly estimatedCustomsVat: number | null;
    readonly customsVat: number | null;
    readonly costDischarge: number | null;
    readonly revenueDischarge: number | null;
    readonly costTransport: number | null;
    readonly revenueTransport: number | null;
    readonly costCustomsInspection: number | null;
    readonly revenueCustomsInspection: number | null;
    readonly costClimaCertificate: number | null;
    readonly revenueClimaCertificate: number | null;
    readonly costOpinion: number | null;
    readonly revenueOpinion: number | null;
    readonly costContainerXray: number | null;
    readonly revenueContainerXray: number | null;
    readonly costContainerHandling: number | null;
    readonly revenueContainerHandling: number | null;
    readonly costShipping: number | null;
    readonly revenueShipping: number | null;
    readonly costCleaning: number | null;
    readonly revenueCleaning: number | null;
    readonly costSpecialHandling: number | null;
    readonly revenueSpecialHandling: number | null;
    readonly costStorage: number | null;
    readonly revenueStorage: number | null;
    readonly costOther: number | null;
    readonly costForklift: number | null;
    readonly revenueForklift: number | null;
    readonly revenueCustomsClearance: number | null;
    readonly costCustomsClearance: number | null;
    readonly discount: number | null;
    readonly discountRemark: number | null;
    readonly receivedPayment: number | null;
    readonly totalCustomsDutiesAndVat: number;
    readonly totalMiscRevenue: number;
    readonly totalMiscCost: number;
    readonly totalContainerRevenue: number | null;
    readonly totalContainerCost: number | null;
    readonly totalCost: number;
    readonly totalRevenue: number;
    readonly displayExpectedHandoverDate: string | null;
    readonly displayHandoverDate: string | null;
    readonly displayDischargeDate: string | null;
    readonly shipmentValue: number | null;
    readonly shippingCost: number | null;
    readonly shipmentValueCurrency: string | null;
    readonly shipmentTypeDetails: {
        readonly portHandlingCharge: number | null;
        readonly vatChargePercent: number | null;
        readonly dutiesRatePercent: number | null;
    } | null;
    readonly container: {
        readonly displayTerminalDate: string | null;
        readonly costXray: number | null;
        readonly costStoragePlusDemurrage: number | null;
        readonly costCleaning: number | null;
        readonly warehouseContact: {
            readonly slug: string;
            readonly name: string;
            readonly freeWarehousingDays: number | null;
            readonly costWarehousingPerDay: number | null;
            readonly costShipmentDischarge: number | null;
        } | null;
    };
    readonly rateCardContact: {
        readonly slug: string;
        readonly name: string;
        readonly rateCard: {
            readonly threePackCarDischarge: number | null;
            readonly forklift: number | null;
            readonly standardCustomsClearance: number | null;
            readonly specialHandling: number | null;
            readonly freeWarehousingDays: number | null;
            readonly costWarehousing: number | null;
        } | null;
    } | null;
    readonly customer: {
        readonly slug: string;
        readonly vatWarehousing: boolean;
        readonly vatRevenues: boolean;
    } | null;
    readonly " $refType": "ShipmentFinanceDetails_shipment";
};
export type ShipmentFinanceDetails_shipment$data = ShipmentFinanceDetails_shipment;
export type ShipmentFinanceDetails_shipment$key = {
    readonly " $data"?: ShipmentFinanceDetails_shipment$data;
    readonly " $fragmentRefs": FragmentRefs<"ShipmentFinanceDetails_shipment">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "freeWarehousingDays",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "costWarehousingPerDay",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "costWarehousing",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "costCleaning",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ShipmentFinanceDetails_shipment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "stage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shipmentType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayCustomsType",
      "storageKey": null
    },
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "estimatedCostWarehousing",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "estimatedRevenueWarehousing",
      "storageKey": null
    },
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "revenueWarehousing",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "estimatedCustomsDuties",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customsDuties",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "estimatedCustomsVat",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customsVat",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "costDischarge",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "revenueDischarge",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "costTransport",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "revenueTransport",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "costCustomsInspection",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "revenueCustomsInspection",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "costClimaCertificate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "revenueClimaCertificate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "costOpinion",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "revenueOpinion",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "costContainerXray",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "revenueContainerXray",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "costContainerHandling",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "revenueContainerHandling",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "costShipping",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "revenueShipping",
      "storageKey": null
    },
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "revenueCleaning",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "costSpecialHandling",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "revenueSpecialHandling",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "costStorage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "revenueStorage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "costOther",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "costForklift",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "revenueForklift",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "revenueCustomsClearance",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "costCustomsClearance",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "discount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "discountRemark",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "receivedPayment",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCustomsDutiesAndVat",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalMiscRevenue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalMiscCost",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalContainerRevenue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalContainerCost",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCost",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalRevenue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayExpectedHandoverDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayHandoverDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayDischargeDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shipmentValue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shippingCost",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shipmentValueCurrency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ShipmentTypeDetails",
      "kind": "LinkedField",
      "name": "shipmentTypeDetails",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "portHandlingCharge",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "vatChargePercent",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dutiesRatePercent",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Container",
      "kind": "LinkedField",
      "name": "container",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayTerminalDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "costXray",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "costStoragePlusDemurrage",
          "storageKey": null
        },
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Contact",
          "kind": "LinkedField",
          "name": "warehouseContact",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v5/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "costShipmentDischarge",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Contact",
      "kind": "LinkedField",
      "name": "rateCardContact",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ContactRateCard",
          "kind": "LinkedField",
          "name": "rateCard",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "threePackCarDischarge",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "forklift",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "standardCustomsClearance",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "specialHandling",
              "storageKey": null
            },
            (v1/*: any*/),
            (v3/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Contact",
      "kind": "LinkedField",
      "name": "customer",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "vatWarehousing",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "vatRevenues",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Shipment",
  "abstractKey": null
};
})();
(node as any).hash = '1b6fb35756312f9cc604c7861bc0ec02';
export default node;
