import React, { Suspense } from 'react'
import { DialogTitle } from '@material-ui/core'
import OdysseyDialog from './OdysseyDialog'
import DraftContactAdd from './DraftContactAdd'
interface Props {
  entitySlug: string
  entityType: string
  open: boolean
  title: string
  onClose: () => void
  onEdit: () => void
}

const ExternalLiveRequestAddDialog: React.FC<Props> = (props) => {
  return (
    <OdysseyDialog
      open={props.open}
      maxWidth='sm'
      fullWidth
      onClose={props.onClose}
      scroll='paper'
      style={{ maxWidth: '640px', margin: 'auto' }}
    >
      <DialogTitle>Raise new ticket</DialogTitle>
      <Suspense fallback={<p>Laoding..</p>}>
        <DraftContactAdd {...props} />
      </Suspense>
    </OdysseyDialog>
  )
}
export default ExternalLiveRequestAddDialog
