/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type StageStatusEnum = "active" | "completed" | "incomplete" | "%future added value";
export type ExternalContainerWorkflow_container = {
    readonly slug: string;
    readonly workflowStages: ReadonlyArray<{
        readonly value: string;
        readonly label: string;
        readonly ownedBy: string | null;
        readonly status: StageStatusEnum;
        readonly " $fragmentRefs": FragmentRefs<"ExternalStage_stage">;
    }>;
    readonly " $refType": "ExternalContainerWorkflow_container";
};
export type ExternalContainerWorkflow_container$data = ExternalContainerWorkflow_container;
export type ExternalContainerWorkflow_container$key = {
    readonly " $data"?: ExternalContainerWorkflow_container$data;
    readonly " $fragmentRefs": FragmentRefs<"ExternalContainerWorkflow_container">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExternalContainerWorkflow_container",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "WorkflowStage",
      "kind": "LinkedField",
      "name": "workflowStages",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ownedBy",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ExternalStage_stage"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ExternalContainer",
  "abstractKey": null
};
(node as any).hash = '2515954c4be2ca193edb30e1e1ba0bd9';
export default node;
