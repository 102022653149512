import { InputProps as StandardInputProps } from '@material-ui/core'
import React, { Suspense } from 'react'
import ArrayInputField from './ArrayInputField'
import BadInputField from './BadInputField'
import BooleanField from './BooleanField'
import CommonInputField from './CommonInputField'
import ContainerField from './ContainerField'
import DateField from './DateField'
import DateTimeField from './DateTimeField'
import FileInputField from './FileInputField'
import ImageInputField from './ImageInputField'
import LinkedContactsField from './LinkedContactsField'
import MultiSelectField from './MultiSelectField'
import ParentShipmentField from './ParentShipmentField'
import PortField from './PortField'
import RateCardField from './RateCardField'
import RichtextField from './RichTextField'
import ShippingLineField from './ShippingLineField'
import { FieldT, FieldVariantEnum } from './types'
import VesselField from './VesselField'
import VoyageField from './VoyageField'

interface Props {
  of?: string
  field?: FieldT
  variant?: FieldVariantEnum
  InputProps?: Partial<StandardInputProps>
  disabled?: boolean
  validate?: (value: string) => string | undefined
}

const InputField: React.FC<Props> = ({ of, field, variant, InputProps, disabled, validate }) => {
  if (!field) {
    return <BadInputField of={of} />
  }
  switch (field.type) {
    case 'date':
      return <DateField field={field} variant={variant} />
    case 'datetime':
      return <DateTimeField field={field} variant={variant} />
    case 'array':
      return <ArrayInputField field={field} variant={variant} />
    case 'container_image_slug':
    case 'shipment_image_slug':
    case 'image_slug':
      return (
        <Suspense fallback={<ImageInputField.Skeleton />}>
          <ImageInputField field={field} variant={variant} />
        </Suspense>
      )
    case 'file_slug':
      return (
        <Suspense fallback={<FileInputField.Skeleton />}>
          <FileInputField field={field} variant={variant} />
        </Suspense>
      )
    case 'rate_card':
      return (
        <Suspense fallback={<RateCardField.Skeleton />}>
          <RateCardField field={field} variant={variant} />
        </Suspense>
      )
    case 'shipping_line':
      return (
        <Suspense fallback={<ShippingLineField.Skeleton />}>
          <ShippingLineField field={field} variant={variant} />
        </Suspense>
      )
    case 'port':
      return (
        <Suspense fallback={<PortField.Skeleton />}>
          <PortField field={field} variant={variant} />
        </Suspense>
      )
    case 'parent_shipment':
      return <ParentShipmentField field={field} />
    case 'vessel':
      return <VesselField field={field} variant={variant} />
    case 'voyage':
      return <VoyageField field={field} variant={variant} />
    case 'container':
      return <ContainerField field={field} variant={variant} disabled={disabled} />
    case 'linked_contacts':
      return <LinkedContactsField field={field} variant={variant} />
    case 'rich_text':
      return <RichtextField field={field} variant={variant} />
    case 'boolean':
      return <BooleanField field={field} variant={variant} />
    case 'boolean_switch':
      return <BooleanField field={field} variant={variant} fieldVariant='switch' />
    case 'multi_select':
      return <MultiSelectField field={field} variant={variant} />
    default:
      return <CommonInputField field={field} variant={variant} InputProps={InputProps} validate={validate} disabled={disabled} />
  }
}

export default InputField
