/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type LiveShareEntityTypeEnum = "shipments" | "%future added value";
export type infoCreateLiveShareInput = {
    clientMutationId?: string | null;
    expiryCount: number;
    expiryType: string;
    contactSlug: string;
    entitySlug: string;
    entityType: LiveShareEntityTypeEnum;
};
export type LiveShareAddDialogMutationVariables = {
    input: infoCreateLiveShareInput;
};
export type LiveShareAddDialogMutationResponse = {
    readonly infoCreateLiveShare: {
        readonly clientMutationId: string | null;
    } | null;
};
export type LiveShareAddDialogMutation = {
    readonly response: LiveShareAddDialogMutationResponse;
    readonly variables: LiveShareAddDialogMutationVariables;
};



/*
mutation LiveShareAddDialogMutation(
  $input: infoCreateLiveShareInput!
) {
  infoCreateLiveShare(input: $input) {
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "infoCreateLiveSharePayload",
    "kind": "LinkedField",
    "name": "infoCreateLiveShare",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LiveShareAddDialogMutation",
    "selections": (v1/*: any*/),
    "type": "ApplicationCommand",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LiveShareAddDialogMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "2060f25bb3beee23dc4c8be369cd1c77",
    "id": null,
    "metadata": {},
    "name": "LiveShareAddDialogMutation",
    "operationKind": "mutation",
    "text": "mutation LiveShareAddDialogMutation(\n  $input: infoCreateLiveShareInput!\n) {\n  infoCreateLiveShare(input: $input) {\n    clientMutationId\n  }\n}\n"
  }
};
})();
(node as any).hash = '540d107e3e58a4b5d633078a960e0632';
export default node;
