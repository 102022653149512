import React from 'react'
import styled from 'styled-components'
import { Typography } from '@material-ui/core'

interface Props {
  note: string
  type?: string
  className?: string
}
interface StickyNoteProps {
  type?: string
}

const Root = styled.div<StickyNoteProps>`
  padding: 8px 16px;
  background-color: ${(props) => (props.type == 'internal' ? props.theme.customPalette.background.stickyNote : '#BDF0D1')};
  margin: 8px 0;
  white-space: pre-wrap;
  border-radius: 4px;
`

const StickyNote: React.FC<Props> = ({ note, className, type }) => {
  return (
    <Root className={className} type={type}>
      <Typography variant='body1'>{note}</Typography>
    </Root>
  )
}

export default StickyNote
