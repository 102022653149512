/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type TicketTypeEnum = "add_documents_and_photos" | "misc" | "request_for_documents" | "request_for_shipment_release" | "request_to_add_contact_link" | "request_to_create_a_new_contact" | "request_to_create_a_new_container" | "request_to_create_a_new_poa" | "request_to_create_a_new_shipment" | "request_to_create_container_shipment" | "signed_document_details" | "%future added value";
export type infoNewTicketInput = {
    clientMutationId?: string | null;
    entityType?: string | null;
    entitySlug?: string | null;
    title?: string | null;
    description?: string | null;
    ticketType: TicketTypeEnum;
    containerNumber?: string | null;
    voyageSlug?: string | null;
    containerSlug?: string | null;
    containerType?: string | null;
    shippingLineSlug?: string | null;
    shipments?: Array<ShipmentQuickAddInput> | null;
};
export type ShipmentQuickAddInput = {
    shipmentType: string;
    vin?: string | null;
    description: string;
    weight?: number | null;
    vinEngine?: string | null;
    vinFuelType?: string | null;
    vinBodyClass?: string | null;
    vinOrigin?: string | null;
};
export type ExternalTicketAddMutationVariables = {
    input: infoNewTicketInput;
};
export type ExternalTicketAddMutationResponse = {
    readonly infoNewTicket: {
        readonly clientMutationId: string | null;
        readonly ticket: {
            readonly slug: string;
        };
    } | null;
};
export type ExternalTicketAddMutation = {
    readonly response: ExternalTicketAddMutationResponse;
    readonly variables: ExternalTicketAddMutationVariables;
};



/*
mutation ExternalTicketAddMutation(
  $input: infoNewTicketInput!
) {
  infoNewTicket(input: $input) {
    clientMutationId
    ticket {
      slug
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientMutationId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ExternalTicketAddMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "infoNewTicketPayload",
        "kind": "LinkedField",
        "name": "infoNewTicket",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Ticket",
            "kind": "LinkedField",
            "name": "ticket",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ApplicationCommand",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ExternalTicketAddMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "infoNewTicketPayload",
        "kind": "LinkedField",
        "name": "infoNewTicket",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Ticket",
            "kind": "LinkedField",
            "name": "ticket",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4855a0b0a4f2050919429bc7bb864570",
    "id": null,
    "metadata": {},
    "name": "ExternalTicketAddMutation",
    "operationKind": "mutation",
    "text": "mutation ExternalTicketAddMutation(\n  $input: infoNewTicketInput!\n) {\n  infoNewTicket(input: $input) {\n    clientMutationId\n    ticket {\n      slug\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'd4e50e5f7abd83cdcc05a579e8bfdc20';
export default node;
