/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ContainerFinanceShowQueryVariables = {
    slug: string;
};
export type ContainerFinanceShowQueryResponse = {
    readonly containerShow: {
        readonly " $fragmentRefs": FragmentRefs<"ContainerFinance_container">;
    };
};
export type ContainerFinanceShowQuery = {
    readonly response: ContainerFinanceShowQueryResponse;
    readonly variables: ContainerFinanceShowQueryVariables;
};



/*
query ContainerFinanceShowQuery(
  $slug: String!
) {
  containerShow(slug: $slug) {
    ...ContainerFinance_container
    id
  }
}

fragment ContainerFinance_container on Container {
  slug
  finances {
    label
    value
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ContainerFinanceShowQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Container",
        "kind": "LinkedField",
        "name": "containerShow",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ContainerFinance_container"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ContainerFinanceShowQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Container",
        "kind": "LinkedField",
        "name": "containerShow",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "slug",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "KeyValueField",
            "kind": "LinkedField",
            "name": "finances",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "label",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9384804851c440e6a6eec7eebb848040",
    "id": null,
    "metadata": {},
    "name": "ContainerFinanceShowQuery",
    "operationKind": "query",
    "text": "query ContainerFinanceShowQuery(\n  $slug: String!\n) {\n  containerShow(slug: $slug) {\n    ...ContainerFinance_container\n    id\n  }\n}\n\nfragment ContainerFinance_container on Container {\n  slug\n  finances {\n    label\n    value\n  }\n}\n"
  }
};
})();
(node as any).hash = '3608218ada07f85f3b05399e55eab205';
export default node;
