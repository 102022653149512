import { InputBase, InputBaseProps } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'

const Input = styled(InputBase)`
  border: 1.5px solid transparent;
  background: #f2f2f2;
  border-radius: 8px;
  margin: 8px 0;

  & > input {
    box-sizing: content-box;
    line-height: 24px;
    border: 1.5px solid transparent;
    border-radius: 8px;
    padding: 16px;
  }

  & > input:hover {
    background: #efefef;
  }

  & > input:focus {
    border: 1.5px solid #0080f6;
  }
`

const StyledInput: React.FC<InputBaseProps> = (props) => {
  return <Input {...props} />
}

export default StyledInput
