import get from 'lodash/get'
import React from 'react'
import isObject from 'lodash/isObject'

import styled from 'styled-components'
import { RendererOptions } from '../DataTable'
import { Typography } from '@material-ui/core'
import withInputRenderer, { Icon } from './RendererHoc'

const StyledText = styled(Typography)`
  text-align: left;
  flex: 1;
  font-size: inherit;
  font-variant-numeric: tabular-nums;
  color: ${(props) => props.theme.palette.text.primary};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export function NumberRenderer<T extends any>({ identifier, rowData, icon, value }: RendererOptions<T>) {
  const data = value || get(rowData, identifier, 0)

  const formattedText = (parseFloat(data) || 0.0).toFixed(2)

  if (isObject(data)) {
    return (
      <>
        {icon && <Icon src={icon} />}
        <StyledText title={JSON.stringify(data)}>{JSON.stringify(data) || '--'}</StyledText>
      </>
    )
  }

  return (
    <>
      {icon && <Icon src={icon} />}
      <StyledText title={formattedText}>{formattedText}</StyledText>
    </>
  )
}

NumberRenderer.operators = ['contains', 'eq', 'neq', 'is_null', 'not_null']

export default withInputRenderer({ type: 'number' })(NumberRenderer)
