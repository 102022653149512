/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type FormFieldTypeEnum = "array" | "boolean" | "boolean_switch" | "container" | "container_image_slug" | "date" | "datetime" | "doc_slug" | "file_slug" | "image_slug" | "linked_contacts" | "multi_select" | "number" | "parent_shipment" | "port" | "rate_card" | "rich_text" | "searchable" | "shipment_image_slug" | "shipping_line" | "string" | "vessel" | "voyage" | "%future added value";
export type DraftContactAddQueryVariables = {};
export type DraftContactAddQueryResponse = {
    readonly infoAddDraftContactForm: {
        readonly formSchema: {
            readonly fieldSet: ReadonlyArray<{
                readonly name: string;
                readonly type: FormFieldTypeEnum;
                readonly required: boolean;
                readonly title: string | null;
                readonly description: string | null;
                readonly format: string | null;
                readonly enum: ReadonlyArray<string | null> | null;
                readonly enumTitles: ReadonlyArray<string | null> | null;
                readonly placeholder: string | null;
            }>;
        };
    };
};
export type DraftContactAddQuery = {
    readonly response: DraftContactAddQueryResponse;
    readonly variables: DraftContactAddQueryVariables;
};



/*
query DraftContactAddQuery {
  infoAddDraftContactForm {
    formSchema {
      fieldSet {
        name
        type
        required
        title
        description
        format
        enum
        enumTitles
        placeholder
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "InfoAddDraftContactFormResponse",
    "kind": "LinkedField",
    "name": "infoAddDraftContactForm",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "FormSchema",
        "kind": "LinkedField",
        "name": "formSchema",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FormField",
            "kind": "LinkedField",
            "name": "fieldSet",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "required",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "format",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "enum",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "enumTitles",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "placeholder",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DraftContactAddQuery",
    "selections": (v0/*: any*/),
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DraftContactAddQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "963bab885c67c7a232d6a6cf741c1cb4",
    "id": null,
    "metadata": {},
    "name": "DraftContactAddQuery",
    "operationKind": "query",
    "text": "query DraftContactAddQuery {\n  infoAddDraftContactForm {\n    formSchema {\n      fieldSet {\n        name\n        type\n        required\n        title\n        description\n        format\n        enum\n        enumTitles\n        placeholder\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '1f441c4cf1e90418df6502a2129d1a0e';
export default node;
