import React from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import { ShipmentEmailsQuery } from '../../__generated__/ShipmentEmailsQuery.graphql'
import EmailsTab from '../../components/Email/EmailsTab'
import useLazyLoadQueryWithSubscription from '../../hooks/useLazyLoadQueryWithSubscription'

const ShipmentEmails: React.FC<{ slug: string }> & { Skeleton: React.FC } = ({ slug }) => {
  const queryData = useLazyLoadQueryWithSubscription<ShipmentEmailsQuery>(
    shipmentQuery,
    { slug },
    { subscriptionOptions: { entityType: 'shipments', entitySlug: slug } }
  )

  return <EmailsTab emails={queryData.shipmentShow.emails} entityType='shipments' entitySlug={slug} />
}

const shipmentQuery = graphql`
  query ShipmentEmailsQuery($slug: String!) {
    shipmentShow(slug: $slug) {
      emails {
        ...EmailsTab_emails
      }
    }
  }
`

ShipmentEmails.Skeleton = () => <EmailsTab.Skeleton />

export default ShipmentEmails
