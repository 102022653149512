/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ExternalShowItemTypeEnum = "contact" | "container" | "shipment" | "%future added value";
export type ExternalContainerShowHeroCardQueryVariables = {
    slug: string;
    itemType: ExternalShowItemTypeEnum;
};
export type ExternalContainerShowHeroCardQueryResponse = {
    readonly externalItemShow: {
        readonly slug: string;
        readonly title: string;
        readonly image: {
            readonly url: string | null;
            readonly alt: string | null;
            readonly " $fragmentRefs": FragmentRefs<"ItemImage_data">;
        } | null;
        readonly badges: ReadonlyArray<{
            readonly key: string;
            readonly " $fragmentRefs": FragmentRefs<"Badge_data">;
        }> | null;
        readonly lines: ReadonlyArray<{
            readonly key: string;
            readonly " $fragmentRefs": FragmentRefs<"SemanticLine_data">;
        }> | null;
        readonly keyFields: ReadonlyArray<{
            readonly key: string;
            readonly " $fragmentRefs": FragmentRefs<"KeyField_data">;
        }> | null;
    };
    readonly externalContainerShow: {
        readonly slug: string;
        readonly isArchived: boolean;
        readonly stage: string | null;
        readonly releaseInvoiceStatus: string | null;
        readonly subStage: {
            readonly " $fragmentRefs": FragmentRefs<"SubStage_data">;
        };
        readonly watches: {
            readonly " $fragmentRefs": FragmentRefs<"Watchers_watches">;
        };
        readonly shipmentItems: ReadonlyArray<{
            readonly slug: string;
            readonly title: string;
            readonly badges: ReadonlyArray<{
                readonly key: string;
                readonly " $fragmentRefs": FragmentRefs<"Badge_data">;
            }> | null;
            readonly image: {
                readonly url: string | null;
            } | null;
            readonly lines: ReadonlyArray<{
                readonly key: string;
                readonly " $fragmentRefs": FragmentRefs<"SemanticLine_data">;
            }> | null;
        }>;
        readonly " $fragmentRefs": FragmentRefs<"ExternalContainerWorkflow_container">;
    };
};
export type ExternalContainerShowHeroCardQuery = {
    readonly response: ExternalContainerShowHeroCardQueryResponse;
    readonly variables: ExternalContainerShowHeroCardQueryVariables;
};



/*
query ExternalContainerShowHeroCardQuery(
  $slug: String!
  $itemType: ExternalShowItemTypeEnum!
) {
  externalItemShow(slug: $slug, type: $itemType) {
    slug
    title
    image {
      url
      alt
      ...ItemImage_data
    }
    badges {
      key
      ...Badge_data
    }
    lines {
      key
      ...SemanticLine_data
    }
    keyFields {
      key
      ...KeyField_data
    }
    id
  }
  externalContainerShow(slug: $slug) {
    slug
    isArchived
    stage
    releaseInvoiceStatus
    ...ExternalContainerWorkflow_container
    subStage {
      ...SubStage_data
    }
    watches {
      ...Watchers_watches
    }
    shipmentItems {
      slug
      title
      badges {
        key
        ...Badge_data
      }
      image {
        url
      }
      lines {
        key
        ...SemanticLine_data
      }
      id
    }
    id
  }
}

fragment Badge_data on Badge {
  type
}

fragment ExternalContainerWorkflow_container on ExternalContainer {
  slug
  workflowStages {
    value
    label
    ownedBy
    status
    ...ExternalStage_stage
  }
}

fragment ExternalStage_stage on WorkflowStage {
  value
  status
  label
  completedAt
  ownedBy
}

fragment ItemImage_data on Image {
  url
  alt
  highlight
  shape
  overlay
  overlayIcon
}

fragment KeyField_data on KeyField {
  label
  value {
    key
    ...SemanticString_data
  }
}

fragment SemanticLine_data on SemanticLine {
  parts {
    key
    ...SemanticString_data
  }
}

fragment SemanticString_data on SemanticString {
  text
}

fragment SubStage_data on SubStage {
  stageIdx
  stage
  value
  displayValue
  status
  label
  validSubStages {
    value
    displayValue
  }
}

fragment Watchers_watches on ItemWatchConnection {
  nodes {
    contactSlug
    contactItem {
      title
      image {
        url
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "itemType"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "slug"
},
v2 = {
  "kind": "Variable",
  "name": "slug",
  "variableName": "slug"
},
v3 = [
  (v2/*: any*/),
  {
    "kind": "Variable",
    "name": "type",
    "variableName": "itemType"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "alt",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Badge",
  "kind": "LinkedField",
  "name": "badges",
  "plural": true,
  "selections": [
    (v8/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Badge_data"
    }
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "SemanticLine",
  "kind": "LinkedField",
  "name": "lines",
  "plural": true,
  "selections": [
    (v8/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SemanticLine_data"
    }
  ],
  "storageKey": null
},
v11 = [
  (v2/*: any*/)
],
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isArchived",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stage",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "releaseInvoiceStatus",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "Image",
  "kind": "LinkedField",
  "name": "image",
  "plural": false,
  "selections": [
    (v6/*: any*/)
  ],
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "Badge",
  "kind": "LinkedField",
  "name": "badges",
  "plural": true,
  "selections": [
    (v8/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v17 = [
  (v8/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "text",
    "storageKey": null
  }
],
v18 = {
  "alias": null,
  "args": null,
  "concreteType": "SemanticLine",
  "kind": "LinkedField",
  "name": "lines",
  "plural": true,
  "selections": [
    (v8/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "SemanticString",
      "kind": "LinkedField",
      "name": "parts",
      "plural": true,
      "selections": (v17/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayValue",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ExternalContainerShowHeroCardQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "ExternalListItem",
        "kind": "LinkedField",
        "name": "externalItemShow",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Image",
            "kind": "LinkedField",
            "name": "image",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ItemImage_data"
              }
            ],
            "storageKey": null
          },
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "KeyField",
            "kind": "LinkedField",
            "name": "keyFields",
            "plural": true,
            "selections": [
              (v8/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "KeyField_data"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v11/*: any*/),
        "concreteType": "ExternalContainer",
        "kind": "LinkedField",
        "name": "externalContainerShow",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SubStage",
            "kind": "LinkedField",
            "name": "subStage",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SubStage_data"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ItemWatchConnection",
            "kind": "LinkedField",
            "name": "watches",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "Watchers_watches"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ListItem",
            "kind": "LinkedField",
            "name": "shipmentItems",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v9/*: any*/),
              (v15/*: any*/),
              (v10/*: any*/)
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ExternalContainerWorkflow_container"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ExternalContainerShowHeroCardQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "ExternalListItem",
        "kind": "LinkedField",
        "name": "externalItemShow",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Image",
            "kind": "LinkedField",
            "name": "image",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "highlight",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "shape",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "overlay",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "overlayIcon",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v16/*: any*/),
          (v18/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "KeyField",
            "kind": "LinkedField",
            "name": "keyFields",
            "plural": true,
            "selections": [
              (v8/*: any*/),
              (v19/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SemanticString",
                "kind": "LinkedField",
                "name": "value",
                "plural": true,
                "selections": (v17/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v20/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v11/*: any*/),
        "concreteType": "ExternalContainer",
        "kind": "LinkedField",
        "name": "externalContainerShow",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "WorkflowStage",
            "kind": "LinkedField",
            "name": "workflowStages",
            "plural": true,
            "selections": [
              (v21/*: any*/),
              (v19/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ownedBy",
                "storageKey": null
              },
              (v22/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "completedAt",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SubStage",
            "kind": "LinkedField",
            "name": "subStage",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "stageIdx",
                "storageKey": null
              },
              (v13/*: any*/),
              (v21/*: any*/),
              (v23/*: any*/),
              (v22/*: any*/),
              (v19/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ValidSubStage",
                "kind": "LinkedField",
                "name": "validSubStages",
                "plural": true,
                "selections": [
                  (v21/*: any*/),
                  (v23/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ItemWatchConnection",
            "kind": "LinkedField",
            "name": "watches",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ItemWatch",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "contactSlug",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ListItem",
                    "kind": "LinkedField",
                    "name": "contactItem",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      (v15/*: any*/),
                      (v20/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ListItem",
            "kind": "LinkedField",
            "name": "shipmentItems",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v16/*: any*/),
              (v15/*: any*/),
              (v18/*: any*/),
              (v20/*: any*/)
            ],
            "storageKey": null
          },
          (v20/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "350fc46e4b2dad282bf5929152ec86c3",
    "id": null,
    "metadata": {},
    "name": "ExternalContainerShowHeroCardQuery",
    "operationKind": "query",
    "text": "query ExternalContainerShowHeroCardQuery(\n  $slug: String!\n  $itemType: ExternalShowItemTypeEnum!\n) {\n  externalItemShow(slug: $slug, type: $itemType) {\n    slug\n    title\n    image {\n      url\n      alt\n      ...ItemImage_data\n    }\n    badges {\n      key\n      ...Badge_data\n    }\n    lines {\n      key\n      ...SemanticLine_data\n    }\n    keyFields {\n      key\n      ...KeyField_data\n    }\n    id\n  }\n  externalContainerShow(slug: $slug) {\n    slug\n    isArchived\n    stage\n    releaseInvoiceStatus\n    ...ExternalContainerWorkflow_container\n    subStage {\n      ...SubStage_data\n    }\n    watches {\n      ...Watchers_watches\n    }\n    shipmentItems {\n      slug\n      title\n      badges {\n        key\n        ...Badge_data\n      }\n      image {\n        url\n      }\n      lines {\n        key\n        ...SemanticLine_data\n      }\n      id\n    }\n    id\n  }\n}\n\nfragment Badge_data on Badge {\n  type\n}\n\nfragment ExternalContainerWorkflow_container on ExternalContainer {\n  slug\n  workflowStages {\n    value\n    label\n    ownedBy\n    status\n    ...ExternalStage_stage\n  }\n}\n\nfragment ExternalStage_stage on WorkflowStage {\n  value\n  status\n  label\n  completedAt\n  ownedBy\n}\n\nfragment ItemImage_data on Image {\n  url\n  alt\n  highlight\n  shape\n  overlay\n  overlayIcon\n}\n\nfragment KeyField_data on KeyField {\n  label\n  value {\n    key\n    ...SemanticString_data\n  }\n}\n\nfragment SemanticLine_data on SemanticLine {\n  parts {\n    key\n    ...SemanticString_data\n  }\n}\n\nfragment SemanticString_data on SemanticString {\n  text\n}\n\nfragment SubStage_data on SubStage {\n  stageIdx\n  stage\n  value\n  displayValue\n  status\n  label\n  validSubStages {\n    value\n    displayValue\n  }\n}\n\nfragment Watchers_watches on ItemWatchConnection {\n  nodes {\n    contactSlug\n    contactItem {\n      title\n      image {\n        url\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'f3d67c274b83946184be3e7600054480';
export default node;
