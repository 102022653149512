/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ExternalItemListFooter_data = {
    readonly paginationMeta: {
        readonly hasNextPage: boolean;
        readonly hasPrevPage: boolean;
        readonly page: number;
        readonly perPage: number;
        readonly totalPages: number;
    } | null;
    readonly " $refType": "ExternalItemListFooter_data";
};
export type ExternalItemListFooter_data$data = ExternalItemListFooter_data;
export type ExternalItemListFooter_data$key = {
    readonly " $data"?: ExternalItemListFooter_data$data;
    readonly " $fragmentRefs": FragmentRefs<"ExternalItemListFooter_data">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExternalItemListFooter_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Paginate",
      "kind": "LinkedField",
      "name": "paginationMeta",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasNextPage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasPrevPage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "page",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "perPage",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalPages",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ExternalItemList",
  "abstractKey": null
};
(node as any).hash = '16044a282fb395093994c17415756084';
export default node;
