import React from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import Button from './Button'
import { GeneratableAtcItemMutation } from '../__generated__/GeneratableAtcItemMutation.graphql'
import useNiceMutation from '../mutations/useNiceMutation'
import styled from 'styled-components'
import { useSnackbar } from 'notistack'

interface Props {
  slug: string
}

const InlineButton = styled(Button)`
  margin: 0px;
  padding: 0px;
`

const GeneratableAtcItem: React.FC<Props> = ({ slug }) => {
  const [commitGenerate] = useNiceMutation<GeneratableAtcItemMutation>(generateMutation)
  const { enqueueSnackbar } = useSnackbar()
  const generateDoc = () => {
    commitGenerate({
      variables: {
        input: {
          entitySlug: slug,
        },
      },
      onCompleted: (_, errors) => {
        if (!errors) {
          enqueueSnackbar('Xml generated successfully', { variant: 'success' })
        }
      },
    })
  }

  return (
    <>
      <InlineButton onClick={generateDoc}>Create ATC</InlineButton>
    </>
  )
}

const generateMutation = graphql`
  mutation GeneratableAtcItemMutation($input: infoGenerateAtcInput!) {
    infoGenerateAtc(input: $input) {
      clientMutationId
      result
    }
  }
`

export default GeneratableAtcItem
