import React from 'react'
import { BrowserRouterProps, Router } from 'react-router-dom'
import { BrowserHistory, createBrowserHistory, Update } from 'history'
import LoadingBar from 'react-top-loading-bar'

const OdysseyBrowserRouter: React.FC<BrowserRouterProps> = ({ children, window }) => {
  const historyRef = React.useRef<BrowserHistory>()
  const loadingBarRef = React.useRef<{ continuousStart: () => void; complete: () => void } | null>()
  if (historyRef.current == null) {
    historyRef.current = createBrowserHistory({ window })
  }

  const [startTransition, isPending] = React.unstable_useTransition()

  const history = historyRef.current
  const [state, dispatch] = React.useReducer((_: Update, action: Update) => action, {
    action: history.action,
    location: history.location,
  })

  React.useLayoutEffect(
    () =>
      history.listen((update) => {
        startTransition(() => {
          dispatch(update)
        })
      }),
    [history, startTransition]
  )

  React.useEffect(() => {
    if (isPending) {
      loadingBarRef.current?.continuousStart()
    } else {
      loadingBarRef.current?.complete()
    }
  }, [isPending])

  return (
    <Router action={state.action} location={state.location} navigator={history}>
      <LoadingBar color={'#80d1ff'} ref={loadingBarRef} waitingTime={300} />
      {children}
    </Router>
  )
}

export default OdysseyBrowserRouter
