import { graphql } from 'babel-plugin-relay/macro'
import React from 'react'
import { useLazyLoadQuery } from 'react-relay/hooks'
import FormActionBar from '../../components/Form/FormActionBar'
import NiceFormik from '../../components/Form/NiceFormik'
import VoyageForm from '../../forms/Voyage/VoyageForm'
import useNiceMutation from '../../mutations/useNiceMutation'
import { VoyageEditFormQuery } from '../../__generated__/VoyageEditFormQuery.graphql'
import { VoyageEditMutation } from '../../__generated__/VoyageEditMutation.graphql'
import FormContainer from '../../forms/FormContainer'

interface Props {
  slug: string
  onClose: () => void
}

const VoyageEdit: React.FC<Props> & { Skeleton: React.FC } = ({ slug, onClose }) => {
  const data = useLazyLoadQuery<VoyageEditFormQuery>(query, { slug: slug })
  const fields = data.infoUpdateVoyageForm.formSchema.fieldSet
  const fieldMaster = Object.fromEntries(fields.map((field) => [field.name, field]))

  const [commit] = useNiceMutation<VoyageEditMutation>(mutation)

  return (
    <>
      <NiceFormik
        initialValues={data.infoUpdateVoyageForm.initialValue || {}}
        onSubmit={(values, actions) => {
          commit({
            variables: {
              input: values as any,
            },
            onError: () => actions.setSubmitting(false),
            onCompleted: (res, errors) => {
              actions.setSubmitting(false)
              if (!errors) {
                onClose()
              }
            },
          })
        }}
        formSchema={fields}
      >
        {({ submitForm, values }) => (
          <FormContainer>
            <VoyageForm fieldMaster={fieldMaster} />
            <FormActionBar title={`Editing voyage ${(values as any).name}`} onCancel={() => onClose()} onSubmit={() => submitForm()} />
          </FormContainer>
        )}
      </NiceFormik>
    </>
  )
}

const query = graphql`
  query VoyageEditFormQuery($slug: String!) {
    infoUpdateVoyageForm(slug: $slug) {
      formSchema {
        fieldSet {
          name
          type
          required
          title
          description
          format
          enum
          enumTitles
        }
      }
      initialValue {
        slug
        name
        vesselSlug
        sourcePortCode
        destinationPortCode
        shouldFetchEta
        eta
        etd
      }
    }
  }
`

const mutation = graphql`
  mutation VoyageEditMutation($input: infoUpdateVoyageInput!) {
    infoUpdateVoyage(input: $input) {
      voyage {
        slug
      }
    }
  }
`

VoyageEdit.Skeleton = () => (
  <>
    <VoyageForm.Skeleton />
    <FormActionBar.Skeleton />
  </>
)

export default VoyageEdit
