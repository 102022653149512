/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ResetPasswordFormQueryVariables = {
    token: string;
};
export type ResetPasswordFormQueryResponse = {
    readonly resetPasswordValidity: {
        readonly isValid: boolean;
        readonly title: string;
        readonly subtext: string;
    };
};
export type ResetPasswordFormQuery = {
    readonly response: ResetPasswordFormQueryResponse;
    readonly variables: ResetPasswordFormQueryVariables;
};



/*
query ResetPasswordFormQuery(
  $token: String!
) {
  resetPasswordValidity(token: $token) {
    isValid
    title
    subtext
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "token"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "token",
        "variableName": "token"
      }
    ],
    "concreteType": "ResetPasswordValidationResponse",
    "kind": "LinkedField",
    "name": "resetPasswordValidity",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isValid",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "subtext",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ResetPasswordFormQuery",
    "selections": (v1/*: any*/),
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ResetPasswordFormQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b0d872f8c682a08163fa0f3865174210",
    "id": null,
    "metadata": {},
    "name": "ResetPasswordFormQuery",
    "operationKind": "query",
    "text": "query ResetPasswordFormQuery(\n  $token: String!\n) {\n  resetPasswordValidity(token: $token) {\n    isValid\n    title\n    subtext\n  }\n}\n"
  }
};
})();
(node as any).hash = 'f4231d08df4f201daaa6cb6b364dbee3';
export default node;
