import React, { useMemo } from 'react'
import { Formik, FormikValues, FormikConfig } from 'formik'
import { FieldT } from './types'
import { formSchemaToYup } from './helpers'

function NiceFormik<Values extends FormikValues = FormikValues, ExtraProps = Record<string, unknown>>(
  props: FormikConfig<Values> & ExtraProps & { formSchema?: ReadonlyArray<FieldT> }
): JSX.Element {
  const validationSchema = useMemo(() => (props.formSchema ? formSchemaToYup(props.formSchema) : undefined), [props.formSchema])
  return <Formik {...props} validationSchema={validationSchema} />
}

export default NiceFormik
