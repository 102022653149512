/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ExternalDashboardMainContentQueryVariables = {};
export type ExternalDashboardMainContentQueryResponse = {
    readonly externalDashboardQuery: {
        readonly chartContainersByLocation: {
            readonly labels: ReadonlyArray<string>;
            readonly values: ReadonlyArray<number>;
            readonly paths: ReadonlyArray<string>;
        };
        readonly chartContainersByStage: {
            readonly labels: ReadonlyArray<string>;
            readonly values: ReadonlyArray<number>;
            readonly paths: ReadonlyArray<string>;
        };
        readonly chartShipmentsByStage: {
            readonly labels: ReadonlyArray<string>;
            readonly values: ReadonlyArray<number>;
            readonly paths: ReadonlyArray<string>;
        };
        readonly chartProcessOverdue: {
            readonly labels: ReadonlyArray<string>;
            readonly values: ReadonlyArray<number>;
            readonly paths: ReadonlyArray<string>;
        };
        readonly tsContainersEta: {
            readonly labels: ReadonlyArray<string>;
            readonly values: ReadonlyArray<number>;
            readonly paths: ReadonlyArray<string>;
            readonly startIndex: number | null;
        };
        readonly tsShipmentEta: {
            readonly labels: ReadonlyArray<string>;
            readonly values: ReadonlyArray<number>;
            readonly paths: ReadonlyArray<string>;
            readonly startIndex: number | null;
        };
        readonly tsCostsAndRevenues: {
            readonly labels: ReadonlyArray<string>;
            readonly values: ReadonlyArray<{
                readonly revenue: number;
                readonly containerCost: number;
                readonly shipmentCost: number;
            }>;
            readonly paths: ReadonlyArray<string>;
            readonly startIndex: number | null;
        };
    } | null;
};
export type ExternalDashboardMainContentQuery = {
    readonly response: ExternalDashboardMainContentQueryResponse;
    readonly variables: ExternalDashboardMainContentQueryVariables;
};



/*
query ExternalDashboardMainContentQuery {
  externalDashboardQuery {
    chartContainersByLocation {
      labels
      values
      paths
    }
    chartContainersByStage {
      labels
      values
      paths
    }
    chartShipmentsByStage {
      labels
      values
      paths
    }
    chartProcessOverdue {
      labels
      values
      paths
    }
    tsContainersEta {
      labels
      values
      paths
      startIndex
    }
    tsShipmentEta {
      labels
      values
      paths
      startIndex
    }
    tsCostsAndRevenues {
      labels
      values {
        revenue
        containerCost
        shipmentCost
      }
      paths
      startIndex
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "labels",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "values",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paths",
  "storageKey": null
},
v3 = [
  (v0/*: any*/),
  (v1/*: any*/),
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startIndex",
  "storageKey": null
},
v5 = [
  (v0/*: any*/),
  (v1/*: any*/),
  (v2/*: any*/),
  (v4/*: any*/)
],
v6 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Dashboard",
    "kind": "LinkedField",
    "name": "externalDashboardQuery",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ChartData",
        "kind": "LinkedField",
        "name": "chartContainersByLocation",
        "plural": false,
        "selections": (v3/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ChartData",
        "kind": "LinkedField",
        "name": "chartContainersByStage",
        "plural": false,
        "selections": (v3/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ChartData",
        "kind": "LinkedField",
        "name": "chartShipmentsByStage",
        "plural": false,
        "selections": (v3/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ChartData",
        "kind": "LinkedField",
        "name": "chartProcessOverdue",
        "plural": false,
        "selections": (v3/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ChartData",
        "kind": "LinkedField",
        "name": "tsContainersEta",
        "plural": false,
        "selections": (v5/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ChartData",
        "kind": "LinkedField",
        "name": "tsShipmentEta",
        "plural": false,
        "selections": (v5/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CostRevenueChartData",
        "kind": "LinkedField",
        "name": "tsCostsAndRevenues",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CostRevenueChartValue",
            "kind": "LinkedField",
            "name": "values",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "revenue",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "containerCost",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "shipmentCost",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ExternalDashboardMainContentQuery",
    "selections": (v6/*: any*/),
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ExternalDashboardMainContentQuery",
    "selections": (v6/*: any*/)
  },
  "params": {
    "cacheID": "6f503446965d392d37149046e31d1193",
    "id": null,
    "metadata": {},
    "name": "ExternalDashboardMainContentQuery",
    "operationKind": "query",
    "text": "query ExternalDashboardMainContentQuery {\n  externalDashboardQuery {\n    chartContainersByLocation {\n      labels\n      values\n      paths\n    }\n    chartContainersByStage {\n      labels\n      values\n      paths\n    }\n    chartShipmentsByStage {\n      labels\n      values\n      paths\n    }\n    chartProcessOverdue {\n      labels\n      values\n      paths\n    }\n    tsContainersEta {\n      labels\n      values\n      paths\n      startIndex\n    }\n    tsShipmentEta {\n      labels\n      values\n      paths\n      startIndex\n    }\n    tsCostsAndRevenues {\n      labels\n      values {\n        revenue\n        containerCost\n        shipmentCost\n      }\n      paths\n      startIndex\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '00a3c5c22368b2edc7a2d518738866b5';
export default node;
