import React from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import styled from 'styled-components'
import { ShipmentFinanceMetricCard_shipment$key } from '../../__generated__/ShipmentFinanceMetricCard_shipment.graphql'
import { useFragment } from 'react-relay/hooks'
import { Paper, Typography } from '@material-ui/core'
import { asFormattedCurrency } from './utils'
import MoneyIconUri from '../../assets/public/money_icon.svg'
import Button from '../Button'
import useWorkspaceNavigate from '../../hooks/useWorkspaceNavigate'

interface Props {
  shipment: ShipmentFinanceMetricCard_shipment$key
  withTitle?: boolean
}

const RootPaper = styled(Paper)`
  margin-bottom: 16px;
`
const MetricContainer = styled.div`
  padding: 16px;
`

const MetricCellsContainer = styled.div<{ $withTopBorder?: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-top: 1px solid ${(props) => (props.$withTopBorder ? props.theme.palette.divider : 'transparent')};

  ${MetricContainer}:first-of-type {
    border-right: 1px solid ${(props) => props.theme.palette.divider};
  }
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  padding: 16px;
  padding-bottom: 0px;
`

const MetricSubtitle = styled.div`
  display: flex;
  align-items: center;
`

const MetricSubtitleProfitTypography = styled(Typography)<{ $polarity: 'positive' | 'negative' }>`
  color: ${(props) => (props.$polarity === 'positive' ? '#1A7734' : props.theme.palette.error.main)};
`

const SubtitleIconImage = styled.img`
  height: 14px;
  width: 14px;
  margin-left: 8px;
`

const MetricTitle: React.FC = (props) => <Typography variant='body1'>{props.children}</Typography>
const MetricFigure: React.FC = (props) => <Typography variant='h4'>{props.children}</Typography>

const ShipmentFinanceMetricCard: React.FC<Props> = ({ shipment: data, withTitle }) => {
  const wsNavigate = useWorkspaceNavigate()
  const shipment = useFragment(fragment, data)
  return (
    <RootPaper variant='outlined'>
      {withTitle && (
        <TitleContainer>
          <Typography variant='h6'>Finances</Typography>
          <Button onClick={() => wsNavigate(`/shipment/${shipment.slug}/finances`)}>See All</Button>
        </TitleContainer>
      )}
      <MetricCellsContainer $withTopBorder={withTitle}>
        <MetricContainer>
          <MetricTitle>Total Revenue</MetricTitle>
          <MetricFigure>{asFormattedCurrency(shipment.totalRevenue)}</MetricFigure>
          <MetricSubtitle>
            <Typography variant='caption'>
              {asFormattedCurrency(shipment.vatCalculator != null ? shipment.vatCalculator : shipment.totalRevenue)} including VAT
            </Typography>
          </MetricSubtitle>
          <MetricSubtitle>
            <Typography variant='caption'>
              <MetricSubtitleProfitTypography variant='caption' $polarity={shipment.totalProfit >= 0 ? 'positive' : 'negative'}>
                {asFormattedCurrency(shipment.totalProfit)}
              </MetricSubtitleProfitTypography>
              {' in profit'}
            </Typography>
            <SubtitleIconImage src={MoneyIconUri} />
          </MetricSubtitle>
        </MetricContainer>
        <MetricContainer>
          <MetricTitle>Total Cost</MetricTitle>
          <MetricFigure>{asFormattedCurrency(shipment.totalCost)}</MetricFigure>
          <MetricSubtitle>
            <Typography variant='caption'>{asFormattedCurrency(shipment.lexofficeInvoiceAmountPaid)} Received</Typography>
          </MetricSubtitle>
        </MetricContainer>
      </MetricCellsContainer>
    </RootPaper>
  )
}

const fragment = graphql`
  fragment ShipmentFinanceMetricCard_shipment on Shipment {
    slug
    totalCost
    totalRevenue
    totalProfit
    shipmentTypeDetails {
      vatChargePercent
    }
    estimatedRevenueWarehousing
    vatCalculator
    lexofficeInvoiceAmountPaid
  }
`

export default ShipmentFinanceMetricCard
