import { Typography } from '@material-ui/core'
import { graphql } from 'babel-plugin-relay/macro'
import React from 'react'
import { useFragment } from 'react-relay/hooks'
import styled from 'styled-components'
import { SmallAttachment_attachment$key } from '../../__generated__/SmallAttachment_attachment.graphql'

interface Props {
  attachment: SmallAttachment_attachment$key
}

const Root = styled.a`
  border: 1px solid ${(props) => props.theme.customPalette.border.card};
  border-radius: 8px;
  display: flex;
  align-items: center;
  overflow: hidden;
  cursor: pointer;

  text-decoration: none;
  color: inherit;

  &:hover {
    filter: brightness(0.95);
  }
`

const Preview = styled.img<{ $iconPreview?: boolean }>`
  margin-left: 8px;
  margin-right: 8px;
  height: 16px;
  width: 16px;
  object-fit: cover;
`

const AttachmentName = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  padding-right: 4px;
`

const SmallAttachment: React.FC<Props> = ({ attachment: data }) => {
  const attachment = useFragment(fragment, data)
  const previewUrl = `https://drive-thirdparty.googleusercontent.com/64/type/${attachment.contentType}`
  const isIconPreview = attachment.previewUrl ? false : true

  return (
    <Root href={attachment.url} target='_blank'>
      <Preview src={previewUrl} $iconPreview={isIconPreview} />
      <AttachmentName variant='body2'>{attachment.name}</AttachmentName>
    </Root>
  )
}

const fragment = graphql`
  fragment SmallAttachment_attachment on Attachment {
    url
    name
    contentType
    previewUrl
  }
`

export default SmallAttachment
