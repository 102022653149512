/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type infoUpdateShipmentInfoInput = {
    clientMutationId?: string | null;
    slug: string;
    shipmentValue?: number | null;
    shippingCost?: number | null;
    shipmentValueCurrency?: string | null;
    shipmentCostCurrency?: string | null;
    title?: string | null;
    weight?: number | null;
    customsStartDate?: string | null;
    customsFinishedDate?: string | null;
    climaNeededDate?: string | null;
    zodiakId?: string | null;
    paymentChannel?: string | null;
    paymentDate?: string | null;
    expectedHandoverDate?: string | null;
    handoverDate?: string | null;
    pickupDone?: boolean | null;
    customsResponsible?: string | null;
    receivedPayment?: number | null;
    estimatedCustomsDuties?: number | null;
    estimatedCustomsVat?: number | null;
    location?: string | null;
    incoTerms?: string | null;
    incoCity?: string | null;
    bsNumber?: string | null;
    bsDate?: string | null;
    shippingInvoiceNumber?: string | null;
    shippingInvoiceDate?: string | null;
    clima?: string | null;
    titleType?: string | null;
    damage?: boolean | null;
};
export type ShipmentInfoCardEditMutationVariables = {
    input: infoUpdateShipmentInfoInput;
};
export type ShipmentInfoCardEditMutationResponse = {
    readonly infoUpdateShipmentInfo: {
        readonly clientMutationId: string | null;
        readonly shipment: {
            readonly " $fragmentRefs": FragmentRefs<"ShipmentInfoCard_shipment">;
        };
    } | null;
};
export type ShipmentInfoCardEditMutation = {
    readonly response: ShipmentInfoCardEditMutationResponse;
    readonly variables: ShipmentInfoCardEditMutationVariables;
};



/*
mutation ShipmentInfoCardEditMutation(
  $input: infoUpdateShipmentInfoInput!
) {
  infoUpdateShipmentInfo(input: $input) {
    clientMutationId
    shipment {
      ...ShipmentInfoCard_shipment
      id
    }
  }
}

fragment ShipmentInfoCard_shipment on Shipment {
  slug
  stage
  shipmentValueCurrency
  shipmentCostCurrency
  customsClearanceInfoText
  customerName
  customerAddress
  customerState
  customerPostcode
  customerPlace
  customerCountry
  customerPhone
  customerCompany
  customerFormat
  containerSlug
  requiredDocuments
  infoGroupedByStage {
    stageLabel
    stageValue
    fields {
      label
      name
      value
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientMutationId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ShipmentInfoCardEditMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "infoUpdateShipmentInfoPayload",
        "kind": "LinkedField",
        "name": "infoUpdateShipmentInfo",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Shipment",
            "kind": "LinkedField",
            "name": "shipment",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ShipmentInfoCard_shipment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ApplicationCommand",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ShipmentInfoCardEditMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "infoUpdateShipmentInfoPayload",
        "kind": "LinkedField",
        "name": "infoUpdateShipmentInfo",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Shipment",
            "kind": "LinkedField",
            "name": "shipment",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "slug",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "stage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "shipmentValueCurrency",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "shipmentCostCurrency",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "customsClearanceInfoText",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "customerName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "customerAddress",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "customerState",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "customerPostcode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "customerPlace",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "customerCountry",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "customerPhone",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "customerCompany",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "customerFormat",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "containerSlug",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "requiredDocuments",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "InfoGroup",
                "kind": "LinkedField",
                "name": "infoGroupedByStage",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "stageLabel",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "stageValue",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "InfoField",
                    "kind": "LinkedField",
                    "name": "fields",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "label",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "value",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "964f526b1ae4afafdb19250eed57338c",
    "id": null,
    "metadata": {},
    "name": "ShipmentInfoCardEditMutation",
    "operationKind": "mutation",
    "text": "mutation ShipmentInfoCardEditMutation(\n  $input: infoUpdateShipmentInfoInput!\n) {\n  infoUpdateShipmentInfo(input: $input) {\n    clientMutationId\n    shipment {\n      ...ShipmentInfoCard_shipment\n      id\n    }\n  }\n}\n\nfragment ShipmentInfoCard_shipment on Shipment {\n  slug\n  stage\n  shipmentValueCurrency\n  shipmentCostCurrency\n  customsClearanceInfoText\n  customerName\n  customerAddress\n  customerState\n  customerPostcode\n  customerPlace\n  customerCountry\n  customerPhone\n  customerCompany\n  customerFormat\n  containerSlug\n  requiredDocuments\n  infoGroupedByStage {\n    stageLabel\n    stageValue\n    fields {\n      label\n      name\n      value\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '364279a8838d750eb574a69326526718';
export default node;
