import get from 'lodash/get'
import React from 'react'

import styled from 'styled-components'
import withInputRenderer, { Icon } from './RendererHoc'
import { RendererOptions } from '../DataTable'
import TrueIcon from '../../assets/public/v2/true.svg'
import FalseIcon from '../../assets/public/v2/false.svg'
import { Typography } from '@material-ui/core'

const Container = styled('div')`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
`

const InnterContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
`

const StyledText = styled(Typography)`
  font-size: inherit;
  color: ${(props) => props.theme.palette.text.primary};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export function BooleanRenderer<T extends any>({ identifier, rowData, value, icon }: RendererOptions<T>) {
  const data = value || get(rowData, identifier)

  const checked = Boolean(data)
  const booleanIcon = checked ? TrueIcon : FalseIcon
  const label = checked ? 'Yes' : 'No'

  return (
    <Container>
      {icon && <Icon src={icon} />}
      <InnterContainer>
        <StyledText>{label}</StyledText>
        <Icon src={booleanIcon} />
      </InnterContainer>
    </Container>
  )
}

BooleanRenderer.operators = ['eq', 'neq']

export default withInputRenderer({ type: 'checkbox' })(BooleanRenderer)
