import React from 'react'
import { ContactShowWithParams } from '../pages/Contact/ContactShow'
import { ContainerShowWithParams } from '../pages/ExternalContainer/ContainerShow'
import { ShipmentShowWithParams } from '../pages/ExternalShipment/ShipmentShow'
import styled from 'styled-components'
import { Button, IconButton } from '@material-ui/core'
import { OpenInNew, Link, Close } from '@material-ui/icons'
import useWorkspace from '../hooks/useWorkspace'
import { useSnackbar } from 'notistack'
import { useBlocker } from 'react-router-dom'
import { createPath } from 'history'
import { VesselShowWithParams } from '../pages/Vessel/VesselShow'
import { VoyageShowWithParams } from '../pages/Voyage/VoyageShow'
import { SlideInDrawer } from './SlideInDrawer'

export type ExternalShowPageInDrawer_EntityType = 'voyage' | 'vessel' | 'container' | 'contact' | 'shipment' | '%future added value'

interface Props {
  entityType: ExternalShowPageInDrawer_EntityType
  entitySlug: string
  open: boolean
  onClose: () => void
}

const ActionBar = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: ${(props) => (props.theme.palette.type === 'light' ? props.theme.palette.grey[300] : props.theme.palette.grey[700])};
  position: sticky;
  top: 0;
  z-index: 9;
`

const StyledButton = styled(Button)`
  color: ${(props) => (props.theme.palette.type === 'light' ? props.theme.palette.grey[700] : props.theme.palette.grey[300])};
  padding: 4px 8px;
`

const StyledIconButton = styled(IconButton)`
  color: ${(props) => (props.theme.palette.type === 'light' ? props.theme.palette.grey[700] : props.theme.palette.grey[300])};
  border-radius: 0px;
  padding-left: 8px;
  padding-right: 8px;
`

const ExternalShowPageInDrawer: React.FC<Props> = ({ entityType, entitySlug, open, onClose }) => {
  const entityShowPage = () => {
    switch (entityType) {
      case 'voyage':
        return <VoyageShowWithParams slug={entitySlug} tab='home' withSkeleton />
      case 'vessel':
        return <VesselShowWithParams slug={entitySlug} tab='home' withSkeleton />
      case 'container':
        return <ContainerShowWithParams slug={entitySlug} tab='home' withSkeleton />
      case 'contact':
        return <ContactShowWithParams slug={entitySlug} tab='home' withSkeleton />
      case 'shipment':
        return <ShipmentShowWithParams slug={entitySlug} tab='home' withSkeleton />
      default:
        return <></>
    }
  }
  const [workspace] = useWorkspace()
  const url = `/${workspace}/${entityType}/${entitySlug}`
  const { enqueueSnackbar } = useSnackbar()
  const copyUrl = () => {
    navigator.clipboard.writeText(`${window.location.origin}${url}`)
    enqueueSnackbar('Link copied to clipboard')
  }

  // When drawer renders, capture all navigation attempts and open them in new tab
  useBlocker((tx) => {
    window.open(createPath(tx.location))
  }, open)

  return (
    <SlideInDrawer size='default' anchor='right' open={open} elevation={0} onClose={onClose}>
      <ActionBar>
        <StyledIconButton size='small' onClick={onClose}>
          <Close fontSize='small' />
        </StyledIconButton>
        <div>
          <StyledButton startIcon={<Link />} size='small' onClick={copyUrl}>
            Copy Link
          </StyledButton>
          <StyledButton startIcon={<OpenInNew />} size='small' onClick={() => window.open(url)}>
            Open in new
          </StyledButton>
        </div>
      </ActionBar>
      {open && entityShowPage()}
    </SlideInDrawer>
  )
}

export default ExternalShowPageInDrawer
