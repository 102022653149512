/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type BodyField_data = {
    readonly label: string | null;
    readonly value: ReadonlyArray<{
        readonly key: string;
        readonly " $fragmentRefs": FragmentRefs<"SemanticString_data">;
    }> | null;
    readonly " $refType": "BodyField_data";
};
export type BodyField_data$data = BodyField_data;
export type BodyField_data$key = {
    readonly " $data"?: BodyField_data$data;
    readonly " $fragmentRefs": FragmentRefs<"BodyField_data">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BodyField_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SemanticString",
      "kind": "LinkedField",
      "name": "value",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "key",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SemanticString_data"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BodyField",
  "abstractKey": null
};
(node as any).hash = '4c90f476c1c0828600f9c8242a365014';
export default node;
