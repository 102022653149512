import Button from './Button'
import React, { useState } from 'react'
import useNiceMutation from '../mutations/useNiceMutation'
import { graphql } from 'babel-plugin-relay/macro'
import { RevokeAccessMutation } from '../__generated__/RevokeAccessMutation.graphql'
import { useSnackbar } from 'notistack'
import LoadingDots from './LoadingDots'
import ConfirmBox from './ConfirmBox'

interface Props {
  username: string
  revokeAccess: boolean
}

const RevokeAccess: React.FC<Props> = ({ username, revokeAccess }) => {
  const [commit_pass, loading] = useNiceMutation<RevokeAccessMutation>(revoke_access)
  const { enqueueSnackbar } = useSnackbar()
  const text = revokeAccess ? 'Give Access' : 'Revoke Access'
  const [openConfirm, setOpenConfirm] = useState<boolean>(false)
  const title = `Are you sure you want to ${text}?`
  const onConfirm = () => {
    commit_pass({
      variables: { input: { username: username, revokeAccess: !revokeAccess } },
      onCompleted: (_, err) => {
        if (!err) {
          enqueueSnackbar(`${text} action performed successfully`, { variant: 'success' })
        }
      },
    })
  }
  return (
    <>
      {openConfirm && <ConfirmBox open={openConfirm} onClose={() => setOpenConfirm(false)} title={title} onConfirm={onConfirm} />}
      <Button variant='contained' onClick={() => setOpenConfirm(true)}>
        {loading ? <LoadingDots /> : text}
      </Button>
    </>
  )
}

const revoke_access = graphql`
  mutation RevokeAccessMutation($input: infoRevokeAccessToggleInput!) {
    infoRevokeAccessToggle(input: $input) {
      clientMutationId
    }
  }
`
export default RevokeAccess
