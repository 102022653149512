/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type infoGenerateAtcInput = {
    clientMutationId?: string | null;
    entitySlug: string;
};
export type GeneratableAtcItemMutationVariables = {
    input: infoGenerateAtcInput;
};
export type GeneratableAtcItemMutationResponse = {
    readonly infoGenerateAtc: {
        readonly clientMutationId: string | null;
        readonly result: string;
    } | null;
};
export type GeneratableAtcItemMutation = {
    readonly response: GeneratableAtcItemMutationResponse;
    readonly variables: GeneratableAtcItemMutationVariables;
};



/*
mutation GeneratableAtcItemMutation(
  $input: infoGenerateAtcInput!
) {
  infoGenerateAtc(input: $input) {
    clientMutationId
    result
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "infoGenerateAtcPayload",
    "kind": "LinkedField",
    "name": "infoGenerateAtc",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "result",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GeneratableAtcItemMutation",
    "selections": (v1/*: any*/),
    "type": "ApplicationCommand",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GeneratableAtcItemMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b33a5a093e16a25bfa78d2eed09b1313",
    "id": null,
    "metadata": {},
    "name": "GeneratableAtcItemMutation",
    "operationKind": "mutation",
    "text": "mutation GeneratableAtcItemMutation(\n  $input: infoGenerateAtcInput!\n) {\n  infoGenerateAtc(input: $input) {\n    clientMutationId\n    result\n  }\n}\n"
  }
};
})();
(node as any).hash = '6d2a03fe553a9302d00086bc08be55e1';
export default node;
