import React, { useState, useMemo, ReactElement, useCallback, useContext } from 'react'
import styled from 'styled-components'
import DecoratedTextRenderer, { TextRenderer } from './Renderers/TextRenderer'
import { CSSProperties } from 'styled-components'
import {
  Box,
  Typography,
  LinearProgress,
  Checkbox,
  Divider,
  lighten,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  CircularProgress,
} from '@material-ui/core'
import { media } from '../theme'
import {
  ViewModule,
  Tune,
  Close,
  Add,
  ArrowDownward,
  ArrowUpward,
  SearchRounded,
  Archive,
  Unarchive,
  Edit,
  GetApp,
  InfoOutlined,
} from '@material-ui/icons'
import { Table, Tbody, Td, Th, Thead, Tr } from './Table'
import { ToggleButton, ToggleButtonGroup, Alert } from '@material-ui/lab'
import Button from './Button'
import Dropdown, { Search, SearchIconWrapper, StyledInputBase, StyledPopover } from './Dropdown'
import TablePagination from '@material-ui/core/TablePagination'
import WorkspacedLink from './WorkspacedLink'
import pDebounce from 'p-debounce'
import EqualIcon from '../assets/public/v2/eq.svg'
import NotEqualIcon from '../assets/public/v2/neq.svg'
import GreaterThanIcon from '../assets/public/v2/gt.svg'
import GreaterThanOrEqualIcon from '../assets/public/v2/gte.svg'
import LessThanIcon from '../assets/public/v2/lt.svg'
import LessThanOrEqualIcon from '../assets/public/v2/lte.svg'
import BetweenIcon from '../assets/public/v2/between.svg'
import ContainIcon from '../assets/public/v2/contain.svg'
import NullIcon from '../assets/public/v2/null.svg'
import NotNullIcon from '../assets/public/v2/not_null.svg'
import { OPERTOR_DEFAULT_VALUE_MAP, RendererRoot } from './Renderers/RendererHoc'
import { get, set } from 'lodash'
import { isMobile } from '../theme'
import { useSnackbar } from 'notistack'
import SessionContext from '../SessionContext'

type OptionType = { label: string; value: string; icon?: string; disabled?: boolean }
interface Action<P extends any> {
  name: string
  icon: string
  onClick: (record: P) => void
}

interface RendererOptions<T = any> {
  isEditing?: boolean
  editable?: boolean
  identifier: string
  name: string
  isHovered?: boolean
  rowData: T
  options?: OptionType[]
  value?: string | number
  onSubmit?: (record: T) => void
  anchorEl: HTMLButtonElement | null
  selection?: (number | string)[]
  style?: CSSProperties
  to?: string
  icon?: string
  range?: boolean
  metaKey?: string
  type?: EntityType
}

interface Column<T = any> {
  name: string
  icon?: string
  identifier: string
  hidden?: boolean
  editable?: boolean
  sortable?: boolean
  filterable?: boolean
  meta?: string
  style?: CSSProperties
  onSubmit?: (record: T) => void
  renderer?: {
    (options: RendererOptions<T>): ReactElement
    operators?: string[]
  }
}

interface IFilter {
  column: string
  operator: /* OperatorEnum */ any
  value: string
}

interface IOrder {
  column: string
  direction: 'asc' | 'desc'
}

type EntityType = 'container' | 'shipment'

interface Props<P extends any> {
  type: EntityType
  actions: Action<P>[]
  columns: Column<P>[]
  data: P[]
  loading?: boolean
  view?: string
  viewOptions?: OptionType[]
  onViewChange?: (view: string) => void
  onRowClick?: (record: P, e: React.FormEvent<any>) => void
  onSubmit?: (record: P) => void
  filters: IFilter[]
  setFilters: React.Dispatch<React.SetStateAction<IFilter[]>>
  search: string
  setSearch: React.Dispatch<React.SetStateAction<string>>
  includeArchived: boolean
  setIncludeArchived: React.Dispatch<React.SetStateAction<boolean>>
  order: IOrder[]
  setOrder: React.Dispatch<React.SetStateAction<IOrder[]>>
  handlePageChange: (nextPage: number) => void
  handlePageSizeChange: (nextPage: number) => void
  page: number
  pageSize: number
  count?: number
  onDownload?: () => void
  onBulkUpdate?: (records: P[]) => void
  bulkEditFields?: Record<string, string[]>
}

const Flex = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
`

const Toolbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;
  padding: 8px 24px;
  color: ${(props) => props.theme.palette.text.primary};
  border-bottom: ${(props) => `1px solid ${props.theme.palette.divider}`};
`

const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  width: 100%;

  ${media.small`
    .MuiButtonBase-root {
      width: 100%;
    }
  `}

  .Mui-selected {
    color: ${(props) => props.theme.palette.primary.main};
    background-color: ${(props) => lighten(props.theme.palette.primary.light, 0.9)};
    &:hover {
      background-color: ${(props) => lighten(props.theme.palette.primary.light, 0.8)};
    }
  }
  height: 32px;
  border-radius: 4px;
  & > button {
    font-size: 11px;
    padding: 4px 8px;
  }
`

export const IconButton = styled('button')<{ size?: string; variant?: 'ghost' | 'contained' }>`
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};

  text-transform: ${(props) => props.theme.palette.action.disabled};
  border-radius: 5px;

  ${({ size = 'small' }) =>
    size === 'small'
      ? `
    width: 20px;
    height: 20px;
  `
      : `
    width: 36px;
    height: 36px;
  `}
  ${({ variant = 'ghost', theme }) => {
    if (variant === 'ghost')
      return `
    background-color: transparent;
    color: ${theme.palette.text.secondary};
    &:hover {
      background-color: ${theme.palette.action.hover};
    }
    `
    if (variant === 'contained')
      return `
    background-color: ${theme.palette.primary.main};
    color: ${theme.palette.primary.contrastText};
    &:hover {
      background-color: ${theme.palette.primary.dark};
    }
    `
  }}
`

const FilterButton = styled(IconButton)`
  font-size: 11px;
`

const Filterbar = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 24px;
  border-bottom: ${(props) => `1px solid ${props.theme.palette.divider}`};
`

const StyledText = styled(Typography)`
  font-size: inherit;
  color: ${(props) => props.theme.palette.text.primary};

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const HeaderFlex = styled(Flex)`
  gap: 4px;

  &span {
    paddingleft: 8px;
  }

  [data-hoverable] {
    opacity: 0;
  }

  .MuiSvgIcon-root {
    transition: 0.15s ease;
    font-size: 14px;
    margin-left: auto;
  }

  :hover {
    [data-hoverable] {
      opacity: 0.5;
    }
  }
`

const THead = styled(Th)`
  & + & {
    ${HeaderFlex} {
      ::before {
        content: '';
        height: 16px;
        display: inline-block;
        border-left: ${(props) => `1px solid ${props.theme.palette.divider}`};
        margin: -4px;
        padding-right: 12px;
      }
    }
  }
`
const FilterObject = styled(Box)`
  display: flex;
  align-items: center;
  border: ${(props) => `1px solid ${props.theme.palette.divider}`};
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  background-color: ${(props) => props.theme.palette.action.hover};
`

function FilterDropdown({ options, label, value, onValueChange, defaultOpen = false }: any) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [search, setSearch] = React.useState('')
  const open = Boolean(anchorEl)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleSelect = (value: OptionType) => {
    onValueChange(value)
    handleClose()
  }

  return (
    <>
      <RendererRoot
        role='button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        ref={defaultOpen ? setAnchorEl : undefined}
      >
        <StyledText title={value?.label}>{value?.label || label}</StyledText>
      </RendererRoot>
      <Dropdown
        search={search}
        onSearch={setSearch}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        options={options}
        // onChange={handleOnChange}
        value={value?.value}
        onSelect={handleSelect}
      />
    </>
  )
}

const OPERATOR_LABEL_MAP: Record<string, string> = {
  eq: 'Equal',
  neq: 'Not Equal',
  gt: 'Greater Than',
  gteq: 'Greater Than or Equal',
  lt: 'Less Than',
  lteq: 'Less Than or Equal',
  in: 'In',
  not_in: 'Not In',
  contains: 'Contains',
  is_null: 'Is Null',
  not_null: 'Not Null',
  between: 'Between',
}

const OPERATOR_ICON_MAP: Record<string, string> = {
  eq: EqualIcon,
  neq: NotEqualIcon,
  gt: GreaterThanIcon,
  gteq: GreaterThanOrEqualIcon,
  lt: LessThanIcon,
  lteq: LessThanOrEqualIcon,
  in: ContainIcon,
  not_in: ContainIcon,
  contains: ContainIcon,
  is_null: NullIcon,
  not_null: NotNullIcon,
  between: BetweenIcon,
}

interface FilterProps {
  anchorEl: HTMLButtonElement | null
  type: EntityType
  condensed?: boolean
  filterableColumns: Column[]
  filterableColumnOptions: OptionType[]
  setFilters: React.Dispatch<React.SetStateAction<IFilter[]>>
  onClose: () => void
  defaultFilter?: Partial<IFilter>
}

const AddFilter = ({
  anchorEl,
  type,
  condensed = false,
  defaultFilter,
  filterableColumns,
  filterableColumnOptions,
  setFilters,
  onClose,
}: FilterProps) => {
  const [filter, setFilter] = useState<Partial<IFilter>>(
    defaultFilter || { column: filterableColumnOptions[0].value, operator: 'eq', value: '' }
  )

  const selectedColumn = filterableColumns.find((col) => col.identifier === filter.column)
  const isAssociation = selectedColumn && selectedColumn?.identifier.split('.')?.length > 1
  const Renderer = selectedColumn?.renderer || TextRenderer
  const operatorOptions = (Renderer.operators || TextRenderer.operators)?.map((o: string) => ({
    label: OPERATOR_LABEL_MAP[o],
    value: o,
    icon: OPERATOR_ICON_MAP[o],
  }))
  const columnValue = filterableColumnOptions.find((o) => o.value === filter.column)
  const operatorValue = operatorOptions?.find((o) => o.value === filter.operator)
  const filterWithValue = !['is_null', 'not_null'].includes(operatorValue?.value || '')

  const rowData = {}
  set(
    rowData,
    isAssociation ? `${selectedColumn?.identifier.split('.')[0]}.type` : 'type',
    isAssociation ? selectedColumn?.identifier.split('.')[0] : type
  )
  set(rowData, selectedColumn?.identifier || '', filter.value)

  return (
    <Box width={condensed ? '100px' : '300px'} display='flex' alignItems='center' flexGrow={1}>
      {!condensed && (
        <FilterDropdown
          label='Column'
          options={filterableColumnOptions}
          value={columnValue}
          onValueChange={({ value }: any) => {
            setFilter({ ...filter, column: value })
          }}
          defaultOpen={!columnValue}
        />
      )}
      {!condensed && <Divider orientation='vertical' flexItem style={{ margin: '4px 2px' }} />}
      {filter.column && !condensed && (
        <FilterDropdown
          label='Operator'
          options={operatorOptions}
          value={operatorValue}
          onValueChange={({ value }: any) => {
            filter.operator = value
            if (filterWithValue) {
              const newFilter = { ...filter, operator: value } as IFilter
              setFilter(newFilter)
              setFilters((f) => f.concat([newFilter]))
              onClose()
            } else {
              setFilter({ ...filter, operator: value })
            }
          }}
          defaultOpen={!operatorValue}
        />
      )}
      {filterWithValue && (
        <>
          {filter.column && !condensed && <Divider orientation='vertical' flexItem style={{ margin: '4px 2px' }} />}
          {selectedColumn && filter.column && filter.operator && (
            <Renderer
              anchorEl={anchorEl}
              identifier={filter.column}
              name={selectedColumn.name}
              metaKey={selectedColumn.meta}
              rowData={rowData}
              editable
              value={filter.value}
              onSubmit={(value: any) => {
                const filterValueType = Array.isArray(OPERTOR_DEFAULT_VALUE_MAP[filter.operator]) ? 'array' : typeof filter.operator
                const key = `${selectedColumn?.identifier.split('.')[0]}`.replace('Slug', '')
                let filterValue =
                  filterValueType === 'array'
                    ? filter.value?.concat(get(value, selectedColumn?.identifier) || get(value, `${key}Slug`))
                    : get(value, selectedColumn?.identifier) || get(value, `${key}Slug`)

                if (value.linkedContacts) filterValue = value.linkedContacts?.[0].contactSlug
                if (!filter.column || !filterValue) return

                const newFilter = {
                  ...filter,
                  column: filter.column,
                  value: filterValue,
                } as IFilter
                setFilter(newFilter)
                setFilters((f) => f.concat([newFilter]))
                onClose()
              }}
            />
          )}
        </>
      )}
    </Box>
  )
}

const BulkActionsBar = styled.div`
  position: fixed;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%) translateY(calc(100% + 16px));
  transition: 0.15s ease;
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.palette.action.disabled};
  box-shadow: ${(props) => props.theme.shadows[4]};
  z-index: 99;
  background: ${(props) => props.theme.palette.background.paper};

  &[data-active='true'] {
    transform: translateX(-50%) translateY(0%);
  }
`

const BulkAction = ({ onAction, selected, label, endIcon }: any) => {
  const [loading, setLoading] = useState(false)

  const handleBulkAction = async () => {
    setLoading(true)
    try {
      await onAction(selected)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Button disabled={loading} onClick={handleBulkAction} endIcon={endIcon}>
      {loading ? 'Loading...' : label}
    </Button>
  )
}

const BulkActionDialog = ({ open, view, data, selected, handleClose, columns, bulkEditFields, onSubmit, type }: any) => {
  const [state, setState] = useState<any>({})

  const [loading, setLoading] = useState(false)

  const isDisabled = Object.keys(state).length === 0

  const handleSubmit = async () => {
    setLoading(true)

    try {
      await onSubmit?.(
        selected.map((index: number) => {
          const rowData = data[index]
          return {
            slug: rowData.slug,
            ...state,
            linkedContacts: rowData.linkedContacts
              ?.map((c: any) => ({
                ...c,
                contactSlug: state.linkedContacts?.find((lc: any) => lc.role === c.role)?.contactSlug || c.contactSlug,
              }))
              .concat(state.linkedContacts?.filter((lc: any) => !rowData.linkedContacts?.some((c: any) => c.role === lc.role)) || []),
          }
        })
      )
      handleClose()
    } finally {
      setLoading(false)
      setState({})
    }
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{'Update Fields'}</DialogTitle>
      <DialogContent>
        <Box display='flex' flexDirection='column'>
          <Alert variant='standard' color='info' icon={<InfoOutlined />}>
            Modify one or more fields you would like to update.
          </Alert>
          <Grid container spacing={2} style={{ maxWidth: 500 }}>
            {bulkEditFields?.[view]?.map((identifier: string) => {
              const column = columns.find((col: any) => col.identifier === identifier)
              const Renderer = column?.renderer || DecoratedTextRenderer

              return (
                <Grid key={identifier} item xs={6}>
                  <Box display='flex' flexDirection='column' style={{ gap: 4 }}>
                    <Typography variant='caption'>{column.name}</Typography>
                    <Renderer
                      identifier={identifier}
                      rowData={{}}
                      type={type}
                      isEditing
                      editable
                      onSubmit={(rowData: any) => {
                        const [key, ...parts] = identifier.split('.')
                        const isRootAttribute = parts.length <= 0

                        setState((item: any) => {
                          if (isRootAttribute) {
                            return { ...item, [identifier]: rowData[identifier] }
                          }

                          if (['warehouse', 'transporter', 'payer', 'consignee', 'customer', 'shipper'].includes(key)) {
                            const contactSlug = rowData?.linkedContacts?.find((c: any) => c.role === key)?.contactSlug
                            return { ...item, linkedContacts: [...(rowData?.linkedContacts || []), { role: key, contactSlug }] }
                          }

                          const value = rowData[`${key}Slug`]
                          return { ...item, [`${key}Slug`]: value }
                        })
                      }}
                    />
                  </Box>
                </Grid>
              )
            })}
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={loading}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} color='primary' disabled={loading || isDisabled}>
          {loading ? (
            <Flex style={{ gap: 8 }}>
              <CircularProgress size={14} /> Submitting...
            </Flex>
          ) : (
            'Submit'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export const StyledIconButton = styled(IconButton)<{ $active?: boolean }>`
  background-color: ${(props) => (props.$active ? lighten(props.theme.palette.warning.main, 0.9) : undefined)};
  &:hover {
    background-color: ${(props) => (props.$active ? lighten(props.theme.palette.warning.main, 0.8) : undefined)};
  }
  color: ${(props) => (props.$active ? props.theme.palette.warning.main : undefined)};
`

const TableRow = React.memo(({ data, index, onSubmit, onRowClick, isSelected, setSelected, columns, actions }: any) => {
  const getCellData = (col: Column) => {
    const { renderer, hidden = false, editable, meta, ...rest } = col

    const Renderer = renderer || DecoratedTextRenderer

    const rendererOptions: RendererOptions = {
      rowData: data,
      editable: data.isArchived ? false : editable,
      onSubmit: (record) => (col?.onSubmit ? col.onSubmit(record) : onSubmit?.({ ...data[index], [col.identifier]: record[col.identifier] })),
      // value: data[col.identifier],
      metaKey: meta,
      anchorEl: null,
      ...rest,
    }

    return (
      <Td
        key={`${col.identifier}-${index}`}
        $selected={isSelected}
        style={{ minWidth: 100, maxWidth: 200, ...col.style, display: hidden ? 'none' : 'table-cell' }}
      >
        <Renderer {...rendererOptions} />
      </Td>
    )
  }

  return (
    <Tr onClick={(e) => onRowClick?.(data, e)}>
      <Td $selected={isSelected} $disabled={data.isArchived} style={{ maxWidth: 40, width: 40 }}>
        <Checkbox
          size='small'
          checked={isSelected}
          onChange={() => {
            setSelected((curr: number[]) => (isSelected ? curr.filter((i: number) => i !== index) : curr.concat([index])))
          }}
        />
      </Td>
      {columns.map((col: Column) => getCellData(col))}
      {actions.length > 0 && (
        <Td $selected={isSelected} key={`actions-${index}`}>
          <Flex>
            {actions.map((act: any) => (
              <IconButton key={act.name} color='default' onClick={() => act.onClick(data)}>
                <ViewModule />
              </IconButton>
            ))}
          </Flex>
        </Td>
      )}
    </Tr>
  )
})

function DataTable<P extends Record<string, any>>({
  type,
  actions = [],
  columns = [],
  data = [],
  bulkEditFields,
  onRowClick,
  onSubmit,
  view,
  onViewChange,
  viewOptions = [],
  filters,
  setFilters,
  search,
  setSearch,
  includeArchived,
  setIncludeArchived,
  loading,
  order,
  setOrder,
  handlePageChange,
  handlePageSizeChange,
  page,
  pageSize,
  count,
  onDownload,
  onBulkUpdate,
}: Props<P>) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [columnAnchorEl, setColumnAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [condensed, setCondensed] = useState(false)
  const [columnFilter, setColumnFilter] = useState<Partial<IFilter>>()
  const { enqueueSnackbar } = useSnackbar()
  const { user } = useContext(SessionContext)
  const visibleColumns = useMemo(() => columns.filter((col) => !col.hidden), [columns])
  const filterableColumns = useMemo(() => visibleColumns.filter((col) => col.filterable), [columns])
  const filterableColumnOptions = useMemo(
    () => filterableColumns.map((col) => ({ value: col.identifier, label: col.name, icon: col?.icon })),
    [filterableColumns]
  )
  const [selected, setSelected] = useState<number[]>([])

  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    handlePageChange(newPage + 1)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    handlePageSizeChange(parseInt(event.target.value, 10))
  }

  const doSearch = useCallback((value) => pDebounce(setSearch, 300)(value), [setSearch])

  const onClose = () => {
    setAnchorEl(null)
    setColumnAnchorEl(null)
  }

  return (
    <Box style={{ position: 'relative' }}>
      <Toolbar>
        <Flex flexGrow={1}>
          {viewOptions?.length > 0 && (
            <StyledToggleButtonGroup
              value={view}
              exclusive
              size='small'
              onChange={(_, value) => value && onViewChange?.(value)}
              aria-label='view'
            >
              {viewOptions.map((option) => (
                <ToggleButton key={option.value} value={option.value} aria-label={option.label}>
                  {option.label}
                </ToggleButton>
              ))}
            </StyledToggleButtonGroup>
          )}
        </Flex>
        <Flex>
          {!filters.length ? (
            <Tooltip title='Filters' interactive>
              <FilterButton
                size='normal'
                onClick={(e) => {
                  setCondensed(false)
                  setAnchorEl(e.currentTarget)
                }}
              >
                <Tune fontSize='small' />
              </FilterButton>
            </Tooltip>
          ) : (
            <>
              {isMobile() ? (
                <Tooltip title='Clear filters' interactive>
                  <StyledIconButton size='normal' onClick={() => setFilters([])}>
                    <Close style={{ color: 'inherit' }} fontSize='small' />
                  </StyledIconButton>
                </Tooltip>
              ) : (
                <Button
                  style={{ flexShrink: 0 }}
                  variant='outlined'
                  size='small'
                  endIcon={<Close fontSize='small' />}
                  onClick={() => setFilters([])}
                >
                  Clear filters
                </Button>
              )}
            </>
          )}
          <StyledPopover
            open={!!anchorEl}
            fullWidth={!condensed}
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={onClose}
          >
            <AddFilter
              anchorEl={columnAnchorEl}
              type={type}
              condensed={condensed}
              defaultFilter={columnFilter}
              onClose={onClose}
              filterableColumnOptions={filterableColumnOptions}
              filterableColumns={filterableColumns}
              setFilters={setFilters}
            />
          </StyledPopover>
          <Tooltip title='Download All' interactive>
            <StyledIconButton
              size='normal'
              onClick={() => {
                count && count > 0 && count < 500
                  ? onDownload?.()
                  : enqueueSnackbar('Error: Cannot download more than 500 rows. Apply filter and try again', { variant: 'error' })
              }}
            >
              <GetApp style={{ color: 'inherit' }} fontSize='small' />
            </StyledIconButton>
          </Tooltip>
          <Tooltip title={includeArchived ? 'Hide Archived' : 'Show Archived'} interactive>
            <StyledIconButton $active={includeArchived} size='normal' onClick={() => setIncludeArchived(!includeArchived)}>
              {!includeArchived ? <Archive style={{ color: 'inherit' }} fontSize='small' /> : <Unarchive fontSize='small' />}
            </StyledIconButton>
          </Tooltip>
          <Divider flexItem variant='fullWidth' component='hr' orientation='vertical' />
          <Search>
            <SearchIconWrapper>
              <SearchRounded fontSize='small' />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder='Search…'
              inputProps={{ 'aria-label': 'search' }}
              defaultValue={search}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => doSearch(e.target.value)}
            />
          </Search>
          {user?.contact?.contactType === 'internal' && (
            <WorkspacedLink style={{ flexShrink: 0 }} to={`/${type}s/add/`}>
              {isMobile() ? (
                <Tooltip title={`New ${type === 'container' ? 'Container' : 'Shipment'}`} interactive>
                  <StyledIconButton size='normal' variant='contained'>
                    <Add fontSize='small' />
                  </StyledIconButton>
                </Tooltip>
              ) : (
                <Button style={{ borderRadius: 4 }} size='small' variant='contained' startIcon={<Add fontSize='small' />}>
                  New {type === 'container' ? 'Container' : 'Shipment'}
                </Button>
              )}
            </WorkspacedLink>
          )}
        </Flex>
      </Toolbar>
      {filters.length > 0 && (
        <Filterbar>
          {filters.map((filter: IFilter, index: number) => {
            const selectedColumn = filterableColumns.find((col) => col.identifier === filter.column)
            const Renderer = selectedColumn?.renderer || TextRenderer
            const operatorOptions = (Renderer.operators || TextRenderer.operators)?.map((o) => ({
              label: OPERATOR_LABEL_MAP[o],
              value: o,
              icon: OPERATOR_ICON_MAP[o],
            }))
            const operatorValue = operatorOptions?.find((o) => o.value === filter.operator)
            const filterWithValue = !['is_null', 'not_null'].includes(operatorValue?.value || 'eq')

            return (
              <FilterObject key={filter.column}>
                <FilterDropdown
                  label='Column'
                  options={filterableColumnOptions}
                  value={filterableColumnOptions.find((o) => o.value === filter.column)}
                  onValueChange={({ value }: any) => {
                    setFilters((f) => [...f.slice(0, index), { ...f[index], column: value }, ...f.slice(index + 1)])
                  }}
                />
                <Divider orientation='vertical' flexItem />
                {filter.column && (
                  <FilterDropdown
                    label='Operator'
                    options={operatorOptions}
                    value={operatorOptions.find((o) => o.value === filter.operator)}
                    onValueChange={({ value }: any) => {
                      setFilters((f) => [...f.slice(0, index), { ...f[index], operator: value }, ...f.slice(index + 1)])
                    }}
                  />
                )}
                {filterWithValue && (
                  <>
                    <Divider orientation='vertical' flexItem />
                    {selectedColumn && filter.column && filter.operator && (
                      <Renderer
                        metaKey={selectedColumn.meta}
                        anchorEl={null}
                        name={selectedColumn.name}
                        identifier={filter.column}
                        rowData={
                          {
                            type: type,
                            identifier: selectedColumn.identifier,
                            [selectedColumn.identifier]: filter.value,
                            ...(selectedColumn.identifier.split('.').length > 1 && {
                              [selectedColumn.identifier.split('.')[0]]: { type: selectedColumn.identifier.split('.')[0] },
                            }),
                          } as any
                        }
                        value={filter.value}
                        editable
                        onSubmit={(value: any) => {
                          const filterValue = get(value, selectedColumn.identifier)
                          setFilters((f) => [...f.slice(0, index), { ...f[index], value: filterValue }, ...f.slice(index + 1)])
                        }}
                      />
                    )}
                  </>
                )}
                <Divider orientation='vertical' flexItem />
                <IconButton onClick={() => setFilters((f) => [...f.slice(0, index), ...f.slice(index + 1)])}>
                  <Close fontSize='small' />
                </IconButton>
              </FilterObject>
            )
          })}
          {filters.length > 0 && (
            <IconButton
              onClick={(e) => {
                setCondensed(false)
                setAnchorEl(e.currentTarget)
              }}
            >
              <Add fontSize='small' />
            </IconButton>
          )}
        </Filterbar>
      )}
      {loading && <LinearProgress />}
      <div style={{ overflow: 'auto', maxHeight: 'calc(100vh - 185px)' }}>
        <Table style={{ tableLayout: 'auto', width: '100%' }}>
          <Thead>
            <Tr>
              <Th style={{ maxWidth: 40, width: 40 }}>
                <Checkbox
                  size='small'
                  checked={selected.length === data.length}
                  indeterminate={!!selected.length && selected.length < data.length}
                  onChange={() => {
                    setSelected((curr) => {
                      if (!curr.length || curr.length < data.length) return [...data.keys()]
                      return []
                    })
                  }}
                />
              </Th>
              {columns.map(({ hidden = false, sortable = false, filterable, renderer, ...col }: Column) => {
                const orderingIndex = order.findIndex((o) => o.column === col.identifier)
                const ordering = order[orderingIndex]
                const hasFilter = filters.find((f) => f.column === col.identifier)
                const onFilter = (e: React.MouseEvent<HTMLButtonElement>) => {
                  const operator = renderer?.operators ? renderer.operators[0] : 'eq'
                  setCondensed(true)
                  setAnchorEl(e.currentTarget)
                  setColumnAnchorEl(e.currentTarget)
                  setColumnFilter({
                    column: col.identifier,
                    operator: operator,
                    value: hasFilter?.value || OPERTOR_DEFAULT_VALUE_MAP[operator],
                  })
                }

                const onOrder = () => {
                  setOrder((o) =>
                    !ordering
                      ? o.concat([{ column: col.identifier, direction: 'asc' }])
                      : ordering.direction === 'asc'
                      ? [...o.slice(0, orderingIndex), { column: col.identifier, direction: 'desc' }, ...o.slice(orderingIndex + 1)]
                      : [...o.slice(0, orderingIndex), ...o.slice(orderingIndex + 1)]
                  )
                }

                return (
                  <THead key={col.identifier} style={{ minWidth: 100, maxWidth: 200, ...col.style, display: hidden ? 'none' : 'table-cell' }}>
                    <HeaderFlex width='100%' justifyContent='space-between' alignItems='center'>
                      <Box display='flex' justifyContent='space-between' flexGrow={1} alignItems='center'>
                        <span>{col.name}</span>
                        {sortable && (
                          <IconButton onClick={onOrder}>
                            {!ordering && <ArrowUpward data-hoverable />}
                            {ordering?.direction === 'desc' && <ArrowDownward color='secondary' />}
                            {ordering?.direction === 'asc' && <ArrowUpward color='secondary' />}
                          </IconButton>
                        )}
                      </Box>
                      {filterable && (
                        <IconButton onClick={onFilter}>
                          <Tune fontSize='small' color={hasFilter ? 'secondary' : undefined} />
                        </IconButton>
                      )}
                    </HeaderFlex>
                  </THead>
                )
              })}
              {actions.length > 0 && <Td key='actions' />}
            </Tr>
          </Thead>
          <Tbody>
            {data.length === 0 && (
              <Tr>
                <Td colSpan={visibleColumns.length + 1}>No Records</Td>
              </Tr>
            )}
            {data.map((d: P, idx) => {
              const isSelected = selected.includes(idx)
              return <TableRow key={d.id} {...{ data: d, index: idx, onSubmit, onRowClick, isSelected, setSelected, columns, actions }} />
            })}
          </Tbody>
        </Table>
      </div>
      <TablePagination
        classes={{ spacer: 'flex-0' }}
        component={PaginationRoot}
        count={count || 0}
        page={page}
        onChangePage={handleChangePage}
        rowsPerPage={pageSize}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />

      <BulkActionsBar data-active={!!selected.length}>
        <BulkAction onAction={onDownload} selected={selected} label='Download Selected' endIcon={<GetApp />} />
        {user?.contact?.contactType === 'internal' && <BulkAction label='Edit Selected' onAction={handleClickOpen} endIcon={<Edit />} />}
      </BulkActionsBar>
      <BulkActionDialog {...{ type, open, view, data, selected, handleClose, columns, bulkEditFields }} onSubmit={onBulkUpdate} />
    </Box>
  )
}

const PaginationRoot = styled.div`
  .flex-0 {
    flex: 0;
    color: ${(props) => props.theme.palette.text.primary};
  }
`

export type { Action, Column, RendererOptions, IFilter, IOrder }

export default DataTable
