import styled from 'styled-components'

export const Caption = styled.caption`
  text-align: start;
  margin-bottom: 25px;
`

export const Tbody = styled.tbody`
  width: 100%;
  color: ${(props) => props.theme.palette.text.primary};
`

export const Tfoot = styled.tfoot``

export const Tr = styled.tr``

export const Th = styled.th`
  min-width: auto;
  width: auto;
  font-weight: unset;
  text-align: start;
  font-size: 12px;
  padding: 10px 4px;
  color: inherit;
  background-color: ${(props) => props.theme.palette.background.default};
  border-bottom: ${(props) => `1px solid ${props.theme.palette.divider}`};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const Td = styled.td<{ $selected?: boolean; $disabled?: boolean }>`
  position: relative;
  min-width: auto;
  width: auto;
  padding: 10px 4px;
  background-color: ${(props) => (props.$selected ? props.theme.customPalette.background.selectedRow : props.theme.palette.background.default)};
  border-bottom: ${(props) => `1px solid ${props.theme.palette.divider}`};
  font-size: 12px;
  text-align: start;
  color: inherit;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-left: ${(props) => (props.$disabled ? `4px solid ${props.theme.palette.warning.main}` : '4px solid transparent')};
`

export const Thead = styled.thead`
  position: sticky;
  top: 0;
  z-index: 20;
  width: 100%;
  color: ${(props) => props.theme.palette.text.secondary};
`

export const Table = styled.table`
  min-width: 100%;
  width: auto;
  table-layout: fixed;
  border-spacing: 0;
  border-collapse: separate;

  thead tr th:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
    padding-bottom: 1px;
    padding-top: 1px;
    padding-right: 1px;
  }

  thead tr th:nth-child(2) {
    position: sticky;
    left: 40px;
    z-index: 2;
    border-right: ${(props) => `2px solid ${props.theme.palette.divider}`};
  }

  tbody tr td:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
    padding-bottom: 1px;
    padding-top: 1px;
    padding-right: 1px;
  }

  tbody tr td:nth-child(2) {
    position: sticky;
    left: 40px;
    z-index: 2;
    border-right: ${(props) => `2px solid ${props.theme.palette.divider}`};
  }
`
