import React from 'react'
import { graphql } from 'babel-plugin-relay/macro'

import { useFragment } from 'react-relay/hooks'
import { Badge_data$key } from '../__generated__/Badge_data.graphql'
import staffBadge from '../assets/public/badges/staff.svg'
import styled from 'styled-components'
import {
  Star,
  DriveEtaOutlined,
  LocalMallOutlined,
  MotorcycleOutlined,
  LocalShippingOutlined,
  DirectionsBoatOutlined,
  CallMade,
  CallReceived,
} from '@material-ui/icons'
import RedeemOutlinedIcon from '@material-ui/icons/RedeemOutlined'
import { OverridableComponent } from '@material-ui/core/OverridableComponent'
import { SvgIconTypeMap, Box } from '@material-ui/core'

interface Props {
  data: Badge_data$key
}

const BadgeImage = styled.img`
  margin-right: 4px;
`
const BadgeAvatar = styled.div`
  height: 24px;
`

const TextBagde = styled(Box)`
  user-select: none;
  text-align: center;
  font-size: 12px;
  margin-right: 6px;
  font-weight: 600;
  color: ${(props) => props.theme.palette.secondary.main};
  padding: 4px 8px 2px;
  border: 1px solid ${(props) => props.theme.palette.secondary.main};
  background: #fff;
`

const Badge: React.FC<Props> = ({ data }) => {
  const badge = useFragment(badgeFragment, data)
  const icons: { [key: string]: OverridableComponent<SvgIconTypeMap<Record<string, unknown>, 'svg'>> } = {
    car: DriveEtaOutlined,
    boat: DirectionsBoatOutlined,
    motor: MotorcycleOutlined,
    oldtimer: DriveEtaOutlined,
    other_shipment: LocalMallOutlined,
    package: RedeemOutlinedIcon,
    quad: MotorcycleOutlined,
    truck: LocalShippingOutlined,
    outgoing: CallMade,
    incoming: CallReceived,
  }
  const titles: { [key: string]: string } = {
    other_shipment: 'other',
  }
  const Icon: OverridableComponent<SvgIconTypeMap<Record<string, unknown>, 'svg'>> = icons[badge.type] || Star
  switch (badge.type) {
    case 'staff':
      return <BadgeImage alt='Team Portagent' title='Team Portagent' src={staffBadge} />
    case 'foreign_container':
      return (
        <TextBagde title='Foreign container' fontFamily='Monospace'>
          F
        </TextBagde>
      )
    case 'roro_container':
      return (
        <TextBagde title='RoRo container' fontFamily='Monospace'>
          R
        </TextBagde>
      )
    case 'export_container':
      return (
        <TextBagde title='Export container' fontFamily='Monospace'>
          E
        </TextBagde>
      )
    default:
      return (
        <BadgeAvatar title={titles[badge.type] || badge.type}>
          <Icon color='secondary' />
        </BadgeAvatar>
      )
  }
}

const badgeFragment = graphql`
  fragment Badge_data on Badge {
    type
  }
`

export default Badge
