import React from 'react'
import { graphql } from 'babel-plugin-relay/macro'

import { useNavigate } from 'react-router'
import { useLazyLoadQuery } from 'react-relay/hooks'
import { VoyageAddFormQuery } from '../../__generated__/VoyageAddFormQuery.graphql'
import NiceFormik from '../../components/Form/NiceFormik'
import useNiceMutation from '../../mutations/useNiceMutation'
import { VoyageAddMutation } from '../../__generated__/VoyageAddMutation.graphql'
import useWorkspace from '../../hooks/useWorkspace'
import FormActionBar from '../../components/Form/FormActionBar'
import VoyageForm from '../../forms/Voyage/VoyageForm'
import FormContainer from '../../forms/FormContainer'

const VoyageAdd: React.FC & { Skeleton: React.FC } = () => {
  const [workspace] = useWorkspace()
  const navigate = useNavigate()
  const data = useLazyLoadQuery<VoyageAddFormQuery>(query, {})
  const fields = data.infoAddVoyageForm.formSchema.fieldSet
  const fieldMaster = Object.fromEntries(fields.map((field) => [field.name, field]))
  const gotoVoyage = (slug: string) => navigate(`/${workspace}/voyage/${slug}/home`)

  const [commit] = useNiceMutation<VoyageAddMutation>(mutation)

  return (
    <>
      <NiceFormik
        initialValues={{}}
        onSubmit={(values, actions) => {
          commit({
            variables: {
              input: values as any,
            },
            onError: () => actions.setSubmitting(false),
            onCompleted: (res, errors) => {
              actions.setSubmitting(false)
              if (!errors) {
                gotoVoyage(res.infoAddVoyage?.voyage.slug || '')
              }
            },
          })
        }}
        formSchema={fields}
      >
        {({ submitForm }) => (
          <FormContainer>
            <VoyageForm fieldMaster={fieldMaster} />
            <FormActionBar title={'New Voyage'} onCancel={() => navigate(-1)} onSubmit={() => submitForm()} />
          </FormContainer>
        )}
      </NiceFormik>
    </>
  )
}

const query = graphql`
  query VoyageAddFormQuery {
    infoAddVoyageForm {
      formSchema {
        fieldSet {
          name
          type
          required
          title
          description
          format
          enum
          enumTitles
        }
      }
    }
  }
`

const mutation = graphql`
  mutation VoyageAddMutation($input: infoAddVoyageInput!) {
    infoAddVoyage(input: $input) {
      voyage {
        slug
      }
    }
  }
`

VoyageAdd.Skeleton = () => (
  <>
    <VoyageForm.Skeleton />
    <FormActionBar.Skeleton />
  </>
)

export default VoyageAdd
