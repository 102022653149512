import { graphql } from 'babel-plugin-relay/macro'
import React, { Suspense, useState } from 'react'
import ExternalContainerLocation from '../../components/ExternalContainerLocation'
import ExternalFoldersCard from '../../components/File/ExternalFoldersCard'
import ResponsiveGrid from '../../components/ResponsiveGrid'
import { SlideInDrawer } from '../../components/SlideInDrawer'
import { ExternalContainerShowHomeContentQuery } from '../../__generated__/ExternalContainerShowHomeContentQuery.graphql'
import ExternalShipmentAdd from '../ExternalShipment/ExternalShipmentAdd'
import ETACard from '../../components/ETACard'
import ExternalItemListCard from '../../components/ExternalItemListCard'
// import ExternalContainerInfoCard from '../../components/ExternalContainerInfoCard'
import useLazyLoadQueryWithSubscription from '../../hooks/useLazyLoadQueryWithSubscription'
import ExternalTimeline from '../../components/ExternalTimeline/ExternalTimeline'
import LiveRequest from '../../components/LiveRequest'

interface Props {
  slug: string
}

const ExternalContainerShowHomeContent: React.FC<Props> & { Skeleton: React.FC } = ({ slug }) => {
  const queryVariables = { slug: slug }
  const queryData = useLazyLoadQueryWithSubscription<ExternalContainerShowHomeContentQuery>(containerHomeQuery, queryVariables, {
    fetchPolicy: 'store-and-network',
    subscriptionOptions: { entityType: 'containers', entitySlug: slug },
  })
  const { externalContainerShow: container } = queryData
  const [shipmentAddOpen, setShipmentAddOpen] = useState(false)

  return (
    <>
      <SlideInDrawer size='default' anchor='right' open={shipmentAddOpen} elevation={0} onClose={() => setShipmentAddOpen(false)}>
        <Suspense fallback={<ExternalShipmentAdd.Skeleton />}>
          <ExternalShipmentAdd containerSlug={container.slug} onClose={() => setShipmentAddOpen(false)} />
        </Suspense>
      </SlideInDrawer>
      <ResponsiveGrid
        type='show-layout'
        highlight={container.containerType !== 'foreign' ? [<ExternalContainerLocation key='physical_location' container={container} />] : []}
        left={[
          // <ExternalContainerInfoCard key='info-card' container={container} />,
          <Suspense key='shipments-card' fallback={<ExternalItemListCard.Skeleton />}>
            <ExternalItemListCard type='shipments' parentAbsoluteSlug={`containers/${slug}`} title='Shipments' entityTitle='Shipment' />
          </Suspense>,
          <ExternalFoldersCard
            key='photos'
            folderType='photos'
            title='Photos'
            folders={container.folders}
            entityType='containers'
            entitySlug={container.slug}
          />,
        ]}
        middle={[
          <ExternalFoldersCard
            key='documents'
            folderType='documents'
            folders={container.folders}
            title='Documents'
            entityType='containers'
            entitySlug={container.slug}
          />,
        ]}
        right={[
          <>
            <ETACard key='eta' voyage={container.voyage} />,
            <Suspense key='timeline' fallback={<ExternalTimeline.Skeleton />}>
              <ExternalTimeline slug={container.slug} type='containers' />
            </Suspense>
            <LiveRequest key='live-requests' liveRequests={container.liveRequests} entitySlug={slug} entityType='conteainers' />
          </>,
        ]}
      />
    </>
  )
}

export const containerHomeQuery = graphql`
  query ExternalContainerShowHomeContentQuery($slug: String!) {
    externalContainerShow(slug: $slug) {
      slug
      containerType
      ...ExternalContainerLocation_container
      ...ExternalContainerInfoCard_container
      # shipmentItems {
      #   slug
      #   ...ShowPageListItem_listItem
      # }
      folders {
        ...ExternalFoldersCard_folders
      }
      voyage {
        ...ETACard_voyage
      }
      liveRequests {
        ...LiveRequest_liveRequests
      }
    }
  }
`

ExternalContainerShowHomeContent.Skeleton = () => <>Loading...</>

export default ExternalContainerShowHomeContent
