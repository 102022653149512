/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type StageStatusEnum = "active" | "completed" | "incomplete" | "%future added value";
export type ContainerWorkflow_container = {
    readonly slug: string;
    readonly workflowStages: ReadonlyArray<{
        readonly value: string;
        readonly label: string;
        readonly ownedBy: string | null;
        readonly status: StageStatusEnum;
        readonly " $fragmentRefs": FragmentRefs<"Stage_stage">;
    }>;
    readonly " $refType": "ContainerWorkflow_container";
};
export type ContainerWorkflow_container$data = ContainerWorkflow_container;
export type ContainerWorkflow_container$key = {
    readonly " $data"?: ContainerWorkflow_container$data;
    readonly " $fragmentRefs": FragmentRefs<"ContainerWorkflow_container">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContainerWorkflow_container",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "WorkflowStage",
      "kind": "LinkedField",
      "name": "workflowStages",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ownedBy",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Stage_stage"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Container",
  "abstractKey": null
};
(node as any).hash = '98eb037a3b42fa99778b758805581e22';
export default node;
