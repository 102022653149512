import React from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import { useFragment } from 'react-relay/hooks'
import { ContainerLinkCard_data$key } from '../../__generated__/ContainerLinkCard_data.graphql'
import styled from 'styled-components'
import { Avatar, Typography, Badge as MuiBadge } from '@material-ui/core'
import SemanticString from '../SemanticString'
import Badge from '../Badge'
import SemanticLine from '../SemanticLine'

interface Props {
  data: ContainerLinkCard_data$key
}

const Root = styled.div`
  text-decoration: unset;
  color: unset;
  border: 1px solid ${(props) => props.theme.customPalette.border.card};
  border-radius: 4px;
  display: flex;
  align-items: center;
  overflow: hidden;

  &:hover {
    border-color: ${(props) => props.theme.palette.primary.main};
  }
`

const Meta = styled.div`
  padding: 8px;
  height: 100%;
  white-space: pre;
`

const TitleContainer = styled.div`
  padding: 8px 8px 8px 0;
  white-space: pre;
  overflow: hidden;
  background-color: ${(props) => props.theme.palette.background.paper};
  flex: 1;
`

const TitleText = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
`

const LinkAvatar = styled(Avatar)`
  width: 40px;
  height: 40px;
  border-radius: 8px;
`

const ContainerLinkCard: React.FC<Props> = ({ data }) => {
  const link = useFragment(linkFragment, data)
  return (
    <Root>
      {link.imageUrl && (
        <Meta>
          <MuiBadge
            overlap='circle'
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            badgeContent={link.badge && <Badge data={link.badge} />}
          >
            <LinkAvatar alt={link.type || ''} src={link.imageUrl} />
          </MuiBadge>
        </Meta>
      )}
      <TitleContainer>
        {link.title && (
          <TitleText variant='body1'>
            <SemanticString data={link.title} />
          </TitleText>
        )}
        {link.lines?.map((line) => (
          <SemanticLine key={line.key} data={line} />
        ))}
      </TitleContainer>
    </Root>
  )
}

const linkFragment = graphql`
  fragment ContainerLinkCard_data on Link {
    type
    title {
      ...SemanticString_data
    }
    meta {
      ...SemanticString_data
    }
    lines {
      key
      ...SemanticLine_data
    }
    imageUrl
    badge {
      ...Badge_data
    }
  }
`

export default ContainerLinkCard
