/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type FormFieldTypeEnum = "array" | "boolean" | "boolean_switch" | "container" | "container_image_slug" | "date" | "datetime" | "doc_slug" | "file_slug" | "image_slug" | "linked_contacts" | "multi_select" | "number" | "parent_shipment" | "port" | "rate_card" | "rich_text" | "searchable" | "shipment_image_slug" | "shipping_line" | "string" | "vessel" | "voyage" | "%future added value";
export type ContainerAddFormQueryVariables = {};
export type ContainerAddFormQueryResponse = {
    readonly infoAddContainerForm: {
        readonly formSchema: {
            readonly fieldSet: ReadonlyArray<{
                readonly name: string;
                readonly type: FormFieldTypeEnum;
                readonly required: boolean;
                readonly title: string | null;
                readonly description: string | null;
                readonly format: string | null;
                readonly enum: ReadonlyArray<string | null> | null;
                readonly enumTitles: ReadonlyArray<string | null> | null;
                readonly arrayItems: ReadonlyArray<{
                    readonly name: string;
                    readonly type: FormFieldTypeEnum;
                    readonly title: string | null;
                    readonly required: boolean;
                    readonly description: string | null;
                    readonly format: string | null;
                    readonly enum: ReadonlyArray<string | null> | null;
                    readonly enumTitles: ReadonlyArray<string | null> | null;
                }> | null;
            }>;
        };
    };
};
export type ContainerAddFormQuery = {
    readonly response: ContainerAddFormQueryResponse;
    readonly variables: ContainerAddFormQueryVariables;
};



/*
query ContainerAddFormQuery {
  infoAddContainerForm {
    formSchema {
      fieldSet {
        name
        type
        required
        title
        description
        format
        enum
        enumTitles
        arrayItems {
          name
          type
          title
          required
          description
          format
          enum
          enumTitles
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "required",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "format",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "enum",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "enumTitles",
  "storageKey": null
},
v8 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "InfoAddContainerFormResponse",
    "kind": "LinkedField",
    "name": "infoAddContainerForm",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "FormSchema",
        "kind": "LinkedField",
        "name": "formSchema",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FormField",
            "kind": "LinkedField",
            "name": "fieldSet",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "FormField",
                "kind": "LinkedField",
                "name": "arrayItems",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  (v3/*: any*/),
                  (v2/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ContainerAddFormQuery",
    "selections": (v8/*: any*/),
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ContainerAddFormQuery",
    "selections": (v8/*: any*/)
  },
  "params": {
    "cacheID": "0bc8c1a43ba2217c59b222e29f8691b7",
    "id": null,
    "metadata": {},
    "name": "ContainerAddFormQuery",
    "operationKind": "query",
    "text": "query ContainerAddFormQuery {\n  infoAddContainerForm {\n    formSchema {\n      fieldSet {\n        name\n        type\n        required\n        title\n        description\n        format\n        enum\n        enumTitles\n        arrayItems {\n          name\n          type\n          title\n          required\n          description\n          format\n          enum\n          enumTitles\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '90a1cf76069c9c11ab47940ff37d77bf';
export default node;
