import { graphql } from 'babel-plugin-relay/macro'
import React, { Suspense, useState } from 'react'
import ContainerLocation from '../../components/ContainerLocation'
import EmailsCard from '../../components/Email/EmailsCard'
import FoldersCard from '../../components/File/FoldersCard'
import LinkedContactsCard from '../../components/LinkedContactsCard'
import { AddNotes } from '../../components/Notes'
import ResponsiveGrid from '../../components/ResponsiveGrid'
import { SlideInDrawer } from '../../components/SlideInDrawer'
import Timeline from '../../components/Timeline/Timeline'
import { ContainerShowHomeContentQuery } from '../../__generated__/ContainerShowHomeContentQuery.graphql'
import ShipmentAdd from '../Shipment/ShipmentAdd'
import ContainerFinance from './ContainerFinance'
import ETACard from '../../components/ETACard'
import ItemListCard from '../../components/ItemListCard'
import ContainerInfoCard from '../../components/ContainerInfoCard'
import useLazyLoadQueryWithSubscription from '../../hooks/useLazyLoadQueryWithSubscription'
import LiveRequest from '../../components/LiveRequest'
import ETDCard from '../../components/ETDCard'

interface Props {
  slug: string
}

const ContainerShowHomeContent: React.FC<Props> & { Skeleton: React.FC } = ({ slug }) => {
  const queryVariables = { slug: slug }
  const queryData = useLazyLoadQueryWithSubscription<ContainerShowHomeContentQuery>(containerHomeQuery, queryVariables, {
    fetchPolicy: 'store-and-network',
    subscriptionOptions: { entityType: 'containers', entitySlug: slug },
  })
  const { containerShow: container } = queryData
  const [shipmentAddOpen, setShipmentAddOpen] = useState(false)

  return (
    <>
      <SlideInDrawer size='default' anchor='right' open={shipmentAddOpen} elevation={0} onClose={() => setShipmentAddOpen(false)}>
        <Suspense fallback={<ShipmentAdd.Skeleton />}>
          <ShipmentAdd containerSlug={container.slug} onClose={() => setShipmentAddOpen(false)} />
        </Suspense>
      </SlideInDrawer>
      <ResponsiveGrid
        type='show-layout'
        highlight={container.containerType !== 'foreign' ? [<ContainerLocation key='physical_location' container={container} />] : []}
        left={[
          <ContainerInfoCard key='info-card' container={container} />,
          <Suspense key='shipments-card' fallback={<ItemListCard.Skeleton />}>
            <ItemListCard
              type='shipments'
              parentAbsoluteSlug={`containers/${slug}`}
              title='Shipments'
              entityTitle='Shipment'
              onNew={() => setShipmentAddOpen(true)}
            />
          </Suspense>,
          <ContainerFinance key='finances' container={container} />,
        ]}
        middle={[
          // <LiveShare key='live-shares' entitySlug={slug} entityType='containers' liveShares={container.liveShares} />,
          <LiveRequest key='live-requests' entitySlug={slug} entityType='containers' liveRequests={container.liveRequests} />,
          <LinkedContactsCard
            key='contacts'
            entitySlug={container.slug}
            entityType='container'
            linkedContactRoles={container.linkedContactRoles}
          />,
          <FoldersCard
            key='documents'
            folderType='documents'
            folders={container.folders}
            title='Documents'
            entityType='containers'
            entitySlug={container.slug}
          />,
          <EmailsCard key='emails' entityType='containers' entitySlug={container.slug} emails={container.emails} />,
          <FoldersCard
            key='photos'
            folderType='photos'
            title='Photos'
            folders={container.folders}
            entityType='containers'
            entitySlug={container.slug}
          />,
        ]}
        right={[
          <>
            {container.procedure === 'Import' && <ETACard key='eta' voyage={container.voyage} />}
            {container.procedure === 'Export' && <ETDCard key='etd' voyage={container.voyage} />}
          </>,
          <Suspense key='notes' fallback={<AddNotes.Skeleton />}>
            <AddNotes entitySlug={container.slug} entityType='containers' />
          </Suspense>,
          <Suspense key='timeline' fallback={<Timeline.Skeleton />}>
            <Timeline slug={container.slug} type='containers' />
          </Suspense>,
        ]}
      />
    </>
  )
}

export const containerHomeQuery = graphql`
  query ContainerShowHomeContentQuery($slug: String!) {
    containerShow(slug: $slug) {
      slug
      containerType
      procedure
      ...ContainerLocation_container
      ...ContainerFinance_container
      ...ContainerInfoCard_container
      shipmentItems {
        slug
        ...ShowPageListItem_listItem
      }
      liveRequests {
        ...LiveRequest_liveRequests
      }
      linkedContactRoles {
        ...LinkedContactsCard_linkedContactRoles
      }
      emails {
        ...EmailsCard_emails
      }
      folders {
        ...FoldersCard_folders
      }
      voyage {
        ...ETACard_voyage
        ...ETDCard_voyage
      }
    }
  }
`

ContainerShowHomeContent.Skeleton = () => <>Loading...</>

export default ContainerShowHomeContent
