import { Typography, Paper } from '@material-ui/core'
import { graphql } from 'babel-plugin-relay/macro'
import React from 'react'
import { useFragment } from 'react-relay/hooks'
import styled from 'styled-components'
import { LiveShareEntityAttachment_attachment$key } from '../../__generated__/LiveShareEntityAttachment_attachment.graphql'
import { previewUrlForContentType } from './helpers'

interface Props {
  attachment: LiveShareEntityAttachment_attachment$key
}

const RootPaper = styled(Paper).attrs({ variant: 'outlined' })`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  &:hover {
    filter: brightness(0.95);
  }
`

const DocumentTypeTag = styled.div`
  position: absolute;
  right: 0px;
  top: -10px;
  background-color: ${(props) => props.theme.palette.grey[300]};
  padding: 0 8px;
  border-radius: 16px;
  max-width: 90%;
`

const DocumentTypeTagTypography = styled(Typography).attrs({ variant: 'caption' })`
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const PreviewContainer = styled.div`
  width: 100%;
  height: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Preview = styled.img<{ $iconPreview?: boolean }>`
  width: ${(props) => (props.$iconPreview ? '40px' : '100%')};
  height: ${(props) => (props.$iconPreview ? '40px' : '100%')};
  object-fit: cover;
`

const AttachmentName = styled(Typography)`
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
  text-overflow: ellipsis;
  width: 90%;
  padding: 4px;
`

const UnstyledLink = styled.a`
  color: unset;
  text-decoration: unset;
`

const LiveShareEntityAttachment: React.FC<Props> = ({ attachment: data }) => {
  const attachment = useFragment(fragment, data)
  const previewUrl = attachment.previewUrl ? attachment.previewUrl : previewUrlForContentType(attachment.contentType)
  const isIconPreview = attachment.previewUrl ? false : true

  return (
    <>
      <UnstyledLink href={attachment.url} target='_blank'>
        <RootPaper title={attachment.name}>
          <PreviewContainer>
            <Preview src={previewUrl} $iconPreview={isIconPreview} />
          </PreviewContainer>
          <AttachmentName variant='body2'>{attachment.name}</AttachmentName>
          {attachment.documentType && (
            <DocumentTypeTag>
              <DocumentTypeTagTypography>{attachment.documentType}</DocumentTypeTagTypography>
            </DocumentTypeTag>
          )}
        </RootPaper>
      </UnstyledLink>
    </>
  )
}

const fragment = graphql`
  fragment LiveShareEntityAttachment_attachment on LiveShareEntityAttachment {
    url
    downloadUrl
    name
    contentType
    previewUrl
    documentType
  }
`

export default LiveShareEntityAttachment
