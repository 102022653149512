import React from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import { useLazyLoadQuery } from 'react-relay/hooks'
import { Typography, Paper } from '@material-ui/core'
import styled from 'styled-components'
import ShowPageListItem from './ShowPageListItem'
import Button from './Button'
import { ItemListCardQuery, ListItemTypeEnum } from '../__generated__/ItemListCardQuery.graphql'

interface Props {
  type: ListItemTypeEnum
  parentAbsoluteSlug?: string
  contactSlug?: string
  title: string
  entityTitle?: string
  onNew?: () => void
  className?: string
}

const RootPaper = styled(Paper)`
  margin-bottom: 16px;
`

const TitleContainer = styled.div`
  padding: 16px;
  padding-bottom: 8px;
  display: flex;
  justify-content: space-between;
`

const NoDataText = styled(Typography)`
  color: ${(props) => props.theme.customPalette.text.noData};
  text-align: center;
  padding: 16px;
  font-weight: 400;
`

const ItemListCard: React.FC<Props> & { Skeleton: React.FC } = ({
  type,
  parentAbsoluteSlug,
  contactSlug,
  title,
  entityTitle,
  onNew,
  className,
}) => {
  const data = useLazyLoadQuery<ItemListCardQuery>(query, {
    type: type,
    parentAbsoluteSlug: parentAbsoluteSlug,
    contactSlug: contactSlug,
    includeArchived: true,
    requiredPagination: true,
  })
  return (
    <RootPaper variant='outlined' className={className}>
      <TitleContainer>
        <Typography variant='h6'>{title}</Typography>
        {onNew && <Button onClick={() => onNew && onNew()}>New {entityTitle}</Button>}
      </TitleContainer>
      {data.itemList.items.nodes && data.itemList.items.nodes?.length > 0 ? (
        data.itemList.items.nodes.map((item) => item && <ShowPageListItem key={item.slug} listItem={item} />)
      ) : (
        <NoDataText variant='h6'>No Shipments</NoDataText>
      )}
    </RootPaper>
  )
}

const query = graphql`
  query ItemListCardQuery(
    $type: ListItemTypeEnum
    $parentAbsoluteSlug: String
    $contactSlug: String
    $includeArchived: Boolean
    $requiredPagination: Boolean
  ) {
    itemList(
      type: $type
      parentAbsoluteSlug: $parentAbsoluteSlug
      contactSlug: $contactSlug
      includeArchived: $includeArchived
      requiredPagination: $requiredPagination
    ) {
      items {
        nodes {
          slug
          ...ShowPageListItem_listItem
        }
      }
    }
  }
`

ItemListCard.Skeleton = () => <>Loading...</>

export default ItemListCard
