import { Typography } from '@material-ui/core'
import { graphql } from 'babel-plugin-relay/macro'
import React from 'react'
import { useFragment } from 'react-relay/hooks'
import styled from 'styled-components'
import { media } from '../theme'
import { SettingItemImage_data$key } from '../__generated__/SettingItemImage_data.graphql'

interface Props {
  data: SettingItemImage_data$key
}

interface RootProps {
  url: string
  shape: string
}

const Root = styled.div<RootProps>`
  width: 75px;
  height: 75px;
  margin-right: 16px;
  background-image: url('${(props) => props.url}');
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: ${(props) => (props.shape === 'circle' ? '50%' : '8px')};

  display: flex;
  justify-content: center;
  align-items: center;

  ${media.small`
    width: 50px;
    height: 50px;
  `}
`

const Overlay = styled(Typography)`
  background: rgba(0, 0, 0, 0.35);
  font-weight: 700;
  position: relative;
  backdrop-filter: blur(8px);
  color: #eaeaea;
  flex: 1;
  font-size: 12px;
  padding: 8px;
  text-align: center;
  text-transform: uppercase;
  max-height: 80%;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
`

const SettingItemImage: React.FC<Props> = ({ data }) => {
  const image = useFragment(imageFragment, data)

  return (
    <Root url={image?.url as string} shape={image.shape || 'circle'}>
      {image.overlay && <Overlay variant='body2'>{image.overlay}</Overlay>}
    </Root>
  )
}

const imageFragment = graphql`
  fragment SettingItemImage_data on Image {
    url
    shape
    overlay
  }
`

export default SettingItemImage
