/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type FormFieldTypeEnum = "array" | "boolean" | "boolean_switch" | "container" | "container_image_slug" | "date" | "datetime" | "doc_slug" | "file_slug" | "image_slug" | "linked_contacts" | "multi_select" | "number" | "parent_shipment" | "port" | "rate_card" | "rich_text" | "searchable" | "shipment_image_slug" | "shipping_line" | "string" | "vessel" | "voyage" | "%future added value";
export type ExternalShipmentEditFormQueryVariables = {
    slug: string;
};
export type ExternalShipmentEditFormQueryResponse = {
    readonly infoUpdateShipmentForm: {
        readonly formSchema: {
            readonly fieldSet: ReadonlyArray<{
                readonly name: string;
                readonly type: FormFieldTypeEnum;
                readonly required: boolean;
                readonly title: string | null;
                readonly description: string | null;
                readonly format: string | null;
                readonly enum: ReadonlyArray<string | null> | null;
                readonly enumTitles: ReadonlyArray<string | null> | null;
                readonly placeholder: string | null;
            }>;
        };
        readonly initialValue: {
            readonly slug: string;
            readonly shipmentKey: string;
            readonly containerSlug: string;
            readonly profileImageSlug: string | null;
            readonly shipmentType: string;
            readonly vin: string | null;
            readonly description: string;
            readonly parentShipment: string | null;
            readonly dischargeDate: string | null;
            readonly shipperReleaseCheck: boolean;
            readonly finalReleaseCheck: boolean;
            readonly runs: boolean;
            readonly atbPosition: string | null;
            readonly customsType: string | null;
            readonly customsReference: string | null;
            readonly vinEngine: string | null;
            readonly vinFuelType: string | null;
            readonly vinBodyClass: string | null;
            readonly vinOrigin: string | null;
            readonly linkedContacts: {
                readonly nodes: ReadonlyArray<{
                    readonly role: string;
                    readonly contactSlug: string;
                } | null> | null;
            };
        } | null;
    };
};
export type ExternalShipmentEditFormQuery = {
    readonly response: ExternalShipmentEditFormQueryResponse;
    readonly variables: ExternalShipmentEditFormQueryVariables;
};



/*
query ExternalShipmentEditFormQuery(
  $slug: String!
) {
  infoUpdateShipmentForm(slug: $slug) {
    formSchema {
      fieldSet {
        name
        type
        required
        title
        description
        format
        enum
        enumTitles
        placeholder
      }
    }
    initialValue {
      slug
      shipmentKey
      containerSlug
      profileImageSlug
      shipmentType
      vin
      description
      parentShipment
      dischargeDate
      shipperReleaseCheck
      finalReleaseCheck
      runs
      atbPosition
      customsType
      customsReference
      vinEngine
      vinFuelType
      vinBodyClass
      vinOrigin
      linkedContacts {
        nodes {
          role
          contactSlug
        }
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "FormSchema",
  "kind": "LinkedField",
  "name": "formSchema",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "FormField",
      "kind": "LinkedField",
      "name": "fieldSet",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "required",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "format",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "enum",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "enumTitles",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "placeholder",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shipmentKey",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "containerSlug",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profileImageSlug",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shipmentType",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "vin",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "parentShipment",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dischargeDate",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shipperReleaseCheck",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "finalReleaseCheck",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "runs",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "atbPosition",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "customsType",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "customsReference",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "vinEngine",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "vinFuelType",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "vinBodyClass",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "vinOrigin",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "concreteType": "ContactLinkConnection",
  "kind": "LinkedField",
  "name": "linkedContacts",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ContactLink",
      "kind": "LinkedField",
      "name": "nodes",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "role",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "contactSlug",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ExternalShipmentEditFormQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "InfoUpdateShipmentFormResponse",
        "kind": "LinkedField",
        "name": "infoUpdateShipmentForm",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Shipment",
            "kind": "LinkedField",
            "name": "initialValue",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v2/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ExternalShipmentEditFormQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "InfoUpdateShipmentFormResponse",
        "kind": "LinkedField",
        "name": "infoUpdateShipmentForm",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Shipment",
            "kind": "LinkedField",
            "name": "initialValue",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v2/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "37c98a7d6a2e10f0f1cca63aa56b17a8",
    "id": null,
    "metadata": {},
    "name": "ExternalShipmentEditFormQuery",
    "operationKind": "query",
    "text": "query ExternalShipmentEditFormQuery(\n  $slug: String!\n) {\n  infoUpdateShipmentForm(slug: $slug) {\n    formSchema {\n      fieldSet {\n        name\n        type\n        required\n        title\n        description\n        format\n        enum\n        enumTitles\n        placeholder\n      }\n    }\n    initialValue {\n      slug\n      shipmentKey\n      containerSlug\n      profileImageSlug\n      shipmentType\n      vin\n      description\n      parentShipment\n      dischargeDate\n      shipperReleaseCheck\n      finalReleaseCheck\n      runs\n      atbPosition\n      customsType\n      customsReference\n      vinEngine\n      vinFuelType\n      vinBodyClass\n      vinOrigin\n      linkedContacts {\n        nodes {\n          role\n          contactSlug\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'e5e86ed76d4ea0a59e46970d7f8c1715';
export default node;
