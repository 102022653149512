/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LiveShareShipmentShowHeroCardQuery_item = {
    readonly image: {
        readonly url: string | null;
        readonly " $fragmentRefs": FragmentRefs<"ItemImage_data">;
    } | null;
    readonly " $refType": "LiveShareShipmentShowHeroCardQuery_item";
};
export type LiveShareShipmentShowHeroCardQuery_item$data = LiveShareShipmentShowHeroCardQuery_item;
export type LiveShareShipmentShowHeroCardQuery_item$key = {
    readonly " $data"?: LiveShareShipmentShowHeroCardQuery_item$data;
    readonly " $fragmentRefs": FragmentRefs<"LiveShareShipmentShowHeroCardQuery_item">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LiveShareShipmentShowHeroCardQuery_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ItemImage_data"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ListItem",
  "abstractKey": null
};
(node as any).hash = '446be2d95b066c7a3e62761c2e40ff64';
export default node;
