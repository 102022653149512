import { Avatar, Card, CardHeader } from '@material-ui/core'
import { graphql } from 'babel-plugin-relay/macro'
import React from 'react'
import { useFragment } from 'react-relay/hooks'
import styled from 'styled-components'
import useWorkspaceNavigate from '../../hooks/useWorkspaceNavigate'
import { ExternalFeedItem_data$key } from '../../__generated__/ExternalFeedItem_data.graphql'
import KeyField from '../KeyField'
import ExternalFeedAttachments from './ExternalFeedAttachments'
import ExternalFeedImages from './ExternalFeedImages'

interface Props {
  data: ExternalFeedItem_data$key
  external: boolean
}

const VerticalGrid = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-gap: 16px;
`
const StyledCard = styled(Card)`
  padding: 16px 0;
`

const StyledCardHeader = styled(CardHeader)`
  padding-top: 0;
  padding-bottom: 0;
`

const Content = styled.div`
  padding: 0px 16px;
`
const KeyFieldsContainer = styled(Content)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 8px;
  width: 100%;
`

const ExternalFeedItem: React.FC<Props> = ({ data, external }) => {
  const feedItem = useFragment(fragment, data)
  const wsNavigate = useWorkspaceNavigate()
  const maxFields = 9
  return (
    <StyledCard variant='outlined'>
      <VerticalGrid>
        <StyledCardHeader
          avatar={<Avatar src={feedItem?.titleImage?.url as string} />}
          title={
            external ? (
              <span>
                {feedItem.title}{' '}
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  style={{ textTransform: 'capitalize' }}
                  href={`/${wsNavigate.workspace}/${feedItem.entity}/${feedItem.slug}`}
                >
                  ({feedItem.entity}-{feedItem.slug})
                </a>
              </span>
            ) : (
              feedItem.title
            )
          }
          subheader={feedItem.happenedAt}
        />
        {feedItem.images?.edges && feedItem.images.edges.length > 0 && (
          <Content>
            <ExternalFeedImages data={feedItem.images} />
          </Content>
        )}
        {feedItem.contentLines && feedItem.contentLines.length > 0 && (
          <Content>
            {feedItem.contentLines.map((line) => (
              <p key={line.key}>{line.text}</p>
            ))}
          </Content>
        )}
        {feedItem.keyFields && feedItem.keyFields.length > 0 && (
          <KeyFieldsContainer>
            {feedItem.keyFields?.slice(0, maxFields)?.map((field) => (
              <KeyField key={field.key} data={field} />
            ))}
          </KeyFieldsContainer>
        )}
        {feedItem.attachments?.nodes && feedItem.attachments.nodes.length > 0 && (
          <Content>
            <ExternalFeedAttachments data={feedItem.attachments} />
          </Content>
        )}
      </VerticalGrid>
    </StyledCard>
  )
}

const fragment = graphql`
  fragment ExternalFeedItem_data on ExternalFeedItem {
    slug
    entity
    title
    titleImage {
      url
    }
    happenedAt
    images {
      edges {
        node {
          url
        }
      }
      ...ExternalFeedImages_data
    }
    contentLines {
      key
      text
    }
    attachments {
      nodes {
        slug
      }
      ...ExternalFeedAttachments_data
    }
    keyFields {
      key
      ...KeyField_data
    }
  }
`

export default ExternalFeedItem
