import { Dialog, DialogActions, DialogContent, Step, StepButton, Stepper } from '@material-ui/core'
import { graphql } from 'babel-plugin-relay/macro'
import React from 'react'
import { useFragment } from 'react-relay/hooks'
import Button from './Button'
import LinkedContactsCard from './LinkedContactsCard'
import { ExternalShipmentInfoCard_shipment$key } from '../__generated__/ExternalShipmentInfoCard_shipment.graphql'
import ExternalFoldersCard from './File/ExternalFoldersCard'
import LoadingDots from './LoadingDots'
import useNiceMutation from '../mutations/useNiceMutation'
import { useSnackbar } from 'notistack'
import { ExternalUploadDocumentsWizardSendSignatureMutation } from '../__generated__/ExternalUploadDocumentsWizardSendSignatureMutation.graphql'
import { ExternalUploadDocumentsWizardCreateDocsMutation } from '../__generated__/ExternalUploadDocumentsWizardCreateDocsMutation.graphql'

interface Props {
  slug: string
  open: boolean
  shipment: ExternalShipmentInfoCard_shipment$key
  onClose: () => void
}

const steps = ['Upload Bill of Sale', 'Add Customer']

const DOCUMENT_NAME_STEP_1 = 'Bill of Sale'

const ExternalUploadDocumentsWizard: React.FC<Props> = ({ slug, shipment: data, open, onClose }) => {
  const shipment = useFragment(fragment, data)
  const completed = [!shipment.requiredDocuments?.includes(DOCUMENT_NAME_STEP_1), false]

  const [activeStep, setActiveStep] = React.useState(0)

  return (
    <>
      <Dialog open={open} maxWidth='md' onClose={onClose} scroll='paper' style={{ margin: 'auto' }}>
        <Stepper nonLinear activeStep={activeStep}>
          {steps.map((label, index) => {
            return (
              <Step key={label}>
                <StepButton onClick={() => setActiveStep(index)} completed={completed[index]}>
                  {label}
                </StepButton>
              </Step>
            )
          })}
        </Stepper>
        {activeStep === 0 && (
          <UploadBillOfSaleStep shipment={data} setActiveStep={setActiveStep} slug={slug} activeStep={activeStep} onClose={onClose} />
        )}
        {activeStep === 1 && (
          <AddCustomerStep shipment={data} setActiveStep={setActiveStep} slug={slug} activeStep={activeStep} onClose={onClose} />
        )}
      </Dialog>
    </>
  )
}

interface StepContentProps {
  slug: string
  activeStep: number
  setActiveStep: (value: React.SetStateAction<number>) => void
  onClose: () => void
  shipment: ExternalShipmentInfoCard_shipment$key
}

const UploadBillOfSaleStep: React.FC<StepContentProps> = ({ setActiveStep, shipment, slug }) => {
  return (
    <>
      <DialogContent>
        <ExternalFoldersCard
          key='documents'
          folderType='documents'
          // @ts-ignore
          folders={shipment.folders}
          title='Documents'
          entityType='shipments'
          entitySlug={slug}
          documentType={DOCUMENT_NAME_STEP_1}
        />
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={() => setActiveStep((prev) => prev + 1)}>
          Next: Add Customer
        </Button>
      </DialogActions>
    </>
  )
}

const AddCustomerStep: React.FC<StepContentProps> = ({ shipment, onClose }) => {
  const [commitGenerateExternal, externalGenerateIsInFlight] =
    useNiceMutation<ExternalUploadDocumentsWizardCreateDocsMutation>(externalGenerateMutation)
  const [commitSendSignature, isProcessingSendSignature] =
    useNiceMutation<ExternalUploadDocumentsWizardSendSignatureMutation>(sendForSignatureMutation)
  const { enqueueSnackbar } = useSnackbar()
  // @ts-ignore
  const foldersConnection = useFragment(fragment2, shipment.folders)

  const isPoaDocsGenerated = foldersConnection.nodes.some((node: { attachments: { documentType: string }[]; parentType: string }) => {
    const attachment_present = node.attachments.some((attachment: { documentType: string }) =>
      ['vehicle_poa_template', 'general_poa_template', 'fiscal_poa_template'].includes(attachment.documentType)
    )
    return node.parentType === 'documents' && attachment_present
  })

  const processESign = async () => {
    if (!isPoaDocsGenerated) externalGenerateDoc()
    else sendForSignature()
  }
  const sendForSignature = () => {
    commitSendSignature({
      variables: {
        input: {
          // @ts-ignore
          entitySlug: shipment.slug,
          entityType: 'shipments',
        },
      },
      onCompleted: (_, err) => {
        if (!err) {
          enqueueSnackbar(`Documents successfully sent for e-Signature`, { variant: 'success' })
        }
      },
    })
  }
  const externalGenerateDoc = () => {
    commitGenerateExternal({
      variables: {
        input: {
          entityType: 'shipments',
          // @ts-ignore
          entitySlug: shipment.slug,
          // @ts-ignore
          folderSlug: foldersConnection.nodes.filter((e) => e.parentType === 'documents')[0].slug,
          templateSlug: 'poa',
        },
      },
      onCompleted: (_, errors) => {
        if (errors) {
          hasErrors = true
        } else {
          sendForSignature()
        }
        onClose()
      },
    })
  }

  let hasErrors = false

  return (
    <>
      <DialogContent>
        <LinkedContactsCard
          key='contacts'
          // @ts-ignore
          linkedContacts={shipment.linkedContacts}
          // @ts-ignore
          draftContacts={shipment.draftContacts}
          // @ts-ignore
          draftLinkedContactRoles={shipment.draftLinkedContactRoles}
          // @ts-ignore
          entitySlug={shipment.slug}
          entityType='shipment'
          //eslint-disable-next-line jsx-a11y/aria-role
          role='customer'
        />
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={processESign} disabled={externalGenerateIsInFlight || hasErrors}>
          {externalGenerateIsInFlight || isProcessingSendSignature ? <LoadingDots variant='primary' /> : 'Send POA documents for e-Sign'}
        </Button>
      </DialogActions>
    </>
  )
}

const fragment = graphql`
  fragment ExternalUploadDocumentsWizard_shipment on ExternalShipment {
    slug
    requiredDocuments
  }
`

const externalGenerateMutation = graphql`
  mutation ExternalUploadDocumentsWizardCreateDocsMutation($input: infoGeneratePoaDocumentForExternalInput!) {
    infoGeneratePoaDocumentForExternal(input: $input) {
      clientMutationId
    }
  }
`

const sendForSignatureMutation = graphql`
  mutation ExternalUploadDocumentsWizardSendSignatureMutation($input: insignRegisterDocumentForSigningInput!) {
    insignRegisterDocumentForSigning(input: $input) {
      clientMutationId
    }
  }
`

const fragment2 = graphql`
  fragment ExternalUploadDocumentsWizard_folders on FolderConnection {
    nodes {
      slug
      activeTicket
      parentType
      attachments {
        id
        documentType
      }
    }
  }
`

export default ExternalUploadDocumentsWizard
