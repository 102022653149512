import { DialogContent, DialogTitle } from '@material-ui/core'
import { Alert, Skeleton } from '@material-ui/lab'
import { graphql } from 'babel-plugin-relay/macro'
import React, { Suspense, useState } from 'react'
import { useLazyLoadQuery } from 'react-relay/hooks'
import styled from 'styled-components'
import DialogFormActionBar from '../../components/Form/DialogFormActionBar'
import InputField from '../../components/Form/InputField'
import { inputHashToLinkedContacts, linkedContactToInputHash } from '../../components/Form/LinkedContactsField'
import NiceFormik from '../../components/Form/NiceFormik'
import useNiceMutation from '../../mutations/useNiceMutation'
import { media } from '../../theme'
import { AddContainerLinkedContactsMutation } from '../../__generated__/AddContainerLinkedContactsMutation.graphql'
import { AddContainerLinkedContactsQuery } from '../../__generated__/AddContainerLinkedContactsQuery.graphql'
import OdysseyDialog from '../../components/OdysseyDialog'

interface Props {
  slug: string
  open: boolean
  onClose: () => void
}

const FormContainer = styled.div`
  margin-bottom: 8px;
`

const FieldsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 16px;

  ${media.medium`
    grid-template-columns: repeat(1, 1fr);
  `}

  ${media.small`
    grid-template-columns: auto;
  `}
`

const AddContainerLinkedContacts: React.FC<Props> = (props) => {
  return (
    <OdysseyDialog
      open={props.open}
      maxWidth='sm'
      fullWidth
      onClose={props.onClose}
      scroll='paper'
      style={{ maxWidth: '640px', margin: 'auto' }}
    >
      <Suspense fallback={<FormSkeleton {...props} />}>
        <AddForm {...props} />
      </Suspense>
    </OdysseyDialog>
  )
}

const AddForm: React.FC<Props> = (props) => {
  const data = useLazyLoadQuery<AddContainerLinkedContactsQuery>(query, { slug: props.slug })
  const fields = data.infoUpdateContainerForm.formSchema.fieldSet
  const fieldMaster = Object.fromEntries(fields.map((field) => [field.name, field]))
  const [mutationError, setMutationError] = useState<null | string>(null)

  const [commit] = useNiceMutation<AddContainerLinkedContactsMutation>(mutation)

  const initialValue = {
    ...data.infoUpdateContainerForm.initialValue,
    linkedContacts: linkedContactToInputHash(data.infoUpdateContainerForm.initialValue?.linkedContacts?.nodes),
  }

  return (
    <>
      {mutationError && <Alert severity='error'>{mutationError}</Alert>}
      <DialogTitle>Add Contacts</DialogTitle>
      <DialogContent dividers={true}>
        <NiceFormik
          initialValues={initialValue || {}}
          onSubmit={(values, actions) => {
            const processedValues = {
              ...values,
              linkedContacts: inputHashToLinkedContacts((values as any).linkedContacts),
            }
            commit({
              variables: {
                input: processedValues as any,
              },
              onError: () => actions.setSubmitting(false),
              onCompleted: (res, errors) => {
                actions.setSubmitting(false)
                if (errors) {
                  setMutationError(errors.map((err) => err.message).join(', '))
                } else {
                  props.onClose()
                }
              },
            })
          }}
          formSchema={fields}
        >
          {({ submitForm }) => (
            <>
              <FormContainer>
                <FieldsContainer>
                  <InputField of='linkedContacts' field={fieldMaster['linkedContacts']} />
                </FieldsContainer>
              </FormContainer>
              <DialogFormActionBar
                onCancel={() => props.onClose()}
                onSubmit={() => {
                  submitForm()
                }}
                cancelCta='Cancel'
                saveCta='Save'
              />
            </>
          )}
        </NiceFormik>
      </DialogContent>
    </>
  )
}

const FormSkeleton: React.FC<Props> = ({ onClose }) => (
  <>
    <DialogTitle>Add Contacts</DialogTitle>
    <DialogContent>
      <Skeleton variant='rect' height='50px' />
      <Skeleton variant='rect' height='50px' style={{ marginTop: '8px' }} />
    </DialogContent>
    <DialogFormActionBar.Skeleton onCancel={onClose} />
  </>
)

const query = graphql`
  query AddContainerLinkedContactsQuery($slug: String!) {
    infoUpdateContainerForm(slug: $slug) {
      formSchema {
        fieldSet {
          name
          type
          required
          title
          description
          format
          enum
          enumTitles
          placeholder
        }
      }
      initialValue {
        slug
        containerNumber
        containerSize
        voyageSlug
        containerType
        atb

        linkedContacts {
          nodes {
            role
            contactSlug
          }
        }
      }
    }
  }
`

const mutation = graphql`
  mutation AddContainerLinkedContactsMutation($input: infoUpdateContainerInput!) {
    infoUpdateContainer(input: $input) {
      container {
        slug
      }
    }
  }
`

export default AddContainerLinkedContacts
