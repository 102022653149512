import moment from 'moment'

export const useDateTimeDisplay = (date: string) => {
  if (date.includes('on')) {
    return 'on ' + moment.utc(date.split('on ')[1]).local().format('DD MMM YYYY, HH:mm')
  } else if (date.includes('in') || date.includes('ago')) {
    return date
  } else {
    return moment.utc(date).local().format('DD MMM YYYY, HH:mm')
  }
}
