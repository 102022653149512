import { Avatar, Card, IconButton, Menu, MenuItem, Paper, Typography } from '@material-ui/core'
import { ArrowDropDown, Edit, MoreVert } from '@material-ui/icons'
import { Skeleton } from '@material-ui/lab'
import { graphql } from 'babel-plugin-relay/macro'
import React, { useRef, useState } from 'react'
import { Title as ReactHeadTitle } from 'react-head'
import styled from 'styled-components'
import ArchivedBanner from '../../components/ArchivedBanner'
import Badge from '../../components/Badge'
import Button from '../../components/Button'
import ExternalContainerWorkflow from '../../components/ExternalWorkflows/ExternalContainerWorkflow'
import Hero from '../../components/Hero'
import SubStage from '../../components/SubStage'
import TabsUI from '../../components/TabsUI/TabsUI'
import useLazyLoadQueryWithSubscription from '../../hooks/useLazyLoadQueryWithSubscription'
import useWorkspaceNavigate from '../../hooks/useWorkspaceNavigate'
import { media } from '../../theme'
import { ShowPageRenderContext } from '../../viewTypes'
import { ExternalContainerShowHeroCardQuery } from '../../__generated__/ExternalContainerShowHeroCardQuery.graphql'
import { tabs } from './tabs'

interface Props {
  slug: string
  activeTab: string
  onEdit: () => void
  renderContext: ShowPageRenderContext
}

const ContentRoot = styled.div`
  width: 100%;
  min-height: 100px;
`

const NonStageContent = styled.div`
  width: 100%;
  display: grid;
  padding: 0 64px;
  grid-template-columns: 3fr 1fr;

  ${media.medium`
    padding: 0 32px;
    grid-template-columns: 3fr 1fr;
  `}

  ${media.small`
    grid-template-columns: 1fr;
    padding-left: 8px;
    padding-right: 8px;
  `}
`

const TitleContent = styled.div`
  width: 100%;
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${media.small`
    padding-bottom: 8px;
  `}
`

const MetaContent = styled.div`
  width: 100%;
  padding: 34px 20px 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.small`
    padding-top: 0;
    padding-left: 0;
    align-items: stretch;
  `}
`

const StagesContainer = styled.div`
  width: 100%;
  padding-bottom: 16px;
`

const TabContainer = styled.div`
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const DetailsCard = styled(Card)`
  width: 100%;
  padding: 16px;
`

const KeyFieldsContainer = styled.div`
  margin-bottom: 8px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 190px);
  grid-gap: 8px;
  width: 100%;

  ${media.small`
    grid-template-columns: repeat(auto-fill, 110px);
  `}
`

const ProfileImage = styled(Avatar)`
  margin-bottom: 8px;
  height: 100px;
  width: 100px;
  margin-right: 16px;
  border: 4px solid ${(props) => props.theme.palette.background.paper};
  border-radius: 8px;
`

const Titlebar = styled(Paper)`
  position: relative;
  width: 100%;
  height: 72px;
  display: flex;
  align-items: center;
  padding: 8px;
  margin-bottom: 20px;
  margin-top: 30px;

  ${media.small`
    height: 90px;
  `}
`

const TitlebarContent = styled.div`
  display: flex;
  align-items: center;
`

const Title = styled(Typography)`
  margin-right: 8px;
  font-weight: 700;
`

const ShipmentTitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const ShipmentTitleContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`

const NormalActions = styled(FlexContainer)`
  display: flex;
  align-items: center;
  ${media.small`
    display: none;
  `}
`

const CollapsedActions = styled(FlexContainer)`
  display: none;

  ${media.small`
    display: flex;
  `}
`

const ExternalContainerShowHeroCard: React.FC<Props> & { Skeleton: React.FC } = ({ slug, activeTab, renderContext }) => {
  const [open, setOpen] = useState(false)
  const shipmentMenuRef = useRef(null)

  const { externalItemShow: item, externalContainerShow: container } = useLazyLoadQueryWithSubscription<ExternalContainerShowHeroCardQuery>(
    query,
    {
      slug: slug,
      itemType: 'container',
    },
    { subscriptionOptions: { entityType: 'containers', entitySlug: slug } }
  )

  const value = tabs.findIndex((t) => t.value === activeTab)
  const wsNavigate = useWorkspaceNavigate()

  const onTabChange = (e: React.ChangeEvent<unknown>, newValue: number) => {
    wsNavigate(`/container/${item.slug}/${tabs[newValue].value}`)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      {renderContext === 'page' && <ReactHeadTitle>Container - {item.title}</ReactHeadTitle>}
      <ArchivedBanner isArchived={container.isArchived} />
      <Hero backgroundColor='transparent' imageUrl={item?.image?.url as string}>
        <ContentRoot>
          <NonStageContent>
            <TitleContent>
              <Titlebar variant='outlined'>
                <ProfileImage src={item?.image?.url as string} />
                <TitlebarContent>
                  <Title variant='h5'>{item.title}</Title>
                  {item.badges?.map((badge) => (
                    <Badge key={badge.key} data={badge} />
                  ))}
                </TitlebarContent>
                {container.shipmentItems.length > 0 && (
                  <>
                    <ShipmentTitleContainer>
                      <ShipmentTitleContent />
                      <IconButton ref={shipmentMenuRef} onClick={() => setOpen(true)}>
                        <ArrowDropDown />
                      </IconButton>
                      <Menu
                        open={open}
                        anchorEl={shipmentMenuRef.current as HTMLDivElement | null}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        onClose={handleClose}
                      >
                        {container.shipmentItems?.map((shipment) => (
                          <MenuItem
                            key={shipment.slug}
                            onClick={() => {
                              wsNavigate(`/shipment/${shipment.slug}/home`)
                              handleClose()
                            }}
                          >
                            {shipment.title}
                          </MenuItem>
                        ))}
                      </Menu>
                    </ShipmentTitleContainer>
                  </>
                )}
              </Titlebar>
              {/* <DetailsCard variant='outlined'>
                {item.keyFields && (
                  <KeyFieldsContainer>
                    {item.keyFields?.slice(0, maxFields)?.map(field => (
                      <KeyField key={field.key} data={field} />
                    ))}
                    <LinkKeyValueField label='Voyage' value={voyageLink ? <LinkCard data={voyageLink} /> : null} />
                  </KeyFieldsContainer>
                )}
              </DetailsCard> */}
            </TitleContent>
            <MetaContent>
              <SubStage data={container.subStage} entityType='containers' entitySlug={slug} />
            </MetaContent>
          </NonStageContent>
          <StagesContainer>
            <ExternalContainerWorkflow container={container} />
          </StagesContainer>
        </ContentRoot>
      </Hero>
      <Hero backgroundColor='transparent'>
        <TabContainer>
          <TabsUI isWhiteBackground tabs={tabs} value={value} onChange={onTabChange} />
        </TabContainer>
      </Hero>
    </>
  )
}

const query = graphql`
  query ExternalContainerShowHeroCardQuery($slug: String!, $itemType: ExternalShowItemTypeEnum!) {
    externalItemShow(slug: $slug, type: $itemType) {
      slug
      title
      image {
        url
        alt
        ...ItemImage_data
      }
      badges {
        key
        ...Badge_data
      }
      lines {
        key
        ...SemanticLine_data
      }
      keyFields {
        key
        ...KeyField_data
      }
    }

    externalContainerShow(slug: $slug) {
      slug
      isArchived
      stage
      releaseInvoiceStatus
      ...ExternalContainerWorkflow_container
      subStage {
        ...SubStage_data
      }
      watches {
        ...Watchers_watches
      }
      shipmentItems {
        slug
        title
        badges {
          key
          ...Badge_data
        }
        image {
          url
        }
        lines {
          key
          ...SemanticLine_data
        }
      }
    }
  }
`

ExternalContainerShowHeroCard.Skeleton = () => (
  <>
    <Hero backgroundColor='transparent'>
      <ContentRoot>
        <NonStageContent>
          <TitleContent>
            <Titlebar variant='outlined'>
              <Skeleton variant='rect' height='100px' width='100px' style={{ borderRadius: '8px', marginRight: '16px' }} />
              <TitlebarContent>
                <Skeleton>
                  <Title variant='h5'>..................</Title>
                </Skeleton>
              </TitlebarContent>
            </Titlebar>
            <DetailsCard variant='outlined'>
              <KeyFieldsContainer>
                {[...Array(4)].map((idx) => (
                  <Skeleton key={idx} height='44px' />
                ))}
              </KeyFieldsContainer>
            </DetailsCard>
          </TitleContent>
          <MetaContent>
            <Skeleton variant='rect' height='36px' width='200px' style={{ marginBottom: '8px' }} />
          </MetaContent>
        </NonStageContent>
        <StagesContainer>
          <div style={{ height: '64px' }} />
        </StagesContainer>
      </ContentRoot>
    </Hero>
    <Hero backgroundColor='transparent'>
      <TabContainer>
        {/* no TabsUI skeleton */}
        <span></span>
        <NormalActions>
          <Button variant='contained' startIcon={<Edit />} disabled>
            Edit
          </Button>
        </NormalActions>
        <CollapsedActions>
          <IconButton disabled>
            <MoreVert />
          </IconButton>
        </CollapsedActions>
      </TabContainer>
    </Hero>
  </>
)

export default ExternalContainerShowHeroCard
