/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LinkEnum = "contact" | "container" | "shipment" | "ticket" | "vessel" | "voyage" | "%future added value";
export type LinkCard_data = {
    readonly type: LinkEnum;
    readonly title: {
        readonly " $fragmentRefs": FragmentRefs<"SemanticString_data">;
    } | null;
    readonly meta: {
        readonly " $fragmentRefs": FragmentRefs<"SemanticString_data">;
    } | null;
    readonly lines: ReadonlyArray<{
        readonly key: string;
        readonly " $fragmentRefs": FragmentRefs<"SemanticLine_data">;
    }> | null;
    readonly imageUrl: string | null;
    readonly entitySlug: string;
    readonly " $fragmentRefs": FragmentRefs<"VoyageLinkCard_data" | "VesselLinkCard_data" | "ShipmentLinkCard_data" | "ContainerLinkCard_data" | "ContactLinkCard_data">;
    readonly " $refType": "LinkCard_data";
};
export type LinkCard_data$data = LinkCard_data;
export type LinkCard_data$key = {
    readonly " $data"?: LinkCard_data$data;
    readonly " $fragmentRefs": FragmentRefs<"LinkCard_data">;
};



const node: ReaderFragment = (function(){
var v0 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "SemanticString_data"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LinkCard_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SemanticString",
      "kind": "LinkedField",
      "name": "title",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SemanticString",
      "kind": "LinkedField",
      "name": "meta",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SemanticLine",
      "kind": "LinkedField",
      "name": "lines",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "key",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SemanticLine_data"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "imageUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entitySlug",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "VoyageLinkCard_data"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "VesselLinkCard_data"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ShipmentLinkCard_data"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContainerLinkCard_data"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContactLinkCard_data"
    }
  ],
  "type": "Link",
  "abstractKey": null
};
})();
(node as any).hash = 'b0556ee362b9165f37375c871feae089';
export default node;
