import React, { useState, ReactElement, useEffect } from 'react'
import styled from 'styled-components'
import { Tooltip } from '@material-ui/core'

interface Props {
  onChildClick: () => boolean
  children: ReactElement
}

type CopyState = 'notCopied' | 'copied'

const Root = styled.div<{ $copyState: CopyState }>`
  .MuiTooltip-tooltip {
    ${(props) =>
      props.$copyState === 'copied' &&
      `
      background-color: ${props.theme.palette.success.main};
      color: #ffff;
    `}
  }

  .MuiTooltip-arrow {
    ${(props) =>
      props.$copyState === 'copied' &&
      `
      color: ${props.theme.palette.success.main};
    `}
  }
`

const CopyActionWrapper: React.FC<Props> = ({ children, onChildClick }) => {
  const [copyState, setCopyState] = useState<CopyState>('notCopied')
  const handleClick = () => {
    if (onChildClick()) {
      setCopyState('copied')
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (copyState === 'copied') {
        setCopyState('notCopied')
      }
    }, 1000)

    return () => clearTimeout(timer)
  }, [copyState])

  return (
    <Root $copyState={copyState}>
      <Tooltip
        // PopperProps={{
        //   disablePortal: true
        // }}
        placement='top'
        arrow
        title={copyState === 'notCopied' ? 'Click to copy' : 'Copied!'}
      >
        {React.cloneElement(children, { onClick: handleClick })}
      </Tooltip>
    </Root>
  )
}

export default CopyActionWrapper
