import React from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import { PreviewLiveShareQuery } from '../__generated__/PreviewLiveShareQuery.graphql'
import { useEntitySubscription } from '../hooks/useEntitySubscription'
import { useLazyLoadQuery } from 'react-relay/hooks'
import LiveShareShipmentShow from '../pages/LiveShareShipment/LiveShareShipmentShow'

interface Props {
  slug: string
}

// const PreviewLiveShare: React.FC<Props> = ({ slug }) => {
const PreviewLiveShare: React.FC<Props> = ({ slug }) => {
  const { liveShareShipmentShow: shipmentShow } = useLazyLoadQuery<PreviewLiveShareQuery>(query, { slug: slug })
  useEntitySubscription<PreviewLiveShareQuery>({
    entityType: 'shipments',
    entitySlug: slug,
    query: query,
    variables: { slug: slug },
  })

  return <LiveShareShipmentShow shipmentShow={shipmentShow} />
}

const query = graphql`
  query PreviewLiveShareQuery($slug: String!) {
    liveShareShipmentShow(slug: $slug) {
      ...LiveShareShipmentShow_shipmentShow
    }
  }
`

export default PreviewLiveShare
