import React, { useState } from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import { useFragment } from 'react-relay/hooks'
import { ContainerLocation_container$key, PhysicalStatusEnum } from '../__generated__/ContainerLocation_container.graphql'
import { Card, CardContent, Typography, DialogTitle, DialogActions, DialogContent } from '@material-ui/core'
import styled from 'styled-components'
import portRight from '../assets/public/container_location_icons/port-right.png'
import portLeft from '../assets/public/container_location_icons/port-left.png'
import ship from '../assets/public/container_location_icons/ship.png'
import truck from '../assets/public/container_location_icons/truck.png'
import warehouse from '../assets/public/container_location_icons/warehouse.png'
import discharge from '../assets/public/container_location_icons/discharge.png'
import useNiceMutation from '../mutations/useNiceMutation'
import { ContainerLocationChangeStatusMutation } from '../__generated__/ContainerLocationChangeStatusMutation.graphql'
import Button from './Button'
import LoadingDots from './LoadingDots'
import { Alert } from '@material-ui/lab'
import OdysseyDialog from './OdysseyDialog'
import StyledInput from './StyledInput'

interface Props {
  container: ContainerLocation_container$key
}

const StatusCardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-column-gap: 8px;
  margin-top: 16px;
  overflow: auto;
`
const StatusCardLabel = styled(Typography)`
  text-align: center;
  font-size: 12px;
  font-weight: 700;
`

const StatusCardImage = styled.img``

const StatusCard = styled.div<{ $mode: 'finished' | 'current' | 'pending' }>`
  padding: 16px 4px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  cursor: pointer;
  border: 2px solid transparent;
  background-color: ${(props) => props.theme.customPalette.background.stageCard[props.$mode]};

  ${StatusCardLabel} {
    color: ${(props) => props.theme.customPalette.text.stageCard[props.$mode]};
  }

  ${StatusCardImage} {
    filter: ${(props) => (props.$mode === 'pending' ? 'grayscale(100%)' : 'unset')};
  }

  &:hover {
    filter: brightness(0.95);
  }
`

const StatusCardIconContainer = styled.div`
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
`

const ContainerLocation: React.FC<Props> = ({ container: data }) => {
  const container = useFragment(fragment, data)

  const statusIconMap: { [key in PhysicalStatusEnum]: string } = {
    origin_port: portRight,
    shipped: ship,
    full_in_terminal: portLeft,
    full_in_transit: truck,
    in_warehouse: warehouse,
    discharge: discharge,
    empty_in_transit: truck,
    empty_in_terminal: portLeft,
    '%future added value': '',
    destination_port: portLeft,
    empty_in_depot: portRight,
    stowed: discharge,
  }

  const [commitStatusChange, statusChangeIsInFlight] = useNiceMutation<ContainerLocationChangeStatusMutation>(changeStatusMutation)
  const [clickedStatusIdx, setClickedStatusIdx] = useState<number>(-1)
  const clickedStatus = container.validPhysicalStatuses[clickedStatusIdx]
  const [statusChangeError, setStatusChangeError] = useState<null | string>(null)

  const [terminalDate, setTerminalDate] = useState(container.terminalDate || new Date().toISOString().slice(0, 10))
  const [dischargeDate, setDischargeDate] = useState(container.dischargeDate || new Date().toISOString().slice(0, 10))
  const [warehouseInDate, setWarehouseInDate] = useState(container.warehouseInDate || new Date().toISOString().slice(0, 10))

  const closeStatusChangeDialog = () => {
    setClickedStatusIdx(-1)
    setStatusChangeError(null)
  }

  const changeStatusTo = (newStatus: string) => {
    commitStatusChange({
      variables: {
        input: {
          slug: container.slug,
          physicalStatus: newStatus,
          changeDate:
            clickedStatus.value === 'full_in_terminal'
              ? terminalDate
              : clickedStatus.value === 'discharge'
              ? dischargeDate
              : clickedStatus.value === 'in_warehouse'
              ? warehouseInDate
              : null,
        },
      },
      onCompleted: (res, errors) => {
        if (errors) {
          setStatusChangeError(errors.map((err) => err.message).join(', '))
        } else {
          closeStatusChangeDialog()
        }
      },
    })
  }

  const currentStatusIdx = container.validPhysicalStatuses.findIndex((st) => st.value === container.physicalStatus)

  return (
    <>
      <Card variant='outlined'>
        <CardContent>
          <Typography variant='h6'>Container Location</Typography>
          <StatusCardsContainer>
            {container.validPhysicalStatuses.map((status, idx) => (
              <StatusCard
                key={status.value}
                $mode={idx === currentStatusIdx ? 'current' : idx < currentStatusIdx ? 'finished' : 'pending'}
                onClick={() => setClickedStatusIdx(idx)}
              >
                <StatusCardIconContainer>
                  <StatusCardImage src={statusIconMap[status.value]} />
                </StatusCardIconContainer>
                <StatusCardLabel variant='body1'>{status.title}</StatusCardLabel>
              </StatusCard>
            ))}
          </StatusCardsContainer>
        </CardContent>
      </Card>
      <OdysseyDialog open={!!clickedStatus} onClose={closeStatusChangeDialog}>
        {clickedStatus && (
          <>
            <DialogTitle>Change location to {clickedStatus.title}?</DialogTitle>
            {clickedStatus.value === 'full_in_terminal' && (
              <DialogContent>
                Select Terminal in date:
                <StyledInput
                  type='date'
                  value={terminalDate}
                  placeholder='Terminal Date'
                  onChange={(e) => setTerminalDate(e.target.value)}
                ></StyledInput>
              </DialogContent>
            )}
            {clickedStatus.value === 'discharge' && (
              <DialogContent>
                Select Discharge date:
                <StyledInput
                  type='date'
                  value={dischargeDate}
                  placeholder='Discharge Date'
                  onChange={(e) => setDischargeDate(e.target.value)}
                ></StyledInput>
              </DialogContent>
            )}
            {clickedStatus.value === 'in_warehouse' && (
              <DialogContent>
                Select warehouse in date:
                <StyledInput
                  type='date'
                  value={warehouseInDate}
                  placeholder='Warehouse Date'
                  onChange={(e) => setWarehouseInDate(e.target.value)}
                ></StyledInput>
              </DialogContent>
            )}
            {statusChangeError && <Alert severity='error'>{statusChangeError}</Alert>}
            <DialogActions>
              <Button onClick={closeStatusChangeDialog}>Cancel</Button>
              <Button variant='contained' onClick={() => changeStatusTo(clickedStatus.value)}>
                {statusChangeIsInFlight ? <LoadingDots variant='light' /> : 'OK'}
              </Button>
            </DialogActions>
          </>
        )}
      </OdysseyDialog>
    </>
  )
}

const fragment = graphql`
  fragment ContainerLocation_container on Container {
    slug
    physicalStatus
    dischargeDate
    terminalDate
    warehouseInDate
    validPhysicalStatuses {
      value
      title
    }
  }
`

const changeStatusMutation = graphql`
  mutation ContainerLocationChangeStatusMutation($input: infoChangeContainerPhysicalStatusInput!) {
    infoChangeContainerPhysicalStatus(input: $input) {
      container {
        ...ContainerLocation_container
      }
    }
  }
`

export default ContainerLocation
