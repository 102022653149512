/* eslint-disable */

import { max } from 'd3-array'
import { axisBottom, axisLeft } from 'd3-axis'
import { format, formatLocale } from 'd3-format'
import { scaleBand, scaleLinear } from 'd3-scale'
import { mouse, select, selectAll } from 'd3-selection'
import rough from 'roughjs/bundled/rough.esm.js'
import get from 'lodash.get'
import Chart from './Chart'
import { roughCeiling } from './utils/roughCeiling'
import { timeFormat } from 'd3-time-format'


class Bar extends Chart {
  constructor(opts) {
    super(opts)

    // load in arguments from config object
    this.data = opts.data
    this.margin = opts.margin || { top: 8, right: 16, bottom: 64, left: 96 }
    this.revenueColor = get(opts, 'color', 'green')
    this.shipmentCostColor = get(opts, 'color', 'orange')
    this.containerCostColor = get(opts, 'color', 'red')
    this.highlight = get(opts, 'highlight', 'coral')
    this.roughness = roughCeiling({ roughness: opts.roughness })
    this.stroke = get(opts, 'stroke', 'black')
    this.strokeWidth = get(opts, 'strokeWidth', 1)
    this.axisStrokeWidth = get(opts, 'axisStrokeWidth', 0.5)
    this.axisRoughness = get(opts, 'axisRoughness', 0.5)
    this.innerStrokeWidth = get(opts, 'innerStrokeWidth', 1)
    this.fillWeight = get(opts, 'fillWeight', 0.5)
    this.axisFontSize = opts.axisFontSize
    this.labelsKey = 'labels'
    this.paths = this.dataFormat === 'object' ? 'paths' : opts.paths
    this.values = this.dataFormat === 'object' ? 'values' : opts.values
    this.xValueFormat = opts.xValueFormat
    this.xValueFormatType = opts.xValueFormatType || 'number'
    this.yValueFormat = opts.yValueFormat
    this.padding = get(opts, 'padding', 0.1)
    this.xLabel = get(opts, 'xLabel', '')
    this.yLabel = get(opts, 'yLabel', '')
    this.labelFontSize = get(opts, 'labelFontSize', '1rem')
    this.d3FormatWithLocale = formatLocale({
      "decimal": ".",
      "thousands": ",",
      "grouping": [3],
      "currency": ["", "\u00a0€"]
    })
    this.currencyFormat = this.d3FormatWithLocale.format("$,.2~f")
    // new width
    this.initChartValues(opts)
    // resolve font
    this.resolveFont()
    // create the chart
    this.drawChart = this.resolveData(opts.data)
    this.drawChart()
    if (opts.title !== 'undefined') this.setTitle(opts.title)
  }

  initChartValues(opts) {
    const width = opts.width ? opts.width : 900
    const height = opts.height ? opts.height : 300
    this.width = width - this.margin.left - this.margin.right
    this.height = height - this.margin.top - this.margin.bottom
    this.roughId = this.el + '_svg'
    this.graphClass = this.el.substring(1, this.el.length)
    this.interactionG = 'g.' + this.graphClass
    this.setSvg()
  }

  // add this to abstract base
  resolveData(data) {
    return () => {
      this.data = data
      this.drawFromObject()
    }
  }

  addScales() {
    const that = this

    this.xScale = scaleBand()
      .rangeRound([0, this.width])
      .padding(this.padding)
      .domain(this.data[that.labelsKey])

    this.yScale = scaleLinear()
      .rangeRound([this.height, 0])
      .domain([0, max(this.data.values, d => +d.revenue)])
  }

  addLabels() {
    // xLabel
    if (this.xLabel !== '') {
      this.svg
        .append('text')
        .attr('x', this.width / 2)
        .attr('y', this.height + this.margin.bottom / 2)
        .attr('dx', '1em')
        .attr('class', 'labelText')
        .style('text-anchor', 'middle')
        .style('font-family', this.fontFamily)
        .style('font-size', this.labelFontSize)
        .text(this.xLabel)
    }
    // yLabel
    if (this.yLabel !== '') {
      this.svg
        .append('text')
        .attr('transform', 'rotate(-90)')
        .attr('y', 0 - this.margin.left)
        .attr('x', 0 - this.height / 2)
        .attr('dy', '1em')
        .attr('class', 'labelText')
        .style('text-anchor', 'middle')
        .style('font-family', this.fontFamily)
        .style('font-size', this.labelFontSize)
        .text(this.yLabel)
    }
  }

  addAxes() {
    const xAxis = axisBottom(this.xScale)
      .tickSize(0)
      .tickFormat(d => {
        return this.xValueFormat ? (this.xValueFormatType === 'time' ? timeFormat(this.xValueFormat)(new Date(d)) : format(this.xValueFormat)(d)) : d
      })

    const yAxis = axisLeft(this.yScale)
      .tickSize(0)
      .tickFormat(d => {
        return this.currencyFormat(d)
      })

    // x-axis
    this.svg
      .append('g')
      .attr('transform', 'translate(0,' + this.height + ')')
      .call(xAxis)
      .attr('class', `xAxis${this.graphClass}`)
      .selectAll('text')
      .attr('transform', this.data['values'].length > 10 ? 'translate(-10,0)rotate(-45)' : `translate(0, 0)`)
      .style('text-anchor', this.data['values'].length > 10 ? 'end' : 'middle')
      .style('font-family', this.fontFamily)
      .style('font-size', this.axisFontSize === undefined ? `${Math.min(0.8, Math.min(this.width, this.height) / 140)}rem` : this.axisFontSize)
      .style('opacity', 0.9)

    // y-axis
    this.svg
      .append('g')
      .call(yAxis)
      .attr('class', `yAxis${this.graphClass}`)
      .selectAll('text')
      .style('font-family', this.fontFamily)
      .style('font-size', this.axisFontSize === undefined ? `${Math.min(0.95, Math.min(this.width, this.height) / 140)}rem` : this.axisFontSize)
      .style('opacity', 0.9)

    // hide original axes
    selectAll('path.domain').attr('stroke', 'transparent')
  }

  makeAxesRough(roughSvg, rcAxis) {
    const xAxisClass = `xAxis${this.graphClass}`
    const yAxisClass = `yAxis${this.graphClass}`
    const roughXAxisClass = `rough-${xAxisClass}`
    const roughYAxisClass = `rough-${yAxisClass}`

    select(`.${xAxisClass}`)
      .selectAll('path.domain')
      .each(function (d, i) {
        const pathD = select(this)
          .node()
          .getAttribute('d')
        const roughXAxis = rcAxis.path(pathD, {
          fillStyle: 'hachure'
        })
        roughXAxis.setAttribute('class', roughXAxisClass)
        roughSvg.appendChild(roughXAxis)
      })
    selectAll(`.${roughXAxisClass}`).attr('transform', `translate(0, ${this.height})`)

    select(`.${yAxisClass}`)
      .selectAll('path.domain')
      .each(function (d, i) {
        const pathD = select(this)
          .node()
          .getAttribute('d')
        const roughYAxis = rcAxis.path(pathD, {
          fillStyle: 'hachure'
        })
        roughYAxis.setAttribute('class', roughYAxisClass)
        roughSvg.appendChild(roughYAxis)
      })
  }

  setTitle(title) {
    this.svg
      .append('text')
      .attr('x', this.width / 2)
      .attr('y', 0 - this.margin.top / 2)
      .attr('class', 'title')
      .attr('text-anchor', 'middle')
      .style('font-size', this.titleFontSize === undefined ? `${Math.min(40, Math.min(this.width, this.height) / 5)}px` : this.titleFontSize)
      .style('font-family', this.fontFamily)
      .style('opacity', 0.8)
      .text(title)
  }

  addInteraction() {
    selectAll(this.interactionG)
      .filter('[attrName=revenue]')
      .data(this.data.values)
      .append('rect')
      .attr('x', (d, i) => this.datumToBars(d, i).revenue.x)
      .attr('y', (d, i) => this.datumToBars(d, i).revenue.y)
      .attr('width', (d, i) => this.datumToBars(d, i).revenue.width)
      .attr('height', (d, i) => this.datumToBars(d, i).revenue.height)
      .attr('fill', 'transparent')

    selectAll(this.interactionG)
      .filter('[attrName=shipmentCost]')
      .data(this.data.values)
      .append('rect')
      .attr('x', (d, i) => this.datumToBars(d, i).shipmentCost.x)
      .attr('y', (d, i) => this.datumToBars(d, i).shipmentCost.y)
      .attr('width', (d, i) => this.datumToBars(d, i).shipmentCost.width)
      .attr('height', (d, i) => this.datumToBars(d, i).shipmentCost.height)
      .attr('fill', 'transparent')

    selectAll(this.interactionG)
      .filter('[attrName=containerCost]')
      .data(this.data.values)
      .append('rect')
      .attr('x', (d, i) => this.datumToBars(d, i).containerCost.x)
      .attr('y', (d, i) => this.datumToBars(d, i).containerCost.y)
      .attr('width', (d, i) => this.datumToBars(d, i).containerCost.width)
      .attr('height', (d, i) => this.datumToBars(d, i).containerCost.height)
      .attr('fill', 'transparent')

    // create tooltip
    const Tooltip = select(this.el)
      .append('div')
      .style('opacity', 0)
      .attr('class', 'tooltip')
      .style('position', 'absolute')
      .style('background-color', 'white')
      .style('border', 'solid')
      .style('border-width', '1px')
      .style('border-radius', '5px')
      .style('padding', '3px')
      .style('z-index', '99')
      .style('font-family', this.fontFamily)
      .style('font-size', this.tooltipFontSize)
      .style('pointer-events', 'none')

    // event functions
    const mouseover = function (d) {
      Tooltip.style('opacity', 1)
    }
    const that = this

    const mousemove = function (d) {
      const attrX = select(this).attr('attrX')
      const attrY = select(this).attr('attrY')
      const attrName = select(this).attr('attrName')
      const mousePos = mouse(this)
      const tooltipTitle = {
        revenue: 'Revenue',
        shipmentCost: 'Shipments Cost',
        containerCost: 'Container Cost',
      }
      // get size of enclosing div
      Tooltip.html(`<b>${tooltipTitle[attrName]}</b>: ${that.currencyFormat(attrY)}</br>${attrX}`)
        .style('opacity', 0.95)
        .style('left', `${mousePos[0] + that.margin.left + 48}px`)
        .style('top', `${mousePos[1] + that.margin.top}px`)
        .style('transform', mousePos[0] > this.width / 2 ? `translate(-100%)` : null)
    }
    const mouseleave = function (d) {
      Tooltip.style('opacity', 0)
    }

    // d3 event handlers
    selectAll(this.interactionG).on('mouseover', function () {
      mouseover()
      const path = this.getAttribute('attrZ')
      if (path !== 'undefined')
        select(this)
          .style('cursor', 'pointer')
      // select(this)
      //   .select('path')
      //   .style('stroke', that.highlight)
      select(this)
        .selectAll('path:nth-child(2)')
        .style('stroke-width', that.strokeWidth + 1.2)
    })

    selectAll(this.interactionG).on('mouseout', function () {
      mouseleave()
      select(this)
        .select('path')
        // .style('stroke', that.color)
      select(this)
        .selectAll('path:nth-child(2)')
        .style('stroke-width', that.strokeWidth)
    })

    selectAll(this.interactionG).on('mousemove', mousemove)
    selectAll(this.interactionG).on('click', function () {
      const path = this.getAttribute('attrZ')
      if (path !== 'undefined')
        that.onClick(path)
      else
        return null
    })
  }

  initRoughObjects() {
    this.roughSvg = document.getElementById(this.roughId)
    this.rcAxis = rough.svg(this.roughSvg, {
      options: {
        strokeWidth: this.axisStrokeWidth,
        roughness: this.axisRoughness
      }
    })
    this.rc = rough.svg(this.roughSvg, {
      options: {
        stroke: this.stroke === 'none' ? undefined : this.stroke,
        strokeWidth: this.innerStrokeWidth,
        roughness: this.roughness,
        bowing: this.bowing,
        fillStyle: this.fillStyle
      }
    })
  }

  datumToBars(d, idx) {
    return {
      revenue: {
        x: this.xScale(this.data[this.labelsKey][idx]),
        y: this.yScale(+d.revenue),
        width: this.xScale.bandwidth() / 2,
        height: this.height - this.yScale(+d.revenue)
      },
      shipmentCost: {
        x: this.xScale(this.data[this.labelsKey][idx]) + (this.xScale.bandwidth() / 2),
        y: this.yScale(+d.shipmentCost),
        width: this.xScale.bandwidth() / 2,
        height: this.height - this.yScale(+d.shipmentCost)
      },
      containerCost: {
        x: this.xScale(this.data[this.labelsKey][idx]) + (this.xScale.bandwidth() / 2),
        y: this.yScale(d.containerCost + d.shipmentCost),
        width: this.xScale.bandwidth() / 2,
        height: this.height - this.yScale(+d.containerCost),
      },
    }
  }

  drawFromObject() {
    this.initRoughObjects()
    this.addScales()
    this.addAxes()
    this.makeAxesRough(this.roughSvg, this.rcAxis)
    this.addLabels()

    // Add barplot
    this.data.values.forEach((d, i) => {
      const bars = this.datumToBars(d, i)

      const revenueNode = this.rc.rectangle(
        bars.revenue.x,
        bars.revenue.y,
        bars.revenue.width,
        bars.revenue.height,
        {
          fill: this.revenueColor,
          simplification: this.simplification,
          fillWeight: this.fillWeight
        }
      )
      const roughRevenueNode = this.roughSvg.appendChild(revenueNode)
      roughRevenueNode.setAttribute('class', this.graphClass)
      roughRevenueNode.setAttribute('attrX', this.data[this.labelsKey][i])
      roughRevenueNode.setAttribute('attrZ', this.data[this.paths][i])
      roughRevenueNode.setAttribute('attrY', +d.revenue)
      roughRevenueNode.setAttribute('attrName', 'revenue')


      const shipmentCostNode = this.rc.rectangle(
        bars.shipmentCost.x,
        bars.shipmentCost.y,
        bars.shipmentCost.width,
        bars.shipmentCost.height,
        {
          fill: this.shipmentCostColor,
          simplification: this.simplification,
          fillWeight: this.fillWeight,
        }
      )
      const roughShipmentCostNode = this.roughSvg.appendChild(shipmentCostNode)
      roughShipmentCostNode.setAttribute('class', this.graphClass)
      roughShipmentCostNode.setAttribute('attrX', this.data[this.labelsKey][i])
      roughShipmentCostNode.setAttribute('attrZ', this.data[this.paths][i])
      roughShipmentCostNode.setAttribute('attrY', +d.shipmentCost)
      roughShipmentCostNode.setAttribute('attrName', 'shipmentCost')



      const containerCostNode = this.rc.rectangle(
        bars.containerCost.x,
        bars.containerCost.y,
        bars.containerCost.width,
        bars.containerCost.height,
        {
          fill: this.containerCostColor,
          simplification: this.simplification,
          fillWeight: this.fillWeight,
        }
      )
      const roughContainerCostNode = this.roughSvg.appendChild(containerCostNode)
      roughContainerCostNode.setAttribute('class', this.graphClass)
      roughContainerCostNode.setAttribute('attrX', this.data[this.labelsKey][i])
      roughContainerCostNode.setAttribute('attrZ', this.data[this.paths][i])
      roughContainerCostNode.setAttribute('attrY', +d.containerCost)
      roughContainerCostNode.setAttribute('attrName', 'containerCost')

    })

    selectAll(this.interactionG)
      .selectAll('path:nth-child(2)')
      .style('stroke-width', this.strokeWidth)
    // If desired, add interactivity
    if (this.interactive === true) {
      this.addInteraction()
    }
  } // draw
}

export default Bar
