/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type RegistrationStatus = "invited" | "not_invited" | "registered" | "%future added value";
export type ContactShowHeroTabBar_contact = {
    readonly slug: string;
    readonly canEdit: boolean;
    readonly registrationStatus: RegistrationStatus;
    readonly invitation: {
        readonly url: string;
        readonly sentAt: string;
    } | null;
    readonly user: {
        readonly createdAt: string;
    } | null;
    readonly revokeAccess: boolean;
    readonly " $refType": "ContactShowHeroTabBar_contact";
};
export type ContactShowHeroTabBar_contact$data = ContactShowHeroTabBar_contact;
export type ContactShowHeroTabBar_contact$key = {
    readonly " $data"?: ContactShowHeroTabBar_contact$data;
    readonly " $fragmentRefs": FragmentRefs<"ContactShowHeroTabBar_contact">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContactShowHeroTabBar_contact",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canEdit",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registrationStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Invitation",
      "kind": "LinkedField",
      "name": "invitation",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sentAt",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdAt",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "revokeAccess",
      "storageKey": null
    }
  ],
  "type": "Contact",
  "abstractKey": null
};
(node as any).hash = '9d82c66c89eae5ecb1168fa4c206f015';
export default node;
