import { Paper, Typography, Avatar } from '@material-ui/core'
import { graphql } from 'babel-plugin-relay/macro'
import React, { useContext, useState } from 'react'
import { useFragment } from 'react-relay/hooks'
import styled from 'styled-components'
import Button from './Button'
import LiveRequestAddDialog from './LiveRequestAddDialog'
import { LiveRequest_liveRequests$key } from '../__generated__/LiveRequest_liveRequests.graphql'
import LiveRequestDrawer from './LiveRequestDrawer'
import { TicketEntityTypeEnum } from '../__generated__/LiveRequestAddDialogMutation.graphql'
import SessionContext from '../SessionContext'
import ExternalLiveRequestAddDialog from './ExternalLiveRequestAddDialog'
import { parse } from 'qs'
import { useDateTimeDisplay } from '../hooks/timeZoneDisplay'
interface Props {
  liveRequests: LiveRequest_liveRequests$key
  entitySlug: string
  entityType: string
}

const Root = styled(Paper)`
  padding-top: 16px;
  margin-bottom: 16px;
`
const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 16px;
  margin-bottom: 8px;
`

const LiveRequestContainer = styled.div`
  padding: 10px 8px 8px 0;
  white-space: pre;
  overflow: hidden;
  background-color: ${(props) => props.theme.palette.background.paper};
  flex: 1;
`

const NonOverflowText = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
`

const TitleText = styled(NonOverflowText)`
  line-height: 1;
  text-transform: capitalize;
`

const FullDivLink = styled.div`
  cursor: pointer;
  background-color: ${(props) => props.theme.palette.background.paper};

  &:hover {
    filter: brightness(0.95);
  }
`

const StyledDivLink = styled.div`
  display: flex;
  padding: 10px 16px;
  cursor: pointer;
  background-color: ${(props) => props.theme.palette.background.paper};

  &:hover {
    filter: brightness(0.995);
  }
`

const SubTitle = styled(Typography)`
  display: inline-block;
  font-style: oblique;
  font-size: 0.77em;
`

const LinkAvatarContainer = styled.div`
  padding: 0 10px 0 10px;
  height: 100%;
  white-space: pre;
`

const LinkAvatar = styled(Avatar)`
  width: 50px;
  height: 50px;
`

const NoDataText = styled(Typography)`
  color: ${(props) => props.theme.customPalette.text.noData};
  text-align: center;
  padding: 16px;
  font-weight: 400;
`

export const TicketStatus = styled.span<{ $status: string }>`
  color: ${(props) => {
    switch (props.$status) {
      case 'open':
        return props.theme.customPalette.ticketStatusOpen.color
      case 'resolved':
        return props.theme.customPalette.ticketStatusResolved.color
      case 'closed':
        return props.theme.customPalette.ticketStatusClosed.color
      default:
        return props.theme.palette.primary.main
    }
  }};
  font-weight: bold;
`

const LiveRequest: React.FC<Props> = ({ liveRequests: data, entitySlug, entityType }) => {
  const { user } = useContext(SessionContext)
  const liveRequestConnection = useFragment(fragment, data)
  const queryVariables = parse(location.search, { ignoreQueryPrefix: true })

  const [newLiveRequestOpen, setNewLiveRequestOpen] = useState(false)
  const [openLiveShareSlug, setOpenLiveShareSlug] = useState(queryVariables.ticket_slug || '')
  const closeDrawer = () => {
    setOpenLiveShareSlug('')
  }
  return (
    <>
      <Root variant='outlined'>
        <TitleContainer>
          <Typography variant='h6'>Live Request</Typography>
          <Button onClick={() => setNewLiveRequestOpen(true)}>New Request</Button>
        </TitleContainer>
        {liveRequestConnection.nodes?.length === 0 && <NoDataText variant='h6'>No Live Request</NoDataText>}
        {liveRequestConnection.nodes?.map((request) => {
          if (request)
            return (
              <>
                <FullDivLink onClick={() => setOpenLiveShareSlug(request.slug)}>
                  <StyledDivLink>
                    <LinkAvatarContainer>
                      <LinkAvatar alt={request.raisedFor?.name || ''}>{(request.raisedFor?.name || '').slice(0, 1).toUpperCase()}</LinkAvatar>
                    </LinkAvatarContainer>
                    <LiveRequestContainer>
                      <TitleText variant='body1'>
                        {request.title.replace('poa', 'POA')} ({request.raisedFor?.name})&nbsp;&nbsp;
                        <TicketStatus $status={request.status}>{request.displayStatus}</TicketStatus>
                      </TitleText>
                      <SubTitle variant='subtitle2'>
                        Raised by {request.raisedBy.name} {useDateTimeDisplay(request.raisedAt)}
                      </SubTitle>
                    </LiveRequestContainer>
                  </StyledDivLink>
                </FullDivLink>

                <LiveRequestDrawer
                  key={request.slug}
                  slug={request.slug}
                  open={request.slug === openLiveShareSlug}
                  onClose={() => closeDrawer()}
                />
              </>
            )
        })}
      </Root>
      <LiveRequestAddDialog
        open={newLiveRequestOpen && user?.contact?.contactType === 'internal'}
        title='Raise new ticket'
        onClose={() => setNewLiveRequestOpen(false)}
        onEdit={() => setNewLiveRequestOpen(false)}
        entitySlug={entitySlug.toString()}
        entityType={entityType as TicketEntityTypeEnum}
      />
      <ExternalLiveRequestAddDialog
        open={newLiveRequestOpen && user?.contact?.contactType === 'external'}
        title='Raise new ticket'
        onClose={() => setNewLiveRequestOpen(false)}
        onEdit={() => setNewLiveRequestOpen(false)}
        entitySlug={entitySlug.toString()}
        entityType={entityType as TicketEntityTypeEnum}
      />
    </>
  )
}

const fragment = graphql`
  fragment LiveRequest_liveRequests on TicketConnection {
    nodes {
      slug
      title
      status
      direction
      ticketType
      absoluteSlugs
      raisedAt
      displayStatus
      raisedBy {
        name
      }
      raisedFor {
        name
      }
    }
  }
`
export default LiveRequest
