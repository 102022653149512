/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type DraftContactShowQueryVariables = {
    slug: string;
};
export type DraftContactShowQueryResponse = {
    readonly draftContactShow: {
        readonly format: string | null;
        readonly address: string | null;
        readonly postcode: string | null;
        readonly place: string | null;
        readonly company: string | null;
        readonly country: string | null;
        readonly language: string | null;
        readonly businessPhone: string;
        readonly state: string | null;
        readonly name: string;
        readonly image: {
            readonly " $fragmentRefs": FragmentRefs<"ItemImage_data">;
        };
        readonly keyFields: ReadonlyArray<{
            readonly key: string;
            readonly " $fragmentRefs": FragmentRefs<"KeyField_data">;
        }>;
        readonly lines: ReadonlyArray<{
            readonly key: string;
            readonly " $fragmentRefs": FragmentRefs<"SemanticLine_data">;
        }>;
    };
};
export type DraftContactShowQuery = {
    readonly response: DraftContactShowQueryResponse;
    readonly variables: DraftContactShowQueryVariables;
};



/*
query DraftContactShowQuery(
  $slug: String!
) {
  draftContactShow(slug: $slug) {
    format
    address
    postcode
    place
    company
    country
    language
    businessPhone
    state
    name
    image {
      ...ItemImage_data
    }
    keyFields {
      key
      ...KeyField_data
    }
    lines {
      key
      ...SemanticLine_data
    }
    id
  }
}

fragment ItemImage_data on Image {
  url
  alt
  highlight
  shape
  overlay
  overlayIcon
}

fragment KeyField_data on KeyField {
  label
  value {
    key
    ...SemanticString_data
  }
}

fragment SemanticLine_data on SemanticLine {
  parts {
    key
    ...SemanticString_data
  }
}

fragment SemanticString_data on SemanticString {
  text
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "format",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "postcode",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "place",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "company",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "country",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "language",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "businessPhone",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v13 = [
  (v12/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "text",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DraftContactShowQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DraftContact",
        "kind": "LinkedField",
        "name": "draftContactShow",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Image",
            "kind": "LinkedField",
            "name": "image",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ItemImage_data"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "KeyField",
            "kind": "LinkedField",
            "name": "keyFields",
            "plural": true,
            "selections": [
              (v12/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "KeyField_data"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SemanticLine",
            "kind": "LinkedField",
            "name": "lines",
            "plural": true,
            "selections": [
              (v12/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SemanticLine_data"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DraftContactShowQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DraftContact",
        "kind": "LinkedField",
        "name": "draftContactShow",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Image",
            "kind": "LinkedField",
            "name": "image",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "url",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "alt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "highlight",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "shape",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "overlay",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "overlayIcon",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "KeyField",
            "kind": "LinkedField",
            "name": "keyFields",
            "plural": true,
            "selections": [
              (v12/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "label",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SemanticString",
                "kind": "LinkedField",
                "name": "value",
                "plural": true,
                "selections": (v13/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SemanticLine",
            "kind": "LinkedField",
            "name": "lines",
            "plural": true,
            "selections": [
              (v12/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SemanticString",
                "kind": "LinkedField",
                "name": "parts",
                "plural": true,
                "selections": (v13/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c53e0b6ff616302d6045d2ff3616256b",
    "id": null,
    "metadata": {},
    "name": "DraftContactShowQuery",
    "operationKind": "query",
    "text": "query DraftContactShowQuery(\n  $slug: String!\n) {\n  draftContactShow(slug: $slug) {\n    format\n    address\n    postcode\n    place\n    company\n    country\n    language\n    businessPhone\n    state\n    name\n    image {\n      ...ItemImage_data\n    }\n    keyFields {\n      key\n      ...KeyField_data\n    }\n    lines {\n      key\n      ...SemanticLine_data\n    }\n    id\n  }\n}\n\nfragment ItemImage_data on Image {\n  url\n  alt\n  highlight\n  shape\n  overlay\n  overlayIcon\n}\n\nfragment KeyField_data on KeyField {\n  label\n  value {\n    key\n    ...SemanticString_data\n  }\n}\n\nfragment SemanticLine_data on SemanticLine {\n  parts {\n    key\n    ...SemanticString_data\n  }\n}\n\nfragment SemanticString_data on SemanticString {\n  text\n}\n"
  }
};
})();
(node as any).hash = '8c1bcf9a037726813ea249517e75c83a';
export default node;
