/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AttachmentEntityTypeEnum = "contacts" | "containers" | "shipments" | "%future added value";
export type infoUpdateAttachmentDetailsInput = {
    clientMutationId?: string | null;
    entityType: AttachmentEntityTypeEnum;
    entitySlug: string;
    attachmentSlug: string;
    name: string;
    documentType?: string | null;
};
export type EntityAttachmentListPreviewerDetailsMutationVariables = {
    input: infoUpdateAttachmentDetailsInput;
};
export type EntityAttachmentListPreviewerDetailsMutationResponse = {
    readonly infoUpdateAttachmentDetails: {
        readonly attachment: {
            readonly " $fragmentRefs": FragmentRefs<"EntityAttachment_attachment">;
        };
    } | null;
};
export type EntityAttachmentListPreviewerDetailsMutation = {
    readonly response: EntityAttachmentListPreviewerDetailsMutationResponse;
    readonly variables: EntityAttachmentListPreviewerDetailsMutationVariables;
};



/*
mutation EntityAttachmentListPreviewerDetailsMutation(
  $input: infoUpdateAttachmentDetailsInput!
) {
  infoUpdateAttachmentDetails(input: $input) {
    attachment {
      ...EntityAttachment_attachment
      id
    }
  }
}

fragment EntityAttachment_attachment on EntityAttachment {
  url
  downloadUrl
  name
  contentType
  previewUrl
  documentType
  draftTicketStatus
  lexOfficeInvoiceStatus
  signedStatus
  rejectReason
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EntityAttachmentListPreviewerDetailsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "infoUpdateAttachmentDetailsPayload",
        "kind": "LinkedField",
        "name": "infoUpdateAttachmentDetails",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EntityAttachment",
            "kind": "LinkedField",
            "name": "attachment",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "EntityAttachment_attachment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ApplicationCommand",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EntityAttachmentListPreviewerDetailsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "infoUpdateAttachmentDetailsPayload",
        "kind": "LinkedField",
        "name": "infoUpdateAttachmentDetails",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EntityAttachment",
            "kind": "LinkedField",
            "name": "attachment",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "url",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "downloadUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "contentType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "previewUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "documentType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "draftTicketStatus",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lexOfficeInvoiceStatus",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "signedStatus",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "rejectReason",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "08583a83d9c7e3af0a4d669767fd26be",
    "id": null,
    "metadata": {},
    "name": "EntityAttachmentListPreviewerDetailsMutation",
    "operationKind": "mutation",
    "text": "mutation EntityAttachmentListPreviewerDetailsMutation(\n  $input: infoUpdateAttachmentDetailsInput!\n) {\n  infoUpdateAttachmentDetails(input: $input) {\n    attachment {\n      ...EntityAttachment_attachment\n      id\n    }\n  }\n}\n\nfragment EntityAttachment_attachment on EntityAttachment {\n  url\n  downloadUrl\n  name\n  contentType\n  previewUrl\n  documentType\n  draftTicketStatus\n  lexOfficeInvoiceStatus\n  signedStatus\n  rejectReason\n}\n"
  }
};
})();
(node as any).hash = '282ab2596c040bbcf450436bb2b4c003';
export default node;
