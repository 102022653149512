/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type TicketEntityTypeEnum = "containers" | "shipments" | "%future added value";
export type infoAddDraftContactInput = {
    clientMutationId?: string | null;
    ticketType: string;
    format: string;
    salutation: string;
    firstName: string;
    lastName: string;
    email?: string | null;
    businessPhone?: string | null;
    company?: string | null;
    country: string;
    place?: string | null;
    postcode?: string | null;
    address?: string | null;
    state?: string | null;
    language?: string | null;
    roles: Array<string>;
    entitySlug: string;
    entityType: TicketEntityTypeEnum;
    eori?: string | null;
    vat?: string | null;
};
export type DraftContactAddMutationVariables = {
    input: infoAddDraftContactInput;
};
export type DraftContactAddMutationResponse = {
    readonly infoAddDraftContact: {
        readonly clientMutationId: string | null;
    } | null;
};
export type DraftContactAddMutation = {
    readonly response: DraftContactAddMutationResponse;
    readonly variables: DraftContactAddMutationVariables;
};



/*
mutation DraftContactAddMutation(
  $input: infoAddDraftContactInput!
) {
  infoAddDraftContact(input: $input) {
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "infoAddDraftContactPayload",
    "kind": "LinkedField",
    "name": "infoAddDraftContact",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DraftContactAddMutation",
    "selections": (v1/*: any*/),
    "type": "ApplicationCommand",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DraftContactAddMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "49c937272e3e1617d3e919c35575fd76",
    "id": null,
    "metadata": {},
    "name": "DraftContactAddMutation",
    "operationKind": "mutation",
    "text": "mutation DraftContactAddMutation(\n  $input: infoAddDraftContactInput!\n) {\n  infoAddDraftContact(input: $input) {\n    clientMutationId\n  }\n}\n"
  }
};
})();
(node as any).hash = 'ae148db5c7a8b9e09d4a355f70cb3f0a';
export default node;
