/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ListItemTypeEnum = "containers" | "external_contacts" | "internal_contacts" | "item" | "shipments" | "tickets" | "vessels" | "voyages" | "%future added value";
export type FilterInput = {
    key: string;
    values: Array<string>;
};
export type ItemListHeaderQueryVariables = {
    searchQuery?: string | null;
    type?: ListItemTypeEnum | null;
    filters?: Array<FilterInput> | null;
    includeArchived?: boolean | null;
    sortKey?: string | null;
    page?: number | null;
    perPage?: number | null;
    handoverDateStart?: string | null;
    handoverDateEnd?: string | null;
    voyageEtaStart?: string | null;
    voyageEtaEnd?: string | null;
    dischargeDateStart?: string | null;
    dischargeDateEnd?: string | null;
};
export type ItemListHeaderQueryResponse = {
    readonly itemList: {
        readonly " $fragmentRefs": FragmentRefs<"ItemListHeader_data">;
    };
};
export type ItemListHeaderQuery = {
    readonly response: ItemListHeaderQueryResponse;
    readonly variables: ItemListHeaderQueryVariables;
};



/*
query ItemListHeaderQuery(
  $searchQuery: String
  $type: ListItemTypeEnum
  $filters: [FilterInput!]
  $includeArchived: Boolean
  $sortKey: String
  $page: Int
  $perPage: Int
  $handoverDateStart: Date
  $handoverDateEnd: Date
  $voyageEtaStart: Date
  $voyageEtaEnd: Date
  $dischargeDateStart: Date
  $dischargeDateEnd: Date
) {
  itemList(searchQuery: $searchQuery, type: $type, filters: $filters, includeArchived: $includeArchived, sortKey: $sortKey, page: $page, perPage: $perPage, handoverDateStart: $handoverDateStart, handoverDateEnd: $handoverDateEnd, voyageEtaStart: $voyageEtaStart, voyageEtaEnd: $voyageEtaEnd, dischargeDateStart: $dischargeDateStart, dischargeDateEnd: $dischargeDateEnd) {
    ...ItemListHeader_data
  }
}

fragment ItemListHeader_data on ItemList {
  sortKeys {
    key
    title
  }
  totalCount
  downloadToken
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "dischargeDateEnd"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "dischargeDateStart"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "handoverDateEnd"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "handoverDateStart"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includeArchived"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "page"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "perPage"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchQuery"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sortKey"
},
v10 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "type"
},
v11 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "voyageEtaEnd"
},
v12 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "voyageEtaStart"
},
v13 = [
  {
    "kind": "Variable",
    "name": "dischargeDateEnd",
    "variableName": "dischargeDateEnd"
  },
  {
    "kind": "Variable",
    "name": "dischargeDateStart",
    "variableName": "dischargeDateStart"
  },
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  {
    "kind": "Variable",
    "name": "handoverDateEnd",
    "variableName": "handoverDateEnd"
  },
  {
    "kind": "Variable",
    "name": "handoverDateStart",
    "variableName": "handoverDateStart"
  },
  {
    "kind": "Variable",
    "name": "includeArchived",
    "variableName": "includeArchived"
  },
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page"
  },
  {
    "kind": "Variable",
    "name": "perPage",
    "variableName": "perPage"
  },
  {
    "kind": "Variable",
    "name": "searchQuery",
    "variableName": "searchQuery"
  },
  {
    "kind": "Variable",
    "name": "sortKey",
    "variableName": "sortKey"
  },
  {
    "kind": "Variable",
    "name": "type",
    "variableName": "type"
  },
  {
    "kind": "Variable",
    "name": "voyageEtaEnd",
    "variableName": "voyageEtaEnd"
  },
  {
    "kind": "Variable",
    "name": "voyageEtaStart",
    "variableName": "voyageEtaStart"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ItemListHeaderQuery",
    "selections": [
      {
        "alias": null,
        "args": (v13/*: any*/),
        "concreteType": "ItemList",
        "kind": "LinkedField",
        "name": "itemList",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ItemListHeader_data"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v8/*: any*/),
      (v10/*: any*/),
      (v2/*: any*/),
      (v5/*: any*/),
      (v9/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v12/*: any*/),
      (v11/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ItemListHeaderQuery",
    "selections": [
      {
        "alias": null,
        "args": (v13/*: any*/),
        "concreteType": "ItemList",
        "kind": "LinkedField",
        "name": "itemList",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SortKey",
            "kind": "LinkedField",
            "name": "sortKeys",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "key",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "downloadToken",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5d360f485fef7c8dd4192c22cfa61d56",
    "id": null,
    "metadata": {},
    "name": "ItemListHeaderQuery",
    "operationKind": "query",
    "text": "query ItemListHeaderQuery(\n  $searchQuery: String\n  $type: ListItemTypeEnum\n  $filters: [FilterInput!]\n  $includeArchived: Boolean\n  $sortKey: String\n  $page: Int\n  $perPage: Int\n  $handoverDateStart: Date\n  $handoverDateEnd: Date\n  $voyageEtaStart: Date\n  $voyageEtaEnd: Date\n  $dischargeDateStart: Date\n  $dischargeDateEnd: Date\n) {\n  itemList(searchQuery: $searchQuery, type: $type, filters: $filters, includeArchived: $includeArchived, sortKey: $sortKey, page: $page, perPage: $perPage, handoverDateStart: $handoverDateStart, handoverDateEnd: $handoverDateEnd, voyageEtaStart: $voyageEtaStart, voyageEtaEnd: $voyageEtaEnd, dischargeDateStart: $dischargeDateStart, dischargeDateEnd: $dischargeDateEnd) {\n    ...ItemListHeader_data\n  }\n}\n\nfragment ItemListHeader_data on ItemList {\n  sortKeys {\n    key\n    title\n  }\n  totalCount\n  downloadToken\n}\n"
  }
};
})();
(node as any).hash = 'e996a73f97112ecd5846383504b339ff';
export default node;
