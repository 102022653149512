import get from 'lodash/get'
import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import Dropdown, { OptionType } from '../Dropdown'
import { RendererOptions } from '../DataTable'
import { Typography, IconButton, CircularProgress } from '@material-ui/core'
import { useQuery } from 'urql'
import ContainerIcon from '../../assets/public/v2/container.svg'
import ShipmentIcon from '../../assets/public/v2/shipment.svg'
import VesselIcon from '../../assets/public/v2/vessel.svg'
import ShippingLineIcon from '../../assets/public/v2/shipping_line.svg'
import VoyageIcon from '../../assets/public/v2/voyage.svg'
import { Icon, InfoContainer } from './RendererHoc'
import { RendererRoot } from './RendererHoc'
import ShowPageInDrawer, { ShowPageInDrawer_EntityType } from '../ShowPageInDrawer'
import { InfoOutlined } from '@material-ui/icons'
import { startCase } from 'lodash'

const StyledText = styled(Typography)`
  font-size: inherit;
  color: ${(props) => props.theme.palette.text.primary};

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const ContainersList = `
  query ContainersList($searchQuery: String) {
    v2ContainerList(searchQuery: $searchQuery) {
      id
      slug
      containerNumber
      imageUrl
    }
  }
`

const ShipmentsList = `
  query ShipmentsList($searchQuery: String) {
    v2ShipmentList(searchQuery: $searchQuery) {
      id
      slug
      shipmentKey
      description
      shipmentType
      imageUrl
    }
  }
`

const VesselsList = `
  query VesselsList($searchQuery: String) {
    v2VesselList(searchQuery: $searchQuery) {
      id
      slug
      name
      imageUrl
    }
  }
`

const VoyageList = `
  query VoyageList($searchQuery: String) {
    v2VoyageList(searchQuery: $searchQuery) {
      id
      slug
      name
      imageUrl
      eta
    }
  }
`

const ShippingLinesList = `
  query ShippingLinesList($searchQuery: String) {
    v2ShippingLineList(searchQuery: $searchQuery) {
      id
      slug
      name
      imageUrl
    }
  }
`

const PickupLocationList = `
  query PickupLocationList($searchQuery: String) {
    v2PickupLocationList(searchQuery: $searchQuery) {
      slug
      name
    }
  }
`

const TurninLocationList = `
  query TurninLocationList($searchQuery: String) {
    v2TurninLocationList(searchQuery: $searchQuery) {
      slug
      name
    }
  }
`

const TYPE_QUERY_MAP = {
  container: ContainersList,
  shipment: ShipmentsList,
  vessel: VesselsList,
  shippingLine: ShippingLinesList,
  voyage: VoyageList,
  pickupLocationSlug: PickupLocationList,
  turninLocationSlug: TurninLocationList,
}

const TYPE_NODE_MAP = {
  container: 'v2ContainerList',
  shipment: 'v2ShipmentList',
  vessel: 'v2VesselList',
  shippingLine: 'v2ShippingLineList',
  voyage: 'v2VoyageList',
  pickupLocationSlug: 'v2PickupLocationList',
  turninLocationSlug: 'v2TurninLocationList',
}

const TYPE_LABEL_MAP = {
  container: 'containerNumber',
  shipment: 'shipmentKey',
  vessel: 'name',
  shippingLine: 'name',
  voyage: 'name',
  pickupLocationSlug: 'name',
  turninLocationSlug: 'name',
}

const TYPE_ICON_MAP = {
  container: ContainerIcon,
  shipment: ShipmentIcon,
  vessel: VesselIcon,
  shippingLine: ShippingLineIcon,
  voyage: VoyageIcon,
  pickupLocationSlug: VoyageIcon,
  turninLocationSlug: VoyageIcon,
}

function ItemRenderer<T extends Record<string, any>>({
  anchorEl: outerAnchorEl,
  identifier,
  name,
  rowData,
  editable,
  metaKey = 'slug',
  value: externalValue,
  onSubmit,
}: RendererOptions<T>) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(outerAnchorEl)
  const [search, setSearch] = React.useState((externalValue as string) || '')
  const type: keyof typeof TYPE_QUERY_MAP = useMemo(
    () => get(rowData, `${identifier.split('.')[0]}.type`) || (identifier.split('.')[0] as keyof typeof TYPE_QUERY_MAP),
    [rowData, identifier]
  )
  const slug: string = useMemo(() => get(rowData, `${identifier.split('.')[0]}.slug`), [rowData, identifier])
  const open = Boolean(anchorEl)
  const [show, setShow] = useState(false)

  const rendererValue = get(rowData, identifier)
  const rendererMeta = get(rowData, metaKey)

  const [{ data, fetching }] = useQuery({
    query: TYPE_QUERY_MAP[type],
    variables: search.length > 2 ? { searchQuery: search } : { searchQuery: '' },
    pause: !type,
  })

  const options = useMemo(
    () =>
      (data?.[TYPE_NODE_MAP[type]] || []).map((d: any) => ({
        value: d.slug,
        label: d[TYPE_LABEL_MAP[type]],
        icon: TYPE_ICON_MAP[type],
        meta: get(d, metaKey),
      })),
    [data, type]
  )

  const itemValueOption: OptionType = options.find((opt: OptionType) => opt.value === rendererValue) || {
    label: startCase(rendererValue),
    value: rendererValue,
    icon: TYPE_ICON_MAP[type],
    meta: rendererMeta,
  }

  const [_value, setValue] = useState<OptionType>(itemValueOption)
  const value = options.find((opt: OptionType) => opt.value === externalValue) || _value

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    editable && setAnchorEl(event.currentTarget)
  }

  const handleOnChange = (value: OptionType) => {
    setValue(value)
    const key = `${identifier.split('.')[0]}`.replace('Slug', '')
    onSubmit?.({ slug: rowData.slug, [`${key}Slug`]: value.value } as any)
    handleClose()
  }

  useEffect(() => {
    const newValue = options.find((opt: OptionType) => opt.label === get(rowData, identifier) || opt.value === get(rowData, identifier))
    if (options.length > 0 && newValue?.value !== value?.value) {
      if (newValue?.value) setValue(newValue)
    }
  }, [options, rowData, identifier])

  const showMore = !!value?.value && type !== 'shippingLine'

  return (
    <>
      <RendererRoot
        role='button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        tabIndex={0}
        onClick={handleClick}
        title={value?.label}
        data-disabled={rowData.isArchived}
      >
        <Icon src={TYPE_ICON_MAP[type || 'container']} />
        <StyledText>{value?.label || '--'}</StyledText>
        {fetching && !value?.label && <CircularProgress variant='indeterminate' size={12} color='secondary' />}
        {showMore && (
          <InfoContainer data-hoverable>
            <IconButton
              size='small'
              onClick={(e) => {
                e.stopPropagation()
                setShow(true)
              }}
            >
              <InfoOutlined fontSize='small' />
            </IconButton>
          </InfoContainer>
        )}
      </RendererRoot>
      <Dropdown
        title={name}
        search={search}
        onSearch={(value) => setSearch(value)}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        options={options}
        onSelect={handleOnChange}
        value={value?.value}
      />
      {showMore && !['shippingLine', 'pickupLocationSlug', 'turninLocationSlug'].includes(type) && (
        <ShowPageInDrawer entityType={type as ShowPageInDrawer_EntityType} entitySlug={slug} open={show} onClose={() => setShow(false)} />
      )}
    </>
  )
}

ItemRenderer.operators = ['eq', 'neq', 'is_null', 'not_null']

export { TYPE_QUERY_MAP }

export default ItemRenderer
