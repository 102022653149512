import { useLocation, useNavigate } from 'react-router'

const useWorkspace = (): [string, string, (path?: string) => void] => {
  const location = useLocation()
  const navigate = useNavigate()
  const workspace = location.pathname.split('/')[1]
  const workspaceName = workspace.length > 1 ? `${workspace[0].toUpperCase()}${workspace.slice(1)}` : workspace
  const wsNavigate = (path?: string) => navigate(`/${workspace}${path || ''}`)

  return [workspace, workspaceName, wsNavigate]
}

export default useWorkspace
