import React, { Suspense, useContext, useMemo, useState } from 'react'
import { Box, LinearProgress, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core'
import { graphql } from 'babel-plugin-relay/macro'

import ATBIcon from '../../assets/public/v2/atb.svg'
import axios from 'axios'
import ContactRenderer from '../../components/Renderers/ContactRenderer'
import ContainerIcon from '../../assets/public/v2/container.svg'
import ContainerPickSent from '../../components/Workflows/ContainerPickSent'
import ContainerReleaseConfirm from '../../components/Workflows/ContainerReleaseConfirm'
import ContainerReleaseRequest from '../../components/Workflows/ContainerReleaseRequest'
import ContainerSizeIcon from '../../assets/public/v2/size.svg'
import ContainerTypeIcon from '../../assets/public/v2/type.svg'
import DataTable, { IOrder } from '../../components/DataTable'
import DateBlankIcon from '../../assets/public/v2/date_blank.svg'
import DateRenderer from '../../components/Renderers/DateRenderer'
import DecoratedNumberRenderer from '../../components/Renderers/NumberRenderer'
import fileDownload from 'js-file-download'
import ItemRenderer from '../../components/Renderers/ItemRenderer'
import LinkRenderer from '../../components/Renderers/LinkRenderer'
import LoadingDots from '../../components/LoadingDots'
import moment from 'moment'
import OdysseyDialog from '../../components/OdysseyDialog'
import OptionsRenderer from '../../components/Renderers/OptionsRenderer'
import PhysicalStatusIcon from '../../assets/public/v2/location.svg'
import PickupIcon from '../../assets/public/v2/pickup.svg'
import SessionContext from '../../SessionContext'
import ShippingLineIcon from '../../assets/public/v2/shipping_line.svg'
import StageRenderer from '../../components/Renderers/StageRenderer'
import StageStarted from '../../assets/public/v2/stage_started.svg'
import StyledInput from '../../components/StyledInput'
import TransporterIcon from '../../assets/public/v2/transporter.svg'
import TurninIcon from '../../assets/public/v2/turnin.svg'
import useNiceMutation from '../../mutations/useNiceMutation'
import usePager from '../../hooks/usePager'
import VesselIcon from '../../assets/public/v2/vessel.svg'
import VoyageIcon from '../../assets/public/v2/voyage.svg'
import WarehouseIcon from '../../assets/public/v2/warehouse.svg'
import { ContainersTableMutation } from '../../__generated__/ContainersTableMutation.graphql'
import { ContainersTableContainerBulkActionsMutation } from '../../__generated__/ContainersTableContainerBulkActionsMutation.graphql'
import { ContainersTableStageChangeMutation } from '../../__generated__/ContainersTableStageChangeMutation.graphql'
import { useLocation } from 'react-router'
import { useQuery } from 'urql'
import { useSnackbar } from 'notistack'
import type { Column, IFilter } from '../../components/DataTable'

const VIEW_OPTIONS = [
  { label: 'Release', value: 'release' },
  { label: 'Finance', value: 'finance' },
  { label: 'Disposition', value: 'disposition' },
]

const VIEWS = {
  release: [
    'containerNumber',
    'stage',
    'eta',
    'voyage.name',
    'vessel.name',
    'shippingLine.name',
    'bookingNumber',
    'pickupLocationSlug',
    'turninLocationSlug',
    // 'lastNote',
    // 'releaseDocs'
  ],
  finance: [
    'containerNumber',
    'stage',
    'eta',
    'voyage.name',
    'vessel.name',
    'costStorage',
    'costDemurrage',
    'costTransport',
    'costXray',
    'costThc',
    'costOther',
    'costCommission',
    'costCleaning',
    'estimatedCostStorage',
    'estimatedCostDemurrage',
    // 'invoices'
  ],
  disposition: [
    'containerNumber',
    'stage',
    'eta',
    'voyage.name',
    'vessel.name',
    'warehouse.name',
    'transporter.name',
    'physicalStatus',
    'dischargeDate',
    'terminalDate',
    'atb',
    'pickupReference',
    'pickupLocationSlug',
    'turninReference',
    'turninLocationSlug',
    'latestPickupDate',
    'latestTurninDate',
    'pickupDate',
    'pickupComment',
    'containerSize',
    'containerType',
    // 'lastComment'
  ],
}

const BULK_EDITABLE_FIELDS = {
  release: ['voyage.name', 'bookingNumber', 'shippingLine.name'],
  finance: [
    'costStorage',
    'costDemurrage',
    'costTransport',
    'costXray',
    'costThc',
    'costOther',
    'costCommission',
    'costCleaning',
    'estimatedCostStorage',
    'estimatedCostDemurrage',
  ],
  disposition: [
    'voyage.name',
    'pickupLocationSlug',
    'turninLocationSlug',
    'latestPickupDate',
    'latestTurninDate',
    'pickupComment',
    'transporter.name',
    'warehouse.name',
  ],
}

const ContainersTable: React.FC = () => {
  const { user } = useContext(SessionContext)
  const [filters, setFilters] = React.useState<IFilter[]>([])
  const [search, setSearch] = React.useState('')
  const [order, setOrder] = React.useState<IOrder[]>([])
  const [page, pageSize, handlePageChange, handlePageSizeChange] = usePager()
  const [includeArchived, setIncludeArchived] = React.useState(false)

  const canEdit =
    user?.contact?.displayRoles.map((role) => role.value).includes('admin') ||
    user?.contact?.displayRoles.map((role) => role.value).includes('super-admin') ||
    user?.contact?.displayRoles.map((role) => role.value).includes('container-agent')

  const [currentView, setCurrentView] = React.useState<'release' | 'finance' | 'disposition'>('release')

  const releaseQueryResult = useQuery({
    query: releaseQuery,
    requestPolicy: 'cache-and-network',
    pause: currentView !== 'release',
    variables: {
      ...(search.length > 2 && { searchQuery: search }),
      filters,
      order,
      page,
      pageSize,
      includeArchived,
    },
  })

  const financeQueryResult = useQuery({
    query: financeQuery,
    requestPolicy: 'cache-and-network',
    pause: currentView !== 'finance',
    variables: {
      ...(search.length > 2 && { searchQuery: search }),
      filters,
      order,
      page,
      pageSize,
      includeArchived,
    },
  })

  const dispositionQueryResult = useQuery({
    query: dispositionQuery,
    requestPolicy: 'cache-and-network',
    pause: currentView !== 'disposition',
    variables: {
      ...(search.length > 2 && { searchQuery: search }),
      filters,
      order,
      page,
      pageSize,
      includeArchived,
    },
  })

  const data =
    currentView === 'release'
      ? releaseQueryResult[0].data
      : currentView === 'finance'
      ? financeQueryResult[0].data
      : dispositionQueryResult[0].data
  const fetching =
    currentView === 'release'
      ? releaseQueryResult[0].fetching
      : currentView === 'finance'
      ? financeQueryResult[0].fetching
      : dispositionQueryResult[0].fetching
  const refetch =
    currentView === 'release' ? releaseQueryResult[1] : currentView === 'finance' ? financeQueryResult[1] : dispositionQueryResult[1]

  const items = data?.v2ContainerList?.response.edges.map((edge: any) => edge?.node) || []

  const [commit, commitInFlight] = useNiceMutation<ContainersTableMutation>(mutation)
  const [bulkUpdate] = useNiceMutation<ContainersTableContainerBulkActionsMutation>(bulkUpdateMutation)
  const [changeStage, changeStageInFlight] = useNiceMutation<ContainersTableStageChangeMutation>(changeStageMutation)
  const url = `${process.env.REACT_APP_API_URL}/v2/containers/download`
  const workspace = useLocation().pathname.split('/')[1]
  const [clickedStatus, setClickedStatus] = useState<any>(null)
  const [date, setDate] = useState(new Date().toISOString().slice(0, 10))
  const [releaseRequestedDialoq, setReleaseRequestedDialoq] = useState<string | null>(null)
  const [releaseConfirmDialoq, setReleaseConfirmDialoq] = useState<string | null>(null)
  const [pickSentDialoq, setPickSentDialoq] = useState<string | null>(null)

  const { enqueueSnackbar } = useSnackbar()

  const download = (selected?: number[]) => {
    const filename = `containers-${moment().format('YYYY-MM-DD-HH:mm:ss')}.xlsx`
    return axios
      .post(
        url,
        {
          workspace,
          slugs: Array.isArray(selected) ? selected.map((_, i) => data?.v2ContainerList?.response.edges[i].node.slug) : undefined,
          filters,
          order,
          search_query: search,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
          responseType: 'blob',
        }
      )
      .then((res) => {
        fileDownload(res.data, filename)
      })
      .catch((error) => {
        error.response.data.text().then(function (text: string) {
          try {
            enqueueSnackbar(JSON.parse(text as string).errors, { variant: 'error' })
          } catch {
            enqueueSnackbar('Something went wrong', { variant: 'error' })
          }
        })
      })
  }

  const onBulkUpdate = async (items: any[]) => {
    const containers = items.map((item) => ({
      slug: item?.slug,
      voyageSlug: item?.voyageSlug,
      bookingNumber: item?.bookingNumber,
      linkedContacts: item?.linkedContacts.map((contact: any) => ({ role: contact.role, contactSlug: contact.contactSlug })) || [],

      pickupLocationSlug: item?.pickupLocationSlug,
      turninLocationSlug: item?.turninLocationSlug,
      pickupComment: item?.pickupComment,
      latestPickupDate: item?.latestPickupDate,
      latestTurninDate: item?.latestTurninDate,

      shippingLineSlug: item?.shippingLineSlug,
      costStorage: item?.costStorage,
      costDemurrage: item?.costDemurrage,
      costTransport: item?.costTransport,
      costXray: item?.costXray,
      costThc: item?.costThc,
      costOther: item?.costOther,
      costCommission: item?.costCommission,
      costCleaning: item?.costCleaning,
      estimatedCostStorage: item?.estimatedCostStorage,
      estimatedCostDemurrage: item?.estimatedCostDemurrage,
    }))

    return new Promise((resolve, reject) => {
      bulkUpdate({
        variables: {
          input: {
            containers,
            action: 'update',
          },
        },
        onError: reject,
        onCompleted: (response) => {
          if (response.infoContainerBulkActions?.result) {
            refetch({ requestPolicy: 'network-only' })
            resolve(response)
            enqueueSnackbar('Containers updated', { variant: 'success' })
          }
          // actions.setSubmitting(false)
          // if (!errors) {
          //   onClose()
          // }
        },
      })
    })
  }

  const onSubmit = (item: any) => {
    const values = {
      slug: item?.slug,
      ...item,
      ...(item?.linkedContacts && {
        linkedContacts: item.linkedContacts?.map((contact: any) => ({ role: contact.role, contactSlug: contact.contactSlug })) || [],
      }),
    }

    commit({
      variables: {
        input: values,
      },
      // onError: () => actions.setSubmitting(false),
      onCompleted: (response) => {
        if (response.infoV2UpdateContainer?.container) {
          refetch({ requestPolicy: 'network-only' })
        }
        // actions.setSubmitting(false)
        // if (!errors) {
        //   onClose()
        // }
      },
    })
  }

  const onStageSubmit = (item: any) => {
    const { stage } = item

    const values = {
      slug: item?.slug,
      stage: item?.stage,
    }

    if (stage) {
      changeStage({
        variables: {
          input: values as any,
        },
        // onError: () => actions.setSubmitting(false),
        onCompleted: () => {
          // actions.setSubmitting(false)
          // if (!errors) {
          //   onClose()
          // }
        },
      })
    }

    if (stage === 'release_requested') {
      setReleaseRequestedDialoq(item.slug)
      return
    } else if (stage === 'release_confirmed') {
      setReleaseConfirmDialoq(item.slug)
      return
    } else if (stage === 'pickup_sent') {
      setPickSentDialoq(item.slug)
      return
    }

    if (releaseConfirmDialoq || releaseRequestedDialoq || pickSentDialoq) {
      commit({
        variables: {
          input: {
            slug: item?.slug,
            thcPaidDate: item?.thcPaidDate,
            bookingNumber: item?.bookingNumber,
            containerNumber: item?.containerNumber,
            voyageSlug: item?.voyageSlug,
            containerType: item?.containerType,
            pickupLocationSlug: item?.pickupLocationSlug,
            turninLocationSlug: item?.turninLocationSlug,
            pickupReference: item?.pickupReference,
            turninReference: item?.turninReference,
            latestPickupDate: item?.latestPickupDate,
            latestTurninDate: item?.latestTurninDate,
            pickupComment: item?.pickupComment,
            shippingLineSlug: item?.shippingLineSlug,
            linkedContacts: item?.linkedContacts.map((contact: any) => ({ role: contact.role, contactSlug: contact.contactSlug })) || [],
          },
        },
        onError: () => {
          setReleaseRequestedDialoq(null)
          setReleaseConfirmDialoq(null)
          setPickSentDialoq(null)
        },
        onCompleted: (response) => {
          if (response.infoV2UpdateContainer?.container) {
            refetch({ requestPolicy: 'network-only' })
          }
          setReleaseRequestedDialoq(null)
          setReleaseConfirmDialoq(null)
          setPickSentDialoq(null)
        },
      })
    }
  }

  const onPhysicalStatusSubmit = (item: any) => {
    if (['full_in_terminal', 'in_warehouse'].includes(item?.physicalStatus) && !clickedStatus) {
      setClickedStatus(item)
      return
    }

    const values = {
      slug: item?.slug,
      physicalStatus: item?.physicalStatus,
      changeDate: date,
    }
    commit({
      variables: {
        input: values as any,
      },
      onError: () => setClickedStatus(null),
      onCompleted: (response) => {
        if (response.infoV2UpdateContainer?.container) {
          refetch({ requestPolicy: 'network-only' })
        }
        setClickedStatus(null)
        // actions.setSubmitting(false)
        // if (!errors) {
        //   onClose()
        // }
      },
    })
  }

  const onShippingLineSubmit = (item: any) => {
    const values = {
      slug: item?.slug,
      shippingLineSlug: item?.shippingLineSlug,
    }
    commit({
      variables: {
        input: values as any,
      },
      onError: () => setClickedStatus(null),
      onCompleted: (response) => {
        if (response.infoV2UpdateContainer?.container) {
          refetch({ requestPolicy: 'network-only' })
        }
        setClickedStatus(null)
      },
    })
  }

  const columns: Column[] = useMemo(
    () =>
      [
        {
          identifier: 'containerNumber',
          name: 'Container',
          onSubmit,
          sortable: true,
          editable: false,
          renderer: LinkRenderer,
          style: { maxWidth: 150 },
          icon: ContainerIcon,
        },
        {
          identifier: 'stage',
          name: 'Stage',
          renderer: StageRenderer,
          sortable: true,
          filterable: true,
          editable: true,
          icon: StageStarted,
          onSubmit: onStageSubmit,
        },
        {
          identifier: 'eta',
          name: 'ETA',
          sortable: true,
          editable: false,
          filterable: true,
          icon: DateBlankIcon,
          renderer: DateRenderer,
        },
        {
          identifier: 'voyage.name',
          name: 'Voyage',
          renderer: ItemRenderer,
          sortable: true,
          editable: currentView !== 'finance',
          filterable: true,
          icon: VoyageIcon,
          meta: 'eta',
          style: { maxWidth: 100 },
          onSubmit,
        },
        {
          identifier: 'vessel.name',
          name: 'Vessel',
          sortable: true,
          editable: false,
          filterable: true,
          icon: VesselIcon,
          renderer: ItemRenderer,
        },
        {
          identifier: 'shippingLine.name',
          name: 'Shipping Line',
          sortable: true,
          editable: true,
          filterable: true,
          icon: ShippingLineIcon,
          renderer: ItemRenderer,
          onSubmit: onShippingLineSubmit,
        },
        {
          identifier: 'warehouse.name',
          name: 'Warehouse',
          editable: true,
          filterable: true,
          icon: WarehouseIcon,
          style: { maxWidth: 150 },
          renderer: ContactRenderer,
          onSubmit,
        },
        {
          identifier: 'transporter.name',
          name: 'Transporter',
          editable: true,
          filterable: true,
          icon: TransporterIcon,
          style: { maxWidth: 150 },
          renderer: ContactRenderer,
          onSubmit,
        },
        {
          identifier: 'physicalStatus',
          name: 'Physical Location',
          filterable: true,
          editable: true,
          sortable: true,
          icon: PhysicalStatusIcon,
          renderer: OptionsRenderer,
          onSubmit: onPhysicalStatusSubmit,
        },
        { identifier: 'atb', name: 'ATB', sortable: true, editable: true, icon: ATBIcon, onSubmit },

        {
          identifier: 'containerSize',
          name: 'Size',
          sortable: true,
          editable: true,
          filterable: true,
          icon: ContainerSizeIcon,
          renderer: OptionsRenderer,
          onSubmit,
        },
        {
          identifier: 'containerType',
          name: 'Type',
          renderer: OptionsRenderer,
          sortable: true,
          filterable: true,
          editable: true,
          icon: ContainerTypeIcon,
          onSubmit,
        },
        { identifier: 'pickupComment', name: 'Pickup Comment', sortable: true, editable: true, onSubmit },
        {
          identifier: 'pickupLocationSlug',
          name: 'Pickup Location',
          icon: PickupIcon,
          sortable: true,
          editable: true,
          filterable: true,
          onSubmit,
          renderer: ItemRenderer,
        },
        {
          identifier: 'turninLocationSlug',
          name: 'Turnin Location',
          sortable: true,
          editable: true,
          filterable: true,
          icon: TurninIcon,
          onSubmit,
          renderer: ItemRenderer,
        },
        {
          identifier: 'pickupReference',
          name: 'Pickup Reference',
          sortable: true,
          editable: true,
          icon: PickupIcon,
          onSubmit,
        },
        {
          identifier: 'turninReference',
          name: 'Turnin Reference',
          sortable: true,
          editable: true,
          icon: TurninIcon,
          onSubmit,
        },

        { identifier: 'latestPickupDate', name: 'Latest Pickup Date', sortable: true, editable: true, onSubmit, renderer: DateRenderer },
        { identifier: 'latestTurninDate', name: 'Latest Turnin Date', sortable: true, editable: true, onSubmit, renderer: DateRenderer },
        { identifier: 'terminalDate', name: 'Terminal Date', sortable: true, editable: true, onSubmit, renderer: DateRenderer },
        {
          identifier: 'dischargeDate',
          name: 'Discharge Date',
          sortable: true,
          editable: true,
          filterable: true,
          onSubmit,
          icon: DateBlankIcon,
          renderer: DateRenderer,
        },
        {
          identifier: 'pickupDate',
          name: 'Pickup Date',
          sortable: true,
          editable: true,
          filterable: true,
          onSubmit,
          icon: DateBlankIcon,
          renderer: DateRenderer,
        },
        {
          identifier: 'thcPaidDate',
          name: 'Thc Paid Date',
          sortable: true,
          editable: true,
          onSubmit,
          icon: DateBlankIcon,
          renderer: DateRenderer,
        },

        { identifier: 'bookingNumber', name: 'Booking Number', sortable: true, editable: true, onSubmit },
        { identifier: 'costCleaning', name: 'Cost Cleaning', sortable: true, editable: true, renderer: DecoratedNumberRenderer, onSubmit },
        { identifier: 'costCommission', name: 'Commission Cost', sortable: true, editable: true, renderer: DecoratedNumberRenderer, onSubmit },
        { identifier: 'costDemurrage', name: 'Demurrage Cost', sortable: true, editable: true, renderer: DecoratedNumberRenderer, onSubmit },
        { identifier: 'costOther', name: 'Other Cost', sortable: true, editable: true, renderer: DecoratedNumberRenderer, onSubmit },
        { identifier: 'costStorage', name: 'Storage Cost', sortable: true, editable: true, renderer: DecoratedNumberRenderer, onSubmit },
        {
          identifier: 'costStoragePlusDemurrage',
          name: 'Storage Plus Demurrage Cost',
          sortable: true,
          editable: true,
          renderer: DecoratedNumberRenderer,
          onSubmit,
        },
        { identifier: 'costThc', name: 'THC Cost', sortable: true, editable: true, renderer: DecoratedNumberRenderer, onSubmit },
        { identifier: 'costTransport', name: 'Transport Cost', sortable: true, editable: true, renderer: DecoratedNumberRenderer, onSubmit },
        { identifier: 'costXray', name: 'Xray Cost', sortable: true, editable: true, renderer: DecoratedNumberRenderer, onSubmit },
        {
          identifier: 'estimatedCostDemurrage',
          name: 'Est. Cost Demurrage',
          sortable: true,
          editable: true,
          renderer: DecoratedNumberRenderer,
          onSubmit,
        },
        {
          identifier: 'estimatedCostStorage',
          name: 'Est. Cost Storage',
          sortable: true,
          editable: true,
          renderer: DecoratedNumberRenderer,
          onSubmit,
        },
      ].map((col) => ({
        ...col,
        editable: col.editable && user?.contact?.contactType === 'internal' && canEdit,
        hidden: !VIEWS[currentView].includes(col.identifier),
      })),
    [currentView]
  )

  return (
    <Box style={{ position: 'relative' }}>
      <Suspense key='item-list' fallback={<LinearProgress />}>
        <DataTable
          type='container'
          loading={fetching || commitInFlight || changeStageInFlight}
          filters={filters}
          setFilters={setFilters}
          search={search}
          setSearch={setSearch}
          includeArchived={includeArchived}
          setIncludeArchived={setIncludeArchived}
          order={order}
          setOrder={setOrder}
          actions={[]}
          bulkEditFields={BULK_EDITABLE_FIELDS}
          columns={columns}
          data={items}
          onSubmit={onSubmit}
          view={currentView}
          onViewChange={(view) => setCurrentView(view as keyof typeof VIEWS)}
          viewOptions={VIEW_OPTIONS}
          handlePageChange={handlePageChange}
          handlePageSizeChange={handlePageSizeChange}
          page={page - 1}
          pageSize={pageSize}
          count={data?.v2ContainerList?.totalCount}
          onDownload={download}
          onBulkUpdate={onBulkUpdate}
        />
      </Suspense>
      <OdysseyDialog open={!!clickedStatus} onClose={() => setClickedStatus(null)}>
        {clickedStatus && (
          <>
            <DialogTitle>Change location?</DialogTitle>
            {clickedStatus?.physicalStatus === 'full_in_terminal' && (
              <DialogContent>
                Select Terminal date:
                <StyledInput type='date' value={date} placeholder='Terminal Date' onChange={(e) => setDate(e.target.value)}></StyledInput>
              </DialogContent>
            )}

            {clickedStatus?.physicalStatus === 'in_warehouse' && (
              <DialogContent>
                Select Discharge date:
                <StyledInput type='date' value={date} placeholder='Discharge Date' onChange={(e) => setDate(e.target.value)}></StyledInput>
              </DialogContent>
            )}
            <DialogActions>
              <Button onClick={() => setClickedStatus(null)}>Cancel</Button>
              <Button variant='contained' onClick={() => onPhysicalStatusSubmit(clickedStatus)}>
                {commitInFlight ? <LoadingDots variant='light' /> : 'OK'}
              </Button>
            </DialogActions>
          </>
        )}
      </OdysseyDialog>
      {releaseRequestedDialoq && (
        <ContainerReleaseRequest
          open={!!releaseRequestedDialoq}
          onClose={() => setReleaseRequestedDialoq(null)}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          slug={releaseRequestedDialoq!}
          onSubmit={onStageSubmit}
        ></ContainerReleaseRequest>
      )}
      {releaseConfirmDialoq && (
        <ContainerReleaseConfirm
          open={!!releaseConfirmDialoq}
          onClose={() => setReleaseConfirmDialoq(null)}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          slug={releaseConfirmDialoq!}
          onSubmit={onStageSubmit}
        ></ContainerReleaseConfirm>
      )}
      {pickSentDialoq && (
        <ContainerPickSent
          open={!!pickSentDialoq}
          onClose={() => setPickSentDialoq(null)}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          slug={pickSentDialoq!}
          onSubmit={onStageSubmit}
        ></ContainerPickSent>
      )}
    </Box>
  )
}

const releaseQuery = `
  query ContainersTableQuery($searchQuery: String, $includeArchived: Boolean!, $filters: [V2FilterInput!], $order: [V2OrderInput!], $page: Int, $pageSize: Int) {
    v2ContainerList(searchQuery: $searchQuery, includeArchived: $includeArchived, filters: $filters, order: $order, page: $page, pageSize: $pageSize) {
      response {
        edges {
          node {
            bookingNumber
            containerNumber
            eta
            id
            isArchived
            pickupDate
            pickupLocationSlug
            slug
            stage
            thcPaidDate
            turninLocationSlug
            type

            linkedContacts {
              contactSlug
              role
            }

            shippingLine {
              id
              name
              slug
              type
            }
            voyage {
              id
              name
              slug
              type
              eta
            }
            vessel {
              id
              name
              slug
              type
            }
          }
        }
      }
      paginate {
        hasNextPage
        hasPrevPage
        page
        perPage
        totalPages
      }
      totalCount
    }
  }
`

const financeQuery = `
  query ContainersTableQuery($searchQuery: String, $includeArchived: Boolean!, $filters: [V2FilterInput!], $order: [V2OrderInput!], $page: Int, $pageSize: Int) {
    v2ContainerList(searchQuery: $searchQuery, includeArchived: $includeArchived, filters: $filters, order: $order, page: $page, pageSize: $pageSize) {
      response {
        edges {
          node {
            containerNumber
            costCleaning
            costCommission
            costDemurrage
            costOther
            costStorage
            costStoragePlusDemurrage
            costThc
            costTransport
            costXray
            estimatedCostDemurrage
            estimatedCostStorage
            eta
            id
            isArchived
            slug
            stage
            thcPaidDate
            type

            linkedContacts {
              contactSlug
              role
            }

            voyage {
              id
              name
              slug
              type
              eta
            }
            vessel {
              id
              name
              slug
              type
            }
          }
        }
      }
      paginate {
        hasNextPage
        hasPrevPage
        page
        perPage
        totalPages
      }
      totalCount
    }
  }
`

const dispositionQuery = `
  query ContainersTableQuery($searchQuery: String, $includeArchived: Boolean!, $filters: [V2FilterInput!], $order: [V2OrderInput!], $page: Int, $pageSize: Int) {
    v2ContainerList(searchQuery: $searchQuery, includeArchived: $includeArchived, filters: $filters, order: $order, page: $page, pageSize: $pageSize) {
      response {
        edges {
          node {
            atb
            containerNumber
            containerSize
            containerType
            dischargeDate
            eta
            id
            isArchived
            latestPickupDate
            latestTurninDate
            physicalStatus
            pickupComment
            pickupDate
            pickupLocationSlug
            pickupReference
            slug
            stage
            thcPaidDate
            turninLocationSlug
            turninReference
            type
            terminalDate

            linkedContacts {
              contactSlug
              role
            }

            voyage {
              id
              name
              slug
              type
              eta
            }
            vessel {
              id
              name
              slug
              type
            }
            warehouse {
              name
              type
              slug
            }
            transporter {
              name
              type
              slug
            }
          }
        }
      }
      paginate {
        hasNextPage
        hasPrevPage
        page
        perPage
        totalPages
      }
      totalCount
    }
  }
`

const mutation = graphql`
  mutation ContainersTableMutation($input: infoV2UpdateContainerInput!) {
    infoV2UpdateContainer(input: $input) {
      container {
        atb
        bookingNumber
        containerNumber
        containerSize
        containerType
        costCleaning
        costCommission
        costDemurrage
        costOther
        costStorage
        costStoragePlusDemurrage
        costThc
        costTransport
        costXray
        dischargeDate
        estimatedCostDemurrage
        estimatedCostStorage
        eta
        id
        isArchived
        latestPickupDate
        latestTurninDate
        physicalStatus
        pickupComment
        pickupDate
        pickupLocationSlug
        pickupReference
        slug
        stage
        thcPaidDate
        turninLocationSlug
        turninReference
        type
        terminalDate

        linkedContacts {
          contactSlug
          role
        }

        shippingLine {
          id
          name
          slug
          type
        }
        voyage {
          id
          name
          slug
          type
          eta
        }
        vessel {
          id
          name
          slug
          type
        }
        warehouse {
          name
          type
          slug
        }
        transporter {
          name
          type
          slug
        }
      }
    }
  }
`

const changeStageMutation = graphql`
  mutation ContainersTableStageChangeMutation($input: infoChangeContainerStageInput!) {
    infoChangeContainerStage(input: $input) {
      clientMutationId
    }
  }
`

const bulkUpdateMutation = graphql`
  mutation ContainersTableContainerBulkActionsMutation($input: infoContainerBulkActionsInput!) {
    infoContainerBulkActions(input: $input) {
      result
    }
  }
`

// TODO: Backend
// Add entity object to the query
// voyage {
//  slug
//  type
//  ...
// }

export default ContainersTable
