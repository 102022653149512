/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type infoGenerateAtbInput = {
    clientMutationId?: string | null;
    entitySlug: string;
};
export type GeneratableAtbItemMutationVariables = {
    input: infoGenerateAtbInput;
};
export type GeneratableAtbItemMutationResponse = {
    readonly infoGenerateAtb: {
        readonly clientMutationId: string | null;
        readonly result: string;
    } | null;
};
export type GeneratableAtbItemMutation = {
    readonly response: GeneratableAtbItemMutationResponse;
    readonly variables: GeneratableAtbItemMutationVariables;
};



/*
mutation GeneratableAtbItemMutation(
  $input: infoGenerateAtbInput!
) {
  infoGenerateAtb(input: $input) {
    clientMutationId
    result
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "infoGenerateAtbPayload",
    "kind": "LinkedField",
    "name": "infoGenerateAtb",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "result",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GeneratableAtbItemMutation",
    "selections": (v1/*: any*/),
    "type": "ApplicationCommand",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GeneratableAtbItemMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "411d48ae252b75ef1aca99ad84fafe7c",
    "id": null,
    "metadata": {},
    "name": "GeneratableAtbItemMutation",
    "operationKind": "mutation",
    "text": "mutation GeneratableAtbItemMutation(\n  $input: infoGenerateAtbInput!\n) {\n  infoGenerateAtb(input: $input) {\n    clientMutationId\n    result\n  }\n}\n"
  }
};
})();
(node as any).hash = '09edb9bb7e2e42741541c5c5799684dd';
export default node;
