/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ContainerReleaseRequestFormQueryVariables = {
    slug: string;
};
export type ContainerReleaseRequestFormQueryResponse = {
    readonly infoUpdateContainerForm: {
        readonly initialValue: {
            readonly slug: string;
            readonly containerNumber: string;
            readonly voyageSlug: string;
            readonly containerType: string;
            readonly bookingNumber: string | null;
            readonly thcPaidDate: string | null;
            readonly shippingLineSlug: string | null;
            readonly linkedContacts: {
                readonly nodes: ReadonlyArray<{
                    readonly role: string;
                    readonly contactSlug: string;
                } | null> | null;
            };
        } | null;
    };
};
export type ContainerReleaseRequestFormQuery = {
    readonly response: ContainerReleaseRequestFormQueryResponse;
    readonly variables: ContainerReleaseRequestFormQueryVariables;
};



/*
query ContainerReleaseRequestFormQuery(
  $slug: String!
) {
  infoUpdateContainerForm(slug: $slug) {
    initialValue {
      slug
      containerNumber
      voyageSlug
      containerType
      bookingNumber
      thcPaidDate
      shippingLineSlug
      linkedContacts {
        nodes {
          role
          contactSlug
        }
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "containerNumber",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voyageSlug",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "containerType",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bookingNumber",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thcPaidDate",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shippingLineSlug",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "ContactLinkConnection",
  "kind": "LinkedField",
  "name": "linkedContacts",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ContactLink",
      "kind": "LinkedField",
      "name": "nodes",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "role",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "contactSlug",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ContainerReleaseRequestFormQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "InfoUpdateContainerFormResponse",
        "kind": "LinkedField",
        "name": "infoUpdateContainerForm",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Container",
            "kind": "LinkedField",
            "name": "initialValue",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ContainerReleaseRequestFormQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "InfoUpdateContainerFormResponse",
        "kind": "LinkedField",
        "name": "infoUpdateContainerForm",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Container",
            "kind": "LinkedField",
            "name": "initialValue",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5b2bf4c78dd6894456cce01f17581654",
    "id": null,
    "metadata": {},
    "name": "ContainerReleaseRequestFormQuery",
    "operationKind": "query",
    "text": "query ContainerReleaseRequestFormQuery(\n  $slug: String!\n) {\n  infoUpdateContainerForm(slug: $slug) {\n    initialValue {\n      slug\n      containerNumber\n      voyageSlug\n      containerType\n      bookingNumber\n      thcPaidDate\n      shippingLineSlug\n      linkedContacts {\n        nodes {\n          role\n          contactSlug\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'cb24f3d9641df0cda2c34ed196fd027d';
export default node;
