/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LinkedContactsCard_linkedContacts = {
    readonly nodes: ReadonlyArray<{
        readonly role: string;
        readonly displayRole: string;
        readonly displayName: string;
        readonly contactItem: {
            readonly slug: string;
            readonly title: string;
            readonly image: {
                readonly url: string | null;
                readonly alt: string | null;
            } | null;
        };
    } | null> | null;
    readonly " $refType": "LinkedContactsCard_linkedContacts";
};
export type LinkedContactsCard_linkedContacts$data = LinkedContactsCard_linkedContacts;
export type LinkedContactsCard_linkedContacts$key = {
    readonly " $data"?: LinkedContactsCard_linkedContacts$data;
    readonly " $fragmentRefs": FragmentRefs<"LinkedContactsCard_linkedContacts">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LinkedContactsCard_linkedContacts",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ContactLink",
      "kind": "LinkedField",
      "name": "nodes",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "role",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayRole",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ListItem",
          "kind": "LinkedField",
          "name": "contactItem",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "slug",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Image",
              "kind": "LinkedField",
              "name": "image",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "url",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "alt",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ContactLinkConnection",
  "abstractKey": null
};
(node as any).hash = 'a72d3621b47e31dbc3ebb1087cdca73b';
export default node;
