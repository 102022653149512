import { Typography } from '@material-ui/core'
import { graphql } from 'babel-plugin-relay/macro'
import React from 'react'
import { useFragment } from 'react-relay/hooks'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { HighlightT } from '../../components/TextHighlighter'
import { ViewMode } from '../../viewTypes'
import { ExternalItemListContent_data$key } from '../../__generated__/ExternalItemListContent_data.graphql'
import ExternalListPageItem from './ExternalListPageItem'

interface Props {
  data: ExternalItemListContent_data$key
  viewMode: ViewMode
  highlights?: HighlightT[]
}

const NoResultsText = styled(Typography)`
  color: ${(props) => props.theme.palette.grey[300]};
  text-align: center;
  padding: 32px;
  padding-bottom: 8px;
  font-weight: 700;
`
const ResetFiltersLink = styled(Link)`
  text-decoration: none;
  display: block;
  text-align: center;
`

const ExternalItemListContent: React.FC<Props> = ({ data, viewMode, highlights }) => {
  const { items } = useFragment(fragment, data)

  return (
    <>
      {items && items.edges && items.edges.length > 0 ? (
        items.edges.map((edge) => {
          const item = edge && edge.node
          if (item) return <ExternalListPageItem key={item.slug} listItem={item} viewMode={viewMode} highlights={highlights} />
          return <></>
        })
      ) : (
        <>
          <NoResultsText variant='h5'>No results</NoResultsText>
          <ResetFiltersLink to={`${window.location.pathname}`}>Reset Filters</ResetFiltersLink>
        </>
      )}
    </>
  )
}

const fragment = graphql`
  fragment ExternalItemListContent_data on ExternalItemList {
    items {
      edges {
        node {
          slug
          ...ExternalListPageItem_listItem
        }
      }
    }
  }
`

export default ExternalItemListContent
