/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type infoUploadAttachmentInput = {
    clientMutationId?: string | null;
    caption?: string | null;
};
export type FileInputFieldUploadMutationVariables = {
    input: infoUploadAttachmentInput;
};
export type FileInputFieldUploadMutationResponse = {
    readonly infoUploadAttachment: {
        readonly attachmentSlug: string;
    } | null;
};
export type FileInputFieldUploadMutation = {
    readonly response: FileInputFieldUploadMutationResponse;
    readonly variables: FileInputFieldUploadMutationVariables;
};



/*
mutation FileInputFieldUploadMutation(
  $input: infoUploadAttachmentInput!
) {
  infoUploadAttachment(input: $input) {
    attachmentSlug
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "infoUploadAttachmentPayload",
    "kind": "LinkedField",
    "name": "infoUploadAttachment",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "attachmentSlug",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FileInputFieldUploadMutation",
    "selections": (v1/*: any*/),
    "type": "ApplicationCommand",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FileInputFieldUploadMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5a5425fcaf9d7b0bdde88dcf76a2ea02",
    "id": null,
    "metadata": {},
    "name": "FileInputFieldUploadMutation",
    "operationKind": "mutation",
    "text": "mutation FileInputFieldUploadMutation(\n  $input: infoUploadAttachmentInput!\n) {\n  infoUploadAttachment(input: $input) {\n    attachmentSlug\n  }\n}\n"
  }
};
})();
(node as any).hash = '29cba60b775886821e4f14634f0acce4';
export default node;
