/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ContainerInfoCard_container = {
    readonly slug: string;
    readonly pickupComment: string | null;
    readonly infoFields: ReadonlyArray<{
        readonly label: string;
        readonly value: string | null;
    }>;
    readonly " $refType": "ContainerInfoCard_container";
};
export type ContainerInfoCard_container$data = ContainerInfoCard_container;
export type ContainerInfoCard_container$key = {
    readonly " $data"?: ContainerInfoCard_container$data;
    readonly " $fragmentRefs": FragmentRefs<"ContainerInfoCard_container">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContainerInfoCard_container",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pickupComment",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "KeyValueField",
      "kind": "LinkedField",
      "name": "infoFields",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Container",
  "abstractKey": null
};
(node as any).hash = 'd46605eaf687a989ebec7d5a017d9cee';
export default node;
