/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type infoUnwatchItemInput = {
    clientMutationId?: string | null;
    entitySlug: string;
    entityType: string;
};
export type WatchersUnwatchMutationVariables = {
    input: infoUnwatchItemInput;
};
export type WatchersUnwatchMutationResponse = {
    readonly infoUnwatchItem: {
        readonly clientMutationId: string | null;
    } | null;
};
export type WatchersUnwatchMutation = {
    readonly response: WatchersUnwatchMutationResponse;
    readonly variables: WatchersUnwatchMutationVariables;
};



/*
mutation WatchersUnwatchMutation(
  $input: infoUnwatchItemInput!
) {
  infoUnwatchItem(input: $input) {
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "infoUnwatchItemPayload",
    "kind": "LinkedField",
    "name": "infoUnwatchItem",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WatchersUnwatchMutation",
    "selections": (v1/*: any*/),
    "type": "ApplicationCommand",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WatchersUnwatchMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "2653bc551e0a68501d6eacda25337974",
    "id": null,
    "metadata": {},
    "name": "WatchersUnwatchMutation",
    "operationKind": "mutation",
    "text": "mutation WatchersUnwatchMutation(\n  $input: infoUnwatchItemInput!\n) {\n  infoUnwatchItem(input: $input) {\n    clientMutationId\n  }\n}\n"
  }
};
})();
(node as any).hash = '0db0b295dfcce288bac70623734c64d5';
export default node;
