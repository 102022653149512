import React, { Suspense, useState } from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import styled from 'styled-components'
import { ContainerInfoCard_container$key } from '../__generated__/ContainerInfoCard_container.graphql'
import { useFragment, useLazyLoadQuery } from 'react-relay/hooks'
import { DialogContent, DialogTitle, Paper, Typography } from '@material-ui/core'
import KeyValueField from './KeyValueField'
import { CustomsInfoTextContainer, CustomsTextLabel } from '../pages/ExternalShipment/ExternalShipmentInfoCard'
import Button from './Button'
import OdysseyDialog from './OdysseyDialog'
import { FormFieldsContainer, StyledForm, TitleAndContentContainer } from './ShipmentInfoCard'
import NiceFormik from './Form/NiceFormik'
import { ContainerInfoCardEditDialogQuery } from '../__generated__/ContainerInfoCardEditDialogQuery.graphql'
import DialogFormActionBar from './Form/DialogFormActionBar'
import { Alert, Skeleton } from '@material-ui/lab'
import InputField from './Form/InputField'
import useNiceMutation from '../mutations/useNiceMutation'
import { ContainerInfoCardEditMutation } from '../__generated__/ContainerInfoCardEditMutation.graphql'
import GeneratableAtbItem from './GeneratableAtbItem'

interface Props {
  container: ContainerInfoCard_container$key
}

const RootPaper = styled(Paper)`
  padding: 16px;
  margin-bottom: 16px;
`

const TitleContainer = styled.div`
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const VerticalGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 8px;
`

const KeyFieldsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 8px;
`

const FlexContainer = styled.div`
  display: flex;
  gap: 10px;
`

interface EditFormProps {
  slug: string
  onClose: () => void
}

const ContainerInfoCard: React.FC<Props> = ({ container: data }) => {
  const container = useFragment(fragment, data)
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const onEditClose = () => setEditDialogOpen(false)

  return (
    <>
      <RootPaper variant='outlined'>
        <TitleAndContentContainer>
          <TitleContainer>
            <Typography variant='h6'>Info</Typography>
            <Button onClick={() => setEditDialogOpen(true)}>Edit</Button>
          </TitleContainer>
          <VerticalGrid>
            <KeyFieldsContainer>
              {container.infoFields.slice(0, 6).map((field) => (
                <>
                  <KeyValueField key={field.label} label={field.label} value={field.value} />
                </>
              ))}
            </KeyFieldsContainer>
            <CustomsTextLabel />
            <KeyFieldsContainer>
              {container.infoFields.slice(6, 12).map((field) => (
                <>
                  <KeyValueField key={field.label} label={field.label} value={field.value} />
                </>
              ))}
            </KeyFieldsContainer>
            <CustomsTextLabel />
            <KeyFieldsContainer>
              {container.infoFields.slice(12, 15).map((field) => (
                <>
                  <KeyValueField key={field.label} label={field.label} value={field.value} />
                </>
              ))}
            </KeyFieldsContainer>
            <div>
              <CustomsTextLabel>Pickup Comment</CustomsTextLabel>
              <CustomsInfoTextContainer>{container.pickupComment || '--'}</CustomsInfoTextContainer>
            </div>
            <>
              <FlexContainer>
                <GeneratableAtbItem slug={container.slug} />
              </FlexContainer>
            </>
          </VerticalGrid>
        </TitleAndContentContainer>
      </RootPaper>
      <OdysseyDialog open={editDialogOpen} fullWidth onClose={onEditClose} scroll='paper' style={{ maxWidth: '640px', margin: 'auto' }}>
        <DialogTitle>Editing Info</DialogTitle>
        <Suspense fallback={<EditForm.Skeleton onClose={onEditClose} />}>
          <EditForm slug={container.slug} onClose={onEditClose} />
        </Suspense>
      </OdysseyDialog>
    </>
  )
}

const EditForm: React.FC<EditFormProps> & { Skeleton: React.FC<{ onClose?: () => void }> } = ({ slug, onClose }) => {
  const data = useLazyLoadQuery<ContainerInfoCardEditDialogQuery>(query, { slug: slug })
  const fields = data.infoUpdateContainerInfoForm.formSchema.fieldSet
  const filterFields = fields.filter((field) => field.name !== 'slug')

  // fields.filter(field => field.name !== 'slug')
  const fieldMaster = Object.fromEntries(fields.map((field) => [field.name, field]))
  const [mutationError, setMutationError] = useState<null | string>(null)
  const [commit] = useNiceMutation<ContainerInfoCardEditMutation>(mutation)

  return (
    <NiceFormik
      initialValues={data.infoUpdateContainerInfoForm.initialValue || {}}
      onSubmit={(values, actions) => {
        const processedValues = {
          ...values,
        }
        commit({
          variables: {
            input: processedValues as any,
          },
          onCompleted: (res, errors) => {
            actions.setSubmitting(false)
            if (errors) {
              setMutationError(errors.map((err) => err.message).join(', '))
            } else {
              onClose()
            }
          },
        })
      }}
      formSchema={fields}
    >
      {({ submitForm }) => (
        <StyledForm>
          {mutationError && <Alert severity='error'>{mutationError}</Alert>}
          <DialogContent dividers={true}>
            <FormFieldsContainer>
              {filterFields.map((infoField, index) => {
                return <InputField key={`${infoField.name}-${index}`} of={infoField.name} field={fieldMaster[infoField.name]} />
              })}
            </FormFieldsContainer>
          </DialogContent>
          <DialogFormActionBar
            onCancel={() => onClose()}
            onSubmit={() => {
              submitForm()
            }}
            cancelCta='Cancel'
            saveCta='Save'
          />
        </StyledForm>
      )}
    </NiceFormik>
  )
}

EditForm.Skeleton = ({ onClose }) => (
  <>
    <DialogContent dividers={true}>
      <FormFieldsContainer>
        <Skeleton variant='rect' height='56px' />
        <Skeleton variant='rect' height='56px' />
        <Skeleton variant='rect' height='56px' />
        <Skeleton variant='rect' height='56px' />
      </FormFieldsContainer>
    </DialogContent>
    <DialogFormActionBar.Skeleton onCancel={onClose} />
  </>
)

const fragment = graphql`
  fragment ContainerInfoCard_container on Container {
    slug
    pickupComment
    infoFields {
      label
      value
    }
  }
`

const query = graphql`
  query ContainerInfoCardEditDialogQuery($slug: String!) {
    infoUpdateContainerInfoForm(slug: $slug) {
      formSchema {
        fieldSet {
          name
          type
          required
          title
          description
          format
          enum
          enumTitles
        }
      }
      initialValue {
        slug
        bookingNumber
        containerSize
        pickupReference
        pickupLocationSlug
        turninReference
        turninLocationSlug
        latestPickupDate
        pickupDate
        latestTurninDate
        pickupComment
        terminalDate
        warehouseInDate
        warehouseOutDate
        released
        hazardous
        terminalStop
      }
    }
  }
`

const mutation = graphql`
  mutation ContainerInfoCardEditMutation($input: infoUpdateContainerInfoInput!) {
    infoUpdateContainerInfo(input: $input) {
      clientMutationId
    }
  }
`

export default ContainerInfoCard
