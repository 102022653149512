/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type infoChangeContainerStageInput = {
    clientMutationId?: string | null;
    slug: string;
    stage: string;
};
export type ContainerWorkflowChangeStageMutationVariables = {
    input: infoChangeContainerStageInput;
};
export type ContainerWorkflowChangeStageMutationResponse = {
    readonly infoChangeContainerStage: {
        readonly container: {
            readonly " $fragmentRefs": FragmentRefs<"ContainerWorkflow_container">;
        };
    } | null;
};
export type ContainerWorkflowChangeStageMutation = {
    readonly response: ContainerWorkflowChangeStageMutationResponse;
    readonly variables: ContainerWorkflowChangeStageMutationVariables;
};



/*
mutation ContainerWorkflowChangeStageMutation(
  $input: infoChangeContainerStageInput!
) {
  infoChangeContainerStage(input: $input) {
    container {
      ...ContainerWorkflow_container
      id
    }
  }
}

fragment ContainerWorkflow_container on Container {
  slug
  workflowStages {
    value
    label
    ownedBy
    status
    ...Stage_stage
  }
}

fragment Stage_stage on WorkflowStage {
  value
  status
  label
  completedAt
  ownedBy
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ContainerWorkflowChangeStageMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "infoChangeContainerStagePayload",
        "kind": "LinkedField",
        "name": "infoChangeContainerStage",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Container",
            "kind": "LinkedField",
            "name": "container",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ContainerWorkflow_container"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ApplicationCommand",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ContainerWorkflowChangeStageMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "infoChangeContainerStagePayload",
        "kind": "LinkedField",
        "name": "infoChangeContainerStage",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Container",
            "kind": "LinkedField",
            "name": "container",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "slug",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "WorkflowStage",
                "kind": "LinkedField",
                "name": "workflowStages",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "value",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "label",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "ownedBy",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "completedAt",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3f4dde81d9720d0cf4ff7f22b679c873",
    "id": null,
    "metadata": {},
    "name": "ContainerWorkflowChangeStageMutation",
    "operationKind": "mutation",
    "text": "mutation ContainerWorkflowChangeStageMutation(\n  $input: infoChangeContainerStageInput!\n) {\n  infoChangeContainerStage(input: $input) {\n    container {\n      ...ContainerWorkflow_container\n      id\n    }\n  }\n}\n\nfragment ContainerWorkflow_container on Container {\n  slug\n  workflowStages {\n    value\n    label\n    ownedBy\n    status\n    ...Stage_stage\n  }\n}\n\nfragment Stage_stage on WorkflowStage {\n  value\n  status\n  label\n  completedAt\n  ownedBy\n}\n"
  }
};
})();
(node as any).hash = 'a51f3b239dce17e808b7e491b6e58223';
export default node;
