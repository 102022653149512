import { graphql } from 'babel-plugin-relay/macro'
import NiceFormik from './Form/NiceFormik'
import React from 'react'
import { DialogActions, DialogContent } from '@material-ui/core'
import DraftShipmentForm from './DraftShipmentForm'
import { Form } from 'formik'
import LoadingDots from './LoadingDots'
import { useLazyLoadQuery } from 'react-relay/hooks'
import { DraftShipmentAddQuery } from '../__generated__/DraftShipmentAddQuery.graphql'
import Button from './Button'
import useNiceMutation from '../mutations/useNiceMutation'
import { DraftShipmentAddMutation } from '../__generated__/DraftShipmentAddMutation.graphql'
import { externalInputHashToLinkedContacts } from './Form/ExternalLinkedContactsField'

interface Props {
  open: boolean
  onClose: () => void
}

interface FormValues {
  firstName: string
  lastName: string
  salutation: string
  format: string
  address: string
  place: string
  country: string
}

const DraftShipmentAdd: React.FC<Props> = (props) => {
  const data = useLazyLoadQuery<DraftShipmentAddQuery>(query, {})
  const fields = data.infoAddDraftShipmentForm.formSchema.fieldSet
  const fieldMaster = Object.fromEntries(fields.map((field) => [field.name, field]))
  const [commit, loading] = useNiceMutation<DraftShipmentAddMutation>(mutation)
  const initialValues = {
    description: '',
    containerSlug: '',
    shipmentType: '',
    vin: '',
    contactCreate: false,
    firstName: '',
    lastName: '',
    salutation: '',
    format: '',
    address: '',
    place: '',
    country: '',
  }

  return (
    <>
      <NiceFormik
        initialValues={initialValues}
        validate={(values) => {
          const errors: Partial<FormValues> = {}
          const validateField = (field: keyof FormValues, message: string) => {
            if (!values[field]) {
              errors[field] = message
            }
          }
          if (values.contactCreate) {
            validateField('firstName', 'First name is a required field')
            validateField('lastName', 'Last name is a required field')
            validateField('lastName', 'Last name is a required field')
            validateField('salutation', 'Salutation is a required field')
            validateField('format', 'Format is a required field')
            validateField('address', 'Address is a required field')
            validateField('place', 'City is a required field')
            validateField('country', 'Country is a required field')
          }
          return errors
        }}
        onSubmit={(values, actions) => {
          const processedValues = {
            ...values,
            linkedContacts: externalInputHashToLinkedContacts((values as any).linkedContacts),
          }
          commit({
            variables: {
              input: processedValues as any,
            },
            onError: () => actions.setSubmitting(false),
            onCompleted: (res, errors) => {
              actions.setSubmitting(false)
              if (!errors) {
                props.onClose()
              }
            },
          })
        }}
        formSchema={fields}
      >
        {({ submitForm }) => (
          <Form>
            <DialogContent dividers={true}>
              <DraftShipmentForm fieldMaster={fieldMaster} />
            </DialogContent>
            <DialogActions>
              <Button onClick={props.onClose} variant='contained'>
                Cancel
              </Button>
              <Button onClick={submitForm} variant='contained'>
                {loading ? <LoadingDots /> : 'Save'}
              </Button>
            </DialogActions>
          </Form>
        )}
      </NiceFormik>
    </>
  )
}

const query = graphql`
  query DraftShipmentAddQuery {
    infoAddDraftShipmentForm {
      formSchema {
        fieldSet {
          name
          type
          required
          title
          description
          format
          enum
          enumTitles
          placeholder
        }
      }
    }
  }
`
const mutation = graphql`
  mutation DraftShipmentAddMutation($input: infoAddDraftShipmentInput!) {
    infoAddDraftShipment(input: $input) {
      clientMutationId
    }
  }
`
export default DraftShipmentAdd
