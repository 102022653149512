import { DialogActions, DialogContent, FormControlLabel, makeStyles, Switch, TextField } from '@material-ui/core'
import React, { useState } from 'react'
import { VoyageContainerReleaseWizard_voyage } from '../__generated__/VoyageContainerReleaseWizard_voyage.graphql'
import Button from './Button'
import { SendEmailDialogInternal } from './Email/SendEmailDialog'

interface Props {
  voyage: VoyageContainerReleaseWizard_voyage
  emailSend: () => void
  slug: string
}
const useStyles = makeStyles({
  noDataText: {
    fontSize: '1.25rem',
    color: '#e0e0e0',
    textAlign: 'center',
    padding: '16px',
    fontWeight: 400,
    margin: '0px',
  },
})

const VoyageReleaseRequestEmail: React.FC<Props> = ({ voyage, emailSend, slug }) => {
  const [searchQuery, setSearchQuery] = useState('')
  const [emailDialog, setEmailDialog] = useState<boolean>(false)
  const classes = useStyles()
  const [containers, setContainers] = useState<string[]>([])
  const handleChange = (slug: string, checked: boolean) => {
    if (checked) {
      setContainers([...containers, slug])
    } else {
      setContainers(containers.filter((containerSlug) => containerSlug !== slug))
    }
  }
  return (
    <>
      {emailDialog ? (
        <SendEmailDialogInternal
          entityType='voyages'
          entitySlug={slug}
          onClose={emailSend}
          initialTemplateSlug='09-2-release-request-containers'
          bodyContentFilter={containers}
        />
      ) : (
        <>
          <DialogContent>
            {voyage.confirmedContainers.length === 0 && <h6 className={classes.noDataText}>No releasable containers</h6>}
            {voyage.confirmedContainers.length !== 0 && (
              <>
                <TextField
                  size='small'
                  variant='standard'
                  value={searchQuery}
                  label='Search'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value as string)}
                />
                <p>Please select the containers and Request Release via Email.</p>
              </>
            )}
            {voyage.confirmedContainers
              ?.filter((item) => item.bookingNumber && item.containerItem.title.toLowerCase().includes(searchQuery.toLowerCase()))
              .map((container) => {
                return (
                  <div key={container.slug}>
                    <FormControlLabel
                      label={`${container.containerItem.title} : ${container.bookingNumber}`}
                      control={
                        <Switch
                          checked={containers.includes(container.slug)}
                          onChange={(e) => handleChange(container.slug, e.target.checked)}
                          color='primary'
                        />
                      }
                      labelPlacement='end'
                    />
                  </div>
                )
              })}
          </DialogContent>
          {voyage.confirmedContainers.length !== 0 && (
            <DialogActions>
              <Button variant='outlined' onClick={() => setEmailDialog(true)}>
                Send Email
              </Button>
            </DialogActions>
          )}
        </>
      )}
    </>
  )
}

export default VoyageReleaseRequestEmail
