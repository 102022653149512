/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type TemplateEntityTypeEnum = "contacts" | "containers" | "shipments" | "%future added value";
export type ContainerPickSentDocumentQueryVariables = {
    entityType: TemplateEntityTypeEnum;
    entitySlug: string;
    templateSlug: string;
};
export type ContainerPickSentDocumentQueryResponse = {
    readonly generatableDocumentValidation: {
        readonly errors: ReadonlyArray<string>;
    };
};
export type ContainerPickSentDocumentQuery = {
    readonly response: ContainerPickSentDocumentQueryResponse;
    readonly variables: ContainerPickSentDocumentQueryVariables;
};



/*
query ContainerPickSentDocumentQuery(
  $entityType: TemplateEntityTypeEnum!
  $entitySlug: String!
  $templateSlug: String!
) {
  generatableDocumentValidation(entityType: $entityType, entitySlug: $entitySlug, templateSlug: $templateSlug) {
    errors
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "entitySlug"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "entityType"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "templateSlug"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "entitySlug",
        "variableName": "entitySlug"
      },
      {
        "kind": "Variable",
        "name": "entityType",
        "variableName": "entityType"
      },
      {
        "kind": "Variable",
        "name": "templateSlug",
        "variableName": "templateSlug"
      }
    ],
    "concreteType": "GeneratableDocument",
    "kind": "LinkedField",
    "name": "generatableDocumentValidation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ContainerPickSentDocumentQuery",
    "selections": (v3/*: any*/),
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "ContainerPickSentDocumentQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "2cdd985ba0d7df79e1c1daba0520dc1e",
    "id": null,
    "metadata": {},
    "name": "ContainerPickSentDocumentQuery",
    "operationKind": "query",
    "text": "query ContainerPickSentDocumentQuery(\n  $entityType: TemplateEntityTypeEnum!\n  $entitySlug: String!\n  $templateSlug: String!\n) {\n  generatableDocumentValidation(entityType: $entityType, entitySlug: $entitySlug, templateSlug: $templateSlug) {\n    errors\n  }\n}\n"
  }
};
})();
(node as any).hash = '57bcae58152d7f9cec691a5df93b0654';
export default node;
