/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type StageEntityTypeEnum = "containers" | "shipments" | "%future added value";
export type infoPickEntityStageInput = {
    clientMutationId?: string | null;
    entityType: StageEntityTypeEnum;
    entitySlug: string;
    stage?: string | null;
    pickedBy: string;
};
export type ContainerWorkflowPickStageMutationVariables = {
    input: infoPickEntityStageInput;
};
export type ContainerWorkflowPickStageMutationResponse = {
    readonly infoPickEntityStage: {
        readonly clientMutationId: string | null;
    } | null;
};
export type ContainerWorkflowPickStageMutation = {
    readonly response: ContainerWorkflowPickStageMutationResponse;
    readonly variables: ContainerWorkflowPickStageMutationVariables;
};



/*
mutation ContainerWorkflowPickStageMutation(
  $input: infoPickEntityStageInput!
) {
  infoPickEntityStage(input: $input) {
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "infoPickEntityStagePayload",
    "kind": "LinkedField",
    "name": "infoPickEntityStage",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ContainerWorkflowPickStageMutation",
    "selections": (v1/*: any*/),
    "type": "ApplicationCommand",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ContainerWorkflowPickStageMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "47dd38d48a5125de4b84f07ee2c2fbd2",
    "id": null,
    "metadata": {},
    "name": "ContainerWorkflowPickStageMutation",
    "operationKind": "mutation",
    "text": "mutation ContainerWorkflowPickStageMutation(\n  $input: infoPickEntityStageInput!\n) {\n  infoPickEntityStage(input: $input) {\n    clientMutationId\n  }\n}\n"
  }
};
})();
(node as any).hash = 'bca2af67aa67e785e8bf9fef43559f0a';
export default node;
