import React from 'react'
import { PaginationContainer, Root, useStyles } from './TaxAssessments'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { Pagination, Skeleton } from '@material-ui/lab'
import { graphql } from 'babel-plugin-relay/macro'
import { VinScrapingFailureLogsWithParams_list$key } from '../../__generated__/VinScrapingFailureLogsWithParams_list.graphql'
import { useFragment, useLazyLoadQuery } from 'react-relay/hooks'
import { parse } from 'qs'
import useWorkspaceNavigate from '../../hooks/useWorkspaceNavigate'
import { VinScrapingFailureLogsQuery } from '../../__generated__/VinScrapingFailureLogsQuery.graphql'

const VinScrapingFailureLogs: React.FC & { Skeleton: React.FC } = () => {
  const classes = useStyles()
  const queryVariables = parse(location.search, { ignoreQueryPrefix: true })
  const page = Number.parseInt(queryVariables.page as string) || 1
  const perPage = Number.parseInt(queryVariables.perPage as string) || 10
  const wsNavigate = useWorkspaceNavigate()
  const changePageTo = (newPage: number) => wsNavigate.relative({ page: newPage })
  const data = useLazyLoadQuery<VinScrapingFailureLogsQuery>(query, { page: page, perPage: perPage })

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size='medium' aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeader}>Shipment</TableCell>
            <TableCell className={classes.tableHeader}>VIN</TableCell>
            <TableCell className={classes.tableHeader}>Retry count</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <VinScrapingFailureLogsWithParams
            page={page}
            perPage={perPage}
            onPageChange={(newPage) => changePageTo(newPage)}
            list={data.vinScrapingFailureLogs}
          />
        </TableBody>
      </Table>
    </TableContainer>
  )
}

interface Params {
  page: number
  perPage: number
  onPageChange: (page: number) => void
  list: VinScrapingFailureLogsWithParams_list$key
}

const VinScrapingFailureLogsWithParams: React.FC<Params> = ({ page, onPageChange, list: data }) => {
  const list = useFragment(fragmentVinScrapingFailureLog, data)
  return (
    <>
      {list.vinScrapeLogs?.map((log) => (
        <>
          <TableRow key={log.shipmentSlug}>
            <TableCell>
              <a href={log.hyperlink as string} target='_blank' rel='noopener noreferrer'>
                {log.shipmentSlug}
              </a>
            </TableCell>
            <TableCell>{log.vin}</TableCell>
            <TableCell>{log.retryCount}</TableCell>
          </TableRow>
        </>
      ))}
      {list.paginationMeta && list.paginationMeta.totalPages > 1 && (
        <TableRow>
          <TableCell colSpan={5}>
            <PaginationContainer>
              <Pagination shape='rounded' count={list.paginationMeta.totalPages} page={page} onChange={(_, newPage) => onPageChange(newPage)} />
            </PaginationContainer>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}

VinScrapingFailureLogs.Skeleton = () => (
  <Root>
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <Skeleton variant='rect' height='50px'></Skeleton>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <Skeleton variant='rect' height='80px'></Skeleton>
          </TableRow>
          <TableRow>
            <Skeleton variant='rect' height='80px'></Skeleton>
          </TableRow>
          <TableRow>
            <Skeleton variant='rect' height='80px'></Skeleton>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  </Root>
)

const query = graphql`
  query VinScrapingFailureLogsQuery($page: Int, $perPage: Int) {
    vinScrapingFailureLogs(page: $page, perPage: $perPage) {
      ...VinScrapingFailureLogsWithParams_list
    }
  }
`

const fragmentVinScrapingFailureLog = graphql`
  fragment VinScrapingFailureLogsWithParams_list on VinScrapeFailureLogs {
    vinScrapeLogs {
      shipmentSlug
      vin
      retryCount
      hyperlink
    }
    paginationMeta {
      totalPages
    }
  }
`

export default VinScrapingFailureLogs
