/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type infoChangeShipmentStageInput = {
    clientMutationId?: string | null;
    slug: string;
    stage: string;
    handoverDate?: string | null;
};
export type ShipmentsTableStageChangeMutationVariables = {
    input: infoChangeShipmentStageInput;
};
export type ShipmentsTableStageChangeMutationResponse = {
    readonly infoChangeShipmentStage: {
        readonly clientMutationId: string | null;
        readonly shipment: {
            readonly slug: string;
            readonly automaticHandover: boolean | null;
            readonly stage: string;
        };
    } | null;
};
export type ShipmentsTableStageChangeMutation = {
    readonly response: ShipmentsTableStageChangeMutationResponse;
    readonly variables: ShipmentsTableStageChangeMutationVariables;
};



/*
mutation ShipmentsTableStageChangeMutation(
  $input: infoChangeShipmentStageInput!
) {
  infoChangeShipmentStage(input: $input) {
    clientMutationId
    shipment {
      slug
      automaticHandover
      stage
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientMutationId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "automaticHandover",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stage",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ShipmentsTableStageChangeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "infoChangeShipmentStagePayload",
        "kind": "LinkedField",
        "name": "infoChangeShipmentStage",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Shipment",
            "kind": "LinkedField",
            "name": "shipment",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ApplicationCommand",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ShipmentsTableStageChangeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "infoChangeShipmentStagePayload",
        "kind": "LinkedField",
        "name": "infoChangeShipmentStage",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Shipment",
            "kind": "LinkedField",
            "name": "shipment",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "98137966d2aef12949a9eb88122cdbb1",
    "id": null,
    "metadata": {},
    "name": "ShipmentsTableStageChangeMutation",
    "operationKind": "mutation",
    "text": "mutation ShipmentsTableStageChangeMutation(\n  $input: infoChangeShipmentStageInput!\n) {\n  infoChangeShipmentStage(input: $input) {\n    clientMutationId\n    shipment {\n      slug\n      automaticHandover\n      stage\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '72c5dfa0a1467a60ac752f1527689c05';
export default node;
