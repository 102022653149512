/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type StageStatusEnum = "active" | "completed" | "incomplete" | "%future added value";
export type ExternalShowPageListItem_listItem = {
    readonly slug: string;
    readonly title: string;
    readonly itemType: string;
    readonly image: {
        readonly " $fragmentRefs": FragmentRefs<"ItemImage_data">;
    } | null;
    readonly path: string | null;
    readonly stages: ReadonlyArray<{
        readonly key: string;
        readonly status: StageStatusEnum;
        readonly label: string;
        readonly value: string;
    }> | null;
    readonly badges: ReadonlyArray<{
        readonly key: string;
        readonly " $fragmentRefs": FragmentRefs<"Badge_data">;
    }> | null;
    readonly lines: ReadonlyArray<{
        readonly key: string;
        readonly " $fragmentRefs": FragmentRefs<"SemanticLine_data">;
    }> | null;
    readonly keyFields: ReadonlyArray<{
        readonly key: string;
        readonly " $fragmentRefs": FragmentRefs<"KeyField_data">;
    }> | null;
    readonly " $refType": "ExternalShowPageListItem_listItem";
};
export type ExternalShowPageListItem_listItem$data = ExternalShowPageListItem_listItem;
export type ExternalShowPageListItem_listItem$key = {
    readonly " $data"?: ExternalShowPageListItem_listItem$data;
    readonly " $fragmentRefs": FragmentRefs<"ExternalShowPageListItem_listItem">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExternalShowPageListItem_listItem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "itemType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ItemImage_data"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "path",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Stage",
      "kind": "LinkedField",
      "name": "stages",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Badge",
      "kind": "LinkedField",
      "name": "badges",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Badge_data"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SemanticLine",
      "kind": "LinkedField",
      "name": "lines",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SemanticLine_data"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "KeyField",
      "kind": "LinkedField",
      "name": "keyFields",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "KeyField_data"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ExternalListItem",
  "abstractKey": null
};
})();
(node as any).hash = '081256e0da673679dd5cd2c6318ff3d4';
export default node;
