import { Avatar, Card, IconButton, Menu, MenuItem, Paper, Typography } from '@material-ui/core'
import { ArrowDropDown, ChevronRight } from '@material-ui/icons'
import { graphql } from 'babel-plugin-relay/macro'
import React, { useRef, useState } from 'react'
import { useLazyLoadQuery } from 'react-relay/hooks'
import styled from 'styled-components'
import Badge from '../../components/Badge'
import SemanticLine from '../../components/SemanticLine'
import useWorkspaceNavigate from '../../hooks/useWorkspaceNavigate'
import { isSmall, media, isMobile } from '../../theme'
import { DashboardHeaderQuery, DateFilterEnum } from '../../__generated__/DashboardHeaderQuery.graphql'
import Button from '../../components/Button'

interface Props {
  my: string
  handle: string
  includeArchived: string
  dateFilter: DateFilterEnum
}

const Titlebar = styled(Paper)`
  position: relative;
  max-width: 1000px;
  width: 100%;
  height: 72px;
  display: flex;
  align-items: center;
  padding: 8px;
  margin-bottom: 25px;

  ${media.small`
    height: 90px;
  `}
`

const TitlebarContent = styled.div`
  display: flex;
  align-items: center;
`

const Title = styled(Typography)`
  margin-right: 8px;
  font-weight: 700;
`

const ParentTitleContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 4px;
  border-radius: 4px;
  min-width: 200px;
`

const SeperatorContainer = styled.div`
  margin: 0 8px;
`

const DashboardTitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const DashboardTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

const TitleContent = styled.div`
  width: 100%;
  padding: 25px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${media.small`
    padding: 0px;
  `}
`

const ContianerProfileImage = styled(Avatar)`
  margin-right: 8px;
  height: 40px;
  width: 40px;
  border: 4px solid ${(props) => props.theme.palette.background.paper};
  border-radius: 8px;
`

const ProfileImage = styled(Avatar)`
  margin-right: 16px;
  height: 100px;
  width: 100px;
  border: 4px solid ${(props) => props.theme.palette.background.paper};
`

const DetailsCard = styled(Card)`
  width: 100%;
  max-width: 770px;
  padding: 16px;
`

const DashboardHeader: React.FC<Props> = ({ handle, includeArchived, dateFilter }) => {
  const [open, setOpen] = useState(false)
  const shipmentMenuRef = useRef(null)
  const toggleArchivedVisibility = () => {
    includeArchived = includeArchived === 'true' ? 'false' : 'true'
    wsNavigate.relative({
      includeArchived: includeArchived,
    })
  }

  const { dashboardHeaderQuery } = useLazyLoadQuery<DashboardHeaderQuery>(query, {
    handle: handle,
    includeArchived: includeArchived,
    dateFilter: dateFilter,
  })
  const wsNavigate = useWorkspaceNavigate()

  const handleClose = () => {
    setOpen(false)
  }
  return (
    <>
      <TitleContent>
        <Titlebar variant='outlined'>
          {!isSmall() && (
            <>
              <ParentTitleContainer>
                <ContianerProfileImage src={dashboardHeaderQuery?.parentHandle?.image?.url || ''} />
                <TitlebarContent>
                  <Title variant='subtitle1'>{dashboardHeaderQuery?.parentHandle?.name}</Title>
                </TitlebarContent>
              </ParentTitleContainer>
              <SeperatorContainer>
                <ChevronRight />
              </SeperatorContainer>
            </>
          )}
          <DashboardTitleContainer ref={shipmentMenuRef}>
            <DashboardTitle>
              <ProfileImage src={dashboardHeaderQuery?.selectedHandle?.image?.url as string} />
              <div>
                <TitlebarContent>
                  <Title variant='h5'>
                    {dashboardHeaderQuery?.selectedHandle?.title}
                    {"'"}s dashboard
                  </Title>
                  {dashboardHeaderQuery?.selectedHandle?.badges?.map((badge, idx) => (
                    <Badge key={idx} data={badge} />
                  ))}
                </TitlebarContent>
                {!isMobile() && dashboardHeaderQuery?.selectedHandle?.lines?.map((line, idx) => <SemanticLine key={idx} data={line} />)}
              </div>
            </DashboardTitle>
            <IconButton onClick={() => setOpen(true)}>
              <ArrowDropDown />
            </IconButton>
            <Menu
              open={open}
              anchorEl={shipmentMenuRef.current as HTMLDivElement | null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              onClose={handleClose}
            >
              {dashboardHeaderQuery?.availableHandles?.map((selectedHandle) => (
                <MenuItem
                  key={selectedHandle.slug}
                  selected={handle === selectedHandle.slug}
                  onClick={() => {
                    wsNavigate(`/@${selectedHandle.slug}`)
                    handleClose()
                  }}
                >
                  {selectedHandle.title}
                  {"'"}s dashboard
                </MenuItem>
              ))}
            </Menu>
          </DashboardTitleContainer>
        </Titlebar>
        <DetailsCard variant='outlined'>
          {dashboardHeaderQuery?.summary}&nbsp;&nbsp;&nbsp;&nbsp;
          <Button variant='outlined' onClick={toggleArchivedVisibility}>
            {includeArchived === 'true' ? 'Hide Archived' : 'Include Archived'}
          </Button>
        </DetailsCard>
      </TitleContent>
    </>
  )
}

export default DashboardHeader

const query = graphql`
  query DashboardHeaderQuery($handle: String!, $includeArchived: String, $dateFilter: DateFilterEnum!) {
    dashboardHeaderQuery(handle: $handle, includeArchived: $includeArchived, dateFilter: $dateFilter) {
      summary
      parentHandle {
        slug
        name
        image {
          url
          ...ItemImage_data
        }
      }
      selectedHandle {
        slug
        title
        image {
          url
          ...ItemImage_data
        }
        badges {
          ...Badge_data
        }
        lines {
          ...SemanticLine_data
        }
      }
      availableHandles {
        slug
        title
      }
    }
  }
`
