import React, { useContext, useState } from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import styled from 'styled-components'

import useNiceMutation from '../../mutations/useNiceMutation'
import { GeneratableDocItemMutation, AttachmentEntityTypeEnum } from '../../__generated__/GeneratableDocItemMutation.graphql'
import { Divider, IconButton, Tooltip, TooltipProps, Typography } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import Button from '../Button'
import { GeneratableDocItem_document$key } from '../../__generated__/GeneratableDocItem_document.graphql'
import { useFragment } from 'react-relay/hooks'
import LoadingDots from '../LoadingDots'
import { Done, PriorityHigh } from '@material-ui/icons'
import EntityAttachmentListPreviewer from './EntityAttachmentListPreviewer'
import SessionContext from '../../SessionContext'
import { GeneratableDocItemExternalMutation } from '../../__generated__/GeneratableDocItemExternalMutation.graphql'

interface Props {
  entityType: AttachmentEntityTypeEnum
  entitySlug: string
  folderSlug: string
  document: GeneratableDocItem_document$key
  onGenerated?: () => void
}

const VerticalFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const GeneratableDoc = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
`

const HorizontalFlex = styled.div`
  display: flex;
  align-items: center;
`

const ErrorsTooltip = styled((props: TooltipProps) => <Tooltip classes={{ popper: props.className }} {...props} />)<TooltipProps>`
  & .MuiTooltip-tooltip {
    background-color: ${(props) => props.theme.palette.background.paper};
    color: ${(props) => props.theme.palette.text.primary};
    box-shadow: ${(props) => props.theme.shadows[3]};
  }

  & .MuiTooltip-arrow {
    color: ${(props) => props.theme.palette.background.paper};
  }
`

const ErrorsCountTypography = styled(Typography).attrs({ variant: 'subtitle2' })`
  margin-right: 4px;
`

const GeneratableDocItem: React.FC<Props> & { Skeleton: React.FC } = ({ document: data, onGenerated, entityType, entitySlug, folderSlug }) => {
  const [commitGenerate, generateIsInFlight] = useNiceMutation<GeneratableDocItemMutation>(generateMutation)
  const [commitGenerateExternal, externalGenerateIsInFlight] = useNiceMutation<GeneratableDocItemExternalMutation>(externalGenerateMutation)
  const [existingAttachmentPreviewOpen, setExistingAttachmentPreviewOpen] = useState(false)
  const document = useFragment(fragment, data)
  const { user } = useContext(SessionContext)
  const generateDoc = () => {
    commitGenerate({
      variables: {
        input: {
          entityType: entityType,
          entitySlug: entitySlug,
          folderSlug: folderSlug,
          templateSlug: document.templateSlug,
        },
      },
      onCompleted: (_, errors) => {
        if (!errors) {
          onGenerated && onGenerated()
        }
      },
    })
  }

  const externalGenerateDoc = () => {
    commitGenerateExternal({
      variables: {
        input: {
          entityType: entityType,
          entitySlug: entitySlug,
          folderSlug: folderSlug,
          templateSlug: document.templateSlug,
        },
      },
      onCompleted: (_, errors) => {
        if (!errors) {
          onGenerated && onGenerated()
        }
      },
    })
  }

  const hasErrors = document.errors.length > 0
  return (
    <>
      <GeneratableDoc key={document.templateSlug}>
        <VerticalFlexContainer>
          <Typography variant='h6'>{document.title}</Typography>
          <Typography variant='body2' color='textSecondary'>
            {document.docType}
          </Typography>
        </VerticalFlexContainer>
        <HorizontalFlex>
          {document.existingAttachment && (
            <ErrorsTooltip
              interactive
              arrow
              title={
                <>
                  <Typography variant='body1'>{document.existingAttachment.uploadedAtDisplay}</Typography>
                  <Typography variant='caption'>Click to preview</Typography>
                </>
              }
            >
              <IconButton color='primary' onClick={() => setExistingAttachmentPreviewOpen(true)}>
                <Done />
              </IconButton>
            </ErrorsTooltip>
          )}
          {hasErrors && (
            <ErrorsTooltip
              interactive
              arrow
              title={
                <>
                  {document.errors.map((error, idx) => (
                    <Typography key={idx} variant='body1'>
                      {error}
                    </Typography>
                  ))}
                </>
              }
            >
              <IconButton>
                <ErrorsCountTypography>{document.errors.length}</ErrorsCountTypography> <PriorityHigh />
              </IconButton>
            </ErrorsTooltip>
          )}

          {user?.contact?.contactType === 'internal' ? (
            <Button variant='outlined' size='small' onClick={generateDoc} disabled={generateIsInFlight || hasErrors}>
              {generateIsInFlight ? <LoadingDots variant='primary' /> : 'Generate'}
            </Button>
          ) : (
            <Button variant='outlined' size='small' onClick={externalGenerateDoc} disabled={externalGenerateIsInFlight || hasErrors}>
              {externalGenerateIsInFlight ? <LoadingDots variant='primary' /> : 'Generate'}
            </Button>
          )}
        </HorizontalFlex>
      </GeneratableDoc>
      <Divider />
      {document.existingAttachment && (
        <EntityAttachmentListPreviewer
          attachments={[document.existingAttachment]}
          intialPreviewedAttachmentSlug={document.existingAttachment.attachmentSlug}
          open={existingAttachmentPreviewOpen}
          onClose={() => setExistingAttachmentPreviewOpen(false)}
        />
      )}
    </>
  )
}

GeneratableDocItem.Skeleton = () => (
  <>
    <GeneratableDoc>
      <VerticalFlexContainer>
        <Skeleton>
          <Typography variant='h6'>.......................</Typography>
        </Skeleton>
        <Skeleton>
          <Typography variant='body2' color='textSecondary'>
            .................
          </Typography>
        </Skeleton>
      </VerticalFlexContainer>
      <Button variant='outlined' disabled>
        Generate
      </Button>
    </GeneratableDoc>
    <Divider />
  </>
)

const fragment = graphql`
  fragment GeneratableDocItem_document on GeneratableDocument {
    templateSlug
    title
    docType
    errors
    existingAttachment {
      attachmentSlug
      uploadedAtDisplay
      ...EntityAttachmentListPreviewer_attachment
    }
  }
`

const generateMutation = graphql`
  mutation GeneratableDocItemMutation($input: infoGenerateDocumentInput!) {
    infoGenerateDocument(input: $input) {
      clientMutationId
      shipment {
        slug
      }
      container {
        slug
        pickupOrders {
          attachmentSlug
          name
          isImage
        }
      }
    }
  }
`

const externalGenerateMutation = graphql`
  mutation GeneratableDocItemExternalMutation($input: infoGeneratePoaDocumentForExternalInput!) {
    infoGeneratePoaDocumentForExternal(input: $input) {
      clientMutationId
    }
  }
`

export default GeneratableDocItem
