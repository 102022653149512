import React, { Suspense, useState } from 'react'
import OdysseyDialog from '../OdysseyDialog'
import { Skeleton } from '@material-ui/lab'
import { DialogContent, DialogTitle, DialogActions, ListItem, makeStyles } from '@material-ui/core'
import List from '@material-ui/core/List'
import NiceFormik from '../../components/Form/NiceFormik'
import { useLazyLoadQuery } from 'react-relay/hooks'
import useNiceMutation from '../../mutations/useNiceMutation'
import { inputHashToLinkedContacts, linkedContactToInputHash } from '../../components/Form/LinkedContactsField'
import { graphql } from 'babel-plugin-relay/macro'
import { DetailsFieldsContainer } from '../../forms/Container/ContainerForm'
import InputField from '../Form/InputField'
import Button from '../Button'
import LoadingDots from '../LoadingDots'
import { ContainerPickSentFormQuery } from '../../__generated__/ContainerPickSentFormQuery.graphql'
import { ContainerPickSentMutation } from '../../__generated__/ContainerPickSentMutation.graphql'
import { ContainerPickSentStageChangeMutation } from '../../__generated__/ContainerPickSentStageChangeMutation.graphql'
import { ContainerPickSentDocumentQuery } from '../../__generated__/ContainerPickSentDocumentQuery.graphql'
import styled from 'styled-components'
import { ContainerPickSentDocumentGeneratMutation } from '../../__generated__/ContainerPickSentDocumentGeneratMutation.graphql'
import { ContainerPickSentAutomaticPickupEmailMutation } from '../../__generated__/ContainerPickSentAutomaticPickupEmailMutation.graphql'
import { media } from '../../theme'

interface Props {
  open: boolean
  slug: string
  onClose: () => void
  onSubmit?: (values: any) => void
}

const useStyles = makeStyles({
  errorItem: {
    color: 'red',
    padding: '0px',
  },
})

const ErrorContainer = styled.div`
  margin-top: 16px;
`

export const PickupComment = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 16px;
  margin-bottom: 16px;
  margin-top: 16px;
  ${media.small`
    grid-template-columns: auto;
  `}
`
const ContainerPickSent: React.FC<Props> = (props) => {
  return (
    <>
      <OdysseyDialog open={props.open} onClose={props.onClose}>
        <DialogTitle></DialogTitle>
        <Suspense fallback={<ContainerReleaseConfirmSkeleton {...props} />}>
          <ContainerPickSentContent {...props}></ContainerPickSentContent>
        </Suspense>
      </OdysseyDialog>
    </>
  )
}
const ContainerPickSentContent: React.FC<Props> = ({ onSubmit, onClose, slug }) => {
  const data = useLazyLoadQuery<ContainerPickSentFormQuery>(query, { slug: slug })
  const fields = data.infoUpdateContainerForm.formSchema.fieldSet
  const fieldMaster = Object.fromEntries(fields.map((field) => [field.name, field]))

  const documentData = useLazyLoadQuery<ContainerPickSentDocumentQuery>(document_query, {
    entityType: 'containers',
    entitySlug: slug,
    templateSlug: 'pickup',
  })
  const errors = documentData.generatableDocumentValidation.errors.filter((err) => err.includes('warehouse'))

  const [commit, loading] = useNiceMutation<ContainerPickSentMutation>(mutation)
  const [commitStageChange] = useNiceMutation<ContainerPickSentStageChangeMutation>(changeStageMutation)
  const [commitDocumentGeneration] = useNiceMutation<ContainerPickSentDocumentGeneratMutation>(documentGeneratMutation)
  const [commitEmailSend] = useNiceMutation<ContainerPickSentAutomaticPickupEmailMutation>(automaticPickupEmail)

  const [submitType, setSubmitType] = useState<string>('')

  const initialValue = {
    ...data.infoUpdateContainerForm.initialValue,
    linkedContacts: linkedContactToInputHash(data.infoUpdateContainerForm.initialValue?.linkedContacts?.nodes),
  }
  const classes = useStyles()

  const handleStageChange = () => {
    commitStageChange({
      variables: {
        input: {
          slug: slug,
          stage: 'pickup_sent',
        },
      },
      onCompleted: (res, errors) => {
        if (!errors) {
          onClose()
          if (submitType == 'automatic') {
            documentGenerate()
          }
        }
      },
    })
  }

  const documentGenerate = () => {
    commitDocumentGeneration({
      variables: { input: { slug: slug } },
      onCompleted: (res, errors) => {
        if (!errors) {
          handleEmailSent()
        }
      },
    })
  }

  const handleEmailSent = () => {
    commitEmailSend({
      variables: { input: { containerSlug: slug } },
    })
  }

  return (
    <>
      <NiceFormik
        initialValues={initialValue || {}}
        onSubmit={(values, actions) => {
          const processedValues = {
            ...values,
            linkedContacts: inputHashToLinkedContacts((values as any).linkedContacts),
          }
          if (onSubmit) {
            onSubmit(processedValues)
            return
          }
          commit({
            variables: {
              input: processedValues as any,
            },
            onError: () => actions.setSubmitting(false),
            onCompleted: (res, errors) => {
              actions.setSubmitting(false)
              if (!errors) {
                handleStageChange()
              }
            },
          })
        }}
        formSchema={fields}
      >
        {({ values, submitForm }) => (
          <>
            <DialogContent>
              <DetailsFieldsContainer>
                <InputField of='pickupReference' field={fieldMaster['pickupReference']} />
                <InputField of='turninReference' field={fieldMaster['turninReference']} />
                <InputField of='pickupLocationSlug' field={fieldMaster['pickupLocationSlug']} />
                <InputField of='turninLocationSlug' field={fieldMaster['turninLocationSlug']} />
                <InputField of='latestPickupDate' field={fieldMaster['latestPickupDate']} />
                <InputField of='latestTurninDate' field={fieldMaster['latestTurninDate']} />
              </DetailsFieldsContainer>
              <PickupComment>
                <InputField of='pickupComment' field={fieldMaster['pickupComment']} />
              </PickupComment>
              {errors.length > 0 && (
                <ErrorContainer>
                  Automatic Pick Sent is not possible.
                  <List>
                    {errors.map((err, index) => {
                      return (
                        <ListItem className={classes.errorItem} key={index}>
                          {err}
                        </ListItem>
                      )
                    })}
                  </List>
                </ErrorContainer>
              )}
            </DialogContent>
            <DialogActions>
              <Button variant='contained' onClick={() => onClose()}>
                Cancel
              </Button>
              <Button variant='contained' onClick={() => submitForm()}>
                {loading ? <LoadingDots /> : 'Manual'}
              </Button>
              <Button
                value='automatic'
                disabled={
                  !(values as any).pickupReference ||
                  !(values as any).turninReference ||
                  !(values as any).pickupLocationSlug ||
                  !(values as any).turninLocationSlug ||
                  !(values as any).latestPickupDate ||
                  !(values as any).latestTurninDate ||
                  errors.length > 0
                }
                variant='contained'
                onClick={(e) => {
                  e.preventDefault
                  setSubmitType('automatic')
                  setTimeout(() => {
                    submitForm()
                  }, 50)
                }}
              >
                {loading ? <LoadingDots /> : 'Automatic'}
              </Button>
            </DialogActions>
          </>
        )}
      </NiceFormik>
    </>
  )
}

const ContainerReleaseConfirmSkeleton: React.FC<Props> = () => (
  <>
    <DialogContent>
      <List>
        <ListItem>
          <Skeleton variant='rect' height='20px' width='100%' />
        </ListItem>
        <ListItem>
          <Skeleton variant='rect' height='20px' width='100%' />
        </ListItem>
      </List>
    </DialogContent>
  </>
)

const query = graphql`
  query ContainerPickSentFormQuery($slug: String!) {
    infoUpdateContainerForm(slug: $slug) {
      formSchema {
        fieldSet {
          name
          type
          required
          title
          description
          format
          enum
          enumTitles
          placeholder
        }
      }
      initialValue {
        slug
        containerNumber
        voyageSlug
        containerType
        pickupLocationSlug
        turninLocationSlug
        pickupReference
        turninReference
        latestPickupDate
        latestTurninDate
        shippingLineSlug
        pickupComment
        linkedContacts {
          nodes {
            role
            contactSlug
          }
        }
      }
    }
  }
`

const mutation = graphql`
  mutation ContainerPickSentMutation($input: infoUpdateContainerInput!) {
    infoUpdateContainer(input: $input) {
      clientMutationId
    }
  }
`
const changeStageMutation = graphql`
  mutation ContainerPickSentStageChangeMutation($input: infoChangeContainerStageInput!) {
    infoChangeContainerStage(input: $input) {
      clientMutationId
    }
  }
`

const document_query = graphql`
  query ContainerPickSentDocumentQuery($entityType: TemplateEntityTypeEnum!, $entitySlug: String!, $templateSlug: String!) {
    generatableDocumentValidation(entityType: $entityType, entitySlug: $entitySlug, templateSlug: $templateSlug) {
      errors
    }
  }
`

const documentGeneratMutation = graphql`
  mutation ContainerPickSentDocumentGeneratMutation($input: infoAutomaticPickupDocumentInput!) {
    infoAutomaticPickupDocument(input: $input) {
      clientMutationId
    }
  }
`
const automaticPickupEmail = graphql`
  mutation ContainerPickSentAutomaticPickupEmailMutation($input: infoAutomaticPickupEmailInput!) {
    infoAutomaticPickupEmail(input: $input) {
      clientMutationId
    }
  }
`
export default ContainerPickSent
