/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type infoSendLiveShareEmailInput = {
    clientMutationId?: string | null;
    slug: string;
};
export type LiveShareDrawerMutationVariables = {
    input: infoSendLiveShareEmailInput;
};
export type LiveShareDrawerMutationResponse = {
    readonly infoSendLiveShareEmail: {
        readonly clientMutationId: string | null;
    } | null;
};
export type LiveShareDrawerMutation = {
    readonly response: LiveShareDrawerMutationResponse;
    readonly variables: LiveShareDrawerMutationVariables;
};



/*
mutation LiveShareDrawerMutation(
  $input: infoSendLiveShareEmailInput!
) {
  infoSendLiveShareEmail(input: $input) {
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "infoSendLiveShareEmailPayload",
    "kind": "LinkedField",
    "name": "infoSendLiveShareEmail",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LiveShareDrawerMutation",
    "selections": (v1/*: any*/),
    "type": "ApplicationCommand",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LiveShareDrawerMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "28a2f204d3ed0eb12b2dca8c4be7f503",
    "id": null,
    "metadata": {},
    "name": "LiveShareDrawerMutation",
    "operationKind": "mutation",
    "text": "mutation LiveShareDrawerMutation(\n  $input: infoSendLiveShareEmailInput!\n) {\n  infoSendLiveShareEmail(input: $input) {\n    clientMutationId\n  }\n}\n"
  }
};
})();
(node as any).hash = '7e267e6ffc2c5530b6ccc7b6fa1689a7';
export default node;
