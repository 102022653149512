import { Button } from '@material-ui/core'
import styled from 'styled-components'

const SecondaryButton = styled(Button)`
  padding: 8px 12px;
  border-radius: 8px;
  background-color: #f2f2f2;
  font-weight: bold;
  text-transform: unset;
  font-size: 16px;

  &:hover {
    background-color: #f5f5f5;
  }
`

export default SecondaryButton
