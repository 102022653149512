import { Paper, Tooltip, Typography } from '@material-ui/core'
import { OfflineBolt } from '@material-ui/icons'
import { graphql } from 'babel-plugin-relay/macro'
import React from 'react'
import { useFragment } from 'react-relay/hooks'
import styled from 'styled-components'
import { ETACard_voyage$key } from '../__generated__/ETACard_voyage.graphql'

interface Props {
  voyage: ETACard_voyage$key
}

const RootCard = styled(Paper)`
  padding: 16px;
  margin-bottom: 16px;
`

const ETAText = styled(Typography)`
  font-size: 36px;
  font-weight: 300;
  display: flex;
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const LiveETAIconContainer = styled.div`
  margin-left: 8px;
`

const ETACard: React.FC<Props> = ({ voyage: data }) => {
  const voyage = useFragment(fragment, data)

  return (
    <RootCard variant='outlined'>
      <TitleContainer>
        <Typography variant='h6'>ETA</Typography>
        <Typography variant='caption' color='textSecondary'>
          {voyage.etaLastFetchedAt ? `Last Updated ${voyage.etaLastFetchedAt}` : null}
        </Typography>
      </TitleContainer>
      <ETAText variant='h4'>
        {voyage.displayEta || '--'}
        {voyage.shouldFetchEta && (
          <Tooltip title={`Live ETA - Updated daily starting from ${voyage.etaFetchStartDate}`} placement='top' interactive>
            <LiveETAIconContainer>
              <OfflineBolt />
            </LiveETAIconContainer>
          </Tooltip>
        )}
      </ETAText>
    </RootCard>
  )
}

const fragment = graphql`
  fragment ETACard_voyage on Voyage {
    eta
    displayEta
    etaLastFetchedAt
    etaFetchStartDate
    shouldFetchEta
  }
`

export default ETACard
