/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ContainerSizeEnum = "S_20_DC" | "S_40_DC" | "S_40_HC_Standard" | "S_40_Reefer" | "S_45_HC" | "%future added value";
export type ContainerStageEnum = "confirmed" | "finished" | "pickup_sent" | "release_confirmed" | "release_requested" | "%future added value";
export type ContainerTypeEnum = "Foreign" | "Inspection" | "Regular" | "RoRo" | "Stock" | "Truck" | "%future added value";
export type PhysicalStatusEnum = "destination_port" | "discharge" | "empty_in_depot" | "empty_in_terminal" | "empty_in_transit" | "full_in_terminal" | "full_in_transit" | "in_warehouse" | "origin_port" | "shipped" | "stowed" | "%future added value";
export type infoV2UpdateContainerInput = {
    clientMutationId?: string | null;
    slug: string;
    containerNumber?: string | null;
    voyageSlug?: string | null;
    containerType?: string | null;
    containerSize?: string | null;
    procedure?: string | null;
    atb?: string | null;
    bookingNumber?: string | null;
    turninLocationSlug?: string | null;
    pickupLocationSlug?: string | null;
    pickupReference?: string | null;
    turninReference?: string | null;
    pickupComment?: string | null;
    billOfLadingNumber?: string | null;
    eta?: string | null;
    latestPickupDate?: string | null;
    pickupDate?: string | null;
    latestTurninDate?: string | null;
    thcPaidDate?: string | null;
    linkedContacts?: Array<LinkedContactInput> | null;
    estimatedCostStorage?: number | null;
    estimatedCostDemurrage?: number | null;
    costStorage?: number | null;
    costDemurrage?: number | null;
    costTransport?: number | null;
    costXray?: number | null;
    costThc?: number | null;
    costOther?: number | null;
    costCommission?: number | null;
    costCleaning?: number | null;
    stage?: string | null;
    physicalStatus?: string | null;
    changeDate?: string | null;
    shippingLineSlug?: string | null;
};
export type LinkedContactInput = {
    role: string;
    contactSlug: string;
};
export type ContainersTableMutationVariables = {
    input: infoV2UpdateContainerInput;
};
export type ContainersTableMutationResponse = {
    readonly infoV2UpdateContainer: {
        readonly container: {
            readonly atb: string | null;
            readonly bookingNumber: string | null;
            readonly containerNumber: string;
            readonly containerSize: ContainerSizeEnum | null;
            readonly containerType: ContainerTypeEnum;
            readonly costCleaning: number | null;
            readonly costCommission: number | null;
            readonly costDemurrage: number | null;
            readonly costOther: number | null;
            readonly costStorage: number | null;
            readonly costStoragePlusDemurrage: number | null;
            readonly costThc: number | null;
            readonly costTransport: number | null;
            readonly costXray: number | null;
            readonly dischargeDate: string | null;
            readonly estimatedCostDemurrage: number | null;
            readonly estimatedCostStorage: number | null;
            readonly eta: string | null;
            readonly id: string;
            readonly isArchived: boolean;
            readonly latestPickupDate: string | null;
            readonly latestTurninDate: string | null;
            readonly physicalStatus: PhysicalStatusEnum | null;
            readonly pickupComment: string | null;
            readonly pickupDate: string | null;
            readonly pickupLocationSlug: string | null;
            readonly pickupReference: string | null;
            readonly slug: string;
            readonly stage: ContainerStageEnum | null;
            readonly thcPaidDate: string | null;
            readonly turninLocationSlug: string | null;
            readonly turninReference: string | null;
            readonly type: string;
            readonly terminalDate: string | null;
            readonly linkedContacts: ReadonlyArray<{
                readonly contactSlug: string;
                readonly role: string;
            }>;
            readonly shippingLine: {
                readonly id: string;
                readonly name: string;
                readonly slug: string;
                readonly type: string;
            } | null;
            readonly voyage: {
                readonly id: string;
                readonly name: string;
                readonly slug: string;
                readonly type: string;
                readonly eta: string | null;
            };
            readonly vessel: {
                readonly id: string;
                readonly name: string;
                readonly slug: string;
                readonly type: string;
            } | null;
            readonly warehouse: {
                readonly name: string;
                readonly type: string;
                readonly slug: string;
            } | null;
            readonly transporter: {
                readonly name: string;
                readonly type: string;
                readonly slug: string;
            } | null;
        };
    } | null;
};
export type ContainersTableMutation = {
    readonly response: ContainersTableMutationResponse;
    readonly variables: ContainersTableMutationVariables;
};



/*
mutation ContainersTableMutation(
  $input: infoV2UpdateContainerInput!
) {
  infoV2UpdateContainer(input: $input) {
    container {
      atb
      bookingNumber
      containerNumber
      containerSize
      containerType
      costCleaning
      costCommission
      costDemurrage
      costOther
      costStorage
      costStoragePlusDemurrage
      costThc
      costTransport
      costXray
      dischargeDate
      estimatedCostDemurrage
      estimatedCostStorage
      eta
      id
      isArchived
      latestPickupDate
      latestTurninDate
      physicalStatus
      pickupComment
      pickupDate
      pickupLocationSlug
      pickupReference
      slug
      stage
      thcPaidDate
      turninLocationSlug
      turninReference
      type
      terminalDate
      linkedContacts {
        contactSlug
        role
      }
      shippingLine {
        id
        name
        slug
        type
      }
      voyage {
        id
        name
        slug
        type
        eta
      }
      vessel {
        id
        name
        slug
        type
      }
      warehouse {
        name
        type
        slug
        id
      }
      transporter {
        name
        type
        slug
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "atb",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bookingNumber",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "containerNumber",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "containerSize",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "containerType",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "costCleaning",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "costCommission",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "costDemurrage",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "costOther",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "costStorage",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "costStoragePlusDemurrage",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "costThc",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "costTransport",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "costXray",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dischargeDate",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "estimatedCostDemurrage",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "estimatedCostStorage",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "eta",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isArchived",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "latestPickupDate",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "latestTurninDate",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "physicalStatus",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pickupComment",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pickupDate",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pickupLocationSlug",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pickupReference",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stage",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thcPaidDate",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "turninLocationSlug",
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "turninReference",
  "storageKey": null
},
v34 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v35 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "terminalDate",
  "storageKey": null
},
v36 = {
  "alias": null,
  "args": null,
  "concreteType": "ContactLink",
  "kind": "LinkedField",
  "name": "linkedContacts",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contactSlug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "role",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v37 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v38 = [
  (v20/*: any*/),
  (v37/*: any*/),
  (v29/*: any*/),
  (v34/*: any*/)
],
v39 = {
  "alias": null,
  "args": null,
  "concreteType": "ShippingLine",
  "kind": "LinkedField",
  "name": "shippingLine",
  "plural": false,
  "selections": (v38/*: any*/),
  "storageKey": null
},
v40 = {
  "alias": null,
  "args": null,
  "concreteType": "Voyage",
  "kind": "LinkedField",
  "name": "voyage",
  "plural": false,
  "selections": [
    (v20/*: any*/),
    (v37/*: any*/),
    (v29/*: any*/),
    (v34/*: any*/),
    (v19/*: any*/)
  ],
  "storageKey": null
},
v41 = {
  "alias": null,
  "args": null,
  "concreteType": "Vessel",
  "kind": "LinkedField",
  "name": "vessel",
  "plural": false,
  "selections": (v38/*: any*/),
  "storageKey": null
},
v42 = [
  (v37/*: any*/),
  (v34/*: any*/),
  (v29/*: any*/)
],
v43 = [
  (v37/*: any*/),
  (v34/*: any*/),
  (v29/*: any*/),
  (v20/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ContainersTableMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "infoV2UpdateContainerPayload",
        "kind": "LinkedField",
        "name": "infoV2UpdateContainer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContainerV2",
            "kind": "LinkedField",
            "name": "container",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/),
              (v23/*: any*/),
              (v24/*: any*/),
              (v25/*: any*/),
              (v26/*: any*/),
              (v27/*: any*/),
              (v28/*: any*/),
              (v29/*: any*/),
              (v30/*: any*/),
              (v31/*: any*/),
              (v32/*: any*/),
              (v33/*: any*/),
              (v34/*: any*/),
              (v35/*: any*/),
              (v36/*: any*/),
              (v39/*: any*/),
              (v40/*: any*/),
              (v41/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Contact",
                "kind": "LinkedField",
                "name": "warehouse",
                "plural": false,
                "selections": (v42/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Contact",
                "kind": "LinkedField",
                "name": "transporter",
                "plural": false,
                "selections": (v42/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ApplicationCommand",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ContainersTableMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "infoV2UpdateContainerPayload",
        "kind": "LinkedField",
        "name": "infoV2UpdateContainer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContainerV2",
            "kind": "LinkedField",
            "name": "container",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/),
              (v23/*: any*/),
              (v24/*: any*/),
              (v25/*: any*/),
              (v26/*: any*/),
              (v27/*: any*/),
              (v28/*: any*/),
              (v29/*: any*/),
              (v30/*: any*/),
              (v31/*: any*/),
              (v32/*: any*/),
              (v33/*: any*/),
              (v34/*: any*/),
              (v35/*: any*/),
              (v36/*: any*/),
              (v39/*: any*/),
              (v40/*: any*/),
              (v41/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Contact",
                "kind": "LinkedField",
                "name": "warehouse",
                "plural": false,
                "selections": (v43/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Contact",
                "kind": "LinkedField",
                "name": "transporter",
                "plural": false,
                "selections": (v43/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "468b06b50e877b3a132feba8059f9a35",
    "id": null,
    "metadata": {},
    "name": "ContainersTableMutation",
    "operationKind": "mutation",
    "text": "mutation ContainersTableMutation(\n  $input: infoV2UpdateContainerInput!\n) {\n  infoV2UpdateContainer(input: $input) {\n    container {\n      atb\n      bookingNumber\n      containerNumber\n      containerSize\n      containerType\n      costCleaning\n      costCommission\n      costDemurrage\n      costOther\n      costStorage\n      costStoragePlusDemurrage\n      costThc\n      costTransport\n      costXray\n      dischargeDate\n      estimatedCostDemurrage\n      estimatedCostStorage\n      eta\n      id\n      isArchived\n      latestPickupDate\n      latestTurninDate\n      physicalStatus\n      pickupComment\n      pickupDate\n      pickupLocationSlug\n      pickupReference\n      slug\n      stage\n      thcPaidDate\n      turninLocationSlug\n      turninReference\n      type\n      terminalDate\n      linkedContacts {\n        contactSlug\n        role\n      }\n      shippingLine {\n        id\n        name\n        slug\n        type\n      }\n      voyage {\n        id\n        name\n        slug\n        type\n        eta\n      }\n      vessel {\n        id\n        name\n        slug\n        type\n      }\n      warehouse {\n        name\n        type\n        slug\n        id\n      }\n      transporter {\n        name\n        type\n        slug\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'a2cf0bb31c39c45bfbea13ae43d3a6f9';
export default node;
