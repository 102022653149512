import { Grid } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'
import LiveShareAuthForm from '../components/LiveShareAuthForm'

const Root = styled(Grid)`
  width: 100%;
  height: 100%;
  min-height: 100vh;
`

const LiveShareShow: React.FC = () => {
  return (
    <Root container>
      <Grid item xs={12}>
        <LiveShareAuthForm />
      </Grid>
    </Root>
  )
}

export default LiveShareShow
