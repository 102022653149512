import { TextField as MuiTextField } from '@material-ui/core'
import { AutocompleteRenderInputParams } from '@material-ui/lab'
import { graphql } from 'babel-plugin-relay/macro'
import { Field, useField } from 'formik'
import { Autocomplete } from 'formik-material-ui-lab'
import React, { useEffect, useRef, useState } from 'react'
import { fetchQuery, useRelayEnvironment } from 'react-relay/hooks'
import ItemImage from '../ItemImage'
import { FieldT, FieldVariantEnum } from './types'
import { ExternalLinkedContactsFieldQuery } from '../../__generated__/ExternalLinkedContactsFieldQuery.graphql'

interface Props {
  field: FieldT
  variant?: FieldVariantEnum
}

const ExternalLinkedContactsField: React.FC<Props> = ({ field }) => {
  const [, meta] = useField(field.name)
  // We dont want to display initial consignee filed at ui while adding and updating shipments
  const nonEnumRoles = Object.keys(meta.value || {}).filter((role) => !field.enum?.includes(role) && role !== 'initial-consignee')

  return (
    <>
      {field.enum?.map((role, idx) => {
        const fieldName = `${field.name}.${role}`
        return (
          <SingleLinkedContactField key={fieldName} fieldName={fieldName} role={role} title={field.enumTitles ? field.enumTitles[idx] : role} />
        )
      })}
      {nonEnumRoles.map((role) => {
        const fieldName = `${field.name}.${role}`
        return <SingleLinkedContactField key={fieldName} fieldName={fieldName} role={role} title={role} />
      })}
    </>
  )
}

interface SingleFieldProps {
  fieldName: string
  title: string
  role: string
}

const SingleLinkedContactField: React.FC<SingleFieldProps> = ({ fieldName, title, role }) => {
  const [, meta, helpers] = useField(fieldName)
  const [searchQuery, setSearchQuery] = useState(meta.value || '')
  const searchQueryRef = useRef(searchQuery)
  const environment = useRelayEnvironment()
  const [options, setOptions] = useState<any[]>([])

  useEffect(() => {
    fetchQuery<ExternalLinkedContactsFieldQuery>(environment, query, {
      type: 'external_contacts',
      searchQuery: searchQuery,
      filters: [{ key: 'role', values: [role === 'initial-consignee' ? 'consignee' : role] }],
      perPage: 10,
    })
      .toPromise()
      .then((data) => data?.externalItemList.items.nodes || [])
      .then((opts) => setOptions(opts as never[]))
  }, [searchQuery, environment, role])

  const onQueryChange = (newQuery: string) => {
    searchQueryRef.current = newQuery
    setTimeout(() => {
      if (searchQueryRef.current === newQuery) {
        setSearchQuery(newQuery)
      }
    }, 300)
  }

  return (
    <Field
      name={fieldName}
      component={Autocomplete}
      options={options}
      getOptionLabel={(option: any) => (typeof option === 'string' ? option : option.title)}
      getOptionSelected={(option: any, value: string) => option.slug === value}
      value={meta.value}
      onChange={(_: any, value: any) => (value ? helpers.setValue(value.slug) : helpers.setValue(null))}
      onInputChange={(_e: any, value: string) => onQueryChange(value)}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <MuiTextField {...params} helperText={meta.touched ? meta.error : null} label={title} variant='outlined' />
      )}
      renderOption={(option: any) => (
        <>
          <ItemImage data={option.image} variant='small' />
          {option.title}
        </>
      )}
    />
  )
}

export const externaLinkedContactToInputHash = (
  linkedContacts: readonly ({ readonly role: string; readonly contactSlug: string } | null)[] | null | undefined
) => {
  return Object.fromEntries(linkedContacts?.filter((node) => node !== null)?.map((node) => [node?.role, node?.contactSlug]) || [])
}

export const externalInputHashToLinkedContacts = (hash: { [role: string]: string } | null) => {
  return Object.entries(hash || {})
    .filter(([, contactSlug]) => contactSlug !== null && contactSlug !== undefined)
    .map(([role, contactSlug]) => ({ role: role, contactSlug: contactSlug }))
}

const query = graphql`
  query ExternalLinkedContactsFieldQuery($type: ExternalListItemTypeEnum!, $searchQuery: String, $filters: [FilterInput!], $perPage: Int) {
    externalItemList(type: $type, searchQuery: $searchQuery, filters: $filters, perPage: $perPage) {
      items {
        nodes {
          slug
          title
          image {
            ...ItemImage_data
          }
        }
      }
    }
  }
`

export default ExternalLinkedContactsField
