import React, { Suspense, useState } from 'react'
import { useParams } from 'react-router'
import { NiceTabPanel } from '../../components/TabsUI/TabsUI'
import VoyageShowHeroCard from '../../components/VoyageShowHeroCard'
import VoyageShowHomeContent from './VoyageShowHomeContent'
import NoteContent from '../../components/NoteTab'
import RelatedItemList from '../ItemList/RelatedItemList'
import VoyageEdit from './VoyageEdit'
import EntityShowOrchestrator from '../../components/EntityShowOrchestrator'
import { ShowPageRenderContext } from '../../viewTypes'

const VoyageShow: React.FC = () => {
  const { slug, tab } = useParams()
  return <VoyageShowWithParams slug={slug} tab={tab} renderContext='page' />
}

interface Params {
  slug: string
  tab: string
  withSkeleton?: boolean
  renderContext?: ShowPageRenderContext
}

export const VoyageShowWithParams: React.FC<Params> = ({ slug, tab, withSkeleton, renderContext = 'drawer' }) => {
  const [viewMode, setViewMode] = useState<'show' | 'edit'>('show')

  return (
    <EntityShowOrchestrator
      showPage={
        <>
          {withSkeleton ? (
            <Suspense fallback={<VoyageShowHeroCard.Skeleton />}>
              <VoyageShowHeroCard slug={slug} activeTab={tab} onEdit={() => setViewMode('edit')} renderContext={renderContext} />
            </Suspense>
          ) : (
            <VoyageShowHeroCard slug={slug} activeTab={tab} onEdit={() => setViewMode('edit')} renderContext={renderContext} />
          )}

          <NiceTabPanel forTab='home' activeTab={tab}>
            <Suspense fallback={<VoyageShowHomeContent.Skeleton />}>
              <VoyageShowHomeContent slug={slug} />
            </Suspense>
          </NiceTabPanel>

          <NiceTabPanel forTab='containers' activeTab={tab}>
            <Suspense fallback={<RelatedItemList.Skeleton />}>
              <RelatedItemList
                type='containers'
                parentAbsoluteSlug={`voyages/${slug}`}
                description='All containers present in this voyage'
                entity='Container'
              />
            </Suspense>
          </NiceTabPanel>

          <NiceTabPanel forTab='notes' activeTab={tab}>
            <Suspense fallback={<NoteContent.Skeleton />}>
              <NoteContent slug={slug} entityType='voyages' />
            </Suspense>
          </NiceTabPanel>
        </>
      }
      editPage={<VoyageEdit slug={slug} onClose={() => setViewMode('show')} />}
      viewMode={viewMode}
    />
  )
}

export default VoyageShow
