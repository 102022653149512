/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ExternalListItemTypeEnum = "containers" | "external_contacts" | "item" | "shipments" | "tickets" | "voyages" | "%future added value";
export type FilterInput = {
    key: string;
    values: Array<string>;
};
export type ExternalRelatedItemListQueryVariables = {
    searchQuery?: string | null;
    type?: ExternalListItemTypeEnum | null;
    parentAbsoluteSlug?: string | null;
    filters?: Array<FilterInput> | null;
    includeArchived?: boolean | null;
    sortKey?: string | null;
    page?: number | null;
    perPage?: number | null;
};
export type ExternalRelatedItemListQueryResponse = {
    readonly externalItemList: {
        readonly sortKeys: ReadonlyArray<{
            readonly key: string;
            readonly title: string;
        }>;
        readonly totalCount: number | null;
        readonly downloadToken: string;
        readonly " $fragmentRefs": FragmentRefs<"ExternalItemListFooter_data" | "ExternalRelatedItemListContent_data">;
    };
};
export type ExternalRelatedItemListQuery = {
    readonly response: ExternalRelatedItemListQueryResponse;
    readonly variables: ExternalRelatedItemListQueryVariables;
};



/*
query ExternalRelatedItemListQuery(
  $searchQuery: String
  $type: ExternalListItemTypeEnum
  $parentAbsoluteSlug: String
  $filters: [FilterInput!]
  $includeArchived: Boolean
  $sortKey: String
  $page: Int
  $perPage: Int
) {
  externalItemList(searchQuery: $searchQuery, type: $type, parentAbsoluteSlug: $parentAbsoluteSlug, filters: $filters, includeArchived: $includeArchived, sortKey: $sortKey, page: $page, perPage: $perPage) {
    ...ExternalItemListFooter_data
    ...ExternalRelatedItemListContent_data
    sortKeys {
      key
      title
    }
    totalCount
    downloadToken
  }
}

fragment Badge_data on Badge {
  type
}

fragment ExternalItemListFooter_data on ExternalItemList {
  paginationMeta {
    hasNextPage
    hasPrevPage
    page
    perPage
    totalPages
  }
}

fragment ExternalRelatedItemListContent_data on ExternalItemList {
  items {
    edges {
      node {
        slug
        ...ExternalShowPageListItem_listItem
        id
      }
    }
  }
  downloadToken
}

fragment ExternalShowPageListItem_listItem on ExternalListItem {
  slug
  title
  itemType
  image {
    ...ItemImage_data
  }
  path
  stages {
    key
    status
    label
    value
  }
  badges {
    key
    ...Badge_data
  }
  lines {
    key
    ...SemanticLine_data
  }
  keyFields {
    key
    ...KeyField_data
  }
}

fragment ItemImage_data on Image {
  url
  alt
  highlight
  shape
  overlay
  overlayIcon
}

fragment KeyField_data on KeyField {
  label
  value {
    key
    ...SemanticString_data
  }
}

fragment SemanticLine_data on SemanticLine {
  parts {
    key
    ...SemanticString_data
  }
}

fragment SemanticString_data on SemanticString {
  text
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includeArchived"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "page"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "parentAbsoluteSlug"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "perPage"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchQuery"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sortKey"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "type"
},
v8 = [
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  {
    "kind": "Variable",
    "name": "includeArchived",
    "variableName": "includeArchived"
  },
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page"
  },
  {
    "kind": "Variable",
    "name": "parentAbsoluteSlug",
    "variableName": "parentAbsoluteSlug"
  },
  {
    "kind": "Variable",
    "name": "perPage",
    "variableName": "perPage"
  },
  {
    "kind": "Variable",
    "name": "searchQuery",
    "variableName": "searchQuery"
  },
  {
    "kind": "Variable",
    "name": "sortKey",
    "variableName": "sortKey"
  },
  {
    "kind": "Variable",
    "name": "type",
    "variableName": "type"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "SortKey",
  "kind": "LinkedField",
  "name": "sortKeys",
  "plural": true,
  "selections": [
    (v9/*: any*/),
    (v10/*: any*/)
  ],
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "downloadToken",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v15 = [
  (v9/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "text",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ExternalRelatedItemListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "ExternalItemList",
        "kind": "LinkedField",
        "name": "externalItemList",
        "plural": false,
        "selections": [
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ExternalItemListFooter_data"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ExternalRelatedItemListContent_data"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v5/*: any*/),
      (v7/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v6/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "ExternalRelatedItemListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": "ExternalItemList",
        "kind": "LinkedField",
        "name": "externalItemList",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Paginate",
            "kind": "LinkedField",
            "name": "paginationMeta",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasPrevPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "page",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "perPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalPages",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ExternalListItemConnection",
            "kind": "LinkedField",
            "name": "items",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ExternalListItemEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ExternalListItem",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "slug",
                        "storageKey": null
                      },
                      (v10/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "itemType",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Image",
                        "kind": "LinkedField",
                        "name": "image",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "url",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "alt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "highlight",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "shape",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "overlay",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "overlayIcon",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "path",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Stage",
                        "kind": "LinkedField",
                        "name": "stages",
                        "plural": true,
                        "selections": [
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "status",
                            "storageKey": null
                          },
                          (v14/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "value",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Badge",
                        "kind": "LinkedField",
                        "name": "badges",
                        "plural": true,
                        "selections": [
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "type",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SemanticLine",
                        "kind": "LinkedField",
                        "name": "lines",
                        "plural": true,
                        "selections": [
                          (v9/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SemanticString",
                            "kind": "LinkedField",
                            "name": "parts",
                            "plural": true,
                            "selections": (v15/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "KeyField",
                        "kind": "LinkedField",
                        "name": "keyFields",
                        "plural": true,
                        "selections": [
                          (v9/*: any*/),
                          (v14/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SemanticString",
                            "kind": "LinkedField",
                            "name": "value",
                            "plural": true,
                            "selections": (v15/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v13/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5845b399255796630cce1efd5c4b1448",
    "id": null,
    "metadata": {},
    "name": "ExternalRelatedItemListQuery",
    "operationKind": "query",
    "text": "query ExternalRelatedItemListQuery(\n  $searchQuery: String\n  $type: ExternalListItemTypeEnum\n  $parentAbsoluteSlug: String\n  $filters: [FilterInput!]\n  $includeArchived: Boolean\n  $sortKey: String\n  $page: Int\n  $perPage: Int\n) {\n  externalItemList(searchQuery: $searchQuery, type: $type, parentAbsoluteSlug: $parentAbsoluteSlug, filters: $filters, includeArchived: $includeArchived, sortKey: $sortKey, page: $page, perPage: $perPage) {\n    ...ExternalItemListFooter_data\n    ...ExternalRelatedItemListContent_data\n    sortKeys {\n      key\n      title\n    }\n    totalCount\n    downloadToken\n  }\n}\n\nfragment Badge_data on Badge {\n  type\n}\n\nfragment ExternalItemListFooter_data on ExternalItemList {\n  paginationMeta {\n    hasNextPage\n    hasPrevPage\n    page\n    perPage\n    totalPages\n  }\n}\n\nfragment ExternalRelatedItemListContent_data on ExternalItemList {\n  items {\n    edges {\n      node {\n        slug\n        ...ExternalShowPageListItem_listItem\n        id\n      }\n    }\n  }\n  downloadToken\n}\n\nfragment ExternalShowPageListItem_listItem on ExternalListItem {\n  slug\n  title\n  itemType\n  image {\n    ...ItemImage_data\n  }\n  path\n  stages {\n    key\n    status\n    label\n    value\n  }\n  badges {\n    key\n    ...Badge_data\n  }\n  lines {\n    key\n    ...SemanticLine_data\n  }\n  keyFields {\n    key\n    ...KeyField_data\n  }\n}\n\nfragment ItemImage_data on Image {\n  url\n  alt\n  highlight\n  shape\n  overlay\n  overlayIcon\n}\n\nfragment KeyField_data on KeyField {\n  label\n  value {\n    key\n    ...SemanticString_data\n  }\n}\n\nfragment SemanticLine_data on SemanticLine {\n  parts {\n    key\n    ...SemanticString_data\n  }\n}\n\nfragment SemanticString_data on SemanticString {\n  text\n}\n"
  }
};
})();
(node as any).hash = 'f1fe4aa724f4e5737e96f6b4250042c0';
export default node;
