import { Card, IconButton, Tooltip, Typography } from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import SessionContext from '../SessionContext'
import { NoteEntityTypeEnum } from '../__generated__/NoteTabQuery.graphql'
import { DeleteNote } from './Notes'
import { graphql } from 'babel-plugin-relay/macro'
import { useLazyLoadQuery } from 'react-relay/hooks'
import { ExternalNotesQuery } from '../__generated__/ExternalNotesQuery.graphql'
import { useDateTimeDisplay } from '../hooks/timeZoneDisplay'

interface ShowNotesProps {
  entitySlug: string
  entityType: NoteEntityTypeEnum
}

const NotesContainer = styled.div`
  display: grid;
  grid-column-template: auto auto;
  grid-gap: 8px;
  grid-template-columns: repeat(2, 1fr);
`
const Note = styled(Card)`
  width: 100%;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const NoteContent = styled(Typography)`
  white-space: pre-wrap;
`
const NoteFooter = styled.div`
  margin-top: 4px;
  display: flex;
  justify-content: space-between;
`
const NoteFooterText = styled(Typography)`
  flex: 1;
  text-align: right;
`

const ExternalShowNotes: React.FC<ShowNotesProps> & { Skeleton: React.FC } = ({ entityType, entitySlug }) => {
  const { user } = useContext(SessionContext)
  const isSuperAdmin = Boolean(user?.contact?.displayRoles.find((role) => role.value === 'super-admin'))
  const [dialogOpen, setDialogOpen] = useState(false)
  const [noteSlug, setNoteSlug] = useState<string | null>(null)
  const data = useLazyLoadQuery<ExternalNotesQuery>(query, { entityType: entityType, entitySlug: entitySlug })

  const openHandle = (slug: string) => {
    setDialogOpen(true)
    setNoteSlug(slug)
  }

  const closeHandle = () => {
    setDialogOpen(false)
    setNoteSlug(null)
  }

  return (
    <>
      <DeleteNote
        slug={noteSlug}
        dialogOpen={dialogOpen}
        onClose={() => closeHandle()}
        entityType={entityType}
        entitySlug={entitySlug}
        queryType='external'
      />
      {data.externalNoteList.length > 0 && (
        <>
          <br></br>
          <Typography variant='h6'>Notes By External</Typography>
          <br></br>
        </>
      )}
      <NotesContainer>
        {data.externalNoteList.map(
          (note) =>
            note && (
              <>
                <Note key={note.slug} variant='outlined'>
                  <NoteContent variant='body1'>{note.content}</NoteContent>
                  <NoteFooter>
                    {isSuperAdmin && (
                      <Tooltip title='Delete' placement='top' interactive>
                        <IconButton onClick={() => openHandle(note.slug)}>
                          <Delete fontSize='small' />
                        </IconButton>
                      </Tooltip>
                    )}
                    <NoteFooterText variant='caption' color='textSecondary'>
                      {note.addedBy.username} {useDateTimeDisplay(note.addedAt)}
                    </NoteFooterText>
                  </NoteFooter>
                </Note>
              </>
            )
        )}
      </NotesContainer>
    </>
  )
}

const query = graphql`
  query ExternalNotesQuery($entityType: NoteEntityTypeEnum!, $entitySlug: String!) {
    externalNoteList(entityType: $entityType, entitySlug: $entitySlug) {
      slug
      content
      addedAt
      isSticky
      addedBy {
        username
      }
    }
  }
`

ExternalShowNotes.Skeleton = () => <>Loading...</>

export { ExternalShowNotes }
