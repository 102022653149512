import React, { useState, unstable_useTransition } from 'react'
import { graphql } from 'babel-plugin-relay/macro'

import styled from 'styled-components'
import { Typography, Link, FormControlLabel, Checkbox as MuiCheckbox, TextField } from '@material-ui/core'
import { parse, stringify } from 'qs'
import { useLocation, useNavigate } from 'react-router'
import { ListPageFilter_filter$key } from '../__generated__/ListPageFilter_filter.graphql'
import { useFragment } from 'react-relay/hooks'
import { ListPageFilter_filterValue$key } from '../__generated__/ListPageFilter_filterValue.graphql'

const FilterContainer = styled.div`
  padding: 6px 12px;
  margin-bottom: 8px;
  background-color: ${(props) => props.theme.palette.background.paper};
  border: 1px solid ${(props) => props.theme.palette.divider};
`
const FilterContent = styled.div`
  padding: 8px 4px;
`

interface Items {
  itemsToShow: number
  expanded: boolean
}

interface Props {
  filter: ListPageFilter_filter$key
}

const StyledLink = styled(Link)`
  cursor: pointer;
`

const StyledFormControlLabel = styled(FormControlLabel)<{ $selected: boolean; $filterIsActive: boolean }>`
  & > .MuiFormControlLabel-label {
    color: ${(props) => (props.$filterIsActive && !props.$selected ? props.theme.palette.text.secondary : props.theme.palette.text.primary)};
  }

  ${(props) =>
    !props.$selected &&
    `
    & .MuiIconButton-label {
      color: ${props.theme.palette.type === 'light' ? props.theme.palette.grey[500] : props.theme.palette.grey[500]}
    }
  `}
`

const ListPageFilter: React.FC<Props> = ({ filter: data }) => {
  const filter = useFragment(filterFragment, data)
  const defaultItems = 6
  const [showItems, setShowItems] = useState<Items>({ itemsToShow: defaultItems, expanded: false })
  const showMore = () => {
    showItems.itemsToShow === defaultItems
      ? setShowItems({ itemsToShow: filter?.values != null ? filter?.values?.length : 0, expanded: true })
      : setShowItems({ itemsToShow: defaultItems, expanded: false })
  }
  const location = useLocation()
  const queryVariables = parse(location.search, { ignoreQueryPrefix: true })
  const navigate = useNavigate()
  const [startTransition] = unstable_useTransition()
  const handleFilterToggle = (key: string, label: string) => {
    const tempVars = Object.assign({}, queryVariables)

    if (Array.isArray(tempVars[key]) && (tempVars[key] as string[])?.includes(label)) {
      const filterArray = tempVars[key] as string[]
      const labelIdx = filterArray.indexOf(label)
      tempVars[key] = [...filterArray.slice(0, labelIdx), ...filterArray.slice(labelIdx + 1)]
    } else {
      if (!Array.isArray(tempVars[key])) {
        tempVars[key] = []
      }

      ;(tempVars[key] as string[]).push(label)
    }
    startTransition(() => {
      navigate(`?${stringify(tempVars)}`)
    })
  }

  // we order values by whatever the order was on initial render
  // reduces position jumps
  const [initialLabels] = useState(filter.values?.map((value) => value.label) || [])
  const orderedFilterValues = filter?.values?.slice().sort((valA, valB) => {
    const firstIdx = initialLabels.indexOf(valA.label)
    const secondIdx = initialLabels.indexOf(valB.label)

    return (firstIdx >= 0 ? firstIdx : Infinity) - (secondIdx >= 0 ? secondIdx : Infinity)
  })
  const filterIsActive = filter?.values?.some((value) => value.selected) || false
  const [searchQuery, setSearchQuery] = useState('')
  return (
    <>
      <FilterContainer>
        <Typography variant='subtitle2' color='textSecondary'>
          {filter.name}
        </Typography>
        {(filter?.values != null ? filter?.values?.length > defaultItems : false) && (
          <TextField
            size='small'
            variant='standard'
            value={searchQuery}
            label='Search'
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value as string)}
          />
        )}
        <FilterContent>
          {orderedFilterValues
            ?.filter((item) => item.displayLabel.toLowerCase().includes(searchQuery.toLowerCase()))
            ?.slice(0, showItems.itemsToShow)
            .map((value) => (
              <CheckboxFilterValue
                key={`${filter.key}-${value.label}`}
                filterValue={value}
                onChange={() => handleFilterToggle(filter.key, value.label)}
                filterIsActive={filterIsActive}
              />
            ))}
          {(filter?.values != null ? filter?.values?.length > defaultItems : false) && (
            <StyledLink color='secondary' onClick={showMore}>
              {showItems.expanded ? '...Show Less' : 'Show More...'}
            </StyledLink>
          )}
        </FilterContent>
      </FilterContainer>
    </>
  )
}

interface CheckboxFilterValueProps {
  filterValue: ListPageFilter_filterValue$key
  filterIsActive: boolean
  onChange: () => void
}

const CheckboxFilterValue: React.FC<CheckboxFilterValueProps> = ({ filterValue: data, filterIsActive, onChange }) => {
  const filterValue = useFragment(filterValueFragment, data)
  const displayLabel =
    filterValue.displayLabel.startsWith('_') && filterValue.displayLabel.endsWith('_') ? (
      <em>{filterValue.displayLabel.replace(/_/g, ' ')}</em>
    ) : (
      <>{filterValue.displayLabel}</>
    )

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <StyledFormControlLabel
        control={<MuiCheckbox color='primary' checked={filterValue.selected} name={filterValue.label} onChange={onChange} />}
        label={displayLabel}
        $selected={filterValue.selected}
        $filterIsActive={filterIsActive}
      />
      <Typography color='textSecondary' variant='body2'>
        {filterValue.count}
      </Typography>
    </div>
  )
}

const filterFragment = graphql`
  fragment ListPageFilter_filter on Filter {
    key
    name
    values {
      label
      displayLabel
      selected
      ...ListPageFilter_filterValue
    }
  }
`

const filterValueFragment = graphql`
  fragment ListPageFilter_filterValue on FilterValue {
    label
    displayLabel
    selected
    count
  }
`
export default ListPageFilter
