import { Typography } from '@material-ui/core'
import { graphql } from 'babel-plugin-relay/macro'
import React, { useEffect } from 'react'
import { useLazyLoadQuery, useFragment } from 'react-relay/hooks'
import styled from 'styled-components'
import { DateFilterEnum } from '../../__generated__/DashboardHeaderQuery.graphql'
import { FeedItemListTypeEnum, TimelineQuery } from '../../__generated__/TimelineQuery.graphql'
import { Timeline_queryData$key } from '../../__generated__/Timeline_queryData.graphql'
import LoadingDots from '../LoadingDots'
import Button from '../Button'
import FeedItem from './FeedItem'

interface Props {
  handle?: string | null
  slug?: string | null
  type: FeedItemListTypeEnum
  parentAbsoluteSlug?: string
  group?: string | null
  external?: boolean
  dateFilter?: DateFilterEnum
}

const VerticalGrid = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-gap: 8px;
`

const DayLabelContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 1px;
    transform: translateY(-50%);
    background-color: ${(props) => (props.theme.palette.type === 'light' ? props.theme.palette.grey[300] : props.theme.palette.grey[700])};
  }
`

const DayLabelTypography = styled(Typography)`
  padding: 4px 12px;
  text-align: center;
  color: ${(props) => (props.theme.palette.type === 'light' ? props.theme.palette.grey[700] : props.theme.palette.grey[300])};
  z-index: 1;
  background-color: ${(props) => props.theme.palette.background.default};
`

const DayLabel: React.FC = (props) => (
  <DayLabelContainer>
    <DayLabelTypography variant='caption'>{props.children}</DayLabelTypography>
  </DayLabelContainer>
)

const LoadMoreButton = styled(Button)`
  margin: 0;
  border-radius: 4px;
`

const Timeline: React.FC<Props> & { Skeleton: React.FC } = ({
  handle,
  slug,
  type,
  group,
  parentAbsoluteSlug,
  dateFilter = 'all_time',
  external = false,
}) => {
  const [pageSize, setPageSize] = React.useState(5)
  const [isLoadingNext, setIsLoadingNext] = React.useState(false)
  useEffect(() => {
    setIsLoadingNext(true)
  }, [pageSize])

  const queryData = useLazyLoadQuery<TimelineQuery>(query, {
    handle: handle,
    slug: slug,
    type: type,
    group: group,
    dateFilter: dateFilter,
    parentAbsoluteSlug: parentAbsoluteSlug,
    first: pageSize,
  })

  useEffect(() => {
    setIsLoadingNext(false)
  }, [queryData])

  return <FeedItemList isLoadingNext={isLoadingNext} queryData={queryData} external={external} pageSize={pageSize} setPageSize={setPageSize} />
}

const FeedItemList: React.FC<{
  queryData: Timeline_queryData$key
  external: boolean
  pageSize: number
  setPageSize: React.Dispatch<React.SetStateAction<number>>
  isLoadingNext?: boolean
}> = ({ queryData, external, pageSize, setPageSize, isLoadingNext }) => {
  const { feedItemList } = useFragment(paginatedFeedItemsFragment, queryData)

  const feedItems = feedItemList.feedItems

  return (
    <VerticalGrid style={{ paddingBottom: 32 }}>
      {feedItems?.map(
        (item, idx) =>
          item && (
            <React.Fragment key={idx}>
              {idx === 0 || (feedItems && item.dayLabel !== feedItems[idx - 1]?.dayLabel) ? <DayLabel>{item.dayLabel}</DayLabel> : null}
              <FeedItem key={item.slug} data={item} external={external} />
            </React.Fragment>
          )
      )}
      {feedItemList.totalCount > pageSize && (
        <LoadMoreButton
          variant='outlined'
          onClick={(e) => {
            // if we don't remove focus from button, page snaps to it's new position (which can be off-screen)
            e.preventDefault()
            e.currentTarget.blur()

            setPageSize((p) => p + 5)
          }}
          tabIndex={-1}
        >
          {isLoadingNext ? <LoadingDots variant='primary' /> : 'Show more activity'}
        </LoadMoreButton>
      )}
    </VerticalGrid>
  )
}

const query = graphql`
  query TimelineQuery(
    $handle: String
    $slug: String
    $type: FeedItemListTypeEnum!
    $dateFilter: DateFilterEnum!
    $group: String
    $parentAbsoluteSlug: String
    $first: Int
    $after: Int
  ) {
    ...Timeline_queryData
  }
`

const paginatedFeedItemsFragment = graphql`
  fragment Timeline_queryData on ApplicationQuery @refetchable(queryName: "TimelinePaginationQuery") {
    feedItemList(
      handle: $handle
      slug: $slug
      type: $type
      group: $group
      parentAbsoluteSlug: $parentAbsoluteSlug
      dateFilter: $dateFilter
      first: $first
      after: $after
    ) {
      feedItems {
        slug
        dayLabel
        ...FeedItem_data
      }
      totalCount
    }
  }
`

Timeline.Skeleton = () => <>Loading...</>

export default Timeline
