import { Avatar, Card, Paper, Typography } from '@material-ui/core'
import { ChevronRight } from '@material-ui/icons'
import { graphql } from 'babel-plugin-relay/macro'
import React, { useRef } from 'react'
import { useLazyLoadQuery } from 'react-relay/hooks'
import styled from 'styled-components'
import Badge from '../../components/Badge'
import SemanticLine from '../../components/SemanticLine'
import { isSmall, media } from '../../theme'
import { ExternalDashboardHeaderQuery } from '../../__generated__/ExternalDashboardHeaderQuery.graphql'

interface Props {
  my: string
}

const Titlebar = styled(Paper)`
  position: relative;
  max-width: 1000px;
  width: 100%;
  height: 72px;
  display: flex;
  align-items: center;
  padding: 8px;
  margin-bottom: 32px;
  margin-top: 64px;

  ${media.small`
    height: 90px;
  `}
`

const TitlebarContent = styled.div`
  display: flex;
  align-items: center;
`

const Title = styled(Typography)`
  margin-right: 8px;
  font-weight: 700;
`

const ParentTitleContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 4px;
  border-radius: 4px;
  min-width: 200px;
`

const SeperatorContainer = styled.div`
  margin: 0 8px;
`

const DashboardTitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const DashboardTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

const TitleContent = styled.div`
  width: 100%;
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${media.small`
    padding-bottom: 8px;
  `}
`

const ContianerProfileImage = styled(Avatar)`
  margin-right: 8px;
  height: 40px;
  width: 40px;
  border: 4px solid ${(props) => props.theme.palette.background.paper};
  border-radius: 8px;
`

const ProfileImage = styled(Avatar)`
  margin-right: 16px;
  height: 100px;
  width: 100px;
  border: 4px solid ${(props) => props.theme.palette.background.paper};
`

const DetailsCard = styled(Card)`
  width: 100%;
  max-width: 770px;
  padding: 16px;
`

const ExternalDashboardHeader: React.FC<Props> = () => {
  const shipmentMenuRef = useRef(null)

  const { externalDashboardHeaderQuery } = useLazyLoadQuery<ExternalDashboardHeaderQuery>(query, {})

  return (
    <>
      <TitleContent>
        <Titlebar variant='outlined'>
          {!isSmall() && (
            <>
              <ParentTitleContainer>
                <ContianerProfileImage src={''} />
                <TitlebarContent>
                  <Title variant='subtitle1'>{externalDashboardHeaderQuery?.parentHandle?.name}</Title>
                </TitlebarContent>
              </ParentTitleContainer>
              <SeperatorContainer>
                <ChevronRight />
              </SeperatorContainer>
            </>
          )}
          <DashboardTitleContainer ref={shipmentMenuRef}>
            <DashboardTitle>
              <ProfileImage src={externalDashboardHeaderQuery?.selectedHandle?.image?.url as string} />
              <div>
                <TitlebarContent>
                  <Title variant='h5'>
                    {externalDashboardHeaderQuery?.selectedHandle?.title}
                    {"'"}s dashboard
                  </Title>
                  {externalDashboardHeaderQuery?.selectedHandle?.badges?.map((badge, idx) => (
                    <Badge key={idx} data={badge} />
                  ))}
                </TitlebarContent>
                {externalDashboardHeaderQuery?.selectedHandle?.lines?.map((line, idx) => (
                  <SemanticLine key={idx} data={line} />
                ))}
              </div>
            </DashboardTitle>
          </DashboardTitleContainer>
        </Titlebar>
        <DetailsCard variant='outlined'>{externalDashboardHeaderQuery?.summary}&nbsp;&nbsp;&nbsp;&nbsp;</DetailsCard>
      </TitleContent>
    </>
  )
}

export default ExternalDashboardHeader

const query = graphql`
  query ExternalDashboardHeaderQuery {
    externalDashboardHeaderQuery {
      summary
      parentHandle {
        slug
        name
        image {
          url
          ...ItemImage_data
        }
      }
      selectedHandle {
        slug
        title
        image {
          url
          ...ItemImage_data
        }
        badges {
          ...Badge_data
        }
        lines {
          ...SemanticLine_data
        }
      }
      availableHandles {
        slug
        title
      }
    }
  }
`
