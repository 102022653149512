/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type TemplateEntityTypeEnum = "contacts" | "containers" | "shipments" | "%future added value";
export type helpersFileUploadableDocsQueryVariables = {
    entityType: TemplateEntityTypeEnum;
};
export type helpersFileUploadableDocsQueryResponse = {
    readonly uploadableDocuments: ReadonlyArray<{
        readonly documentName: string;
        readonly description: string;
    }>;
};
export type helpersFileUploadableDocsQuery = {
    readonly response: helpersFileUploadableDocsQueryResponse;
    readonly variables: helpersFileUploadableDocsQueryVariables;
};



/*
query helpersFileUploadableDocsQuery(
  $entityType: TemplateEntityTypeEnum!
) {
  uploadableDocuments(entityType: $entityType) {
    documentName
    description
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "entityType"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "entityType",
        "variableName": "entityType"
      }
    ],
    "concreteType": "UploadableDocument",
    "kind": "LinkedField",
    "name": "uploadableDocuments",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "documentName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "helpersFileUploadableDocsQuery",
    "selections": (v1/*: any*/),
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "helpersFileUploadableDocsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "63b4cf6cbd442550f8a904d1b13a5039",
    "id": null,
    "metadata": {},
    "name": "helpersFileUploadableDocsQuery",
    "operationKind": "query",
    "text": "query helpersFileUploadableDocsQuery(\n  $entityType: TemplateEntityTypeEnum!\n) {\n  uploadableDocuments(entityType: $entityType) {\n    documentName\n    description\n  }\n}\n"
  }
};
})();
(node as any).hash = '5ee44b6267bb8a929e71f7a52870fce4';
export default node;
