import { Chip } from '@material-ui/core'
import { Done } from '@material-ui/icons'
import { graphql } from 'babel-plugin-relay/macro'
import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import useNiceMutation from '../../mutations/useNiceMutation'
import SessionContext from '../../SessionContext'
import { SwitchWorkspaceDialogMutation } from '../../__generated__/SwitchWorkspaceDialogMutation.graphql'
import SwitchWorkspaceDialog from '../Workspace/SwitchWorkspaceDialog'

const SelectedChip = styled(Chip)`
  font-weight: 700;
  margin-right: 8px;
  text-transform: capitalize;
`
const NormalChip = styled(Chip)`
  margin-right: 8px;
  text-transform: capitalize;
`
const ChipContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`
export const Workspaces: React.FC = () => {
  const { user } = useContext(SessionContext)
  const [commit] = useNiceMutation<SwitchWorkspaceDialogMutation>(workspace_mutation)
  const [dialogWorkpace, setDialogWorkspace] = useState<string | null>(null)

  const switchWorkspace = () => {
    dialogWorkpace &&
      commit({
        variables: { input: { workspaceSlug: dialogWorkpace } },
        onCompleted: (_, err) => {
          if (!err) window.location.href = `/${dialogWorkpace}/profile`
        },
      })
  }

  return (
    <>
      <SwitchWorkspaceDialog open={!!dialogWorkpace} onClose={() => setDialogWorkspace(null)} onSwitch={switchWorkspace} />
      <ChipContainer>
        {user?.registeredInvitedContacts
          ?.map((u) => u.workspace)
          .map((ws) =>
            ws === user?.contact?.workspace ? (
              <SelectedChip icon={<Done />} color='primary' clickable label={ws} />
            ) : (
              <NormalChip clickable label={ws} onClick={() => setDialogWorkspace(ws)} />
            )
          )}
      </ChipContainer>
    </>
  )
}
const workspace_mutation = graphql`
  mutation SwitchWorkspaceDialogMutation($input: infoSwitchWorkspaceInput!) {
    infoSwitchWorkspace(input: $input) {
      clientMutationId
    }
  }
`
