import { Drawer } from '@material-ui/core'
import styled from 'styled-components'
import { media } from '../theme'

interface SlideInDrawerProps {
  size: 'small' | 'default'
}

export const SlideInDrawer = styled(Drawer)<SlideInDrawerProps>`
  top: 65px !important;

  & > .MuiBackdrop-root {
    top: 65px;
  }

  & > .MuiDrawer-paper {
    max-width: ${(props) => (props.size === 'small' ? '640px' : '1680px')};
    width: 1680px;
    height: 100%;
    margin-top: 65px;
    height: calc(100% - 65px);
  }

  ${media.medium`
    & > .MuiDrawer-paper {
      width: 1280px;
    }
  `}

  ${(props) => media.small`
    top: 56px !important;

    & > .MuiBackdrop-root {
      top: 56px;
    }

    & > .MuiDrawer-paper {
      margin-top: 56px;
      height: calc(100% - 56px);
      width: ${props.size === 'small' ? '80vw' : '95vw'};
    }
  `}
`
