import styled from 'styled-components'
import { ButtonBase } from '@material-ui/core'

const CTAButton = styled(ButtonBase)`
  width: 100%;
  padding: 16px;
  background: #1d6aff;
  border-radius: 10px;
  margin: 8px 0;
  color: white;
  font-size: 16px;
  font-weight: bold;
  font-family: 'Muli';
  display: flex;
  justify-content: center;
  align-items: center;

  &[disabled] {
    background-color: ${(props) => props.theme.palette.grey[500]};
  }

  &:hover {
    background-color: #1a5fe4;
  }

  &:focus {
    background-color: #1a5fe4;
  }
`

export default CTAButton
