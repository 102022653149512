/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type WorkspaceHeroQueryVariables = {};
export type WorkspaceHeroQueryResponse = {
    readonly workspace: ReadonlyArray<{
        readonly slug: string;
        readonly name: string;
        readonly description: string | null;
        readonly isCurrent: boolean;
        readonly isAllowed: boolean;
    }>;
};
export type WorkspaceHeroQuery = {
    readonly response: WorkspaceHeroQueryResponse;
    readonly variables: WorkspaceHeroQueryVariables;
};



/*
query WorkspaceHeroQuery {
  workspace {
    slug
    name
    description
    isCurrent
    isAllowed
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "WorkspaceShow",
    "kind": "LinkedField",
    "name": "workspace",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "slug",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isCurrent",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isAllowed",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "WorkspaceHeroQuery",
    "selections": (v0/*: any*/),
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "WorkspaceHeroQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "c487b7e43bc383d0b03437b55a3cc800",
    "id": null,
    "metadata": {},
    "name": "WorkspaceHeroQuery",
    "operationKind": "query",
    "text": "query WorkspaceHeroQuery {\n  workspace {\n    slug\n    name\n    description\n    isCurrent\n    isAllowed\n  }\n}\n"
  }
};
})();
(node as any).hash = '381873c87cd6d227bfd1c7efce4665d7';
export default node;
