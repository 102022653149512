import { CssBaseline, PaletteType, ThemeProvider } from '@material-ui/core'
import { SnackbarProvider } from 'notistack'
import React, { Suspense, useEffect, useState } from 'react'
import { RelayEnvironmentProvider } from 'react-relay/hooks'
import { ThemeProvider as SCThemeProvider } from 'styled-components'
import './App.css'
import { createClient, Provider } from 'urql'
import LayoutOrchestrator from './layouts/LayoutOrchestrator'
import environment from './relayEnvironment'
import { themeOfType } from './theme'
import { ErrorBoundaryWithBox } from './utils/ErrorBoundary'
import Actioncable from 'actioncable'
import ActionCableContext from './ActionCableContext'
import OdysseyBrowserRouter from './utils/OdysseyBrowserRouter'
import { HeadProvider, Title as ReactHeadTitle } from 'react-head'
import { getToken, serverUrl } from './api/server'

const cableUrl = process.env.REACT_APP_API_URL?.replace(/^http/, 'ws')
const cable = Actioncable.createConsumer(`${cableUrl}/cable`)

function App() {
  const [themeType, setThemeType] = useState<PaletteType>('light')
  const theme = themeOfType(themeType)

  const workspace = window.location.pathname.split('/')[1]
  const fetchUrl = workspace ? `${serverUrl}/${workspace}/graphql` : `${serverUrl}/graphql`

  const client = createClient({
    url: fetchUrl,
    fetchOptions: () => {
      const token = getToken()
      return {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    },
  })

  useEffect(() => {
    // ugly, but necessary :(
    // react-head doesn't take over the existing title tag present in markup, but creates it's own
    // hence, we remove existing tag when react-head takes over
    // reference: https://github.com/tizmagik/react-head/issues/83
    const defaultTitle = document.getElementById('default-title')
    if (defaultTitle) defaultTitle.parentNode?.removeChild(defaultTitle)
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <Provider value={client}>
        <RelayEnvironmentProvider environment={environment}>
          <SCThemeProvider theme={{ ...theme, toggleTheme: () => setThemeType((prevType) => (prevType === 'light' ? 'dark' : 'light')) }}>
            <CssBaseline />
            <OdysseyBrowserRouter>
              <HeadProvider>
                <ReactHeadTitle>Odyssey</ReactHeadTitle>
                <Suspense fallback={<>Loading...</>}>
                  <SnackbarProvider maxSnack={3}>
                    <ErrorBoundaryWithBox>
                      <ActionCableContext.Provider value={{ cable: cable }}>
                        <LayoutOrchestrator />
                      </ActionCableContext.Provider>
                    </ErrorBoundaryWithBox>
                  </SnackbarProvider>
                </Suspense>
              </HeadProvider>
            </OdysseyBrowserRouter>
          </SCThemeProvider>
        </RelayEnvironmentProvider>
      </Provider>
    </ThemeProvider>
  )
}

export default App
