/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type DateFilterEnum = "all_time" | "last_month" | "last_seven_days" | "last_three_month" | "last_year" | "today" | "%future added value";
export type DashboardMainContentContainersQueryVariables = {
    handle: string;
    includeArchived?: string | null;
    dateFilter: DateFilterEnum;
};
export type DashboardMainContentContainersQueryResponse = {
    readonly dashboardContainersQuery: {
        readonly chartContainersByLocation: {
            readonly labels: ReadonlyArray<string>;
            readonly values: ReadonlyArray<number>;
            readonly paths: ReadonlyArray<string>;
        };
        readonly chartContainersByStage: {
            readonly labels: ReadonlyArray<string>;
            readonly values: ReadonlyArray<number>;
            readonly paths: ReadonlyArray<string>;
        };
        readonly tsContainersEta: {
            readonly labels: ReadonlyArray<string>;
            readonly values: ReadonlyArray<number>;
            readonly paths: ReadonlyArray<string>;
            readonly startIndex: number | null;
        };
    } | null;
};
export type DashboardMainContentContainersQuery = {
    readonly response: DashboardMainContentContainersQueryResponse;
    readonly variables: DashboardMainContentContainersQueryVariables;
};



/*
query DashboardMainContentContainersQuery(
  $handle: String!
  $includeArchived: String
  $dateFilter: DateFilterEnum!
) {
  dashboardContainersQuery(handle: $handle, includeArchived: $includeArchived, dateFilter: $dateFilter) {
    chartContainersByLocation {
      labels
      values
      paths
    }
    chartContainersByStage {
      labels
      values
      paths
    }
    tsContainersEta {
      labels
      values
      paths
      startIndex
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "dateFilter"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "handle"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "includeArchived"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "labels",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "values",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paths",
  "storageKey": null
},
v6 = [
  (v3/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/)
],
v7 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "dateFilter",
        "variableName": "dateFilter"
      },
      {
        "kind": "Variable",
        "name": "handle",
        "variableName": "handle"
      },
      {
        "kind": "Variable",
        "name": "includeArchived",
        "variableName": "includeArchived"
      }
    ],
    "concreteType": "DashboardContainers",
    "kind": "LinkedField",
    "name": "dashboardContainersQuery",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ChartData",
        "kind": "LinkedField",
        "name": "chartContainersByLocation",
        "plural": false,
        "selections": (v6/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ChartData",
        "kind": "LinkedField",
        "name": "chartContainersByStage",
        "plural": false,
        "selections": (v6/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ChartData",
        "kind": "LinkedField",
        "name": "tsContainersEta",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startIndex",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "DashboardMainContentContainersQuery",
    "selections": (v7/*: any*/),
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "DashboardMainContentContainersQuery",
    "selections": (v7/*: any*/)
  },
  "params": {
    "cacheID": "e98ec6b8926a7f88e274cd9131b207c0",
    "id": null,
    "metadata": {},
    "name": "DashboardMainContentContainersQuery",
    "operationKind": "query",
    "text": "query DashboardMainContentContainersQuery(\n  $handle: String!\n  $includeArchived: String\n  $dateFilter: DateFilterEnum!\n) {\n  dashboardContainersQuery(handle: $handle, includeArchived: $includeArchived, dateFilter: $dateFilter) {\n    chartContainersByLocation {\n      labels\n      values\n      paths\n    }\n    chartContainersByStage {\n      labels\n      values\n      paths\n    }\n    tsContainersEta {\n      labels\n      values\n      paths\n      startIndex\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'a6c7eff97c663b3dfc4989a08a0e9791';
export default node;
