/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ExternalTimeline_queryData = {
    readonly externalFeedItemList: {
        readonly feedItems: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly slug: string;
                    readonly dayLabel: string;
                    readonly " $fragmentRefs": FragmentRefs<"ExternalFeedItem_data">;
                } | null;
            } | null> | null;
            readonly pageInfo: {
                readonly hasNextPage: boolean;
                readonly endCursor: string | null;
            };
        } | null;
    };
    readonly " $refType": "ExternalTimeline_queryData";
};
export type ExternalTimeline_queryData$data = ExternalTimeline_queryData;
export type ExternalTimeline_queryData$key = {
    readonly " $data"?: ExternalTimeline_queryData$data;
    readonly " $fragmentRefs": FragmentRefs<"ExternalTimeline_queryData">;
};



const node: ReaderFragment = (function(){
var v0 = [
  "externalFeedItemList",
  "feedItems"
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "count"
    },
    {
      "kind": "RootArgument",
      "name": "cursor"
    },
    {
      "kind": "RootArgument",
      "name": "group"
    },
    {
      "kind": "RootArgument",
      "name": "parentAbsoluteSlug"
    },
    {
      "kind": "RootArgument",
      "name": "slug"
    },
    {
      "kind": "RootArgument",
      "name": "type"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./ExternalTimelinePaginationQuery.graphql.ts')
    }
  },
  "name": "ExternalTimeline_queryData",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "group",
          "variableName": "group"
        },
        {
          "kind": "Variable",
          "name": "parentAbsoluteSlug",
          "variableName": "parentAbsoluteSlug"
        },
        {
          "kind": "Variable",
          "name": "slug",
          "variableName": "slug"
        },
        {
          "kind": "Variable",
          "name": "type",
          "variableName": "type"
        }
      ],
      "concreteType": "ExternalFeedItemList",
      "kind": "LinkedField",
      "name": "externalFeedItemList",
      "plural": false,
      "selections": [
        {
          "alias": "feedItems",
          "args": null,
          "concreteType": "ExternalFeedItemConnection",
          "kind": "LinkedField",
          "name": "__ExternalTimeline_feedItemList_feedItems_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ExternalFeedItemEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ExternalFeedItem",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "slug",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "dayLabel",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "ExternalFeedItem_data"
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ApplicationQuery",
  "abstractKey": null
};
})();
(node as any).hash = '22e32614cbf08bf982dd5755954528ce';
export default node;
