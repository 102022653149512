import { graphql } from 'babel-plugin-relay/macro'
import React, { useState } from 'react'
import { useFragment, useLazyLoadQuery } from 'react-relay/hooks'
import { ItemListFilters_data$key } from '../../__generated__/ItemListFilters_data.graphql'
import { ItemType } from '../../viewTypes'
import ListPageFilter from '../../components/ListPageFilter'
import { ItemListFiltersQuery, ItemListFiltersQueryVariables } from '../../__generated__/ItemListFiltersQuery.graphql'

interface Props {
  listType?: ItemType
  contactSlug?: string
  variables: ItemListFiltersQueryVariables
}

const ItemListFilters: React.FC<Props> = ({ variables, listType }) => {
  const data = useLazyLoadQuery<ItemListFiltersQuery>(filtersQuery, variables)
  const result = useFragment(fragment, data.itemList as ItemListFilters_data$key)
  const filters = result.filters || []

  const [initialKeys] = useState(filters.map((filter) => filter.key))
  const orderedFilters = filters.slice().sort((valA, valB) => {
    const firstIdx = initialKeys.indexOf(valA.key)
    const secondIdx = initialKeys.indexOf(valB.key)

    return (firstIdx >= 0 ? firstIdx : Infinity) - (secondIdx >= 0 ? secondIdx : Infinity)
  })

  return (
    <>
      {orderedFilters.map((filter) => (
        <ListPageFilter key={`${listType}-${filter.key}`} filter={filter} />
      ))}
    </>
  )
}

const filtersQuery = graphql`
  query ItemListFiltersQuery(
    $searchQuery: String
    $type: ListItemTypeEnum
    $filters: [FilterInput!]
    $includeArchived: Boolean
    $sortKey: String
    $page: Int
    $perPage: Int
    $handoverDateStart: Date
    $handoverDateEnd: Date
    $voyageEtaStart: Date
    $voyageEtaEnd: Date
    $dischargeDateStart: Date
    $dischargeDateEnd: Date
    $contactSlug: String
  ) {
    itemList(
      searchQuery: $searchQuery
      type: $type
      filters: $filters
      includeArchived: $includeArchived
      sortKey: $sortKey
      page: $page
      perPage: $perPage
      handoverDateStart: $handoverDateStart
      handoverDateEnd: $handoverDateEnd
      voyageEtaStart: $voyageEtaStart
      voyageEtaEnd: $voyageEtaEnd
      dischargeDateStart: $dischargeDateStart
      dischargeDateEnd: $dischargeDateEnd
      showFilters: true
      contactSlug: $contactSlug
    ) {
      ...ItemListFilters_data
    }
  }
`

const fragment = graphql`
  fragment ItemListFilters_data on ItemList {
    filters {
      key
      ...ListPageFilter_filter
    }
  }
`

export default ItemListFilters
