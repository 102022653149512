import { Collapse, DialogContent, DialogTitle, Grow, IconButton, Paper, Typography, Link, Tooltip, DialogActions } from '@material-ui/core'
import { Add, ChevronRight, Delete, ExpandMore } from '@material-ui/icons'
import EditIcon from '@material-ui/icons/Edit'
import { Alert, Skeleton } from '@material-ui/lab'
import { graphql } from 'babel-plugin-relay/macro'
import React, { Suspense, useState, DragEventHandler } from 'react'
import { useFragment, useLazyLoadQuery } from 'react-relay/hooks'
import styled from 'styled-components'
import useNiceMutation from '../../mutations/useNiceMutation'
import { media } from '../../theme'
import { AttachmentEntityTypeEnum } from '../../__generated__/FileCreationDialogUploadMutation.graphql'
import { FolderMutation } from '../../__generated__/FolderMutation.graphql'
import { FolderQuery } from '../../__generated__/FolderQuery.graphql'
import { Folder_folder$key } from '../../__generated__/Folder_folder.graphql'
import DialogFormActionBar from '../Form/DialogFormActionBar'
import InputField from '../Form/InputField'
import NiceFormik from '../Form/NiceFormik'
import EntityAttachment from './EntityAttachment'
import FileCreationDialog from './FileCreationDialog'
import { ViewModeT, DisplayMode, FolderType } from './types'
import OdysseyDialog from '../OdysseyDialog'
import EntityAttachmentListItem from './EntityAttachmentListItem'
import { TransitionGroup } from 'react-transition-group'
import EntityAttachmentListPreviewer from './EntityAttachmentListPreviewer'
import GetAppIcon from '@material-ui/icons/GetApp'
import { useLocation } from 'react-router'
import Button from '../Button'
import { FolderDeleteAllAttactmentsMutation } from '../../__generated__/FolderDeleteAllAttactmentsMutation.graphql'
import LoadingDots from '../LoadingDots'
import { authorizeDownloadAttachments } from '../../api/server'
import ShareIcon from '@material-ui/icons/Share'
import FolderRoleVisibility from './FolderRoleVisibility'
interface Props {
  folder: Folder_folder$key
  entityType: AttachmentEntityTypeEnum
  entitySlug: string
  viewMode: ViewModeT
  displayMode: DisplayMode
  folderType: FolderType
  documentType?: string
  isExternal?: boolean
}

const Root = styled.div`
  margin-bottom: 16px;
`

const FilesContainer = styled.div<{ $viewMode: ViewModeT }>`
  margin-top: 8px;
  display: grid;
  grid-template-columns: ${(props) => (props.$viewMode === 'home' ? 'repeat(4, minmax(0, 1fr))' : 'repeat(7, minmax(0, 1fr))')};
  grid-auto-rows: 120px;
  grid-gap: 16px;

  ${media.medium`
    grid-template-columns: repeat(4, minmax(0, 1fr));
  `}

  ${media.small`
    grid-template-columns: repeat(3, minmax(0, 1fr));
  `}
`

const FilesListContainer = styled.div`
  margin-top: 8px;
  display: grid;
  grid-template-columns: 1fr;
`

export const NewButton = styled(Paper)<{ $dropHover: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #cacaca;
  min-height: 62px;
  cursor: pointer;
  border-style: dashed;

  &:hover {
    filter: brightness(0.95);
  }
  ${(props) =>
    props.$dropHover &&
    `
    & * {
      pointer-events: none;
    }
    border: 5px dashed ${props.theme.palette.primary.light};
    color: ${props.theme.palette.primary.light};
  `}
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const TitleTextContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

const ActionIconButton = styled(IconButton)`
  color: ${(props) => props.theme.palette.grey[500]};
  margin-left: 8px;
`
const ActionContainer = styled.div`
  display: flex;
`
const Download = styled(Link)`
  margin-top: 4px;
`
const FieldsContainer = styled.div`
  margin-bottom: 16px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 16px;

  ${media.medium`
    grid-template-columns: repeat(1, 1fr);
  `}

  ${media.small`
    grid-template-columns: auto;
  `}
`
const DownloadIcon = styled(GetAppIcon)`
  color: #9e9e9e;
`

const Folder: React.FC<Props> = ({ folder: data, entityType, entitySlug, viewMode, folderType, displayMode, documentType, isExternal }) => {
  const folder = useFragment(fragment, data)

  const [expanded, setExpanded] = useState(viewMode === 'home' && folder.attachments.length > 9 ? false : true)
  const [previewedAttachmentSlug, setPreviewedAttachmentSlug] = useState<null | string>(null)
  const [fileCreationOpen, setFileCreationOpen] = useState(false)
  const [droppedFiles, setDroppedFiles] = useState<FileList | null>(null)
  const [editFolderOpen, setEditFolderOpen] = useState(false)
  const [shareFolderOpen, setShareFolderOpen] = useState(false)
  const workSpace = useLocation().pathname.split('/')[1]
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false)
  const [commitDelete, deleteIsInFlight, deleteMutationError] =
    useNiceMutation<FolderDeleteAllAttactmentsMutation>(deleteAllAttachmentsMutation)

  const closeEditFolderDialog = () => {
    setEditFolderOpen(false)
  }

  const handleDelete = () => {
    commitDelete({
      variables: {
        input: {
          folderSlug: folder.slug,
        },
      },
      onCompleted: (_, errors) => {
        if (!errors) {
          setDeleteConfirmationOpen(false)
        }
      },
    })
  }

  const [dropState, setDropState] = useState(false)

  const onDragEnter: DragEventHandler = (evt) => {
    evt.dataTransfer.dropEffect = 'copy'
    evt.preventDefault()

    setDropState(true)
  }

  const onDragOver: DragEventHandler = (evt) => {
    evt.dataTransfer.dropEffect = 'copy'
    evt.preventDefault()
  }

  const onDragLeave = () => {
    setDropState(false)
  }

  const onDrop: DragEventHandler = (evt) => {
    evt.dataTransfer.dropEffect = 'copy'
    evt.preventDefault()
    setDropState(false)
    setDroppedFiles(evt.dataTransfer.files)
    setFileCreationOpen(true)
  }

  return (
    <>
      <Root>
        <TitleContainer>
          <TitleTextContainer onClick={() => setExpanded((prevState) => !prevState)}>
            <Typography variant='body1'>
              {folder.name} ({folder.attachments.length})
            </Typography>
            {expanded ? <ExpandMore fontSize='small' /> : <ChevronRight fontSize='small' />}
          </TitleTextContainer>
          <ActionContainer>
            {displayMode === 'list' && (
              <ActionIconButton onClick={() => setFileCreationOpen(true)} size='small'>
                <Add fontSize='small' />
              </ActionIconButton>
            )}
            <ActionIconButton onClick={() => setEditFolderOpen(true)} size='small'>
              <EditIcon fontSize='small' />
            </ActionIconButton>
            {folder.attachments.length > 0 && (
              <>
                <ActionIconButton size='small'>
                  <Tooltip title='Download all' placement='top' interactive>
                    <Download
                      onClick={() =>
                        authorizeDownloadAttachments(
                          `${process.env.REACT_APP_API_URL}/entity/download_all_attachments.zip?folder_slug=${folder.slug}&workspace=${workSpace}`,
                          `${folder.name.replace(' ', '_').toLocaleLowerCase()}`,
                          `${folder.entitySlug}`
                        )
                      }
                    >
                      <DownloadIcon fontSize='small' />
                    </Download>
                  </Tooltip>
                </ActionIconButton>
                <ActionIconButton onClick={() => setShareFolderOpen(true)} size='small'>
                  <Tooltip title='Share Folder' placement='top' interactive>
                    <ShareIcon fontSize='small' />
                  </Tooltip>
                </ActionIconButton>
                <ActionIconButton size='small' onClick={() => setDeleteConfirmationOpen(true)}>
                  <Tooltip title='Delete all' placement='top' interactive>
                    <Delete fontSize='small' />
                  </Tooltip>
                </ActionIconButton>
              </>
            )}
          </ActionContainer>
        </TitleContainer>
        <Collapse in={expanded}>
          {displayMode === 'grid' ? (
            <FilesContainer $viewMode={viewMode}>
              <TransitionGroup component={null} appear={false}>
                {folder.attachments.map((attachment) => (
                  <Grow in={true} key={attachment.attachmentSlug} style={{ transformOrigin: 'center top' }}>
                    <EntityAttachment
                      key={attachment.attachmentSlug}
                      attachment={attachment}
                      onPreview={() => setPreviewedAttachmentSlug(attachment.attachmentSlug)}
                    />
                  </Grow>
                ))}
              </TransitionGroup>
              <NewButton
                onDragOver={onDragOver}
                onDragEnter={onDragEnter}
                onDragLeave={onDragLeave}
                onDrop={onDrop}
                variant='outlined'
                onClick={(e) => {
                  e.preventDefault()
                  setFileCreationOpen(true)
                }}
                $dropHover={dropState}
              >
                {dropState ? <Typography>Drop to add</Typography> : <Add />}
              </NewButton>
            </FilesContainer>
          ) : (
            <FilesListContainer>
              <TransitionGroup component={null} appear={false}>
                {folder.attachments.map((attachment) => (
                  <Grow in={true} key={attachment.attachmentSlug} style={{ transformOrigin: 'center top' }}>
                    <EntityAttachmentListItem
                      key={attachment.attachmentSlug}
                      attachment={attachment}
                      onPreview={() => setPreviewedAttachmentSlug(attachment.attachmentSlug)}
                    />
                  </Grow>
                ))}
              </TransitionGroup>
            </FilesListContainer>
          )}
        </Collapse>
      </Root>
      <EntityAttachmentListPreviewer
        attachments={folder.attachments}
        intialPreviewedAttachmentSlug={previewedAttachmentSlug}
        open={!!previewedAttachmentSlug}
        onClose={() => setPreviewedAttachmentSlug(null)}
      />
      <FileCreationDialog
        key={`${fileCreationOpen}`}
        files={droppedFiles}
        folder={folder}
        entityType={entityType}
        entitySlug={entitySlug}
        open={fileCreationOpen}
        onClose={() => {
          setDroppedFiles(null)
          setFileCreationOpen(false)
        }}
        folderType={folderType}
        documentType={documentType}
        isExternal={isExternal}
      />
      <FolderRoleVisibility shareFolderOpen={shareFolderOpen} onClose={() => setShareFolderOpen(false)} slug={folder.slug} name={folder.name} />
      <OdysseyDialog
        open={editFolderOpen}
        maxWidth='sm'
        fullWidth
        onClose={closeEditFolderDialog}
        scroll='paper'
        style={{ maxWidth: '640px', margin: 'auto' }}
      >
        <Suspense fallback={<EditFolderForm.Skeleton onClose={() => closeEditFolderDialog()} />}>
          <EditFolderForm folderSlug={folder.slug} onClose={() => closeEditFolderDialog()} />
        </Suspense>
      </OdysseyDialog>
      <OdysseyDialog open={deleteConfirmationOpen} onClose={() => setDeleteConfirmationOpen(false)}>
        <DialogTitle>Delete all from {folder.name} </DialogTitle>
        {deleteMutationError && <Alert severity='error'>{deleteMutationError}</Alert>}
        <DialogActions>
          <Button onClick={() => setDeleteConfirmationOpen(false)}>Cancel</Button>
          <Button variant='contained' onClick={() => handleDelete()}>
            {deleteIsInFlight ? <LoadingDots variant='light' /> : 'OK'}
          </Button>
        </DialogActions>
      </OdysseyDialog>
    </>
  )
}

interface EditFolderProps {
  folderSlug: string
  onClose: () => void
}
const EditFolderForm: React.FC<EditFolderProps> & { Skeleton: React.FC<{ onClose?: () => void }> } = ({ folderSlug, onClose }) => {
  const [commit] = useNiceMutation<FolderMutation>(mutation)
  const [mutationError, setMutationError] = useState<null | string>(null)
  const formData = useLazyLoadQuery<FolderQuery>(query, { slug: folderSlug })
  const fields = formData.infoUpdateFolderForm.formSchema.fieldSet
  const fieldMaster = Object.fromEntries(fields.map((field) => [field.name, field]))

  return (
    <>
      <NiceFormik
        initialValues={formData.infoUpdateFolderForm.initialValue || {}}
        onSubmit={(values, actions) => {
          commit({
            variables: {
              input: values as any,
            },
            onError: () => actions.setSubmitting(false),
            onCompleted: (res, errors) => {
              actions.setSubmitting(false)
              if (errors) {
                setMutationError(errors.map((err) => err.message).join(', '))
              } else {
                onClose()
              }
            },
          })
        }}
        formSchema={fields}
      >
        {({ submitForm }) => (
          <>
            <DialogTitle>Editing folder</DialogTitle>
            <DialogContent dividers>
              <FieldsContainer>
                {mutationError && <Alert severity='error'>{mutationError}</Alert>}
                <InputField of={'name'} field={fieldMaster['name']} />
              </FieldsContainer>
            </DialogContent>
            <DialogFormActionBar
              onCancel={() => onClose()}
              onSubmit={() => {
                submitForm()
              }}
              cancelCta='Cancel'
              saveCta='Save'
            />
          </>
        )}
      </NiceFormik>
    </>
  )
}

EditFolderForm.Skeleton = ({ onClose }) => (
  <>
    <DialogTitle>Editing Folder</DialogTitle>
    <DialogContent>
      <Skeleton variant='rect' height='50px' />
    </DialogContent>
    <DialogFormActionBar.Skeleton onCancel={onClose} />
  </>
)
const fragment = graphql`
  fragment Folder_folder on Folder {
    slug
    name
    parentType
    entitySlug
    attachments {
      attachmentSlug
      name
      ...EntityAttachmentListPreviewer_attachment
      ...EntityAttachment_attachment
      ...EntityAttachmentListItem_attachment
    }
    ...FileCreationDialog_folder
  }
`
const mutation = graphql`
  mutation FolderMutation($input: infoUpdateFolderInput!) {
    infoUpdateFolder(input: $input) {
      clientMutationId
    }
  }
`

const query = graphql`
  query FolderQuery($slug: String!) {
    infoUpdateFolderForm(slug: $slug) {
      formSchema {
        fieldSet {
          name
          type
          required
          title
          description
          format
          enum
          enumTitles
        }
      }
      initialValue {
        slug
        name
      }
    }
  }
`

const deleteAllAttachmentsMutation = graphql`
  mutation FolderDeleteAllAttactmentsMutation($input: infoDeleteAllAttachmentsInput!) {
    infoDeleteAllAttachments(input: $input) {
      clientMutationId
    }
  }
`

export default Folder
