import { Collapse, Grow, IconButton, Link, Tooltip, Typography } from '@material-ui/core'
import { graphql } from 'babel-plugin-relay/macro'
import React, { DragEventHandler, useState } from 'react'
import { useFragment } from 'react-relay/hooks'
import styled from 'styled-components'
import { media } from '../../theme'
import { ExternalFolder_folder$key } from '../../__generated__/ExternalFolder_folder.graphql'
import EntityAttachment from './EntityAttachment'
import { ViewModeT, DisplayMode, FolderType } from './types'
import EntityAttachmentListItem from './EntityAttachmentListItem'
import { TransitionGroup } from 'react-transition-group'
import EntityAttachmentListPreviewer from './EntityAttachmentListPreviewer'
import { ChevronRight, ExpandMore } from '@material-ui/icons'
import { Add } from '@material-ui/icons'
import DraftFileCreationDialog from './DraftFileCreationDialog'
import { authorizeDownloadAttachments } from '../../api/server'
import GetAppIcon from '@material-ui/icons/GetApp'
import { useLocation } from 'react-router'
import { NewButton } from './Folder'
import { TemplateEntityTypeEnum } from '../../__generated__/helpersFileUploadableDocsQuery.graphql'

interface Props {
  folder: ExternalFolder_folder$key
  viewMode: ViewModeT
  displayMode: DisplayMode
  activeTicket: string
  folderType: FolderType
  documentType?: string
}

const Root = styled.div`
  margin-bottom: 16px;
`

const FilesContainer = styled.div<{ $viewMode: ViewModeT }>`
  margin-top: 8px;
  display: grid;
  grid-template-columns: ${(props) => (props.$viewMode === 'home' ? 'repeat(4, minmax(0, 1fr))' : 'repeat(7, minmax(0, 1fr))')};
  grid-auto-rows: 120px;
  grid-gap: 16px;

  ${media.medium`
    grid-template-columns: repeat(4, minmax(0, 1fr));
  `}

  ${media.small`
    grid-template-columns: repeat(3, minmax(0, 1fr));
  `}
`

const FilesListContainer = styled.div`
  margin-top: 8px;
  display: grid;
  grid-template-columns: 1fr;
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const TitleTextContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`
const ActionIconButton = styled(IconButton)`
  color: ${(props) => props.theme.palette.grey[500]};
  margin-left: 8px;
`
const ActionContainer = styled.div`
  display: flex;
`
const DownloadIcon = styled(GetAppIcon)`
  color: #9e9e9e;
`

const Download = styled(Link)`
  margin-top: 4px;
`
const ExternalFolder: React.FC<Props> = ({ folder: data, viewMode, displayMode, activeTicket, folderType, documentType }) => {
  const folder = useFragment(fragment, data)
  const workSpace = useLocation().pathname.split('/')[1]
  const [draftFileCreationOpen, setDraftFileCreationOpen] = useState(false)
  const [expanded, setExpanded] = useState(!(viewMode === 'home' && folder.attachments.length > 9))
  const [previewedAttachmentSlug, setPreviewedAttachmentSlug] = useState<null | string>(null)
  const [dropState, setDropState] = useState(false)
  const [droppedFiles, setDroppedFiles] = useState<FileList | null>(null)

  const onDragEnter: DragEventHandler = (evt) => {
    evt.dataTransfer.dropEffect = 'copy'
    evt.preventDefault()
    setDropState(true)
  }

  const onDragOver: DragEventHandler = (evt) => {
    evt.dataTransfer.dropEffect = 'copy'
    evt.preventDefault()
  }

  const onDragLeave = () => {
    setDropState(false)
  }

  const onDrop: DragEventHandler = (evt) => {
    evt.dataTransfer.dropEffect = 'copy'
    evt.preventDefault()
    setDropState(false)
    setDroppedFiles(evt.dataTransfer.files)
    setDraftFileCreationOpen(true)
  }
  const closeHandle = () => {
    setDroppedFiles(null)
    setDraftFileCreationOpen(false)
  }

  return (
    <>
      {(folder.attachments.length > 0 || folder.name == 'My Images' || folder.name == 'My Docs') && (
        <Root>
          <TitleContainer>
            <TitleTextContainer onClick={() => setExpanded((prevState) => !prevState)}>
              <Typography variant='body1'>
                {folder.name} ({folder.attachments.length})
              </Typography>
              {expanded ? <ExpandMore fontSize='small' /> : <ChevronRight fontSize='small' />}
            </TitleTextContainer>
            <ActionContainer>
              {folder.attachments.length > 0 && folderType === 'photos' && (
                <>
                  <ActionIconButton size='small'>
                    <Tooltip title='Download all' placement='top' interactive>
                      <Download
                        onClick={() =>
                          authorizeDownloadAttachments(
                            `${process.env.REACT_APP_API_URL}/entity/download_all_attachments.zip?folder_slug=${folder.slug}&workspace=${workSpace}`,
                            `${folder.name.replace(' ', '_').toLocaleLowerCase()}`,
                            `${folder.entitySlug}`
                          )
                        }
                      >
                        <DownloadIcon fontSize='small' />
                      </Download>
                    </Tooltip>
                  </ActionIconButton>
                </>
              )}
            </ActionContainer>
          </TitleContainer>
          <Collapse in={expanded}>
            {displayMode === 'grid' ? (
              <FilesContainer $viewMode={viewMode}>
                <TransitionGroup component={null} appear={false}>
                  {folder.attachments.map((attachment) => (
                    <Grow in={true} key={attachment.attachmentSlug} style={{ transformOrigin: 'center top' }}>
                      <EntityAttachment
                        key={attachment.attachmentSlug}
                        attachment={attachment}
                        onPreview={() => setPreviewedAttachmentSlug(attachment.attachmentSlug)}
                      />
                    </Grow>
                  ))}
                </TransitionGroup>
                {(folder.name == 'My Docs' || folder.name == 'My Images') && (
                  <NewButton
                    onDragOver={onDragOver}
                    onDragEnter={onDragEnter}
                    onDragLeave={onDragLeave}
                    onDrop={onDrop}
                    variant='outlined'
                    onClick={(e) => {
                      e.preventDefault()
                      setDraftFileCreationOpen(true)
                    }}
                    $dropHover={dropState}
                  >
                    {dropState ? <Typography>Drop to add</Typography> : <Add />}
                  </NewButton>
                )}
              </FilesContainer>
            ) : (
              <FilesListContainer>
                <TransitionGroup component={null} appear={false}>
                  {folder.attachments.map((attachment) => (
                    <Grow in={true} key={attachment.attachmentSlug} style={{ transformOrigin: 'center top' }}>
                      <EntityAttachmentListItem
                        key={attachment.attachmentSlug}
                        attachment={attachment}
                        onPreview={() => setPreviewedAttachmentSlug(attachment.attachmentSlug)}
                      />
                    </Grow>
                  ))}
                </TransitionGroup>
              </FilesListContainer>
            )}
          </Collapse>
        </Root>
      )}
      <DraftFileCreationDialog
        ticketSlug={activeTicket}
        open={draftFileCreationOpen}
        folderSlug={folder.slug}
        entitySlug={folder.entitySlug}
        entityType={folder.entityType as TemplateEntityTypeEnum}
        folderName={folder.name}
        onClose={() => closeHandle()}
        files={droppedFiles}
        folderType={folderType}
        documentType={documentType}
      />
      <EntityAttachmentListPreviewer
        attachments={folder.attachments}
        intialPreviewedAttachmentSlug={previewedAttachmentSlug}
        open={!!previewedAttachmentSlug}
        onClose={() => setPreviewedAttachmentSlug(null)}
      />
    </>
  )
}

const fragment = graphql`
  fragment ExternalFolder_folder on Folder {
    slug
    entitySlug
    entityType
    name
    parentType
    attachments {
      attachmentSlug
      name
      isImage
      ...EntityAttachmentListPreviewer_attachment
      ...EntityAttachment_attachment
      ...EntityAttachmentListItem_attachment
    }
    ...FileCreationDialog_folder
  }
`

export default ExternalFolder
