import { Divider, Typography } from '@material-ui/core'
import { Close, Edit } from '@material-ui/icons'
import { graphql } from 'babel-plugin-relay/macro'
import React, { useState } from 'react'
import { useFragment } from 'react-relay/hooks'
import styled from 'styled-components'
import { isSmall, media } from '../theme'
import { SettingsItem_settingsItem$key } from '../__generated__/SettingsItem_settingsItem.graphql'
import BodyField from './BodyField'
import Button from './Button'
import EditSettingDialog from './EditSettingDialog'
import KeyField from './KeyField'
import SemanticLine from './SemanticLine'
import SettingItemImage from './SettingItemImage'
import ShowField from './ShowField'
import { SlideInDrawer } from './SlideInDrawer'
import Attachment from './File/Attachment'

interface Props {
  settingsItem: SettingsItem_settingsItem$key
  detailed?: 'detailed'
}

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`

const RootContainer = styled(FlexContainer)`
  cursor: pointer;
  position: relative;
  padding: 8px 16px;
  align-items: flex-start;
  background-color: ${(props) => props.theme.palette.background.paper};
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
  transition-duration: 300ms;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  /* TODO: figure out a way to pass styled component props to media helper */
  ${media.mobile`
    border-top: 1px solid #e4e4e4;
    border-bottom: 1px solid #e4e4e4;
    flex-direction: row;
    align-items: flex-start;
  `}
`

const ShowContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 16px;
`

const Header = styled.div`
  background-color: ${(props) => props.theme.palette.background.default};
  display: flex;
`

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const Titlebar = styled.div`
  flex-grow: 1;
`

const Content = styled(FlexContainer)`
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
`

const TitleContainer = styled(FlexContainer)`
  width: 100%;
  justify-content: space-between;
`

const Title = styled(Typography)`
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  margin-right: 8px;
  line-height: 30px;
  transition-duration: 200ms;

  & > a {
    color: ${(props) => props.theme.palette.text.primary};
    text-decoration: unset;
  }

  &:hover > a {
    color: #0085ff;
  }
`

const KeyFieldsContainer = styled.div`
  margin: 4px 0 16px 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, 190px);
  grid-gap: 8px;
  width: 100%;

  ${media.small`
    grid-template-columns: repeat(auto-fill, 110px);
  `}
`

const ShowFieldsContainer = styled.div`
  margin: 4px 0 16px 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 8px;
  width: 100%;

  ${media.small`
    grid-template-columns: repeat(2, 1fr);
  `}
`
const BodyFieldsContainer = styled.div`
  margin: 4px 0 16px 0;
  display: grid;
  grid-template-columns: repeat(1, 2fr);
  grid-gap: 8px;
  width: 100%;

  ${media.small`
    grid-template-columns: repeat(1, 2fr);
  `}
`

const AttachmentsContainer = styled.div`
  margin: 4px 0 16px 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 120px;
  grid-gap: 8px;
  width: 100%;

  ${media.small`
    grid-template-columns: repeat(1, 1fr);
  `}
`

const SettingsItem: React.FC<Props> = ({ settingsItem, detailed }) => {
  const [open, setOpen] = useState(false)
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const item = useFragment(settingsItemFragment, settingsItem)
  const maxFields = detailed ? 6 : isSmall() ? 3 : 4
  return (
    <>
      <SlideInDrawer size='small' anchor='right' open={open} elevation={0} onClose={() => setOpen(false)}>
        <ShowContainer>
          <Header>
            <Titlebar>
              <Typography variant='h5'>{item.settingType.replace('_', ' ').toUpperCase()}</Typography>
            </Titlebar>
            <div>
              <EditSettingDialog
                slug={item.slug}
                type={item.settingType}
                open={editDialogOpen}
                onClose={() => setEditDialogOpen(false)}
                onEdit={() => setEditDialogOpen(false)}
                title={`Edit ${item.title}`}
              />
              <Button endIcon={<Edit />} onClick={() => setEditDialogOpen(true)}>
                Edit
              </Button>
              <Button endIcon={<Close />} onClick={() => setOpen(false)}>
                Close
              </Button>
            </div>
          </Header>
          <Divider variant='fullWidth' component='hr' />
          <br />
          {item.image && <SettingItemImage data={item.image} />}
          <Titlebar>
            <Typography variant='h6'>{item.title}</Typography>
            {item.lines?.map((line) => (
              <SemanticLine key={line.key} data={line} />
            ))}
          </Titlebar>

          {item.keyFields && (
            <KeyFieldsContainer>
              {item.keyFields?.map((field) => (
                <KeyField key={field.key} data={field} />
              ))}
            </KeyFieldsContainer>
          )}
          {item.bodyFields && (
            <BodyFieldsContainer>
              {item.bodyFields?.map((field) => (
                <BodyField key={field.key} data={field} />
              ))}
            </BodyFieldsContainer>
          )}
          {item.attachments && (
            <AttachmentsContainer>
              {item.attachments.map((attachment) => (
                <Attachment key={attachment.slug} attachment={attachment} />
              ))}
            </AttachmentsContainer>
          )}
          <Divider variant='fullWidth' component='hr' />
          <Footer>
            {item.showFields && (
              <ShowFieldsContainer>
                {item.showFields?.map((field) => (
                  <ShowField key={field.key} data={field} />
                ))}
              </ShowFieldsContainer>
            )}
          </Footer>
          {/* <ResponsiveGrid type='mini-show-layout' left={skeleton} right={skeleton} middle={skeleton} /> */}
        </ShowContainer>
      </SlideInDrawer>

      <RootContainer onClick={() => setOpen(true)}>
        {item.image && <SettingItemImage data={item.image} />}

        <Content>
          <TitleContainer>
            <Title variant='h4' color='textPrimary'>
              {item.title}
            </Title>
          </TitleContainer>
          {item.lines?.map((line) => (
            <SemanticLine key={line.key} data={line} />
          ))}
          {item.keyFields && (
            <KeyFieldsContainer>
              {item.keyFields?.slice(0, maxFields)?.map((field) => (
                <KeyField key={field.key} data={field} />
              ))}
            </KeyFieldsContainer>
          )}
        </Content>
      </RootContainer>
    </>
  )
}

const settingsItemFragment = graphql`
  fragment SettingsItem_settingsItem on SettingsItem {
    slug
    title
    settingType
    image {
      ...SettingItemImage_data
    }
    lines {
      key
      ...SemanticLine_data
    }
    keyFields {
      key
      ...KeyField_data
    }
    showFields {
      key
      ...ShowField_data
    }
    bodyFields {
      key
      ...BodyField_data
    }
    attachments {
      slug
      ...Attachment_attachment
    }
  }
`

export default SettingsItem
