/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ExternalShipmentInfoCard_shipment = {
    readonly slug: string;
    readonly customerInfoText: string | null;
    readonly stage: string;
    readonly requiredDocuments: ReadonlyArray<string> | null;
    readonly infoExternalField: ReadonlyArray<{
        readonly label: string;
        readonly name: string;
        readonly value: string | null;
    }>;
    readonly " $refType": "ExternalShipmentInfoCard_shipment";
};
export type ExternalShipmentInfoCard_shipment$data = ExternalShipmentInfoCard_shipment;
export type ExternalShipmentInfoCard_shipment$key = {
    readonly " $data"?: ExternalShipmentInfoCard_shipment$data;
    readonly " $fragmentRefs": FragmentRefs<"ExternalShipmentInfoCard_shipment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExternalShipmentInfoCard_shipment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customerInfoText",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "stage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "requiredDocuments",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ExternalInfoField",
      "kind": "LinkedField",
      "name": "infoExternalField",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ExternalShipment",
  "abstractKey": null
};
(node as any).hash = '6fac6727b947de093606de24254da6d3';
export default node;
