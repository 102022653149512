/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type FileCreationDialog_folder = {
    readonly slug: string;
    readonly name: string;
    readonly " $refType": "FileCreationDialog_folder";
};
export type FileCreationDialog_folder$data = FileCreationDialog_folder;
export type FileCreationDialog_folder$key = {
    readonly " $data"?: FileCreationDialog_folder$data;
    readonly " $fragmentRefs": FragmentRefs<"FileCreationDialog_folder">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FileCreationDialog_folder",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "Folder",
  "abstractKey": null
};
(node as any).hash = '1ce8b3dbaa91ebf46db3f19d21b89396';
export default node;
