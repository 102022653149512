import { Typography } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'

interface Props {
  label: string
  value?: string | number | JSX.Element | null
  className?: string
}

const Container = styled.div`
  width: 100%;
  overflow: hidden;
`

const Label = styled(Typography)`
  font-size: 12px;
  // color: #333;
`

const Value = styled(Typography)`
  // color: #333;
  font-weight: 500;
  box-sizing: content-box;
  white-space: nowrap;
  overflow: hidden;
  line-height: 24px;
  text-overflow: ellipsis;
`

const KeyValueField: React.FC<Props> = ({ label, value, className }) => {
  const valueIsEmpty = value === null || value === undefined || (typeof value === 'string' && value.length === 0)
  return (
    <Container className={className}>
      <Label variant='caption' color='textSecondary'>
        {label}
      </Label>
      <Value variant='body1'>{valueIsEmpty ? '--' : value === 'true' ? '✓' : value === 'false' ? '✗' : value}</Value>
    </Container>
  )
}

export default KeyValueField
