/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type LayoutOrchestratorSessionQueryVariables = {};
export type LayoutOrchestratorSessionQueryResponse = {
    readonly sessionMe: {
        readonly username: string;
        readonly contact: {
            readonly slug: string;
            readonly name: string;
            readonly workspace: string;
            readonly contactType: string;
            readonly displayRoles: ReadonlyArray<{
                readonly name: string;
                readonly value: string;
            }>;
            readonly profileUrl: string | null;
            readonly email: string | null;
            readonly position: string | null;
        } | null;
        readonly defaultWorkspace: string;
        readonly allowedWorkspaces: ReadonlyArray<string>;
        readonly registeredInvitedContacts: ReadonlyArray<{
            readonly name: string;
            readonly workspace: string;
            readonly email: string | null;
        }>;
    } | null;
};
export type LayoutOrchestratorSessionQuery = {
    readonly response: LayoutOrchestratorSessionQueryResponse;
    readonly variables: LayoutOrchestratorSessionQueryVariables;
};



/*
query LayoutOrchestratorSessionQuery {
  sessionMe {
    username
    contact {
      slug
      name
      workspace
      contactType
      displayRoles {
        name
        value
      }
      profileUrl
      email
      position
      id
    }
    defaultWorkspace
    allowedWorkspaces
    registeredInvitedContacts {
      name
      workspace
      email
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "workspace",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contactType",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Role",
  "kind": "LinkedField",
  "name": "displayRoles",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profileUrl",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "position",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "defaultWorkspace",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "allowedWorkspaces",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "LayoutOrchestratorSessionQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "sessionMe",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Contact",
            "kind": "LinkedField",
            "name": "contact",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Contact",
            "kind": "LinkedField",
            "name": "registeredInvitedContacts",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "LayoutOrchestratorSessionQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "sessionMe",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Contact",
            "kind": "LinkedField",
            "name": "contact",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v11/*: any*/)
            ],
            "storageKey": null
          },
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Contact",
            "kind": "LinkedField",
            "name": "registeredInvitedContacts",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v7/*: any*/),
              (v11/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e4d04520d7f39e1cf6736d6b6e31bdf4",
    "id": null,
    "metadata": {},
    "name": "LayoutOrchestratorSessionQuery",
    "operationKind": "query",
    "text": "query LayoutOrchestratorSessionQuery {\n  sessionMe {\n    username\n    contact {\n      slug\n      name\n      workspace\n      contactType\n      displayRoles {\n        name\n        value\n      }\n      profileUrl\n      email\n      position\n      id\n    }\n    defaultWorkspace\n    allowedWorkspaces\n    registeredInvitedContacts {\n      name\n      workspace\n      email\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '4609ee51ca8821edb7f00f6c95532b66';
export default node;
