import { ClickAwayListener, darken, lighten } from '@material-ui/core'
import { get } from 'lodash'
import React, { ChangeEvent, useEffect } from 'react'
import styled from 'styled-components'
import { RendererOptions } from '../DataTable'
import DateBlankIcon from '../../assets/public/v2/date_blank.svg'

import 'rsuite/dist/rsuite.min.css'

type RendererComponent<T extends any> = (props: RendererOptions<T>) => JSX.Element

const OPERTOR_DEFAULT_VALUE_MAP: Record<string, any> = {
  eq: '',
  neq: '',
  gt: '',
  gte: '',
  lt: '',
  lte: '',
  in: [],
  not_in: [],
  contains: '',
  is_null: false,
  not_null: false,
  between: [],
}

const TYPE_ICON_MAP: Record<string, string> = {
  date: DateBlankIcon,
  datetime: DateBlankIcon,
  time: DateBlankIcon,
}

const Container = styled('div')<{ isEditing?: boolean }>((props) => ({
  position: 'relative',
  width: '-webkit-fill-available',
  boxSizing: 'border-box',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  // justifyContent: 'space-between',
  gap: '8px',
  height: '28px',
  padding: props.isEditing ? 0 : '4px 8px',
  borderRadius: '4px',
  transition: 'background-color 0.2s ease-in-out',

  '&:hover': {
    backgroundColor: props.theme.palette.action.hover,
  },
}))

const Input = styled('input')({
  fontSize: 'inherit',
  width: '100%',
  height: '28px',
  textIndent: '4px',
  paddingRight: '4px',
  borderRadius: '4px',
  border: 'none',
  outline: 'none',
  boxSizing: 'border-box',

  '&[type="date"]::-webkit-calendar-picker-indicator': {
    color: 'rgba(0, 0, 0, 0)',
    opacity: '1',
    display: 'block',
    background: `url("${DateBlankIcon}") no-repeat`,
    backgroundPosition: 'center',
    width: '14px',
    height: '14px',
    borderWidth: 'thin',
  },
})

const InputContainer = styled('div')(({ theme }) => ({
  flex: 1,
  width: 0,
  display: 'flex',
  alignItems: 'center',
  borderRadius: '4px',
  transition: 'background-color 0.2s ease-in-out',
  outline: 'none',
  border: `1.5px solid ${theme.palette.primary.main}`,

  '&:hover': {
    border: `1.5px solid ${theme.palette.primary.main}`,
  },

  '&:focus': {
    border: `1.5px solid ${theme.palette.primary.main}`,
  },

  '&:focus-within': {
    border: `1.5px solid ${theme.palette.primary.main}`,
  },
}))

export const InfoContainer = styled.div`
  td:hover & {
    opacity: 1;
  }

  height: 28px;
  opacity: 0;
  display: flex;
  flex: 0;
  align-items: center;
  justify-content: center;
  transition: 0.15s ease;
  background: ${(props) =>
    `linear-gradient(to right, transparent, ${
      props.theme.palette.type === 'light'
        ? darken(props.theme.palette.background.paper, 0.1)
        : lighten(props.theme.palette.background.paper, 0.1)
    } 50%)`};
  border-radius: inherit;
  margin-left: 8px;
  padding: 1px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`

export const Icon = styled('img')`
  width: 14px;
  height: 14px;
  color: ${(props) => props.theme.palette.text.primary};
`

const INPUT_WITH_PICKER = ['date', 'datetime', 'time']

const withInputRenderer =
  ({
    type = 'text',
  }: {
    type?:
      | 'button'
      | 'checkbox'
      | 'color'
      | 'date'
      | 'datetime'
      | 'email'
      | 'file'
      | 'hidden'
      | 'image'
      | 'month'
      | 'number'
      | 'password'
      | 'radio'
      | 'range'
      | 'reset'
      | 'search'
      | 'submit'
      | 'tel'
      | 'text'
      | 'time'
      | 'url'
      | 'week'
  }) =>
  <T extends Record<string, any>>(Component: RendererComponent<T>) =>
  ({ identifier, rowData, onSubmit, editable, value: defaultValue, ...props }: RendererOptions<T>) => {
    const initalValue = defaultValue || get(rowData, identifier)
    const [isEditing, setIsEditing] = React.useState(false)
    const [value, setValue] = React.useState(initalValue)

    useEffect(() => {
      setValue(initalValue)
    }, [initalValue])

    const handleSubmit = () => {
      // @ts-ignore
      // TODO: fix this for same value on client
      onSubmit?.({
        // ...rowData,
        slug: rowData.slug,
        [identifier]: type === 'number' && typeof value === 'string' ? Number.parseFloat(value) : value,
      })
      setIsEditing(false)
    }

    const inputTypeParams = {
      text: {
        value: value || '',
        onChange: (e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value as any),
      },
      number: {
        step: 1,
        value: value || '',
        onChange: (e: ChangeEvent<HTMLInputElement>) => setValue(e.target.value as any),
      },
      date: {
        value: value,
        onChange: (e: ChangeEvent<HTMLInputElement>) => {
          setValue(e.target.value as any)
        },
      },
      checkbox: {
        checked: Boolean(value),
        onChange: (e: ChangeEvent<HTMLInputElement>) => setValue(e.target.checked as any),
      },
    }

    return (
      <ClickAwayListener
        onClickAway={() => {
          setIsEditing(false)
        }}
      >
        <Container isEditing={isEditing && editable} tabIndex={0} onFocus={() => setIsEditing(true)} onClick={() => setIsEditing(true)}>
          {isEditing && editable ? (
            <InputContainer>
              <Input
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus
                type={type}
                {...(inputTypeParams as any)[type]}
                onFocus={(e: any) => INPUT_WITH_PICKER.includes(type) && e.currentTarget.showPicker()}
                onKeyDown={(e: any) => {
                  if (e.key === 'Enter') {
                    handleSubmit()
                    setIsEditing(false)
                  }
                }}
                onBlur={handleSubmit}
              />
            </InputContainer>
          ) : (
            <>
              <Component
                rowData={rowData}
                identifier={identifier}
                value={value}
                editable={editable}
                onSubmit={onSubmit}
                icon={TYPE_ICON_MAP[type]}
                {...props}
              />
            </>
          )}
        </Container>
      </ClickAwayListener>
    )
  }

export { Container as RendererRoot, OPERTOR_DEFAULT_VALUE_MAP }

export default withInputRenderer
