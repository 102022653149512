import React from 'react'
import { Card, CardContent, Typography } from '@material-ui/core'
import styled from 'styled-components'
import { graphql } from 'babel-plugin-relay/macro'
import { useFragment } from 'react-relay/hooks'
import { ShipmentCompanyCard_shipment$key } from '../../__generated__/ShipmentCompanyCard_shipment.graphql'
import ValidationField from './ShipmentValidationField'
import useNiceMutation from '../../mutations/useNiceMutation'
import Button from '../../components/Button'
import { ShipmentCompanyCardEoriMutation } from '../../__generated__/ShipmentCompanyCardEoriMutation.graphql'
import { ShipmentCompanyCardVatMutation } from '../../__generated__/ShipmentCompanyCardVatMutation.graphql'
import { useSnackbar } from 'notistack'

interface Props {
  shipment: ShipmentCompanyCard_shipment$key
}

const KeyFieldsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 8px;
  width: 100%;
`

const Validate = styled(Button)`
  width: 100%;
`

const Caption = styled(Typography)`
  text-align: center;
  display: block;
  font-size: 10px;
`

const ShipmentCompanyCard: React.FC<Props> = ({ shipment: data }) => {
  const shipment = useFragment(fragment, data)
  const company = shipment.company
  const [commitCheckEori] = useNiceMutation<ShipmentCompanyCardEoriMutation>(EoriCheckMutation)
  const { enqueueSnackbar } = useSnackbar()
  const handleEoriValidation = () => {
    commitCheckEori({
      variables: { input: { shipmentSlug: shipment.slug, generateDocument: true } },
      onCompleted: (res, errors) => {
        if (!errors) {
          {
            enqueueSnackbar(`Eori  ${shipment.company?.eori} is ${res.infoValidateShipmentEori?.docGenerated ? 'valid' : 'invalid'}`, {
              variant: res.infoValidateShipmentEori?.docGenerated ? 'success' : 'error',
            })
          }
        }
      },
    })
  }
  const [commitCheckVat] = useNiceMutation<ShipmentCompanyCardVatMutation>(VatCheckMutation)
  const handleVatValidation = () => {
    commitCheckVat({
      variables: { input: { shipmentSlug: shipment.slug, generateDocument: true } },
    })
  }

  return (
    <>
      {company && (
        <Card variant='outlined' style={{ marginBottom: '16px' }}>
          <CardContent>
            <Typography variant='h6' style={{ display: 'initial' }}>
              Company
            </Typography>
            <KeyFieldsContainer>
              <ValidationField
                label='EORI'
                value={company?.eori}
                validated={company?.eoriValid}
                validated_at={company?.eoriValidatedAt}
              ></ValidationField>
              <div>
                <Validate variant='outlined' onClick={handleEoriValidation}>
                  Validate EORI
                </Validate>
                <Caption variant='caption'>
                  {shipment.eoriDocGeneratedAt === null || shipment.eoriDocGeneratedAt === undefined
                    ? 'Validation doc not generated yet'
                    : `Doc last generated on ${shipment.eoriDocGeneratedAt}`}
                </Caption>
              </div>
              <ValidationField
                label='VAT'
                value={company?.vat}
                validated={company?.vatValid}
                validated_at={company?.vatValidatedAt}
              ></ValidationField>
              <div>
                <Validate variant='outlined' onClick={handleVatValidation}>
                  Validate VAT
                </Validate>
                <Caption variant='caption'>
                  {shipment.vatDocGeneratedAt === null || shipment.vatDocGeneratedAt === undefined
                    ? 'Validation doc not generated yet'
                    : `Doc last generated on ${shipment.vatDocGeneratedAt}`}
                </Caption>
              </div>
            </KeyFieldsContainer>
          </CardContent>
        </Card>
      )}
    </>
  )
}

const fragment = graphql`
  fragment ShipmentCompanyCard_shipment on Shipment {
    slug
    company {
      eori
      eoriValid
      eoriValidatedAt
      vat
      vatValid
      vatValidatedAt
    }
    eoriDocGeneratedAt
    vatDocGeneratedAt
  }
`

const EoriCheckMutation = graphql`
  mutation ShipmentCompanyCardEoriMutation($input: infoValidateShipmentEoriInput!) {
    infoValidateShipmentEori(input: $input) {
      clientMutationId
      docGenerated
    }
  }
`

const VatCheckMutation = graphql`
  mutation ShipmentCompanyCardVatMutation($input: infoValidateShipmentVatInput!) {
    infoValidateShipmentVat(input: $input) {
      clientMutationId
      docGenerated
    }
  }
`

export default ShipmentCompanyCard
