import React from 'react'
import { graphql } from 'babel-plugin-relay/macro'

import styled from 'styled-components'
import { Paper, Typography } from '@material-ui/core'
import { useFragment } from 'react-relay/hooks'
import LiveShareFolder from './LiveShareFolder'
import { LiveShareFoldersCard_shipmentShow$key } from '../../__generated__/LiveShareFoldersCard_shipmentShow.graphql'

interface Props {
  title: string
  shipmentShow: LiveShareFoldersCard_shipmentShow$key
  folderType: 'documents' | 'photos'
}

const Root = styled(Paper)`
  padding: 16px;
  margin-bottom: 16px;
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`

const LiveShareFoldersCard: React.FC<Props> = ({ shipmentShow: data, title, folderType }) => {
  const { folders } = useFragment(fragment, data)
  return (
    <>
      <Root variant='outlined'>
        <TitleContainer>
          <Typography variant='h6'>{title}</Typography>
        </TitleContainer>
        {folders.filter((f) => f?.parentType === folderType).map((node) => node && <LiveShareFolder key={node.slug} folder={node} />)}
      </Root>
    </>
  )
}

const fragment = graphql`
  fragment LiveShareFoldersCard_shipmentShow on LiveShareShowShipment {
    folders {
      slug
      parentType
      ...LiveShareFolder_folder
    }
  }
`

export default LiveShareFoldersCard
