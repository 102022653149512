import { useCallback, useState } from 'react'
import { ApiError, ApiRequest, Data } from '../api/ApiTypes'
import server, { setToken, unsetToken } from '../api/server'

export interface User {
  username: string
  name: string
  roles: string[]
  email: string
  profileUrl: string
}

// API lifecycle
interface Payload {
  user: {
    login: string
    password: string
    otp: string
  }
}
interface Response {
  current_user: User
  token: string
  message: string
  revoke_access: boolean
  otp_required_for_login: boolean
  invalid_ip: boolean
}

// use lifecycle
type LoginState = Data<Response>
interface LoginHelpers {
  doLogin: (login: string, password: string, otp: string) => void
}
interface Params {
  reloadSession: () => void
  onChange?: (state: LoginState) => void
}

export const initialState: LoginState = {
  data: null,
  error: null,
  loading: false,
}

const useLogin = ({ reloadSession, onChange }: Params): [LoginState, LoginHelpers] => {
  const [state, setState] = useState(initialState)

  const doLogin = useCallback(
    (login: string, password: string, otp: string) => {
      const changeState = (state: LoginState): void => {
        setState(state)
        if (onChange) onChange(state)
      }

      const apiCall: ApiRequest<Response, Payload> = {
        method: 'POST',
        path: '/users/sign_in',
        data: { user: { login, password, otp } },
        onSuccess: (data: Response, token: string) => {
          if (data.revoke_access) {
            changeState({ data, error: { message: 'Your account has been disabled. Please contact Team Odyssey.' }, loading: false })
          } else if (data.otp_required_for_login || data.invalid_ip) {
            changeState({ data, error: data.message ? { message: data.message } : null, loading: false })
          } else {
            changeState({ data, error: null, loading: false })
            setToken(token.replace('Bearer ', ''))
            reloadSession()
          }
        },
        onFail: (error: ApiError) => {
          changeState({ data: null, error, loading: false })
        },
      }
      setState({ ...initialState, loading: true })
      unsetToken()
      server(apiCall)
    },
    [reloadSession, setState, onChange]
  )

  return [state, { doLogin }]
}
export default useLogin
