import React from 'react'
import styled from 'styled-components'
import { media } from '../theme'

interface Props {
  hero?: React.ReactNode
  firstGroup?: React.ReactNode
  secondGroup?: React.ReactNode
  timeline?: React.ReactNode
}

const Layout = styled.div`
  margin: auto;
  display: grid;
  width: 1660px;
  padding-top: 16px;
  grid-template-areas:
    'he he he he he he he he he he he he he'
    'fg fg fg fg sg sg sg sg ti ti ti ti ti';
  grid-template-rows: auto auto;
  grid-template-columns: repeat(13, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 30px;

  ${media.medium`
    width: 1140px;
    padding-top: 8px;
    grid-template-areas:
    'he he he he he he he he he'
    'fg fg fg fg ti ti ti ti ti'
    'sg sg sg sg ti ti ti ti ti';
    grid-template-rows: auto auto auto;
    grid-template-columns: repeat(9, 1fr);
  `}

  ${media.small`
    width: 100%;
    padding-top: 0;
    min-width: 360px;
    max-width: 625px;
    grid-template-columns: auto;
    grid-template-rows: auto auto auto auto;
    grid-template-areas: 'he' 'fg' 'sg' 'ti';
  `}
`

const Hero = styled.div`
  grid-area: he;
`

const FirstGroup = styled.div`
  grid-area: fg;
`

const SecondGroup = styled.div`
  grid-area: sg;
`

const Timeline = styled.div`
  grid-area: ti;
`

const ShowLayout: React.FC<Props> = ({ hero, firstGroup, secondGroup, timeline }) => {
  return (
    <Layout>
      <Hero>{hero}</Hero>
      <FirstGroup>{firstGroup}</FirstGroup>
      <SecondGroup>{secondGroup}</SecondGroup>
      <Timeline>{timeline}</Timeline>
    </Layout>
  )
}

export default ShowLayout
