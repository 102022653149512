/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type infoValidateShipmentVatInput = {
    clientMutationId?: string | null;
    shipmentSlug: string;
    generateDocument?: boolean | null;
};
export type ShipmentCompanyCardVatMutationVariables = {
    input: infoValidateShipmentVatInput;
};
export type ShipmentCompanyCardVatMutationResponse = {
    readonly infoValidateShipmentVat: {
        readonly clientMutationId: string | null;
        readonly docGenerated: boolean;
    } | null;
};
export type ShipmentCompanyCardVatMutation = {
    readonly response: ShipmentCompanyCardVatMutationResponse;
    readonly variables: ShipmentCompanyCardVatMutationVariables;
};



/*
mutation ShipmentCompanyCardVatMutation(
  $input: infoValidateShipmentVatInput!
) {
  infoValidateShipmentVat(input: $input) {
    clientMutationId
    docGenerated
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "infoValidateShipmentVatPayload",
    "kind": "LinkedField",
    "name": "infoValidateShipmentVat",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "docGenerated",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ShipmentCompanyCardVatMutation",
    "selections": (v1/*: any*/),
    "type": "ApplicationCommand",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ShipmentCompanyCardVatMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7c3864c6655cadd85412972701e2a1a5",
    "id": null,
    "metadata": {},
    "name": "ShipmentCompanyCardVatMutation",
    "operationKind": "mutation",
    "text": "mutation ShipmentCompanyCardVatMutation(\n  $input: infoValidateShipmentVatInput!\n) {\n  infoValidateShipmentVat(input: $input) {\n    clientMutationId\n    docGenerated\n  }\n}\n"
  }
};
})();
(node as any).hash = '379e787472e43fe97ef00c3f5e0b4e5c';
export default node;
