/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type UploadDocumentsWizard_folders = {
    readonly nodes: ReadonlyArray<{
        readonly slug: string;
        readonly activeTicket: string;
        readonly parentType: string;
        readonly attachments: ReadonlyArray<{
            readonly id: string;
            readonly documentType: string | null;
        }>;
    } | null> | null;
    readonly " $refType": "UploadDocumentsWizard_folders";
};
export type UploadDocumentsWizard_folders$data = UploadDocumentsWizard_folders;
export type UploadDocumentsWizard_folders$key = {
    readonly " $data"?: UploadDocumentsWizard_folders$data;
    readonly " $fragmentRefs": FragmentRefs<"UploadDocumentsWizard_folders">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UploadDocumentsWizard_folders",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Folder",
      "kind": "LinkedField",
      "name": "nodes",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "activeTicket",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "parentType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "EntityAttachment",
          "kind": "LinkedField",
          "name": "attachments",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "documentType",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "FolderConnection",
  "abstractKey": null
};
(node as any).hash = 'e15cd93ff1c25b3c12b21a222f608e0a';
export default node;
