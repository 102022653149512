/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type NoteEntityTypeEnum = "contacts" | "containers" | "shipments" | "vessels" | "voyages" | "%future added value";
export type NotesExternalQueryVariables = {
    entityType: NoteEntityTypeEnum;
    entitySlug: string;
};
export type NotesExternalQueryResponse = {
    readonly externalNoteList: ReadonlyArray<{
        readonly slug: string;
        readonly content: string;
        readonly addedAt: string;
        readonly isSticky: boolean;
        readonly addedBy: {
            readonly username: string;
        };
    }>;
};
export type NotesExternalQuery = {
    readonly response: NotesExternalQueryResponse;
    readonly variables: NotesExternalQueryVariables;
};



/*
query NotesExternalQuery(
  $entityType: NoteEntityTypeEnum!
  $entitySlug: String!
) {
  externalNoteList(entityType: $entityType, entitySlug: $entitySlug) {
    slug
    content
    addedAt
    isSticky
    addedBy {
      username
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "entitySlug"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "entityType"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "entitySlug",
        "variableName": "entitySlug"
      },
      {
        "kind": "Variable",
        "name": "entityType",
        "variableName": "entityType"
      }
    ],
    "concreteType": "Note",
    "kind": "LinkedField",
    "name": "externalNoteList",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "slug",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "content",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "addedAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isSticky",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "addedBy",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "NotesExternalQuery",
    "selections": (v2/*: any*/),
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "NotesExternalQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "0cba1721609fee8a493d6268c2ff0596",
    "id": null,
    "metadata": {},
    "name": "NotesExternalQuery",
    "operationKind": "query",
    "text": "query NotesExternalQuery(\n  $entityType: NoteEntityTypeEnum!\n  $entitySlug: String!\n) {\n  externalNoteList(entityType: $entityType, entitySlug: $entitySlug) {\n    slug\n    content\n    addedAt\n    isSticky\n    addedBy {\n      username\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '3508f31d4a839f43d01e3df98c83f697';
export default node;
