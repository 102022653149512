import React, { useEffect, useState } from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import styled from 'styled-components'
import { AttachmentSelectionDialog_emailForm$key } from '../../__generated__/AttachmentSelectionDialog_emailForm.graphql'
import { useFragment } from 'react-relay/hooks'
import OdysseyDialog from '../OdysseyDialog'
import { DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import Button from '../Button'
import { NiceTabsUI } from '../TabsUI/TabsUI'
import { media } from '../../theme'
import EntityAttachment from '../File/EntityAttachment'
import { CheckCircle } from '@material-ui/icons'

interface Props {
  open: boolean
  onClose: () => void
  onSelection: (selectedAttachmentSlugs: string[]) => void
  emailForm: AttachmentSelectionDialog_emailForm$key
  selectedAttachmentSlugs: string[]
}

const StyledDialogTitle = styled(DialogTitle)`
  padding-bottom: 0px;
`

const FolderSection = styled.div`
  margin-bottom: 16px;
`

export const FilesContainer = styled.div`
  margin-top: 8px;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-auto-rows: 120px;
  grid-gap: 16px;

  ${media.small`
    grid-template-columns: repeat(3, minmax(0, 1fr));
  `}
`

export const SelectableAttachmentWrapper = styled.div<{ $selected: boolean }>`
  position: relative;
  border-radius: 4px;
  border: 2px solid ${(props) => (props.$selected ? props.theme.customPalette.selectionIcon : 'transparent')};
`

export const SlectableAttachmentIconButton = styled.div`
  position: absolute;
  left: -8px;
  top: -8px;
  color: ${(props) => props.theme.customPalette.selectionIcon};
`

export const StyledCheckCircle = styled(CheckCircle)`
  position: relative;
  border-radius: 50%;
  background-color: ${(props) => props.theme.palette.background.paper};
`

const NoDataText = styled(Typography).attrs({ variant: 'h6' })`
  color: ${(props) => props.theme.customPalette.text.noData};
  text-align: center;
  padding: 16px;
  font-weight: 400;
`

const AttachmentSelectionDialog: React.FC<Props> = ({ emailForm, selectedAttachmentSlugs, open, onClose, onSelection }) => {
  const { attachmentFolderSets } = useFragment(fragment, emailForm)
  const [activeFolderSetKey, setActiveFolderSetKey] = useState<string | null>(attachmentFolderSets[0]?.key)
  const activeFolderSet = attachmentFolderSets.find((folderSet) => folderSet.key === activeFolderSetKey)
  const activeFoldersWithAttachments = activeFolderSet?.folders.filter((folder) => folder.attachments.length > 0) || []

  const [localSelectedAttachmentSlugs, setLocalSelectedAttachmentSlugs] = useState<string[]>([])
  useEffect(() => setLocalSelectedAttachmentSlugs(selectedAttachmentSlugs), [selectedAttachmentSlugs])

  return (
    <OdysseyDialog open={open} onClose={onClose} fullWidth style={{ maxWidth: '920px', margin: 'auto' }}>
      <StyledDialogTitle>Select Attachments</StyledDialogTitle>
      {attachmentFolderSets.length > 1 && (
        <NiceTabsUI
          tabs={attachmentFolderSets.map((folderSet) => ({ label: folderSet.title, value: folderSet.key }))}
          value={activeFolderSetKey}
          onChange={(newValue) => setActiveFolderSetKey(newValue)}
        />
      )}
      <DialogContent>
        {activeFoldersWithAttachments.length > 0 ? (
          activeFoldersWithAttachments.map(
            (folder) =>
              folder.attachments.length > 0 && (
                <FolderSection key={folder.slug}>
                  <Typography style={{ marginBottom: '16px' }} variant='body1'>
                    {folder.name}{' '}
                    <span style={{ padding: '4px 6px', backgroundColor: '#e4e4e4', color: '#212121', borderRadius: '4px' }}>
                      {folder.entitySlug.toUpperCase()}
                    </span>
                  </Typography>
                  <FilesContainer>
                    {folder.attachments.map((attachment) => (
                      <SelectableAttachmentWrapper
                        key={attachment.attachmentSlug}
                        $selected={localSelectedAttachmentSlugs.includes(attachment.attachmentSlug)}
                        onClick={() =>
                          setLocalSelectedAttachmentSlugs((prevState) =>
                            prevState.includes(attachment.attachmentSlug)
                              ? prevState.filter((slug) => slug !== attachment.attachmentSlug)
                              : prevState.concat([attachment.attachmentSlug])
                          )
                        }
                      >
                        <EntityAttachment attachment={attachment} />
                        {localSelectedAttachmentSlugs.includes(attachment.attachmentSlug) && (
                          <SlectableAttachmentIconButton>
                            <StyledCheckCircle />
                          </SlectableAttachmentIconButton>
                        )}
                      </SelectableAttachmentWrapper>
                    ))}
                  </FilesContainer>
                </FolderSection>
              )
          )
        ) : (
          <>
            <FilesContainer />
            <NoDataText>No attachments</NoDataText>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button variant='contained' onClick={() => onSelection(localSelectedAttachmentSlugs)}>
          OK
        </Button>
      </DialogActions>
    </OdysseyDialog>
  )
}

const fragment = graphql`
  fragment AttachmentSelectionDialog_emailForm on EmailFormResponse {
    attachmentFolderSets {
      key
      title
      folders {
        slug
        entitySlug
        name
        attachments {
          attachmentSlug
          ...EntityAttachment_attachment
        }
      }
    }
  }
`

export default AttachmentSelectionDialog
