/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LinkedContactsTabShipmentQueryVariables = {
    slug: string;
};
export type LinkedContactsTabShipmentQueryResponse = {
    readonly shipmentShow: {
        readonly linkedContacts: {
            readonly " $fragmentRefs": FragmentRefs<"LinkedContactsTab_linkedContacts">;
        };
    };
};
export type LinkedContactsTabShipmentQuery = {
    readonly response: LinkedContactsTabShipmentQueryResponse;
    readonly variables: LinkedContactsTabShipmentQueryVariables;
};



/*
query LinkedContactsTabShipmentQuery(
  $slug: String!
) {
  shipmentShow(slug: $slug) {
    linkedContacts {
      ...LinkedContactsTab_linkedContacts
    }
    id
  }
}

fragment ItemImage_data on Image {
  url
  alt
  highlight
  shape
  overlay
  overlayIcon
}

fragment KeyField_data on KeyField {
  label
  value {
    key
    ...SemanticString_data
  }
}

fragment LinkedContactsTab_linkedContacts on ContactLinkConnection {
  edges {
    node {
      contactSlug
      role
      contactItem {
        title
        id
      }
      ...ShowLinkedContactsItem_linkedContact
    }
  }
}

fragment SemanticLine_data on SemanticLine {
  parts {
    key
    ...SemanticString_data
  }
}

fragment SemanticString_data on SemanticString {
  text
}

fragment ShowLinkedContactsItem_linkedContact on ContactLink {
  role
  roles
  contactSlug
  contactItem {
    slug
    title
    isArchived
    path
    itemType
    statuses {
      key
      value {
        ...SemanticString_data
      }
    }
    image {
      ...ItemImage_data
    }
    lines {
      key
      ...SemanticLine_data
    }
    keyFields {
      key
      ...KeyField_data
    }
    stickyNoteContent
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v5 = [
  (v3/*: any*/),
  (v4/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LinkedContactsTabShipmentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Shipment",
        "kind": "LinkedField",
        "name": "shipmentShow",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContactLinkConnection",
            "kind": "LinkedField",
            "name": "linkedContacts",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "LinkedContactsTab_linkedContacts"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LinkedContactsTabShipmentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Shipment",
        "kind": "LinkedField",
        "name": "shipmentShow",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ContactLinkConnection",
            "kind": "LinkedField",
            "name": "linkedContacts",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ContactLinkEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ContactLink",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "contactSlug",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "role",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ListItem",
                        "kind": "LinkedField",
                        "name": "contactItem",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          },
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "slug",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isArchived",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "path",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "itemType",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Status",
                            "kind": "LinkedField",
                            "name": "statuses",
                            "plural": true,
                            "selections": [
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "SemanticString",
                                "kind": "LinkedField",
                                "name": "value",
                                "plural": false,
                                "selections": [
                                  (v4/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Image",
                            "kind": "LinkedField",
                            "name": "image",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "url",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "alt",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "highlight",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "shape",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "overlay",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "overlayIcon",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SemanticLine",
                            "kind": "LinkedField",
                            "name": "lines",
                            "plural": true,
                            "selections": [
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "SemanticString",
                                "kind": "LinkedField",
                                "name": "parts",
                                "plural": true,
                                "selections": (v5/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "KeyField",
                            "kind": "LinkedField",
                            "name": "keyFields",
                            "plural": true,
                            "selections": [
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "label",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "SemanticString",
                                "kind": "LinkedField",
                                "name": "value",
                                "plural": true,
                                "selections": (v5/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "stickyNoteContent",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "roles",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "72142e00b1edb045942f7687f4cb9d30",
    "id": null,
    "metadata": {},
    "name": "LinkedContactsTabShipmentQuery",
    "operationKind": "query",
    "text": "query LinkedContactsTabShipmentQuery(\n  $slug: String!\n) {\n  shipmentShow(slug: $slug) {\n    linkedContacts {\n      ...LinkedContactsTab_linkedContacts\n    }\n    id\n  }\n}\n\nfragment ItemImage_data on Image {\n  url\n  alt\n  highlight\n  shape\n  overlay\n  overlayIcon\n}\n\nfragment KeyField_data on KeyField {\n  label\n  value {\n    key\n    ...SemanticString_data\n  }\n}\n\nfragment LinkedContactsTab_linkedContacts on ContactLinkConnection {\n  edges {\n    node {\n      contactSlug\n      role\n      contactItem {\n        title\n        id\n      }\n      ...ShowLinkedContactsItem_linkedContact\n    }\n  }\n}\n\nfragment SemanticLine_data on SemanticLine {\n  parts {\n    key\n    ...SemanticString_data\n  }\n}\n\nfragment SemanticString_data on SemanticString {\n  text\n}\n\nfragment ShowLinkedContactsItem_linkedContact on ContactLink {\n  role\n  roles\n  contactSlug\n  contactItem {\n    slug\n    title\n    isArchived\n    path\n    itemType\n    statuses {\n      key\n      value {\n        ...SemanticString_data\n      }\n    }\n    image {\n      ...ItemImage_data\n    }\n    lines {\n      key\n      ...SemanticLine_data\n    }\n    keyFields {\n      key\n      ...KeyField_data\n    }\n    stickyNoteContent\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '7f1531d109f22a6c7c81ba352c96d07c';
export default node;
