import { Button, Grow, IconButton, InputLabel, Typography } from '@material-ui/core'
import { Add, Apps, Clear } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import { graphql } from 'babel-plugin-relay/macro'
import { FieldArray, Form, useField, useFormikContext } from 'formik'
import React, { useState } from 'react'
import { useLazyLoadQuery } from 'react-relay/hooks'
import { TransitionGroup } from 'react-transition-group'
import styled from 'styled-components'
import ExternalContainerField from '../../components/Form/ExternalContainerField'
import ExternalVoyageField from '../../components/Form/ExternalVoyageField'
import FormActionBar from '../../components/Form/FormActionBar'
import InputField from '../../components/Form/InputField'
import NiceFormik from '../../components/Form/NiceFormik'
import { FieldT } from '../../components/Form/types'
import LoadingDots from '../../components/LoadingDots'
import {
  DetailsFieldsContainer,
  NewButtonPaper,
  RemoveShipmentButton,
  ShipmentItem,
  VinContainer,
  VinDetailsTypography,
} from '../../forms/Container/ContainerForm'
import useVinDetails from '../../hooks/useVinDetails'
import useWorkspaceNavigate from '../../hooks/useWorkspaceNavigate'
import useNiceMutation from '../../mutations/useNiceMutation'
import { media } from '../../theme'
import { ExternalTicketAddMutation } from '../../__generated__/ExternalTicketAddMutation.graphql'
import { ExternalTicketAddQuery } from '../../__generated__/ExternalTicketAddQuery.graphql'

const Layout = styled.div`
  width: 1440px;
  padding-top: 32px;
  margin: auto;
  display: grid;
  grid-template-columns: 1;
  grid-gap: 16px;

  ${media.small`
    width: 100%;
    padding-top: 0;
    min-width: 360px;
    max-width: 625px;
    grid-template-columns: auto;
  `}
`

const Titlebar = styled.div`
  padding: 8px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const StyledForm = styled(Form)`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`
const FieldsContainer = styled.div`
  margin-bottom: 16px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;

  ${media.medium`
    grid-template-columns: repeat(1, 1fr);
  `}

  ${media.small`
    grid-template-columns: auto;
  `}
`

const NewContainerForm: React.FC = () => {
  const data = useLazyLoadQuery<ExternalTicketAddQuery>(query, {})
  const fields = data.infoNewTicketForm.formSchema.fieldSet
  const fieldMaster = Object.fromEntries(fields.map((field) => [field.name, field]))

  return (
    <>
      <FieldsContainer>
        <InputField of='containerNumber' field={fieldMaster['containerNumber']} />
        <InputField of='containerType' field={fieldMaster['containerType']} />
        <ExternalVoyageField field={fieldMaster['voyageSlug']} withShippingLine />
      </FieldsContainer>
      <ShipmentQuickAddCard fieldMaster={fieldMaster} />
    </>
  )
}

interface ShipmentQuickAddProps {
  fieldMaster: {
    [key: string]: FieldT
  }
}

const ShipmentQuickAddCard: React.FC<ShipmentQuickAddProps> = ({ fieldMaster }) => {
  const { values }: { values: any } = useFormikContext()
  const shipmentFieldMaster = fieldMaster['shipments'].arrayItems
    ? Object.fromEntries(fieldMaster['shipments'].arrayItems?.map((field) => [field.name, field]))
    : {}
  const vinFields = ['vinOrigin', 'vinEngine', 'vinFuelType', 'vinBodyClass']

  return (
    <div>
      <InputLabel style={{ marginBottom: '12px' }}>Shipments</InputLabel>
      <FieldArray
        name='shipments'
        render={(arrayHelpers) => (
          <>
            <TransitionGroup component={null}>
              {values.shipments &&
                values.shipments.length > 0 &&
                values.shipments.map((shipment: any, idx: any) => {
                  const prefix = `shipments[${idx}]`
                  const prefixed = (name: string) => `shipments[${idx}].${name}`
                  return (
                    <Grow in={true} key={shipment.key} style={{ transformOrigin: 'center top' }}>
                      <ShipmentItem>
                        <DetailsFieldsContainer>
                          <InputField
                            of={prefixed('shipmentType')}
                            field={{ ...shipmentFieldMaster['shipmentType'], name: prefixed('shipmentType') }}
                          />
                          {shipment.shipmentType !== 'package' ? (
                            <VinField shipmentFieldMaster={shipmentFieldMaster} namePrefix={prefix} />
                          ) : (
                            <div />
                          )}
                          <InputField
                            of={prefixed('description')}
                            field={{ ...shipmentFieldMaster['description'], name: prefixed('description') }}
                          />
                          <InputField of={prefixed('weight')} field={{ ...shipmentFieldMaster['weight'], name: prefixed('weight') }} />
                        </DetailsFieldsContainer>
                        {shipment.shipmentType !== 'package' && (
                          <VinDetailsTypography>
                            {vinFields.map((fieldName, idx) => (
                              <>
                                {shipmentFieldMaster[fieldName]?.title}:{' '}
                                {shipment[fieldName] === undefined || shipment[fieldName] === null || shipment[fieldName] === ''
                                  ? '__'
                                  : shipment[fieldName]}
                                {idx === vinFields.length - 1 ? '' : ', '}
                              </>
                            ))}
                          </VinDetailsTypography>
                        )}
                        <RemoveShipmentButton size='small' onClick={() => arrayHelpers.remove(idx)}>
                          <Clear fontSize='small' />
                        </RemoveShipmentButton>
                      </ShipmentItem>
                    </Grow>
                  )
                })}
            </TransitionGroup>
            <NewButtonPaper
              variant='outlined'
              onClick={() =>
                arrayHelpers.push({
                  key: Math.random().toString().slice(2, 8),
                  description: '',
                })
              }
            >
              <Add />
            </NewButtonPaper>
          </>
        )}
      />
    </div>
  )
}

interface VinFieldProps {
  shipmentFieldMaster: {
    [key: string]: FieldT
  }
  namePrefix: string
}

const VinField: React.FC<VinFieldProps> = ({ shipmentFieldMaster, namePrefix }) => {
  const { setFieldValue } = useFormikContext()
  const [, meta] = useField(namePrefix)
  const [fetchVin, vinFetchIsInProgress] = useVinDetails()
  const handleVinFetch = () => {
    fetchVin(meta.value.vin, {
      onSuccess: (vinDetails) => {
        setFieldValue(`${namePrefix}.description`, vinDetails.description)
        setFieldValue(`${namePrefix}.vinEngine`, vinDetails.vinEngine)
        setFieldValue(`${namePrefix}.vinFuelType`, vinDetails.vinFuelType)
        setFieldValue(`${namePrefix}.vinBodyClass`, vinDetails.vinBodyClass)
        setFieldValue(`${namePrefix}.vinOrigin`, vinDetails.vinOrigin)
      },
    })
  }
  const validateVin = (vin: string) => {
    let error
    if (!vin && meta.value.shipmentType !== 'package') {
      error = 'VIN is required'
    }
    return error
  }

  return (
    <VinContainer>
      <InputField of='vin' field={{ ...shipmentFieldMaster['vin'], name: `${namePrefix}.vin` }} validate={validateVin} />
      <IconButton>{vinFetchIsInProgress ? <LoadingDots variant='dark' /> : <Apps fontSize='large' onClick={handleVinFetch} />}</IconButton>
    </VinContainer>
  )
}

const ExternalTicketAdd: React.FC & { Skeleton: React.FC } = () => {
  const data = useLazyLoadQuery<ExternalTicketAddQuery>(query, {})
  const fields = data.infoNewTicketForm.formSchema.fieldSet
  const fieldMaster = Object.fromEntries(fields.map((field) => [field.name, field]))
  const [mutationError, setMutationError] = useState<null | string>(null)
  const [commit] = useNiceMutation<ExternalTicketAddMutation>(mutation)
  const wsNavigate = useWorkspaceNavigate()

  return (
    <Layout>
      <div>
        <Titlebar>
          <div>
            <Typography variant='h5'>Add Tickets</Typography>
          </div>
        </Titlebar>
        <NiceFormik
          initialValues={{ entityType: '', ticketType: '', entitySlug: '' }}
          onSubmit={(values, actions) => {
            const processedValues = {
              ...values,
              shipments: (values as any).shipments?.map((shipment: any) => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { key: _, ...shipmentWithoutKey } = shipment

                return shipmentWithoutKey
              }),
            }
            commit({
              variables: {
                input: processedValues as any,
              },
              onError: () => actions.setSubmitting(false),
              onCompleted: (res, errors) => {
                actions.setSubmitting(false)
                if (errors) {
                  setMutationError(errors.map((err) => err.message).join(', '))
                } else {
                  wsNavigate(`/ticket/${res.infoNewTicket?.ticket.slug}/home`)
                }
              },
            })
          }}
          formSchema={fields}
        >
          {({ submitForm, values }) => (
            <StyledForm>
              {mutationError && <Alert severity='error'>{mutationError}</Alert>}
              <FieldsContainer>
                <div style={{ marginBottom: '8px' }}>
                  <InputLabel style={{ marginBottom: '12px' }}>Ticket Type</InputLabel>
                  <InputField of={'ticketType'} field={fieldMaster['ticketType']} />
                </div>
                {/* <div>
                  <InputLabel style={{ marginBottom: '12px' }}>Request from</InputLabel>
                  <InputField of={'raisedFor'} field={fieldMaster['raisedFor']} />
                </div> */}
                <div style={{ marginBottom: '16px' }}>
                  <InputLabel style={{ marginBottom: '12px' }}>Provide a small description</InputLabel>
                  <InputField variant='large' of={'description'} field={fieldMaster['description']} />
                </div>
              </FieldsContainer>
              {values.ticketType === 'misc' && (
                <div style={{ marginBottom: '8px' }}>
                  <InputLabel style={{ marginBottom: '12px' }}>Title</InputLabel>
                  <InputField of={'title'} field={fieldMaster['title']} />
                </div>
              )}
              <div style={{ marginBottom: '16px' }}>
                {values.ticketType === 'request_to_create_container_shipment' && (
                  <>
                    <div style={{ marginBottom: '8px' }}>
                      <InputLabel style={{ marginBottom: '12px' }}>Entity</InputLabel>
                      <InputField of={'entityType'} field={fieldMaster['entityType']} />
                    </div>
                    {values.entityType === 'containers' && (
                      <div style={{ marginBottom: '8px' }}>
                        <InputLabel style={{ marginBottom: '12px' }}>Container Details</InputLabel>
                        <NewContainerForm />
                      </div>
                    )}
                    {values.entityType === 'shipments' && (
                      <div style={{ marginBottom: '8px' }}>
                        <InputLabel style={{ marginBottom: '12px' }}>Container</InputLabel>
                        <ExternalContainerField field={fieldMaster['containerSlug']} />
                        <ShipmentQuickAddCard fieldMaster={fieldMaster} />
                      </div>
                    )}
                  </>
                )}
              </div>
              <Button onClick={submitForm}>Save</Button>
            </StyledForm>
          )}
        </NiceFormik>
      </div>
    </Layout>
  )
}

const mutation = graphql`
  mutation ExternalTicketAddMutation($input: infoNewTicketInput!) {
    infoNewTicket(input: $input) {
      clientMutationId
      ticket {
        slug
      }
    }
  }
`

const query = graphql`
  query ExternalTicketAddQuery($entityType: String) {
    infoNewTicketForm(entityType: $entityType) {
      formSchema {
        fieldSet {
          name
          type
          required
          title
          description
          format
          enum
          enumTitles

          arrayItems {
            name
            type
            title
            required
            description
            format
            enum
            enumTitles
          }
        }
      }
    }
  }
`

ExternalTicketAdd.Skeleton = () => <FormActionBar.Skeleton />

export default ExternalTicketAdd
