/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SemanticString_data = {
    readonly text: string;
    readonly " $refType": "SemanticString_data";
};
export type SemanticString_data$data = SemanticString_data;
export type SemanticString_data$key = {
    readonly " $data"?: SemanticString_data$data;
    readonly " $fragmentRefs": FragmentRefs<"SemanticString_data">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SemanticString_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    }
  ],
  "type": "SemanticString",
  "abstractKey": null
};
(node as any).hash = '48b933daed82cd33b8fcf7afd257063d';
export default node;
