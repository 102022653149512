/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type FormFieldTypeEnum = "array" | "boolean" | "boolean_switch" | "container" | "container_image_slug" | "date" | "datetime" | "doc_slug" | "file_slug" | "image_slug" | "linked_contacts" | "multi_select" | "number" | "parent_shipment" | "port" | "rate_card" | "rich_text" | "searchable" | "shipment_image_slug" | "shipping_line" | "string" | "vessel" | "voyage" | "%future added value";
export type FolderQueryVariables = {
    slug: string;
};
export type FolderQueryResponse = {
    readonly infoUpdateFolderForm: {
        readonly formSchema: {
            readonly fieldSet: ReadonlyArray<{
                readonly name: string;
                readonly type: FormFieldTypeEnum;
                readonly required: boolean;
                readonly title: string | null;
                readonly description: string | null;
                readonly format: string | null;
                readonly enum: ReadonlyArray<string | null> | null;
                readonly enumTitles: ReadonlyArray<string | null> | null;
            }>;
        };
        readonly initialValue: {
            readonly slug: string;
            readonly name: string;
        } | null;
    };
};
export type FolderQuery = {
    readonly response: FolderQueryResponse;
    readonly variables: FolderQueryVariables;
};



/*
query FolderQuery(
  $slug: String!
) {
  infoUpdateFolderForm(slug: $slug) {
    formSchema {
      fieldSet {
        name
        type
        required
        title
        description
        format
        enum
        enumTitles
      }
    }
    initialValue {
      slug
      name
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "FormSchema",
  "kind": "LinkedField",
  "name": "formSchema",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "FormField",
      "kind": "LinkedField",
      "name": "fieldSet",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "required",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "format",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "enum",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "enumTitles",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FolderQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "InfoUpdateFolderFormResponse",
        "kind": "LinkedField",
        "name": "infoUpdateFolderForm",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Folder",
            "kind": "LinkedField",
            "name": "initialValue",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FolderQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "InfoUpdateFolderFormResponse",
        "kind": "LinkedField",
        "name": "infoUpdateFolderForm",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Folder",
            "kind": "LinkedField",
            "name": "initialValue",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3e4c0fba5b294dbe26592f5fc11d3d1b",
    "id": null,
    "metadata": {},
    "name": "FolderQuery",
    "operationKind": "query",
    "text": "query FolderQuery(\n  $slug: String!\n) {\n  infoUpdateFolderForm(slug: $slug) {\n    formSchema {\n      fieldSet {\n        name\n        type\n        required\n        title\n        description\n        format\n        enum\n        enumTitles\n      }\n    }\n    initialValue {\n      slug\n      name\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'd525e99254664b29ed2637c8117e7961';
export default node;
