import { Typography } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'
import { isSmall, media } from '../theme'

// DISCLAIMER: don't refer these types for backend
interface StageT {
  text: string
  subText: string
  status: 'completed' | 'incomplete' | 'active'
}

interface Props {
  stages: StageT[]
}

const Step = styled.div`
  padding: 8px 16px;
  flex-grow: 1;
  display: flex;
  align-items: center;
  background-color: white;
  position: relative;
  height: 64px;
  margin: 0 24px;

  ${media.tablet`
    margin: 0 14px;
  `}

  ${media.mobile`
    margin: 0 14px;
  `}

  &:before {
    content: '';
    position: absolute;
    left: -23px;
    display: block;
    width: 0;
    height: 0;
    border-top: 32px solid white;
    border-bottom: 32px solid white;
    border-left: 24px solid transparent;
  }

  &:after {
    content: '';
    position: absolute;
    right: -23px;
    display: block;
    width: 0;
    height: 0;
    border-top: 32px solid transparent;
    border-bottom: 32px solid transparent;
    border-left: 24px solid white;
  }
`

const Container = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  overflow-x: auto;

  ${Step}:last-child {
    margin-right: 0;
    &:after {
      content: none;
    }
  }

  ${Step}:first-child {
    margin-left: 0;
    &:before {
      content: none;
    }
  }
`

const StepNumber = styled(Typography)`
  font-weight: 600;
`

const StepLabel = styled.div`
  padding-left: 16px;
`

const StepTitle = styled(Typography)`
  font-size: 1.1rem;
  white-space: nowrap;
`

const StepCaption = styled(Typography)`
  white-space: nowrap;
`

const Stages: React.FC<Props> = ({ stages }) => {
  return (
    <Container>
      {stages.map((stage, idx) => (
        <Step key={stage.text}>
          <StepNumber variant='h5'>{idx}</StepNumber>
          {(!isSmall() || stage.status === 'active') && (
            <StepLabel>
              <StepTitle variant='h6'>{stage.text}</StepTitle>
              <StepCaption variant='caption' color='textSecondary'>
                {stage.subText}
              </StepCaption>
            </StepLabel>
          )}
        </Step>
      ))}
    </Container>
  )
}

export default Stages
