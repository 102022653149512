import { graphql } from 'babel-plugin-relay/macro'
import React from 'react'
import { useLazyLoadQuery } from 'react-relay/hooks'
import FormActionBar from '../../components/Form/FormActionBar'
import { inputHashToLinkedContacts, linkedContactToInputHash } from '../../components/Form/LinkedContactsField'
import NiceFormik from '../../components/Form/NiceFormik'
import ShipmentForm from '../../forms/Shipment/ShipmentForm'
import useNiceMutation from '../../mutations/useNiceMutation'
import { ShipmentEditFormQuery } from '../../__generated__/ShipmentEditFormQuery.graphql'
import { ShipmentEditMutation } from '../../__generated__/ShipmentEditMutation.graphql'
import FormContainer from '../../forms/FormContainer'

interface Props {
  slug: string
  onClose: () => void
}

const ShipmentEdit: React.FC<Props> & { Skeleton: React.FC } = ({ slug, onClose }) => {
  const data = useLazyLoadQuery<ShipmentEditFormQuery>(query, { slug: slug })
  const fields = data.infoUpdateShipmentForm.formSchema.fieldSet
  const fieldMaster = Object.fromEntries(fields.map((field) => [field.name, field]))

  const [commit] = useNiceMutation<ShipmentEditMutation>(mutation)

  const initialValue = {
    ...data.infoUpdateShipmentForm.initialValue,
    linkedContacts: linkedContactToInputHash(data.infoUpdateShipmentForm.initialValue?.linkedContacts?.nodes),
  }

  return (
    <>
      <NiceFormik
        initialValues={initialValue || {}}
        onSubmit={(values, actions) => {
          const processedValues = {
            ...values,
            linkedContacts: inputHashToLinkedContacts((values as any).linkedContacts),
          }
          commit({
            variables: {
              input: processedValues as any,
            },
            onError: () => actions.setSubmitting(false),
            onCompleted: (res, errors) => {
              actions.setSubmitting(false)
              if (!errors) {
                onClose()
              }
            },
          })
        }}
        formSchema={fields}
      >
        {({ submitForm, values }) => (
          <FormContainer>
            <ShipmentForm mode='edit' fieldMaster={fieldMaster} />
            <FormActionBar title={`Editing shipment ${(values as any).shipmentKey}`} onCancel={() => onClose()} onSubmit={() => submitForm()} />
          </FormContainer>
        )}
      </NiceFormik>
    </>
  )
}

const query = graphql`
  query ShipmentEditFormQuery($slug: String!) {
    infoUpdateShipmentForm(slug: $slug) {
      formSchema {
        fieldSet {
          name
          type
          required
          title
          description
          format
          enum
          enumTitles
          placeholder
        }
      }
      initialValue {
        slug
        shipmentKey
        containerSlug
        profileImageSlug
        shipmentType
        vin
        description
        parentShipment

        dischargeDate
        shipperReleaseCheck
        finalReleaseCheck
        runs
        atbPosition
        customsType
        customsReference

        vinEngine
        vinFuelType
        vinBodyClass
        vinOrigin

        linkedContacts {
          nodes {
            role
            contactSlug
          }
        }
      }
    }
  }
`

const mutation = graphql`
  mutation ShipmentEditMutation($input: infoUpdateShipmentInput!) {
    infoUpdateShipment(input: $input) {
      shipment {
        slug
      }
    }
  }
`

ShipmentEdit.Skeleton = () => (
  <>
    <ShipmentForm.Skeleton />
    <FormActionBar.Skeleton />
  </>
)

export default ShipmentEdit
