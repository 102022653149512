import { Typography } from '@material-ui/core'
import { graphql } from 'babel-plugin-relay/macro'
import React from 'react'
import { useFragment } from 'react-relay/hooks'
import styled from 'styled-components'
import { ItemImage_data$key } from '../__generated__/ItemImage_data.graphql'

type ImageVariant = 'small' | 'extraSmall' | null

interface Props {
  selected?: boolean
  data: ItemImage_data$key
  variant?: ImageVariant
}

interface RootProps {
  selected: boolean
  url: string
  shape: string
  $size?: ImageVariant
}

const Root = styled.div<RootProps>`
  min-width: ${(props) => (props.$size === 'extraSmall' ? '50px' : props.$size === 'small' ? '100px' : '200px')};
  width: ${(props) => (props.$size === 'extraSmall' ? '50px' : props.$size === 'small' ? '100px' : '200px')};
  height: ${(props) => (props.$size === 'extraSmall' ? '50px' : props.$size === 'small' ? '100px' : '200px')};
  margin-right: 16px;
  background-image: url('${(props) => props?.url}');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: ${(props) => (props.shape === 'circle' || props.shape === 'shipment' ? '50%' : '8px')};
  align-items: ${(props) => (props.shape === 'circle' || props.shape === 'shipment' ? 'center' : 'start')};
  padding: ${(props) => (props.shape === 'circle' || props.shape === 'shipment' ? '10px' : '0px')};

  position: relative;
  flex-direction: ${(props) => (props.shape === 'circle' || props.shape === 'shipment' ? 'column-reverse' : 'column')};
  display: flex;
  justify-content: space-between;
  transition-duration: 100ms;

  &:before {
    content: '';
    transition-duration: 100ms;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    opacity: 0;
  }

  &:after {
    content: '✓';
    width: 100%;
    height: 100%;
    opacity: ${(props) => (props.selected ? 1 : 0)};
    visibility: ${(props) => (props.selected ? 'visible' : 'hidden')};

    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: ${(props) => (props.$size === 'extraSmall' ? '32px' : '72px')};
    background-color: #448aff;
    border-radius: ${(props) => (props.shape === 'circle' || props.shape === 'shipment' ? '50%' : '8px')};
  }
`

interface OverlayProps {
  $size?: ImageVariant
}

const Overlay = styled(Typography)<OverlayProps>`
  background: rgba(0, 0, 0, 0.35);
  font-size: ${(props) => (props.$size === 'extraSmall' ? '5px' : props.$size === 'small' ? '10px' : '20px')};
  font-weight: ${(props) => (props.$size === 'extraSmall' ? '700' : props.$size === 'small' ? '900' : '700')};
  position: relative;
  width: 100%;
  backdrop-filter: blur(8px);
  color: #eaeaea;
  flex: 1;
  padding: ${(props) => (props.$size === 'extraSmall' ? '4pz' : props.$size === 'small' ? '8px' : '16px')};
  text-align: center;
  text-transform: uppercase;
  height: 30%;
  max-height: ${(props) => (props.$size === 'extraSmall' ? '15px' : props.$size === 'small' ? '30px' : '60px')};

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${(props) => (props.$size === 'extraSmall' ? '1' : props.$size === 'small' ? '1' : '3')};
  overflow: hidden;
  > p {
    margin: 0;
    padding: 0;
  }
`

interface HighlightProps {
  $size?: ImageVariant
  shape: string
}

const Highlight = styled(Typography)<HighlightProps>`
  background: rgba(244, 241, 214, 0.92);
  border-radius: ${(props) => (props.shape === 'circle' || props.shape === 'shipment' ? '8px' : '8px 0 0 0')};
  padding: 0px 16px 0px 8px;
  width: fit-content;
  height: 15%;
  font-size: ${(props) => (props.$size === 'extraSmall' ? '4px' : props.$size === 'small' ? '7px' : '14px')};
  max-height: ${(props) => (props.$size === 'extraSmall' ? '6px' : props.$size === 'small' ? '12px' : '24px')};
  flex: 1;
  position: relative;
  backdrop-filter: blur(8px);
  color: #111111;
  text-align: left;
  overflow: hidden;
`
interface BlankProps {
  size?: ImageVariant
}

const Blank = styled.div<BlankProps>`
  background: transparent;
  flex: 1;
  height: 15%;
  max-height: ${(props) => (props.size !== 'small' ? '30px' : '15px')};
`

const ItemImage: React.FC<Props> = ({ selected = false, data, variant }) => {
  const image = useFragment(imageFragment, data)
  const size = variant === 'small' ? 'small' : variant === 'extraSmall' ? 'extraSmall' : null

  return (
    <Root selected={selected} url={image?.url as string} shape={image.shape || 'circle'} $size={size}>
      {image.highlight ? (
        <Highlight variant={'caption'} shape={image.shape || 'circle'} $size={size}>
          {image.highlight}
        </Highlight>
      ) : (
        <Blank size={variant} />
      )}
      {image.overlay ? (
        <Overlay variant={'caption'} $size={size}>
          {image.overlay?.split('\n').map((r) => (
            <p key={r}>{r}</p>
          ))}
        </Overlay>
      ) : (
        <Blank size={variant} />
      )}
      <Blank size={variant} />
    </Root>
  )
}

const imageFragment = graphql`
  fragment ItemImage_data on Image {
    url
    alt
    highlight
    shape
    overlay
    overlayIcon
  }
`

export default ItemImage
