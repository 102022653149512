/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type FolderRoleVisibilityQueryVariables = {
    slug: string;
};
export type FolderRoleVisibilityQueryResponse = {
    readonly folderShow: {
        readonly roleVisibilities: ReadonlyArray<{
            readonly role: string;
            readonly roleShortTitle: string;
            readonly roleTitle: string;
            readonly visible: boolean;
        }>;
    };
};
export type FolderRoleVisibilityQuery = {
    readonly response: FolderRoleVisibilityQueryResponse;
    readonly variables: FolderRoleVisibilityQueryVariables;
};



/*
query FolderRoleVisibilityQuery(
  $slug: String!
) {
  folderShow(slug: $slug) {
    roleVisibilities {
      role
      roleShortTitle
      roleTitle
      visible
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "RoleVisibility",
  "kind": "LinkedField",
  "name": "roleVisibilities",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "role",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "roleShortTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "roleTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "visible",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FolderRoleVisibilityQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Folder",
        "kind": "LinkedField",
        "name": "folderShow",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FolderRoleVisibilityQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Folder",
        "kind": "LinkedField",
        "name": "folderShow",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d899e60c1db0823ae02a511587f64e94",
    "id": null,
    "metadata": {},
    "name": "FolderRoleVisibilityQuery",
    "operationKind": "query",
    "text": "query FolderRoleVisibilityQuery(\n  $slug: String!\n) {\n  folderShow(slug: $slug) {\n    roleVisibilities {\n      role\n      roleShortTitle\n      roleTitle\n      visible\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '0cf8b82c20a33495a5c78ecc3538964f';
export default node;
