import React from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import { useFragment } from 'react-relay/hooks'
import { ContactLinkCard_data$key } from '../../__generated__/ContactLinkCard_data.graphql'
import styled from 'styled-components'
import { Avatar, Typography } from '@material-ui/core'
import SemanticString from '../SemanticString'

interface Props {
  data: ContactLinkCard_data$key
}

const Root = styled.div`
  text-decoration: unset;
  color: unset;
  border: 1px solid ${(props) => props.theme.customPalette.border.card};
  border-radius: 4px;
  display: flex;
  align-items: center;
  overflow: hidden;

  &:hover {
    border-color: ${(props) => props.theme.palette.primary.main};
  }
`

const Meta = styled.div`
  padding: 8px;
  height: 100%;
  white-space: pre;
`

const TitleContainer = styled.div`
  padding: 8px 8px 8px 0;
  white-space: pre;
  overflow: hidden;
  background-color: ${(props) => props.theme.palette.background.paper};
  flex: 1;
`

const NonOverflowText = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
`

const TitleText = styled(NonOverflowText)`
  line-height: 1;
`

const LinkAvatar = styled(Avatar)`
  width: 40px;
  height: 40px;
`

const ContactLinkCard: React.FC<Props> = ({ data }) => {
  const link = useFragment(linkFragment, data)
  return (
    <Root>
      <Meta>{link.imageUrl && <LinkAvatar alt={link.type || ''} src={link.imageUrl} />}</Meta>
      <TitleContainer>
        {link.title && (
          <TitleText variant='body1'>
            <SemanticString data={link.title} />
          </TitleText>
        )}
        {link.meta && (
          <NonOverflowText variant='caption' color='textSecondary'>
            <SemanticString data={link.meta} />
          </NonOverflowText>
        )}
      </TitleContainer>
    </Root>
  )
}

const linkFragment = graphql`
  fragment ContactLinkCard_data on Link {
    type
    title {
      ...SemanticString_data
    }
    meta {
      ...SemanticString_data
    }
    imageUrl
  }
`

export default ContactLinkCard
