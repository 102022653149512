import React, { useContext } from 'react'
import { TicketShowWithParams } from '../pages/Ticket/TicketShow'
import { SlideInDrawer } from './SlideInDrawer'
import styled from 'styled-components'
import { ExternalTicketShowWithParams } from '../pages/ExternalTicket/ExternalTicketShow'
import SessionContext from '../SessionContext'

interface Props {
  slug: string
  open: boolean
  onClose: () => void
}

const ShowContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 16px;
`

const LiveRequestDrawer: React.FC<Props> = ({ slug, open, onClose }) => {
  const { user } = useContext(SessionContext)
  return (
    <SlideInDrawer size='default' anchor='right' open={open} elevation={0} onClose={onClose}>
      <ShowContainer>
        {user?.contact?.contactType === 'internal' && <TicketShowWithParams slug={slug} renderContext='drawer' />}
        {user?.contact?.contactType === 'external' && <ExternalTicketShowWithParams slug={slug} renderContext='drawer' />}
      </ShowContainer>
    </SlideInDrawer>
  )
}

export default LiveRequestDrawer
