/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import { Checkbox as MuiCheckbox } from '@material-ui/core'
import DateRangePicker from 'rsuite/DateRangePicker'

interface Props {
  filterType: string
  heading: string
  handleDateChange(filterType: string, newDateRange: string[]): any
  dateRange: string[]
}

const DateRangePickerFilter: React.FC<Props> = ({ filterType, handleDateChange, heading, dateRange }) => {
  return (
    <>
      <DateRangePicker
        placeholder={heading}
        format='dd/MM/yyyy'
        value={dateRange}
        onChange={(e) => {
          handleDateChange(filterType, e)
        }}
        style={{ margin: 5, width: 225 }}
      />
      {false && <MuiCheckbox color='primary' checked={true} />}
    </>
  )
}

export default DateRangePickerFilter
