/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LiveShareShipmentShowHeroCardQuery_shipment = {
    readonly slug: string;
    readonly shipmentKey: string;
    readonly description: string;
    readonly shipmentType: string;
    readonly vin: string | null;
    readonly weight: number | null;
    readonly containerNumber: string;
    readonly workflowStages: ReadonlyArray<{
        readonly value: string;
        readonly " $fragmentRefs": FragmentRefs<"Stage_stage">;
    }>;
    readonly " $refType": "LiveShareShipmentShowHeroCardQuery_shipment";
};
export type LiveShareShipmentShowHeroCardQuery_shipment$data = LiveShareShipmentShowHeroCardQuery_shipment;
export type LiveShareShipmentShowHeroCardQuery_shipment$key = {
    readonly " $data"?: LiveShareShipmentShowHeroCardQuery_shipment$data;
    readonly " $fragmentRefs": FragmentRefs<"LiveShareShipmentShowHeroCardQuery_shipment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LiveShareShipmentShowHeroCardQuery_shipment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shipmentKey",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shipmentType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vin",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "weight",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "containerNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "WorkflowStage",
      "kind": "LinkedField",
      "name": "workflowStages",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Stage_stage"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "LiveShareShipment",
  "abstractKey": null
};
(node as any).hash = '4d3e33101cc8d7313ff7cfd4797c78c7';
export default node;
