import { Typography, CircularProgress } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { graphql } from 'babel-plugin-relay/macro'
import { useField } from 'formik'
import React, { DragEventHandler, useState, useRef, ChangeEvent } from 'react'
import { useLazyLoadQuery } from 'react-relay/hooks'
import styled from 'styled-components'
import { FileInputFieldQuery } from '../../__generated__/FileInputFieldQuery.graphql'
import { FieldT, FieldVariantEnum } from './types'
import Button from '../Button'
import useNiceMutation from '../../mutations/useNiceMutation'
import { FileInputFieldUploadMutation } from '../../__generated__/FileInputFieldUploadMutation.graphql'
import { previewUrlForContentType } from '../File/helpers'

interface Props {
  field: FieldT
  variant?: FieldVariantEnum
}

const FileInput = styled.input`
  display: none;
`

const DropArea = styled.div<{ $dropHover: boolean }>`
  padding: 32px;
  text-align: center;
  border: 2px dashed ${(props) => (props.$dropHover ? props.theme.palette.primary.main : props.theme.palette.grey[300])};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const RemoveText = styled(Typography)`
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`

const PreviewImage = styled.img`
  max-height: 100px;
  max-width: 100px;
`

const FileInputField: React.FC<Props> & { Skeleton: React.FC } = ({ field }) => {
  const [, meta, helpers] = useField(field.name)
  const data = useLazyLoadQuery<FileInputFieldQuery>(query, { slug: meta.value })

  const [commit, uploadIsInFlight] = useNiceMutation<FileInputFieldUploadMutation>(mutation)
  const [dropHover, setDropHover] = useState<boolean>(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const onUpload = (file: File) => {
    commit({
      variables: {
        input: {
          caption: file.name,
        },
      },
      uploadables: {
        file: file,
      },
      onCompleted: (res, errors) => {
        if (!errors) {
          helpers.setValue(res.infoUploadAttachment?.attachmentSlug || null)
        }
      },
    })
  }

  const openFileDialog = (): void => {
    if (!inputRef.current) return
    inputRef.current.click()
  }

  const onFileInputChange = (evt: ChangeEvent<HTMLInputElement>) => setFileData(evt.target.files ? (evt.target.files[0] as File) : null)

  const setFileData = (file: File | null): void => {
    if (!file) {
      return
    }
    onUpload(file)
  }

  const onDragOver: DragEventHandler = (evt) => {
    evt.preventDefault()
    setDropHover(true)
  }

  const onDragLeave = () => setDropHover(false)

  const onDrop: DragEventHandler = (evt) => {
    evt.preventDefault()
    setFileData(evt.dataTransfer ? (evt.dataTransfer.files[0] as File) : null)
    setDropHover(false)
  }

  return (
    <DropArea $dropHover={dropHover} onDragOver={onDragOver} onDragLeave={onDragLeave} onDrop={onDrop}>
      {uploadIsInFlight ? (
        <CircularProgress />
      ) : meta.value ? (
        <>
          <PreviewImage
            src={data.attachmentQuery?.previewUrl || previewUrlForContentType(data.attachmentQuery?.contentType)}
            alt={data.attachmentQuery?.name || ''}
          />
          <Typography variant='body1'>{data.attachmentQuery?.name}</Typography>
          <RemoveText variant='caption' color='textSecondary' onClick={() => helpers.setValue(null)}>
            Remove
          </RemoveText>
        </>
      ) : (
        <>
          <Typography variant='body1'>Drag a file here</Typography>
          <Typography variant='subtitle2'>or</Typography>
          <Button color='primary' onClick={openFileDialog}>
            Select a file from your devise
          </Button>
          <FileInput ref={inputRef} type='file' onChange={onFileInputChange} value='' />
        </>
      )}
    </DropArea>
  )
}

FileInputField.Skeleton = () => (
  <div>
    <Skeleton variant='rect' width='100%' height='150px' />
  </div>
)

const query = graphql`
  query FileInputFieldQuery($slug: String) {
    attachmentQuery(slug: $slug) {
      url
      name
      previewUrl
      contentType
    }
  }
`

const mutation = graphql`
  mutation FileInputFieldUploadMutation($input: infoUploadAttachmentInput!) {
    infoUploadAttachment(input: $input) {
      attachmentSlug
    }
  }
`

export default FileInputField
