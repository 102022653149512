/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type infoAddContactInput = {
    clientMutationId?: string | null;
    slug: string;
    contactType: string;
    format: string;
    salutation: string;
    imageSlug?: string | null;
    firstName: string;
    lastName: string;
    email?: string | null;
    secondaryEmails?: Array<string> | null;
    businessPhone?: string | null;
    mobilePhone?: string | null;
    privatePhone?: string | null;
    faxPhone?: string | null;
    zollContactNumber?: string | null;
    zollPhone?: string | null;
    atbForCustoms?: boolean | null;
    company?: string | null;
    position?: string | null;
    country: string;
    state?: string | null;
    place?: string | null;
    postcode?: string | null;
    address?: string | null;
    language?: string | null;
    lexOfficeId?: string | null;
    eori?: string | null;
    vat?: string | null;
    vatWarehousing?: boolean | null;
    vatRevenues?: boolean | null;
    website?: string | null;
    roles: Array<string>;
    rateCardSlug?: string | null;
    freeWarehousingDays?: number | null;
    costWarehousingPerDay?: number | null;
    costShipmentDischarge?: number | null;
    customsOffice?: string | null;
    syncWithEmory?: boolean | null;
    blocked?: boolean | null;
    preRegistered?: boolean | null;
};
export type ContactAddMutationVariables = {
    input: infoAddContactInput;
};
export type ContactAddMutationResponse = {
    readonly infoAddContact: {
        readonly contact: {
            readonly slug: string;
        };
    } | null;
};
export type ContactAddMutation = {
    readonly response: ContactAddMutationResponse;
    readonly variables: ContactAddMutationVariables;
};



/*
mutation ContactAddMutation(
  $input: infoAddContactInput!
) {
  infoAddContact(input: $input) {
    contact {
      slug
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ContactAddMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "infoAddContactPayload",
        "kind": "LinkedField",
        "name": "infoAddContact",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Contact",
            "kind": "LinkedField",
            "name": "contact",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ApplicationCommand",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ContactAddMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "infoAddContactPayload",
        "kind": "LinkedField",
        "name": "infoAddContact",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Contact",
            "kind": "LinkedField",
            "name": "contact",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a171da32b7a4df888fc61065b3ad9799",
    "id": null,
    "metadata": {},
    "name": "ContactAddMutation",
    "operationKind": "mutation",
    "text": "mutation ContactAddMutation(\n  $input: infoAddContactInput!\n) {\n  infoAddContact(input: $input) {\n    contact {\n      slug\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '9e882b6c51535dd06e3bf6343a60803c';
export default node;
