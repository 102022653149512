import { OperationType, GraphQLTaggedNode, fetchQuery, getRequest } from 'relay-runtime'
import useWorkspace from './useWorkspace'
import { useEffect, useContext } from 'react'
import ActionCableContext from '../ActionCableContext'
import { useRelayEnvironment } from 'react-relay/hooks'

export type SubscribableEntityT = 'shipments' | 'containers' | 'contacts' | 'voyages' | 'vessels' | 'tickets'

export function useEntitySubscription<TQuery extends OperationType>({
  entityType,
  entitySlug,
  query,
  variables,
}: {
  entityType: SubscribableEntityT
  entitySlug: string
  query: GraphQLTaggedNode
  variables: TQuery['variables']
}) {
  const { cable } = useContext(ActionCableContext)
  const environment = useRelayEnvironment()
  const [workspace] = useWorkspace()
  const stringifiedVariables = JSON.stringify(variables)
  useEffect(() => {
    const channel = cable?.subscriptions.create(
      {
        channel: 'EntityChannel',
        workspace: workspace,
        entity_type: entityType,
        entity_slug: entitySlug,
        refetchOperationName: getRequest(query).operation.name, // for debugging purposes
      },
      {
        received: () => fetchQuery(environment, query, variables),
      }
    )

    return () => channel?.unsubscribe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityType, entitySlug, workspace, environment, query, stringifiedVariables, cable])
}

export function useEntitySubscriptionWithCallback({
  entityType,
  entitySlug,
  onUpdate,
}: {
  entityType: SubscribableEntityT
  entitySlug: string
  onUpdate: () => void
}) {
  const { cable } = useContext(ActionCableContext)
  const [workspace] = useWorkspace()
  useEffect(() => {
    const channel = cable?.subscriptions.create(
      {
        channel: 'EntityChannel',
        workspace: workspace,
        entity_type: entityType,
        entity_slug: entitySlug,
      },
      {
        received: () => onUpdate(),
      }
    )

    return () => channel?.unsubscribe()
  }, [cable, workspace, entityType, entitySlug, onUpdate])
}
