/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type SettingTypeEnum = "config_settings" | "customs_types" | "default_folders" | "document_templates" | "document_types" | "email_templates" | "languages" | "miscellaneous_settings" | "ports" | "rate_cards" | "roles" | "shipment_types" | "shipping_lines" | "%future added value";
export type PortFieldQueryVariables = {
    type: SettingTypeEnum;
};
export type PortFieldQueryResponse = {
    readonly settingList: {
        readonly items: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly slug: string;
                    readonly title: string;
                } | null;
            } | null> | null;
        };
    };
};
export type PortFieldQuery = {
    readonly response: PortFieldQueryResponse;
    readonly variables: PortFieldQueryVariables;
};



/*
query PortFieldQuery(
  $type: SettingTypeEnum!
) {
  settingList(type: $type) {
    items {
      edges {
        node {
          slug
          title
          id
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "type"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "type",
    "variableName": "type"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PortFieldQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SettingsList",
        "kind": "LinkedField",
        "name": "settingList",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SettingsItemConnection",
            "kind": "LinkedField",
            "name": "items",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SettingsItemEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SettingsItem",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PortFieldQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SettingsList",
        "kind": "LinkedField",
        "name": "settingList",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SettingsItemConnection",
            "kind": "LinkedField",
            "name": "items",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SettingsItemEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SettingsItem",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "18101347e2051ff7321abe49735c5979",
    "id": null,
    "metadata": {},
    "name": "PortFieldQuery",
    "operationKind": "query",
    "text": "query PortFieldQuery(\n  $type: SettingTypeEnum!\n) {\n  settingList(type: $type) {\n    items {\n      edges {\n        node {\n          slug\n          title\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '815f0ff94910459510993bdbb02c95aa';
export default node;
