export const tabs = [
  { label: 'Home', value: 'home' },
  { label: 'Files', value: 'files' },
  { label: 'Contacts', value: 'contacts' },
  { label: 'Emails', value: 'emails' },
  { label: 'Shares', value: 'shares' },
  { label: 'Requests', value: 'requests' },
  { label: 'Finances', value: 'finances' },
  { label: 'Notes', value: 'notes' },
]
