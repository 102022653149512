import React from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import { useFragment } from 'react-relay/hooks'
import { VoyageLinkCard_data$key } from '../../__generated__/VoyageLinkCard_data.graphql'
import styled from 'styled-components'
import { Typography } from '@material-ui/core'
import SemanticString from '../SemanticString'

interface Props {
  data: VoyageLinkCard_data$key
  voyageKey?: string | null
}

const Root = styled.div`
  border: 1px solid ${(props) => props.theme.customPalette.border.card};
  border-radius: 4px;
  display: flex;
  align-items: center;
  overflow: hidden;

  &:hover {
    border-color: ${(props) => props.theme.palette.primary.main};
  }
`

const Meta = styled.div`
  padding: 8px;
  height: 100%;
  background-color: ${(props) => props.theme.palette.grey[200]};
  white-space: pre;
`

const TitleContainer = styled.div`
  padding: 8px;
  white-space: pre;
  overflow: hidden;
  background-color: ${(props) => props.theme.palette.background.paper};
  flex: 1;
`

const TitleText = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
`

const VoyageLinkCard: React.FC<Props> = ({ data, voyageKey = null }) => {
  const link = useFragment(linkFragment, data)
  let metaTag
  if (voyageKey === 'etd') {
    metaTag = <Typography variant='body1'>{link.voyageEtd ? <SemanticString data={link.voyageEtd} /> : '--'}</Typography>
  } else {
    metaTag = <Typography variant='body1'>{link.meta ? <SemanticString data={link.meta} /> : '--'}</Typography>
  }

  return (
    <Root>
      <Meta>{metaTag}</Meta>
      <TitleContainer>
        <TitleText variant='body1'>{link.title && <SemanticString data={link.title} />}</TitleText>
      </TitleContainer>
    </Root>
  )
}

const linkFragment = graphql`
  fragment VoyageLinkCard_data on Link {
    type
    title {
      ...SemanticString_data
    }
    meta {
      ...SemanticString_data
    }
    voyageEta {
      ...SemanticString_data
    }
    voyageEtd {
      ...SemanticString_data
    }
  }
`

export default VoyageLinkCard
