import { DialogContent, DialogTitle, DialogActions, ListItem } from '@material-ui/core'
import React, { Suspense, useState } from 'react'
import OdysseyDialog from '../OdysseyDialog'
import List from '@material-ui/core/List'
import { Skeleton } from '@material-ui/lab'
import Button from '../Button'
import StyledInput from '../StyledInput'
import { graphql } from 'babel-plugin-relay/macro'
import { useLazyLoadQuery } from 'react-relay/hooks'
import useNiceMutation from '../../mutations/useNiceMutation'
import { ContainerReleaseRequestMutation } from '../../__generated__/ContainerReleaseRequestMutation.graphql'
import LoadingDots from '../LoadingDots'
import { inputHashToLinkedContacts, linkedContactToInputHash } from '../Form/LinkedContactsField'
import { ContainerReleaseRequestFormQuery } from '../../__generated__/ContainerReleaseRequestFormQuery.graphql'
import { ContainerReleaseRequestStageChangeMutation } from '../../__generated__/ContainerReleaseRequestStageChangeMutation.graphql'

interface Props {
  open: boolean
  slug: string
  onClose: () => void
  onSubmit?: (values: any) => void
}
const ContainerReleaseRequest: React.FC<Props> = (props) => {
  return (
    <>
      <OdysseyDialog open={props.open} onClose={props.onClose}>
        <DialogTitle></DialogTitle>
        <Suspense fallback={<ContainerReleaseRequestSkeleton {...props} />}>
          <ContainerReleaseRequestContent {...props}></ContainerReleaseRequestContent>
        </Suspense>
      </OdysseyDialog>
    </>
  )
}

const ContainerReleaseRequestContent: React.FC<Props> = ({ onSubmit, onClose, slug }) => {
  const data = useLazyLoadQuery<ContainerReleaseRequestFormQuery>(query, { slug: slug })
  const values = data.infoUpdateContainerForm.initialValue

  const initialValue = {
    ...data.infoUpdateContainerForm.initialValue,
    linkedContacts: linkedContactToInputHash(data.infoUpdateContainerForm.initialValue?.linkedContacts?.nodes),
  }
  const [paidDate, setPaidDate] = useState(values?.thcPaidDate as string)
  const [bookingNumber, setBookingNumber] = useState<string>(values?.bookingNumber as string)
  const [commit, loading] = useNiceMutation<ContainerReleaseRequestMutation>(mutation)
  const [commitStageChange] = useNiceMutation<ContainerReleaseRequestStageChangeMutation>(changeStageMutation)

  const handleCommit = () => {
    if (onSubmit) {
      onSubmit({
        ...initialValue,
        thcPaidDate: paidDate,
        bookingNumber: bookingNumber,
        linkedContacts: inputHashToLinkedContacts((initialValue as any).linkedContacts),
      })
      return
    }
    commit({
      variables: {
        input: {
          ...initialValue,
          thcPaidDate: paidDate,
          bookingNumber: bookingNumber,
          linkedContacts: inputHashToLinkedContacts((initialValue as any).linkedContacts),
        } as any,
      },
      onCompleted: (res, errors) => {
        if (!errors) {
          handleStageChange()
        }
      },
    })
  }

  const handleStageChange = () => {
    commitStageChange({
      variables: {
        input: {
          slug: slug,
          stage: 'release_requested',
        },
      },
      onCompleted: (res, errors) => {
        if (!errors) {
          onClose()
        }
      },
    })
  }
  return (
    <>
      <DialogContent>
        <div>
          <label htmlFor='paid_date'>Thc paid date</label>&nbsp;&nbsp;
          <StyledInput
            type='date'
            id='paid_date'
            value={paidDate}
            onChange={(e) => {
              setPaidDate(e.target.value)
            }}
          ></StyledInput>
        </div>
        <div>
          <label htmlFor='booking_number'>Booking Number</label>&nbsp;&nbsp;
          <StyledInput
            type='text'
            id='booking_number'
            value={bookingNumber}
            onChange={(e) => {
              setBookingNumber(e.target.value)
            }}
          ></StyledInput>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          onClick={() => {
            onClose()
          }}
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          onClick={() => {
            handleCommit()
          }}
        >
          {loading ? <LoadingDots /> : 'Save'}
        </Button>
      </DialogActions>
    </>
  )
}

const ContainerReleaseRequestSkeleton: React.FC<Props> = () => (
  <>
    <DialogContent>
      <List>
        <ListItem>
          <Skeleton variant='rect' height='20px' width='100%' />
        </ListItem>
        <ListItem>
          <Skeleton variant='rect' height='20px' width='100%' />
        </ListItem>
      </List>
    </DialogContent>
  </>
)

const mutation = graphql`
  mutation ContainerReleaseRequestMutation($input: infoUpdateContainerInput!) {
    infoUpdateContainer(input: $input) {
      clientMutationId
    }
  }
`

const query = graphql`
  query ContainerReleaseRequestFormQuery($slug: String!) {
    infoUpdateContainerForm(slug: $slug) {
      initialValue {
        slug
        containerNumber
        voyageSlug
        containerType
        bookingNumber
        thcPaidDate
        shippingLineSlug
        linkedContacts {
          nodes {
            role
            contactSlug
          }
        }
      }
    }
  }
`
const changeStageMutation = graphql`
  mutation ContainerReleaseRequestStageChangeMutation($input: infoChangeContainerStageInput!) {
    infoChangeContainerStage(input: $input) {
      clientMutationId
    }
  }
`

export default ContainerReleaseRequest
