import { Button, DialogActions, DialogTitle } from '@material-ui/core'
import React from 'react'
import OdysseyDialog from './OdysseyDialog'

interface Props {
  open: boolean
  title: string
  onClose: () => void
  onConfirm: () => void
}

export const ConfirmBox: React.FC<Props> = ({ open, onClose, title, onConfirm }) => {
  return (
    <>
      <OdysseyDialog
        fullWidth
        open={open}
        maxWidth='xs'
        onClose={() => {
          onClose()
        }}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogActions>
          <Button variant='text' color='primary' onClick={() => onClose()}>
            No
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              onConfirm()
              onClose()
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </OdysseyDialog>
    </>
  )
}

export default ConfirmBox
