import React, { useEffect, useRef, useState } from 'react'
import { Typography, TextField } from '@material-ui/core'
import { fetchQuery, useRelayEnvironment } from 'react-relay/hooks'
import { graphql } from 'babel-plugin-relay/macro'
import ItemImage from './ItemImage'
import { TitleRow, TitleContainer, TitleLink } from './ShowPageListItem'
import SemanticLine from './SemanticLine'
import ShowPageInDrawer from './ShowPageInDrawer'
import styled from 'styled-components'
import WorkspacedLink from './WorkspacedLink'
import { SlideInDrawer } from './SlideInDrawer'
import { SearchShipmentsQuery } from '../__generated__/SearchShipmentsQuery.graphql'
import Button from './Button'
import useNiceMutation from '../mutations/useNiceMutation'
import { SearchShipmentsMutation } from '../__generated__/SearchShipmentsMutation.graphql'
import { Alert } from '@material-ui/lab'
import useWorkspace from '../hooks/useWorkspace'
import { useNavigate } from 'react-router'
import { useSnackbar } from 'notistack'

interface Props {
  open: boolean
  onClose: () => void
  selectFile: string
}

const RootWorkSpacedLink = styled(WorkspacedLink)`
  flex: 1;
  position: relative;
  background-color: ${(props) => props.theme.palette.background.paper};

  &:hover {
    filter: brightness(0.95);
  }
`

const SearchField = styled(TextField)`
  width: 70%;
  margin: 10px;
`
const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`

const ItemContainer = styled(FlexContainer)`
  padding: 0 20px;
`

const Topbar = styled(FlexContainer)``

const RootContainer = styled(FlexContainer)`
  padding: 8px;
  align-items: flex-start;
  background-color: ${(props) => props.theme.palette.background.paper};
  transition-duration: 300ms;
`

const TitleText = styled(Typography)`
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
`

const SearchShipments: React.FC<Props> = ({ open, onClose, selectFile }) => {
  const [searchQuery, setSearchQuery] = useState('')
  const searchQueryRef = useRef(searchQuery)
  const textInput = useRef<any>()
  const environment = useRelayEnvironment()
  const [options, setOptions] = useState<any[]>([])
  const [shipmentPreview, setShipmentPreview] = useState(false)
  const [shipment, setShipment] = useState('')
  const [commit, , mutationError] = useNiceMutation<SearchShipmentsMutation>(assign)
  const [workspace] = useWorkspace()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const handldeAssign = (shipmentSlug: string) => {
    commit({
      variables: { input: { slug: selectFile, shipmentSlug: shipmentSlug } },
      onCompleted: (_, errors) => {
        if (!errors) {
          onClose()
          setSearchQuery('')
          enqueueSnackbar(`Successfully linked to an shipment: ${shipmentSlug}`, { variant: 'success' })
          navigate(`/${workspace}/admin/tax-assessments/linked`)
        }
      },
    })
  }
  const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (e.ctrlKey) return

    e.preventDefault()
    setShipmentPreview(true)
  }

  useEffect(() => {
    fetchQuery<SearchShipmentsQuery>(environment, query, {
      type: 'shipments',
      searchQuery: searchQuery,
      perPage: 50,
      requiredPagination: true,
    })
      .toPromise()
      .then((data) => data?.itemList.items.nodes || [])
      .then((opts) => setOptions(opts as never[]))
  }, [searchQuery, environment])

  const onQueryChange = (newQuery: string) => {
    searchQueryRef.current = newQuery
    setTimeout(() => {
      if (searchQueryRef.current === newQuery) {
        setSearchQuery(newQuery)
      }
    }, 300)
  }
  return (
    <SlideInDrawer
      size='small'
      elevation={0}
      anchor='right'
      open={open}
      onClose={() => {
        onClose()
        setSearchQuery('')
      }}
    >
      <Topbar>
        <SearchField variant='outlined' inputRef={textInput} label='Search' onChange={(event) => onQueryChange(event.target.value)} />
        {mutationError && <Alert severity='error'>{mutationError}</Alert>}
      </Topbar>
      {options &&
        options.map((item) => {
          return (
            <>
              <ItemContainer>
                <RootWorkSpacedLink
                  to={`/${item.itemType}/${item.slug}`}
                  onClick={(e) => {
                    handleLinkClick(e)
                    setShipment(item.slug)
                  }}
                >
                  <RootContainer key={item.slug}>
                    {item.image && <ItemImage variant='extraSmall' data={item.image} />}
                    <TitleRow title={item.title}>
                      <TitleContainer>
                        <TitleLink>
                          <TitleText variant='caption' color='textPrimary'>
                            {item.title}
                          </TitleText>
                          {item.lines?.map((line: any) => (
                            <SemanticLine key={line.key} data={line} />
                          ))}
                        </TitleLink>
                      </TitleContainer>
                    </TitleRow>
                  </RootContainer>
                </RootWorkSpacedLink>
                <Button onClick={() => handldeAssign(item.slug)}>Assign</Button>
              </ItemContainer>
            </>
          )
        })}
      <ShowPageInDrawer
        entityType='shipment'
        entitySlug={shipment}
        open={shipmentPreview}
        onClose={() => {
          setShipmentPreview(false)
          setShipment('')
        }}
      />
    </SlideInDrawer>
  )
}

const query = graphql`
  query SearchShipmentsQuery($type: ListItemTypeEnum!, $searchQuery: String, $perPage: Int, $requiredPagination: Boolean) {
    itemList(type: $type, searchQuery: $searchQuery, perPage: $perPage, requiredPagination: $requiredPagination) {
      items {
        nodes {
          slug
          title
          itemType
          lines {
            ...SemanticLine_data
          }
          image {
            ...ItemImage_data
          }
        }
      }
    }
  }
`
const assign = graphql`
  mutation SearchShipmentsMutation($input: infoLinkedTaxAssessmentToShipmentInput!) {
    infoLinkedTaxAssessmentToShipment(input: $input) {
      clientMutationId
    }
  }
`
export default SearchShipments
