import React, { useState, FormEvent } from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import styled from 'styled-components'
import Button from '../../components/Button'
import { useFragment, useMutation } from 'react-relay/hooks'
import { ShipmentFinanceActionCardMutation } from '../../__generated__/ShipmentFinanceActionCardMutation.graphql'
import { Alert } from '@material-ui/lab'
import LoadingDots from '../LoadingDots'
import EntityAttachment from '../File/EntityAttachment'
import { media } from '../../theme'
import { ViewModeT } from '../../components/File/types'
import { Grid, Paper } from '@material-ui/core'
import { ShipmentFinanceActionCard_shipment$key } from '../../__generated__/ShipmentFinanceActionCard_shipment.graphql'
import SendEmailDialog from '../Email/SendEmailDialog'
import EntityAttachmentListPreviewer from '../File/EntityAttachmentListPreviewer'
import { makeStyles } from '@material-ui/core/styles'

interface Props {
  shipment: ShipmentFinanceActionCard_shipment$key
}

const MetricContainer = styled.div`
  padding: 16px;
`
const RootPaper = styled(Paper)`
  margin-bottom: 16px;
  ${MetricContainer}:first-of-type {
    border-right: 1px solid ${(props) => props.theme.palette.divider};
  }
`

const FilesContainer = styled.div<{ $viewMode: ViewModeT }>`
  margin-top: 20px;
  display: grid;
  grid-template-columns: ${(props) => (props.$viewMode === 'home' ? 'repeat(2, minmax(0, 1fr))' : 'repeat(7, minmax(0, 1fr))')};
  grid-auto-rows: 120px;
  grid-gap: 16px;

  ${media.medium`
    grid-template-columns: repeat(2, minmax(0, 1fr));
  `}

  ${media.small`
    grid-template-columns: repeat(3, minmax(0, 1fr));
  `}
`
const useStyles = makeStyles({
  divider: {
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
  },
})

const ShipmentFinanceActionCard: React.FC<Props> = ({ shipment: data }) => {
  const shipment = useFragment(fragment, data)
  const shipment_slug = shipment.slug
  const lexofficeInvoices = shipment.lexofficeInvoices
  const taxAssessments = shipment.taxAssessments

  const [generateInvoiceCommit, generateInvoiceIsInFlight] = useMutation<ShipmentFinanceActionCardMutation>(generateInvoiceMutation)
  const [mutationError, setMutationError] = useState<null | string>(null)
  const [mutationSuccess, setMutationSuccess] = useState(false)
  const classes = useStyles()

  const handleGenerateInvoice = (e: FormEvent) => {
    e.preventDefault()
    generateInvoiceCommit({
      variables: {
        input: {
          slug: shipment_slug,
        },
      },
      onCompleted: (_, errors) => {
        if (errors) {
          setMutationError(errors.map((err) => err?.message).join(', '))
        } else {
          setMutationSuccess(true)
        }
      },
    })
  }
  const [emailOpen, setEmailOpen] = useState(false)
  const [invoiceAttachmentSlug, setInvoiceAttachmentSlug] = useState<null | string>(null)
  const [taxAttachmentSlug, setTaxAttachmentSlug] = useState<null | string>(null)

  return (
    <RootPaper variant='outlined'>
      <MetricContainer>
        <div>
          {mutationError && <Alert severity='error'>{mutationError}</Alert>}
          {mutationSuccess && <Alert severity='success'>Invoice generated</Alert>}
          <Button variant='outlined' onClick={handleGenerateInvoice} disabled={generateInvoiceIsInFlight}>
            {generateInvoiceIsInFlight ? <LoadingDots variant='dark' /> : 'Generate Invoice'}
          </Button>
          {lexofficeInvoices && lexofficeInvoices.length > 0 && (
            <Button variant='outlined' onClick={() => setEmailOpen(true)}>
              Send E-mail
            </Button>
          )}
          <SendEmailDialog
            open={emailOpen}
            onClose={() => setEmailOpen(false)}
            entityType='shipments'
            entitySlug={shipment_slug}
            initialTemplateSlug='invoice'
          />
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} className={classes.divider}>
            <FilesContainer $viewMode='home'>
              {lexofficeInvoices && (
                <>
                  {lexofficeInvoices.map((invoice, idx) => (
                    <EntityAttachment key={idx} attachment={invoice} onPreview={() => setInvoiceAttachmentSlug(invoice.attachmentSlug)} />
                  ))}
                  <EntityAttachmentListPreviewer
                    attachments={lexofficeInvoices}
                    intialPreviewedAttachmentSlug={invoiceAttachmentSlug}
                    open={!!invoiceAttachmentSlug}
                    onClose={() => setInvoiceAttachmentSlug(null)}
                  />
                </>
              )}
            </FilesContainer>
          </Grid>
          <Grid item xs={12} md={6}>
            <FilesContainer $viewMode='home'>
              {taxAssessments && (
                <>
                  {taxAssessments.map((assessment, idx) => (
                    <EntityAttachment key={idx} attachment={assessment} onPreview={() => setTaxAttachmentSlug(assessment.attachmentSlug)} />
                  ))}
                  <EntityAttachmentListPreviewer
                    attachments={taxAssessments}
                    intialPreviewedAttachmentSlug={taxAttachmentSlug}
                    open={!!taxAttachmentSlug}
                    onClose={() => setTaxAttachmentSlug(null)}
                  />
                </>
              )}
            </FilesContainer>
          </Grid>
        </Grid>
      </MetricContainer>
    </RootPaper>
  )
}

const generateInvoiceMutation = graphql`
  mutation ShipmentFinanceActionCardMutation($input: infoGenerateInvoiceInput!) {
    infoGenerateInvoice(input: $input) {
      clientMutationId
    }
  }
`

const fragment = graphql`
  fragment ShipmentFinanceActionCard_shipment on Shipment {
    slug
    shipmentType
    lexofficeInvoices {
      attachmentSlug
      name
      isImage
      documentType
      ...EntityAttachmentListPreviewer_attachment
      ...EntityAttachment_attachment
      ...EntityAttachmentListItem_attachment
    }
    taxAssessments {
      attachmentSlug
      name
      isImage
      documentType
      ...EntityAttachmentListPreviewer_attachment
      ...EntityAttachment_attachment
      ...EntityAttachmentListItem_attachment
    }
  }
`

export default ShipmentFinanceActionCard
