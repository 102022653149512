import React from 'react'
import { ButtonGroup } from '@material-ui/core'
import { ZoomIn, ZoomOut } from '@material-ui/icons'
import styled from 'styled-components'
import Button from '../../components/Button'
import { BarChart, RevenueBarChart } from '../../components/Charts/OdysseyCharts'
import { Data, RevenueData } from '../../components/Charts/types'
import usePaginatedBarChart from '../../components/Charts/utils/usePaginatedBarChart'

const BarActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const ShipmentEtaBar: React.FC<{ data: Data }> = ({ data }) => {
  const { paginatedData, hasNextPage, hasPrevPage, canZoomIn, canZoomOut, paginate, zoom } = usePaginatedBarChart<Data>(data)

  return (
    <div>
      <BarChart data={paginatedData} xLabel='ETAs' yLabel='Containers' />
      <BarActionsContainer>
        <ButtonGroup>
          <Button onClick={() => zoom('out')} disabled={!canZoomOut}>
            <ZoomOut color={canZoomOut ? 'action' : 'disabled'} />
          </Button>
          <Button onClick={() => zoom('in')} disabled={!canZoomIn}>
            <ZoomIn color={canZoomIn ? 'action' : 'disabled'} />
          </Button>
        </ButtonGroup>
        <ButtonGroup>
          <Button onClick={() => paginate('backward')} disabled={!hasPrevPage}>
            Prev
          </Button>
          <Button onClick={() => paginate('forward')} disabled={!hasNextPage}>
            Next
          </Button>
        </ButtonGroup>
      </BarActionsContainer>
    </div>
  )
}

export const ShipmentRevenueBar: React.FC<{ data: RevenueData }> = ({ data }) => {
  const { paginatedData, hasNextPage, hasPrevPage, canZoomIn, canZoomOut, paginate, zoom } = usePaginatedBarChart<RevenueData>(data)

  return (
    <div>
      <RevenueBarChart data={paginatedData} xLabel='Handover Dates' yLabel='Revenues & Costs' />
      <BarActionsContainer>
        <ButtonGroup>
          <Button onClick={() => zoom('out')} disabled={!canZoomOut}>
            <ZoomOut color={canZoomOut ? 'action' : 'disabled'} />
          </Button>
          <Button onClick={() => zoom('in')} disabled={!canZoomIn}>
            <ZoomIn color={canZoomIn ? 'action' : 'disabled'} />
          </Button>
        </ButtonGroup>
        <ButtonGroup>
          <Button onClick={() => paginate('backward')} disabled={!hasPrevPage}>
            Prev
          </Button>
          <Button onClick={() => paginate('forward')} disabled={!hasNextPage}>
            Next
          </Button>
        </ButtonGroup>
      </BarActionsContainer>
    </div>
  )
}
