/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type VinDetailsCard_shipment = {
    readonly slug: string;
    readonly vinDetails: ReadonlyArray<{
        readonly label: string;
        readonly name: string;
        readonly value: string | null;
    }>;
    readonly " $refType": "VinDetailsCard_shipment";
};
export type VinDetailsCard_shipment$data = VinDetailsCard_shipment;
export type VinDetailsCard_shipment$key = {
    readonly " $data"?: VinDetailsCard_shipment$data;
    readonly " $fragmentRefs": FragmentRefs<"VinDetailsCard_shipment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "VinDetailsCard_shipment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "InfoField",
      "kind": "LinkedField",
      "name": "vinDetails",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Shipment",
  "abstractKey": null
};
(node as any).hash = 'bef16beb0f52dbc8f4ff1d27eae33ea1';
export default node;
