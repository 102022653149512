import { Card } from '@material-ui/core'
import { Alert, Skeleton } from '@material-ui/lab'
import { graphql } from 'babel-plugin-relay/macro'
import React, { FormEvent, useState } from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components'
import OdysseyLogoImage from '../assets/odyssey-logo.svg'
import useNiceMutation from '../mutations/useNiceMutation'
import { LiveShareAuthFormMutation, LiveShareAuthFormMutationResponse } from '../__generated__/LiveShareAuthFormMutation.graphql'
import { LiveShareEntityTypeEnum } from '../__generated__/LiveShareAddDialogMutation.graphql'
import CTAButton from './CTAButton'
import LoadingDots from './LoadingDots'
import StyledInput from './StyledInput'
import { LiveShareShipmentShow } from '../pages/LiveShareShipment/LiveShareShipmentShow'
const InvitationCard = styled(Card)`
  padding: 16px 24px;
  max-width: 500px;
  margin: auto;
`

const OdysseyLogoContainer = styled.div`
  width: 50%;
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
`

const Root = styled.div``

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: white;
  width: fit-content;
  height: 100vh;
  overflow-y: auto;
`

const LiveShareAuthForm: React.FC = () => {
  const { token, entityType, entitySlug } = useParams()
  const [password, setPassword] = useState('')
  const [error, setError] = useState<string | null>(null)
  const [authCommit, authIsInFlight] = useNiceMutation<LiveShareAuthFormMutation>(authMutation)
  const [authSucceeded, setAuthSucceeded] = useState(false)
  const [data, setData] = useState<LiveShareAuthFormMutationResponse['infoAuthLiveShare']>(null)

  const handleLogin = (e: FormEvent) => {
    e.preventDefault()

    authCommit({
      variables: {
        input: {
          entitySlug: entitySlug,
          entityType: entityType as LiveShareEntityTypeEnum,
          token: token,
          password: password,
        },
      },
      onCompleted: (resp, errors) => {
        if (errors) {
          setError(errors.map((err) => err.message).join(', '))
        } else {
          setData(resp.infoAuthLiveShare)
          setAuthSucceeded(true)
        }
      },
    })
  }
  return (
    <>
      {error && <Alert severity='error'>{error}</Alert>}
      {!authSucceeded ? (
        <InvitationCard>
          <OdysseyLogoContainer>
            <img src={OdysseyLogoImage} alt='odyssey-logo' width='100%' />
          </OdysseyLogoContainer>
          <form onSubmit={handleLogin}>
            <StyledInput fullWidth placeholder='Password' type='password' value={password} onChange={(e) => setPassword(e.target.value)} />
            <CTAButton type='submit'>{authIsInFlight ? <LoadingDots /> : 'Login'}</CTAButton>
          </form>
        </InvitationCard>
      ) : (
        data &&
        data.result && (
          <Root>
            <Container>
              <LiveShareShipmentShow shipmentShow={data.result} />
            </Container>
          </Root>
        )
      )}
    </>
  )
}

const FormSkeleton = (
  <InvitationCard>
    <OdysseyLogoContainer>
      <img src={OdysseyLogoImage} alt='odyssey-logo' width='100%' />
    </OdysseyLogoContainer>
    <Skeleton variant='rect' height={100} style={{ marginBottom: '16px' }} />
    <Skeleton variant='rect' height={50} style={{ marginBottom: '8px' }} />
    <CTAButton disabled>Get Started</CTAButton>
  </InvitationCard>
)

const authMutation = graphql`
  mutation LiveShareAuthFormMutation($input: infoAuthLiveShareInput!) {
    infoAuthLiveShare(input: $input) {
      clientMutationId
      result {
        ...LiveShareShipmentShow_shipmentShow
      }
    }
  }
`

export default Object.assign(LiveShareAuthForm, { skeletion: FormSkeleton })
