/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type StageStatusEnum = "active" | "completed" | "incomplete" | "%future added value";
export type ExternalShipmentWorkflow_shipment = {
    readonly slug: string;
    readonly workflowStages: ReadonlyArray<{
        readonly value: string;
        readonly label: string;
        readonly ownedBy: string | null;
        readonly status: StageStatusEnum;
        readonly " $fragmentRefs": FragmentRefs<"ExternalStage_stage">;
    }>;
    readonly " $refType": "ExternalShipmentWorkflow_shipment";
};
export type ExternalShipmentWorkflow_shipment$data = ExternalShipmentWorkflow_shipment;
export type ExternalShipmentWorkflow_shipment$key = {
    readonly " $data"?: ExternalShipmentWorkflow_shipment$data;
    readonly " $fragmentRefs": FragmentRefs<"ExternalShipmentWorkflow_shipment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExternalShipmentWorkflow_shipment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "WorkflowStage",
      "kind": "LinkedField",
      "name": "workflowStages",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ownedBy",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ExternalStage_stage"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ExternalShipment",
  "abstractKey": null
};
(node as any).hash = '1fc77088f6fe5dc0ab91be19c2d9d6a9';
export default node;
