import React, { Suspense } from 'react'
import LiveShare from './LiveShare'
import ResponsiveGrid from './ResponsiveGrid'
import { graphql } from 'babel-plugin-relay/macro'
import { LiveShareTabQuery } from '../__generated__/LiveShareTabQuery.graphql'
import Timeline from './Timeline/Timeline'
import useLazyLoadQueryWithSubscription from '../hooks/useLazyLoadQueryWithSubscription'

interface Props {
  entitySlug: string
}

const LiveShareTab: React.FC<Props> & { Skeleton: React.FC } = ({ entitySlug }) => {
  const { shipmentShow: shipment } = useLazyLoadQueryWithSubscription<LiveShareTabQuery>(
    query,
    { slug: entitySlug },
    { subscriptionOptions: { entityType: 'shipments', entitySlug: entitySlug } }
  )

  return (
    <ResponsiveGrid
      type='show-layout'
      highlight={[<LiveShare key={entitySlug} entitySlug={entitySlug} variant='full' liveShares={shipment.liveShares} />]}
      right={[
        <Suspense key={entitySlug} fallback={<Timeline.Skeleton />}>
          <Timeline slug={entitySlug} type='shipments' group='liveshare' />
        </Suspense>,
      ]}
    />
  )
}

const query = graphql`
  query LiveShareTabQuery($slug: String!) {
    shipmentShow(slug: $slug) {
      liveShares {
        ...LiveShare_liveShares
      }
    }
  }
`

LiveShareTab.Skeleton = () => <>Loading...</>
export default LiveShareTab
