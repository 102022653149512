/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ExternalListItemTypeEnum = "containers" | "external_contacts" | "item" | "shipments" | "tickets" | "voyages" | "%future added value";
export type ExternalItemListCardQueryVariables = {
    type?: ExternalListItemTypeEnum | null;
    parentAbsoluteSlug?: string | null;
};
export type ExternalItemListCardQueryResponse = {
    readonly externalItemList: {
        readonly items: {
            readonly nodes: ReadonlyArray<{
                readonly slug: string;
                readonly " $fragmentRefs": FragmentRefs<"ExternalShowPageListItem_listItem">;
            } | null> | null;
        };
    };
};
export type ExternalItemListCardQuery = {
    readonly response: ExternalItemListCardQueryResponse;
    readonly variables: ExternalItemListCardQueryVariables;
};



/*
query ExternalItemListCardQuery(
  $type: ExternalListItemTypeEnum
  $parentAbsoluteSlug: String
) {
  externalItemList(type: $type, parentAbsoluteSlug: $parentAbsoluteSlug) {
    items {
      nodes {
        slug
        ...ExternalShowPageListItem_listItem
        id
      }
    }
  }
}

fragment Badge_data on Badge {
  type
}

fragment ExternalShowPageListItem_listItem on ExternalListItem {
  slug
  title
  itemType
  image {
    ...ItemImage_data
  }
  path
  stages {
    key
    status
    label
    value
  }
  badges {
    key
    ...Badge_data
  }
  lines {
    key
    ...SemanticLine_data
  }
  keyFields {
    key
    ...KeyField_data
  }
}

fragment ItemImage_data on Image {
  url
  alt
  highlight
  shape
  overlay
  overlayIcon
}

fragment KeyField_data on KeyField {
  label
  value {
    key
    ...SemanticString_data
  }
}

fragment SemanticLine_data on SemanticLine {
  parts {
    key
    ...SemanticString_data
  }
}

fragment SemanticString_data on SemanticString {
  text
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "parentAbsoluteSlug"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "type"
},
v2 = [
  {
    "kind": "Variable",
    "name": "parentAbsoluteSlug",
    "variableName": "parentAbsoluteSlug"
  },
  {
    "kind": "Variable",
    "name": "type",
    "variableName": "type"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v6 = [
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "text",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ExternalItemListCardQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "ExternalItemList",
        "kind": "LinkedField",
        "name": "externalItemList",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ExternalListItemConnection",
            "kind": "LinkedField",
            "name": "items",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ExternalListItem",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ExternalShowPageListItem_listItem"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ExternalItemListCardQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "ExternalItemList",
        "kind": "LinkedField",
        "name": "externalItemList",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ExternalListItemConnection",
            "kind": "LinkedField",
            "name": "items",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ExternalListItem",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "itemType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Image",
                    "kind": "LinkedField",
                    "name": "image",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "url",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "alt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "highlight",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "shape",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "overlay",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "overlayIcon",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "path",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Stage",
                    "kind": "LinkedField",
                    "name": "stages",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                      },
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "value",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Badge",
                    "kind": "LinkedField",
                    "name": "badges",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "type",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SemanticLine",
                    "kind": "LinkedField",
                    "name": "lines",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SemanticString",
                        "kind": "LinkedField",
                        "name": "parts",
                        "plural": true,
                        "selections": (v6/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "KeyField",
                    "kind": "LinkedField",
                    "name": "keyFields",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SemanticString",
                        "kind": "LinkedField",
                        "name": "value",
                        "plural": true,
                        "selections": (v6/*: any*/),
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ebb91b436555be66f549c6203c41831f",
    "id": null,
    "metadata": {},
    "name": "ExternalItemListCardQuery",
    "operationKind": "query",
    "text": "query ExternalItemListCardQuery(\n  $type: ExternalListItemTypeEnum\n  $parentAbsoluteSlug: String\n) {\n  externalItemList(type: $type, parentAbsoluteSlug: $parentAbsoluteSlug) {\n    items {\n      nodes {\n        slug\n        ...ExternalShowPageListItem_listItem\n        id\n      }\n    }\n  }\n}\n\nfragment Badge_data on Badge {\n  type\n}\n\nfragment ExternalShowPageListItem_listItem on ExternalListItem {\n  slug\n  title\n  itemType\n  image {\n    ...ItemImage_data\n  }\n  path\n  stages {\n    key\n    status\n    label\n    value\n  }\n  badges {\n    key\n    ...Badge_data\n  }\n  lines {\n    key\n    ...SemanticLine_data\n  }\n  keyFields {\n    key\n    ...KeyField_data\n  }\n}\n\nfragment ItemImage_data on Image {\n  url\n  alt\n  highlight\n  shape\n  overlay\n  overlayIcon\n}\n\nfragment KeyField_data on KeyField {\n  label\n  value {\n    key\n    ...SemanticString_data\n  }\n}\n\nfragment SemanticLine_data on SemanticLine {\n  parts {\n    key\n    ...SemanticString_data\n  }\n}\n\nfragment SemanticString_data on SemanticString {\n  text\n}\n"
  }
};
})();
(node as any).hash = 'a7330a0de5ded24f48ab78a60ffa0aad';
export default node;
