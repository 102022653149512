/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type FormFieldTypeEnum = "array" | "boolean" | "boolean_switch" | "container" | "container_image_slug" | "date" | "datetime" | "doc_slug" | "file_slug" | "image_slug" | "linked_contacts" | "multi_select" | "number" | "parent_shipment" | "port" | "rate_card" | "rich_text" | "searchable" | "shipment_image_slug" | "shipping_line" | "string" | "vessel" | "voyage" | "%future added value";
export type VinDetailsCardEditDialogQueryVariables = {
    shipmentSlug: string;
};
export type VinDetailsCardEditDialogQueryResponse = {
    readonly infoCreateOrUpdateVinDetailForm: {
        readonly formSchema: {
            readonly fieldSet: ReadonlyArray<{
                readonly name: string;
                readonly type: FormFieldTypeEnum;
                readonly required: boolean;
                readonly title: string | null;
                readonly description: string | null;
                readonly format: string | null;
                readonly enum: ReadonlyArray<string | null> | null;
                readonly enumTitles: ReadonlyArray<string | null> | null;
            }>;
        };
        readonly initialValue: {
            readonly shipmentSlug: string;
            readonly year: string | null;
            readonly model: string | null;
            readonly body: string | null;
            readonly engine: string | null;
            readonly country: string | null;
            readonly countryCode: string | null;
            readonly capacity: string | null;
            readonly fuelType: string | null;
        } | null;
    };
};
export type VinDetailsCardEditDialogQuery = {
    readonly response: VinDetailsCardEditDialogQueryResponse;
    readonly variables: VinDetailsCardEditDialogQueryVariables;
};



/*
query VinDetailsCardEditDialogQuery(
  $shipmentSlug: String!
) {
  infoCreateOrUpdateVinDetailForm(shipmentSlug: $shipmentSlug) {
    formSchema {
      fieldSet {
        name
        type
        required
        title
        description
        format
        enum
        enumTitles
      }
    }
    initialValue {
      shipmentSlug
      year
      model
      body
      engine
      country
      countryCode
      capacity
      fuelType
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "shipmentSlug"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "shipmentSlug",
        "variableName": "shipmentSlug"
      }
    ],
    "concreteType": "InfoCreateOrUpdateVinDetailFormResponse",
    "kind": "LinkedField",
    "name": "infoCreateOrUpdateVinDetailForm",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "FormSchema",
        "kind": "LinkedField",
        "name": "formSchema",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FormField",
            "kind": "LinkedField",
            "name": "fieldSet",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "required",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "format",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "enum",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "enumTitles",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "VinDetail",
        "kind": "LinkedField",
        "name": "initialValue",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shipmentSlug",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "year",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "model",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "body",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "engine",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "country",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "countryCode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "capacity",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fuelType",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "VinDetailsCardEditDialogQuery",
    "selections": (v1/*: any*/),
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "VinDetailsCardEditDialogQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a07cbd5c0fb87b2c768f0d1409acb0b8",
    "id": null,
    "metadata": {},
    "name": "VinDetailsCardEditDialogQuery",
    "operationKind": "query",
    "text": "query VinDetailsCardEditDialogQuery(\n  $shipmentSlug: String!\n) {\n  infoCreateOrUpdateVinDetailForm(shipmentSlug: $shipmentSlug) {\n    formSchema {\n      fieldSet {\n        name\n        type\n        required\n        title\n        description\n        format\n        enum\n        enumTitles\n      }\n    }\n    initialValue {\n      shipmentSlug\n      year\n      model\n      body\n      engine\n      country\n      countryCode\n      capacity\n      fuelType\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'ff4d967d079c152a924378c76fc3a961';
export default node;
