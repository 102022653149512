import React, { Suspense, useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { NiceTabPanel } from '../../components/TabsUI/TabsUI'
import ContainerShowHomeContent, { containerHomeQuery } from './ContainerShowHomeContent'
import ContainerShowHeroCard from './ContainerShowHeroCard'
import NoteContent from '../../components/NoteTab'
import ContainerEdit from './ContainerEdit'
import RelatedItemList from '../ItemList/RelatedItemList'
import ContainerFolders from './ContainerFolders'
import ContainerEmails from './ContainerEmails'
import ContainerFinanceShow from './ContainerFinanceShow'
import styled from 'styled-components'
import { useRelayEnvironment } from 'react-relay/hooks'
import { createOperationDescriptor, getRequest } from 'relay-runtime'
import EntityShowOrchestrator from '../../components/EntityShowOrchestrator'
import { uploadableDocsQuery } from '../../components/File/helpers'
import { ShowPageRenderContext } from '../../viewTypes'
import { ContainerLinkedContactsTab } from '../../components/LinkedContactsTab'

const ContainerShow: React.FC = () => {
  const { slug, tab } = useParams()
  return <ContainerShowWithParams slug={slug} tab={tab} renderContext='page' />
}

interface Params {
  slug: string
  tab: string
  withSkeleton?: boolean
  renderContext?: ShowPageRenderContext
}

const TabPanelsContainer = styled.div`
  min-height: 90vh;
`

export const ContainerShowWithParams: React.FC<Params> = ({ slug, tab, withSkeleton = false, renderContext = 'drawer' }) => {
  const [viewMode, setViewMode] = useState<'show' | 'edit'>('show')
  const environment = useRelayEnvironment()

  useEffect(() => {
    // telling relay to retain these queries in store even when their components are not mounted (until this component unmounts)
    const homeQueryDisposable = environment.retain(createOperationDescriptor(getRequest(containerHomeQuery), { slug: slug }))
    const uploadableDocsQueryDisposable = environment.retain(
      createOperationDescriptor(getRequest(uploadableDocsQuery), { entityType: 'containers' })
    )

    return () => {
      homeQueryDisposable.dispose()
      uploadableDocsQueryDisposable.dispose()
    }
  }, [slug, environment])

  return (
    <EntityShowOrchestrator
      showPage={
        <>
          {withSkeleton ? (
            <Suspense fallback={<ContainerShowHeroCard.Skeleton />}>
              <ContainerShowHeroCard slug={slug} activeTab={tab} onEdit={() => setViewMode('edit')} renderContext={renderContext} />
            </Suspense>
          ) : (
            <ContainerShowHeroCard slug={slug} activeTab={tab} onEdit={() => setViewMode('edit')} renderContext={renderContext} />
          )}

          <TabPanelsContainer>
            <NiceTabPanel forTab='home' activeTab={tab}>
              <Suspense fallback={<ContainerShowHomeContent.Skeleton />}>
                <ContainerShowHomeContent slug={slug} />
              </Suspense>
            </NiceTabPanel>

            <NiceTabPanel forTab='finances' activeTab={tab}>
              <Suspense fallback={<ContainerFinanceShow.Skeleton />}>
                <ContainerFinanceShow slug={slug} />
              </Suspense>
            </NiceTabPanel>

            <NiceTabPanel forTab='files' activeTab={tab}>
              <Suspense fallback={<ContainerFolders.Skeleton />}>
                <ContainerFolders slug={slug} />
              </Suspense>
            </NiceTabPanel>

            <NiceTabPanel forTab='contacts' activeTab={tab}>
              <Suspense fallback={<ContainerLinkedContactsTab.Skeleton />}>
                <ContainerLinkedContactsTab slug={slug} />
              </Suspense>
            </NiceTabPanel>

            <NiceTabPanel forTab='emails' activeTab={tab}>
              <Suspense fallback={<ContainerEmails.Skeleton />}>
                <ContainerEmails slug={slug} />
              </Suspense>
            </NiceTabPanel>

            <NiceTabPanel forTab='shipments' activeTab={tab}>
              <Suspense fallback={<RelatedItemList.Skeleton />}>
                <RelatedItemList
                  type='shipments'
                  parentAbsoluteSlug={`containers/${slug}`}
                  description='Shipments in this container'
                  entity='Shipment'
                />
              </Suspense>
            </NiceTabPanel>
            <NiceTabPanel forTab='notes' activeTab={tab}>
              <Suspense fallback={<NoteContent.Skeleton />}>
                <NoteContent slug={slug} entityType='containers' />
              </Suspense>
            </NiceTabPanel>
          </TabPanelsContainer>
        </>
      }
      editPage={<ContainerEdit slug={slug} onClose={() => setViewMode('show')} />}
      viewMode={viewMode}
    />
  )
}

export default ContainerShow
