import { graphql } from 'babel-plugin-relay/macro'
import React from 'react'
import { useLazyLoadQuery } from 'react-relay/hooks'
import { useNavigate } from 'react-router'
import FormActionBar from '../../components/Form/FormActionBar'
import NiceFormik from '../../components/Form/NiceFormik'
import ContactForm from '../../forms/Contact/ContactForm'
import useWorkspace from '../../hooks/useWorkspace'
import useNiceMutation from '../../mutations/useNiceMutation'
import { ContactAddFormQuery, ContactTypeEnum } from '../../__generated__/ContactAddFormQuery.graphql'
import { ContactAddMutation } from '../../__generated__/ContactAddMutation.graphql'
import FormContainer from '../../forms/FormContainer'

interface Props {
  type: ContactTypeEnum
  title: string
  onClose?: () => void
  onSubmit?: (contactSlug: string) => void
}
export interface FormValues {
  address: string
  businessPhone: string
  place: string
}

const ContactAdd: React.FC<Props> & { Skeleton: React.FC } = ({ type, title, onClose, onSubmit }) => {
  const [workspace] = useWorkspace()
  const navigate = useNavigate()
  const data = useLazyLoadQuery<ContactAddFormQuery>(query, { type: type })
  const fields = data.infoAddContactForm.formSchema.fieldSet
  const fieldMaster = Object.fromEntries(fields.map((field) => [field.name, field]))
  const gotoContact = (slug: string) => navigate(`/${workspace}/contact/${slug}/home`)

  const [commit] = useNiceMutation<ContactAddMutation>(mutation)

  return (
    <>
      <NiceFormik
        initialValues={{ contactType: type, slug: '', roles: [], address: '', businessPhone: '', place: '' }}
        validate={(values) => {
          const errors: Partial<FormValues> = {}
          if (!(values.roles as string[]).includes('transporter') && !values.address) {
            errors.address = 'Address is a required field'
          }
          if (!(values.roles as string[]).includes('transporter') && !values.place) {
            errors.place = 'City is a required field'
          }
          if ((values.roles as string[]).includes('transporter') && !values.businessPhone) {
            errors.businessPhone = 'businessPhone is a required field'
          }
          return errors
        }}
        onSubmit={(values, actions) => {
          commit({
            variables: {
              input: values as any,
            },
            onError: () => actions.setSubmitting(false),
            onCompleted: (_res, errors) => {
              actions.setSubmitting(false)
              if (!errors) {
                onClose === undefined ? gotoContact((values as any).slug) : onClose()
                if (onSubmit !== undefined) onSubmit((values as any).slug)
              }
            },
          })
        }}
        formSchema={fields}
      >
        {({ submitForm }) => (
          <FormContainer>
            <ContactForm fieldMaster={fieldMaster} mode='add' />
            <FormActionBar title={title} onCancel={() => (onClose === undefined ? navigate(-1) : onClose())} onSubmit={() => submitForm()} />
          </FormContainer>
        )}
      </NiceFormik>
    </>
  )
}

const query = graphql`
  query ContactAddFormQuery($type: ContactTypeEnum!) {
    infoAddContactForm(type: $type) {
      formSchema {
        fieldSet {
          name
          type
          required
          title
          description
          format
          enum
          enumTitles
        }
      }
    }
  }
`

const mutation = graphql`
  mutation ContactAddMutation($input: infoAddContactInput!) {
    infoAddContact(input: $input) {
      contact {
        slug
      }
    }
  }
`

ContactAdd.Skeleton = () => (
  <>
    <ContactForm.Skeleton />
    <FormActionBar.Skeleton />
  </>
)

export default ContactAdd
