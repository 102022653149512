import React from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import styled from 'styled-components'
import { ContactRateCard_rateCardItem$key } from '../../__generated__/ContactRateCard_rateCardItem.graphql'
import { Paper, Typography } from '@material-ui/core'
import { useFragment } from 'react-relay/hooks'
import { media } from '../../theme'
import KeyField from '../../components/KeyField'

interface Props {
  rateCardItem: ContactRateCard_rateCardItem$key
}

const RootPaper = styled(Paper)`
  padding: 16px;
`

const TitleContainer = styled.div`
  margin-bottom: 8px;
`

const KeyFieldsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 8px;

  ${media.medium`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${media.small`
    grid-template-columns: repeat(2, 1fr);
  `}
`

const ContactRateCard: React.FC<Props> = ({ rateCardItem: data }) => {
  const rateCardItem = useFragment(fragment, data)
  return (
    <RootPaper variant='outlined'>
      <TitleContainer>
        <Typography variant='h6'>Rate Card ({rateCardItem.title})</Typography>
      </TitleContainer>
      {rateCardItem.keyFields && (
        <KeyFieldsContainer>
          {rateCardItem.keyFields?.map((field) => (
            <KeyField key={field.key} data={field} />
          ))}
        </KeyFieldsContainer>
      )}
    </RootPaper>
  )
}

const fragment = graphql`
  fragment ContactRateCard_rateCardItem on SettingsItem {
    title
    keyFields {
      key
      ...KeyField_data
    }
  }
`

export default ContactRateCard
