/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AttachmentEntityTypeEnum = "contacts" | "containers" | "shipments" | "%future added value";
export type EntityAttachmentRoleVisibility_attachment = {
    readonly name: string;
    readonly attachmentSlug: string;
    readonly entityType: AttachmentEntityTypeEnum;
    readonly entitySlug: string;
    readonly roleVisibilities: ReadonlyArray<{
        readonly role: string;
        readonly roleShortTitle: string;
        readonly roleTitle: string;
        readonly visible: boolean;
    }>;
    readonly " $refType": "EntityAttachmentRoleVisibility_attachment";
};
export type EntityAttachmentRoleVisibility_attachment$data = EntityAttachmentRoleVisibility_attachment;
export type EntityAttachmentRoleVisibility_attachment$key = {
    readonly " $data"?: EntityAttachmentRoleVisibility_attachment$data;
    readonly " $fragmentRefs": FragmentRefs<"EntityAttachmentRoleVisibility_attachment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EntityAttachmentRoleVisibility_attachment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "attachmentSlug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entityType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entitySlug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RoleVisibility",
      "kind": "LinkedField",
      "name": "roleVisibilities",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "role",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "roleShortTitle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "roleTitle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "visible",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "EntityAttachment",
  "abstractKey": null
};
(node as any).hash = '61ad0308c63561141bbceb7e3511fcb6';
export default node;
