import React from 'react'
import { graphql } from 'babel-plugin-relay/macro'

import { useNavigate } from 'react-router'
import { useLazyLoadQuery } from 'react-relay/hooks'
import { ContainerAddFormQuery } from '../../__generated__/ContainerAddFormQuery.graphql'
import NiceFormik from '../../components/Form/NiceFormik'
import useNiceMutation from '../../mutations/useNiceMutation'
import { ContainerAddMutation } from '../../__generated__/ContainerAddMutation.graphql'
import useWorkspace from '../../hooks/useWorkspace'
import FormActionBar from '../../components/Form/FormActionBar'
import ContainerForm from '../../forms/Container/ContainerForm'
import { inputHashToLinkedContacts } from '../../components/Form/LinkedContactsField'
import FormContainer from '../../forms/FormContainer'

const ContainerAdd: React.FC & { Skeleton: React.FC } = () => {
  const [workspace] = useWorkspace()
  const navigate = useNavigate()
  const data = useLazyLoadQuery<ContainerAddFormQuery>(query, {})
  const fields = data.infoAddContainerForm.formSchema.fieldSet
  const fieldMaster = Object.fromEntries(fields.map((field) => [field.name, field]))
  const gotoContainer = (slug: string) => navigate(`/${workspace}/container/${slug}/home`)

  const [commit] = useNiceMutation<ContainerAddMutation>(mutation)

  return (
    <>
      <NiceFormik
        initialValues={{}}
        onSubmit={(values, actions) => {
          const processedValues = {
            ...values,
            shipments: (values as any).shipments?.map((shipment: any) => {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const { key: _, ...shipmentWithoutKey } = shipment

              return shipmentWithoutKey
            }),
            linkedContacts: inputHashToLinkedContacts((values as any).linkedContacts),
          }
          commit({
            variables: {
              input: processedValues as any,
            },
            onError: () => actions.setSubmitting(false),
            onCompleted: (res, errors) => {
              actions.setSubmitting(false)
              if (!errors) {
                gotoContainer(res.infoAddContainer?.container.slug || '')
              }
            },
          })
        }}
        formSchema={fields}
      >
        {({ submitForm }) => (
          <FormContainer>
            <ContainerForm fieldMaster={fieldMaster} mode='add' />
            <FormActionBar title={'New Container'} onCancel={() => navigate(-1)} onSubmit={() => submitForm()} />
          </FormContainer>
        )}
      </NiceFormik>
    </>
  )
}

const query = graphql`
  query ContainerAddFormQuery {
    infoAddContainerForm {
      formSchema {
        fieldSet {
          name
          type
          required
          title
          description
          format
          enum
          enumTitles

          arrayItems {
            name
            type
            title
            required
            description
            format
            enum
            enumTitles
          }
        }
      }
    }
  }
`

const mutation = graphql`
  mutation ContainerAddMutation($input: infoAddContainerInput!) {
    infoAddContainer(input: $input) {
      container {
        slug
      }
    }
  }
`

ContainerAdd.Skeleton = () => (
  <>
    <ContainerForm.Skeleton />
    <FormActionBar.Skeleton />
  </>
)

export default ContainerAdd
