import { Card, Typography } from '@material-ui/core'
import { graphql } from 'babel-plugin-relay/macro'
import React from 'react'
import { useFragment } from 'react-relay/hooks'
import styled from 'styled-components'
import { isMedium, isSmall, media } from '../theme'
import { ShowPageItem_data$key } from '../__generated__/ShowPageItem_data.graphql'
import Badge from './Badge'
import ItemImage from './ItemImage'
import KeyField from './KeyField'
import KeyValueField from './KeyValueField'
import LinkCard from './LinkSet/LinkCard'
import SemanticLine from './SemanticLine'
import SemanticString from './SemanticString'
import Status from './Status'
import StickyNote from './StickyNote'

interface Props {
  data: ShowPageItem_data$key
}

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`

export const HeroContainer = styled(FlexContainer)`
  padding: 16px;
  position: relative;
  padding-bottom: 0;
  align-items: flex-start;

  ${media.small`
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `}
`

export const ContactHeader = styled.div`
  width: 100%;
  margin: 0 16px;

  ${media.small`
    margin-top: 8px;
    margin-bottom: 8px;
  `}
`

export const ProfileContainer = styled(FlexContainer)`
  flex-direction: column;
  justify-content: center;
`

export const TitleContainer = styled(FlexContainer)`
  width: 100%;
  justify-content: space-between;

  ${media.small`
    flex-direction: column-reverse;
  `}
`

export const HeaderContainer = styled(FlexContainer)`
  margin: 8px 0;
  flex-direction: column;
  align-items: flex-start;

  ${media.small`
    padding-bottom: 8px;
    align-items: center;
    border-bottom: 1px solid #e4e4e4;
  `}
`

export const Title = styled(Typography)`
  font-size: 24px;
  font-weight: 700;
  margin-right: 8px;
  line-height: 30px;
  transition-duration: 200ms;
  color: #344e5c;

  ${media.small`
    margin: 8px 0;
  `}
`

const MetaContainer = styled.div`
  min-width: 200px;

  ${media.small`
    min-width: 100%;
  `}
`

const DetailsCard = styled(Card)`
  width: 100%;
  padding: 16px;
`

const KeyFieldsContainer = styled.div`
  margin: 4px 0 16px 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, 190px);
  grid-gap: 8px;
  width: 100%;

  ${media.small`
    grid-template-columns: repeat(auto-fill, 110px);
  `}
`

const LinkKeyFieldsContainer = styled(KeyFieldsContainer)`
  grid-template-columns: repeat(2, 1fr);
  ${media.small`
    grid-template-columns: 1fr;
  `}
`

const ShowPageItem: React.FC<Props> = ({ data }) => {
  const item = useFragment(showItemFragment, data)
  const maxFields = isSmall() ? 4 : isMedium() ? 9 : 15

  const vesselLink = (item.linkSets?.map((linkSet) => linkSet.links?.find((link) => link.type === 'vessel'))?.filter((link) => link) || [])[0]

  return (
    <HeroContainer>
      <ProfileContainer>{item.image && <ItemImage data={item.image} />}</ProfileContainer>
      <ContactHeader>
        <HeaderContainer>
          <TitleContainer>
            <Title variant='subtitle1' color='textPrimary'>
              {item.title}
              {item.badges?.map((badge) => (
                <Badge key={badge.key} data={badge} />
              ))}
            </Title>
          </TitleContainer>
          {item.lines?.map((line) => (
            <SemanticLine key={line.key} data={line} />
          ))}
        </HeaderContainer>
        <DetailsCard variant='outlined'>
          {item.keyFields && (
            <KeyFieldsContainer>
              {item.keyFields?.slice(0, maxFields)?.map((field) => (
                <KeyField key={field.key} data={field} />
              ))}
            </KeyFieldsContainer>
          )}
          {item.itemType === 'voyage' && (
            <LinkKeyFieldsContainer>
              <KeyValueField label='Vessel' value={vesselLink ? <LinkCard data={vesselLink} /> : null} />
            </LinkKeyFieldsContainer>
          )}
        </DetailsCard>
      </ContactHeader>
      <MetaContainer>
        {item.statuses &&
          item.statuses.length > 0 &&
          item.statuses.map((status) => (
            <Status key={status.key}>
              <SemanticString data={status.value} />
            </Status>
          ))}
        {item.stickyNoteContent && <StickyNote note={item.stickyNoteContent} />}
      </MetaContainer>
    </HeroContainer>
  )
}

const showItemFragment = graphql`
  fragment ShowPageItem_data on ListItem {
    slug
    title
    itemType
    image {
      ...ItemImage_data
    }
    path
    statuses {
      key
      value {
        ...SemanticString_data
      }
    }
    badges {
      key
      ...Badge_data
    }
    actions {
      key
      ...Action_data
    }
    lines {
      key
      ...SemanticLine_data
    }
    keyFields {
      key
      ...KeyField_data
    }
    linkSets {
      links {
        key
        type
        ...LinkCard_data
      }
    }
    stickyNoteContent
  }
`

export default ShowPageItem
