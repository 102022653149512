/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ShipmentShowHeroCardContainerQueryVariables = {
    slug: string;
};
export type ShipmentShowHeroCardContainerQueryResponse = {
    readonly shipmentShow: {
        readonly slug: string;
        readonly allPackages: ReadonlyArray<{
            readonly slug: string;
            readonly shipmentKey: string;
            readonly description: string;
            readonly shipmentType: string;
            readonly vin: string | null;
        }>;
        readonly container: {
            readonly slug: string;
            readonly containerNumber: string;
            readonly imageUrl: string;
            readonly shipmentItems: ReadonlyArray<{
                readonly slug: string;
                readonly title: string;
                readonly children: ReadonlyArray<{
                    readonly slug: string;
                    readonly title: string;
                    readonly badges: ReadonlyArray<{
                        readonly key: string;
                        readonly " $fragmentRefs": FragmentRefs<"Badge_data">;
                    }> | null;
                    readonly lines: ReadonlyArray<{
                        readonly key: string;
                        readonly parts: ReadonlyArray<{
                            readonly " $fragmentRefs": FragmentRefs<"SemanticString_data">;
                        }> | null;
                        readonly " $fragmentRefs": FragmentRefs<"SemanticLine_data">;
                    }> | null;
                }> | null;
                readonly badges: ReadonlyArray<{
                    readonly key: string;
                    readonly " $fragmentRefs": FragmentRefs<"Badge_data">;
                }> | null;
                readonly image: {
                    readonly url: string | null;
                } | null;
                readonly lines: ReadonlyArray<{
                    readonly key: string;
                    readonly parts: ReadonlyArray<{
                        readonly " $fragmentRefs": FragmentRefs<"SemanticString_data">;
                    }> | null;
                    readonly " $fragmentRefs": FragmentRefs<"SemanticLine_data">;
                }> | null;
            }>;
        };
    };
};
export type ShipmentShowHeroCardContainerQuery = {
    readonly response: ShipmentShowHeroCardContainerQueryResponse;
    readonly variables: ShipmentShowHeroCardContainerQueryVariables;
};



/*
query ShipmentShowHeroCardContainerQuery(
  $slug: String!
) {
  shipmentShow(slug: $slug) {
    slug
    allPackages {
      slug
      shipmentKey
      description
      shipmentType
      vin
      id
    }
    container {
      slug
      containerNumber
      imageUrl
      shipmentItems {
        slug
        title
        children {
          slug
          title
          badges {
            key
            ...Badge_data
          }
          lines {
            key
            parts {
              ...SemanticString_data
            }
            ...SemanticLine_data
          }
          id
        }
        badges {
          key
          ...Badge_data
        }
        image {
          url
        }
        lines {
          key
          parts {
            ...SemanticString_data
          }
          ...SemanticLine_data
        }
        id
      }
      id
    }
    id
  }
}

fragment Badge_data on Badge {
  type
}

fragment SemanticLine_data on SemanticLine {
  parts {
    key
    ...SemanticString_data
  }
}

fragment SemanticString_data on SemanticString {
  text
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shipmentKey",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shipmentType",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "vin",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "containerNumber",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageUrl",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "Badge",
  "kind": "LinkedField",
  "name": "badges",
  "plural": true,
  "selections": [
    (v10/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Badge_data"
    }
  ],
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "SemanticLine",
  "kind": "LinkedField",
  "name": "lines",
  "plural": true,
  "selections": [
    (v10/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "SemanticString",
      "kind": "LinkedField",
      "name": "parts",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SemanticString_data"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SemanticLine_data"
    }
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "Image",
  "kind": "LinkedField",
  "name": "image",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "Badge",
  "kind": "LinkedField",
  "name": "badges",
  "plural": true,
  "selections": [
    (v10/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "SemanticLine",
  "kind": "LinkedField",
  "name": "lines",
  "plural": true,
  "selections": [
    (v10/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "SemanticString",
      "kind": "LinkedField",
      "name": "parts",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        },
        (v10/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ShipmentShowHeroCardContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Shipment",
        "kind": "LinkedField",
        "name": "shipmentShow",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Shipment",
            "kind": "LinkedField",
            "name": "allPackages",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Container",
            "kind": "LinkedField",
            "name": "container",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ListItem",
                "kind": "LinkedField",
                "name": "shipmentItems",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ListItem",
                    "kind": "LinkedField",
                    "name": "children",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v9/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v11/*: any*/),
                  (v13/*: any*/),
                  (v12/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ShipmentShowHeroCardContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Shipment",
        "kind": "LinkedField",
        "name": "shipmentShow",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Shipment",
            "kind": "LinkedField",
            "name": "allPackages",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v14/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Container",
            "kind": "LinkedField",
            "name": "container",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ListItem",
                "kind": "LinkedField",
                "name": "shipmentItems",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ListItem",
                    "kind": "LinkedField",
                    "name": "children",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v9/*: any*/),
                      (v15/*: any*/),
                      (v16/*: any*/),
                      (v14/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v15/*: any*/),
                  (v13/*: any*/),
                  (v16/*: any*/),
                  (v14/*: any*/)
                ],
                "storageKey": null
              },
              (v14/*: any*/)
            ],
            "storageKey": null
          },
          (v14/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "27d4215a663ea958354fc187a5c3d2e6",
    "id": null,
    "metadata": {},
    "name": "ShipmentShowHeroCardContainerQuery",
    "operationKind": "query",
    "text": "query ShipmentShowHeroCardContainerQuery(\n  $slug: String!\n) {\n  shipmentShow(slug: $slug) {\n    slug\n    allPackages {\n      slug\n      shipmentKey\n      description\n      shipmentType\n      vin\n      id\n    }\n    container {\n      slug\n      containerNumber\n      imageUrl\n      shipmentItems {\n        slug\n        title\n        children {\n          slug\n          title\n          badges {\n            key\n            ...Badge_data\n          }\n          lines {\n            key\n            parts {\n              ...SemanticString_data\n            }\n            ...SemanticLine_data\n          }\n          id\n        }\n        badges {\n          key\n          ...Badge_data\n        }\n        image {\n          url\n        }\n        lines {\n          key\n          parts {\n            ...SemanticString_data\n          }\n          ...SemanticLine_data\n        }\n        id\n      }\n      id\n    }\n    id\n  }\n}\n\nfragment Badge_data on Badge {\n  type\n}\n\nfragment SemanticLine_data on SemanticLine {\n  parts {\n    key\n    ...SemanticString_data\n  }\n}\n\nfragment SemanticString_data on SemanticString {\n  text\n}\n"
  }
};
})();
(node as any).hash = '6c81a2ba94daece9ee92da072f18c5ea';
export default node;
