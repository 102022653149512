/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type EmailsCard_emails = {
    readonly nodes: ReadonlyArray<{
        readonly slug: string;
        readonly " $fragmentRefs": FragmentRefs<"EmailItem_email">;
    } | null> | null;
    readonly " $refType": "EmailsCard_emails";
};
export type EmailsCard_emails$data = EmailsCard_emails;
export type EmailsCard_emails$key = {
    readonly " $data"?: EmailsCard_emails$data;
    readonly " $fragmentRefs": FragmentRefs<"EmailsCard_emails">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EmailsCard_emails",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Email",
      "kind": "LinkedField",
      "name": "nodes",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EmailItem_email"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "EmailConnection",
  "abstractKey": null
};
(node as any).hash = 'adc54ce76cd3de441f88739a820b2e05';
export default node;
