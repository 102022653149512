import React from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import { useFragment } from 'react-relay/hooks'
import { VesselLinkCard_data$key } from '../../__generated__/VesselLinkCard_data.graphql'
import styled from 'styled-components'
import SemanticString from '../SemanticString'
import { DirectionsBoat } from '@material-ui/icons'

interface Props {
  data: VesselLinkCard_data$key
}

const Root = styled.div`
  text-decoration: unset;
  color: unset;
  border: 1px solid ${(props) => props.theme.customPalette.border.card};
  border-radius: 4px;
  display: flex;
  align-items: center;
  overflow: hidden;

  &:hover {
    border-color: ${(props) => props.theme.palette.primary.main};
  }
`

const Meta = styled.div`
  padding: 8px;
  height: 100%;
  background-color: ${(props) => props.theme.palette.grey[200]};
  color: ${(props) => props.theme.palette.grey[500]};
  white-space: pre;
  display: flex;
`

const TitleContainer = styled.div`
  padding: 8px;
  white-space: pre;
  overflow: hidden;
  background-color: ${(props) => props.theme.palette.background.paper};
  flex: 1;
`

const TitleText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`

const VesselLinkCard: React.FC<Props> = ({ data }) => {
  const link = useFragment(linkFragment, data)
  return (
    <Root>
      <Meta>
        <DirectionsBoat />
      </Meta>
      <TitleContainer>
        <TitleText>{link.title && <SemanticString data={link.title} />}</TitleText>
      </TitleContainer>
    </Root>
  )
}

const linkFragment = graphql`
  fragment VesselLinkCard_data on Link {
    type
    title {
      ...SemanticString_data
    }
  }
`

export default VesselLinkCard
