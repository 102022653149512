import { DialogContent, DialogTitle, DialogActions, ListItem, makeStyles } from '@material-ui/core'
import React, { Suspense, useState } from 'react'
import OdysseyDialog from '../OdysseyDialog'
import { graphql } from 'babel-plugin-relay/macro'
import { useLazyLoadQuery } from 'react-relay/hooks'
import { ShipmentAutomaticHandoverQuery } from '../../__generated__/ShipmentAutomaticHandoverQuery.graphql'
import List from '@material-ui/core/List'
import { Skeleton } from '@material-ui/lab'
import Button from '../Button'
import useNiceMutation from '../../mutations/useNiceMutation'
import { ShipmentAutomaticHandoverMutation } from '../../__generated__/ShipmentAutomaticHandoverMutation.graphql'
import LoadingDots from '../LoadingDots'
import { ShipmentAutomaticHandoverEmailMutation } from '../../__generated__/ShipmentAutomaticHandoverEmailMutation.graphql'
import StyledInput from '../StyledInput'
import moment from 'moment'
interface Props {
  open: boolean
  entitySlug: string
  onClose: () => void
  onAction: (values?: any) => void
}
const useStyles = makeStyles({
  errorItem: {
    color: 'red',
    padding: '0px',
  },
  item: {
    padding: '0px',
  },
})
const ShipmentAutomaticHandover: React.FC<Props> = (props) => {
  return (
    <>
      <OdysseyDialog open={props.open} onClose={props.onClose}>
        <DialogTitle></DialogTitle>
        <Suspense fallback={<ShipmentAutomatiHandoverContentSkeleton {...props} />}>
          <ShipmentAutomatiHandoverContent {...props}></ShipmentAutomatiHandoverContent>
        </Suspense>
      </OdysseyDialog>
    </>
  )
}

const ShipmentAutomatiHandoverContent: React.FC<Props> = ({ onClose, entitySlug, onAction }) => {
  const data = useLazyLoadQuery<ShipmentAutomaticHandoverQuery>(query, {
    entityType: 'shipments',
    entitySlug: entitySlug,
    templateSlug: 'handover',
  })
  const errors = data.generatableDocumentValidation.errors
  const information = data.generatableDocumentValidation.information
  const [handoverDate, setHandoverDate] = useState(moment().format('YYYY-MM-DD'))

  const [commit, loading] = useNiceMutation<ShipmentAutomaticHandoverMutation>(mutation)
  const [commitEmail, loadingEmail] = useNiceMutation<ShipmentAutomaticHandoverEmailMutation>(email_mutation)
  // const [stageChangeIsInFlight, setStageChangeIsInFlight] = useState(false)

  const classes = useStyles()

  const handleAutomatic = () => {
    if (entitySlug && handoverDate)
      commit({
        variables: {
          input: {
            shipmentSlug: entitySlug,
            handoverDate: handoverDate,
          },
        },
        onCompleted: (res, errors) => {
          if (!errors) {
            sendEmail()
          }
        },
      })
    onClose()
  }

  const sendEmail = () => {
    commitEmail({
      variables: {
        input: {
          shipmentSlug: entitySlug,
        },
      },
      onCompleted: async (res, errors) => {
        if (!errors) {
          onAction({
            slug: entitySlug,
            stage: 'handover',
            handoverDate: handoverDate,
          })
          onClose()
        }
      },
    })
  }

  return (
    <>
      <DialogContent>
        {data.generatableDocumentValidation.handoverDatePresent && (
          <div>
            <label htmlFor='handover_date'> Handover Date</label>&nbsp;&nbsp;
            <StyledInput
              type='date'
              id='handover_date'
              value={handoverDate}
              onChange={(e) => {
                setHandoverDate(e.target.value)
              }}
            ></StyledInput>
          </div>
        )}
        {errors.length > 0 ? (
          <>
            Automatic Handover is not possible.
            <List>
              {errors.map((err, index) => {
                return (
                  <ListItem className={classes.errorItem} key={index}>
                    {err}
                  </ListItem>
                )
              })}
            </List>
          </>
        ) : (
          <List>
            <ListItem className={classes.item}>Handover Date: {information?.handoverDate as string} </ListItem>
            <ListItem className={classes.item}>Transporter Name: {information?.transporterName as string} </ListItem>
            <ListItem className={classes.item}>Warehouse Email: {information?.warehouseEmail as string} </ListItem>
          </List>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          onClick={() => {
            onAction({
              slug: entitySlug,
              stage: 'handover',
              handoverDate: handoverDate,
            })
            onClose()
          }}
        >
          Manual
        </Button>
        <Button variant='contained' disabled={errors.length > 0} onClick={handleAutomatic}>
          {loading || loadingEmail ? <LoadingDots /> : 'Automatic'}
        </Button>
      </DialogActions>
    </>
  )
}

const ShipmentAutomatiHandoverContentSkeleton: React.FC<Props> = () => (
  <>
    <DialogContent>
      <List>
        <ListItem>
          <Skeleton variant='rect' height='20px' width='100%' />
        </ListItem>
        <ListItem>
          <Skeleton variant='rect' height='20px' width='100%' />
        </ListItem>
        <ListItem>
          <Skeleton variant='rect' height='20px' width='100%' />
        </ListItem>
      </List>
    </DialogContent>
    <DialogActions></DialogActions>
  </>
)

const query = graphql`
  query ShipmentAutomaticHandoverQuery($entityType: TemplateEntityTypeEnum!, $entitySlug: String!, $templateSlug: String!) {
    generatableDocumentValidation(entityType: $entityType, entitySlug: $entitySlug, templateSlug: $templateSlug) {
      templateSlug
      title
      docType
      errors
      handoverDatePresent
      information {
        handoverDate
        transporterName
        warehouseEmail
      }
    }
  }
`
const mutation = graphql`
  mutation ShipmentAutomaticHandoverMutation($input: infoAutomaticHandoverInput!) {
    infoAutomaticHandover(input: $input) {
      clientMutationId
    }
  }
`
const email_mutation = graphql`
  mutation ShipmentAutomaticHandoverEmailMutation($input: infoAutomaticHandoverEmailInput!) {
    infoAutomaticHandoverEmail(input: $input) {
      clientMutationId
    }
  }
`

export default ShipmentAutomaticHandover
