import { Typography } from '@material-ui/core'
import { graphql } from 'babel-plugin-relay/macro'
import React from 'react'
import styled from 'styled-components'
import Field from '../../components/Field'
import ResponsiveGrid from '../../components/ResponsiveGrid'
import { media } from '../../theme'
import useLazyLoadQueryWithSubscription from '../../hooks/useLazyLoadQueryWithSubscription'
import { ExternalHomeContentQuery } from '../../__generated__/ExternalHomeContentQuery.graphql'

interface Props {
  slug: string
}

const BorderedCard = styled.div`
  border: 1px solid ${(props) => props.theme.customPalette.border.card};
  border-radius: 8px;
  margin-bottom: 16px;
  padding: 16px;

  :last-child {
    margin-bottom: unset;
  }
`

const DetailsContainer = styled.div`
  margin: 4px 0;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 8px;

  ${media.medium`
    grid-template-columns: repeat(3, 1fr);
  `}

  ${media.small`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${media.mobile`
    grid-template-columns: repeat(1, 1fr);
  `}
`

const LongDetailsContainer = styled.div`
  margin: 4px 0;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 8px;

  ${media.medium`
    grid-template-columns: repeat(1, 1fr);
  `}

  ${media.small`
    grid-template-columns: repeat(1, 1fr);
  `}
`

const ExternalHomeContent: React.FC<Props> & { Skeleton: React.FC } = ({ slug }) => {
  const queryData = useLazyLoadQueryWithSubscription<ExternalHomeContentQuery>(
    query,
    { slug: slug },
    { subscriptionOptions: { entityType: 'contacts', entitySlug: slug } }
  )
  const { contactShow: contact } = queryData

  return (
    <ResponsiveGrid
      type='show-layout'
      highlight={[
        <BorderedCard key={0}>
          <Typography variant='h6'>Contact Details</Typography>
          <DetailsContainer>
            <Field label='Contact Form' value={contact.format} />
            <Field label='Company' value={contact.company} />
            <Field label='Address' value={contact.address} />
          </DetailsContainer>
          <DetailsContainer>
            <Field label='Postcode' value={contact.postcode} />
            <Field label='City' value={contact.place} />
            <Field label='State' value={contact.state} />
            <Field label='Position' value={contact.position} />
            <Field label='Business Phone' value={contact.businessPhone} />
            <Field label='Mobile Phone' value={contact.mobilePhone} />
            <Field label='Private Phone' value={contact.privatePhone} />
            <Field label='Fax Phone' value={contact.faxPhone} />
            <Field label='Language' value={contact.language} />
            <Field label='Country' value={contact.country} />
            <Field label='Website' value={contact.website} />
          </DetailsContainer>
          <LongDetailsContainer>
            <Field label='Secondary Emails' value={contact.secondaryEmails?.join(' | ')} />
          </LongDetailsContainer>
        </BorderedCard>,
      ]}
    />
  )
}

const query = graphql`
  query ExternalHomeContentQuery($slug: String!) {
    contactShow(slug: $slug) {
      slug
      name
      contactType
      businessPhone
      mobilePhone
      privatePhone
      faxPhone
      format
      company
      position
      secondaryEmails
      country
      state
      place
      postcode
      language
      address
      eori
      lexOfficeId
      vat
      vatWarehousing
      vatRevenues
      website
      freeWarehousingDays
      costWarehousingPerDay
      costShipmentDischarge
    }
  }
`

ExternalHomeContent.Skeleton = () => <>Loading...</>

export default ExternalHomeContent
