import { graphql } from 'babel-plugin-relay/macro'
import React from 'react'
import { useFragment } from 'react-relay/hooks'
import styled from 'styled-components'
import { FeedImages_data$key } from '../../__generated__/FeedImages_data.graphql'
interface Props {
  data: FeedImages_data$key
}

const SmallImageContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: 120px;
  grid-gap: 8px;
`

interface SmallImageProps {
  $src: string
  $overlay?: boolean
}

const SmallImage = styled.div<SmallImageProps>`
  position: relative;
  width: 100%;

  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('${(props) => props.$src}');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  & > span {
    color: white;
    font-size: 24px;
    font-weight: bold;
    z-index: 4;
  }

  &::after {
    content: '';
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    background-color: ${(props) => (props.$overlay ? 'rgba(0, 0, 0, 0.3)' : 'transparent')};
  }
`

const FeedImages: React.FC<Props> = ({ data }) => {
  const images = useFragment(imageFragment, data)

  return (
    <>
      {images && images.edges && images.edges.length > 0 && (
        <>
          <SmallImageContainer>
            {images.edges.map((edge) => edge && edge.node && <SmallImage key={edge?.node?.url as string} $src={edge?.node?.url as string} />)}
          </SmallImageContainer>
        </>
      )}
    </>
  )
}

const imageFragment = graphql`
  fragment FeedImages_data on ImageConnection {
    edges {
      node {
        url
        shape
        overlay
      }
    }
  }
`

export default FeedImages
