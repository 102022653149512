/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ActionEnum = "send_invite" | "%future added value";
export type ActionRole = "primary" | "secondary" | "%future added value";
export type Action_data = {
    readonly type: ActionEnum;
    readonly role: ActionRole | null;
    readonly cta: string | null;
    readonly " $refType": "Action_data";
};
export type Action_data$data = Action_data;
export type Action_data$key = {
    readonly " $data"?: Action_data$data;
    readonly " $fragmentRefs": FragmentRefs<"Action_data">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Action_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "role",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cta",
      "storageKey": null
    }
  ],
  "type": "Action",
  "abstractKey": null
};
(node as any).hash = 'aee6944ac5a5880c1bceef5bead96d75';
export default node;
