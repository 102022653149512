import React from 'react'
import styled from 'styled-components'

const Root = styled.div`
  padding: 8px 16px;
  background-color: ${(props) => props.theme.customPalette.background.statusChip};
  border-radius: 8px;
  color: ${(props) => props.theme.palette.primary.main};
  font-weight: bold;
  text-align: center;
`

const Status: React.FC<{ className?: string }> = ({ children, ...props }) => {
  return <Root {...props}>{children}</Root>
}

export default Status
