import get from 'lodash/get'
import React from 'react'

import styled from 'styled-components'
import { RendererOptions } from '../DataTable'
import { Typography } from '@material-ui/core'
import withInputRenderer, { Icon } from './RendererHoc'
import moment from 'moment'
import DateErrorIcon from '../../assets/public/v2/date_error.svg'

const StyledText = styled(Typography)<{ $hasError: boolean }>`
  font-size: inherit;
  color: ${(props) => (props.$hasError ? '#EB5757' : props.theme.palette.text.primary)};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

function DateRenderer<T extends any>({ identifier, rowData, icon, value }: RendererOptions<T>) {
  const data = value || get(rowData, identifier)
  const valueDate = data && moment(data).format('ll')
  const hasError = valueDate === 'Invalid date'

  return (
    <>
      {(icon || hasError) && <Icon src={hasError ? DateErrorIcon : icon} />}
      <StyledText title={data} $hasError={hasError}>
        {hasError ? '----/--/--' : value ? data : '----/--/--'}
      </StyledText>
    </>
  )
}

const DecoratedDateRenderer = withInputRenderer({ type: 'date' })(DateRenderer)
// @ts-ignore
DecoratedDateRenderer.operators = ['eq', 'neq', 'is_null', 'not_null', 'gt', 'gteq', 'lt', 'lteq', 'between']

export default DecoratedDateRenderer
