/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LiveShareEntityAttachment_attachment = {
    readonly url: string;
    readonly downloadUrl: string;
    readonly name: string;
    readonly contentType: string;
    readonly previewUrl: string | null;
    readonly documentType: string | null;
    readonly " $refType": "LiveShareEntityAttachment_attachment";
};
export type LiveShareEntityAttachment_attachment$data = LiveShareEntityAttachment_attachment;
export type LiveShareEntityAttachment_attachment$key = {
    readonly " $data"?: LiveShareEntityAttachment_attachment$data;
    readonly " $fragmentRefs": FragmentRefs<"LiveShareEntityAttachment_attachment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LiveShareEntityAttachment_attachment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "downloadUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contentType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "previewUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "documentType",
      "storageKey": null
    }
  ],
  "type": "LiveShareEntityAttachment",
  "abstractKey": null
};
(node as any).hash = '7a1936931ffb664015c256d44433c88b';
export default node;
