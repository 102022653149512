import { Paper, Card, Typography, InputBase, IconButton, Tooltip, DialogActions, DialogTitle } from '@material-ui/core'
import { graphql } from 'babel-plugin-relay/macro'
import { useSnackbar } from 'notistack'
import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import useNiceMutation from '../mutations/useNiceMutation'
import { NotesAddMutation, NoteEntityTypeEnum } from '../__generated__/NotesAddMutation.graphql'
import Button from './Button'
import { useLazyLoadQuery, useRelayEnvironment } from 'react-relay/hooks'
import { NotesQuery } from '../__generated__/NotesQuery.graphql'
import { NoteOutlined, Note as NoteFilled } from '@material-ui/icons'
import { fetchQuery } from 'react-relay'
import LoadingDots from './LoadingDots'
import { Alert, Skeleton } from '@material-ui/lab'
import useWorkspaceNavigate from '../hooks/useWorkspaceNavigate'
import { NoteTabQueryResponse } from '../__generated__/NoteTabQuery.graphql'
import { NotesToggleMutation } from '../__generated__/NotesToggleMutation.graphql'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import OdysseyDialog from './OdysseyDialog'
import { NotesDeleteMutation } from '../__generated__/NotesDeleteMutation.graphql'
import { Delete } from '@material-ui/icons'
import SessionContext from '../SessionContext'
import { useDateTimeDisplay } from '../hooks/timeZoneDisplay'

interface Props {
  entitySlug: string
  entityType: NoteEntityTypeEnum
  showLast?: boolean
}

interface ShowNotesProps {
  entitySlug: string
  entityType: NoteEntityTypeEnum
  notes: NoteTabQueryResponse['noteList']
}

interface DeleteNoteProps {
  dialogOpen: boolean
  slug: string | null
  onClose: () => void
  entitySlug: string
  entityType: NoteEntityTypeEnum
  queryType: string
}

const Root = styled(Paper)`
  padding: 16px;
  margin-bottom: 16px;
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`

const NoteContainer = styled.div`
  display: grid;
  grid-gap: 8px;
  margin-top: 8px;
`

const NotesContainer = styled.div`
  display: grid;
  grid-column-template: auto auto;
  grid-gap: 8px;
  grid-template-columns: repeat(2, 1fr);
`

const Note = styled(Card)`
  width: 100%;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const NoteContent = styled(Typography)`
  white-space: pre-wrap;
`

const NoteFooter = styled.div`
  margin-top: 4px;
  display: flex;
  justify-content: space-between;
`

const InputActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const InputContainer = styled.div<{ $isSticky: boolean; $isAdding: boolean; $contactType: string }>`
  padding: 8px 16px;
  border: 1px solid ${(props) => props.theme.customPalette.border.card};
  border-radius: 8px;
  background-color: ${(props) =>
    props.$isSticky ? (props.$contactType == 'internal' ? props.theme.customPalette.background.stickyNote : '#bdf0d1') : 'unset'};
  box-shadow: ${(props) => (props.$isAdding ? props.theme.shadows[1] : 'unset')};
`
interface StyledNoteProps {
  contactType?: string
}

const NoteFilledColoured = styled(NoteFilled)<StyledNoteProps>`
  color: ${(props) => (props.contactType == 'internal' ? props.theme.customPalette.background.stickyNote : '#bdf0d1')};
`
const NoteFooterText = styled(Typography)`
  flex: 1;
  text-align: right;
`

const AddNotes: React.FC<Props> & { Skeleton: React.FC } = ({ entitySlug, entityType, showLast = true }) => {
  const { user } = useContext(SessionContext)
  const data = useLazyLoadQuery<NotesQuery>(query, { entityType: entityType, entitySlug: entitySlug })
  const [commit, isInFlight] = useNiceMutation<NotesAddMutation>(mutation)
  const { enqueueSnackbar } = useSnackbar()

  const [note, setNote] = useState('')
  const [isSticky, setIsSticky] = useState(false)
  const [isAdding, setIsAdding] = useState(false)

  const environment = useRelayEnvironment()
  const refresh = () => {
    fetchQuery(environment, query, { entityType: entityType, entitySlug: entitySlug })
  }
  const wsNavigate = useWorkspaceNavigate()

  const onSave = () => {
    commit({
      variables: {
        input: {
          entitySlug: entitySlug,
          entityType: entityType,
          isSticky: isSticky,
          content: note,
        },
      },
      onCompleted: (_res, errors) => {
        if (!errors) {
          enqueueSnackbar('Note saved', { variant: 'success' })
          refresh()
          cancelAddMode()
        }
      },
    })
  }

  const cancelAddMode = () => {
    setIsAdding(false)
    setNote('')
    setIsSticky(false)
  }

  return (
    <Root variant='outlined'>
      {showLast && (
        <TitleContainer>
          <Typography variant='h6'>Notes</Typography>
          <Button
            onClick={() =>
              wsNavigate(
                wsNavigate.location
                  .split('/')
                  .slice(0, -1)
                  .filter((e) => e !== wsNavigate.workspace)
                  .concat('notes')
                  .join('/')
              )
            }
          >
            See All
          </Button>
        </TitleContainer>
      )}
      <InputContainer $isSticky={isSticky} $isAdding={isAdding} $contactType={user?.contact?.contactType as string}>
        <InputBase
          fullWidth
          multiline
          placeholder='Take a note..'
          onFocus={() => setIsAdding(true)}
          onChange={(e) => setNote(e.target.value)}
          value={note}
        />
        {isAdding && (
          <InputActionsContainer>
            <Tooltip title={isSticky ? 'Make Regular' : 'Make Sticky'} placement='top' interactive>
              <IconButton onClick={() => setIsSticky((prevState) => !prevState)}>{isSticky ? <NoteFilled /> : <NoteOutlined />}</IconButton>
            </Tooltip>
            <Button onClick={cancelAddMode}>Cancel</Button>
            <Button onClick={onSave} disabled={isInFlight}>
              {isInFlight ? <LoadingDots variant='primary' /> : 'Save'}
            </Button>
          </InputActionsContainer>
        )}
      </InputContainer>
      {showLast && (
        <NoteContainer>
          {data.noteList.map(
            (note) =>
              note && (
                <Note key={note.slug} variant='outlined'>
                  <NoteContent variant='body1'>{note.content}</NoteContent>
                  <NoteFooter>
                    {note.isSticky ? <NoteFilledColoured contactType={user?.contact?.contactType as string} /> : <></>}
                    <NoteFooterText variant='caption' color='textSecondary'>
                      {note.addedBy.username} {useDateTimeDisplay(note.addedAt)}
                    </NoteFooterText>
                  </NoteFooter>
                </Note>
              )
          )}
        </NoteContainer>
      )}
    </Root>
  )
}

const ShowNotes: React.FC<ShowNotesProps> & { Skeleton: React.FC } = ({ notes, entityType, entitySlug }) => {
  const [commit, loading] = useNiceMutation<NotesToggleMutation>(toggleStick)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [noteSlug, setNoteSlug] = useState<string | null>(null)
  const { enqueueSnackbar } = useSnackbar()
  const environment = useRelayEnvironment()
  const { user } = useContext(SessionContext)
  const isSuperAdmin = Boolean(user?.contact?.displayRoles.find((role) => role.value === 'super-admin'))

  const refresh = () => {
    fetchQuery(environment, query, { entityType: entityType, entitySlug: entitySlug })
  }
  const onRemove = (slug: string, isSticky: boolean) => {
    const text = isSticky ? 'Sticky note removed' : 'Sticky node added'
    commit({
      variables: { input: { slug: slug, isSticky: !isSticky } },
      onCompleted: (_, err) => {
        if (!err) {
          enqueueSnackbar(`${text} successfully`, { variant: 'success' })
          refresh()
        }
      },
    })
  }

  const openHandle = (slug: string) => {
    setDialogOpen(true)
    setNoteSlug(slug)
  }

  const closeHandle = () => {
    setDialogOpen(false)
    setNoteSlug(null)
  }

  return (
    <>
      <NotesContainer>
        <DeleteNote
          slug={noteSlug}
          dialogOpen={dialogOpen}
          onClose={() => closeHandle()}
          entityType={entityType}
          entitySlug={entitySlug}
          queryType='internal'
        />
        {notes.map(
          (note) =>
            note && (
              <>
                <Note key={note.slug} variant='outlined'>
                  <NoteContent variant='body1'>{note.content}</NoteContent>
                  <NoteFooter>
                    {loading ? (
                      <LoadingDots />
                    ) : (
                      <>
                        <Tooltip title={note.isSticky ? 'Remove as Sticky Note' : 'Add as Sticky Note'} placement='top' interactive>
                          <IconButton onClick={() => onRemove(note.slug, note.isSticky)}>
                            {note.isSticky ? <RemoveCircleIcon /> : <NoteOutlined />}
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                    {isSuperAdmin && (
                      <Tooltip title='Delete' placement='top' interactive>
                        <IconButton onClick={() => openHandle(note.slug)}>
                          <Delete fontSize='small' />
                        </IconButton>
                      </Tooltip>
                    )}
                    <NoteFooterText variant='caption' color='textSecondary'>
                      {note.addedBy.username} {note.addedAt}
                    </NoteFooterText>
                  </NoteFooter>
                </Note>
              </>
            )
        )}
      </NotesContainer>
    </>
  )
}

const DeleteNote: React.FC<DeleteNoteProps> = ({ dialogOpen, slug, onClose, entityType, entitySlug, queryType }) => {
  const [commitDelete, deleteIsInFlight, deleteMutationError] = useNiceMutation<NotesDeleteMutation>(deleteNoteMutation)
  const environment = useRelayEnvironment()
  const reloadQuery = queryType == 'internal' ? query : externalQuery
  const refresh = () => {
    fetchQuery(environment, reloadQuery, { entityType: entityType, entitySlug: entitySlug })
  }
  const { enqueueSnackbar } = useSnackbar()
  const handleDelete = () => {
    commitDelete({
      variables: {
        input: {
          slug: slug as string,
        },
      },
      onCompleted: (_, errors) => {
        if (!errors) {
          enqueueSnackbar('Note Deleted Successfully', { variant: 'success' })
          refresh()
          onClose()
        }
      },
    })
  }
  return (
    <>
      <OdysseyDialog open={dialogOpen} onClose={onClose}>
        <DialogTitle>This action will delete the note permanently, are you sure?</DialogTitle>
        {deleteMutationError && <Alert severity='error'>{deleteMutationError}</Alert>}
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button variant='contained' onClick={() => handleDelete()}>
            {deleteIsInFlight ? <LoadingDots /> : 'Delete'}
          </Button>
        </DialogActions>
      </OdysseyDialog>
    </>
  )
}

const toggleStick = graphql`
  mutation NotesToggleMutation($input: infoToggleStickyNoteInput!) {
    infoToggleStickyNote(input: $input) {
      clientMutationId
    }
  }
`

const mutation = graphql`
  mutation NotesAddMutation($input: infoAddNoteInput!) {
    infoAddNote(input: $input) {
      clientMutationId
    }
  }
`

const deleteNoteMutation = graphql`
  mutation NotesDeleteMutation($input: infoDeleteNoteInput!) {
    infoDeleteNote(input: $input) {
      clientMutationId
    }
  }
`

const query = graphql`
  query NotesQuery($entityType: NoteEntityTypeEnum!, $entitySlug: String!) {
    noteList(entityType: $entityType, entitySlug: $entitySlug) {
      slug
      content
      addedAt
      isSticky
      addedBy {
        username
      }
    }
  }
`
const externalQuery = graphql`
  query NotesExternalQuery($entityType: NoteEntityTypeEnum!, $entitySlug: String!) {
    externalNoteList(entityType: $entityType, entitySlug: $entitySlug) {
      slug
      content
      addedAt
      isSticky
      addedBy {
        username
      }
    }
  }
`

AddNotes.Skeleton = () => (
  <Root variant='outlined'>
    <TitleContainer>
      <Typography variant='h6'>Notes</Typography>
      <Button>See All</Button>
    </TitleContainer>
    <Skeleton variant='rect' height='50px' />
  </Root>
)

ShowNotes.Skeleton = () => <>Loading...</>

export { AddNotes, ShowNotes, DeleteNote }
