/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ExternalShowItemTypeEnum = "contact" | "container" | "shipment" | "%future added value";
export type ExternalShipmentShowHeroCardQueryVariables = {
    slug: string;
    itemType: ExternalShowItemTypeEnum;
};
export type ExternalShipmentShowHeroCardQueryResponse = {
    readonly externalItemShow: {
        readonly slug: string;
        readonly title: string;
        readonly stickyNoteContentByExternal: string | null;
        readonly image: {
            readonly url: string | null;
            readonly " $fragmentRefs": FragmentRefs<"ItemImage_data">;
        } | null;
        readonly badges: ReadonlyArray<{
            readonly key: string;
            readonly " $fragmentRefs": FragmentRefs<"Badge_data">;
        }> | null;
        readonly keyFields: ReadonlyArray<{
            readonly key: string;
            readonly " $fragmentRefs": FragmentRefs<"KeyField_data">;
        }> | null;
        readonly lines: ReadonlyArray<{
            readonly key: string;
            readonly " $fragmentRefs": FragmentRefs<"SemanticLine_data">;
        }> | null;
    };
    readonly externalShipmentShow: {
        readonly slug: string;
        readonly isArchived: boolean;
        readonly stage: string;
        readonly shipmentKey: string;
        readonly subStages: ReadonlyArray<{
            readonly stageIdx: number | null;
            readonly " $fragmentRefs": FragmentRefs<"SubStage_data">;
        }>;
        readonly allShipments: ReadonlyArray<{
            readonly slug: string;
            readonly shipmentKey: string;
            readonly description: string;
            readonly shipmentType: string;
            readonly vin: string | null;
        }> | null;
        readonly container: {
            readonly slug: string;
            readonly containerNumber: string;
            readonly imageUrl: string;
        };
        readonly watches: {
            readonly " $fragmentRefs": FragmentRefs<"Watchers_watches">;
        };
        readonly " $fragmentRefs": FragmentRefs<"ExternalShipmentWorkflow_shipment">;
    };
};
export type ExternalShipmentShowHeroCardQuery = {
    readonly response: ExternalShipmentShowHeroCardQueryResponse;
    readonly variables: ExternalShipmentShowHeroCardQueryVariables;
};



/*
query ExternalShipmentShowHeroCardQuery(
  $slug: String!
  $itemType: ExternalShowItemTypeEnum!
) {
  externalItemShow(slug: $slug, type: $itemType) {
    slug
    title
    stickyNoteContentByExternal
    image {
      url
      ...ItemImage_data
    }
    badges {
      key
      ...Badge_data
    }
    keyFields {
      key
      ...KeyField_data
    }
    lines {
      key
      ...SemanticLine_data
    }
    id
  }
  externalShipmentShow(slug: $slug) {
    slug
    isArchived
    stage
    shipmentKey
    subStages {
      stageIdx
      ...SubStage_data
    }
    allShipments {
      slug
      shipmentKey
      description
      shipmentType
      vin
      id
    }
    container {
      slug
      containerNumber
      imageUrl
      id
    }
    watches {
      ...Watchers_watches
    }
    ...ExternalShipmentWorkflow_shipment
    id
  }
}

fragment Badge_data on Badge {
  type
}

fragment ExternalShipmentWorkflow_shipment on ExternalShipment {
  slug
  workflowStages {
    value
    label
    ownedBy
    status
    ...ExternalStage_stage
  }
}

fragment ExternalStage_stage on WorkflowStage {
  value
  status
  label
  completedAt
  ownedBy
}

fragment ItemImage_data on Image {
  url
  alt
  highlight
  shape
  overlay
  overlayIcon
}

fragment KeyField_data on KeyField {
  label
  value {
    key
    ...SemanticString_data
  }
}

fragment SemanticLine_data on SemanticLine {
  parts {
    key
    ...SemanticString_data
  }
}

fragment SemanticString_data on SemanticString {
  text
}

fragment SubStage_data on SubStage {
  stageIdx
  stage
  value
  displayValue
  status
  label
  validSubStages {
    value
    displayValue
  }
}

fragment Watchers_watches on ItemWatchConnection {
  nodes {
    contactSlug
    contactItem {
      title
      image {
        url
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "itemType"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "slug"
},
v2 = {
  "kind": "Variable",
  "name": "slug",
  "variableName": "slug"
},
v3 = [
  (v2/*: any*/),
  {
    "kind": "Variable",
    "name": "type",
    "variableName": "itemType"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stickyNoteContentByExternal",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v9 = [
  (v2/*: any*/)
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isArchived",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stage",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shipmentKey",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stageIdx",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shipmentType",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "vin",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "containerNumber",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageUrl",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v20 = [
  (v8/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "text",
    "storageKey": null
  }
],
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayValue",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ExternalShipmentShowHeroCardQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "ExternalListItem",
        "kind": "LinkedField",
        "name": "externalItemShow",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Image",
            "kind": "LinkedField",
            "name": "image",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ItemImage_data"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Badge",
            "kind": "LinkedField",
            "name": "badges",
            "plural": true,
            "selections": [
              (v8/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "Badge_data"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "KeyField",
            "kind": "LinkedField",
            "name": "keyFields",
            "plural": true,
            "selections": [
              (v8/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "KeyField_data"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SemanticLine",
            "kind": "LinkedField",
            "name": "lines",
            "plural": true,
            "selections": [
              (v8/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SemanticLine_data"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v9/*: any*/),
        "concreteType": "ExternalShipment",
        "kind": "LinkedField",
        "name": "externalShipmentShow",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SubStage",
            "kind": "LinkedField",
            "name": "subStages",
            "plural": true,
            "selections": [
              (v13/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SubStage_data"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ExternalShipment",
            "kind": "LinkedField",
            "name": "allShipments",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v12/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ExternalContainer",
            "kind": "LinkedField",
            "name": "container",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ItemWatchConnection",
            "kind": "LinkedField",
            "name": "watches",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "Watchers_watches"
              }
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ExternalShipmentWorkflow_shipment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ExternalShipmentShowHeroCardQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "ExternalListItem",
        "kind": "LinkedField",
        "name": "externalItemShow",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Image",
            "kind": "LinkedField",
            "name": "image",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "alt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "highlight",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "shape",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "overlay",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "overlayIcon",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Badge",
            "kind": "LinkedField",
            "name": "badges",
            "plural": true,
            "selections": [
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "KeyField",
            "kind": "LinkedField",
            "name": "keyFields",
            "plural": true,
            "selections": [
              (v8/*: any*/),
              (v19/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SemanticString",
                "kind": "LinkedField",
                "name": "value",
                "plural": true,
                "selections": (v20/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SemanticLine",
            "kind": "LinkedField",
            "name": "lines",
            "plural": true,
            "selections": [
              (v8/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SemanticString",
                "kind": "LinkedField",
                "name": "parts",
                "plural": true,
                "selections": (v20/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v21/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v9/*: any*/),
        "concreteType": "ExternalShipment",
        "kind": "LinkedField",
        "name": "externalShipmentShow",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SubStage",
            "kind": "LinkedField",
            "name": "subStages",
            "plural": true,
            "selections": [
              (v13/*: any*/),
              (v11/*: any*/),
              (v22/*: any*/),
              (v23/*: any*/),
              (v24/*: any*/),
              (v19/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ValidSubStage",
                "kind": "LinkedField",
                "name": "validSubStages",
                "plural": true,
                "selections": [
                  (v22/*: any*/),
                  (v23/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ExternalShipment",
            "kind": "LinkedField",
            "name": "allShipments",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v12/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v21/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ExternalContainer",
            "kind": "LinkedField",
            "name": "container",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v21/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ItemWatchConnection",
            "kind": "LinkedField",
            "name": "watches",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ItemWatch",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "contactSlug",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ListItem",
                    "kind": "LinkedField",
                    "name": "contactItem",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Image",
                        "kind": "LinkedField",
                        "name": "image",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v21/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "WorkflowStage",
            "kind": "LinkedField",
            "name": "workflowStages",
            "plural": true,
            "selections": [
              (v22/*: any*/),
              (v19/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ownedBy",
                "storageKey": null
              },
              (v24/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "completedAt",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v21/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8dc24b346d1280c3d03af1a698d533ad",
    "id": null,
    "metadata": {},
    "name": "ExternalShipmentShowHeroCardQuery",
    "operationKind": "query",
    "text": "query ExternalShipmentShowHeroCardQuery(\n  $slug: String!\n  $itemType: ExternalShowItemTypeEnum!\n) {\n  externalItemShow(slug: $slug, type: $itemType) {\n    slug\n    title\n    stickyNoteContentByExternal\n    image {\n      url\n      ...ItemImage_data\n    }\n    badges {\n      key\n      ...Badge_data\n    }\n    keyFields {\n      key\n      ...KeyField_data\n    }\n    lines {\n      key\n      ...SemanticLine_data\n    }\n    id\n  }\n  externalShipmentShow(slug: $slug) {\n    slug\n    isArchived\n    stage\n    shipmentKey\n    subStages {\n      stageIdx\n      ...SubStage_data\n    }\n    allShipments {\n      slug\n      shipmentKey\n      description\n      shipmentType\n      vin\n      id\n    }\n    container {\n      slug\n      containerNumber\n      imageUrl\n      id\n    }\n    watches {\n      ...Watchers_watches\n    }\n    ...ExternalShipmentWorkflow_shipment\n    id\n  }\n}\n\nfragment Badge_data on Badge {\n  type\n}\n\nfragment ExternalShipmentWorkflow_shipment on ExternalShipment {\n  slug\n  workflowStages {\n    value\n    label\n    ownedBy\n    status\n    ...ExternalStage_stage\n  }\n}\n\nfragment ExternalStage_stage on WorkflowStage {\n  value\n  status\n  label\n  completedAt\n  ownedBy\n}\n\nfragment ItemImage_data on Image {\n  url\n  alt\n  highlight\n  shape\n  overlay\n  overlayIcon\n}\n\nfragment KeyField_data on KeyField {\n  label\n  value {\n    key\n    ...SemanticString_data\n  }\n}\n\nfragment SemanticLine_data on SemanticLine {\n  parts {\n    key\n    ...SemanticString_data\n  }\n}\n\nfragment SemanticString_data on SemanticString {\n  text\n}\n\nfragment SubStage_data on SubStage {\n  stageIdx\n  stage\n  value\n  displayValue\n  status\n  label\n  validSubStages {\n    value\n    displayValue\n  }\n}\n\nfragment Watchers_watches on ItemWatchConnection {\n  nodes {\n    contactSlug\n    contactItem {\n      title\n      image {\n        url\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'a9c01b29d22a88cd691102e1a0192b46';
export default node;
