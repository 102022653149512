import React from 'react'
import styled from 'styled-components'
import { Tooltip, Typography, DialogActions } from '@material-ui/core'
import { useFormikContext } from 'formik'
import Button from '../Button'
import InfoIcon from '@material-ui/icons/Info'
import LoadingDots from '../LoadingDots'

interface Props {
  title?: string
  onCancel?: () => void
  onSubmit?: () => void
  cancelCta?: string
  saveCta?: string
}

interface SkeletonProps {
  cancelCta?: string
  saveCta?: string
  onCancel?: () => void
}

const StyledDialogActions = styled(DialogActions)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const DialogFormActionBar: React.FC<Props> & { Skeleton: React.FC<SkeletonProps> } = ({ title, onSubmit, onCancel, cancelCta, saveCta }) => {
  const { setTouched, isSubmitting, dirty, errors, touched } = useFormikContext()
  const [open, setOpen] = React.useState(false)

  const handleSubmit = () => {
    setTouched(getTouchedObj(errors))
    if (Object.keys(errors).length === 0) {
      if (onSubmit) onSubmit()
    } else {
      setOpen(true)
    }
  }

  const getTouchedObj = (errors: any) => {
    const tmp_touched: any = touched
    Object.keys(errors).forEach((key) => {
      if (Array.isArray(errors[key])) {
        errors[key].forEach((val: any, index: any) => {
          if (index === 0) tmp_touched[key] = []
          tmp_touched[key].push(getTouchedObj(val))
        })
      } else {
        tmp_touched[key] = true
      }
    })

    return tmp_touched
  }

  return (
    <>
      <StyledDialogActions>
        <Typography variant='h6'>
          {title}
          {Object.keys(errors).length !== 0 && open && (
            <>
              <Tooltip
                placement='top'
                title={
                  <h2>
                    <pre>{Object.values(errors).join('\n')}</pre>
                  </h2>
                }
                interactive
              >
                <Button>
                  <>
                    <InfoIcon style={{ color: 'red', padding: '0 2px' }} />{' '}
                    <Typography style={{ color: 'red' }}>{Object.keys(errors).length} errors found</Typography>
                  </>
                </Button>
              </Tooltip>
            </>
          )}
        </Typography>
        <div>
          <Button onClick={onCancel}>{cancelCta || 'Cancel'}</Button>
          <Button onClick={handleSubmit} disabled={isSubmitting || !dirty} variant='contained'>
            {isSubmitting ? <LoadingDots variant='dark' /> : saveCta || 'Save'}
          </Button>
        </div>
      </StyledDialogActions>
    </>
  )
}

DialogFormActionBar.Skeleton = ({ saveCta, cancelCta, onCancel }) => (
  <StyledDialogActions>
    <div></div>
    <div>
      <Button onClick={() => onCancel && onCancel()}>{cancelCta || 'Cancel'}</Button>
      <Button variant='contained' disabled>
        {saveCta || 'Save'}
      </Button>
    </div>
  </StyledDialogActions>
)

export default DialogFormActionBar
