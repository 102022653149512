/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type BadgeEnum = "boat" | "car" | "export_container" | "foreign_container" | "incoming" | "inspection_container" | "motor" | "oldtimer" | "other_shipment" | "outgoing" | "package" | "quad" | "roro_container" | "staff" | "stock_container" | "transloading_container" | "truck" | "truck_container" | "%future added value";
export type Badge_data = {
    readonly type: BadgeEnum;
    readonly " $refType": "Badge_data";
};
export type Badge_data$data = Badge_data;
export type Badge_data$key = {
    readonly " $data"?: Badge_data$data;
    readonly " $fragmentRefs": FragmentRefs<"Badge_data">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Badge_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "type": "Badge",
  "abstractKey": null
};
(node as any).hash = 'af4aa6db50c7752bf82e90adbcfb6cf5';
export default node;
