import React from 'react'
import { graphql } from 'babel-plugin-relay/macro'

import { useLazyLoadQuery } from 'react-relay/hooks'
import NiceFormik from '../../components/Form/NiceFormik'
import useNiceMutation from '../../mutations/useNiceMutation'
import FormActionBar from '../../components/Form/FormActionBar'
import ContainerForm from '../../forms/Container/ContainerForm'
import { ExternalContainerEditFormQuery } from '../../__generated__/ExternalContainerEditFormQuery.graphql'
import { ExternalContainerEditMutation } from '../../__generated__/ExternalContainerEditMutation.graphql'
import { inputHashToLinkedContacts, linkedContactToInputHash } from '../../components/Form/LinkedContactsField'
import FormContainer from '../../forms/FormContainer'

interface Props {
  slug: string
  onClose: () => void
}

const ExternalContainerEdit: React.FC<Props> & { Skeleton: React.FC } = ({ slug, onClose }) => {
  const data = useLazyLoadQuery<ExternalContainerEditFormQuery>(query, { slug: slug })
  const fields = data.infoUpdateContainerForm.formSchema.fieldSet
  const fieldMaster = Object.fromEntries(fields.map((field) => [field.name, field]))

  const [commit] = useNiceMutation<ExternalContainerEditMutation>(mutation)
  const initialValue = {
    ...data.infoUpdateContainerForm.initialValue,
    linkedContacts: linkedContactToInputHash(data.infoUpdateContainerForm.initialValue?.linkedContacts?.nodes),
  }

  return (
    <>
      <NiceFormik
        initialValues={initialValue || {}}
        onSubmit={(values, actions) => {
          const processedValues = {
            ...values,
            linkedContacts: inputHashToLinkedContacts((values as any).linkedContacts),
          }
          commit({
            variables: {
              input: processedValues as any,
            },
            onError: () => actions.setSubmitting(false),
            onCompleted: (res, errors) => {
              actions.setSubmitting(false)
              if (!errors) {
                onClose()
              }
            },
          })
        }}
        formSchema={fields}
      >
        {({ submitForm, values }) => (
          <FormContainer>
            <ContainerForm fieldMaster={fieldMaster} mode='edit' />
            <FormActionBar
              title={`Editing container ${(values as any).containerNumber}`}
              onCancel={() => onClose()}
              onSubmit={() => submitForm()}
            />
          </FormContainer>
        )}
      </NiceFormik>
    </>
  )
}

const query = graphql`
  query ExternalContainerEditFormQuery($slug: String!) {
    infoUpdateContainerForm(slug: $slug) {
      formSchema {
        fieldSet {
          name
          type
          required
          title
          description
          format
          enum
          enumTitles
          placeholder
        }
      }
      initialValue {
        slug
        containerNumber
        containerSize
        profileImageSlug
        voyageSlug
        containerType
        atb
        bookingNumber
        pickupLocationSlug
        turninLocationSlug
        terminalDate
        dischargeDate
        latestPickupDate
        pickupDate
        thcPaidDate
        latestTurninDate
        pickupReference
        turninReference
        billOfLadingNumber
        shippingLineSlug
        linkedContacts {
          nodes {
            role
            contactSlug
          }
        }
      }
    }
  }
`

const mutation = graphql`
  mutation ExternalContainerEditMutation($input: infoUpdateContainerInput!) {
    infoUpdateContainer(input: $input) {
      container {
        slug
      }
    }
  }
`

ExternalContainerEdit.Skeleton = () => (
  <>
    <ContainerForm.Skeleton />
    <FormActionBar.Skeleton />
  </>
)

export default ExternalContainerEdit
