import React, { Suspense } from 'react'
import OdysseyDialog from '../OdysseyDialog'
import { Skeleton } from '@material-ui/lab'
import { DialogContent, DialogTitle, DialogActions, ListItem } from '@material-ui/core'
import List from '@material-ui/core/List'
import NiceFormik from '../../components/Form/NiceFormik'
import { useLazyLoadQuery } from 'react-relay/hooks'
import useNiceMutation from '../../mutations/useNiceMutation'
import { inputHashToLinkedContacts, linkedContactToInputHash } from '../../components/Form/LinkedContactsField'
import { graphql } from 'babel-plugin-relay/macro'
import { DetailsFieldsContainer } from '../../forms/Container/ContainerForm'
import { ContainerReleaseConfirmFormQuery } from '../../__generated__/ContainerReleaseConfirmFormQuery.graphql'
import { ContainerReleaseConfirmMutation } from '../../__generated__/ContainerReleaseConfirmMutation.graphql'
import InputField from '../Form/InputField'
import Button from '../Button'
import { Form } from 'formik'
import LoadingDots from '../LoadingDots'
import { ContainerReleaseConfirmStageChangeMutation } from '../../__generated__/ContainerReleaseConfirmStageChangeMutation.graphql'
import styled from 'styled-components'
import { media } from '../../theme'

interface Props {
  open: boolean
  slug: string
  onClose: () => void
  onSubmit?: (values: any) => void
}

const BookingNumberField = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 16px;
  margin-bottom: 16px;
  margin-top: 16px;
  ${media.small`
    grid-template-columns: auto;
  `}
`

const ContainerReleaseConfirm: React.FC<Props> = (props) => {
  return (
    <>
      <OdysseyDialog open={props.open} onClose={props.onClose}>
        <DialogTitle></DialogTitle>
        <Suspense fallback={<ContainerReleaseConfirmSkeleton {...props} />}>
          <ContainerReleaseConfirmContent {...props}></ContainerReleaseConfirmContent>
        </Suspense>
      </OdysseyDialog>
    </>
  )
}
const ContainerReleaseConfirmContent: React.FC<Props> = ({ onSubmit, onClose, slug }) => {
  const data = useLazyLoadQuery<ContainerReleaseConfirmFormQuery>(query, { slug: slug })
  const fields = data.infoUpdateContainerForm.formSchema.fieldSet
  const fieldMaster = Object.fromEntries(fields.map((field) => [field.name, field]))

  const [commit, loading] = useNiceMutation<ContainerReleaseConfirmMutation>(mutation)
  const [commitStageChange] = useNiceMutation<ContainerReleaseConfirmStageChangeMutation>(changeStageMutation)

  const initialValue = {
    ...data.infoUpdateContainerForm.initialValue,
    linkedContacts: linkedContactToInputHash(data.infoUpdateContainerForm.initialValue?.linkedContacts?.nodes),
  }
  const handleStageChange = () => {
    commitStageChange({
      variables: {
        input: {
          slug: slug,
          stage: 'release_confirmed',
        },
      },
      onCompleted: (res, errors) => {
        if (!errors) {
          onClose()
        }
      },
    })
  }
  return (
    <>
      <NiceFormik
        initialValues={initialValue || {}}
        onSubmit={(values, actions) => {
          const processedValues = {
            ...values,
            linkedContacts: inputHashToLinkedContacts((values as any).linkedContacts),
          }

          if (onSubmit) {
            onSubmit(processedValues)
            return
          }

          commit({
            variables: {
              input: processedValues as any,
            },
            onError: () => actions.setSubmitting(false),
            onCompleted: (res, errors) => {
              actions.setSubmitting(false)
              if (!errors) {
                handleStageChange()
              }
            },
          })
        }}
        formSchema={fields}
      >
        <Form>
          <DialogContent>
            <BookingNumberField>
              <InputField of='bookingNumber' field={fieldMaster['bookingNumber']} />
            </BookingNumberField>
            <DetailsFieldsContainer>
              <InputField of='pickupReference' field={fieldMaster['pickupReference']} />
              <InputField of='turninReference' field={fieldMaster['turninReference']} />
              <InputField of='pickupLocationSlug' field={fieldMaster['pickupLocationSlug']} />
              <InputField of='turninLocationSlug' field={fieldMaster['turninLocationSlug']} />
              <InputField of='latestPickupDate' field={fieldMaster['latestPickupDate']} />
              <InputField of='latestTurninDate' field={fieldMaster['latestTurninDate']} />
            </DetailsFieldsContainer>
          </DialogContent>
          <DialogActions>
            <Button variant='contained' onClick={() => onClose()}>
              Cancel
            </Button>
            <Button variant='contained' type='submit'>
              {loading ? <LoadingDots /> : 'Save'}
            </Button>
          </DialogActions>
        </Form>
      </NiceFormik>
    </>
  )
}

const ContainerReleaseConfirmSkeleton: React.FC<Props> = () => (
  <>
    <DialogContent>
      <List>
        <ListItem>
          <Skeleton variant='rect' height='20px' width='100%' />
        </ListItem>
        <ListItem>
          <Skeleton variant='rect' height='20px' width='100%' />
        </ListItem>
      </List>
    </DialogContent>
  </>
)

const query = graphql`
  query ContainerReleaseConfirmFormQuery($slug: String!) {
    infoUpdateContainerForm(slug: $slug) {
      formSchema {
        fieldSet {
          name
          type
          required
          title
          description
          format
          enum
          enumTitles
          placeholder
        }
      }
      initialValue {
        slug
        containerNumber
        voyageSlug
        containerType
        bookingNumber
        pickupLocationSlug
        turninLocationSlug
        pickupReference
        turninReference
        latestPickupDate
        latestTurninDate
        shippingLineSlug
        linkedContacts {
          nodes {
            role
            contactSlug
          }
        }
      }
    }
  }
`

const mutation = graphql`
  mutation ContainerReleaseConfirmMutation($input: infoUpdateContainerInput!) {
    infoUpdateContainer(input: $input) {
      clientMutationId
    }
  }
`
const changeStageMutation = graphql`
  mutation ContainerReleaseConfirmStageChangeMutation($input: infoChangeContainerStageInput!) {
    infoChangeContainerStage(input: $input) {
      clientMutationId
    }
  }
`
export default ContainerReleaseConfirm
