import { graphql } from 'babel-plugin-relay/macro'
import React, { useState } from 'react'
import { useFragment } from 'react-relay/hooks'
import { ExternalItemListFilters_data$key } from '../../__generated__/ExternalItemListFilters_data.graphql'
import { ItemType } from '../../viewTypes'
import ListPageFilter from '../../components/ListPageFilter'
interface Props {
  listType?: ItemType
  data: ExternalItemListFilters_data$key
}

const ExternalItemListFilters: React.FC<Props> = ({ data, listType }) => {
  const { filters } = useFragment(fragment, data)
  const [initialKeys] = useState(filters.map((filter) => filter.key))
  const orderedFilters = filters.slice().sort((valA, valB) => {
    const firstIdx = initialKeys.indexOf(valA.key)
    const secondIdx = initialKeys.indexOf(valB.key)

    return (firstIdx >= 0 ? firstIdx : Infinity) - (secondIdx >= 0 ? secondIdx : Infinity)
  })
  return (
    <>
      {orderedFilters.map((filter) => (
        <ListPageFilter key={`${listType}-${filter.key}`} filter={filter} />
      ))}
    </>
  )
}

const fragment = graphql`
  fragment ExternalItemListFilters_data on ExternalItemList {
    filters {
      key
      ...ListPageFilter_filter
    }
  }
`

export default ExternalItemListFilters
