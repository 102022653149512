/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LiveShareDrawer_liveshare = {
    readonly name: string;
    readonly slug: string;
    readonly password: string;
    readonly url: string;
    readonly contact: {
        readonly firstName: string;
        readonly lastName: string | null;
        readonly email: string | null;
    };
    readonly email: string | null;
    readonly generatedBy: string | null;
    readonly generatedAt: string | null;
    readonly requestedBy: string | null;
    readonly requestedAt: string | null;
    readonly viewedAt: ReadonlyArray<string> | null;
    readonly expiresAt: string;
    readonly " $refType": "LiveShareDrawer_liveshare";
};
export type LiveShareDrawer_liveshare$data = LiveShareDrawer_liveshare;
export type LiveShareDrawer_liveshare$key = {
    readonly " $data"?: LiveShareDrawer_liveshare$data;
    readonly " $fragmentRefs": FragmentRefs<"LiveShareDrawer_liveshare">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LiveShareDrawer_liveshare",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "password",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Contact",
      "kind": "LinkedField",
      "name": "contact",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "generatedBy",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "generatedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "requestedBy",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "requestedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "expiresAt",
      "storageKey": null
    }
  ],
  "type": "LiveShare",
  "abstractKey": null
};
})();
(node as any).hash = '63f621a8421e5f6696c3ef38f4dd10f1';
export default node;
