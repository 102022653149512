import { MutationParameters, GraphQLTaggedNode, IEnvironment, MutationConfig, Disposable } from 'relay-runtime'
import { UseMutationConfig } from 'react-relay/lib/relay-experimental/useMutation'
import { useMutation } from 'react-relay/hooks'
import { useSnackbar } from 'notistack'
import { useState } from 'react'

const useNiceMutation = <TMutation extends MutationParameters>(
  mutation: GraphQLTaggedNode,
  params?: {
    commitMutationFn?: (environment: IEnvironment, config: MutationConfig<TMutation>) => Disposable
    noErrorSnackbar?: boolean
  }
): [(config: UseMutationConfig<TMutation>) => Disposable, boolean, null | string] => {
  const [commit, isInFlight] = useMutation<TMutation>(mutation, params?.commitMutationFn)
  const [error, setError] = useState<null | string>(null)
  const { enqueueSnackbar } = useSnackbar()

  const hookedCommitFn = (config: UseMutationConfig<TMutation>): Disposable => {
    return commit({
      ...config,
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' })
        if (config.onError) config.onError(error)
      },
      onCompleted: (res, errors) => {
        if (errors) {
          const message = errors.map((err) => err.message).join(', ')
          if (params?.noErrorSnackbar !== true) {
            enqueueSnackbar(message, { variant: 'error' })
          }
          setError(message)
        }
        if (config.onCompleted) config.onCompleted(res, errors)
      },
    })
  }

  return [hookedCommitFn, isInFlight, error]
}

export default useNiceMutation
