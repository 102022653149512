import React, { unstable_useTransition, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import styled from 'styled-components'
import { graphql } from 'babel-plugin-relay/macro'
import { useFragment, useLazyLoadQuery, useRelayEnvironment } from 'react-relay/hooks'
import { useLocation, useNavigate } from 'react-router'
import useWorkspaceNavigate from '../../hooks/useWorkspaceNavigate'
import { TaxAssessmentsQuery } from '../../__generated__/TaxAssessmentsQuery.graphql'
import { parse, stringify } from 'qs'
import { Pagination } from '@material-ui/lab'
import { Description, ArrowDropUp, ArrowDropDown } from '@material-ui/icons'
import { Button, Checkbox, IconButton, lighten } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { media } from '../../theme'
import LoadingDots from '../../components/LoadingDots'
import useNiceMutation from '../../mutations/useNiceMutation'
import { TaxAssessmentsVerifyMutation } from '../../__generated__/TaxAssessmentsVerifyMutation.graphql'
import { useSnackbar } from 'notistack'
import { TaxAssessmentsWithParams_list$key } from '../../__generated__/TaxAssessmentsWithParams_list.graphql'
import { fetchQuery } from 'react-relay'
import { useDateTimeDisplay } from '../../hooks/timeZoneDisplay'

export const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableHeader: {
    fontWeight: 700,
  },
})

export const Root = styled.div`
  width: 1660px;
  margin: auto;
  margin-top: 8px;

  ${media.medium`
    width: 1140px;
  `}

  ${media.small`
    width: 100%;
    min-width: 360px;
    max-width: 625px;
  `}
`

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 8px;
`

const StyledIconButton = styled(Button)<{ $active?: boolean }>`
  background-color: ${(props) => (props.$active ? lighten(props.theme.palette.success.main, 1) : undefined)};
  &:hover {
    background-color: ${(props) => (props.$active ? lighten(props.theme.palette.success.main, 0.8) : undefined)};
  }
  color: ${(props) => (props.$active ? props.theme.palette.warning.main : undefined)};
`

const TaxAssessments: React.FC & { Skeleton: React.FC } = () => {
  const classes = useStyles()
  const location = useLocation()
  const navigate = useNavigate()
  const wsNavigate = useWorkspaceNavigate()
  const [startTransition] = unstable_useTransition()
  const queryVariables = parse(location.search, { ignoreQueryPrefix: true })
  const page = Number.parseInt(queryVariables.page as string) || 1
  const perPage = Number.parseInt(queryVariables.perPage as string) || 15
  const mode = (queryVariables.mode as string) || localStorage.getItem('linkedMode') || 'null'
  const changePageTo = (newPage: number) => wsNavigate.relative({ page: newPage, mode: mode })
  const [sort, setSort] = useState(queryVariables.created_at || 'desc')
  const data = useLazyLoadQuery<TaxAssessmentsQuery>(query, { page: page, perPage: perPage, mode: mode as string, sort: sort as string })
  const handleSort = (sort: string) => {
    const tempVars = Object.assign({}, queryVariables)
    if (sort === 'desc') {
      tempVars['created'] = 'asc'
      setSort('asc')
    } else if (sort === 'asc') {
      tempVars['created'] = 'desc'
      setSort('desc')
    }
    startTransition(() => {
      navigate(`?${stringify(tempVars)}`)
    })
  }

  const handleAction = (action: string | null) => {
    localStorage.setItem('linkedMode', action as string)
    wsNavigate.relative({ page: 1, mode: action })
  }

  return (
    <Root>
      <div>
        <StyledIconButton $active={mode == 'null' ? true : false}>
          <Button color='inherit' size='small' onClick={() => handleAction('null')}>
            All
          </Button>
        </StyledIconButton>
        <StyledIconButton $active={mode == 'ATB' ? true : false}>
          <Button color='inherit' size='small' onClick={() => handleAction('ATB')}>
            ATB
          </Button>
        </StyledIconButton>
        <StyledIconButton $active={mode == 'ATC' ? true : false}>
          <Button color='inherit' size='small' onClick={() => handleAction('ATC')}>
            ATC
          </Button>
        </StyledIconButton>
        <StyledIconButton $active={mode == 'T1' ? true : false}>
          <Button color='inherit' size='small' onClick={() => handleAction('T1')}>
            T1
          </Button>
        </StyledIconButton>
        <StyledIconButton $active={mode == 'Others' ? true : false}>
          <Button color='inherit' size='small' onClick={() => handleAction('Others')}>
            Others
          </Button>
        </StyledIconButton>
      </div>
      <TableContainer component={Paper}>
        <Table className={classes.table} size='medium' aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeader}>Name</TableCell>
              <TableCell className={classes.tableHeader}>File</TableCell>
              <TableCell className={classes.tableHeader}>Shipment</TableCell>
              <TableCell className={classes.tableHeader} onClick={() => handleSort(sort as string)}>
                Created
                <span>{sort == 'asc' ? <ArrowDropDown /> : <ArrowDropUp />}</span>
              </TableCell>
              <TableCell className={classes.tableHeader}>Verified</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TaxAssessmentsWithParams
              page={page}
              perPage={perPage}
              onPageChange={(newPage) => changePageTo(newPage)}
              sort={sort as string}
              list={data.taxAssessmentList}
              mode={mode as string}
            />
          </TableBody>
        </Table>
      </TableContainer>
    </Root>
  )
}
interface Params {
  page: number
  perPage: number
  onPageChange: (page: number) => void
  sort: string
  list: TaxAssessmentsWithParams_list$key
  mode: string | null
}

const TaxAssessmentsWithParams: React.FC<Params> = ({ page, onPageChange, sort, list: data, mode }) => {
  const list = useFragment(fragmentTaxAssessment, data)
  const [commit, loading] = useNiceMutation<TaxAssessmentsVerifyMutation>(verifyMutation)
  const { enqueueSnackbar } = useSnackbar()
  const environment = useRelayEnvironment()

  const refresh = () => {
    fetchQuery(environment, query, { page: page, perPage: 15, mode: mode, sort: sort })
  }

  const handleChange = (slug: string, checked: boolean) => {
    const status = checked ? 'Unverified' : 'Verified'

    commit({
      variables: { input: { slug: slug } },
      onCompleted: (_, errors) => {
        if (!errors) {
          enqueueSnackbar(`Document Successfully ${status}`, { variant: 'success' })
          refresh()
        }
      },
    })
  }

  return (
    <>
      {list.taxAssessmentFiles?.map((log) => (
        <>
          <TableRow key={log.entitySlug}>
            <TableCell>{log.name}</TableCell>
            <TableCell>
              <IconButton onClick={() => window.open(log.callResponseUrl as string)} disabled={!log.callResponseUrl}>
                <Description />
              </IconButton>
            </TableCell>
            <TableCell>
              <a href={log.hyperlink as string} target='_blank' rel='noopener noreferrer'>
                {log.entitySlug}
              </a>
            </TableCell>
            <TableCell>{useDateTimeDisplay(log.uploadedAt)}</TableCell>
            <TableCell>
              {loading ? <LoadingDots /> : <Checkbox checked={log.verified} onChange={() => handleChange(log.attachmentSlug, log.verified)} />}
            </TableCell>
          </TableRow>
        </>
      ))}
      {list.paginationMeta && list.paginationMeta.totalPages > 1 && (
        <TableRow>
          <TableCell colSpan={5}>
            <PaginationContainer>
              <Pagination shape='rounded' count={list.paginationMeta.totalPages} page={page} onChange={(_, newPage) => onPageChange(newPage)} />
            </PaginationContainer>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}

const query = graphql`
  query TaxAssessmentsQuery($page: Int, $perPage: Int, $mode: String, $sort: String!) {
    taxAssessmentList(page: $page, perPage: $perPage, mode: $mode, sort: $sort) {
      ...TaxAssessmentsWithParams_list
    }
  }
`
const verifyMutation = graphql`
  mutation TaxAssessmentsVerifyMutation($input: infoDocumentVerifyInput!) {
    infoDocumentVerify(input: $input) {
      clientMutationId
    }
  }
`

const fragmentTaxAssessment = graphql`
  fragment TaxAssessmentsWithParams_list on TaxAssessmentList {
    taxAssessmentFiles {
      entitySlug
      name
      linked
      uploadedAt
      callResponseUrl
      hyperlink
      verified
      attachmentSlug
    }
    paginationMeta {
      totalPages
    }
  }
`

TaxAssessments.Skeleton = () => (
  <Root>
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <Skeleton variant='rect' height='50px'></Skeleton>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <Skeleton variant='rect' height='80px'></Skeleton>
          </TableRow>
          <TableRow>
            <Skeleton variant='rect' height='80px'></Skeleton>
          </TableRow>
          <TableRow>
            <Skeleton variant='rect' height='80px'></Skeleton>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  </Root>
)

export default TaxAssessments
