/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type TaxAssessmentsQueryVariables = {
    page?: number | null;
    perPage?: number | null;
    mode?: string | null;
    sort: string;
};
export type TaxAssessmentsQueryResponse = {
    readonly taxAssessmentList: {
        readonly " $fragmentRefs": FragmentRefs<"TaxAssessmentsWithParams_list">;
    };
};
export type TaxAssessmentsQuery = {
    readonly response: TaxAssessmentsQueryResponse;
    readonly variables: TaxAssessmentsQueryVariables;
};



/*
query TaxAssessmentsQuery(
  $page: Int
  $perPage: Int
  $mode: String
  $sort: String!
) {
  taxAssessmentList(page: $page, perPage: $perPage, mode: $mode, sort: $sort) {
    ...TaxAssessmentsWithParams_list
  }
}

fragment TaxAssessmentsWithParams_list on TaxAssessmentList {
  taxAssessmentFiles {
    entitySlug
    name
    linked
    uploadedAt
    callResponseUrl
    hyperlink
    verified
    attachmentSlug
    id
  }
  paginationMeta {
    totalPages
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "mode"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "page"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "perPage"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sort"
},
v4 = [
  {
    "kind": "Variable",
    "name": "mode",
    "variableName": "mode"
  },
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page"
  },
  {
    "kind": "Variable",
    "name": "perPage",
    "variableName": "perPage"
  },
  {
    "kind": "Variable",
    "name": "sort",
    "variableName": "sort"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TaxAssessmentsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "TaxAssessmentList",
        "kind": "LinkedField",
        "name": "taxAssessmentList",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TaxAssessmentsWithParams_list"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "TaxAssessmentsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "TaxAssessmentList",
        "kind": "LinkedField",
        "name": "taxAssessmentList",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TaxAssessment",
            "kind": "LinkedField",
            "name": "taxAssessmentFiles",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "entitySlug",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "linked",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "uploadedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "callResponseUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hyperlink",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "verified",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "attachmentSlug",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Paginate",
            "kind": "LinkedField",
            "name": "paginationMeta",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalPages",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b1cd5d2db607f5a1441e0e51f7c60d57",
    "id": null,
    "metadata": {},
    "name": "TaxAssessmentsQuery",
    "operationKind": "query",
    "text": "query TaxAssessmentsQuery(\n  $page: Int\n  $perPage: Int\n  $mode: String\n  $sort: String!\n) {\n  taxAssessmentList(page: $page, perPage: $perPage, mode: $mode, sort: $sort) {\n    ...TaxAssessmentsWithParams_list\n  }\n}\n\nfragment TaxAssessmentsWithParams_list on TaxAssessmentList {\n  taxAssessmentFiles {\n    entitySlug\n    name\n    linked\n    uploadedAt\n    callResponseUrl\n    hyperlink\n    verified\n    attachmentSlug\n    id\n  }\n  paginationMeta {\n    totalPages\n  }\n}\n"
  }
};
})();
(node as any).hash = 'a3123539298e94efa74ad48685135ec0';
export default node;
