import React, { Suspense, useEffect, useRef, useState } from 'react'
import { Typography, TextField } from '@material-ui/core'
import { fetchQuery, useRelayEnvironment } from 'react-relay/hooks'
import { graphql } from 'babel-plugin-relay/macro'
import { SearchContactsQuery } from '../__generated__/SearchContactsQuery.graphql'
import ItemImage from './ItemImage'
import { TitleRow, TitleContainer, TitleLink } from './ShowPageListItem'
import SemanticLine from './SemanticLine'
import ShowPageInDrawer from './ShowPageInDrawer'
import styled from 'styled-components'
import WorkspacedLink from './WorkspacedLink'
import { SlideInDrawer } from './SlideInDrawer'
import { camelCase, startCase } from 'lodash'
import Button from './Button'
import ContactAdd from '../pages/Contact/ContactAdd'
import useNiceMutation from '../mutations/useNiceMutation'
import { SearchContactsAddMutation } from '../__generated__/SearchContactsAddMutation.graphql'
import { Alert } from '@material-ui/lab'

interface Props {
  entityType: string
  entitySlug: string
  open: boolean
  onClose: () => void
  role: string
}

const RootWorkSpacedLink = styled(WorkspacedLink)`
  flex: 1;
  position: relative;
  background-color: ${(props) => props.theme.palette.background.paper};

  &:hover {
    filter: brightness(0.95);
  }
`

const SearchField = styled(TextField)`
  width: 70%;
  margin: 10px;
`
const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`

const ItemContainer = styled(FlexContainer)`
  padding: 0 20px;
`

const Topbar = styled(FlexContainer)``

const RootContainer = styled(FlexContainer)`
  padding: 8px;
  align-items: flex-start;
  background-color: ${(props) => props.theme.palette.background.paper};
  transition-duration: 300ms;
`

const TitleText = styled(Typography)`
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
`

const SearchContacts: React.FC<Props> = ({ entityType, entitySlug, open, onClose, role }) => {
  const [searchQuery, setSearchQuery] = useState('')
  const searchQueryRef = useRef(searchQuery)
  const textInput = useRef<any>()
  const environment = useRelayEnvironment()
  const [options, setOptions] = useState<any[]>([])
  const [contactPreview, setContactPreview] = useState(false)
  const [contact, setContact] = useState('')
  const [contactAddOpen, setContactAddOpen] = useState(false)
  const [commitAddContactLink, , mutationError] = useNiceMutation<SearchContactsAddMutation>(add_mutation)
  const addContactLink = (contactSlug: string, assignRole: boolean) => {
    commitAddContactLink({
      variables: {
        input: { entityType: entityType, entitySlug: entitySlug, role: role, contactSlug: contactSlug, assign: assignRole, commandCall: true },
      },
      onCompleted: (_, errors) => {
        if (!errors) {
          onClose()
          setSearchQuery('')
        }
      },
    })
  }

  const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (e.ctrlKey) return

    e.preventDefault()
    setContactPreview(true)
  }

  useEffect(() => {
    fetchQuery<SearchContactsQuery>(environment, query, {
      type: 'external_contacts',
      searchQuery: searchQuery,
      perPage: 20,
      requiredPagination: true,
    })
      .toPromise()
      .then((data) => data?.itemList.items.nodes || [])
      .then((opts) => setOptions(opts as never[]))
  }, [searchQuery, environment, role])

  const onQueryChange = (newQuery: string) => {
    searchQueryRef.current = newQuery
    setTimeout(() => {
      if (searchQueryRef.current === newQuery) {
        setSearchQuery(newQuery)
      }
    }, 300)
  }
  return (
    <SlideInDrawer
      size='small'
      elevation={0}
      anchor='right'
      open={open}
      onClose={() => {
        onClose()
        setSearchQuery('')
      }}
    >
      <Topbar>
        <SearchField
          variant='outlined'
          inputRef={textInput}
          label={`Search ${startCase(camelCase(role))}`}
          onChange={(event) => onQueryChange(event.target.value)}
        />
        <Button variant='outlined' onClick={() => setContactAddOpen(true)}>
          New Contact
        </Button>
        {mutationError && <Alert severity='error'>{mutationError}</Alert>}
      </Topbar>
      <SlideInDrawer size='default' anchor='right' open={contactAddOpen} elevation={0} onClose={() => setContactAddOpen(false)}>
        <Suspense fallback={<ContactAdd.Skeleton />}>
          <ContactAdd
            type='external'
            title='New External Contact'
            onClose={() => setContactAddOpen(false)}
            onSubmit={(slug) => {
              textInput.current.value = ''
              setSearchQuery(slug)
            }}
          />
        </Suspense>
      </SlideInDrawer>

      {options &&
        options
          .slice()
          .sort((item1, item2) => {
            const x = item1.contactRoles.includes(role)
            const y = item2.contactRoles.includes(role)
            if (x && !y) return -1
            if (!x && y) return 1
            return 0
          })
          .map((item) => {
            return (
              <>
                <ItemContainer>
                  <RootWorkSpacedLink
                    to={`/${item.itemType}/${item.slug}`}
                    onClick={(e) => {
                      handleLinkClick(e)
                      setContact(item.slug)
                    }}
                  >
                    <RootContainer key={item.slug}>
                      {item.image && <ItemImage variant='extraSmall' data={item.image} />}
                      <TitleRow title={item.title}>
                        <TitleContainer>
                          <TitleLink>
                            <TitleText variant='caption' color='textPrimary'>
                              {item.title}
                            </TitleText>
                            {item.lines?.map((line: any) => (
                              <SemanticLine key={line.key} data={line} />
                            ))}
                          </TitleLink>
                        </TitleContainer>
                      </TitleRow>
                    </RootContainer>
                  </RootWorkSpacedLink>
                  {/* initial consignee is same as consignee because to track initial consignee in future*/}
                  {item.contactRoles.includes(role === 'initial-consignee' ? 'consignee' : role) ? (
                    <Button onClick={() => addContactLink(item.slug, false)}>Assign</Button>
                  ) : (
                    <Button onClick={() => addContactLink(item.slug, true)}>{`Make ${startCase(camelCase(role))} & Assign`}</Button>
                  )}
                </ItemContainer>
              </>
            )
          })}
      <ShowPageInDrawer
        entityType='contact'
        entitySlug={contact}
        open={contactPreview}
        onClose={() => {
          setContactPreview(false)
          setContact('')
        }}
      />
    </SlideInDrawer>
  )
}

const query = graphql`
  query SearchContactsQuery($type: ListItemTypeEnum!, $searchQuery: String, $perPage: Int, $requiredPagination: Boolean) {
    itemList(type: $type, searchQuery: $searchQuery, perPage: $perPage, requiredPagination: $requiredPagination) {
      items {
        nodes {
          slug
          title
          itemType
          contactRoles
          lines {
            ...SemanticLine_data
          }
          image {
            ...ItemImage_data
          }
        }
      }
    }
  }
`

const add_mutation = graphql`
  mutation SearchContactsAddMutation($input: infoAddContactLinkInput!) {
    infoAddContactLink(input: $input) {
      clientMutationId
    }
  }
`

export default SearchContacts
