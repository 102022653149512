/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ShipmentCompanyCard_shipment = {
    readonly slug: string;
    readonly company: {
        readonly eori: string | null;
        readonly eoriValid: boolean | null;
        readonly eoriValidatedAt: string | null;
        readonly vat: string | null;
        readonly vatValid: boolean | null;
        readonly vatValidatedAt: string | null;
    } | null;
    readonly eoriDocGeneratedAt: string | null;
    readonly vatDocGeneratedAt: string | null;
    readonly " $refType": "ShipmentCompanyCard_shipment";
};
export type ShipmentCompanyCard_shipment$data = ShipmentCompanyCard_shipment;
export type ShipmentCompanyCard_shipment$key = {
    readonly " $data"?: ShipmentCompanyCard_shipment$data;
    readonly " $fragmentRefs": FragmentRefs<"ShipmentCompanyCard_shipment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ShipmentCompanyCard_shipment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Contact",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "eori",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "eoriValid",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "eoriValidatedAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "vat",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "vatValid",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "vatValidatedAt",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eoriDocGeneratedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vatDocGeneratedAt",
      "storageKey": null
    }
  ],
  "type": "Shipment",
  "abstractKey": null
};
(node as any).hash = '12f29b67a73372877aef367fb65c7031';
export default node;
