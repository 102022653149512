/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ExternalListItemTypeEnum = "containers" | "external_contacts" | "item" | "shipments" | "tickets" | "voyages" | "%future added value";
export type FilterInput = {
    key: string;
    values: Array<string>;
};
export type ExternalItemListFiltersQueryVariables = {
    type?: ExternalListItemTypeEnum | null;
    filters?: Array<FilterInput> | null;
    sortKey?: string | null;
    page?: number | null;
    perPage?: number | null;
    searchQuery?: string | null;
    voyageEtaStart?: string | null;
    voyageEtaEnd?: string | null;
    dischargeDateStart?: string | null;
    dischargeDateEnd?: string | null;
};
export type ExternalItemListFiltersQueryResponse = {
    readonly externalItemList: {
        readonly " $fragmentRefs": FragmentRefs<"ExternalItemListFilters_data">;
    };
};
export type ExternalItemListFiltersQuery = {
    readonly response: ExternalItemListFiltersQueryResponse;
    readonly variables: ExternalItemListFiltersQueryVariables;
};



/*
query ExternalItemListFiltersQuery(
  $type: ExternalListItemTypeEnum
  $filters: [FilterInput!]
  $sortKey: String
  $page: Int
  $perPage: Int
  $searchQuery: String
  $voyageEtaStart: Date
  $voyageEtaEnd: Date
  $dischargeDateStart: Date
  $dischargeDateEnd: Date
) {
  externalItemList(type: $type, filters: $filters, sortKey: $sortKey, page: $page, perPage: $perPage, searchQuery: $searchQuery, voyageEtaStart: $voyageEtaStart, voyageEtaEnd: $voyageEtaEnd, dischargeDateStart: $dischargeDateStart, dischargeDateEnd: $dischargeDateEnd, showFilters: true) {
    ...ExternalItemListFilters_data
  }
}

fragment ExternalItemListFilters_data on ExternalItemList {
  filters {
    key
    ...ListPageFilter_filter
  }
}

fragment ListPageFilter_filter on Filter {
  key
  name
  values {
    label
    displayLabel
    selected
    ...ListPageFilter_filterValue
  }
}

fragment ListPageFilter_filterValue on FilterValue {
  label
  displayLabel
  selected
  count
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "dischargeDateEnd"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "dischargeDateStart"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "page"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "perPage"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchQuery"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sortKey"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "type"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "voyageEtaEnd"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "voyageEtaStart"
},
v10 = [
  {
    "kind": "Variable",
    "name": "dischargeDateEnd",
    "variableName": "dischargeDateEnd"
  },
  {
    "kind": "Variable",
    "name": "dischargeDateStart",
    "variableName": "dischargeDateStart"
  },
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page"
  },
  {
    "kind": "Variable",
    "name": "perPage",
    "variableName": "perPage"
  },
  {
    "kind": "Variable",
    "name": "searchQuery",
    "variableName": "searchQuery"
  },
  {
    "kind": "Literal",
    "name": "showFilters",
    "value": true
  },
  {
    "kind": "Variable",
    "name": "sortKey",
    "variableName": "sortKey"
  },
  {
    "kind": "Variable",
    "name": "type",
    "variableName": "type"
  },
  {
    "kind": "Variable",
    "name": "voyageEtaEnd",
    "variableName": "voyageEtaEnd"
  },
  {
    "kind": "Variable",
    "name": "voyageEtaStart",
    "variableName": "voyageEtaStart"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ExternalItemListFiltersQuery",
    "selections": [
      {
        "alias": null,
        "args": (v10/*: any*/),
        "concreteType": "ExternalItemList",
        "kind": "LinkedField",
        "name": "externalItemList",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ExternalItemListFilters_data"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v7/*: any*/),
      (v2/*: any*/),
      (v6/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v9/*: any*/),
      (v8/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ExternalItemListFiltersQuery",
    "selections": [
      {
        "alias": null,
        "args": (v10/*: any*/),
        "concreteType": "ExternalItemList",
        "kind": "LinkedField",
        "name": "externalItemList",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Filter",
            "kind": "LinkedField",
            "name": "filters",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "key",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "FilterValue",
                "kind": "LinkedField",
                "name": "values",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "label",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayLabel",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "selected",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "count",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "821d36fdd11929c920477cb74cd2d4c4",
    "id": null,
    "metadata": {},
    "name": "ExternalItemListFiltersQuery",
    "operationKind": "query",
    "text": "query ExternalItemListFiltersQuery(\n  $type: ExternalListItemTypeEnum\n  $filters: [FilterInput!]\n  $sortKey: String\n  $page: Int\n  $perPage: Int\n  $searchQuery: String\n  $voyageEtaStart: Date\n  $voyageEtaEnd: Date\n  $dischargeDateStart: Date\n  $dischargeDateEnd: Date\n) {\n  externalItemList(type: $type, filters: $filters, sortKey: $sortKey, page: $page, perPage: $perPage, searchQuery: $searchQuery, voyageEtaStart: $voyageEtaStart, voyageEtaEnd: $voyageEtaEnd, dischargeDateStart: $dischargeDateStart, dischargeDateEnd: $dischargeDateEnd, showFilters: true) {\n    ...ExternalItemListFilters_data\n  }\n}\n\nfragment ExternalItemListFilters_data on ExternalItemList {\n  filters {\n    key\n    ...ListPageFilter_filter\n  }\n}\n\nfragment ListPageFilter_filter on Filter {\n  key\n  name\n  values {\n    label\n    displayLabel\n    selected\n    ...ListPageFilter_filterValue\n  }\n}\n\nfragment ListPageFilter_filterValue on FilterValue {\n  label\n  displayLabel\n  selected\n  count\n}\n"
  }
};
})();
(node as any).hash = '56a03d759c74e6db4a3738011355a2e3';
export default node;
