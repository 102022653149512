import React, { Suspense } from 'react'
import { DialogTitle } from '@material-ui/core'
import OdysseyDialog from './OdysseyDialog'
import DraftContactEdit from './DraftContactEdit'

interface Props {
  ticketSlug: string
  open: boolean
  onClose: () => void
}

const LiveRequestEditDialog: React.FC<Props> = (props) => {
  return (
    <OdysseyDialog
      open={props.open}
      maxWidth='sm'
      fullWidth
      onClose={props.onClose}
      scroll='paper'
      style={{ maxWidth: '640px', margin: 'auto' }}
    >
      <DialogTitle>Edit ticket</DialogTitle>
      <Suspense fallback={<p>Laoding..</p>}>
        <DraftContactEdit {...props} />
      </Suspense>
    </OdysseyDialog>
  )
}
export default LiveRequestEditDialog
