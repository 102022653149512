import axios from 'axios'
import React, { Suspense, useContext, useMemo } from 'react'
import { Box, LinearProgress } from '@material-ui/core'

import ContactRenderer from '../../components/Renderers/ContactRenderer'
import ShipmentIcon from '../../assets/public/v2/shipment.svg'
import DataTable, { IOrder } from '../../components/DataTable'
import DateRenderer from '../../components/Renderers/DateRenderer'
import fileDownload from 'js-file-download'
import LinkRenderer from '../../components/Renderers/LinkRenderer'
import DecoratedBooleanRenderer from '../../components/Renderers/BooleanRenderer'
import DecoratedNumberRenderer from '../../components/Renderers/NumberRenderer'
import moment from 'moment'
import SessionContext from '../../SessionContext'
import StageRenderer from '../../components/Renderers/StageRenderer'
import usePager from '../../hooks/usePager'
import VinIcon from '../../assets/public/v2/vin.svg'
import DateBlankIcon from '../../assets/public/v2/date_blank.svg'
import WarehouseIcon from '../../assets/public/v2/warehouse.svg'
import TransporterIcon from '../../assets/public/v2/transporter.svg'
import StageWaiting from '../../assets/public/v2/stage_waiting.svg'
import ReleaseIcon from '../../assets/public/v2/release.svg'
import { useQuery } from 'urql'
import { useSnackbar } from 'notistack'
import { useLocation } from 'react-router'
import type { Column, IFilter } from '../../components/DataTable'
import { getToken } from '../../api/server'

const ExternalShipmentsTable: React.FC = () => {
  const { user } = useContext(SessionContext)
  const [filters, setFilters] = React.useState<IFilter[]>([])
  const [search, setSearch] = React.useState('')
  const [order, setOrder] = React.useState<IOrder[]>([])
  const [page, pageSize, handlePageChange, handlePageSizeChange] = usePager()
  const [includeArchived, setIncludeArchived] = React.useState(false)

  const queryResult = useQuery({
    requestPolicy: 'cache-and-network',
    query: query,
    variables: {
      ...(search.length > 2 && { searchQuery: search }),
      filters,
      order,
      page,
      pageSize,
      includeArchived,
    },
  })

  const nestedContactsQueryResult = useQuery({
    requestPolicy: 'cache-and-network',
    query: nestedContactsQuery,
    variables: {
      ...(search.length > 2 && { searchQuery: search }),
      filters,
      order,
      page,
      pageSize,
      includeArchived,
    },
  })

  const data = queryResult[0].data
  const fetching = queryResult[0].fetching || nestedContactsQueryResult[0].fetching
  const nestedItems = nestedContactsQueryResult[0].data?.v2ShipmentList?.response.edges.map((edge: any) => edge?.node) || []
  const items =
    data?.v2ShipmentList?.response.edges.map((edge: any) => ({
      ...edge.node,
      ...nestedItems.find((item: any) => item.slug === edge.node?.slug),
    })) || []

  const url = `${process.env.REACT_APP_API_URL}/v2/shipments/download`
  const workspace = useLocation().pathname.split('/')[1]
  const token = getToken()

  const { enqueueSnackbar } = useSnackbar()
  const download = (selected?: number[]) => {
    const filename = `shipments-${moment().format('YYYY-MM-DD-HH:mm:ss')}.xlsx`
    return axios
      .post(
        url,
        {
          workspace,
          slugs: Array.isArray(selected) ? selected.map((i) => data?.v2ShipmentList?.response.edges[i].node.slug) : undefined,
          filters,
          order,
          search_query: search,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          responseType: 'blob',
        }
      )
      .then((res) => {
        fileDownload(res.data, filename)
      })
      .catch((error) => {
        error.response.data.text().then(function (text: string) {
          try {
            enqueueSnackbar(JSON.parse(text as string).errors, {
              variant: 'error',
            })
          } catch {
            enqueueSnackbar('Something went wrong', { variant: 'error' })
          }
        })
      })
  }

  const columns: Column[] = useMemo(
    () =>
      [
        {
          identifier: 'description',
          name: 'Shipment',
          sortable: true,
          editable: false,
          renderer: LinkRenderer,
          icon: ShipmentIcon,
          style: { maxWidth: 180 },
        },
        {
          identifier: 'stage',
          name: 'Stage',
          sortable: true,
          editable: false,
          filterable: true,
          icon: StageWaiting,
          style: { width: 150 },
          renderer: StageRenderer,
        },
        {
          identifier: 'eta',
          name: 'ETA',
          sortable: true,
          editable: false,
          filterable: true,
          icon: DateBlankIcon,
          style: { width: 150 },
          renderer: DateRenderer,
        },
        {
          identifier: 'vin',
          name: 'VIN',
          sortable: true,
          editable: false,
          icon: VinIcon,
          style: { width: 150 },
        },
        {
          identifier: 'customer.name',
          name: 'Customer',
          sortable: true,
          editable: false,
          filterable: false,
          style: { width: 150 },
          renderer: ContactRenderer,
        },
        {
          identifier: 'transporter.name',
          name: 'Transporter',
          sortable: true,
          editable: false,
          filterable: false,
          icon: TransporterIcon,
          style: { width: 150 },
          renderer: ContactRenderer,
        },
        {
          identifier: 'warehouse.name',
          name: 'Warehouse',
          sortable: true,
          editable: false,
          filterable: false,
          icon: WarehouseIcon,
          style: { width: 150 },
        },
        {
          identifier: 'dischargeDate',
          name: 'Discharge Date',
          sortable: true,
          editable: false,
          filterable: true,
          icon: DateBlankIcon,
          renderer: DateRenderer,
        },
        {
          identifier: 'shipperReleaseCheck',
          name: 'Shipper Release',
          sortable: true,
          editable: false,
          filterable: true,
          icon: ReleaseIcon,
          renderer: DecoratedBooleanRenderer,
        },
        {
          identifier: 'finalReleaseCheck',
          name: 'Final Release',
          sortable: true,
          editable: false,
          filterable: true,
          icon: ReleaseIcon,
          renderer: DecoratedBooleanRenderer,
        },
        {
          identifier: 'paymentDate',
          name: 'Payment Date',
          sortable: true,
          editable: false,
          filterable: true,
          icon: DateBlankIcon,
          renderer: DateRenderer,
        },
        {
          identifier: 'totalRevenueWithVat',
          name: 'Total Cost',
          sortable: true,
          editable: false,
          renderer: DecoratedNumberRenderer,
        },
        {
          identifier: 'expectedHandoverDate',
          name: 'Expected Handover Date',
          filterable: true,
          sortable: true,
          editable: false,
          icon: DateBlankIcon,
          renderer: DateRenderer,
        },
        {
          identifier: 'handoverDate',
          name: 'Handover Date',
          sortable: true,
          editable: false,
          filterable: true,
          icon: DateBlankIcon,
          renderer: DateRenderer,
        },
      ].map((col) => ({
        ...col,
        editable: col.editable && user?.contact?.contactType === 'External',
      })),
    []
  )

  return (
    <Box style={{ position: 'relative' }}>
      <Suspense key='item-list' fallback={<LinearProgress />}>
        <DataTable
          type='shipment'
          loading={fetching}
          filters={filters}
          setFilters={setFilters}
          search={search}
          setSearch={setSearch}
          includeArchived={includeArchived}
          setIncludeArchived={setIncludeArchived}
          order={order}
          setOrder={setOrder}
          actions={[]}
          columns={columns}
          data={items}
          handlePageChange={handlePageChange}
          handlePageSizeChange={handlePageSizeChange}
          page={page - 1}
          pageSize={pageSize}
          count={data?.v2ShipmentList?.totalCount}
          onDownload={download}
        />
      </Suspense>
    </Box>
  )
}

const nestedContactsQuery = `
  query ShipmentsTableQuery($searchQuery: String, $includeArchived: Boolean!, $filters: [V2FilterInput!], $order: [V2OrderInput!], $page: Int, $pageSize: Int) {
    v2ShipmentList(searchQuery: $searchQuery, includeArchived: $includeArchived, filters: $filters, order: $order, page: $page, pageSize: $pageSize) {
      response {
        edges {
          node {
            slug
            warehouse {
              slug
              name
            }
            transporter {
              name
              slug
            }
            payer {
              name
              slug
            }
            customer {
              name
              slug
            }
            linkedContacts {
              contactSlug
              role
            }
          }
        }
      }
    }
  }
`

const query = `
  query ShipmentsTableQuery($searchQuery: String, $includeArchived: Boolean!, $filters: [V2FilterInput!], $order: [V2OrderInput!], $page: Int, $pageSize: Int) {
    v2ShipmentList(searchQuery: $searchQuery, includeArchived: $includeArchived, filters: $filters, order: $order, page: $page, pageSize: $pageSize) {
      response {
        edges {
          node {
            description
            stage
            eta
            vin
            dischargeDate
            shipperReleaseCheck
            finalReleaseCheck
            expectedHandoverDate
            handoverDate
            paymentDate
            totalRevenueWithVat
            id
            isArchived
            slug
            type
          }
        }
      }
      paginate {
        hasNextPage
        hasPrevPage
        page
        perPage
        totalPages
      }
      totalCount
    }
  }
`
export default ExternalShipmentsTable
