/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ItemLink_item = {
    readonly title: string;
    readonly path: string | null;
    readonly itemType: string;
    readonly slug: string;
    readonly image: {
        readonly url: string | null;
    } | null;
    readonly " $refType": "ItemLink_item";
};
export type ItemLink_item$data = ItemLink_item;
export type ItemLink_item$key = {
    readonly " $data"?: ItemLink_item$data;
    readonly " $fragmentRefs": FragmentRefs<"ItemLink_item">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ItemLink_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "path",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "itemType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ListItem",
  "abstractKey": null
};
(node as any).hash = '943b782350c5a57c87fa4753121c217b';
export default node;
