import React, { Suspense } from 'react'
import { graphql } from 'babel-plugin-relay/macro'

import styled from 'styled-components'
import { Paper, Typography } from '@material-ui/core'
import { useLazyLoadQuery } from 'react-relay/hooks'
import { SanctionJobLogsQuery } from '../../__generated__/SanctionJobLogsQuery.graphql'
import ApiLogItem from '../../components/ApiLogItem'
import { media } from '../../theme'
import WorkspacedLink from '../../components/WorkspacedLink'

const RootPaper = styled(Paper)``
const TitleContainer = styled.div`
  padding: 16px;
`

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`

const ContactCard = styled.div`
  padding: 8px;
  border: 1px solid #${(props) => props.theme.customPalette.border.card};
  border-radius: 4px;

  &:hover {
    border-color: ${(props) => props.theme.palette.primary.main};
  }

  ${media.small`
    padding: unset;
    border: unset;
  `}
`

const StatusIcon = styled.div<{ $status: boolean }>`
  height: 15px;
  width: 15px;
  position: relative;
  border-radius: 50%;
  background-color: ${(props) => {
    if (props.$status) {
      return props.theme.palette.primary.light
    } else {
      return props.theme.palette.error.light
    }
  }};
  margin-right: 8px;
`

const NoDataText = styled(Typography).attrs({ variant: 'h6' })`
  color: ${(props) => props.theme.customPalette.text.noData};
  text-align: center;
  padding: 16px;
  font-weight: 400;
`

const SanctionJobLogs: React.FC = () => {
  return (
    <RootPaper variant='outlined'>
      <TitleContainer>
        <Typography variant='h5'>API Logs</Typography>
        <Typography variant='body1'>All the calls we&apos;ve made to external systems</Typography>
      </TitleContainer>
      <Suspense fallback={<SanctionJobLogsWithParams.Skeleton />}>
        <SanctionJobLogsWithParams />
      </Suspense>
    </RootPaper>
  )
}

const SanctionJobLogsWithParams: React.FC & { Skeleton: React.FC } = () => {
  const data = useLazyLoadQuery<SanctionJobLogsQuery>(query, {})

  if (data.sanctionLogList?.length === 0) {
    return <NoDataText>No jobs</NoDataText>
  }

  return (
    <>
      {data.sanctionLogList?.map((log) => (
        <FlexContainer key={log.lastRun} style={{ justifyContent: 'space-between', padding: '12px 24px', borderBottom: '1px solid #eee' }}>
          <FlexContainer>
            <StatusIcon $status={log.status} />
            <Typography variant='body1'>Last run {log.lastRun}</Typography>
          </FlexContainer>
          <FlexContainer>
            {log.contacts?.map((contact) => (
              <WorkspacedLink key={contact.slug} to={`/contact/${contact.slug}`}>
                <ContactCard>
                  <Typography variant='body2' color='textSecondary'>
                    {contact.name}
                  </Typography>
                </ContactCard>
              </WorkspacedLink>
            ))}
          </FlexContainer>
          <Typography variant='body2' color='textSecondary'>
            Sanctioned contact: {log.status ? log.count : 0}
          </Typography>
        </FlexContainer>
      ))}
    </>
  )
}

SanctionJobLogsWithParams.Skeleton = () => (
  <>
    <ApiLogItem.Skeleton />
    <ApiLogItem.Skeleton />
    <ApiLogItem.Skeleton />
  </>
)

const query = graphql`
  query SanctionJobLogsQuery {
    sanctionLogList {
      lastRun
      status
      count
      contacts {
        slug
        name
      }
    }
  }
`

export default SanctionJobLogs
