import React, { useEffect, useRef, useState } from 'react'
import { Typography, TextField } from '@material-ui/core'
import { fetchQuery, useRelayEnvironment } from 'react-relay/hooks'
import { graphql } from 'babel-plugin-relay/macro'
import ItemImage from './ItemImage'
import { TitleRow, TitleContainer, TitleLink } from './ShowPageListItem'
import SemanticLine from './SemanticLine'
import ShowPageInDrawer from './ShowPageInDrawer'
import styled from 'styled-components'
import WorkspacedLink from './WorkspacedLink'
import { SlideInDrawer } from './SlideInDrawer'
import Button from './Button'
import { ExternalContactListQuery } from '../__generated__/ExternalContactListQuery.graphql'
import { ExternalContactListAddMutation } from '../__generated__/ExternalContactListAddMutation.graphql'
import useNiceMutation from '../mutations/useNiceMutation'
import LoadingDots from './LoadingDots'
import { Alert } from '@material-ui/lab'
import { camelCase, startCase } from 'lodash'

interface Props {
  entityType: string
  entitySlug: string
  open: boolean
  onClose: () => void
  role: string
}

const RootWorkSpacedLink = styled(WorkspacedLink)`
  flex: 1;
  position: relative;
  background-color: ${(props) => props.theme.palette.background.paper};

  &:hover {
    filter: brightness(0.95);
  }
`

const SearchField = styled(TextField)`
  width: 70%;
  margin: 10px;
`
const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`

const ItemContainer = styled(FlexContainer)`
  padding: 0 20px;
`

const Topbar = styled(FlexContainer)``

const RootContainer = styled(FlexContainer)`
  padding: 8px;
  align-items: flex-start;
  background-color: ${(props) => props.theme.palette.background.paper};
  transition-duration: 300ms;
`

const TitleText = styled(Typography)`
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
`

const ExternalContactList: React.FC<Props> = ({ entityType, entitySlug, open, onClose, role }) => {
  const [searchQuery, setSearchQuery] = useState('')
  const searchQueryRef = useRef(searchQuery)
  const textInput = useRef<any>()
  const environment = useRelayEnvironment()
  const [options, setOptions] = useState<any[]>([])
  const [contactPreview, setContactPreview] = useState(false)
  const [contact, setContact] = useState('')
  const [commitAddDraftContactLink, loading, mutationError] = useNiceMutation<ExternalContactListAddMutation>(addMutation)
  const addContactLink = (contactSlug: string) => {
    commitAddDraftContactLink({
      variables: {
        input: { entityType: entityType, entitySlug: entitySlug, role: role, contactSlug: contactSlug },
      },
      onCompleted: (_, errors) => {
        if (!errors) {
          onClose()
          setSearchQuery('')
        }
      },
    })
  }

  const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (e.ctrlKey) return

    e.preventDefault()
    setContactPreview(true)
  }

  useEffect(() => {
    fetchQuery<ExternalContactListQuery>(environment, query, {
      type: 'external_contacts',
      searchQuery: searchQuery,
      perPage: 20,
    })
      .toPromise()
      .then((data) => data?.externalItemList.items.nodes || [])
      .then((opts) => setOptions(opts as never[]))
  }, [searchQuery, environment, role])

  const onQueryChange = (newQuery: string) => {
    searchQueryRef.current = newQuery
    setTimeout(() => {
      if (searchQueryRef.current === newQuery) {
        setSearchQuery(newQuery)
      }
    }, 300)
  }
  return (
    <SlideInDrawer
      size='small'
      elevation={0}
      anchor='right'
      open={open}
      onClose={() => {
        onClose()
        setSearchQuery('')
      }}
    >
      <Topbar>
        <SearchField
          variant='outlined'
          inputRef={textInput}
          label={`Search ${startCase(camelCase(role))}`}
          onChange={(event) => onQueryChange(event.target.value)}
        />
        {mutationError && <Alert severity='error'>{mutationError}</Alert>}
      </Topbar>

      {options &&
        options.map((item) => {
          return (
            <>
              <ItemContainer>
                <RootWorkSpacedLink
                  to={`/${item.itemType}/${item.slug}`}
                  onClick={(e) => {
                    handleLinkClick(e)
                    setContact(item.slug)
                  }}
                >
                  <RootContainer key={item.slug}>
                    {item.image && <ItemImage variant='extraSmall' data={item.image} />}
                    <TitleRow title={item.title}>
                      <TitleContainer>
                        <TitleLink>
                          <TitleText variant='caption' color='textPrimary'>
                            {item.title}
                          </TitleText>
                          {item.lines?.map((line: any) => (
                            <SemanticLine key={line.key} data={line} />
                          ))}
                        </TitleLink>
                      </TitleContainer>
                    </TitleRow>
                  </RootContainer>
                </RootWorkSpacedLink>
                <Button onClick={() => addContactLink(item.slug)}>{loading ? <LoadingDots /> : 'Assign'}</Button>
              </ItemContainer>
            </>
          )
        })}
      <ShowPageInDrawer
        entityType='contact'
        entitySlug={contact}
        open={contactPreview}
        onClose={() => {
          setContactPreview(false)
          setContact('')
        }}
      />
    </SlideInDrawer>
  )
}

const query = graphql`
  query ExternalContactListQuery(
    $type: ExternalListItemTypeEnum
    $filters: [FilterInput!]
    $sortKey: String
    $page: Int
    $perPage: Int
    $searchQuery: String
    $voyageEtaStart: Date
    $voyageEtaEnd: Date
    $dischargeDateStart: Date
    $dischargeDateEnd: Date
    $includeArchived: Boolean
  ) {
    externalItemList(
      type: $type
      filters: $filters
      sortKey: $sortKey
      page: $page
      perPage: $perPage
      searchQuery: $searchQuery
      voyageEtaStart: $voyageEtaStart
      voyageEtaEnd: $voyageEtaEnd
      dischargeDateStart: $dischargeDateStart
      dischargeDateEnd: $dischargeDateEnd
      includeArchived: $includeArchived
    ) {
      items {
        nodes {
          slug
          title
          itemType
          lines {
            ...SemanticLine_data
          }
          image {
            ...ItemImage_data
          }
        }
      }
    }
  }
`

const addMutation = graphql`
  mutation ExternalContactListAddMutation($input: infoAddDraftContactLinkInput!) {
    infoAddDraftContactLink(input: $input) {
      clientMutationId
    }
  }
`

export default ExternalContactList
