import React from 'react'
import styled from 'styled-components'
import { media } from '../theme'

interface Props {
  backgroundColor?: string
  imageUrl?: string
  children: React.ReactChild
  className?: string
}

interface RootProps {
  backgroundColor?: string
  imageUrl?: string
}

const Content = styled.div`
  margin: auto;
  width: 1660px;

  ${media.medium`
    width: 1140px;
    padding-top: 8px;
  `}

  ${media.small`
    width: 100%;
    padding-top: 0;
    min-width: 320px;
    max-width: 625px;
  `}
`

const Root = styled.div<RootProps>`
  width: 100%;
  background-color: ${(props) => props.backgroundColor || (props.theme.palette.type === 'light' ? '#eaeaea' : '#3a3a3a')};
  background-image: url('${(props) => props.imageUrl}');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-blend-mode: multiply;
`

const Hero: React.FC<Props> = ({ backgroundColor, imageUrl, children, className }) => {
  return (
    <Root backgroundColor={backgroundColor} imageUrl={imageUrl} className={className}>
      <Content>{children}</Content>
    </Root>
  )
}

export default Hero
