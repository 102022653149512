import React, { Suspense } from 'react'
import { graphql } from 'babel-plugin-relay/macro'

import { ShipmentFinancesQuery } from '../../__generated__/ShipmentFinancesQuery.graphql'
import ResponsiveGrid from '../../components/ResponsiveGrid'
import Timeline from '../../components/Timeline/Timeline'
import ShipmentFinanceDetails from '../../components/Finances/ShipmentFinanceDetails'
import ShipmentFinanceMetricCard from '../../components/Finances/ShipmentFinanceMetricCard'
import ShipmentSiblingCompositionCard from '../../components/ShipmentSiblingCompositionCard'
import ShipmentFinanceActionCard from '../../components/Finances/ShipmentFinanceActionCard'
import ExchangeRatesCard from '../../components/Finances/ExchangeRatesCard'
import useLazyLoadQueryWithSubscription from '../../hooks/useLazyLoadQueryWithSubscription'
import { Divider } from '@material-ui/core'

const ShipmentFinances: React.FC<{ slug: string }> & { Skeleton: React.FC } = ({ slug }) => {
  const queryData = useLazyLoadQueryWithSubscription<ShipmentFinancesQuery>(
    shipmentFinanceQuery,
    { slug },
    { fetchPolicy: 'store-and-network', subscriptionOptions: { entityType: 'shipments', entitySlug: slug } }
  )

  return (
    <ResponsiveGrid
      type='show-layout'
      highlight={[<ShipmentFinanceDetails key='finance-form' shipment={queryData.shipmentShow} />]}
      right={[
        <ShipmentFinanceMetricCard key='metrics' shipment={queryData.shipmentShow} />,
        <Divider key='divider' style={{ marginBottom: '16px' }} />,
        <ShipmentFinanceActionCard key='invoice' shipment={queryData.shipmentShow} />,
        <ShipmentSiblingCompositionCard key='container-composition' shipment={queryData.shipmentShow} />,
        <Suspense key='exchange-rates-card' fallback={<ExchangeRatesCard.Skeleton />}>
          <ExchangeRatesCard key='exchange-rates' />
        </Suspense>,
        <Suspense key='timeline' fallback={<Timeline.Skeleton />}>
          <Timeline type='shipments' slug={slug} group='finances' />
        </Suspense>,
      ]}
    />
  )
}

export const shipmentFinanceQuery = graphql`
  query ShipmentFinancesQuery($slug: String!) {
    shipmentShow(slug: $slug) {
      slug
      ...ShipmentRateCard_shipment
      ...ShipmentFinanceDetails_shipment
      ...ShipmentFinanceMetricCard_shipment
      ...ShipmentSiblingCompositionCard_shipment
      ...ShipmentCompanyCard_shipment
      ...ShipmentFinanceActionCard_shipment
    }
  }
`

ShipmentFinances.Skeleton = () => <>Loading...</>

export default ShipmentFinances
