import { Container, Typography } from '@material-ui/core'
import { ExitToApp } from '@material-ui/icons'
import React, { useContext } from 'react'
import { useLocation, useNavigate } from 'react-router'
import styled from 'styled-components'
import Workspace from '../asset/internal/workspace.png'
import { NavItemT } from '../layouts/Internal'
import SessionContext from '../SessionContext'
import { media } from '../theme'
import Appbar from './Appbar'
import Button from './Button'

const Root = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  padding-top: 64px;
`

const Content = styled.div`
  text-align: center;
`

const WorkspaceImage = styled.img`
  height: 70%;

  ${media.small`
    width: 100%;
    height: unset;
  `}
`

const Title = styled(Typography)`
  margin-bottom: 16px;
`

const NoAccess: React.FC = () => {
  const { user, reloadSession } = useContext(SessionContext)
  const location = useLocation()
  const navigate = useNavigate()
  const defaultWorkspace = user?.defaultWorkspace || 'live'
  const currentPath = location.pathname.split('/').slice(2).join('/')

  const navItems: NavItemT[] = [
    { label: 'Home', path: '/', activePath: /^$/ },
    { label: 'Containers', path: '/containers', activePath: /^containers/ },
    { label: 'Shipments', path: '/shipments', activePath: /^shipments/ },
    { label: 'Voyages', path: '/voyages', activePath: /^voyages/ },
    { label: 'Contacts', path: '/contacts', activePath: /^contacts/ },
    { label: 'Admin', path: '/admin/workspaces', activePath: /^admin/ },
  ]
  const activeNavLabel = navItems.filter((item) => item.activePath?.test(currentPath))[0]?.label || ''

  return (
    <>
      <Appbar navItems={navItems} activeNavLabel={activeNavLabel} activeSubNavLabel={''} workspace={defaultWorkspace} />
      <Root>
        <Content>
          <Title variant='h3'>No access</Title>
          <Typography variant='h6'>Your need to be invited by the Portagent Team.</Typography>
          <Typography variant='body1'>Ask an admin to invite you to this workspace or create it.</Typography>
        </Content>
        <WorkspaceImage src={Workspace} alt='no-workspace' />
        <Typography>You can go back to your default workspace</Typography>
        <Button
          color='primary'
          startIcon={<ExitToApp />}
          onClick={() => {
            navigate(`/${user?.defaultWorkspace}`)
            reloadSession()
          }}
        >
          {user?.defaultWorkspace}
        </Button>
      </Root>
    </>
  )
}

export default NoAccess
