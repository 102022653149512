import React from 'react'
import { Field as FormikField, ErrorMessage } from 'formik'
import { TextField } from 'formik-material-ui'
import styled from 'styled-components'
import { FieldT, FieldVariantEnum } from './types'

interface Props {
  field: FieldT
  variant?: FieldVariantEnum
}

const StyledField = styled(FormikField)`
  max-width: 800px;
`

const DateField: React.FC<Props> = ({ field }) => {
  return (
    <>
      <StyledField
        key={field.name}
        name={field.name}
        label={field.title}
        type='date'
        variant='outlined'
        fullWidth
        InputLabelProps={{ shrink: true }}
        component={TextField}
      />
      <ErrorMessage name={field.name} />
    </>
  )
}

export default DateField
