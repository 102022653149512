/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type TemplateEntityTypeEnum = "contacts" | "containers" | "shipments" | "%future added value";
export type FileCreationDialogGeneratableDocsQueryVariables = {
    entityType: TemplateEntityTypeEnum;
    entitySlug: string;
};
export type FileCreationDialogGeneratableDocsQueryResponse = {
    readonly generatableDocuments: ReadonlyArray<{
        readonly templateSlug: string;
        readonly " $fragmentRefs": FragmentRefs<"GeneratableDocItem_document">;
    }>;
};
export type FileCreationDialogGeneratableDocsQuery = {
    readonly response: FileCreationDialogGeneratableDocsQueryResponse;
    readonly variables: FileCreationDialogGeneratableDocsQueryVariables;
};



/*
query FileCreationDialogGeneratableDocsQuery(
  $entityType: TemplateEntityTypeEnum!
  $entitySlug: String!
) {
  generatableDocuments(entityType: $entityType, entitySlug: $entitySlug) {
    templateSlug
    ...GeneratableDocItem_document
  }
}

fragment EntityAttachmentListPreviewer_attachment on EntityAttachment {
  url
  downloadUrl
  name
  contentType
  previewUrl
  documentType
  entityType
  entitySlug
  attachmentSlug
  thumbnailImage
  ...EntityAttachmentRoleVisibility_attachment
}

fragment EntityAttachmentRoleVisibility_attachment on EntityAttachment {
  name
  attachmentSlug
  entityType
  entitySlug
  roleVisibilities {
    role
    roleShortTitle
    roleTitle
    visible
  }
}

fragment GeneratableDocItem_document on GeneratableDocument {
  templateSlug
  title
  docType
  errors
  existingAttachment {
    attachmentSlug
    uploadedAtDisplay
    ...EntityAttachmentListPreviewer_attachment
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "entitySlug"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "entityType"
},
v2 = [
  {
    "kind": "Variable",
    "name": "entitySlug",
    "variableName": "entitySlug"
  },
  {
    "kind": "Variable",
    "name": "entityType",
    "variableName": "entityType"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "templateSlug",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "FileCreationDialogGeneratableDocsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "GeneratableDocument",
        "kind": "LinkedField",
        "name": "generatableDocuments",
        "plural": true,
        "selections": [
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "GeneratableDocItem_document"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "FileCreationDialogGeneratableDocsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "GeneratableDocument",
        "kind": "LinkedField",
        "name": "generatableDocuments",
        "plural": true,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "docType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "errors",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "EntityAttachment",
            "kind": "LinkedField",
            "name": "existingAttachment",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "attachmentSlug",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "uploadedAtDisplay",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "url",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "downloadUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "contentType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "previewUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "documentType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "entityType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "entitySlug",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "thumbnailImage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "RoleVisibility",
                "kind": "LinkedField",
                "name": "roleVisibilities",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "role",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "roleShortTitle",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "roleTitle",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "visible",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7149c003290ec7472e71d930b806267f",
    "id": null,
    "metadata": {},
    "name": "FileCreationDialogGeneratableDocsQuery",
    "operationKind": "query",
    "text": "query FileCreationDialogGeneratableDocsQuery(\n  $entityType: TemplateEntityTypeEnum!\n  $entitySlug: String!\n) {\n  generatableDocuments(entityType: $entityType, entitySlug: $entitySlug) {\n    templateSlug\n    ...GeneratableDocItem_document\n  }\n}\n\nfragment EntityAttachmentListPreviewer_attachment on EntityAttachment {\n  url\n  downloadUrl\n  name\n  contentType\n  previewUrl\n  documentType\n  entityType\n  entitySlug\n  attachmentSlug\n  thumbnailImage\n  ...EntityAttachmentRoleVisibility_attachment\n}\n\nfragment EntityAttachmentRoleVisibility_attachment on EntityAttachment {\n  name\n  attachmentSlug\n  entityType\n  entitySlug\n  roleVisibilities {\n    role\n    roleShortTitle\n    roleTitle\n    visible\n  }\n}\n\nfragment GeneratableDocItem_document on GeneratableDocument {\n  templateSlug\n  title\n  docType\n  errors\n  existingAttachment {\n    attachmentSlug\n    uploadedAtDisplay\n    ...EntityAttachmentListPreviewer_attachment\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '5734d8030725c3855baf62c5028fee6f';
export default node;
