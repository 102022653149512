/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ListPageFilter_filter = {
    readonly key: string;
    readonly name: string | null;
    readonly values: ReadonlyArray<{
        readonly label: string;
        readonly displayLabel: string;
        readonly selected: boolean;
        readonly " $fragmentRefs": FragmentRefs<"ListPageFilter_filterValue">;
    }> | null;
    readonly " $refType": "ListPageFilter_filter";
};
export type ListPageFilter_filter$data = ListPageFilter_filter;
export type ListPageFilter_filter$key = {
    readonly " $data"?: ListPageFilter_filter$data;
    readonly " $fragmentRefs": FragmentRefs<"ListPageFilter_filter">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ListPageFilter_filter",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "key",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FilterValue",
      "kind": "LinkedField",
      "name": "values",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayLabel",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "selected",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ListPageFilter_filterValue"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Filter",
  "abstractKey": null
};
(node as any).hash = 'c0a984dbf464e41e1a1eb583a3f4605f';
export default node;
