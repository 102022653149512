import React, { Suspense } from 'react'
import LiveShareShipmentShowHomeContent from './LiveShareShipmentShowHomeContent'
import LiveShareShipmentShowHeroCard from './LiveShareShipmentShowHeroCard'
import { graphql } from 'babel-plugin-relay/macro'
import { useFragment } from 'react-relay/hooks'
import { LiveShareShipmentShow_shipmentShow$key } from '../../__generated__/LiveShareShipmentShow_shipmentShow.graphql'

interface Params {
  shipmentShow: LiveShareShipmentShow_shipmentShow$key
}

export const LiveShareShipmentShow: React.FC<Params> = ({ shipmentShow: data }) => {
  const shipmentShow = useFragment(showFragment, data)
  return (
    <>
      <Suspense fallback={<LiveShareShipmentShowHeroCard.Skeleton />}>
        <LiveShareShipmentShowHeroCard shipment={shipmentShow.shipment} item={shipmentShow.item} />
      </Suspense>
      <Suspense fallback={<LiveShareShipmentShowHomeContent.Skeleton />}>
        <LiveShareShipmentShowHomeContent shipmentShow={shipmentShow} />
      </Suspense>
    </>
  )
}

export default LiveShareShipmentShow

const showFragment = graphql`
  fragment LiveShareShipmentShow_shipmentShow on LiveShareShowShipment {
    shipment {
      slug
      ...LiveShareShipmentShowHeroCardQuery_shipment
    }

    item {
      slug
      ...LiveShareShipmentShowHeroCardQuery_item
    }
    ...LiveShareShipmentShowHomeContentQuery_shipmentShow
  }
`
