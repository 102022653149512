import { graphql } from 'babel-plugin-relay/macro'
import React, { useCallback } from 'react'
import { fetchQuery } from 'react-relay'
import { useLazyLoadQuery } from 'react-relay/hooks'
import { useLocation } from 'react-router'
import NoAccess from '../components/NoAccess'
import NoWorkspace from '../components/NoWorkspace'
import useWorkspace from '../hooks/useWorkspace'
import environment from '../relayEnvironment'
import SessionContext from '../SessionContext'
import { LayoutOrchestratorSessionQuery } from '../__generated__/LayoutOrchestratorSessionQuery.graphql'
import External from './External'
import Internal from './Internal'
import Public from './Public'

const LayoutOrchestrator: React.FC = () => {
  const data = useLazyLoadQuery<LayoutOrchestratorSessionQuery>(query, {})
  const roles = data?.sessionMe?.contact?.displayRoles
  const isLoggedIn = data.sessionMe !== null
  const location = useLocation()

  const refresh = useCallback(() => {
    fetchQuery(environment, query, {})
  }, [])

  const [currentWorksapce] = useWorkspace()
  if (location.pathname === '/' && data?.sessionMe?.defaultWorkspace) {
    window.location.href = `/${data?.sessionMe?.defaultWorkspace}`
    return <p>Loading {data?.sessionMe?.defaultWorkspace}...</p>
  }

  return (
    <SessionContext.Provider
      value={{
        user: data.sessionMe,
        isLoggedIn: isLoggedIn,
        reloadSession: refresh,
      }}
    >
      {isLoggedIn ? (
        data && data.sessionMe && data.sessionMe.allowedWorkspaces.includes(currentWorksapce) ? (
          data.sessionMe.contact?.contactType === 'internal' ? (
            <Internal />
          ) : (
            <External roles={roles} />
          )
        ) : currentWorksapce && currentWorksapce.length >= 1 ? (
          <NoWorkspace />
        ) : (
          <>
            <NoAccess />
          </>
        )
      ) : (
        <Public />
      )}
    </SessionContext.Provider>
  )
}

const query = graphql`
  query LayoutOrchestratorSessionQuery {
    sessionMe {
      username
      contact {
        slug
        name
        workspace
        contactType
        displayRoles {
          name
          value
        }
        profileUrl
        email
        position
      }
      defaultWorkspace
      allowedWorkspaces
      registeredInvitedContacts {
        name
        workspace
        email
      }
    }
  }
`

export default LayoutOrchestrator
