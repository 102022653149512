import React from 'react'
import styled from 'styled-components'
import { Card, Tooltip, Typography } from '@material-ui/core'
import { media } from '../../theme'
import Hero from '../Hero'
import { useFormikContext } from 'formik'
import Button from '../Button'
import { Skeleton } from '@material-ui/lab'
import InfoIcon from '@material-ui/icons/Info'

interface Props {
  title: string
  onCancel?: () => void
  onSubmit?: () => void
  cancelCta?: string
  saveCta?: string
}

const ActionBar = styled(Card)`
  position: absolute;
  z-index: 1;
  left: 0;
  bottom: 0;
  right: 0;
`

const ActionBarContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;

  ${media.small`
    padding: 8px;
  `}
`

const FormActionBar: React.FC<Props> & { Skeleton: React.FC } = ({ title, onSubmit, onCancel, cancelCta, saveCta }) => {
  const { setTouched, isSubmitting, dirty, errors, touched } = useFormikContext()
  const [open, setOpen] = React.useState(false)

  const handleSubmit = () => {
    setTouched(getTouchedObj(errors))
    if (Object.keys(errors).length === 0) {
      if (onSubmit) onSubmit()
    } else {
      setOpen(true)
    }
  }

  const getTouchedObj = (errors: any) => {
    const tmp_touched: any = touched
    Object.keys(errors).forEach((key) => {
      if (Array.isArray(errors[key])) {
        errors[key].forEach((val: any, index: any) => {
          if (index === 0) tmp_touched[key] = []
          tmp_touched[key].push(getTouchedObj(val))
        })
      } else {
        tmp_touched[key] = true
      }
    })

    return tmp_touched
  }

  return (
    <ActionBar elevation={3}>
      <Hero backgroundColor='transparent'>
        <>
          <ActionBarContent>
            <Typography variant='h6'>
              {title}
              {Object.keys(errors).length !== 0 && open && (
                <>
                  <Tooltip
                    title={
                      <h2>
                        <pre>{Object.values(errors).join('\n')}</pre>
                      </h2>
                    }
                    interactive
                  >
                    <Button>
                      <>
                        <InfoIcon style={{ color: 'red', padding: '0 2px' }} />{' '}
                        <Typography style={{ color: 'red' }}>{Object.keys(errors).length} errors found</Typography>
                      </>
                    </Button>
                  </Tooltip>
                </>
              )}
            </Typography>
            <div>
              <Button variant='outlined' size='large' onClick={onCancel}>
                {cancelCta || 'Cancel'}
              </Button>
              <Button onClick={handleSubmit} disabled={isSubmitting || !dirty} variant='contained' size='large'>
                {saveCta || 'Save'}
              </Button>
            </div>
          </ActionBarContent>
        </>
      </Hero>
    </ActionBar>
  )
}

FormActionBar.Skeleton = () => (
  <ActionBar elevation={3}>
    <Hero backgroundColor='transparent'>
      <>
        <ActionBarContent>
          <Skeleton>
            <Typography variant='h6'>.......................</Typography>
          </Skeleton>
          <div>
            <Button variant='outlined' size='large' disabled>
              Cancel
            </Button>
            <Button variant='contained' size='large' disabled>
              Save
            </Button>
          </div>
        </ActionBarContent>
      </>
    </Hero>
  </ActionBar>
)

export default FormActionBar
