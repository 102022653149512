import React from 'react'
import { useLazyLoadQuery } from 'react-relay/hooks'
import NiceFormik from './Form/NiceFormik'
import { graphql } from 'babel-plugin-relay/macro'
import { DraftContactAddQuery } from '../__generated__/DraftContactAddQuery.graphql'
import { DraftContactAddMutation } from '../__generated__/DraftContactAddMutation.graphql'
import useNiceMutation from '../mutations/useNiceMutation'
import { Form } from 'formik'
import DraftContactForm from './DraftContactForm'
import { DialogActions, DialogContent } from '@material-ui/core'
import LoadingDots from './LoadingDots'
import Button from './Button'
import { FormValues } from '../pages/Contact/ContactAdd'

interface Props {
  entitySlug: string
  entityType: string
  open: boolean
  title: string
  onClose: () => void
  onEdit: () => void
}

const DraftContactAdd: React.FC<Props> = (props) => {
  const data = useLazyLoadQuery<DraftContactAddQuery>(query, {})
  const fields = data.infoAddDraftContactForm.formSchema.fieldSet
  const fieldMaster = Object.fromEntries(fields.map((field) => [field.name, field]))
  const [commit, loading] = useNiceMutation<DraftContactAddMutation>(mutation)
  const initialValues = {
    ticketType: 'request_to_create_a_new_contact',
    entitySlug: props.entitySlug,
    entityType: `${props.entityType}s`,
    firstName: '',
    lastName: '',
    salutation: '',
    format: '',
    country: '',
    roles: [],
    businessPhone: '',
    address: '',
    place: '',
  }

  return (
    <>
      <NiceFormik
        initialValues={initialValues}
        validate={(values) => {
          const errors: Partial<FormValues> = {}
          if (!(values.roles as string[]).includes('transporter') && !values.address) {
            errors.address = 'Address is a required field'
          }
          if (!(values.roles as string[]).includes('transporter') && !values.place) {
            errors.place = 'City is a required field'
          }
          if ((values.roles as string[]).includes('transporter') && !values.businessPhone) {
            errors.businessPhone = 'businessPhone is a required field'
          }
          return errors
        }}
        onSubmit={(values, actions) => {
          commit({
            variables: {
              input: values as any,
            },
            onError: () => actions.setSubmitting(false),
            onCompleted: (res, errors) => {
              actions.setSubmitting(false)
              if (!errors) {
                props.onClose()
              }
            },
          })
        }}
        formSchema={fields}
      >
        {({ submitForm }) => (
          <Form>
            <DialogContent dividers={true}>
              <DraftContactForm fieldMaster={fieldMaster} mode='add'></DraftContactForm>
            </DialogContent>
            <DialogActions>
              <Button onClick={props.onClose} variant='contained'>
                Cancel
              </Button>
              <Button onClick={submitForm} variant='contained'>
                {loading ? <LoadingDots /> : 'Save'}
              </Button>
            </DialogActions>
          </Form>
        )}
      </NiceFormik>
    </>
  )
}

const query = graphql`
  query DraftContactAddQuery {
    infoAddDraftContactForm {
      formSchema {
        fieldSet {
          name
          type
          required
          title
          description
          format
          enum
          enumTitles
          placeholder
        }
      }
    }
  }
`
const mutation = graphql`
  mutation DraftContactAddMutation($input: infoAddDraftContactInput!) {
    infoAddDraftContact(input: $input) {
      clientMutationId
    }
  }
`
export default DraftContactAdd
