/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LinkEnum = "contact" | "container" | "shipment" | "ticket" | "vessel" | "voyage" | "%future added value";
export type ShowPageItem_data = {
    readonly slug: string;
    readonly title: string;
    readonly itemType: string;
    readonly image: {
        readonly " $fragmentRefs": FragmentRefs<"ItemImage_data">;
    } | null;
    readonly path: string | null;
    readonly statuses: ReadonlyArray<{
        readonly key: string;
        readonly value: {
            readonly " $fragmentRefs": FragmentRefs<"SemanticString_data">;
        };
    }> | null;
    readonly badges: ReadonlyArray<{
        readonly key: string;
        readonly " $fragmentRefs": FragmentRefs<"Badge_data">;
    }> | null;
    readonly actions: ReadonlyArray<{
        readonly key: string;
        readonly " $fragmentRefs": FragmentRefs<"Action_data">;
    }> | null;
    readonly lines: ReadonlyArray<{
        readonly key: string;
        readonly " $fragmentRefs": FragmentRefs<"SemanticLine_data">;
    }> | null;
    readonly keyFields: ReadonlyArray<{
        readonly key: string;
        readonly " $fragmentRefs": FragmentRefs<"KeyField_data">;
    }> | null;
    readonly linkSets: ReadonlyArray<{
        readonly links: ReadonlyArray<{
            readonly key: string;
            readonly type: LinkEnum;
            readonly " $fragmentRefs": FragmentRefs<"LinkCard_data">;
        }> | null;
    }> | null;
    readonly stickyNoteContent: string | null;
    readonly " $refType": "ShowPageItem_data";
};
export type ShowPageItem_data$data = ShowPageItem_data;
export type ShowPageItem_data$key = {
    readonly " $data"?: ShowPageItem_data$data;
    readonly " $fragmentRefs": FragmentRefs<"ShowPageItem_data">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ShowPageItem_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "itemType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ItemImage_data"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "path",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Status",
      "kind": "LinkedField",
      "name": "statuses",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "SemanticString",
          "kind": "LinkedField",
          "name": "value",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SemanticString_data"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Badge",
      "kind": "LinkedField",
      "name": "badges",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Badge_data"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Action",
      "kind": "LinkedField",
      "name": "actions",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Action_data"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SemanticLine",
      "kind": "LinkedField",
      "name": "lines",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SemanticLine_data"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "KeyField",
      "kind": "LinkedField",
      "name": "keyFields",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "KeyField_data"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LinkSet",
      "kind": "LinkedField",
      "name": "linkSets",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Link",
          "kind": "LinkedField",
          "name": "links",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "type",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "LinkCard_data"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "stickyNoteContent",
      "storageKey": null
    }
  ],
  "type": "ListItem",
  "abstractKey": null
};
})();
(node as any).hash = 'b411e596c50a09d287c49ea6d0932fee';
export default node;
