import { Environment, FetchFunction, Network, RecordSource, Store } from 'relay-runtime'
// import { createActionCableHandler } from './createActionCableHandler'
import { getToken, serverUrl } from './api/server'

const error = (status: number, statusText: string): Error => {
  const obj = Error(`${statusText} (${status})`)
  obj.name = `HTTP_${status}`
  return obj
}

const fetchQuery: FetchFunction = (operation, variables, _, uploadables) => {
  const token = getToken()
  let body: BodyInit
  let headers: HeadersInit = {
    Authorization: `Bearer ${token}`,
  }

  if (uploadables) {
    if (!window.FormData) {
      throw new Error('Uploading files without `FormData` not supported.')
    }

    const formData = new FormData()
    formData.append('query', operation.text || '')
    formData.append('variables', JSON.stringify(variables))

    Object.keys(uploadables).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(uploadables, key)) {
        formData.append(key, uploadables[key])
      }
    })

    body = formData
  } else {
    headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    }
    body = JSON.stringify({
      query: operation.text,
      variables,
    })
  }

  const workspace = window.location.pathname.split('/')[1]
  const fetchUrl = workspace ? `${serverUrl}/${workspace}/graphql?op=${operation.name}` : `${serverUrl}/graphql?op=${operation.name}`
  return fetch(fetchUrl, {
    method: 'POST',
    headers: headers,
    body: body,
  })
    .then((response) =>
      response.json().catch((e) => {
        console.log('Error parsing response as json', e)
        throw error(response.status, response.statusText)
      })
    )
    .then((responseJson) => {
      if (responseJson.error && (operation.operationKind !== 'mutation' || responseJson.status === 500)) {
        console.log(`Assuming 500: ${JSON.stringify(responseJson).slice(0, 500)}`)
        throw error(500, responseJson.error.message || 'Something went wrong')
      }
      // if (responseJson.errors && responseJson.errors.length > 0 && responseJson.data === null) {
      //   if (responseJson.errors[0].message.includes('Cannot return null')) {
      //     console.log(`Assuming 404: ${JSON.stringify(responseJson).slice(0, 500)}`)
      //     throw error(404, responseJson.errors[0].message || 'Not found')
      //   }
      // }
      if (responseJson.errors && operation.operationKind !== 'mutation') {
        return {
          ...responseJson,
          data: null,
        }
      }
      return responseJson
    })
    .catch((err) => {
      throw error(0, err.message)
    })
}

// const cableUrl = serverUrl.replace(/^http/, 'ws')
// const cable = ActionCable.createConsumer(`${cableUrl}/cable`)

// const subscriptionHandler = createActionCableHandler({
//   cable: cable
// })

const environment = new Environment({
  network: Network.create(fetchQuery),
  store: new Store(new RecordSource()),
})

export default environment
