import get from 'lodash/get'
import React, { useEffect, useMemo, useState } from 'react'

import styled from 'styled-components'
import Dropdown, { OptionType } from '../Dropdown'
import WarehouseIcon from '../../assets/public/v2/warehouse.svg'
import TransporterIcon from '../../assets/public/v2/transporter.svg'
import PayerIcon from '../../assets/public/v2/payer.svg'
import ContactIcon from '../../assets/public/v2/contact.svg'
import ShowPageInDrawer from '../ShowPageInDrawer'
import { RendererOptions } from '../DataTable'
import { Typography, IconButton, CircularProgress } from '@material-ui/core'
import { Icon, InfoContainer } from './RendererHoc'
import { RendererRoot } from './RendererHoc'
import { InfoOutlined } from '@material-ui/icons'
import { set, startCase } from 'lodash'
import { useQuery } from 'urql'

const ROLE_ICONS: Record<string, string> = {
  warehouse: WarehouseIcon,
  transporter: TransporterIcon,
  payer: PayerIcon,
}

const StyledText = styled(Typography)`
  font-size: inherit;
  color: ${(props) => props.theme.palette.text.primary};

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

function ContactRenderer<T extends Record<string, any>>({
  anchorEl: outerAnchorEl,
  identifier,
  rowData,
  name,
  editable,
  value: externalValue,
  metaKey = 'slug',
  onSubmit,
}: RendererOptions<T>) {
  const role = identifier.split('.')[0]
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(outerAnchorEl)
  const [search, setSearch] = React.useState((externalValue as string) || '')
  const [{ data, fetching }] = useQuery({
    query: query,
    variables: search.length > 2 ? { role, searchQuery: search } : { role, searchQuery: '' },
    pause: !role,
  })

  const open = Boolean(anchorEl)

  const rendererValue = get(rowData, identifier)
  const rendererMeta = get(rowData, metaKey)

  const options = useMemo(
    () =>
      (data?.v2ContactList || []).map((contact: any) => ({
        label: contact.name,
        value: contact.slug,
        icon: ROLE_ICONS[role] || ContactIcon,
        meta: get(contact, metaKey),
      })),
    [data]
  )

  const contactValueOption: OptionType = options.find((opt: OptionType) => opt.value === rendererValue) || {
    label: startCase(rendererValue),
    value: rendererValue,
    icon: ROLE_ICONS[role] || ContactIcon,
    meta: rendererMeta,
  }

  const [_value, setValue] = useState<OptionType>(contactValueOption)
  const value = options.find((opt: OptionType) => opt.value === externalValue) || _value

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    editable && setAnchorEl(event.currentTarget)
  }

  const handleOnChange = (value: OptionType) => {
    setValue(options.find((opt: OptionType) => opt.label === value.label) || contactValueOption)
    const newLinkedContacts = (rowData?.linkedContacts || []).slice()
    const existing = newLinkedContacts.find((c: any) => c.role === role)
    if (existing) {
      existing['contactSlug'] = value.value
    } else {
      newLinkedContacts.push({ role: role, contactSlug: value.value, icon: ROLE_ICONS[role] || ContactIcon })
    }

    set(rowData, identifier, value.value)

    onSubmit?.({
      slug: rowData.slug,
      linkedContacts: newLinkedContacts,
    } as any)
    handleClose()
  }

  useEffect(() => {
    const newValue = options.find((opt: OptionType) => opt.label === get(rowData, identifier)) || contactValueOption
    if (options.length > 0 && value.value !== newValue.value) {
      if (newValue.value) setValue(newValue)
    }
  }, [options, rowData, identifier, value.value])

  const slug: string = useMemo(() => get(rowData, `${identifier.split('.')[0]}.slug`), [rowData, identifier])

  const [show, setShow] = useState(false)
  const showMore = !!value.value

  return (
    <>
      <RendererRoot
        role='button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <Icon src={ROLE_ICONS[role] || ContactIcon} />
        <StyledText title={value?.label}>{value?.label || '--'}</StyledText>
        {fetching && !value?.label && <CircularProgress variant='indeterminate' size={12} color='secondary' />}
        {showMore && (
          <InfoContainer data-hoverable>
            <IconButton
              size='small'
              onClick={(e) => {
                e.stopPropagation()
                setShow(true)
              }}
            >
              <InfoOutlined fontSize='small' />
            </IconButton>
          </InfoContainer>
        )}
      </RendererRoot>
      <Dropdown
        title={name}
        search={search}
        onSearch={(value) => setSearch(value)}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        options={options}
        onSelect={handleOnChange}
        value={value?.value}
      />
      {showMore && <ShowPageInDrawer entityType='contact' entitySlug={slug} open={show} onClose={() => setShow(false)} />}
    </>
  )
}

const query = `
  query ContactRendererQuery($role: String!, $searchQuery: String) {
    v2ContactList(role: $role, searchQuery: $searchQuery) {
      slug
      name
    }
  }
`

ContactRenderer.operators = ['eq', 'neq', 'is_null', 'not_null']

export default ContactRenderer
