/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type insignRegisterDocumentForSigningInput = {
    clientMutationId?: string | null;
    entitySlug: string;
    entityType: string;
    attachmentSlugIds?: Array<string> | null;
};
export type ExternalUploadDocumentsWizardSendSignatureMutationVariables = {
    input: insignRegisterDocumentForSigningInput;
};
export type ExternalUploadDocumentsWizardSendSignatureMutationResponse = {
    readonly insignRegisterDocumentForSigning: {
        readonly clientMutationId: string | null;
    } | null;
};
export type ExternalUploadDocumentsWizardSendSignatureMutation = {
    readonly response: ExternalUploadDocumentsWizardSendSignatureMutationResponse;
    readonly variables: ExternalUploadDocumentsWizardSendSignatureMutationVariables;
};



/*
mutation ExternalUploadDocumentsWizardSendSignatureMutation(
  $input: insignRegisterDocumentForSigningInput!
) {
  insignRegisterDocumentForSigning(input: $input) {
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "insignRegisterDocumentForSigningPayload",
    "kind": "LinkedField",
    "name": "insignRegisterDocumentForSigning",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ExternalUploadDocumentsWizardSendSignatureMutation",
    "selections": (v1/*: any*/),
    "type": "ApplicationCommand",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ExternalUploadDocumentsWizardSendSignatureMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "dc9d66c4c8ef2e9678a65fdf34a989aa",
    "id": null,
    "metadata": {},
    "name": "ExternalUploadDocumentsWizardSendSignatureMutation",
    "operationKind": "mutation",
    "text": "mutation ExternalUploadDocumentsWizardSendSignatureMutation(\n  $input: insignRegisterDocumentForSigningInput!\n) {\n  insignRegisterDocumentForSigning(input: $input) {\n    clientMutationId\n  }\n}\n"
  }
};
})();
(node as any).hash = '625d32ada6e5075b976c09caf1fcadf4';
export default node;
