/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LinkedContactsCard_draftContacts = {
    readonly nodes: ReadonlyArray<{
        readonly slug: string;
        readonly name: string;
        readonly displayRoles: string;
        readonly image: {
            readonly url: string | null;
            readonly alt: string | null;
        };
    } | null> | null;
    readonly " $refType": "LinkedContactsCard_draftContacts";
};
export type LinkedContactsCard_draftContacts$data = LinkedContactsCard_draftContacts;
export type LinkedContactsCard_draftContacts$key = {
    readonly " $data"?: LinkedContactsCard_draftContacts$data;
    readonly " $fragmentRefs": FragmentRefs<"LinkedContactsCard_draftContacts">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LinkedContactsCard_draftContacts",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "DraftContact",
      "kind": "LinkedField",
      "name": "nodes",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayRoles",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Image",
          "kind": "LinkedField",
          "name": "image",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "url",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "alt",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "DraftContactConnection",
  "abstractKey": null
};
(node as any).hash = 'fd8b82eb31df429286d2afd4124055d0';
export default node;
