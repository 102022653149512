/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type VinScrapingFailureLogsQueryVariables = {
    page?: number | null;
    perPage?: number | null;
};
export type VinScrapingFailureLogsQueryResponse = {
    readonly vinScrapingFailureLogs: {
        readonly " $fragmentRefs": FragmentRefs<"VinScrapingFailureLogsWithParams_list">;
    };
};
export type VinScrapingFailureLogsQuery = {
    readonly response: VinScrapingFailureLogsQueryResponse;
    readonly variables: VinScrapingFailureLogsQueryVariables;
};



/*
query VinScrapingFailureLogsQuery(
  $page: Int
  $perPage: Int
) {
  vinScrapingFailureLogs(page: $page, perPage: $perPage) {
    ...VinScrapingFailureLogsWithParams_list
  }
}

fragment VinScrapingFailureLogsWithParams_list on VinScrapeFailureLogs {
  vinScrapeLogs {
    shipmentSlug
    vin
    retryCount
    hyperlink
  }
  paginationMeta {
    totalPages
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "page"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "perPage"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page"
  },
  {
    "kind": "Variable",
    "name": "perPage",
    "variableName": "perPage"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "VinScrapingFailureLogsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "VinScrapeFailureLogs",
        "kind": "LinkedField",
        "name": "vinScrapingFailureLogs",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "VinScrapingFailureLogsWithParams_list"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "VinScrapingFailureLogsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "VinScrapeFailureLogs",
        "kind": "LinkedField",
        "name": "vinScrapingFailureLogs",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VinScrapeFailureLog",
            "kind": "LinkedField",
            "name": "vinScrapeLogs",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "shipmentSlug",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "vin",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "retryCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hyperlink",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Paginate",
            "kind": "LinkedField",
            "name": "paginationMeta",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalPages",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "95489073585dfe7f663461df177d14bb",
    "id": null,
    "metadata": {},
    "name": "VinScrapingFailureLogsQuery",
    "operationKind": "query",
    "text": "query VinScrapingFailureLogsQuery(\n  $page: Int\n  $perPage: Int\n) {\n  vinScrapingFailureLogs(page: $page, perPage: $perPage) {\n    ...VinScrapingFailureLogsWithParams_list\n  }\n}\n\nfragment VinScrapingFailureLogsWithParams_list on VinScrapeFailureLogs {\n  vinScrapeLogs {\n    shipmentSlug\n    vin\n    retryCount\n    hyperlink\n  }\n  paginationMeta {\n    totalPages\n  }\n}\n"
  }
};
})();
(node as any).hash = '1a3da3509bb0fd67758874d1031ad813';
export default node;
