import React, { useState } from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import styled from 'styled-components'
import { ItemLink_item$key } from '../__generated__/ItemLink_item.graphql'
import { useFragment } from 'react-relay/hooks'
import { Link } from 'react-router-dom'
import { Avatar } from '@material-ui/core'
import ShowPageInDrawer from './ShowPageInDrawer'

interface Props {
  item: ItemLink_item$key
}

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  color: inherit;
  text-decoration: none;
  padding: 4px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`

const ItemImage = styled(Avatar)`
  margin-left: 4px;
  height: 16px;
  width: 16px;
`

const previewableItemTypes = ['shipment', 'container', 'voyage', 'contact', 'vessel']

const ItemLink: React.FC<Props> = ({ item: data }) => {
  const item = useFragment(fragment, data)
  const [showPageOpen, setShowPageOpen] = useState(false)
  const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (e.ctrlKey) return

    e.preventDefault()
    setShowPageOpen(true)
  }

  return (
    <>
      <StyledLink target='_blank' to={item.path || '#'} onClick={handleLinkClick}>
        {item.title}
        {item.image && <ItemImage src={item?.image?.url as string} />}
      </StyledLink>
      {previewableItemTypes.includes(item.itemType) && (
        <ShowPageInDrawer entityType={item.itemType as any} entitySlug={item.slug} open={showPageOpen} onClose={() => setShowPageOpen(false)} />
      )}
    </>
  )
}

const fragment = graphql`
  fragment ItemLink_item on ListItem {
    title
    path
    itemType
    slug
    image {
      url
    }
  }
`

export default ItemLink
