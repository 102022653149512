import { Paper, Typography, Avatar } from '@material-ui/core'
import { graphql } from 'babel-plugin-relay/macro'
import React, { useState } from 'react'
import { useFragment } from 'react-relay/hooks'
import styled from 'styled-components'
import { LiveShare_liveShares$key } from '../__generated__/LiveShare_liveShares.graphql'
import Button from './Button'
import LiveShareAddDialog from './LiveShareAddDialog'
import LiveShareDrawer from './LiveShareDrawer'
import { media } from '../theme'
import { useNavigate } from 'react-router'
import useWorkspace from '../hooks/useWorkspace'

interface Props {
  entitySlug: string
  liveShares: LiveShare_liveShares$key
  variant: 'full' | 'compact'
}

const Root = styled(Paper)`
  padding-top: 16px;
  margin-bottom: 16px;
`
const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 16px;
  margin-bottom: 8px;
`

const LiveShareDetailsContainer = styled.div`
  padding: 10px 8px 8px 0;
  white-space: pre;
  overflow: hidden;
  background-color: ${(props) => props.theme.palette.background.paper};
  flex: 1;
`
const NonOverflowText = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
`
const TitleText = styled(NonOverflowText)`
  line-height: 1;
`
const FullDivLink = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 8px;

  cursor: pointer;
  background-color: ${(props) => props.theme.palette.background.paper};

  &:hover {
    filter: brightness(0.95);
  }
`

const StyledDivLink = styled.div`
  display: flex;
  padding: 10px 16px;
  cursor: pointer;
  background-color: ${(props) => props.theme.palette.background.paper};

  &:hover {
    filter: brightness(0.995);
  }
`

const SubTitle = styled(Typography)`
  display: inline-block;
  font-style: oblique;
  font-size: 0.77em;
`

const LinkAvatarContainer = styled.div`
  padding: 0 10px 0 10px;
  height: 100%;
  white-space: pre;
`

const LinkAvatar = styled(Avatar)`
  width: 50px;
  height: 50px;
`

const NoDataText = styled(Typography)`
  color: ${(props) => props.theme.customPalette.text.noData};
  text-align: center;
  padding: 16px;
  font-weight: 400;
`

const ShowFieldsContainer = styled.div`
  margin: 6px 30px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 8px;
  width: 100%;

  ${media.small`
    grid-template-columns: repeat(2, 1fr);
  `}
`

const Container = styled.div`
  max-width: 200px;
  width: 100%;
  margin: 2px 0;
`

const Label = styled(Typography)`
  font-size: 12px;
  color: #333;
`

const Value = styled(Typography)`
  color: #333;
  font-weight: 500;
  box-sizing: content-box;
  white-space: nowrap;
  overflow: hidden;
  line-height: 24px;
  text-overflow: ellipsis;
`

const FullViewContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
const LiveShare: React.FC<Props> = ({ liveShares: data, entitySlug, variant }) => {
  const liveShareConnection = useFragment(fragment, data)
  const [newLiveShareOpen, setNewLiveShareOpen] = useState(false)
  const [openLiveShareSlug, setOpenLiveShareSlug] = useState('')
  const closeDrawer = () => {
    setOpenLiveShareSlug('')
  }
  const [workspace] = useWorkspace()
  const navigate = useNavigate()
  const gotoLiveShareTab = (shipmentSlug: string) => navigate(`/${workspace}/shipment/${shipmentSlug}/shares`)
  const shares = variant === 'compact' ? liveShareConnection.nodes?.filter((s) => !s?.expired) : liveShareConnection.nodes

  return (
    <>
      <Root variant='outlined'>
        <TitleContainer>
          <Typography variant='h6'>Live Shares</Typography>
          <div>
            <Button onClick={() => setNewLiveShareOpen(true)}>New Share</Button>
            {variant === 'compact' && <Button onClick={() => gotoLiveShareTab(entitySlug)}>See All</Button>}
          </div>
        </TitleContainer>
        {liveShareConnection.nodes?.length === 0 && <NoDataText variant='h6'>No Live Shares</NoDataText>}
        {shares?.map(
          (liveShare) =>
            liveShare && (
              <>
                <FullDivLink
                  onClick={() => setOpenLiveShareSlug(liveShare.slug)}
                  style={{ backgroundColor: liveShare.expired ? '#ffebee' : '' }}
                >
                  <StyledDivLink style={{ backgroundColor: liveShare.expired ? '#ffebee' : '' }}>
                    <LinkAvatarContainer>
                      <LinkAvatar alt={liveShare.contact.firstName || ''}>{liveShare.contact.firstName.slice(0, 1).toUpperCase()}</LinkAvatar>
                    </LinkAvatarContainer>
                    <LiveShareDetailsContainer style={{ backgroundColor: liveShare.expired ? '#ffebee' : '' }}>
                      <TitleText variant='body1'>{liveShare.contact.firstName}</TitleText>
                      <SubTitle variant='subtitle2'>
                        {liveShare.expired ? 'Expired' : 'Expires'} {liveShare.expiresAt}
                      </SubTitle>
                    </LiveShareDetailsContainer>
                  </StyledDivLink>
                  {variant === 'full' && (
                    <>
                      <FullViewContainer>
                        <ShowFieldsContainer>
                          <Container>
                            <Label variant='caption' color='textSecondary'>
                              Generated By
                            </Label>
                            <Value variant='body1'>{liveShare.generatedBy === null ? '--' : liveShare.generatedBy}</Value>
                          </Container>
                          <Container>
                            <Label variant='caption' color='textSecondary'>
                              Generated At
                            </Label>
                            <Value variant='body1'>{liveShare.generatedAt === null ? '--' : liveShare.generatedAt}</Value>
                          </Container>
                          <Container>
                            <Label variant='caption' color='textSecondary'>
                              {liveShare.expired ? 'Expired' : 'Expires'}
                            </Label>
                            <Value variant='body1'>{liveShare.expiresAt === null ? '--' : liveShare.expiresAt}</Value>
                          </Container>
                        </ShowFieldsContainer>
                      </FullViewContainer>
                    </>
                  )}
                </FullDivLink>

                <LiveShareDrawer
                  key={liveShare.slug}
                  liveShareItem={liveShare}
                  open={liveShare.slug === openLiveShareSlug}
                  onClose={() => closeDrawer()}
                  entitySlug={entitySlug}
                />
              </>
            )
        )}
      </Root>
      <LiveShareAddDialog
        open={newLiveShareOpen}
        title='Create Live Share'
        onClose={() => setNewLiveShareOpen(false)}
        onEdit={() => setNewLiveShareOpen(false)}
        shipmentSlug={entitySlug.toString()}
      />
    </>
  )
}

const fragment = graphql`
  fragment LiveShare_liveShares on LiveShareConnection {
    nodes {
      name
      slug
      password
      url
      contact {
        firstName
        lastName
        email
      }
      email
      expiresAt
      generatedBy
      generatedAt
      requestedBy
      requestedAt
      expired
      ...LiveShareDrawer_liveshare
    }
  }
`
export default LiveShare
