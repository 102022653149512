/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ExternalListItemTypeEnum = "containers" | "external_contacts" | "item" | "shipments" | "tickets" | "voyages" | "%future added value";
export type FilterInput = {
    key: string;
    values: Array<string>;
};
export type ExternalLinkedContactsFieldQueryVariables = {
    type: ExternalListItemTypeEnum;
    searchQuery?: string | null;
    filters?: Array<FilterInput> | null;
    perPage?: number | null;
};
export type ExternalLinkedContactsFieldQueryResponse = {
    readonly externalItemList: {
        readonly items: {
            readonly nodes: ReadonlyArray<{
                readonly slug: string;
                readonly title: string;
                readonly image: {
                    readonly " $fragmentRefs": FragmentRefs<"ItemImage_data">;
                } | null;
            } | null> | null;
        };
    };
};
export type ExternalLinkedContactsFieldQuery = {
    readonly response: ExternalLinkedContactsFieldQueryResponse;
    readonly variables: ExternalLinkedContactsFieldQueryVariables;
};



/*
query ExternalLinkedContactsFieldQuery(
  $type: ExternalListItemTypeEnum!
  $searchQuery: String
  $filters: [FilterInput!]
  $perPage: Int
) {
  externalItemList(type: $type, searchQuery: $searchQuery, filters: $filters, perPage: $perPage) {
    items {
      nodes {
        slug
        title
        image {
          ...ItemImage_data
        }
        id
      }
    }
  }
}

fragment ItemImage_data on Image {
  url
  alt
  highlight
  shape
  overlay
  overlayIcon
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "perPage"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchQuery"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "type"
},
v4 = [
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  {
    "kind": "Variable",
    "name": "perPage",
    "variableName": "perPage"
  },
  {
    "kind": "Variable",
    "name": "searchQuery",
    "variableName": "searchQuery"
  },
  {
    "kind": "Variable",
    "name": "type",
    "variableName": "type"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ExternalLinkedContactsFieldQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "ExternalItemList",
        "kind": "LinkedField",
        "name": "externalItemList",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ExternalListItemConnection",
            "kind": "LinkedField",
            "name": "items",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ExternalListItem",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Image",
                    "kind": "LinkedField",
                    "name": "image",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "ItemImage_data"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "ExternalLinkedContactsFieldQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "ExternalItemList",
        "kind": "LinkedField",
        "name": "externalItemList",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ExternalListItemConnection",
            "kind": "LinkedField",
            "name": "items",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ExternalListItem",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Image",
                    "kind": "LinkedField",
                    "name": "image",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "url",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "alt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "highlight",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "shape",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "overlay",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "overlayIcon",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "88ac917315a7820d45c41932fbf59f15",
    "id": null,
    "metadata": {},
    "name": "ExternalLinkedContactsFieldQuery",
    "operationKind": "query",
    "text": "query ExternalLinkedContactsFieldQuery(\n  $type: ExternalListItemTypeEnum!\n  $searchQuery: String\n  $filters: [FilterInput!]\n  $perPage: Int\n) {\n  externalItemList(type: $type, searchQuery: $searchQuery, filters: $filters, perPage: $perPage) {\n    items {\n      nodes {\n        slug\n        title\n        image {\n          ...ItemImage_data\n        }\n        id\n      }\n    }\n  }\n}\n\nfragment ItemImage_data on Image {\n  url\n  alt\n  highlight\n  shape\n  overlay\n  overlayIcon\n}\n"
  }
};
})();
(node as any).hash = '39bdf6f218dc140063d09adbf9e164fc';
export default node;
