import { useLazyLoadQuery } from 'react-relay/hooks'
import { FetchPolicy, RenderPolicy } from 'react-relay/lib/relay-experimental/QueryResource'
import { CacheConfig, GraphQLTaggedNode, OperationType } from 'relay-runtime'
import { SubscribableEntityT, useEntitySubscription } from './useEntitySubscription'

const useLazyLoadQueryWithSubscription = <TQuery extends OperationType>(
  gqlQuery: GraphQLTaggedNode,
  variables: TQuery['variables'],
  options: {
    fetchKey?: string | number
    fetchPolicy?: FetchPolicy
    networkCacheConfig?: CacheConfig
    UNSTABLE_renderPolicy?: RenderPolicy
    subscriptionOptions: {
      entityType: SubscribableEntityT
      entitySlug: string
    }
  }
): TQuery['response'] => {
  const { subscriptionOptions, ...relayOptions } = options
  const response = useLazyLoadQuery<TQuery>(gqlQuery, variables, relayOptions)
  useEntitySubscription<TQuery>({
    ...subscriptionOptions,
    query: gqlQuery,
    variables: variables,
  })

  return response
}

export default useLazyLoadQueryWithSubscription
