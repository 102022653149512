import React from 'react'
import { graphql } from 'babel-plugin-relay/macro'

import { useNavigate } from 'react-router'
import Hero from '../../components/Hero'
import { useLazyLoadQuery } from 'react-relay/hooks'
import { VesselAddFormQuery } from '../../__generated__/VesselAddFormQuery.graphql'
import NiceFormik from '../../components/Form/NiceFormik'
import useNiceMutation from '../../mutations/useNiceMutation'
import { VesselAddMutation } from '../../__generated__/VesselAddMutation.graphql'
import useWorkspace from '../../hooks/useWorkspace'
import FormActionBar from '../../components/Form/FormActionBar'
import VesselForm from '../../forms/Vessel/VesselForm'
import FormContainer from '../../forms/FormContainer'

const VesselAdd: React.FC & { Skeleton: React.FC } = () => {
  const [workspace] = useWorkspace()
  const navigate = useNavigate()
  const data = useLazyLoadQuery<VesselAddFormQuery>(query, {})
  const fields = data.infoAddVesselForm.formSchema.fieldSet
  const fieldMaster = Object.fromEntries(fields.map((field) => [field.name, field]))
  const gotoVessel = (slug: string) => navigate(`/${workspace}/vessel/${slug}/home`)

  const [commit] = useNiceMutation<VesselAddMutation>(mutation)

  return (
    <>
      <NiceFormik
        initialValues={{}}
        onSubmit={(values, actions) => {
          commit({
            variables: {
              input: values as any,
            },
            onError: () => actions.setSubmitting(false),
            onCompleted: (res, errors) => {
              actions.setSubmitting(false)
              if (!errors) {
                gotoVessel(res.infoAddVessel?.vessel.slug || '')
              }
            },
          })
        }}
        formSchema={fields}
      >
        {({ submitForm }) => (
          <FormContainer>
            <Hero backgroundColor='#fafafa'>
              <VesselForm fieldMaster={fieldMaster} />
            </Hero>
            <FormActionBar title={'New Vessel'} onCancel={() => navigate(-1)} onSubmit={() => submitForm()} />
          </FormContainer>
        )}
      </NiceFormik>
    </>
  )
}

const query = graphql`
  query VesselAddFormQuery {
    infoAddVesselForm {
      formSchema {
        fieldSet {
          name
          type
          required
          title
          description
          format
          enum
          enumTitles
        }
      }
    }
  }
`

const mutation = graphql`
  mutation VesselAddMutation($input: infoAddVesselInput!) {
    infoAddVessel(input: $input) {
      vessel {
        slug
      }
    }
  }
`

VesselAdd.Skeleton = () => (
  <>
    <VesselForm.Skeleton />
    <FormActionBar.Skeleton />
  </>
)

export default VesselAdd
