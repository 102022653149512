import { Avatar, Chip, Typography } from '@material-ui/core'
import { Add, Edit } from '@material-ui/icons'
import { graphql } from 'babel-plugin-relay/macro'
import React, { useState } from 'react'
import { useLazyLoadQuery } from 'react-relay/hooks'
import { useNavigate, useParams } from 'react-router'
import styled from 'styled-components'
import WorkspaceBg from '../../asset/internal/workspace_bg.png'
import WorkspaceAvatarPic from '../../asset/internal/workspace_pic.png'
import Button from '../../components/Button'
import TabsUI from '../../components/TabsUI/TabsUI'
import useWorkspace from '../../hooks/useWorkspace'
import useNiceMutation from '../../mutations/useNiceMutation'
import { media } from '../../theme'
import { WorkspaceHeroMutation } from '../../__generated__/WorkspaceHeroMutation.graphql'
import { WorkspaceHeroQuery } from '../../__generated__/WorkspaceHeroQuery.graphql'
import SwitchWorkspaceDialog from './SwitchWorkspaceDialog'
import { tabs } from './tabs'

const HeroContainer = styled.div`
  position: relative;
  width: -webkit-fill-available;
  display: flex;
  color: white;
  margin-bottom: 16px;

  ${media.mobile`
    align-items: center;
    flex-direction: column;
    padding: 0 16px;
    box-sizing: content-box;
  `}/* &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    background: url("${WorkspaceBg}");
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: top;

    ${media.mobile`
      background-size:  auto 70%;
    `}
  } */
`

const Title = styled(Typography)`
  width: 100%;
  color: white;
  font-weight: 700;
`

const WorkspaceAvatar = styled(Avatar)`
  width: 144px;
  height: 144px;
  margin: 32px;

  ${media.small`
    margin: 16px;
    width: 72px;
    height: 72px;
  `}
`

const HeroContent = styled.div`
  width: 100%;
  padding-top: 16px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;

  ${media.small`
    flex-direction: column;
  `}
`

const TitleContent = styled.div`
  ${media.small`
    margin-bottom: 8px;
    text-align: center;
  `}
`

const ActionContainer = styled.div`
  ${media.small`
    margin-bottom: 8px;
  `}
`

interface AddButtonProps {
  customColor: string
}

const AddButton = styled(Button)<AddButtonProps>`
  color: ${(props) => props.customColor};
  background-color: white;
  font-weight: 700;

  &:hover {
    background-color: #f5f5f5;
  }
`

const EditButton = styled(Button)`
  color: white;
  border-color: white;
  border-width: 2px;
  font-weight: 700;
`

const SwitchContainer = styled.div`
  margin: 8px 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`

const SelectedChip = styled(Chip)<AddButtonProps>`
  background-color: white;
  color: ${(props) => props.customColor};
  font-weight: 700;
  margin-right: 8px;

  &:hover {
    background-color: #f5f5f5;
  }
`

const CustomChip = styled(Chip)`
  color: white;
  border-color: white;
  border-width: 2px;
  font-weight: 700;
  margin-right: 8px;
  & > svg {
    color: white;
  }
`

const WorkspaceHero: React.FC = () => {
  const { workspace: workspaceData } = useLazyLoadQuery<WorkspaceHeroQuery>(query, {})

  const [workspace, workspaceName] = useWorkspace()
  const [dialogWorkpace, setDialogWorkspace] = useState<string | null>(null)
  const currentWorkspace = workspaceData.find((ws) => ws.isCurrent)

  const navigate = useNavigate()

  const { tab } = useParams()
  const value = tabs.findIndex((t) => t.value === tab)
  const onTabChange = (e: React.ChangeEvent<unknown>, newValue: number) => {
    navigate(`/${currentWorkspace ? currentWorkspace.slug : workspace}/workspace/${tabs[newValue].value}`)
  }
  const workspaceColor = '#7ca1ff'
  const [commit] = useNiceMutation<WorkspaceHeroMutation>(mutation)

  const switchWorkspace = () => {
    dialogWorkpace &&
      commit({
        variables: { input: { workspaceSlug: dialogWorkpace } },
        onCompleted: (_, err) => {
          if (!err) window.location.href = '/'
        },
      })
  }

  return (
    <>
      <SwitchWorkspaceDialog open={!!dialogWorkpace} onClose={() => setDialogWorkspace(null)} onSwitch={switchWorkspace} />
      <HeroContainer>
        <WorkspaceAvatar src={WorkspaceAvatarPic} alt='workspace' />
        <HeroContent>
          <TitleContainer>
            <TitleContent>
              <Title variant='h5'>{currentWorkspace ? currentWorkspace.name : workspaceName} Workspace</Title>
              <Typography variant='subtitle1'>{currentWorkspace ? currentWorkspace.description : 'No Description'}</Typography>
              <SwitchContainer>
                {workspaceData.map((ws) =>
                  ws.isCurrent ? (
                    <SelectedChip key={ws.slug} clickable customColor={workspaceColor} label={`${ws.name} Workspace`} />
                  ) : (
                    <CustomChip
                      key={ws.slug}
                      clickable
                      disabled={!ws.isAllowed}
                      label={`${ws.name} Workspace`}
                      variant='outlined'
                      onClick={() => setDialogWorkspace(ws.slug)}
                    />
                  )
                )}
              </SwitchContainer>
            </TitleContent>
            <ActionContainer>
              <EditButton variant='outlined' disableElevation startIcon={<Edit />}>
                Edit
              </EditButton>
              <AddButton customColor={workspaceColor} variant='contained' disableElevation startIcon={<Add />}>
                Add Workspace
              </AddButton>
            </ActionContainer>
          </TitleContainer>
          <TabsUI isWhiteBackground={false} tabs={tabs} value={value} onChange={onTabChange} />
        </HeroContent>
      </HeroContainer>
    </>
  )
}

const mutation = graphql`
  mutation WorkspaceHeroMutation($input: infoSwitchWorkspaceInput!) {
    infoSwitchWorkspace(input: $input) {
      clientMutationId
    }
  }
`

const query = graphql`
  query WorkspaceHeroQuery {
    workspace {
      slug
      name
      description
      isCurrent
      isAllowed
    }
  }
`

export default WorkspaceHero
