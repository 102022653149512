import React, { Suspense, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import styled from 'styled-components'
import { DialogActions, DialogContent, DialogTitle, Divider, Drawer, Fade, IconButton, TextField, Tooltip, Typography } from '@material-ui/core'
import { TransitionProps } from '@material-ui/core/transitions'
import { EntityAttachmentListPreviewer_attachment$key } from '../../__generated__/EntityAttachmentListPreviewer_attachment.graphql'
import { useFragment, useLazyLoadQuery } from 'react-relay/hooks'
import { ArrowBack, CloudDownloadOutlined, Delete, NavigateBefore, NavigateNext } from '@material-ui/icons'
import { Alert, Autocomplete, Skeleton } from '@material-ui/lab'
import useNiceMutation from '../../mutations/useNiceMutation'
import { helpersFileUploadableDocsQuery } from '../../__generated__/helpersFileUploadableDocsQuery.graphql'
import DialogFormActionBar from '../Form/DialogFormActionBar'
import NiceFormik from '../Form/NiceFormik'
import OdysseyDialog from '../OdysseyDialog'
import EntityAttachmentRoleVisibility from './EntityAttachmentRoleVisibility'
import { previewForAttachment, uploadableDocsQuery } from './helpers'
import Button from '../Button'
import { Field } from 'formik'
import { TextField as FormikTextField } from 'formik-material-ui'
import { EntityAttachmentListPreviewerDetailsMutation } from '../../__generated__/EntityAttachmentListPreviewerDetailsMutation.graphql'
import { EntityAttachmentListPreviewerDeleteMutation } from '../../__generated__/EntityAttachmentListPreviewerDeleteMutation.graphql'
import LoadingDots from '../LoadingDots'
import SessionContext from '../../SessionContext'
import { EntityAttachmentListPreviewerThumbnailMutation } from '../../__generated__/EntityAttachmentListPreviewerThumbnailMutation.graphql'
import PhotoSizeSelectActualOutlinedIcon from '@material-ui/icons/PhotoSizeSelectActualOutlined'
import PhotoSizeSelectActualIcon from '@material-ui/icons/PhotoSizeSelectActual'
import { EntityAttachmentListPreviewerRemoveThumbnailMutation } from '../../__generated__/EntityAttachmentListPreviewerRemoveThumbnailMutation.graphql'
import { media } from '../../theme'

interface Props {
  attachments: readonly (({ readonly attachmentSlug: string } & EntityAttachmentListPreviewer_attachment$key) | null)[]
  intialPreviewedAttachmentSlug: string | null
  open: boolean
  onClose: () => void
  attachmentsEditable?: boolean
}

const HorizontalFlex = styled.div`
  display: flex;
  align-items: center;
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  pointer-events: all;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.65) 0%, transparent 100%);
`

const AttachmentInlineViewNameContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: colum;
  ${media.small`
      width: 0;
      flex: 1;
  `}
`

const TruncatedText = styled(Typography)`
  width: 100%;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  align-self: stretch;
`

const StyledDrawerContent = styled.div`
  width: 100%;
  height: 100%;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
`

const UnstyledLink = styled.a`
  color: unset;
  text-decoration: unset;
`

const StyledDivider = styled(Divider)`
  background-color: ${(props) => (props.theme.palette.type === 'dark' ? props.theme.palette.grey[300] : props.theme.palette.grey[700])};
`

const DrawerTransition = React.forwardRef(function DrawerTransition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Fade ref={ref} {...props} />
})

const StyledDrawer = styled(Drawer).attrs({ TransitionComponent: DrawerTransition })`
  top: 0;

  & > .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.85);
    top: 0;
  }

  & > .MuiDrawer-paper {
    height: 100%;
    background-color: transparent;
    color: white;
    pointer-events: none;
  }
`

const StyledIconButton = styled(IconButton)`
  color: white;

  &.Mui-disabled {
    color: gray;
  }

  &:hover {
    background-color: #f6f6f61c;
  }
`

const ImagePreview = styled.img`
  width: 700px;
  max-width: 95%;
  height: auto;
  max-height: 95%;
  pointer-events: all;
`

const NoPreviewContainer = styled.div`
  pointer-events: all;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const NoPreviewIcon = styled.img`
  height: 50px;
  width: 50px;
`

const PdfObject = styled.object`
  pointer-events: all;
`

const PdfFrame = styled.iframe`
  pointer-events: all;
`

const MicrosoftViewerIframe = styled.iframe`
  pointer-events: all;
  background-color: #fafafa;
`

// refer https://stackoverflow.com/a/4212908/6692326
const microsoftViewerCompatibleContentTypes = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
]

const Footer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
`

const NavigationActionsContainer = styled.div`
  margin-bottom: 8px;
  background-color: #3a3a3a;
  display: flex;
  align-items: center;
  pointer-events: all;
`

const NavigationIconButton = styled(StyledIconButton)`
  border-radius: 0;
`

const NavigationTypography = styled(Typography).attrs({ variant: 'body1' })`
  margin: 0 12px;
`

const FieldsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;
`

const DownloadButton = styled(Button)`
  ${media.small`
    min-width: unset;
    .MuiButton-label > span {
      display: none;
    }
    .MuiButton-label .MuiButton-endIcon {
      margin: 0;
      display: inherit;
    }
  `}
`

const isSafari =
  navigator &&
  navigator.vendor &&
  navigator.vendor.indexOf('Apple') > -1 &&
  navigator.userAgent &&
  navigator.userAgent.indexOf('CriOS') == -1 &&
  navigator.userAgent.indexOf('FxiOS') == -1

const EntityAttachmentListPreviewer: React.FC<Props> = ({
  attachments,
  intialPreviewedAttachmentSlug,
  open,
  onClose,
  attachmentsEditable = true,
}) => {
  const previewerRef = useRef<HTMLDivElement>()
  const [previewedAttachmentIndex, setPreviewedAttachmentIndex] = useState(
    attachments.findIndex((att) => att?.attachmentSlug === intialPreviewedAttachmentSlug)
  )
  const previewedAttachmentData = useMemo(() => attachments[previewedAttachmentIndex], [previewedAttachmentIndex, attachments])
  const previewedAttachment = useFragment(fragment, previewedAttachmentData)
  const { user } = useContext(SessionContext)
  useEffect(() => {
    setPreviewedAttachmentIndex(attachments.findIndex((att) => att?.attachmentSlug === intialPreviewedAttachmentSlug))
  }, [intialPreviewedAttachmentSlug, attachments])

  const { previewUrl, isIconPreview } = previewForAttachment(previewedAttachment || { contentType: '', previewUrl: null })
  const [editDetailsOpen, setEditDetailsOpen] = useState(false)
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false)
  const [commitDelete, deleteIsInFlight, deleteMutationError] = useNiceMutation<EntityAttachmentListPreviewerDeleteMutation>(deleteMutation)
  const [commitThumbnail, thumbnailIsInFlight] = useNiceMutation<EntityAttachmentListPreviewerThumbnailMutation>(thumbnailMutation)

  const [commitRemoveThumbnail, removeThumbnailIsInFlight] =
    useNiceMutation<EntityAttachmentListPreviewerRemoveThumbnailMutation>(removeThumbnailMutation)

  const handleEditDetailsClose = () => setEditDetailsOpen(false)
  const handleDelete = () => {
    if (previewedAttachment === null) return

    commitDelete({
      variables: {
        input: {
          entityType: previewedAttachment.entityType,
          entitySlug: previewedAttachment.entitySlug,
          attachmentSlug: previewedAttachment.attachmentSlug,
        },
      },
      onCompleted: (_, errors) => {
        if (!errors) {
          setDeleteConfirmationOpen(false)
          onClose()
        }
      },
    })
  }
  const handleThumbail = () => {
    if (previewedAttachment === null) return

    commitThumbnail({
      variables: {
        input: {
          entityType: previewedAttachment.entityType,
          entitySlug: previewedAttachment.entitySlug,
          attachmentSlug: previewedAttachment.attachmentSlug,
        },
      },
      onCompleted: (_, errors) => {
        if (!errors) {
          onClose()
        }
      },
    })
  }

  const handleRemoveThumbail = () => {
    if (previewedAttachment === null) return

    commitRemoveThumbnail({
      variables: {
        input: {
          entityType: previewedAttachment.entityType,
          entitySlug: previewedAttachment.entitySlug,
          attachmentSlug: previewedAttachment.attachmentSlug,
        },
      },
      onCompleted: (_, errors) => {
        if (!errors) {
          onClose()
        }
      },
    })
  }

  useEffect(() => {
    if (previewerRef.current) previewerRef.current.focus()
  }, [previewedAttachmentIndex])

  useEffect(() => {
    if (previewerRef.current) previewerRef.current?.focus()
  })

  return (
    <>
      <StyledDrawer
        ref={previewerRef}
        anchor='bottom'
        open={open}
        elevation={0}
        onClose={() => onClose()}
        onKeyDown={(e) => {
          if (!(previewedAttachmentIndex >= attachments.length - 1) && e.key === 'ArrowRight') {
            setPreviewedAttachmentIndex((prevIndex) => Math.min(attachments.length - 1, prevIndex + 1))
          }
          if (!(previewedAttachmentIndex <= 0) && e.key === 'ArrowLeft') {
            setPreviewedAttachmentIndex((prevIndex) => Math.max(0, prevIndex - 1))
          }
        }}
      >
        {previewedAttachment ? (
          <>
            <TitleContainer>
              <HorizontalFlex style={{ flex: 1 }}>
                <StyledIconButton onClick={() => onClose()}>
                  <ArrowBack htmlColor='white' />
                </StyledIconButton>
                <AttachmentInlineViewNameContainer
                  title={attachmentsEditable ? 'Click to edit' : ''}
                  onClick={() => attachmentsEditable && setEditDetailsOpen(true)}
                >
                  <TruncatedText variant='body1'>{previewedAttachment.name}</TruncatedText>
                  {previewedAttachment.documentType && <TruncatedText variant='body2'>{previewedAttachment.documentType}</TruncatedText>}
                </AttachmentInlineViewNameContainer>
              </HorizontalFlex>
              <HorizontalFlex>
                {attachmentsEditable && (
                  <>
                    <EntityAttachmentRoleVisibility attachment={previewedAttachment} />
                    <StyledDivider orientation='vertical' flexItem />
                    {user?.contact?.contactType === 'internal' && (
                      <>
                        <StyledIconButton onClick={() => setDeleteConfirmationOpen(true)}>
                          <Delete htmlColor='white' />
                        </StyledIconButton>
                        {previewedAttachment.contentType.includes('image') &&
                          (previewedAttachment.thumbnailImage ? (
                            <StyledIconButton onClick={() => handleRemoveThumbail()}>
                              <Tooltip title='Remove as Thumbnail' placement='top' interactive>
                                {removeThumbnailIsInFlight ? <LoadingDots /> : <PhotoSizeSelectActualOutlinedIcon htmlColor='white' />}
                              </Tooltip>
                            </StyledIconButton>
                          ) : (
                            <StyledIconButton onClick={() => handleThumbail()}>
                              <Tooltip title='Add as Thumbnail' placement='top' interactive>
                                {thumbnailIsInFlight ? <LoadingDots /> : <PhotoSizeSelectActualIcon htmlColor='white' />}
                              </Tooltip>
                            </StyledIconButton>
                          ))}
                      </>
                    )}
                  </>
                )}
                <UnstyledLink href={previewedAttachment.downloadUrl} target='_blank' download>
                  <DownloadButton variant='contained' endIcon={<CloudDownloadOutlined />}>
                    <span>Download</span>
                  </DownloadButton>
                </UnstyledLink>
              </HorizontalFlex>
            </TitleContainer>
            <StyledDrawerContent>
              {previewedAttachment.contentType === 'application/pdf' ? (
                isSafari ? (
                  <PdfFrame
                    title={previewedAttachment.name}
                    // workaround for 304 in safari
                    src={`${previewedAttachment.url}?t=${performance.now()}`}
                    height='100%'
                    width='100%'
                  />
                ) : (
                  <PdfObject data={previewedAttachment.url} type='application/pdf' height='100%' width='100%'>
                    This browser does not support PDF previews
                  </PdfObject>
                )
              ) : microsoftViewerCompatibleContentTypes.includes(previewedAttachment.contentType) ? (
                <MicrosoftViewerIframe
                  src={`https://view.officeapps.live.com/op/embed.aspx?src=${previewedAttachment.url}`}
                  title={previewedAttachment.name}
                  height='100%'
                  width='100%'
                />
              ) : isIconPreview ? (
                <NoPreviewContainer>
                  <NoPreviewIcon src={previewUrl} />
                  <Typography variant='body1'>{previewedAttachment.name}</Typography>
                  <Typography variant='body2'>No preview available</Typography>
                </NoPreviewContainer>
              ) : (
                <ImagePreview src={previewedAttachment.url} alt={previewedAttachment.name} />
              )}
            </StyledDrawerContent>
            <Footer>
              <NavigationActionsContainer>
                <NavigationIconButton
                  disabled={previewedAttachmentIndex <= 0}
                  onClick={() => setPreviewedAttachmentIndex((prevIndex) => Math.max(0, prevIndex - 1))}
                >
                  <NavigateBefore />
                </NavigationIconButton>
                <NavigationTypography>
                  {previewedAttachmentIndex + 1}/{attachments.length}
                </NavigationTypography>
                <NavigationIconButton
                  disabled={previewedAttachmentIndex >= attachments.length - 1}
                  onClick={() => setPreviewedAttachmentIndex((prevIndex) => Math.min(attachments.length - 1, prevIndex + 1))}
                >
                  <NavigateNext />
                </NavigationIconButton>
              </NavigationActionsContainer>
            </Footer>
          </>
        ) : (
          <TitleContainer>
            <StyledIconButton onClick={() => onClose()}>
              <ArrowBack htmlColor='white' />
            </StyledIconButton>
          </TitleContainer>
        )}
      </StyledDrawer>

      {previewedAttachment && previewedAttachmentData && (
        <>
          <OdysseyDialog
            open={editDetailsOpen && user?.contact?.contactType == 'internal'}
            fullWidth
            style={{ maxWidth: '640px', margin: 'auto' }}
            scroll='paper'
            onClose={handleEditDetailsClose}
          >
            <DialogTitle>Editing attachment</DialogTitle>
            <Suspense fallback={<EditDetailsDialogInternal.Skeleton />}>
              <EditDetailsDialogInternal attachment={previewedAttachmentData} onClose={handleEditDetailsClose} />
            </Suspense>
          </OdysseyDialog>

          <OdysseyDialog open={deleteConfirmationOpen} onClose={() => setDeleteConfirmationOpen(false)}>
            <DialogTitle>Delete {previewedAttachment.name}?</DialogTitle>
            {deleteMutationError && <Alert severity='error'>{deleteMutationError}</Alert>}
            <DialogActions>
              <Button onClick={() => setDeleteConfirmationOpen(false)}>Cancel</Button>
              <Button variant='contained' onClick={() => handleDelete()}>
                {deleteIsInFlight ? <LoadingDots variant='light' /> : 'OK'}
              </Button>
            </DialogActions>
          </OdysseyDialog>
        </>
      )}
    </>
  )
}

interface EditDetailsDialogInternalProps {
  attachment: EntityAttachmentListPreviewer_attachment$key
  onClose: () => void
}

const EditDetailsDialogInternal: React.FC<EditDetailsDialogInternalProps> & { Skeleton: React.FC } = ({ attachment: data, onClose }) => {
  const attachment = useFragment(fragment, data)
  const [commitDetailsUpdate, , detailsUpdateMutationError] =
    useNiceMutation<EntityAttachmentListPreviewerDetailsMutation>(updateDetailsMutation)
  const uploadableDocsData = useLazyLoadQuery<helpersFileUploadableDocsQuery>(uploadableDocsQuery, {
    entityType: attachment.entityType,
  })
  const { user } = useContext(SessionContext)

  return (
    <NiceFormik
      initialValues={{
        entityType: attachment.entityType,
        entitySlug: attachment.entitySlug,
        attachmentSlug: attachment.attachmentSlug,
        name: attachment.name,
        documentType: attachment.documentType,
      }}
      onSubmit={(values, actions) => {
        commitDetailsUpdate({
          variables: {
            input: values,
          },
          onCompleted: (_, errors) => {
            actions.setSubmitting(false)
            if (!errors) {
              onClose()
            }
          },
        })
      }}
    >
      {({ values, submitForm, setFieldValue }) => (
        <>
          {detailsUpdateMutationError && <Alert severity='error'>{detailsUpdateMutationError}</Alert>}
          <DialogContent dividers>
            <FieldsContainer>
              <Field variant='outlined' name={'name'} label={'Name'} fullWidth component={FormikTextField} />
              {user?.contact?.contactType == 'internal' && (
                <Autocomplete
                  options={uploadableDocsData.uploadableDocuments as { documentName: string; description: string }[]}
                  getOptionLabel={(option) => option.documentName || ''}
                  fullWidth
                  value={
                    values.documentType
                      ? uploadableDocsData.uploadableDocuments.find((docData) => docData.documentName === values.documentType) || {
                          documentName: values.documentType,
                          description: '',
                        }
                      : null
                  }
                  onChange={(e, value) => setFieldValue('documentType', value?.documentName)}
                  renderInput={(params) => <TextField {...params} label='Category' variant='outlined' />}
                />
              )}
            </FieldsContainer>
          </DialogContent>
          <DialogFormActionBar
            onCancel={() => onClose()}
            onSubmit={() => {
              submitForm()
            }}
            cancelCta='Cancel'
            saveCta='Save'
          />
        </>
      )}
    </NiceFormik>
  )
}

EditDetailsDialogInternal.Skeleton = () => (
  <>
    <DialogContent dividers>
      <FieldsContainer>
        <Skeleton variant='rect' height='56px' />
        <Skeleton variant='rect' height='56px' />
      </FieldsContainer>
    </DialogContent>
    <DialogFormActionBar.Skeleton />
  </>
)

const fragment = graphql`
  fragment EntityAttachmentListPreviewer_attachment on EntityAttachment {
    url
    downloadUrl
    name
    contentType
    previewUrl
    documentType
    entityType
    entitySlug
    attachmentSlug
    thumbnailImage
    ...EntityAttachmentRoleVisibility_attachment
  }
`

const updateDetailsMutation = graphql`
  mutation EntityAttachmentListPreviewerDetailsMutation($input: infoUpdateAttachmentDetailsInput!) {
    infoUpdateAttachmentDetails(input: $input) {
      attachment {
        ...EntityAttachment_attachment
      }
    }
  }
`

const deleteMutation = graphql`
  mutation EntityAttachmentListPreviewerDeleteMutation($input: infoDeleteAttachmentInput!) {
    infoDeleteAttachment(input: $input) {
      clientMutationId
    }
  }
`

const thumbnailMutation = graphql`
  mutation EntityAttachmentListPreviewerThumbnailMutation($input: infoUpdateEntityThumbnailInput!) {
    infoUpdateEntityThumbnail(input: $input) {
      clientMutationId
    }
  }
`
const removeThumbnailMutation = graphql`
  mutation EntityAttachmentListPreviewerRemoveThumbnailMutation($input: infoRemoveEntityThumbnailInput!) {
    infoRemoveEntityThumbnail(input: $input) {
      clientMutationId
    }
  }
`

export default EntityAttachmentListPreviewer
