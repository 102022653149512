import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { CSSTransition } from 'react-transition-group'

interface Props {
  editPage: ReactNode
  showPage: ReactNode
  viewMode: 'show' | 'edit'
  keyId?: string
}

const StyledTransitionGroup = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;
`

const TransitionRoot = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
`

const ShowTransitionRoot = styled(TransitionRoot)`
  overflow-y: auto;
  overflow-x: hidden;
`

const EntityShowOrchestrator: React.FC<Props> = ({ editPage, showPage, viewMode, keyId }) => {
  return (
    <StyledTransitionGroup>
      <CSSTransition in={viewMode === 'show'} key={`show-page-${keyId}`} appear timeout={180} classNames={'show-page'}>
        <ShowTransitionRoot>{showPage}</ShowTransitionRoot>
      </CSSTransition>
      <CSSTransition in={viewMode === 'edit'} mountOnEnter unmountOnExit key={`edit-page-${keyId}`} timeout={180} classNames={'edit-page'}>
        <TransitionRoot>{editPage}</TransitionRoot>
      </CSSTransition>
    </StyledTransitionGroup>
  )
}

export default EntityShowOrchestrator
