import React from 'react'
import styled from 'styled-components'
import ArchiveIcon from '@material-ui/icons/Archive'
import { Grow, Typography } from '@material-ui/core'
import { isSmall } from '../theme'

type VariantT = 'large' | 'small'

const OverflowRoot = styled.div<{ $variant: VariantT }>`
  --archive-banner-size: ${(props) => (props.$variant === 'large' ? '200px' : '100px')};

  position: absolute;
  top: calc(-1 * var(--archive-banner-size) / 2);
  right: calc(-1 * var(--archive-banner-size) / 2);
  height: var(--archive-banner-size);
  width: var(--archive-banner-size);
`

const OverflowContentContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: ${(props) => props.theme.customPalette.archiveOrange};
  transform: rotate(45deg);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  color: white;
  padding-bottom: 8px;
`

const BarRoot = styled.div`
  position: relative;
  width: 100%;
  padding: 0 16px;
  background-color: ${(props) => props.theme.customPalette.archiveOrange};
  color: white;
`

const ArchivedBanner: React.FC<{ isArchived: boolean; variant?: VariantT }> = ({ isArchived, variant = 'large' }) => {
  return (
    <>
      {isSmall() && isArchived ? (
        <BarRoot>
          <Typography variant='h6'>Archived</Typography>
        </BarRoot>
      ) : (
        <Grow in={isArchived} appear={false} style={{ transformOrigin: 'center top' }}>
          <OverflowRoot $variant={variant}>
            <OverflowContentContainer>
              <ArchiveIcon />
              {variant === 'large' && <Typography variant='h6'>Archived</Typography>}
            </OverflowContentContainer>
          </OverflowRoot>
        </Grow>
      )}
    </>
  )
}

export default ArchivedBanner
