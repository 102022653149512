/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ExternalItemListFilters_data = {
    readonly filters: ReadonlyArray<{
        readonly key: string;
        readonly " $fragmentRefs": FragmentRefs<"ListPageFilter_filter">;
    }>;
    readonly " $refType": "ExternalItemListFilters_data";
};
export type ExternalItemListFilters_data$data = ExternalItemListFilters_data;
export type ExternalItemListFilters_data$key = {
    readonly " $data"?: ExternalItemListFilters_data$data;
    readonly " $fragmentRefs": FragmentRefs<"ExternalItemListFilters_data">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExternalItemListFilters_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Filter",
      "kind": "LinkedField",
      "name": "filters",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "key",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ListPageFilter_filter"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ExternalItemList",
  "abstractKey": null
};
(node as any).hash = '8edbe89c92d9889b87a335068c12d422';
export default node;
