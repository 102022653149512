import { Button } from '@material-ui/core'
import styled from 'styled-components'

const PrimaryButton = styled(Button)`
  padding: 8px 12px;
  border-radius: 8px;
  background-color: #0080f6;
  color: white;
  font-weight: bold;
  text-transform: unset;
  font-size: 16px;

  &:hover {
    background-color: #006fd6;
  }
`

export default PrimaryButton
