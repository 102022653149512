import React from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import { useLazyLoadQuery } from 'react-relay/hooks'
import { Typography, Paper } from '@material-ui/core'
import styled from 'styled-components'
import ExternalShowPageListItem from './ExternalShowPageListItem'
import { ExternalItemListCardQuery, ExternalListItemTypeEnum } from '../__generated__/ExternalItemListCardQuery.graphql'

interface Props {
  type: ExternalListItemTypeEnum
  parentAbsoluteSlug?: string
  contactSlug?: string
  title: string
  entityTitle?: string
  className?: string
}

const RootPaper = styled(Paper)`
  margin-bottom: 16px;
`

const TitleContainer = styled.div`
  padding: 16px;
  padding-bottom: 8px;
  display: flex;
  justify-content: space-between;
`

const NoDataText = styled(Typography)`
  color: ${(props) => props.theme.customPalette.text.noData};
  text-align: center;
  padding: 16px;
  font-weight: 400;
`

const ExternalItemListCard: React.FC<Props> & { Skeleton: React.FC } = ({ type, parentAbsoluteSlug, title, className }) => {
  const data = useLazyLoadQuery<ExternalItemListCardQuery>(query, {
    type: type,
    parentAbsoluteSlug: parentAbsoluteSlug,
  })
  return (
    <RootPaper variant='outlined' className={className}>
      <TitleContainer>
        <Typography variant='h6'>{title}</Typography>
      </TitleContainer>
      {data.externalItemList.items.nodes && data.externalItemList.items.nodes?.length > 0 ? (
        data.externalItemList.items.nodes.map((item) => item && <ExternalShowPageListItem key={item.slug} listItem={item} />)
      ) : (
        <NoDataText variant='h6'>No Shipments</NoDataText>
      )}
    </RootPaper>
  )
}

const query = graphql`
  query ExternalItemListCardQuery($type: ExternalListItemTypeEnum, $parentAbsoluteSlug: String) {
    externalItemList(type: $type, parentAbsoluteSlug: $parentAbsoluteSlug) {
      items {
        nodes {
          slug
          ...ExternalShowPageListItem_listItem
        }
      }
    }
  }
`

ExternalItemListCard.Skeleton = () => <>Loading...</>

export default ExternalItemListCard
