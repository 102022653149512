import React from 'react'
import OdysseyDialog from './OdysseyDialog'
import { DialogTitle, DialogContent, DialogActions } from '@material-ui/core'
import useNiceMutation from '../mutations/useNiceMutation'
import { graphql } from 'babel-plugin-relay/macro'
import { RemoveContactLinkDialogMutation } from '../__generated__/RemoveContactLinkDialogMutation.graphql'
import Button from './Button'
import { Alert } from '@material-ui/lab'

interface Props {
  open: boolean
  onClose: () => void
  entityType: string
  entitySlug: string
  role: string
  contactSlug: string
  title: string
}

const RemoveContactLinkDialog: React.FC<Props> = ({ open, onClose, entityType, entitySlug, role, contactSlug, title }) => {
  const [commit, , mutationError] = useNiceMutation<RemoveContactLinkDialogMutation>(mutation)
  const removeContactLink = () => {
    commit({
      variables: { input: { entityType: entityType, entitySlug: entitySlug, role: role, contactSlug: contactSlug } },
      onCompleted: (_, errors) => {
        if (!errors) onClose()
      },
    })
  }

  return (
    <>
      <OdysseyDialog open={open} onClose={onClose}>
        <DialogTitle>Remove Contact</DialogTitle>
        {mutationError && <Alert severity='error'>{mutationError}</Alert>}
        <DialogContent>{`Remove ${title} as a ${role}?`}</DialogContent>
        <DialogActions>
          <Button variant='text' onClick={onClose}>
            Cancel
          </Button>
          <Button variant='contained' onClick={removeContactLink}>
            Remove
          </Button>
        </DialogActions>
      </OdysseyDialog>
    </>
  )
}

const mutation = graphql`
  mutation RemoveContactLinkDialogMutation($input: infoDeleteContactLinkInput!) {
    infoDeleteContactLink(input: $input) {
      deleted
    }
  }
`

export default RemoveContactLinkDialog
