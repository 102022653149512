import { Typography, Grid } from '@material-ui/core'
import React, { Suspense } from 'react'
import styled from 'styled-components'
import ResetPasswordForm from '../components/ResetPasswordForm'
import { media } from '../theme'

const LandingTitle = styled(Typography)`
  color: white;
  font-weight: bold;
  padding-left: 8px;
  border-left: 4px solid #00abe8;
  margin: 32px 0;

  ${media.mobile`
    font-size: 36px;
  `}
`

const LandingBody = styled(Typography)`
  color: white;
  display: block;
  font-size: 18px;
  font-weight: 400;
  margin: 32px 0;

  ${media.giant`
    width: 80%;
  `}

  ${media.desktop`
    width: 80%;
  `}

  ${media.mobile`
    font-size: 14px;
  `}
`

const ResetPassword: React.FC = () => {
  return (
    <Grid container>
      <Grid item xs={12} lg={6} xl={6}>
        <LandingTitle variant='h4'>Odyssey is Portagent’s collaboration platform</LandingTitle>
        <LandingBody variant='body1'>
          Portagent performs customs clearance of vehicles from the USA, Canada, Japan, United Arab Emirates and South Korea
        </LandingBody>
        <LandingBody variant='body1'>
          Our <b>Odyssey</b> platform enables us to provide the highest level of competence and partnership in port logistics and customs
          clearance.
        </LandingBody>
      </Grid>
      <Grid item md={3} lg={1} xl={1} />
      <Grid item xs={12} md={6} lg={5} xl={4}>
        <Suspense fallback={ResetPasswordForm.skeletion}>
          <ResetPasswordForm />
        </Suspense>
      </Grid>
    </Grid>
  )
}

export default ResetPassword
