import { Avatar as MuiAvatar, Badge as MuiBadge } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { Skeleton } from '@material-ui/lab'
import { graphql } from 'babel-plugin-relay/macro'
import { useField } from 'formik'
import React from 'react'
import { useLazyLoadQuery } from 'react-relay/hooks'
import styled from 'styled-components'
import { ImageInputFieldQuery } from '../../__generated__/ImageInputFieldQuery.graphql'
import ImageUpload from '../Image/ImageUpload'
import { FieldT, FieldVariantEnum } from './types'

interface Props {
  field: FieldT
  variant?: FieldVariantEnum
}

const Avatar = styled(MuiAvatar)`
  width: 192px;
  height: 192px;
  margin-bottom: 16px;
  border: 4px solid white;
`

const Badge = styled(MuiBadge)`
  display: flex;
  align-items: center;
  justify-content: center;
`

const BadgeContent = styled.div`
  padding: 8px;
  background: #212121;
  border-radius: 50%;
  margin-right: 32px;
  margin-bottom: 32px;
  color: white;
  box-shadow: ${(props) => props.theme.shadows[2]};
`

const ImageInputField: React.FC<Props> & { Skeleton: React.FC } = ({ field, variant }) => {
  const [, meta, helpers] = useField(field.name)
  const data = useLazyLoadQuery<ImageInputFieldQuery>(query, { slug: meta.value })
  const overlap = field.type === 'container_image_slug' ? 'rectangle' : 'circle'
  const avatarVariant = field.type === 'container_image_slug' ? 'square' : 'circle'
  const defaultAvatar = field.type === 'container_image_slug' ? 'C' : field.type === 'shipment_image_slug' ? 'S' : null

  if (meta.value)
    return (
      <Badge
        overlap={overlap}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        badgeContent={
          <BadgeContent onClick={() => helpers.setValue(null)}>
            <Close fontSize='small' />
          </BadgeContent>
        }
      >
        <Avatar src={data.attachmentQuery?.url || ''} variant={avatarVariant}>
          {defaultAvatar}
        </Avatar>
      </Badge>
    )

  return (
    <ImageUpload
      size={variant}
      shape={avatarVariant}
      onSuccess={(imageSlug: string | null) => helpers.setValue(imageSlug)}
      placeHolderUrl={field.placeholder}
    />
  )
}

ImageInputField.Skeleton = () => (
  <div>
    <Skeleton variant='circle' width='200px' height='200px' />
  </div>
)

const query = graphql`
  query ImageInputFieldQuery($slug: String) {
    attachmentQuery(slug: $slug) {
      url
    }
  }
`

export default ImageInputField
