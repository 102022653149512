/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type infoChangeShipmentStageInput = {
    clientMutationId?: string | null;
    slug: string;
    stage: string;
    handoverDate?: string | null;
};
export type ShipmentWorkflowChangeStageMutationVariables = {
    input: infoChangeShipmentStageInput;
};
export type ShipmentWorkflowChangeStageMutationResponse = {
    readonly infoChangeShipmentStage: {
        readonly shipment: {
            readonly " $fragmentRefs": FragmentRefs<"ShipmentWorkflow_shipment">;
        };
    } | null;
};
export type ShipmentWorkflowChangeStageMutation = {
    readonly response: ShipmentWorkflowChangeStageMutationResponse;
    readonly variables: ShipmentWorkflowChangeStageMutationVariables;
};



/*
mutation ShipmentWorkflowChangeStageMutation(
  $input: infoChangeShipmentStageInput!
) {
  infoChangeShipmentStage(input: $input) {
    shipment {
      ...ShipmentWorkflow_shipment
      id
    }
  }
}

fragment ShipmentWorkflow_shipment on Shipment {
  slug
  automaticHandover
  workflowStages {
    value
    label
    ownedBy
    status
    ...Stage_stage
  }
}

fragment Stage_stage on WorkflowStage {
  value
  status
  label
  completedAt
  ownedBy
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ShipmentWorkflowChangeStageMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "infoChangeShipmentStagePayload",
        "kind": "LinkedField",
        "name": "infoChangeShipmentStage",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Shipment",
            "kind": "LinkedField",
            "name": "shipment",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ShipmentWorkflow_shipment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ApplicationCommand",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ShipmentWorkflowChangeStageMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "infoChangeShipmentStagePayload",
        "kind": "LinkedField",
        "name": "infoChangeShipmentStage",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Shipment",
            "kind": "LinkedField",
            "name": "shipment",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "slug",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "automaticHandover",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "WorkflowStage",
                "kind": "LinkedField",
                "name": "workflowStages",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "value",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "label",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "ownedBy",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "completedAt",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "282df6553044fe7ecd293493537f36f5",
    "id": null,
    "metadata": {},
    "name": "ShipmentWorkflowChangeStageMutation",
    "operationKind": "mutation",
    "text": "mutation ShipmentWorkflowChangeStageMutation(\n  $input: infoChangeShipmentStageInput!\n) {\n  infoChangeShipmentStage(input: $input) {\n    shipment {\n      ...ShipmentWorkflow_shipment\n      id\n    }\n  }\n}\n\nfragment ShipmentWorkflow_shipment on Shipment {\n  slug\n  automaticHandover\n  workflowStages {\n    value\n    label\n    ownedBy\n    status\n    ...Stage_stage\n  }\n}\n\nfragment Stage_stage on WorkflowStage {\n  value\n  status\n  label\n  completedAt\n  ownedBy\n}\n"
  }
};
})();
(node as any).hash = '05cea5226dc2f900d37929e87b158ab9';
export default node;
