import { Typography } from '@material-ui/core'
import { graphql } from 'babel-plugin-relay/macro'
import React from 'react'
import { useFragment } from 'react-relay/hooks'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Badge from '../../components/Badge'
import ItemImage from '../../components/ItemImage'
import KeyField from '../../components/KeyField'
import LinkSet from '../../components/LinkSet/LinkSet'
import SemanticLine from '../../components/SemanticLine'
import StickyNote from '../../components/StickyNote'
import TextHighlighter, { HighlightT } from '../../components/TextHighlighter'
import { isLarge, isMedium, media } from '../../theme'
import { ViewMode } from '../../viewTypes'
import { ExternalListPageItem_listItem$key, StageStatusEnum } from '../../__generated__/ExternalListPageItem_listItem.graphql'
import ArchivedBanner from '../../components/ArchivedBanner'

interface Props {
  listItem: ExternalListPageItem_listItem$key
  viewMode: ViewMode
  highlights?: HighlightT[]
}

interface ViewProps {
  view?: ViewMode
}

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`

const Root = styled.div`
  border: 1px solid ${(props) => props.theme.palette.divider};
  position: relative;
  overflow: hidden;
`

const Topbar = styled(FlexContainer)`
  ${media.small`
    justify-content: space-between;
  `}
`

interface StepProps {
  status: StageStatusEnum
}

const Step = styled.div<StepProps>`
  flex-grow: 1;
  display: flex;
  align-items: center;
  background-color: ${(props) => (props.status === 'active' ? '#BDE4F0' : props.status === 'completed' ? '#BDF0D1' : '#E8E8E8')};
  color: ${(props) => (props.status === 'active' ? '#1A4A77' : props.status === 'completed' ? '#1A7734' : '#646464')};
  position: relative;
  height: 16px;
  padding: 12px 8px;
  margin-right: 16px;

  &:before {
    content: '';
    position: absolute;
    left: -8px;
    display: block;
    width: 0;
    height: 0;
    color: ${(props) => (props.status === 'active' ? '#1A4A77' : props.status === 'completed' ? '#1A7734' : '#646464')};
    border-top: 12px solid ${(props) => (props.status === 'active' ? '#BDE4F0' : props.status === 'completed' ? '#BDF0D1' : '#E8E8E8')};
    border-bottom: 12px solid ${(props) => (props.status === 'active' ? '#BDE4F0' : props.status === 'completed' ? '#BDF0D1' : '#E8E8E8')};
    border-left: 8px solid transparent;
  }

  &:after {
    content: '';
    position: absolute;
    right: -8px;
    display: block;
    width: 0;
    height: 0;
    color: ${(props) => (props.status === 'active' ? '#1A4A77' : props.status === 'completed' ? '#1A7734' : '#646464')};
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-left: 8px solid ${(props) => (props.status === 'active' ? '#BDE4F0' : props.status === 'completed' ? '#BDF0D1' : '#E8E8E8')};
  }
`

const Stages = styled(FlexContainer)`
  margin-right: 16px;

  ${Step}:first-child {
    margin-left: 0;
    &:before {
      content: none;
    }
  }
`

const RootContainer = styled(FlexContainer)<ViewProps>`
  padding: 16px;
  align-items: flex-start;
  background-color: ${(props) => props.theme.palette.background.paper};
  transition-duration: 300ms;

  ${(props) =>
    props.view === 'grid'
      ? `
    border-top: 1px solid #e4e4e4;
    border-bottom: 1px solid #e4e4e4;
    flex-direction: row;
    align-items: flex-start;
  `
      : ''}
`

const Content = styled(FlexContainer)`
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
`

const TitleRow = styled(FlexContainer)<ViewProps>`
  width: 100%;
  justify-content: ${(props) => (props.view === 'grid' ? 'center' : 'space-between')};
`

const TitleContainer = styled(FlexContainer)<ViewProps>`
  align-items: flex-start;
  width: ${(props) => (props.view === 'grid' ? 'auto' : '100%')};
  margin-right: 8px;
`

const TitleText = styled(Typography)<ViewProps>`
  ${(props) => (props.view === 'grid' ? `text-align: center;` : '')}
  cursor: pointer;
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
`

const TitleLink = styled(Link)`
  margin-right: 8px;
  transition-duration: 200ms;
  color: ${(props) => props.theme.palette.text.primary};
  text-decoration: unset;

  &:hover h6 {
    color: #0085ff;
  }
`

const UnstyledLink = styled(Link)`
  text-decoration: unset;
  color: inherit;
`

const KeyFieldsContainer = styled.div<ViewProps>`
  margin: 4px 0 16px 0;
  display: grid;
  grid-template-columns: ${(props) => (props.view === 'grid' ? 'repeat(auto-fill, 48%)' : 'repeat(auto-fill, 180px)')};
  grid-gap: 8px;
  width: 100%;

  ${(props) => media.large`
    grid-template-columns: ${props.view === 'grid' ? 'repeat(auto-fill, 48%)' : 'repeat(auto-fill, 214px)'};
  `}
`

const LinkSetContainer = styled.div`
  margin-bottom: 8px;
  width: 100%;
`

const Center = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 5px;
`

const ExternalListPageItem: React.FC<Props> = ({ listItem, viewMode, highlights }) => {
  const item = useFragment(listItemFragment, listItem)
  const maxFields = viewMode === 'grid' ? 4 : isMedium() ? 9 : 15

  return (
    <Root style={viewMode === 'grid' ? { margin: '10px', width: '380px' } : {}}>
      <ArchivedBanner isArchived={item.isArchived} variant='small' />
      <Topbar>
        {item.stages && item.stages.length > 0 && (
          <Stages>
            {item.stages.map((stage, idx) => (
              <Step key={stage.key} status={stage.status}>
                {stage.status === 'completed' || stage.status === 'incomplete' ? idx + 1 : stage.label}
              </Step>
            ))}
          </Stages>
        )}
      </Topbar>
      <RootContainer view={viewMode}>
        {item.image && viewMode !== 'grid' && (
          <UnstyledLink to={`${item.path}/home` || '#'}>
            <ItemImage data={item.image} variant='small' />
          </UnstyledLink>
        )}

        <Content>
          {item.image && viewMode === 'grid' && (
            <Center>
              <UnstyledLink to={`${item.path}/home` || '#'}>
                <ItemImage data={item.image} variant='small' />
              </UnstyledLink>
            </Center>
          )}
          <TitleRow view={viewMode} title={item.title}>
            <TitleContainer view={viewMode}>
              <TitleLink to={`${item.path}/home` || '#'}>
                <TitleText variant='subtitle1' view={viewMode} color='textPrimary'>
                  <TextHighlighter highlights={highlights} textToHighlight={item.title} />
                </TitleText>
                {item.lines?.map((line) => (
                  <SemanticLine key={line.key} data={line} highlights={highlights} />
                ))}
              </TitleLink>
              {item.badges?.map((badge) => (
                <span key={badge.key} style={{ marginTop: '3px' }}>
                  <Badge key={badge.key} data={badge} />
                </span>
              ))}
              {item.stickyNoteContentByExternal && isLarge() && viewMode !== 'grid' ? (
                <StickyNote note={item.stickyNoteContentByExternal} type='external' />
              ) : (
                <></>
              )}
            </TitleContainer>
          </TitleRow>
          {item.keyFields && (
            <KeyFieldsContainer view={viewMode}>
              {item.keyFields?.slice(0, maxFields)?.map((field) => (
                <KeyField key={field.key} data={field} highlights={highlights} />
              ))}
            </KeyFieldsContainer>
          )}
          {item.linkSets &&
            viewMode !== 'mini-list' &&
            item.linkSets
              .filter((linkSet) => linkSet.links && linkSet.links.length > 0)
              .map((linkSet) => (
                <LinkSetContainer key={linkSet.key}>
                  <LinkSet data={linkSet} />
                </LinkSetContainer>
              ))}
        </Content>
      </RootContainer>
    </Root>
  )
}

const listItemFragment = graphql`
  fragment ExternalListPageItem_listItem on ExternalListItem {
    slug
    title
    isArchived
    image {
      ...ItemImage_data
    }
    path
    stages {
      key
      status
      label
      value
    }
    badges {
      key
      ...Badge_data
    }
    lines {
      key
      ...SemanticLine_data
    }
    keyFields {
      key
      ...KeyField_data
    }
    linkSets {
      key
      links {
        key
      }
      ...LinkSet_data
    }
    stickyNoteContentByExternal
  }
`

export default ExternalListPageItem
