import React, { useState, useContext } from 'react'
import { graphql } from 'babel-plugin-relay/macro'

import { DialogTitle, DialogActions } from '@material-ui/core'
import styled from 'styled-components'
import { ShipmentWorkflow_shipment$key, ShipmentWorkflow_shipment } from '../../__generated__/ShipmentWorkflow_shipment.graphql'
import { useFragment } from 'react-relay/hooks'
import Button from '../Button'
import { Alert } from '@material-ui/lab'
import useNiceMutation from '../../mutations/useNiceMutation'
import { ShipmentWorkflowChangeStageMutation } from '../../__generated__/ShipmentWorkflowChangeStageMutation.graphql'
import Stage from './Stage'
import OdysseyDialog from '../OdysseyDialog'
import LoadingDots from '../LoadingDots'
import SessionContext from '../../SessionContext'
import { ShipmentWorkflowPickStageMutation } from '../../__generated__/ShipmentWorkflowPickStageMutation.graphql'
import { ShipmentWorkflowUnpickStageMutation } from '../../__generated__/ShipmentWorkflowUnpickStageMutation.graphql'
import ShipmentAutomaticHandover from './ShipmentAutomaticHandover'

interface Props {
  shipment: ShipmentWorkflow_shipment$key
}

const Container = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  overflow-x: auto;
`
const ShipmentWorkflow: React.FC<Props> = ({ shipment: data }) => {
  const shipment = useFragment(fragment, data)
  const stages = shipment.workflowStages

  return (
    <>
      <Container>
        {stages.map(
          (stage, idx) =>
            stage && (
              <ShipmentStage
                key={stage.value}
                shipment={data}
                stage={stage}
                index={idx + 1}
                isFirst={idx === 0}
                isLast={idx === stages.length - 1}
                automaticHandover={shipment?.automaticHandover as boolean}
              />
            )
        )}
      </Container>
    </>
  )
}

interface ShipmentStageProps {
  shipment: ShipmentWorkflow_shipment$key
  stage: ShipmentWorkflow_shipment['workflowStages'][number]
  index: number
  isFirst: boolean
  isLast: boolean
  automaticHandover?: boolean
}

const ShipmentStage: React.FC<ShipmentStageProps> = ({ shipment: data, stage, index, isFirst, isLast, automaticHandover }) => {
  const shipment = useFragment(fragment, data)
  const { user } = useContext(SessionContext)
  const [forcePickConfirmationOpen, setForcePickConfirmationOpen] = useState(false)
  const [commitStageChange, stageChangeIsInFlight] = useNiceMutation<ShipmentWorkflowChangeStageMutation>(changeStageMutation)
  const [commitStagePick, stagePickIsInFlight, stagePickError] = useNiceMutation<ShipmentWorkflowPickStageMutation>(pickStageMutation)
  const [commitStageUnpick, stageUnpickIsInFlight] = useNiceMutation<ShipmentWorkflowUnpickStageMutation>(unpickStageMutation)
  const [handoverDialog, setHandoverDialog] = useState<boolean>(false)

  const handleStageChange = () => {
    if (stageChangeIsInFlight || stage.status === 'active') return
    commitStageChange({
      variables: {
        input: {
          slug: shipment.slug,
          stage: stage.value,
        },
      },
    })
  }
  const isActive = stage.status === 'active'
  const isPickedByMe = stage.ownedBy === user?.username
  const actionType: 'pick' | 'unpick' | null = isActive && isPickedByMe ? 'unpick' : isActive ? 'pick' : null
  const handleActionClick = () => {
    if (actionType === 'pick') {
      if (stage.ownedBy && stage.ownedBy !== user?.username) {
        setForcePickConfirmationOpen(true)
      } else {
        pickStage()
      }
    } else if (actionType === 'unpick') {
      commitStageUnpick({
        variables: {
          input: {
            entityType: 'shipments',
            entitySlug: shipment.slug,
            stage: stage.value,
          },
        },
      })
    }
  }

  const pickStage = () => {
    if (!user) return
    commitStagePick({
      variables: {
        input: {
          entityType: 'shipments',
          entitySlug: shipment.slug,
          stage: stage.value,
          pickedBy: user.username,
        },
      },
      onCompleted: (res, errors) => {
        if (!errors) {
          setForcePickConfirmationOpen(false)
        }
      },
    })
  }

  return (
    <>
      <ShipmentAutomaticHandover
        open={handoverDialog}
        onClose={() => setHandoverDialog(false)}
        entitySlug={shipment.slug}
        onAction={handleStageChange}
      ></ShipmentAutomaticHandover>
      <Stage
        stage={stage}
        index={index}
        isFirst={isFirst}
        isLast={isLast}
        onClick={() => {
          if (stage.value === 'handover' && automaticHandover) {
            setHandoverDialog(true)
          } else {
            handleStageChange()
          }
        }}
        stageChangeIsLoading={stageChangeIsInFlight}
        actionText={actionType === 'pick' ? 'Pick' : actionType === 'unpick' ? 'Unpick' : null}
        onActionClick={handleActionClick}
        actionIsLoading={stagePickIsInFlight || stageUnpickIsInFlight}
      />
      <OdysseyDialog open={forcePickConfirmationOpen} onClose={() => setForcePickConfirmationOpen(false)}>
        <DialogTitle>
          Unassign {stage.ownedBy} and pick {stage.label}?
        </DialogTitle>
        {stagePickError && <Alert severity='error'>{stagePickError}</Alert>}
        <DialogActions>
          <Button onClick={() => setForcePickConfirmationOpen(false)}>Cancel</Button>
          <Button variant='contained' onClick={() => pickStage()} disabled={stagePickIsInFlight}>
            {stagePickIsInFlight ? <LoadingDots variant='light' /> : 'OK'}
          </Button>
        </DialogActions>
      </OdysseyDialog>
    </>
  )
}

const fragment = graphql`
  fragment ShipmentWorkflow_shipment on Shipment {
    slug
    automaticHandover
    workflowStages {
      value
      label
      ownedBy
      status
      ...Stage_stage
    }
  }
`

const changeStageMutation = graphql`
  mutation ShipmentWorkflowChangeStageMutation($input: infoChangeShipmentStageInput!) {
    infoChangeShipmentStage(input: $input) {
      shipment {
        ...ShipmentWorkflow_shipment
      }
    }
  }
`

const pickStageMutation = graphql`
  mutation ShipmentWorkflowPickStageMutation($input: infoPickEntityStageInput!) {
    infoPickEntityStage(input: $input) {
      clientMutationId
    }
  }
`

const unpickStageMutation = graphql`
  mutation ShipmentWorkflowUnpickStageMutation($input: infoUnpickEntityStageInput!) {
    infoUnpickEntityStage(input: $input) {
      clientMutationId
    }
  }
`

export default ShipmentWorkflow
