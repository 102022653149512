import React from 'react'
import { DialogProps, Dialog, Grow } from '@material-ui/core'
import { TransitionProps } from '@material-ui/core/transitions'
import { isSmall } from '../theme'

const DialogTransition = React.forwardRef(function DialogTransition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Grow ref={ref} {...props} />
})

const OdysseyDialog: React.FC<DialogProps> = (props) => <Dialog TransitionComponent={DialogTransition} fullScreen={isSmall()} {...props} />

export default OdysseyDialog
