import { Typography } from '@material-ui/core'
import { graphql } from 'babel-plugin-relay/macro'
import React from 'react'
import { useFragment } from 'react-relay/hooks'
import styled from 'styled-components'
import { SemanticLine_data$key } from '../__generated__/SemanticLine_data.graphql'
import SemanticString from './SemanticString'
import { Skeleton } from '@material-ui/lab'
import { HighlightT } from './TextHighlighter'

interface Props {
  data: SemanticLine_data$key
  highlights?: HighlightT[]
}

const Text = styled(Typography)`
  display: inline-block;
  font-style: oblique;
  margin-right: 4px;
`

const SemanticLine: React.FC<Props> & { Skeleton: React.FC } = ({ data, highlights }) => {
  const line = useFragment(lineFragment, data)
  return (
    <div>
      {line?.parts?.map((part, idx) => (
        <Text key={`${idx}-${part.key}`} variant='subtitle2'>
          <SemanticString data={part} highlights={highlights} />
        </Text>
      ))}
    </div>
  )
}

const lineFragment = graphql`
  fragment SemanticLine_data on SemanticLine {
    parts {
      key
      ...SemanticString_data
    }
  }
`

SemanticLine.Skeleton = () => (
  <Skeleton>
    <Text variant='subtitle2'>.................</Text>
  </Skeleton>
)

export default SemanticLine
