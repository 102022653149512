import { Typography } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'
import { Data } from '../../components/Charts/types'
import useWorkspaceNavigate from '../../hooks/useWorkspaceNavigate'

interface Props {
  data: Data
}

const Root = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 16px;
`

const Row = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  padding: 8px;
  padding-left: 4px;
  cursor: pointer;
  background-color: ${(props) => props.theme.palette.background.paper};

  &:hover {
    filter: brightness(0.95);
  }
`

export const OverdueMetrics: React.FC<Props> = ({ data }) => {
  const wsNavigate = useWorkspaceNavigate()
  return (
    <Root>
      {data.labels?.map((label, idx) => (
        <Row key={label} onClick={() => data?.paths && wsNavigate(data.paths[idx])}>
          <Typography variant='body1' color='textSecondary'>
            {label}
          </Typography>
          <Typography variant='body1'>{data?.values && data.values[idx]}</Typography>
        </Row>
      ))}
    </Root>
  )
}
