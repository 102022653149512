import React, { Suspense, useState } from 'react'
import { useParams } from 'react-router'
import { NiceTabPanel } from '../../components/TabsUI/TabsUI'
import VesselShowHeroCard from '../../components/VesselShowHeroCard'
import VesselShowHomeContent from './VesselShowHomeContent'
import NoteContent from '../../components/NoteTab'
import RelatedItemList from '../ItemList/RelatedItemList'
import VesselEdit from './VesselEdit'
import EntityShowOrchestrator from '../../components/EntityShowOrchestrator'
import { ShowPageRenderContext } from '../../viewTypes'

const VesselShow: React.FC = () => {
  const { slug, tab } = useParams()
  return <VesselShowWithParams slug={slug} tab={tab} renderContext='page' />
}

interface Params {
  slug: string
  tab: string
  withSkeleton?: boolean
  renderContext?: ShowPageRenderContext
}

export const VesselShowWithParams: React.FC<Params> = ({ slug, tab, withSkeleton, renderContext = 'drawer' }) => {
  const [viewMode, setViewMode] = useState<'show' | 'edit'>('show')

  return (
    <EntityShowOrchestrator
      showPage={
        <>
          {withSkeleton ? (
            <Suspense fallback={<VesselShowHeroCard.Skeleton />}>
              <VesselShowHeroCard slug={slug} activeTab={tab} onEdit={() => setViewMode('edit')} renderContext={renderContext} />
            </Suspense>
          ) : (
            <VesselShowHeroCard slug={slug} activeTab={tab} onEdit={() => setViewMode('edit')} renderContext={renderContext} />
          )}
          <NiceTabPanel forTab='home' activeTab={tab}>
            <Suspense fallback={<VesselShowHomeContent.Skeleton />}>{slug && <VesselShowHomeContent slug={slug} />}</Suspense>
          </NiceTabPanel>

          <NiceTabPanel forTab='voyages' activeTab={tab}>
            <Suspense fallback={<RelatedItemList.Skeleton />}>
              <RelatedItemList type='voyages' parentAbsoluteSlug={`vessels/${slug}`} description='All voyages of this vessel' entity='Voyage' />
            </Suspense>
          </NiceTabPanel>

          <NiceTabPanel forTab='notes' activeTab={tab}>
            <Suspense fallback={<NoteContent.Skeleton />}>
              <NoteContent slug={slug} entityType='vessels' />
            </Suspense>
          </NiceTabPanel>
        </>
      }
      editPage={<VesselEdit slug={slug} onClose={() => setViewMode('show')} />}
      viewMode={viewMode}
    />
  )
}

export default VesselShow
