/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type SanctionListIdentifierTypeEnum = "EU" | "UN" | "US" | "%future added value";
export type SanctionListQueryVariables = {};
export type SanctionListQueryResponse = {
    readonly sanctionList: ReadonlyArray<{
        readonly slug: string;
        readonly identifier: SanctionListIdentifierTypeEnum;
        readonly firstName: string;
        readonly lastName: string;
    }>;
};
export type SanctionListQuery = {
    readonly response: SanctionListQueryResponse;
    readonly variables: SanctionListQueryVariables;
};



/*
query SanctionListQuery {
  sanctionList {
    slug
    identifier
    firstName
    lastName
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "SanctionList",
    "kind": "LinkedField",
    "name": "sanctionList",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "slug",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "identifier",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastName",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SanctionListQuery",
    "selections": (v0/*: any*/),
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SanctionListQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "44fecac7b218c92e0a53117154b273cd",
    "id": null,
    "metadata": {},
    "name": "SanctionListQuery",
    "operationKind": "query",
    "text": "query SanctionListQuery {\n  sanctionList {\n    slug\n    identifier\n    firstName\n    lastName\n  }\n}\n"
  }
};
})();
(node as any).hash = '83694579e7abfc97ef15b256c741ef6c';
export default node;
