/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SettingTypeEnum = "config_settings" | "customs_types" | "default_folders" | "document_templates" | "document_types" | "email_templates" | "languages" | "miscellaneous_settings" | "ports" | "rate_cards" | "roles" | "shipment_types" | "shipping_lines" | "%future added value";
export type infoUpdateSettingInput = {
    clientMutationId?: string | null;
    slug: string;
    type: SettingTypeEnum;
    fields: Array<SettingFieldInput>;
};
export type SettingFieldInput = {
    name: string;
    value: string;
};
export type EditSettingDialogMutationVariables = {
    input: infoUpdateSettingInput;
};
export type EditSettingDialogMutationResponse = {
    readonly infoUpdateSetting: {
        readonly clientMutationId: string | null;
        readonly settingItem: {
            readonly " $fragmentRefs": FragmentRefs<"SettingsItem_settingsItem">;
        };
    } | null;
};
export type EditSettingDialogMutation = {
    readonly response: EditSettingDialogMutationResponse;
    readonly variables: EditSettingDialogMutationVariables;
};



/*
mutation EditSettingDialogMutation(
  $input: infoUpdateSettingInput!
) {
  infoUpdateSetting(input: $input) {
    clientMutationId
    settingItem {
      ...SettingsItem_settingsItem
      id
    }
  }
}

fragment Attachment_attachment on Attachment {
  url
  name
  contentType
  previewUrl
}

fragment BodyField_data on BodyField {
  label
  value {
    key
    ...SemanticString_data
  }
}

fragment KeyField_data on KeyField {
  label
  value {
    key
    ...SemanticString_data
  }
}

fragment SemanticLine_data on SemanticLine {
  parts {
    key
    ...SemanticString_data
  }
}

fragment SemanticString_data on SemanticString {
  text
}

fragment SettingItemImage_data on Image {
  url
  shape
  overlay
}

fragment SettingsItem_settingsItem on SettingsItem {
  slug
  title
  settingType
  image {
    ...SettingItemImage_data
  }
  lines {
    key
    ...SemanticLine_data
  }
  keyFields {
    key
    ...KeyField_data
  }
  showFields {
    key
    ...ShowField_data
  }
  bodyFields {
    key
    ...BodyField_data
  }
  attachments {
    slug
    ...Attachment_attachment
  }
}

fragment ShowField_data on ShowField {
  label
  value {
    key
    ...SemanticString_data
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientMutationId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v6 = [
  (v5/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "text",
    "storageKey": null
  }
],
v7 = [
  (v5/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "label",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "SemanticString",
    "kind": "LinkedField",
    "name": "value",
    "plural": true,
    "selections": (v6/*: any*/),
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditSettingDialogMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "infoUpdateSettingPayload",
        "kind": "LinkedField",
        "name": "infoUpdateSetting",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SettingsItem",
            "kind": "LinkedField",
            "name": "settingItem",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SettingsItem_settingsItem"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ApplicationCommand",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditSettingDialogMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "infoUpdateSettingPayload",
        "kind": "LinkedField",
        "name": "infoUpdateSetting",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SettingsItem",
            "kind": "LinkedField",
            "name": "settingItem",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "settingType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Image",
                "kind": "LinkedField",
                "name": "image",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "shape",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "overlay",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SemanticLine",
                "kind": "LinkedField",
                "name": "lines",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SemanticString",
                    "kind": "LinkedField",
                    "name": "parts",
                    "plural": true,
                    "selections": (v6/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "KeyField",
                "kind": "LinkedField",
                "name": "keyFields",
                "plural": true,
                "selections": (v7/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ShowField",
                "kind": "LinkedField",
                "name": "showFields",
                "plural": true,
                "selections": (v7/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "BodyField",
                "kind": "LinkedField",
                "name": "bodyFields",
                "plural": true,
                "selections": (v7/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Attachment",
                "kind": "LinkedField",
                "name": "attachments",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "contentType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "previewUrl",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4c42e0205ae0ff0a9b9e245a0f449704",
    "id": null,
    "metadata": {},
    "name": "EditSettingDialogMutation",
    "operationKind": "mutation",
    "text": "mutation EditSettingDialogMutation(\n  $input: infoUpdateSettingInput!\n) {\n  infoUpdateSetting(input: $input) {\n    clientMutationId\n    settingItem {\n      ...SettingsItem_settingsItem\n      id\n    }\n  }\n}\n\nfragment Attachment_attachment on Attachment {\n  url\n  name\n  contentType\n  previewUrl\n}\n\nfragment BodyField_data on BodyField {\n  label\n  value {\n    key\n    ...SemanticString_data\n  }\n}\n\nfragment KeyField_data on KeyField {\n  label\n  value {\n    key\n    ...SemanticString_data\n  }\n}\n\nfragment SemanticLine_data on SemanticLine {\n  parts {\n    key\n    ...SemanticString_data\n  }\n}\n\nfragment SemanticString_data on SemanticString {\n  text\n}\n\nfragment SettingItemImage_data on Image {\n  url\n  shape\n  overlay\n}\n\nfragment SettingsItem_settingsItem on SettingsItem {\n  slug\n  title\n  settingType\n  image {\n    ...SettingItemImage_data\n  }\n  lines {\n    key\n    ...SemanticLine_data\n  }\n  keyFields {\n    key\n    ...KeyField_data\n  }\n  showFields {\n    key\n    ...ShowField_data\n  }\n  bodyFields {\n    key\n    ...BodyField_data\n  }\n  attachments {\n    slug\n    ...Attachment_attachment\n  }\n}\n\nfragment ShowField_data on ShowField {\n  label\n  value {\n    key\n    ...SemanticString_data\n  }\n}\n"
  }
};
})();
(node as any).hash = '43f637dc17f635b0eee67e7f6acedafd';
export default node;
