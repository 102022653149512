/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PhysicalStatusEnum = "destination_port" | "discharge" | "empty_in_depot" | "empty_in_terminal" | "empty_in_transit" | "full_in_terminal" | "full_in_transit" | "in_warehouse" | "origin_port" | "shipped" | "stowed" | "%future added value";
export type ContainerLocation_container = {
    readonly slug: string;
    readonly physicalStatus: PhysicalStatusEnum | null;
    readonly dischargeDate: string | null;
    readonly terminalDate: string | null;
    readonly warehouseInDate: string | null;
    readonly validPhysicalStatuses: ReadonlyArray<{
        readonly value: PhysicalStatusEnum;
        readonly title: string;
    }>;
    readonly " $refType": "ContainerLocation_container";
};
export type ContainerLocation_container$data = ContainerLocation_container;
export type ContainerLocation_container$key = {
    readonly " $data"?: ContainerLocation_container$data;
    readonly " $fragmentRefs": FragmentRefs<"ContainerLocation_container">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContainerLocation_container",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "physicalStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dischargeDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "terminalDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "warehouseInDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ValidPhysicalStatus",
      "kind": "LinkedField",
      "name": "validPhysicalStatuses",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Container",
  "abstractKey": null
};
(node as any).hash = 'af69db45e613391660c9cd14df5c5681';
export default node;
