/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type AttachmentEntityTypeEnum = "contacts" | "containers" | "shipments" | "%future added value";
export type infoRemoveEntityThumbnailInput = {
    clientMutationId?: string | null;
    attachmentSlug: string;
    entityType: AttachmentEntityTypeEnum;
    entitySlug: string;
};
export type EntityAttachmentListPreviewerRemoveThumbnailMutationVariables = {
    input: infoRemoveEntityThumbnailInput;
};
export type EntityAttachmentListPreviewerRemoveThumbnailMutationResponse = {
    readonly infoRemoveEntityThumbnail: {
        readonly clientMutationId: string | null;
    } | null;
};
export type EntityAttachmentListPreviewerRemoveThumbnailMutation = {
    readonly response: EntityAttachmentListPreviewerRemoveThumbnailMutationResponse;
    readonly variables: EntityAttachmentListPreviewerRemoveThumbnailMutationVariables;
};



/*
mutation EntityAttachmentListPreviewerRemoveThumbnailMutation(
  $input: infoRemoveEntityThumbnailInput!
) {
  infoRemoveEntityThumbnail(input: $input) {
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "infoRemoveEntityThumbnailPayload",
    "kind": "LinkedField",
    "name": "infoRemoveEntityThumbnail",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EntityAttachmentListPreviewerRemoveThumbnailMutation",
    "selections": (v1/*: any*/),
    "type": "ApplicationCommand",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EntityAttachmentListPreviewerRemoveThumbnailMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a61af16a99a11bfb1435138060cdb2fe",
    "id": null,
    "metadata": {},
    "name": "EntityAttachmentListPreviewerRemoveThumbnailMutation",
    "operationKind": "mutation",
    "text": "mutation EntityAttachmentListPreviewerRemoveThumbnailMutation(\n  $input: infoRemoveEntityThumbnailInput!\n) {\n  infoRemoveEntityThumbnail(input: $input) {\n    clientMutationId\n  }\n}\n"
  }
};
})();
(node as any).hash = '9f1ed985a678dd9c9fa0da3bcf1410e8';
export default node;
