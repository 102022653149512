import { Avatar, Card, Typography } from '@material-ui/core'
import { graphql } from 'babel-plugin-relay/macro'
import React from 'react'
import styled from 'styled-components'
import Hero from '../../components/Hero'
import { media } from '../../theme'
import { useFragment } from 'react-relay/hooks'
import { LiveShareShipmentShowHeroCardQuery_shipment$key } from '../../__generated__/LiveShareShipmentShowHeroCardQuery_shipment.graphql'
import { LiveShareShipmentShowHeroCardQuery_item$key } from '../../__generated__/LiveShareShipmentShowHeroCardQuery_item.graphql'
import Stage, { StepWithArrow, StepRoot } from '../../components/Workflows/Stage'

interface Props {
  shipment: LiveShareShipmentShowHeroCardQuery_shipment$key
  item: LiveShareShipmentShowHeroCardQuery_item$key
}

const ProfileImage = styled(Avatar)`
  margin-right: 16px;
  height: 200px;
  width: 200px;
  border: 4px solid ${(props) => props.theme.palette.background.paper};
`

const StagesContainer = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  ${StepRoot}:last-child > ${StepWithArrow} {
    margin-right: 0;
    &:after {
      content: none;
    }
  }

  ${StepRoot}:first-child > ${StepWithArrow} {
    margin-left: 0;
    &:before {
      content: none;
    }
  }
`

const HeroContentRoot = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 900px;
  margin: auto;
`

const HeroDetailsCard = styled(Card)`
  width: 100%;
  padding: 16px;
  margin-top: 16px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;

  ${media.small`
    margin-left: 0px;
    margin-top: 8px;
  `}
`
const HeroFieldsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;

  ${media.medium`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${media.small`
    grid-template-columns: auto;
  `}
`

const Label = styled(Typography)`
  font-size: 12px;
  color: #333;
`
const Container = styled.div`
  max-width: 200px;
  width: 100%;
  margin: 2px 0;
`

const Value = styled(Typography)`
  color: #333;
  font-weight: 500;
  box-sizing: content-box;
  white-space: nowrap;
  overflow: hidden;
  line-height: 24px;
`
const LiveShareShipmentShowHeroCard: React.FC<Props> & { Skeleton: React.FC } = ({ shipment: shipmentData, item: itemData }) => {
  const shipment = useFragment(shipmentFragment, shipmentData)
  const item = useFragment(itemFragment, itemData)

  const stages = shipment.workflowStages
  return (
    <>
      <Hero backgroundColor='transparent' imageUrl={item?.image?.url as string}>
        <>
          <HeroContentRoot>
            <ProfileImage src={item?.image?.url as string} />
            <HeroDetailsCard variant='outlined'>
              <HeroFieldsContainer>
                <Container>
                  <Label variant='caption' color='textSecondary'>
                    VIN
                  </Label>
                  <Value variant='body1'>{shipment.vin}</Value>
                </Container>
                <Container>
                  <Label variant='caption' color='textSecondary'>
                    Shipment Key
                  </Label>
                  <Value variant='body1'>{shipment.shipmentKey}</Value>
                </Container>
                <Container>
                  <Label variant='caption' color='textSecondary'>
                    Description
                  </Label>
                  <Value variant='body1'>{shipment.description}</Value>
                </Container>
                <Container>
                  <Label variant='caption' color='textSecondary'>
                    Shipment Type
                  </Label>
                  <Value variant='body1'>{shipment.shipmentType}</Value>
                </Container>
                <Container>
                  <Label variant='caption' color='textSecondary'>
                    Container Slug
                  </Label>
                  <Value variant='body1'>{shipment.containerNumber}</Value>
                </Container>
                <Container>
                  <Label variant='caption' color='textSecondary'>
                    Weight
                  </Label>
                  <Value variant='body1'>{shipment.weight}</Value>
                </Container>
              </HeroFieldsContainer>
            </HeroDetailsCard>
          </HeroContentRoot>
          <StagesContainer>
            {stages.map(
              (stage, idx) =>
                stage && (
                  <Stage
                    key={stage.value}
                    stage={stage}
                    index={idx + 1}
                    onClick={() => console.log('abc')}
                    isFirst={idx === 0}
                    isLast={idx === stages.length - 1}
                  />
                )
            )}
          </StagesContainer>
        </>
      </Hero>
    </>
  )
}
const shipmentFragment = graphql`
  fragment LiveShareShipmentShowHeroCardQuery_shipment on LiveShareShipment {
    slug
    shipmentKey
    description
    shipmentType
    vin
    weight
    containerNumber
    workflowStages {
      value
      ...Stage_stage
    }
  }
`
const itemFragment = graphql`
  fragment LiveShareShipmentShowHeroCardQuery_item on ListItem {
    image {
      url
      ...ItemImage_data
    }
  }
`

LiveShareShipmentShowHeroCard.Skeleton = () => <>Loading...</>

export default LiveShareShipmentShowHeroCard
