import { LinearProgress, Typography } from '@material-ui/core'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import ListPageItem from '../../components/ListPageItem'
import { HighlightT } from '../../components/TextHighlighter'
import { ViewMode } from '../../viewTypes'
import { ItemList_data } from '../../__generated__/ItemList_data.graphql'

interface Props {
  items: ItemList_data['itemList']['items']
  viewMode: ViewMode
  highlights?: HighlightT[]
  expandAll: boolean
  isLoadingNext: boolean
  setViewMode?: (mode: ViewMode) => void
}

const NoResultsText = styled(Typography)`
  color: ${(props) => props.theme.palette.grey[300]};
  text-align: center;
  padding: 32px;
  padding-bottom: 8px;
  font-weight: 700;
`
const ResetFiltersLink = styled(Link)`
  text-decoration: none;
  display: block;
  text-align: center;
`
const ItemListContent: React.FC<Props> = ({ items, viewMode, highlights, isLoadingNext, expandAll }) => {
  return (
    <>
      {items && items.edges && items.edges.length > 0 ? (
        <>
          {items.edges.map((edge) => {
            const item = edge && edge.node
            if (item) return <ListPageItem key={item.slug} listItem={item} viewMode={viewMode} highlights={highlights} expandAll={expandAll} />
            return <></>
          })}
          {isLoadingNext && <LinearProgress />}
        </>
      ) : (
        <>
          <NoResultsText variant='h5'>No results</NoResultsText>
          <ResetFiltersLink to={`${window.location.pathname}`}>Reset Filters</ResetFiltersLink>
        </>
      )}
    </>
  )
}

export default ItemListContent
