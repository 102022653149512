/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type infoUpdateShipmentInput = {
    clientMutationId?: string | null;
    slug: string;
    shipmentKey: string;
    containerSlug: string;
    profileImageSlug?: string | null;
    shipmentType: string;
    vin?: string | null;
    description: string;
    weight?: number | null;
    parentShipment?: string | null;
    dischargeDate?: string | null;
    shipperReleaseCheck?: boolean | null;
    runs?: boolean | null;
    finalReleaseCheck?: boolean | null;
    atbPosition?: string | null;
    customsType?: string | null;
    customsReference?: string | null;
    vinEngine?: string | null;
    vinFuelType?: string | null;
    vinBodyClass?: string | null;
    vinOrigin?: string | null;
    linkedContacts: Array<LinkedContactInput>;
};
export type LinkedContactInput = {
    role: string;
    contactSlug: string;
};
export type ExternalShipmentEditMutationVariables = {
    input: infoUpdateShipmentInput;
};
export type ExternalShipmentEditMutationResponse = {
    readonly infoUpdateShipment: {
        readonly shipment: {
            readonly slug: string;
        };
    } | null;
};
export type ExternalShipmentEditMutation = {
    readonly response: ExternalShipmentEditMutationResponse;
    readonly variables: ExternalShipmentEditMutationVariables;
};



/*
mutation ExternalShipmentEditMutation(
  $input: infoUpdateShipmentInput!
) {
  infoUpdateShipment(input: $input) {
    shipment {
      slug
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ExternalShipmentEditMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "infoUpdateShipmentPayload",
        "kind": "LinkedField",
        "name": "infoUpdateShipment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Shipment",
            "kind": "LinkedField",
            "name": "shipment",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ApplicationCommand",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ExternalShipmentEditMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "infoUpdateShipmentPayload",
        "kind": "LinkedField",
        "name": "infoUpdateShipment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Shipment",
            "kind": "LinkedField",
            "name": "shipment",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5ed5d38d1ab540f5b8010fbc48644e7d",
    "id": null,
    "metadata": {},
    "name": "ExternalShipmentEditMutation",
    "operationKind": "mutation",
    "text": "mutation ExternalShipmentEditMutation(\n  $input: infoUpdateShipmentInput!\n) {\n  infoUpdateShipment(input: $input) {\n    shipment {\n      slug\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '408c5bc2f91d302602a9d20c7bdee118';
export default node;
