import { useLocation, useNavigate } from 'react-router'
import { parse, stringify } from 'qs'
type NavigateFn = (path?: string | null, query?: Record<string, unknown>) => void

type NavigateT = NavigateFn & {
  workspace: string
  workspaceName: string
  location: string
  unscoped: NavigateFn
  relative: (query?: Record<string, unknown>) => void
}

const useWorkspaceNavigate = (): NavigateT => {
  const location = useLocation()
  const navigate = useNavigate()
  const unscopedNavigate = (path?: string | null, query?: Record<string, unknown>) => {
    if (!query) return navigate(`/${path || ''}`)
    return navigate(`/${path || ''}?${stringify(query)}`)
  }
  const workspace = location.pathname.split('/')[1]
  const workspaceName = workspace.length > 1 ? `${workspace[0].toUpperCase()}${workspace.slice(1)}` : workspace

  const wsNavigate = (path?: string | null, query?: Record<string, unknown>) => unscopedNavigate(`/${workspace}${path || ''}`, query)
  wsNavigate.unscoped = unscopedNavigate
  wsNavigate.relative = (query?: Record<string, unknown>) => {
    const queryVariables = parse(location.search, { ignoreQueryPrefix: true })
    return navigate(`?${stringify({ ...queryVariables, ...query })}`)
  }
  wsNavigate.workspace = workspace
  wsNavigate.workspaceName = workspaceName
  wsNavigate.location = location.pathname

  return wsNavigate
}

export default useWorkspaceNavigate
