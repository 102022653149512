import { FieldHelperProps, FieldHookConfig, useField } from 'formik'
import { useRef } from 'react'

// Refer: https://github.com/formium/formik/issues/2268
// TLDR: useField `helpers` change on every render, hence can't be used as a dependency in `useEffect`
export function useFieldFast<Val = any>(propsOrFieldName: string | FieldHookConfig<Val>) {
  const [field, meta, helpers] = useField<Val>(propsOrFieldName)

  const actualHelpersRef = useRef<FieldHelperProps<Val>>(helpers)

  // On every render save newest helpers to actualHelpersRef
  actualHelpersRef.current.setValue = helpers.setValue
  actualHelpersRef.current.setTouched = helpers.setTouched
  actualHelpersRef.current.setError = helpers.setError

  // On the first render create new function which will never change
  // but call newest helper function
  const consistentHelpersRef = useRef<FieldHelperProps<Val>>({
    setValue: (...args) => actualHelpersRef.current.setValue(...args),
    setTouched: (value: boolean, shouldValidate?: boolean) => actualHelpersRef.current.setTouched(value, shouldValidate),
    setError: (...args) => actualHelpersRef.current.setError(...args),
  })

  return [field, meta, consistentHelpersRef.current] as const
}
