/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LiveShare_liveShares = {
    readonly nodes: ReadonlyArray<{
        readonly name: string;
        readonly slug: string;
        readonly password: string;
        readonly url: string;
        readonly contact: {
            readonly firstName: string;
            readonly lastName: string | null;
            readonly email: string | null;
        };
        readonly email: string | null;
        readonly expiresAt: string;
        readonly generatedBy: string | null;
        readonly generatedAt: string | null;
        readonly requestedBy: string | null;
        readonly requestedAt: string | null;
        readonly expired: boolean;
        readonly " $fragmentRefs": FragmentRefs<"LiveShareDrawer_liveshare">;
    } | null> | null;
    readonly " $refType": "LiveShare_liveShares";
};
export type LiveShare_liveShares$data = LiveShare_liveShares;
export type LiveShare_liveShares$key = {
    readonly " $data"?: LiveShare_liveShares$data;
    readonly " $fragmentRefs": FragmentRefs<"LiveShare_liveShares">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LiveShare_liveShares",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "LiveShare",
      "kind": "LinkedField",
      "name": "nodes",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "password",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Contact",
          "kind": "LinkedField",
          "name": "contact",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastName",
              "storageKey": null
            },
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "expiresAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "generatedBy",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "generatedAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "requestedBy",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "requestedAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "expired",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "LiveShareDrawer_liveshare"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "LiveShareConnection",
  "abstractKey": null
};
})();
(node as any).hash = '7c89059fe105567d4632ea38516708f9';
export default node;
