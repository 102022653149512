/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type infoUpdateContactConfigSettingInput = {
    clientMutationId?: string | null;
    slug: string;
    configSettingSlug: string;
    apply: boolean;
};
export type HomeContentConfigSettingsMutationVariables = {
    input: infoUpdateContactConfigSettingInput;
};
export type HomeContentConfigSettingsMutationResponse = {
    readonly infoUpdateContactConfigSetting: {
        readonly clientMutationId: string | null;
    } | null;
};
export type HomeContentConfigSettingsMutation = {
    readonly response: HomeContentConfigSettingsMutationResponse;
    readonly variables: HomeContentConfigSettingsMutationVariables;
};



/*
mutation HomeContentConfigSettingsMutation(
  $input: infoUpdateContactConfigSettingInput!
) {
  infoUpdateContactConfigSetting(input: $input) {
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "infoUpdateContactConfigSettingPayload",
    "kind": "LinkedField",
    "name": "infoUpdateContactConfigSetting",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "HomeContentConfigSettingsMutation",
    "selections": (v1/*: any*/),
    "type": "ApplicationCommand",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "HomeContentConfigSettingsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3aaedfb7eed53ce6e98ce2e13ddc382c",
    "id": null,
    "metadata": {},
    "name": "HomeContentConfigSettingsMutation",
    "operationKind": "mutation",
    "text": "mutation HomeContentConfigSettingsMutation(\n  $input: infoUpdateContactConfigSettingInput!\n) {\n  infoUpdateContactConfigSetting(input: $input) {\n    clientMutationId\n  }\n}\n"
  }
};
})();
(node as any).hash = '9948ab91636c52a5c785c84049cc6e31';
export default node;
