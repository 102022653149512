import React, { useState } from 'react'
import styled from 'styled-components'
import { media } from '../theme'
import SearchContacts from './SearchContacts'
import { graphql } from 'babel-plugin-relay/macro'
import { AddContactToRoleQuery } from '../__generated__/AddContactToRoleQuery.graphql'
import { useLazyLoadQuery } from 'react-relay/hooks'
import Button from './Button'
import RemoveContactLinkDialog from './RemoveContactLinkDialog'

interface Props {
  entityType: string
  entitySlug: string
  assignedRoles: AssignedRoles
}

export interface AssignedRoles {
  [role: string]: Record<string, string>
}

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  ${media.small`
    grid-template-columns: repeat(1, 1fr);
  `}

  ${media.mobile`
    grid-template-columns: repeat(1, 1fr);
  `}
`

const AddContactToRole: React.FC<Props> = ({ entityType, assignedRoles, entitySlug }) => {
  const [openSearchDrawer, setOpenSearchDrawer] = useState(false)
  const [removeRoleDialog, setRemoveRoleDialog] = useState(false)
  const [currentRole, setCurrentRole] = useState('')
  const closeDrawer = () => {
    setOpenSearchDrawer(false)
    setCurrentRole('')
  }
  const closeDialog = () => {
    setRemoveRoleDialog(false)
    setCurrentRole('')
  }
  const search = (role: string) => {
    setCurrentRole(role)
    setOpenSearchDrawer(true)
  }
  const remove = (role: string) => {
    setCurrentRole(role)
    setRemoveRoleDialog(true)
  }
  const data = useLazyLoadQuery<AddContactToRoleQuery>(query, { entityType: entityType })
  return (
    <>
      <Container>
        {data.validEntityRoles.map(({ name: title, value: role }) => (
          <Button key={role} onClick={() => (Object.keys(assignedRoles).includes(role) ? remove(role) : search(role))}>
            {Object.keys(assignedRoles).includes(role) ? `Remove ${title}` : `Add ${title}`}
          </Button>
        ))}
        <SearchContacts entityType={entityType} entitySlug={entitySlug} open={openSearchDrawer} onClose={closeDrawer} role={currentRole} />
        {removeRoleDialog && (
          <RemoveContactLinkDialog
            open={removeRoleDialog}
            onClose={closeDialog}
            entityType={entityType}
            entitySlug={entitySlug}
            role={currentRole}
            contactSlug={assignedRoles[currentRole]['slug']}
            title={assignedRoles[currentRole]['title']}
          />
        )}
      </Container>
    </>
  )
}

const query = graphql`
  query AddContactToRoleQuery($entityType: String!) {
    validEntityRoles(entityType: $entityType) {
      name
      value
    }
  }
`

export default AddContactToRole
