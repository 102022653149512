/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type TaxAssessmentsWithParams_list = {
    readonly taxAssessmentFiles: ReadonlyArray<{
        readonly entitySlug: string;
        readonly name: string;
        readonly linked: string;
        readonly uploadedAt: string;
        readonly callResponseUrl: string | null;
        readonly hyperlink: string | null;
        readonly verified: boolean;
        readonly attachmentSlug: string;
    }> | null;
    readonly paginationMeta: {
        readonly totalPages: number;
    } | null;
    readonly " $refType": "TaxAssessmentsWithParams_list";
};
export type TaxAssessmentsWithParams_list$data = TaxAssessmentsWithParams_list;
export type TaxAssessmentsWithParams_list$key = {
    readonly " $data"?: TaxAssessmentsWithParams_list$data;
    readonly " $fragmentRefs": FragmentRefs<"TaxAssessmentsWithParams_list">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TaxAssessmentsWithParams_list",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TaxAssessment",
      "kind": "LinkedField",
      "name": "taxAssessmentFiles",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "entitySlug",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "linked",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "uploadedAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "callResponseUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hyperlink",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "verified",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "attachmentSlug",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Paginate",
      "kind": "LinkedField",
      "name": "paginationMeta",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalPages",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TaxAssessmentList",
  "abstractKey": null
};
(node as any).hash = '8b0f621dddd6673c21e5721488ac9c32';
export default node;
