import { graphql } from 'babel-plugin-relay/macro'
import { parse } from 'qs'
import React, { Suspense, useState } from 'react'
import { useLazyLoadQuery } from 'react-relay/hooks'
import { useLocation } from 'react-router'
import Hero from '../../components/Hero'
import ResponsiveGrid from '../../components/ResponsiveGrid'
import useWorkspaceNavigate from '../../hooks/useWorkspaceNavigate'
import { isSmall } from '../../theme'
import { ExternalItemType, ViewMode } from '../../viewTypes'
import { ExternalItemListQuery, ExternalItemListQueryVariables } from '../../__generated__/ExternalItemListQuery.graphql'
import ExternalItemListContent from './ExternalItemListContent'
import ExternalItemListFilters from './ExternalItemListFilters'
import ExternalItemListFooter from './ExternalItemListFooter'
import ExternalItemListHeader from './ExternalItemListHeader'
import { ExternalItemListFiltersQuery } from '../../__generated__/ExternalItemListFiltersQuery.graphql'

interface Props {
  type?: ExternalItemType
  title: string
  description?: string
  entity?: string
  addItemUrl?: string
  roles?: []
}

const FiltersWrapper = ({ variables, type }: any) => {
  const filtersData = useLazyLoadQuery<ExternalItemListFiltersQuery>(filtersQuery, variables)
  return <ExternalItemListFilters data={filtersData.externalItemList} listType={type} showDataFilter={true} />
}

const ContentWrapper = ({ variables, viewMode }: any) => {
  const data = useLazyLoadQuery<ExternalItemListQuery>(query, variables)
  return <ExternalItemListContent data={data.externalItemList} viewMode={viewMode} />
}

const ExternalItemList: React.FC<Props> = ({ type, roles = [] }) => {
  const location = useLocation()
  const {
    sortKey,
    perPage: perPageRaw,
    page: pageRaw,
    searchQuery,
    voyageEtaStart,
    voyageEtaEnd,
    dischargeDateStart,
    dischargeDateEnd,
    includeArchived,
    ...queryVariables
  } = parse(location.search, {
    ignoreQueryPrefix: true,
  })
  const filters = Object.entries(queryVariables).map((entry) => ({ key: entry[0], values: entry[1] as string[] }))
  const page = Number.parseInt(pageRaw as string) || 1
  const perPage = Number.parseInt(perPageRaw as string) || 10
  const [viewMode, setViewMode] = useState<ViewMode>(isSmall() ? 'grid' : 'list')
  const wsNavigate = useWorkspaceNavigate()

  const variables: ExternalItemListQueryVariables = {
    type,
    filters,
    searchQuery: `${searchQuery || ''}`,
    sortKey: `${sortKey || ''}`,
    page,
    perPage,
    voyageEtaStart: voyageEtaStart !== undefined ? voyageEtaStart.toString() : undefined,
    voyageEtaEnd: voyageEtaEnd !== undefined ? voyageEtaEnd.toString() : undefined,
    dischargeDateStart: dischargeDateStart !== undefined ? dischargeDateStart.toString() : undefined,
    dischargeDateEnd: dischargeDateEnd !== undefined ? dischargeDateEnd.toString() : undefined,
    includeArchived: includeArchived === 'true',
  }
  const layout = viewMode === 'list' ? 'index-list-layout' : 'index-grid-layout'

  return (
    <>
      <Hero backgroundColor='transparent'>
        <Suspense key={type + 'header'} fallback={<>Loading...</>}>
          <ExternalItemListHeader variables={variables} viewMode={viewMode} setViewMode={setViewMode} roles={roles} />
        </Suspense>
      </Hero>
      <ResponsiveGrid
        type={layout}
        sidebar={
          isSmall()
            ? []
            : [
                <Suspense key={type + 'filters'} fallback={<>Loading...</>}>
                  <FiltersWrapper variables={variables} type={type} />
                </Suspense>,
              ]
        }
        content={[
          <Suspense key={type + 'item-list'} fallback={<>Loading...</>}>
            <ContentWrapper variables={variables} />
          </Suspense>,
        ]}
      />

      <ResponsiveGrid
        type={'index-list-layout'}
        content={[
          <Suspense key={type + 'footer'} fallback={<>Loading...</>}>
            <ExternalItemListFooter
              viewMode={viewMode}
              variables={variables}
              key={type + 'footer'}
              onPageChange={(page) => wsNavigate.relative({ page: page })}
            />
            ,
          </Suspense>,
        ]}
      />
    </>
  )
}

const query = graphql`
  query ExternalItemListQuery(
    $type: ExternalListItemTypeEnum
    $filters: [FilterInput!]
    $sortKey: String
    $page: Int
    $perPage: Int
    $searchQuery: String
    $voyageEtaStart: Date
    $voyageEtaEnd: Date
    $dischargeDateStart: Date
    $dischargeDateEnd: Date
    $includeArchived: Boolean
  ) {
    externalItemList(
      type: $type
      filters: $filters
      sortKey: $sortKey
      page: $page
      perPage: $perPage
      searchQuery: $searchQuery
      voyageEtaStart: $voyageEtaStart
      voyageEtaEnd: $voyageEtaEnd
      dischargeDateStart: $dischargeDateStart
      dischargeDateEnd: $dischargeDateEnd
      includeArchived: $includeArchived
    ) {
      ...ExternalItemListFooter_data
      ...ExternalItemListContent_data
      ...ExternalItemListHeader_data
    }
  }
`

const filtersQuery = graphql`
  query ExternalItemListFiltersQuery(
    $type: ExternalListItemTypeEnum
    $filters: [FilterInput!]
    $sortKey: String
    $page: Int
    $perPage: Int
    $searchQuery: String
    $voyageEtaStart: Date
    $voyageEtaEnd: Date
    $dischargeDateStart: Date
    $dischargeDateEnd: Date
  ) {
    externalItemList(
      type: $type
      filters: $filters
      sortKey: $sortKey
      page: $page
      perPage: $perPage
      searchQuery: $searchQuery
      voyageEtaStart: $voyageEtaStart
      voyageEtaEnd: $voyageEtaEnd
      dischargeDateStart: $dischargeDateStart
      dischargeDateEnd: $dischargeDateEnd
      showFilters: true
    ) {
      ...ExternalItemListFilters_data
    }
  }
`

export default ExternalItemList
