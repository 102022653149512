/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type EntityAttachment_attachment = {
    readonly url: string;
    readonly downloadUrl: string;
    readonly name: string;
    readonly contentType: string;
    readonly previewUrl: string | null;
    readonly documentType: string | null;
    readonly draftTicketStatus: string | null;
    readonly lexOfficeInvoiceStatus: string | null;
    readonly signedStatus: string | null;
    readonly rejectReason: string | null;
    readonly " $refType": "EntityAttachment_attachment";
};
export type EntityAttachment_attachment$data = EntityAttachment_attachment;
export type EntityAttachment_attachment$key = {
    readonly " $data"?: EntityAttachment_attachment$data;
    readonly " $fragmentRefs": FragmentRefs<"EntityAttachment_attachment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EntityAttachment_attachment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "downloadUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contentType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "previewUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "documentType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "draftTicketStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lexOfficeInvoiceStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "signedStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rejectReason",
      "storageKey": null
    }
  ],
  "type": "EntityAttachment",
  "abstractKey": null
};
(node as any).hash = '905f43d8b01f796e9ce10b023edba250';
export default node;
