/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ContainerEmailsQueryVariables = {
    slug: string;
};
export type ContainerEmailsQueryResponse = {
    readonly containerShow: {
        readonly emails: {
            readonly " $fragmentRefs": FragmentRefs<"EmailsTab_emails">;
        };
    };
};
export type ContainerEmailsQuery = {
    readonly response: ContainerEmailsQueryResponse;
    readonly variables: ContainerEmailsQueryVariables;
};



/*
query ContainerEmailsQuery(
  $slug: String!
) {
  containerShow(slug: $slug) {
    emails {
      ...EmailsTab_emails
    }
    id
  }
}

fragment Attachment_attachment on Attachment {
  url
  name
  contentType
  previewUrl
}

fragment EmailItem_email on Email {
  slug
  subject
  from
  to
  body
  sentAt
  attachments {
    name
    ...SmallAttachment_attachment
    ...Attachment_attachment
  }
}

fragment EmailsCard_emails on EmailConnection {
  nodes {
    slug
    ...EmailItem_email
    id
  }
}

fragment EmailsTab_emails on EmailConnection {
  ...EmailsCard_emails
}

fragment SmallAttachment_attachment on Attachment {
  url
  name
  contentType
  previewUrl
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ContainerEmailsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Container",
        "kind": "LinkedField",
        "name": "containerShow",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EmailConnection",
            "kind": "LinkedField",
            "name": "emails",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "EmailsTab_emails"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ContainerEmailsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Container",
        "kind": "LinkedField",
        "name": "containerShow",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EmailConnection",
            "kind": "LinkedField",
            "name": "emails",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Email",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "slug",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "subject",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "from",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "to",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "body",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sentAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Attachment",
                    "kind": "LinkedField",
                    "name": "attachments",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "url",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "contentType",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "previewUrl",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "87797974c3ae09a876774f6089edc42d",
    "id": null,
    "metadata": {},
    "name": "ContainerEmailsQuery",
    "operationKind": "query",
    "text": "query ContainerEmailsQuery(\n  $slug: String!\n) {\n  containerShow(slug: $slug) {\n    emails {\n      ...EmailsTab_emails\n    }\n    id\n  }\n}\n\nfragment Attachment_attachment on Attachment {\n  url\n  name\n  contentType\n  previewUrl\n}\n\nfragment EmailItem_email on Email {\n  slug\n  subject\n  from\n  to\n  body\n  sentAt\n  attachments {\n    name\n    ...SmallAttachment_attachment\n    ...Attachment_attachment\n  }\n}\n\nfragment EmailsCard_emails on EmailConnection {\n  nodes {\n    slug\n    ...EmailItem_email\n    id\n  }\n}\n\nfragment EmailsTab_emails on EmailConnection {\n  ...EmailsCard_emails\n}\n\nfragment SmallAttachment_attachment on Attachment {\n  url\n  name\n  contentType\n  previewUrl\n}\n"
  }
};
})();
(node as any).hash = '1ad9b0e15c0d4cc9cefb542376d0d0d3';
export default node;
