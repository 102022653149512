import { Paper, Typography } from '@material-ui/core'
import { graphql } from 'babel-plugin-relay/macro'
import React, { useState } from 'react'
import { useFragment } from 'react-relay/hooks'
import styled from 'styled-components'
import { EmailsCard_emails$key } from '../../__generated__/EmailsCard_emails.graphql'
import Button from '../Button'
import SendEmailDialog from './SendEmailDialog'
import { EmailEntityEnum } from './types'
import EmailItem from './EmailItem'

interface Props {
  entitySlug: string
  entityType: EmailEntityEnum
  emails: EmailsCard_emails$key
}

const Root = styled(Paper)`
  padding-top: 16px;
  margin-bottom: 16px;
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 16px;
  margin-bottom: 8px;
`

const NoDataText = styled(Typography)`
  color: ${(props) => props.theme.customPalette.text.noData};
  text-align: center;
  padding: 16px;
  font-weight: 400;
`

const EmailsCard: React.FC<Props> = ({ entitySlug, entityType, emails: data }) => {
  const [emailNewOpen, setEmailNewOpen] = useState(false)
  const emailsConnection = useFragment(fragment, data)

  return (
    <Root variant='outlined'>
      <TitleContainer>
        <Typography variant='h6'>Emails</Typography>
        {entityType !== 'voyages' && <Button onClick={() => setEmailNewOpen(true)}>New Email</Button>}
      </TitleContainer>
      {emailsConnection.nodes?.length === 0 && <NoDataText variant='h6'>No Emails</NoDataText>}
      {emailsConnection.nodes?.map((email) => email && <EmailItem key={email.slug} email={email} />)}
      <SendEmailDialog open={emailNewOpen} onClose={() => setEmailNewOpen(false)} entityType={entityType} entitySlug={entitySlug} />
    </Root>
  )
}

const fragment = graphql`
  fragment EmailsCard_emails on EmailConnection {
    nodes {
      slug
      ...EmailItem_email
    }
  }
`

export default EmailsCard
