/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LinkEnum = "contact" | "container" | "shipment" | "ticket" | "vessel" | "voyage" | "%future added value";
export type VoyageLinkCard_data = {
    readonly type: LinkEnum;
    readonly title: {
        readonly " $fragmentRefs": FragmentRefs<"SemanticString_data">;
    } | null;
    readonly meta: {
        readonly " $fragmentRefs": FragmentRefs<"SemanticString_data">;
    } | null;
    readonly voyageEta: {
        readonly " $fragmentRefs": FragmentRefs<"SemanticString_data">;
    } | null;
    readonly voyageEtd: {
        readonly " $fragmentRefs": FragmentRefs<"SemanticString_data">;
    } | null;
    readonly " $refType": "VoyageLinkCard_data";
};
export type VoyageLinkCard_data$data = VoyageLinkCard_data;
export type VoyageLinkCard_data$key = {
    readonly " $data"?: VoyageLinkCard_data$data;
    readonly " $fragmentRefs": FragmentRefs<"VoyageLinkCard_data">;
};



const node: ReaderFragment = (function(){
var v0 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "SemanticString_data"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "VoyageLinkCard_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SemanticString",
      "kind": "LinkedField",
      "name": "title",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SemanticString",
      "kind": "LinkedField",
      "name": "meta",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SemanticString",
      "kind": "LinkedField",
      "name": "voyageEta",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SemanticString",
      "kind": "LinkedField",
      "name": "voyageEtd",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Link",
  "abstractKey": null
};
})();
(node as any).hash = '111488395a9d040a0fbce13f586c54e8';
export default node;
