/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Attachment_attachment = {
    readonly url: string;
    readonly name: string;
    readonly contentType: string;
    readonly previewUrl: string | null;
    readonly " $refType": "Attachment_attachment";
};
export type Attachment_attachment$data = Attachment_attachment;
export type Attachment_attachment$key = {
    readonly " $data"?: Attachment_attachment$data;
    readonly " $fragmentRefs": FragmentRefs<"Attachment_attachment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Attachment_attachment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contentType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "previewUrl",
      "storageKey": null
    }
  ],
  "type": "Attachment",
  "abstractKey": null
};
(node as any).hash = '5f4897116266a05be888090fa4e995a0';
export default node;
