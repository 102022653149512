import React, { Suspense } from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import styled from 'styled-components'
import { Alert } from '@material-ui/lab'
import { DialogTitle, DialogContent, List, ListItemIcon, Checkbox, ListItemText, ListItem } from '@material-ui/core'
import useNiceMutation from '../../mutations/useNiceMutation'
import OdysseyDialog from '../OdysseyDialog'
import NiceFormik from '../Form/NiceFormik'
import DialogFormActionBar from '../Form/DialogFormActionBar'
import { FolderRoleVisibilityMutation } from '../../__generated__/FolderRoleVisibilityMutation.graphql'
import { FolderRoleVisibilityQuery } from '../../__generated__/FolderRoleVisibilityQuery.graphql'
import { useLazyLoadQuery } from 'react-relay/hooks'

interface Props {
  shareFolderOpen: boolean
  onClose: () => void
  slug: string
  name: string
}

export const StyledDialogContent = styled(DialogContent)`
  padding: 0;
`

const FolderRoleVisibility: React.FC<Props> = (props) => {
  return (
    <>
      <OdysseyDialog
        open={props.shareFolderOpen}
        fullWidth
        style={{ maxWidth: '640px', margin: 'auto' }}
        scroll='paper'
        onClose={props.onClose}
      >
        <Suspense fallback={<>Loading...</>}>
          <FolderRoleVisibilityContent {...props}></FolderRoleVisibilityContent>
        </Suspense>
      </OdysseyDialog>
    </>
  )
}

const FolderRoleVisibilityContent: React.FC<Props> = ({ onClose, slug, name }) => {
  const data = useLazyLoadQuery<FolderRoleVisibilityQuery>(query, { slug: slug })

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [commitVisibilityChange, loading, visibilityChangeError] = useNiceMutation<FolderRoleVisibilityMutation>(mutation)

  return (
    <>
      <NiceFormik
        initialValues={{ roleVisibilities: data.folderShow.roleVisibilities }}
        onSubmit={(values, actions) => {
          commitVisibilityChange({
            variables: {
              input: {
                slug: slug,
                visibleToRoles: values.roleVisibilities
                  .filter((roleVisibility) => roleVisibility.visible)
                  .map((roleVisibility) => roleVisibility.role),
              },
            },
            onCompleted: (_, errors) => {
              actions.setSubmitting(false)
              if (!errors) {
                onClose()
              }
            },
          })
        }}
      >
        {({ values, setFieldValue, submitForm }) => (
          <>
            <DialogTitle>Visibility for {name} folder</DialogTitle>
            {visibilityChangeError && <Alert severity='error'>{visibilityChangeError}</Alert>}
            <StyledDialogContent dividers>
              <List>
                {values.roleVisibilities.map((roleVisibility, idx) => (
                  <ListItem
                    key={roleVisibility.role}
                    role={undefined}
                    button
                    onClick={() => setFieldValue(`roleVisibilities[${idx}].visible`, !roleVisibility.visible)}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge='start'
                        checked={roleVisibility.visible}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': `${roleVisibility.role}-${idx}` }}
                      />
                    </ListItemIcon>
                    <ListItemText id={`${roleVisibility.role}-${idx}`} primary={`${roleVisibility.roleTitle}`} />
                  </ListItem>
                ))}
              </List>
            </StyledDialogContent>
            <DialogFormActionBar
              onCancel={onClose}
              onSubmit={() => {
                submitForm()
              }}
              cancelCta='Cancel'
              saveCta='Share'
            />
          </>
        )}
      </NiceFormik>
    </>
  )
}

const query = graphql`
  query FolderRoleVisibilityQuery($slug: String!) {
    folderShow(slug: $slug) {
      roleVisibilities {
        role
        roleShortTitle
        roleTitle
        visible
      }
    }
  }
`

const mutation = graphql`
  mutation FolderRoleVisibilityMutation($input: infoChangeFolderVisibilityInput!) {
    infoChangeFolderVisibility(input: $input) {
      clientMutationId
    }
  }
`

export default FolderRoleVisibility
