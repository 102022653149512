/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type infoSwitchWorkspaceInput = {
    clientMutationId?: string | null;
    workspaceSlug: string;
};
export type WorkspaceHeroMutationVariables = {
    input: infoSwitchWorkspaceInput;
};
export type WorkspaceHeroMutationResponse = {
    readonly infoSwitchWorkspace: {
        readonly clientMutationId: string | null;
    } | null;
};
export type WorkspaceHeroMutation = {
    readonly response: WorkspaceHeroMutationResponse;
    readonly variables: WorkspaceHeroMutationVariables;
};



/*
mutation WorkspaceHeroMutation(
  $input: infoSwitchWorkspaceInput!
) {
  infoSwitchWorkspace(input: $input) {
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "infoSwitchWorkspacePayload",
    "kind": "LinkedField",
    "name": "infoSwitchWorkspace",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WorkspaceHeroMutation",
    "selections": (v1/*: any*/),
    "type": "ApplicationCommand",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WorkspaceHeroMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c6181c49451c658903113f253ee379cb",
    "id": null,
    "metadata": {},
    "name": "WorkspaceHeroMutation",
    "operationKind": "mutation",
    "text": "mutation WorkspaceHeroMutation(\n  $input: infoSwitchWorkspaceInput!\n) {\n  infoSwitchWorkspace(input: $input) {\n    clientMutationId\n  }\n}\n"
  }
};
})();
(node as any).hash = 'd6dd9ff067d47a0836e12dff90181d7e';
export default node;
