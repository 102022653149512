/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type UnlinkedTaxAssessmentsWithParams_list = {
    readonly unlinkedTaxAssessmentFiles: ReadonlyArray<{
        readonly slug: string;
        readonly name: string;
        readonly createdAt: string;
        readonly callResponseUrl: string | null;
        readonly verified: boolean;
    }> | null;
    readonly paginationMeta: {
        readonly totalPages: number;
    } | null;
    readonly " $refType": "UnlinkedTaxAssessmentsWithParams_list";
};
export type UnlinkedTaxAssessmentsWithParams_list$data = UnlinkedTaxAssessmentsWithParams_list;
export type UnlinkedTaxAssessmentsWithParams_list$key = {
    readonly " $data"?: UnlinkedTaxAssessmentsWithParams_list$data;
    readonly " $fragmentRefs": FragmentRefs<"UnlinkedTaxAssessmentsWithParams_list">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UnlinkedTaxAssessmentsWithParams_list",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UnlinkedTaxAssessment",
      "kind": "LinkedField",
      "name": "unlinkedTaxAssessmentFiles",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "callResponseUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "verified",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Paginate",
      "kind": "LinkedField",
      "name": "paginationMeta",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalPages",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UnlinkedTaxAssessmentList",
  "abstractKey": null
};
(node as any).hash = '5175b579e84c7ebae029632319bcea40';
export default node;
