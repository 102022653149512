/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type FormFieldTypeEnum = "array" | "boolean" | "boolean_switch" | "container" | "container_image_slug" | "date" | "datetime" | "doc_slug" | "file_slug" | "image_slug" | "linked_contacts" | "multi_select" | "number" | "parent_shipment" | "port" | "rate_card" | "rich_text" | "searchable" | "shipment_image_slug" | "shipping_line" | "string" | "vessel" | "voyage" | "%future added value";
export type EditContainerFinanceDialogQueryVariables = {
    slug: string;
};
export type EditContainerFinanceDialogQueryResponse = {
    readonly infoUpdateContainerFinanceForm: {
        readonly formSchema: {
            readonly fieldSet: ReadonlyArray<{
                readonly name: string;
                readonly type: FormFieldTypeEnum;
                readonly required: boolean;
                readonly title: string | null;
                readonly description: string | null;
                readonly format: string | null;
                readonly enum: ReadonlyArray<string | null> | null;
                readonly enumTitles: ReadonlyArray<string | null> | null;
            }>;
        };
        readonly initialValue: {
            readonly slug: string;
            readonly costCleaning: number | null;
            readonly costCommission: number | null;
            readonly costDemurrage: number | null;
            readonly costOther: number | null;
            readonly costStorage: number | null;
            readonly costThc: number | null;
            readonly costTransport: number | null;
            readonly costXray: number | null;
            readonly estimatedCostDemurrage: number | null;
            readonly estimatedCostStorage: number | null;
        } | null;
    };
};
export type EditContainerFinanceDialogQuery = {
    readonly response: EditContainerFinanceDialogQueryResponse;
    readonly variables: EditContainerFinanceDialogQueryVariables;
};



/*
query EditContainerFinanceDialogQuery(
  $slug: String!
) {
  infoUpdateContainerFinanceForm(slug: $slug) {
    formSchema {
      fieldSet {
        name
        type
        required
        title
        description
        format
        enum
        enumTitles
      }
    }
    initialValue {
      slug
      costCleaning
      costCommission
      costDemurrage
      costOther
      costStorage
      costThc
      costTransport
      costXray
      estimatedCostDemurrage
      estimatedCostStorage
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "FormSchema",
  "kind": "LinkedField",
  "name": "formSchema",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "FormField",
      "kind": "LinkedField",
      "name": "fieldSet",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "required",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "format",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "enum",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "enumTitles",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "costCleaning",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "costCommission",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "costDemurrage",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "costOther",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "costStorage",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "costThc",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "costTransport",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "costXray",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "estimatedCostDemurrage",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "estimatedCostStorage",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditContainerFinanceDialogQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "InfoUpdateContainerFinanceFormResponse",
        "kind": "LinkedField",
        "name": "infoUpdateContainerFinanceForm",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Container",
            "kind": "LinkedField",
            "name": "initialValue",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditContainerFinanceDialogQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "InfoUpdateContainerFinanceFormResponse",
        "kind": "LinkedField",
        "name": "infoUpdateContainerFinanceForm",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Container",
            "kind": "LinkedField",
            "name": "initialValue",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1e2194ca423b201bf0bde1258c044c35",
    "id": null,
    "metadata": {},
    "name": "EditContainerFinanceDialogQuery",
    "operationKind": "query",
    "text": "query EditContainerFinanceDialogQuery(\n  $slug: String!\n) {\n  infoUpdateContainerFinanceForm(slug: $slug) {\n    formSchema {\n      fieldSet {\n        name\n        type\n        required\n        title\n        description\n        format\n        enum\n        enumTitles\n      }\n    }\n    initialValue {\n      slug\n      costCleaning\n      costCommission\n      costDemurrage\n      costOther\n      costStorage\n      costThc\n      costTransport\n      costXray\n      estimatedCostDemurrage\n      estimatedCostStorage\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '4e6af503b6d9bc976f8f5b78983f1233';
export default node;
