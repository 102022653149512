import { Edit } from '@material-ui/icons'
import { graphql } from 'babel-plugin-relay/macro'
import React, { useState } from 'react'
import styled from 'styled-components'
import useLazyLoadQueryWithSubscription from '../hooks/useLazyLoadQueryWithSubscription'
import useWorkspace from '../hooks/useWorkspace'
import { tabs } from '../pages/Voyage/tabs'
import { ShowPageRenderContext } from '../viewTypes'
import { VoyageShowHeroCardQuery } from '../__generated__/VoyageShowHeroCardQuery.graphql'
import Button from './Button'
import Hero from './Hero'
import ShowPageItem from './ShowPageItem'
import TabsUI from './TabsUI/TabsUI'
import { Title as ReactHeadTitle } from 'react-head'
import VoyageContainerReleaseWizard from './VoyageContainerReleaseWizard'

interface Props {
  slug: string
  activeTab: string
  onEdit: () => void
  renderContext: ShowPageRenderContext
}
const TabContainer = styled.div`
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const VoyageShowHeroCard: React.FC<Props> & { Skeleton: React.FC } = ({ slug, activeTab, onEdit, renderContext }) => {
  const { itemShow: item, voyageShow: voyage } = useLazyLoadQueryWithSubscription<VoyageShowHeroCardQuery>(
    query,
    { slug: slug, itemType: 'voyage' },
    { subscriptionOptions: { entityType: 'voyages', entitySlug: slug } }
  )

  const value = tabs.findIndex((t) => t.value === activeTab)
  const [, , wsNavigate] = useWorkspace()
  const [releaseContainer, setReleaseContainer] = useState(false)

  const onTabChange = (_: React.ChangeEvent<unknown>, newValue: number) => {
    wsNavigate(`/voyage/${item.slug}/${tabs[newValue].value}`)
  }

  return (
    <>
      {renderContext === 'page' && <ReactHeadTitle>Voyage - {item.title}</ReactHeadTitle>}
      <Hero backgroundColor='#fafafa'>
        <ShowPageItem data={item} />
      </Hero>
      <Hero backgroundColor='transparent'>
        <TabContainer>
          <TabsUI isWhiteBackground tabs={tabs} value={value} onChange={onTabChange} />
          <div>
            <Button variant='outlined' onClick={() => setReleaseContainer(true)}>
              Release Container
            </Button>
            {/* <Button variant='outlined' onClick={() => setReleaseRequestEmailDialog(true)}>
              Send Release Request Email
            </Button>
            <Button variant='outlined' onClick={() => setContainerReleaseDialogOpen(true)}>
              Release Containers
            </Button>
            <Button variant='outlined' onClick={() => setGeneratePickupSendMailContainersDialogOpen(true)}>
              Generate Pickup Doc & Send Mail
            </Button> */}
            <Button variant='contained' startIcon={<Edit />} onClick={() => onEdit()}>
              Edit
            </Button>
          </div>
        </TabContainer>
      </Hero>
      <VoyageContainerReleaseWizard slug={slug} open={releaseContainer} onClose={() => setReleaseContainer(false)} voyage={voyage} />
      {/* <ReleaseVoyageContainersDialog open={containerReleaseDialogOpen} onClose={() => setContainerReleaseDialogOpen(false)} voyage={voyage} /> */}
      {/* <ReleaseRequestVoyageContainersDialog
        open={containerReleaseRequestDialogOpen}
        onClose={() => setContainerReleaseRequestDialogOpen(false)}
        voyage={voyage}
      /> */}
      {/* <SendEmailDialog
        entityType='voyages'
        entitySlug={slug}
        open={showReleaseRequestEmailDialog}
        onClose={() => setReleaseRequestEmailDialog(false)}
        initialTemplateSlug='09-2-release-request-containers'
      ></SendEmailDialog> */}
      {/* <GeneratePickupSendMailVoyageContainersDialog
        open={generatePickupSendMailContainersDialogOpen}
        onClose={() => setGeneratePickupSendMailContainersDialogOpen(false)}
        voyage={voyage}
      /> */}
    </>
  )
}

const query = graphql`
  query VoyageShowHeroCardQuery($slug: String!, $itemType: ShowItemTypeEnum!) {
    itemShow(slug: $slug, type: $itemType) {
      slug
      title
      ...ShowPageItem_data
    }

    voyageShow(slug: $slug) {
      ...VoyageContainerReleaseWizard_voyage
      ...ReleaseVoyageContainersDialog_voyage
      ...ReleaseRequestVoyageContainersDialog_voyage
      ...GeneratePickupSendMailVoyageContainersDialog_voyage
    }
  }
`

VoyageShowHeroCard.Skeleton = () => <>Loading...</>

export default VoyageShowHeroCard
