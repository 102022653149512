import React from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components'
import Hero from '../../components/Hero'
import { TabPanel } from '../../components/TabsUI/TabsUI'
import { media } from '../../theme'
import HomeContent from './HomeContent'
import { tabs } from './tabs'
import WorkspaceHero from './WorkspaceHero'
import TabContent from './TabContent'

const WorkspaceLayout = styled.div`
  margin: auto;
  width: 1660px;

  ${media.medium`
    width: 1140px;
    padding-top: 8px;
  `}

  ${media.small`
    width: 100%;
    padding-top: 0;
    min-width: 360px;
    max-width: 625px;
  `}
`

const Workspace: React.FC = () => {
  const workspaceColor = '#7ca1ff'

  const { tab } = useParams()
  const value = tabs.findIndex((t) => t.value === tab)

  return (
    <>
      <Hero backgroundColor={workspaceColor}>
        <WorkspaceHero />
      </Hero>
      <WorkspaceLayout>
        {tabs.map((tab, idx) => (
          <TabPanel key={tab.type} value={value} index={idx}>
            {tab.type ? <TabContent type={tab.type} /> : <HomeContent />}
          </TabPanel>
        ))}
      </WorkspaceLayout>
    </>
  )
}

export default Workspace
