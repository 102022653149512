import React, { useState } from 'react'
import { useFragment } from 'react-relay/hooks'
import { graphql } from 'babel-plugin-relay/macro'
import { isSmall, isMedium } from '../theme'
import { Tooltip, Typography } from '@material-ui/core'
import { ShowLinkedContactsItem_linkedContact$key } from '../__generated__/ShowLinkedContactsItem_linkedContact.graphql'
import {
  RootWorkSpacedLink,
  Topbar,
  StatusContainer,
  RootContainer,
  Content,
  TitleRow,
  TitleContainer,
  TitleLink,
  TitleText,
  KeyFieldsContainer,
  Archivespan,
} from './ShowPageListItem'
import SemanticString from './SemanticString'
import ItemImage from './ItemImage'
import StickyNote from './StickyNote'
import KeyField from './KeyField'
import ArchiveIcon from '@material-ui/icons/Archive'
import ShowPageInDrawer, { ShowPageInDrawer_EntityType } from './ShowPageInDrawer'
import styled from 'styled-components'
import { startCase, camelCase } from 'lodash'

interface Props {
  contactLink: ShowLinkedContactsItem_linkedContact$key
  roles: string[]
  userType: string | null
}

const RoleText = styled(Typography)`
  display: inline-block;
  font-style: oblique;
  margin-right: 4px;
`

const Status = styled.div`
  padding: 12px 8px;
  display: flex;
  align-items: center;
  background-color: #e4e4e4;
  position: relative;
  height: 16px;

  &:after {
    content: '';
    position: absolute;
    right: -16px;
    display: block;
    width: 0;
    height: 0;
    border-top: 24px solid #e4e4e4;
    border-right: 16px solid transparent;
  }
`

const LinkedContactsItem: React.FC<Props> = ({ contactLink: data, roles, userType = null }) => {
  const contact = useFragment(fragment, data)
  const item = contact.contactItem
  const maxFields = isSmall() ? 3 : isMedium() ? 6 : 9
  const [sidebarShowOpen, setSidebarShowOpen] = useState(false)
  const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (e.ctrlKey) return

    e.preventDefault()
    if (userType && userType == 'external' && contact.roles.includes('warehouse')) {
      return
    }
    setSidebarShowOpen(true)
  }

  return (
    <>
      <RootWorkSpacedLink to={`/${item.itemType}/${item.slug}`} onClick={handleLinkClick}>
        <Topbar>
          {item.statuses && item.statuses.length > 0 && (
            <StatusContainer>
              {item.statuses.map((status) => (
                <Status key={status.key}>
                  <SemanticString data={status.value} />
                </Status>
              ))}
              {roles.map((role) => (
                <Status key={`role_${role}`}>{startCase(camelCase(role))}</Status>
              ))}
            </StatusContainer>
          )}
        </Topbar>
        <RootContainer>
          {item.image && <ItemImage variant='small' data={item.image} />}
          <Content>
            <TitleRow title={item.title}>
              <TitleContainer>
                <TitleLink>
                  <TitleText variant='subtitle1' color='textPrimary'>
                    {item.title}
                  </TitleText>
                  {contact.roles.map((role) => (
                    <RoleText variant='subtitle2' key={role}>
                      {role}
                    </RoleText>
                  ))}
                </TitleLink>
                {item.stickyNoteContent && <StickyNote note={item.stickyNoteContent} />}
                {item.isArchived && (
                  <Tooltip title='Archived'>
                    <Archivespan>
                      <ArchiveIcon />
                    </Archivespan>
                  </Tooltip>
                )}
              </TitleContainer>
            </TitleRow>
            {item.keyFields && (
              <KeyFieldsContainer>
                {item.keyFields?.slice(0, maxFields)?.map((field) => (
                  <KeyField key={field.key} data={field} />
                ))}
              </KeyFieldsContainer>
            )}
          </Content>
        </RootContainer>
      </RootWorkSpacedLink>
      <ShowPageInDrawer
        entityType={item.itemType as ShowPageInDrawer_EntityType}
        entitySlug={item.slug}
        open={sidebarShowOpen}
        onClose={() => setSidebarShowOpen(false)}
      />
    </>
  )
}

const fragment = graphql`
  fragment ShowLinkedContactsItem_linkedContact on ContactLink {
    role
    roles
    contactSlug
    contactItem {
      slug
      title
      isArchived
      path
      itemType
      statuses {
        key
        value {
          ...SemanticString_data
        }
      }
      image {
        ...ItemImage_data
      }
      lines {
        key
        ...SemanticLine_data
      }
      keyFields {
        key
        ...KeyField_data
      }

      stickyNoteContent
    }
  }
`

export default LinkedContactsItem
