import React from 'react'
import styled from 'styled-components'
import ErrorBox from '../components/ErrorBox'
import reportError from './reportError'

type State = {
  error: Error | null
}

type Props = {
  fallback: (error: Error | null, retry: () => void) => React.ReactNode
}

// Sample ErrorBoundary that supports retrying to render the content
// that errored
export default class ErrorBoundary extends React.Component<Props, State> {
  state = { error: null }

  static getDerivedStateFromError(error: Error | null) {
    if (error) reportError(error)
    return { error: error }
  }

  _retry = () => {
    this.setState({ error: null })
  }

  render() {
    const { children, fallback } = this.props
    const { error } = this.state
    if (error) {
      if (typeof fallback === 'function') {
        return fallback(error, this._retry)
      }
      return fallback
    }
    return children
  }
}

const ErrorBoxContainer = styled.div`
  width: 100%;
`
export const ErrorBoundaryWithBox: React.FC = ({ children }) => (
  <ErrorBoundary
    fallback={(error: Error | null, retry: () => void) =>
      error && (
        <ErrorBoxContainer>
          <ErrorBox error={error} onRetry={() => retry()} />
        </ErrorBoxContainer>
      )
    }
  >
    {children}
  </ErrorBoundary>
)
