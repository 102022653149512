import { Typography } from '@material-ui/core'
import { AddAPhoto } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import { graphql } from 'babel-plugin-relay/macro'
import React, { ChangeEvent, DragEventHandler, useState, useRef } from 'react'
import styled from 'styled-components'
import useNiceMutation from '../../mutations/useNiceMutation'
import { ImageUploadMutation } from '../../__generated__/ImageUploadMutation.graphql'
import Button from '../Button'
import { FieldVariantEnum } from '../Form/types'

interface Props {
  size?: FieldVariantEnum
  shape?: 'square' | 'circle'
  onSuccess: (imageSlug: string | null) => void
  placeHolderUrl?: string | null
}

interface RootProps {
  $size?: FieldVariantEnum
}

const Root = styled.div<RootProps>`
  width: ${(props) => (props.$size !== 'normal' ? '200px' : '100px')};
  display: flex;
  align-items: center;
  justify-content: center;
`

const Highlight = styled.div<{ $shape?: 'square' | 'circle'; $placeHolderUrl?: string }>`
  position: relative;
  padding: 32px;
  background-color: ${(props) => props.theme.palette.background.paper};
  text-align: center;
  border: 2px dashed rgb(187, 186, 186);
  border-radius: ${(props) => (props.$shape === 'circle' ? '50%' : '8px')};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 16px;
  overflow: hidden;

  ::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('${(props) => props.$placeHolderUrl}');
    background-size: cover;
    opacity: 0.2;
  }

  & > * {
    position: relative;
  }
`

const FileInput = styled.input`
  display: none;
`

interface ImageProps {
  shape?: 'square' | 'circle'
  size?: FieldVariantEnum
}

const Image = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const ImagePreview = styled.img<ImageProps>`
  min-width: ${(props) => (props.size !== 'normal' ? '200px' : '100px')};
  width: ${(props) => (props.size !== 'normal' ? '200px' : '100px')};
  height: ${(props) => (props.size !== 'normal' ? '200px' : '100px')};
  border-radius: ${(props) => (props.shape === 'circle' ? '50%' : '8px')};
  border: 2px solid ${(props) => props.theme.palette.divider};
`

const IMAGE_FILE_SIZE_LIMIT = 1.5
const VIDEO_FILE_SIZE_LIMIT = 15

const ImageUpload: React.FC<Props> = ({ size, shape, onSuccess, placeHolderUrl }) => {
  const [commit] = useNiceMutation<ImageUploadMutation>(mutation)

  const [, setHighlight] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const [fileDataURL, setFileDataURL] = useState<string | null>(null)

  const inputRef = useRef<HTMLInputElement>(null)

  const onUpload = (file: File) => {
    commit({
      variables: {
        input: {
          category: '',
          caption: '',
        },
      },
      uploadables: {
        file: file,
      },
      onCompleted: (res, errors) => {
        if (!errors) {
          onSuccess(res.infoUploadImage?.imageSlug || null)
        }
      },
    })
  }

  const readFile = (evt: ProgressEvent): void => {
    const reader: FileReader = evt.target as FileReader
    setFileDataURL(reader.result as string)
  }

  const onFileChange = (file: File | null): void => {
    if (!file) {
      setFileDataURL(null)
      return
    }
    const fileReader = new FileReader()
    fileReader.onloadend = readFile
    fileReader.readAsDataURL(file)
    onUpload(file)
  }

  const openFileDialog = (): void => {
    if (!inputRef.current) return
    inputRef.current.click()
  }

  const onFileInputChange = (evt: ChangeEvent<HTMLInputElement>) => {
    const file = evt.target.files ? (evt.target.files[0] as File) : null
    if (!file) return null

    const fileSize = Number.parseFloat((file.size / 1024 / 1024).toFixed(4))

    const isImage = file.type.startsWith('image/')
    const isVideo = file.type.startsWith('video/')

    if (fileSize > IMAGE_FILE_SIZE_LIMIT && isImage) setError('Image size cannot be greater than 1.5MB')
    else if (fileSize > VIDEO_FILE_SIZE_LIMIT && isVideo) setError('Image size cannot be greater than 1.5MB')
    else setFileData(file)
  }

  // const onFileCancel = () => setFileData(null)

  const setFileData = (file: File | null): void => {
    if (onFileChange) onFileChange(file)
  }

  const onDragOver: DragEventHandler = (evt) => {
    evt.preventDefault()
    setHighlight(true)
  }

  const onDragLeave = () => setHighlight(false)

  const onDrop: DragEventHandler = (evt) => {
    evt.preventDefault()
    setFileData(evt.dataTransfer ? (evt.dataTransfer.files[0] as File) : null)
    setHighlight(false)
  }

  return (
    <Root $size={size}>
      {fileDataURL ? (
        <Image>
          <ImagePreview size={size} shape={shape} src={fileDataURL} />
        </Image>
      ) : error ? (
        <Alert severity='error'>{error}</Alert>
      ) : (
        <Highlight $shape={shape} $placeHolderUrl={placeHolderUrl || ''} onDragOver={onDragOver} onDragLeave={onDragLeave} onDrop={onDrop}>
          <AddAPhoto color='action' />
          <Typography variant='subtitle1'>Drag an image here</Typography>
          <Typography variant='caption'>OR</Typography>
          <Button variant='outlined' color='primary' onClick={openFileDialog}>
            Choose
          </Button>
          <FileInput ref={inputRef} type='file' accept='image/*' onChange={onFileInputChange} value='' />
        </Highlight>
      )}
    </Root>
  )
}

const mutation = graphql`
  mutation ImageUploadMutation($input: infoUploadImageInput!) {
    infoUploadImage(input: $input) {
      imageSlug
    }
  }
`

export default ImageUpload
