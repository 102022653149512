import React, { useState, useEffect, Suspense } from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import styled from 'styled-components'
import { ShipmentInfoCard_shipment$key, ShipmentInfoCard_shipment } from '../__generated__/ShipmentInfoCard_shipment.graphql'
import { useFragment, useLazyLoadQuery } from 'react-relay/hooks'
import { Paper, DialogTitle, DialogContent, InputAdornment, Typography } from '@material-ui/core'
import KeyValueField from './KeyValueField'
import Button from './Button'
import NiceFormik from './Form/NiceFormik'
import { ShipmentInfoCardEditDialogQuery } from '../__generated__/ShipmentInfoCardEditDialogQuery.graphql'
import useNiceMutation from '../mutations/useNiceMutation'
import { ShipmentInfoCardEditMutation } from '../__generated__/ShipmentInfoCardEditMutation.graphql'
import { Form } from 'formik'
import { Alert, Skeleton } from '@material-ui/lab'
import DialogFormActionBar from './Form/DialogFormActionBar'
import { media } from '../theme'
import InputField from './Form/InputField'
import CopyActionWrapper from './CopyActionWrapper'
import OdysseyDialog from './OdysseyDialog'
import SearchContainer from './SearchContainers'
import UploadDocumentsWizard from './UploadDocumentsWizard'
import GeneratableAtcItem from './GeneratableAtcItem'

interface Props {
  shipment: ShipmentInfoCard_shipment$key
}

const FlexContainer = styled.div`
  display: flex;
  gap: 10px;
`

export const RootPaper = styled(Paper)`
  padding: 16px 16px 16px 0px;
  margin-bottom: 16px;
  display: flex;
`

export const TitleContainer = styled.div`
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const TitleAndContentContainer = styled.div`
  flex-grow: 1;
`

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 8px;
`

const Stage = styled.div<{ $selected: boolean }>`
  display: flex;
  align-items: center;
  background-color: #e8e8e8;
  color: #646464;
  position: relative;
  height: 32px;
  padding: 0 8px;
  margin-right: 16px;
  cursor: pointer;

  &:hover {
    filter: brightness(0.95);
  }

  &:after {
    content: ${(props) => (props.$selected ? "''" : 'none')};
    position: absolute;
    right: -12px;
    display: block;
    width: 0;
    height: 0;
    color: #646464;
    border-top: 16px solid transparent;
    border-bottom: 16px solid transparent;
    border-left: 12px solid #e8e8e8;
  }
`

const StagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-right: 8px;
  margin-left: -6px;

  ${Stage}:first-child {
    &:before {
      content: '';
      position: absolute;
      top: -4px;
      left: 0;
      width: 100%;
      height: 4px;
      border-top-right-radius: 8px;
      background-color: #e8e8e8;
    }
  }

  ${Stage}:last-child {
    &:before {
      content: '';
      position: absolute;
      bottom: -4px;
      left: 0;
      width: 100%;
      height: 4px;
      border-bottom-right-radius: 8px;
      background-color: #e8e8e8;
    }
  }
`

export const KeyFieldsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 8px;
`

const CustomsInfoTextContainer = styled.div`
  display: flex;
  align-items: center;
  white-space: pre-wrap;
`

const CustomsInfoTypography = styled(Typography)`
  cursor: pointer;
`

export const StyledForm = styled(Form)`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`

export const FormFieldsContainer = styled.div`
  margin-bottom: 16px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;

  ${media.medium`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${media.small`
    grid-template-columns: auto;
  `}
`

const CustomsTextLabelContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 1px;
    transform: translateY(-50%);
    background-color: ${(props) => (props.theme.palette.type === 'light' ? props.theme.palette.grey[300] : props.theme.palette.grey[700])};
  }
`

const CustomsTextLabelTypography = styled(Typography)`
  padding: 4px 8px;
  padding-left: 0;
  text-align: center;
  color: ${(props) => (props.theme.palette.type === 'light' ? props.theme.palette.grey[700] : props.theme.palette.grey[300])};
  z-index: 1;
  background-color: ${(props) => props.theme.palette.background.paper};
`
const InlineButton = styled(Button)`
  margin: 0px;
  padding: 0px;
`

const CustomsTextLabel: React.FC = (props) => (
  <CustomsTextLabelContainer>
    <CustomsTextLabelTypography variant='caption'>{props.children}</CustomsTextLabelTypography>
  </CustomsTextLabelContainer>
)

const currencyFields = ['shipmentValue', 'shippingCost', 'estimatedCustomsDuties', 'estimatedCustomsVat', 'receivedPayment']
const shipmentValueCurrencyFields = ['shipmentValue']
const shipmentCostCurrencyFields = ['shippingCost']

const ShipmentInfoCard: React.FC<Props> = ({ shipment: data }) => {
  const shipment = useFragment(fragment, data)
  const [selectedStage, setSelectedStage] = useState('document_collection')
  const [editDialogOpen, setEditDialogOpen] = useState(false)
  const [openSearchDrawer, setOpenSearchDrawer] = useState(false)
  const [openUploadWizard, setOpenUploadWizard] = useState(false)

  const selectedInfoGroup = shipment.infoGroupedByStage.find((infoGroup) => infoGroup.stageValue === selectedStage)

  useEffect(() => {
    setSelectedStage(
      shipment.infoGroupedByStage.filter((group) => group.stageValue === shipment.stage).length > 0
        ? shipment.stage
        : shipment.infoGroupedByStage[0].stageValue
    )
  }, [shipment])

  let addressArray = [
    shipment.customerAddress,
    shipment.customerState,
    shipment.customerPlace,
    shipment.customerPostcode,
    shipment.customerCountry,
  ]
  addressArray = addressArray.filter(function () {
    return true
  })
  const lastElement = addressArray.pop()

  const copyCustomsInfoText = () => {
    navigator.clipboard.writeText(shipment.customsClearanceInfoText)

    return true
  }

  const copyCustomerName = () => {
    navigator.clipboard.writeText(shipment.customerName as string)

    return true
  }

  const copyCustomerAddress = () => {
    navigator.clipboard.writeText(shipment.customerAddress as string)

    return true
  }

  const copyCustomerState = () => {
    navigator.clipboard.writeText(shipment.customerState as string)

    return true
  }

  const copyCustomerPostcode = () => {
    navigator.clipboard.writeText(shipment.customerPostcode as string)

    return true
  }
  const copyCustomerPlace = () => {
    navigator.clipboard.writeText(shipment.customerPlace as string)

    return true
  }
  const copyCustomerCountry = () => {
    navigator.clipboard.writeText(shipment.customerCountry as string)

    return true
  }

  const copyCustomerPhone = () => {
    navigator.clipboard.writeText(shipment.customerPhone as string)

    return true
  }
  const copyCustomerCompany = () => {
    navigator.clipboard.writeText(shipment.customerCompany as string)

    return true
  }
  const onEditClose = () => setEditDialogOpen(false)

  return (
    <>
      {openSearchDrawer && (
        <SearchContainer
          open={openSearchDrawer}
          onClose={() => setOpenSearchDrawer(false)}
          shipmentSlug={shipment.slug}
          currentContainerSlug={shipment.containerSlug}
        />
      )}
      <RootPaper variant='outlined'>
        <StagesContainer>
          {shipment.infoGroupedByStage.map((infoGroup, idx) => (
            <Stage
              key={infoGroup.stageValue}
              $selected={selectedStage === infoGroup.stageValue}
              onClick={() => setSelectedStage(infoGroup.stageValue)}
            >
              {idx + 1}
            </Stage>
          ))}
        </StagesContainer>
        <TitleAndContentContainer>
          <TitleContainer>
            <Typography variant='h6'>Info / {selectedInfoGroup?.stageLabel}</Typography>
            <Button onClick={() => setEditDialogOpen(true)}>Edit</Button>
          </TitleContainer>
          <Content>
            <KeyFieldsContainer>
              {selectedInfoGroup &&
                selectedInfoGroup.fields.map((field) => (
                  <KeyValueField
                    key={field.label}
                    label={field.label}
                    value={
                      currencyFields.includes(field.name)
                        ? `${field.value === null || field.value === undefined || field.value === '' ? '--' : field.value} ${
                            shipmentValueCurrencyFields.includes(field.name)
                              ? shipment.shipmentValueCurrency ?? ''
                              : shipmentCostCurrencyFields.includes(field.name)
                              ? shipment.shipmentCostCurrency ?? ''
                              : '€'
                          }`
                        : field.value
                    }
                  />
                ))}
            </KeyFieldsContainer>
            {selectedStage === 'document_collection' && shipment.stage === 'document_collection' && (
              <>
                {shipment?.requiredDocuments && shipment?.requiredDocuments?.length > 0 && (
                  <div>
                    <CustomsTextLabel>Document info text</CustomsTextLabel>
                    <CustomsInfoTextContainer>
                      <Typography color='error' variant='body2'>
                        Missing documents {shipment?.requiredDocuments?.join(', ')}
                      </Typography>
                    </CustomsInfoTextContainer>
                  </div>
                )}
                <FlexContainer>
                  <InlineButton onClick={() => setOpenSearchDrawer(true)}>Change Container</InlineButton>
                  <InlineButton onClick={() => setOpenUploadWizard(true)}>Upload Documents</InlineButton>
                </FlexContainer>
              </>
            )}
            {selectedStage === 'customs_clearance' && (
              <>
                <div>
                  <CustomsTextLabel>Customs info text</CustomsTextLabel>
                  <CustomsInfoTextContainer>
                    <CopyActionWrapper onChildClick={copyCustomsInfoText}>
                      <CustomsInfoTypography variant='body2'>{shipment.customsClearanceInfoText}</CustomsInfoTypography>
                    </CopyActionWrapper>
                  </CustomsInfoTextContainer>
                </div>
                <div>
                  <CustomsTextLabel>Customer Info</CustomsTextLabel>
                  <CustomsInfoTextContainer>
                    <CopyActionWrapper onChildClick={copyCustomerName}>
                      <CustomsInfoTypography variant='body2'>{shipment.customerName}</CustomsInfoTypography>
                    </CopyActionWrapper>
                  </CustomsInfoTextContainer>
                  {shipment.customerCompany && shipment.customerFormat === 'company' && (
                    <CustomsInfoTextContainer>
                      <CopyActionWrapper onChildClick={copyCustomerCompany}>
                        <CustomsInfoTypography variant='body2'>{shipment.customerCompany}</CustomsInfoTypography>
                      </CopyActionWrapper>
                    </CustomsInfoTextContainer>
                  )}
                  <CustomsInfoTextContainer>
                    {shipment.customerAddress && (
                      <CopyActionWrapper onChildClick={copyCustomerAddress}>
                        <CustomsInfoTypography variant='body2'>
                          {shipment.customerAddress}
                          {lastElement == shipment.customerAddress ? '' : ', '}
                        </CustomsInfoTypography>
                      </CopyActionWrapper>
                    )}
                    {shipment.customerState && (
                      <CopyActionWrapper onChildClick={copyCustomerState}>
                        <CustomsInfoTypography variant='body2'>
                          {shipment.customerState}
                          {lastElement == shipment.customerState ? '' : ', '}
                        </CustomsInfoTypography>
                      </CopyActionWrapper>
                    )}
                    {shipment.customerPostcode && (
                      <CopyActionWrapper onChildClick={copyCustomerPostcode}>
                        <CustomsInfoTypography variant='body2'>
                          {shipment.customerPostcode}
                          {lastElement == shipment.customerPostcode ? '' : ', '}
                        </CustomsInfoTypography>
                      </CopyActionWrapper>
                    )}
                    {shipment.customerPlace && (
                      <CopyActionWrapper onChildClick={copyCustomerPlace}>
                        <CustomsInfoTypography variant='body2'>
                          {shipment.customerPlace}
                          {lastElement == shipment.customerPlace ? '' : ', '}
                        </CustomsInfoTypography>
                      </CopyActionWrapper>
                    )}
                    {shipment.customerCountry && (
                      <CopyActionWrapper onChildClick={copyCustomerCountry}>
                        <CustomsInfoTypography variant='body2'>
                          {shipment.customerCountry}
                          {lastElement == shipment.customerCountry ? '' : ', '}
                        </CustomsInfoTypography>
                      </CopyActionWrapper>
                    )}
                  </CustomsInfoTextContainer>
                  <CustomsInfoTextContainer>
                    <CopyActionWrapper onChildClick={copyCustomerPhone}>
                      <CustomsInfoTypography variant='body2'>{shipment.customerPhone}</CustomsInfoTypography>
                    </CopyActionWrapper>
                  </CustomsInfoTextContainer>
                </div>
                <>
                  <FlexContainer>
                    <GeneratableAtcItem slug={shipment.slug} />
                  </FlexContainer>
                </>
              </>
            )}
          </Content>
        </TitleAndContentContainer>
      </RootPaper>
      <UploadDocumentsWizard shipment={data} slug={shipment.slug} open={openUploadWizard} onClose={() => setOpenUploadWizard(false)} />
      <OdysseyDialog open={editDialogOpen} fullWidth onClose={onEditClose} scroll='paper' style={{ maxWidth: '640px', margin: 'auto' }}>
        <DialogTitle>Editing Info / {selectedInfoGroup?.stageLabel}</DialogTitle>
        <Suspense fallback={<EditForm.Skeleton onClose={onEditClose} />}>
          {selectedInfoGroup && <EditForm slug={shipment.slug} selectedInfoGroup={selectedInfoGroup} onClose={onEditClose} />}
        </Suspense>
      </OdysseyDialog>
    </>
  )
}

interface EditFormProps {
  slug: string
  selectedInfoGroup: ShipmentInfoCard_shipment['infoGroupedByStage'][number]
  onClose: () => void
}

const EditForm: React.FC<EditFormProps> & { Skeleton: React.FC<{ onClose?: () => void }> } = ({ slug, selectedInfoGroup, onClose }) => {
  const data = useLazyLoadQuery<ShipmentInfoCardEditDialogQuery>(query, { slug: slug })
  const fields = data.infoUpdateShipmentInfoForm.formSchema.fieldSet
  const fieldMaster = Object.fromEntries(fields.map((field) => [field.name, field]))
  const [mutationError, setMutationError] = useState<null | string>(null)
  const [commit] = useNiceMutation<ShipmentInfoCardEditMutation>(mutation)
  const disabledFields = ['estimatedCustomsDuties', 'estimatedCustomsVat']

  return (
    <NiceFormik
      initialValues={data.infoUpdateShipmentInfoForm.initialValue || {}}
      onSubmit={(values, actions) => {
        commit({
          variables: {
            input: {
              ...values,
              slug: slug,
            },
          },
          onCompleted: (res, errors) => {
            actions.setSubmitting(false)
            if (errors) {
              setMutationError(errors.map((err) => err.message).join(', '))
            } else {
              onClose()
            }
          },
        })
      }}
      formSchema={fields}
    >
      {({ submitForm, values }) => (
        <StyledForm>
          {mutationError && <Alert severity='error'>{mutationError}</Alert>}
          <DialogContent dividers={true}>
            <FormFieldsContainer>
              {selectedInfoGroup.fields.map((infoField, index) => {
                return (
                  <InputField
                    key={`${infoField.name}-${index}`}
                    of={infoField.name}
                    field={fieldMaster[infoField.name]}
                    disabled={disabledFields.includes(infoField.name)}
                    InputProps={
                      currencyFields.includes(infoField.name)
                        ? {
                            endAdornment: (
                              <InputAdornment position='end'>
                                {shipmentValueCurrencyFields.includes(infoField.name)
                                  ? (values as any).shipmentValueCurrency ?? ''
                                  : shipmentCostCurrencyFields.includes(infoField.name)
                                  ? (values as any).shipmentCostCurrency ?? ''
                                  : '€'}
                              </InputAdornment>
                            ),
                          }
                        : {}
                    }
                  />
                )
              })}
            </FormFieldsContainer>
          </DialogContent>
          <DialogFormActionBar
            onCancel={() => onClose()}
            onSubmit={() => {
              submitForm()
            }}
            cancelCta='Cancel'
            saveCta='Save'
          />
        </StyledForm>
      )}
    </NiceFormik>
  )
}

EditForm.Skeleton = ({ onClose }) => (
  <>
    <DialogContent dividers={true}>
      <FormFieldsContainer>
        <Skeleton variant='rect' height='56px' />
        <Skeleton variant='rect' height='56px' />
        <Skeleton variant='rect' height='56px' />
        <Skeleton variant='rect' height='56px' />
      </FormFieldsContainer>
    </DialogContent>
    <DialogFormActionBar.Skeleton onCancel={onClose} />
  </>
)

const fragment = graphql`
  fragment ShipmentInfoCard_shipment on Shipment {
    slug
    stage
    shipmentValueCurrency
    shipmentCostCurrency
    customsClearanceInfoText
    customerName
    customerAddress
    customerState
    customerPostcode
    customerPlace
    customerCountry
    customerPhone
    customerCompany
    customerFormat
    containerSlug
    requiredDocuments
    infoGroupedByStage {
      stageLabel
      stageValue
      fields {
        label
        name
        value
      }
    }
  }
`

const query = graphql`
  query ShipmentInfoCardEditDialogQuery($slug: String!) {
    infoUpdateShipmentInfoForm(slug: $slug) {
      formSchema {
        fieldSet {
          name
          type
          required
          title
          description
          format
          enum
          enumTitles
        }
      }
      initialValue {
        slug
        shipmentValue
        shippingCost
        shipmentValueCurrency
        shipmentCostCurrency
        weight
        title
        customsStartDate
        customsFinishedDate
        climaNeededDate
        zodiakId
        paymentChannel
        paymentDate
        pickupDone
        expectedHandoverDate
        handoverDate
        customsResponsible
        receivedPayment
        estimatedCustomsDuties
        estimatedCustomsVat
        location
        incoTerms
        clima
        bsNumber
        bsDate
        titleType
        damage
        incoCity
        shippingInvoiceNumber
        shippingInvoiceDate
      }
    }
  }
`

const mutation = graphql`
  mutation ShipmentInfoCardEditMutation($input: infoUpdateShipmentInfoInput!) {
    infoUpdateShipmentInfo(input: $input) {
      clientMutationId
      shipment {
        ...ShipmentInfoCard_shipment
      }
    }
  }
`

export default ShipmentInfoCard
