/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type WatchersContainerQueryVariables = {
    slug: string;
};
export type WatchersContainerQueryResponse = {
    readonly containerShow: {
        readonly watches: {
            readonly " $fragmentRefs": FragmentRefs<"Watchers_watches">;
        };
    };
};
export type WatchersContainerQuery = {
    readonly response: WatchersContainerQueryResponse;
    readonly variables: WatchersContainerQueryVariables;
};



/*
query WatchersContainerQuery(
  $slug: String!
) {
  containerShow(slug: $slug) {
    watches {
      ...Watchers_watches
    }
    id
  }
}

fragment Watchers_watches on ItemWatchConnection {
  nodes {
    contactSlug
    contactItem {
      title
      image {
        url
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WatchersContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Container",
        "kind": "LinkedField",
        "name": "containerShow",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ItemWatchConnection",
            "kind": "LinkedField",
            "name": "watches",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "Watchers_watches"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WatchersContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Container",
        "kind": "LinkedField",
        "name": "containerShow",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ItemWatchConnection",
            "kind": "LinkedField",
            "name": "watches",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ItemWatch",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "contactSlug",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ListItem",
                    "kind": "LinkedField",
                    "name": "contactItem",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Image",
                        "kind": "LinkedField",
                        "name": "image",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "url",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "714b90840585518c440bf5d19503553b",
    "id": null,
    "metadata": {},
    "name": "WatchersContainerQuery",
    "operationKind": "query",
    "text": "query WatchersContainerQuery(\n  $slug: String!\n) {\n  containerShow(slug: $slug) {\n    watches {\n      ...Watchers_watches\n    }\n    id\n  }\n}\n\nfragment Watchers_watches on ItemWatchConnection {\n  nodes {\n    contactSlug\n    contactItem {\n      title\n      image {\n        url\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '3fdc125c7a0a0da4edb4e7c04c5a2d68';
export default node;
