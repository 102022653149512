/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AttachmentEntityTypeEnum = "contacts" | "containers" | "shipments" | "%future added value";
export type EntityAttachmentListPreviewer_attachment = {
    readonly url: string;
    readonly downloadUrl: string;
    readonly name: string;
    readonly contentType: string;
    readonly previewUrl: string | null;
    readonly documentType: string | null;
    readonly entityType: AttachmentEntityTypeEnum;
    readonly entitySlug: string;
    readonly attachmentSlug: string;
    readonly thumbnailImage: boolean;
    readonly " $fragmentRefs": FragmentRefs<"EntityAttachmentRoleVisibility_attachment">;
    readonly " $refType": "EntityAttachmentListPreviewer_attachment";
};
export type EntityAttachmentListPreviewer_attachment$data = EntityAttachmentListPreviewer_attachment;
export type EntityAttachmentListPreviewer_attachment$key = {
    readonly " $data"?: EntityAttachmentListPreviewer_attachment$data;
    readonly " $fragmentRefs": FragmentRefs<"EntityAttachmentListPreviewer_attachment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EntityAttachmentListPreviewer_attachment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "downloadUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contentType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "previewUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "documentType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entityType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entitySlug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "attachmentSlug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnailImage",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EntityAttachmentRoleVisibility_attachment"
    }
  ],
  "type": "EntityAttachment",
  "abstractKey": null
};
(node as any).hash = 'e6fdfe644b03d0ad5cc1ae2ab988dd20';
export default node;
