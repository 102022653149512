/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ExternalUploadDocumentsWizard_shipment = {
    readonly slug: string;
    readonly requiredDocuments: ReadonlyArray<string> | null;
    readonly " $refType": "ExternalUploadDocumentsWizard_shipment";
};
export type ExternalUploadDocumentsWizard_shipment$data = ExternalUploadDocumentsWizard_shipment;
export type ExternalUploadDocumentsWizard_shipment$key = {
    readonly " $data"?: ExternalUploadDocumentsWizard_shipment$data;
    readonly " $fragmentRefs": FragmentRefs<"ExternalUploadDocumentsWizard_shipment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExternalUploadDocumentsWizard_shipment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "requiredDocuments",
      "storageKey": null
    }
  ],
  "type": "ExternalShipment",
  "abstractKey": null
};
(node as any).hash = 'd6478b76c0e7ac521c75ab3a2898076a';
export default node;
