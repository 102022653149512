/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type GeneratableDocItem_document = {
    readonly templateSlug: string;
    readonly title: string;
    readonly docType: string;
    readonly errors: ReadonlyArray<string>;
    readonly existingAttachment: {
        readonly attachmentSlug: string;
        readonly uploadedAtDisplay: string | null;
        readonly " $fragmentRefs": FragmentRefs<"EntityAttachmentListPreviewer_attachment">;
    } | null;
    readonly " $refType": "GeneratableDocItem_document";
};
export type GeneratableDocItem_document$data = GeneratableDocItem_document;
export type GeneratableDocItem_document$key = {
    readonly " $data"?: GeneratableDocItem_document$data;
    readonly " $fragmentRefs": FragmentRefs<"GeneratableDocItem_document">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GeneratableDocItem_document",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "templateSlug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "docType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "errors",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EntityAttachment",
      "kind": "LinkedField",
      "name": "existingAttachment",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "attachmentSlug",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "uploadedAtDisplay",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EntityAttachmentListPreviewer_attachment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "GeneratableDocument",
  "abstractKey": null
};
(node as any).hash = '26206de68f7c98d344a658912cc688ba';
export default node;
