import { Card } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'
import { media } from '../../theme'
import InputField from '../../components/Form/InputField'
import { FieldT } from '../../components/Form/types'
import Hero from '../../components/Hero'
import { Skeleton } from '@material-ui/lab'

const DetailsCard = styled(Card).attrs({ variant: 'outlined' })`
  margin-top: 16px;
  padding: 16px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 16px;

  ${media.medium`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${media.small`
    grid-template-columns: auto;
  `}
`

interface Props {
  fieldMaster: {
    [key: string]: FieldT
  }
}

const VesselForm: React.FC<Props> & { Skeleton: React.FC } = ({ fieldMaster }) => {
  return (
    <Hero backgroundColor='transparent'>
      <DetailsCard>
        <InputField of='name' field={fieldMaster['name']} />
        <InputField of='shippingLineSlug' field={fieldMaster['shippingLineSlug']} />
        <InputField of='imoNumber' field={fieldMaster['imoNumber']} />
      </DetailsCard>
    </Hero>
  )
}

const FieldSkeleton = styled(Skeleton).attrs({ variant: 'rect', height: '50px' })``
VesselForm.Skeleton = () => (
  <>
    <Hero backgroundColor='transparent'>
      <DetailsCard>
        <FieldSkeleton />
        <FieldSkeleton />
        <FieldSkeleton />
      </DetailsCard>
    </Hero>
  </>
)

export default VesselForm
