/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SmallAttachment_attachment = {
    readonly url: string;
    readonly name: string;
    readonly contentType: string;
    readonly previewUrl: string | null;
    readonly " $refType": "SmallAttachment_attachment";
};
export type SmallAttachment_attachment$data = SmallAttachment_attachment;
export type SmallAttachment_attachment$key = {
    readonly " $data"?: SmallAttachment_attachment$data;
    readonly " $fragmentRefs": FragmentRefs<"SmallAttachment_attachment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SmallAttachment_attachment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contentType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "previewUrl",
      "storageKey": null
    }
  ],
  "type": "Attachment",
  "abstractKey": null
};
(node as any).hash = '3cf0528587f07fcc9367d5440b181827';
export default node;
