import React from 'react'
import { Outlet } from 'react-router-dom'
import styled from 'styled-components'
import { media } from '../../theme'

const Root = styled.div`
  width: 1660px;
  margin: auto;
  margin-top: 8px;

  ${media.medium`
    width: 1140px;
  `}

  ${media.small`
    width: 100%;
    min-width: 360px;
    max-width: 625px;
  `}
`
const Admin: React.FC = () => {
  return (
    <Root>
      <Outlet />
    </Root>
  )
}

export default Admin
