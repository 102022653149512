/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ShipmentStageEnum = "billing" | "customs_clearance" | "document_collection" | "done" | "handover" | "%future added value";
export type infoV2UpdateShipmentInput = {
    clientMutationId?: string | null;
    slug: string;
    shipmentKey?: string | null;
    containerSlug?: string | null;
    profileImageSlug?: string | null;
    shipmentType?: string | null;
    description?: string | null;
    vin?: string | null;
    weight?: number | null;
    dischargeDate?: string | null;
    shipperReleaseCheck?: boolean | null;
    runs?: boolean | null;
    finalReleaseCheck?: boolean | null;
    atbPosition?: string | null;
    customsType?: string | null;
    customsReference?: string | null;
    vinEngine?: string | null;
    vinFuelType?: string | null;
    vinBodyClass?: string | null;
    vinOrigin?: string | null;
    stage?: string | null;
    handoverDate?: string | null;
    paymentDate?: string | null;
    costWarehousing?: number | null;
    revenueWarehousing?: number | null;
    costDischarge?: number | null;
    revenueDischarge?: number | null;
    costTransport?: number | null;
    revenueTransport?: number | null;
    costCustomsInspection?: number | null;
    revenueCustomsInspection?: number | null;
    costClimaCertificate?: number | null;
    revenueClimaCertificate?: number | null;
    costOpinion?: number | null;
    revenueOpinion?: number | null;
    costContainerXray?: number | null;
    revenueContainerXray?: number | null;
    costContainerHandling?: number | null;
    revenueContainerHandling?: number | null;
    costShipping?: number | null;
    revenueShipping?: number | null;
    costCleaning?: number | null;
    revenueCleaning?: number | null;
    costSpecialHandling?: number | null;
    revenueSpecialHandling?: number | null;
    costStorage?: number | null;
    revenueStorage?: number | null;
    costForklift?: number | null;
    revenueForklift?: number | null;
    costOther?: number | null;
    revenueCustomsClearance?: number | null;
    customsDuties?: number | null;
    customsVat?: number | null;
    discount?: number | null;
    discountRemark?: string | null;
    receivedPayment?: number | null;
    expectedHandoverDate?: string | null;
    linkedContacts?: Array<LinkedContactInput> | null;
};
export type LinkedContactInput = {
    role: string;
    contactSlug: string;
};
export type ShipmentsTableMutationVariables = {
    input: infoV2UpdateShipmentInput;
};
export type ShipmentsTableMutationResponse = {
    readonly infoV2UpdateShipment: {
        readonly shipment: {
            readonly automaticHandover: boolean | null;
            readonly costCleaning: number | null;
            readonly costClimaCertificate: number | null;
            readonly costContainerHandling: number | null;
            readonly costContainerXray: number | null;
            readonly costCustomsInspection: number | null;
            readonly costDischarge: number | null;
            readonly costForklift: number | null;
            readonly costOpinion: number | null;
            readonly costOther: number | null;
            readonly costShipping: number | null;
            readonly costSpecialHandling: number | null;
            readonly costStorage: number | null;
            readonly costTransport: number | null;
            readonly costWarehousing: number | null;
            readonly description: string;
            readonly dischargeDate: string | null;
            readonly eta: string | null;
            readonly expectedHandoverDate: string | null;
            readonly finalReleaseCheck: boolean;
            readonly handoverDate: string | null;
            readonly id: string;
            readonly isArchived: boolean;
            readonly paymentDate: string | null;
            readonly revenueCleaning: number | null;
            readonly revenueClimaCertificate: number | null;
            readonly revenueContainerHandling: number | null;
            readonly revenueContainerXray: number | null;
            readonly revenueCustomsClearance: number | null;
            readonly revenueCustomsInspection: number | null;
            readonly revenueDischarge: number | null;
            readonly revenueForklift: number | null;
            readonly revenueOpinion: number | null;
            readonly revenueShipping: number | null;
            readonly revenueSpecialHandling: number | null;
            readonly revenueStorage: number | null;
            readonly revenueTransport: number | null;
            readonly revenueWarehousing: number | null;
            readonly shipperReleaseCheck: boolean;
            readonly slug: string;
            readonly stage: ShipmentStageEnum;
            readonly totalRevenue: number;
            readonly type: string;
            readonly vatCalculator: number | null;
            readonly vin: string | null;
            readonly warehouse: {
                readonly slug: string;
                readonly name: string;
            } | null;
            readonly transporter: {
                readonly name: string;
                readonly slug: string;
            } | null;
            readonly payer: {
                readonly name: string;
                readonly slug: string;
            } | null;
            readonly linkedContacts: ReadonlyArray<{
                readonly contactSlug: string;
                readonly role: string;
            }>;
        };
    } | null;
};
export type ShipmentsTableMutation = {
    readonly response: ShipmentsTableMutationResponse;
    readonly variables: ShipmentsTableMutationVariables;
};



/*
mutation ShipmentsTableMutation(
  $input: infoV2UpdateShipmentInput!
) {
  infoV2UpdateShipment(input: $input) {
    shipment {
      automaticHandover
      costCleaning
      costClimaCertificate
      costContainerHandling
      costContainerXray
      costCustomsInspection
      costDischarge
      costForklift
      costOpinion
      costOther
      costShipping
      costSpecialHandling
      costStorage
      costTransport
      costWarehousing
      description
      dischargeDate
      eta
      expectedHandoverDate
      finalReleaseCheck
      handoverDate
      id
      isArchived
      paymentDate
      revenueCleaning
      revenueClimaCertificate
      revenueContainerHandling
      revenueContainerXray
      revenueCustomsClearance
      revenueCustomsInspection
      revenueDischarge
      revenueForklift
      revenueOpinion
      revenueShipping
      revenueSpecialHandling
      revenueStorage
      revenueTransport
      revenueWarehousing
      shipperReleaseCheck
      slug
      stage
      totalRevenue
      type
      vatCalculator
      vin
      warehouse {
        slug
        name
        id
      }
      transporter {
        name
        slug
        id
      }
      payer {
        name
        slug
        id
      }
      linkedContacts {
        contactSlug
        role
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "automaticHandover",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "costCleaning",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "costClimaCertificate",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "costContainerHandling",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "costContainerXray",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "costCustomsInspection",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "costDischarge",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "costForklift",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "costOpinion",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "costOther",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "costShipping",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "costSpecialHandling",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "costStorage",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "costTransport",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "costWarehousing",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dischargeDate",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "eta",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "expectedHandoverDate",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "finalReleaseCheck",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handoverDate",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isArchived",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paymentDate",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "revenueCleaning",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "revenueClimaCertificate",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "revenueContainerHandling",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "revenueContainerXray",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "revenueCustomsClearance",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "revenueCustomsInspection",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "revenueDischarge",
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "revenueForklift",
  "storageKey": null
},
v34 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "revenueOpinion",
  "storageKey": null
},
v35 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "revenueShipping",
  "storageKey": null
},
v36 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "revenueSpecialHandling",
  "storageKey": null
},
v37 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "revenueStorage",
  "storageKey": null
},
v38 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "revenueTransport",
  "storageKey": null
},
v39 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "revenueWarehousing",
  "storageKey": null
},
v40 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shipperReleaseCheck",
  "storageKey": null
},
v41 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v42 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stage",
  "storageKey": null
},
v43 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalRevenue",
  "storageKey": null
},
v44 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v45 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "vatCalculator",
  "storageKey": null
},
v46 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "vin",
  "storageKey": null
},
v47 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v48 = [
  (v47/*: any*/),
  (v41/*: any*/)
],
v49 = {
  "alias": null,
  "args": null,
  "concreteType": "ContactLink",
  "kind": "LinkedField",
  "name": "linkedContacts",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contactSlug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "role",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v50 = [
  (v47/*: any*/),
  (v41/*: any*/),
  (v23/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ShipmentsTableMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "infoV2UpdateShipmentPayload",
        "kind": "LinkedField",
        "name": "infoV2UpdateShipment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ShipmentV2",
            "kind": "LinkedField",
            "name": "shipment",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/),
              (v23/*: any*/),
              (v24/*: any*/),
              (v25/*: any*/),
              (v26/*: any*/),
              (v27/*: any*/),
              (v28/*: any*/),
              (v29/*: any*/),
              (v30/*: any*/),
              (v31/*: any*/),
              (v32/*: any*/),
              (v33/*: any*/),
              (v34/*: any*/),
              (v35/*: any*/),
              (v36/*: any*/),
              (v37/*: any*/),
              (v38/*: any*/),
              (v39/*: any*/),
              (v40/*: any*/),
              (v41/*: any*/),
              (v42/*: any*/),
              (v43/*: any*/),
              (v44/*: any*/),
              (v45/*: any*/),
              (v46/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Contact",
                "kind": "LinkedField",
                "name": "warehouse",
                "plural": false,
                "selections": [
                  (v41/*: any*/),
                  (v47/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Contact",
                "kind": "LinkedField",
                "name": "transporter",
                "plural": false,
                "selections": (v48/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Contact",
                "kind": "LinkedField",
                "name": "payer",
                "plural": false,
                "selections": (v48/*: any*/),
                "storageKey": null
              },
              (v49/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ApplicationCommand",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ShipmentsTableMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "infoV2UpdateShipmentPayload",
        "kind": "LinkedField",
        "name": "infoV2UpdateShipment",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ShipmentV2",
            "kind": "LinkedField",
            "name": "shipment",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/),
              (v15/*: any*/),
              (v16/*: any*/),
              (v17/*: any*/),
              (v18/*: any*/),
              (v19/*: any*/),
              (v20/*: any*/),
              (v21/*: any*/),
              (v22/*: any*/),
              (v23/*: any*/),
              (v24/*: any*/),
              (v25/*: any*/),
              (v26/*: any*/),
              (v27/*: any*/),
              (v28/*: any*/),
              (v29/*: any*/),
              (v30/*: any*/),
              (v31/*: any*/),
              (v32/*: any*/),
              (v33/*: any*/),
              (v34/*: any*/),
              (v35/*: any*/),
              (v36/*: any*/),
              (v37/*: any*/),
              (v38/*: any*/),
              (v39/*: any*/),
              (v40/*: any*/),
              (v41/*: any*/),
              (v42/*: any*/),
              (v43/*: any*/),
              (v44/*: any*/),
              (v45/*: any*/),
              (v46/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Contact",
                "kind": "LinkedField",
                "name": "warehouse",
                "plural": false,
                "selections": [
                  (v41/*: any*/),
                  (v47/*: any*/),
                  (v23/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Contact",
                "kind": "LinkedField",
                "name": "transporter",
                "plural": false,
                "selections": (v50/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Contact",
                "kind": "LinkedField",
                "name": "payer",
                "plural": false,
                "selections": (v50/*: any*/),
                "storageKey": null
              },
              (v49/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7dcda589c9d252cfc0fe30c2a8939e52",
    "id": null,
    "metadata": {},
    "name": "ShipmentsTableMutation",
    "operationKind": "mutation",
    "text": "mutation ShipmentsTableMutation(\n  $input: infoV2UpdateShipmentInput!\n) {\n  infoV2UpdateShipment(input: $input) {\n    shipment {\n      automaticHandover\n      costCleaning\n      costClimaCertificate\n      costContainerHandling\n      costContainerXray\n      costCustomsInspection\n      costDischarge\n      costForklift\n      costOpinion\n      costOther\n      costShipping\n      costSpecialHandling\n      costStorage\n      costTransport\n      costWarehousing\n      description\n      dischargeDate\n      eta\n      expectedHandoverDate\n      finalReleaseCheck\n      handoverDate\n      id\n      isArchived\n      paymentDate\n      revenueCleaning\n      revenueClimaCertificate\n      revenueContainerHandling\n      revenueContainerXray\n      revenueCustomsClearance\n      revenueCustomsInspection\n      revenueDischarge\n      revenueForklift\n      revenueOpinion\n      revenueShipping\n      revenueSpecialHandling\n      revenueStorage\n      revenueTransport\n      revenueWarehousing\n      shipperReleaseCheck\n      slug\n      stage\n      totalRevenue\n      type\n      vatCalculator\n      vin\n      warehouse {\n        slug\n        name\n        id\n      }\n      transporter {\n        name\n        slug\n        id\n      }\n      payer {\n        name\n        slug\n        id\n      }\n      linkedContacts {\n        contactSlug\n        role\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '9c793aceb4e8a0e1f42620e9b9f51a08';
export default node;
