/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ExternalListItemTypeEnum = "containers" | "external_contacts" | "item" | "shipments" | "tickets" | "voyages" | "%future added value";
export type FilterInput = {
    key: string;
    values: Array<string>;
};
export type ExternalItemListFooterQueryVariables = {
    type?: ExternalListItemTypeEnum | null;
    filters?: Array<FilterInput> | null;
    sortKey?: string | null;
    page?: number | null;
    perPage?: number | null;
    searchQuery?: string | null;
    voyageEtaStart?: string | null;
    voyageEtaEnd?: string | null;
    dischargeDateStart?: string | null;
    dischargeDateEnd?: string | null;
};
export type ExternalItemListFooterQueryResponse = {
    readonly externalItemList: {
        readonly " $fragmentRefs": FragmentRefs<"ExternalItemListFooter_data">;
    };
};
export type ExternalItemListFooterQuery = {
    readonly response: ExternalItemListFooterQueryResponse;
    readonly variables: ExternalItemListFooterQueryVariables;
};



/*
query ExternalItemListFooterQuery(
  $type: ExternalListItemTypeEnum
  $filters: [FilterInput!]
  $sortKey: String
  $page: Int
  $perPage: Int
  $searchQuery: String
  $voyageEtaStart: Date
  $voyageEtaEnd: Date
  $dischargeDateStart: Date
  $dischargeDateEnd: Date
) {
  externalItemList(type: $type, filters: $filters, sortKey: $sortKey, page: $page, perPage: $perPage, searchQuery: $searchQuery, voyageEtaStart: $voyageEtaStart, voyageEtaEnd: $voyageEtaEnd, dischargeDateStart: $dischargeDateStart, dischargeDateEnd: $dischargeDateEnd) {
    ...ExternalItemListFooter_data
  }
}

fragment ExternalItemListFooter_data on ExternalItemList {
  paginationMeta {
    hasNextPage
    hasPrevPage
    page
    perPage
    totalPages
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "dischargeDateEnd"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "dischargeDateStart"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "page"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "perPage"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchQuery"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sortKey"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "type"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "voyageEtaEnd"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "voyageEtaStart"
},
v10 = [
  {
    "kind": "Variable",
    "name": "dischargeDateEnd",
    "variableName": "dischargeDateEnd"
  },
  {
    "kind": "Variable",
    "name": "dischargeDateStart",
    "variableName": "dischargeDateStart"
  },
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page"
  },
  {
    "kind": "Variable",
    "name": "perPage",
    "variableName": "perPage"
  },
  {
    "kind": "Variable",
    "name": "searchQuery",
    "variableName": "searchQuery"
  },
  {
    "kind": "Variable",
    "name": "sortKey",
    "variableName": "sortKey"
  },
  {
    "kind": "Variable",
    "name": "type",
    "variableName": "type"
  },
  {
    "kind": "Variable",
    "name": "voyageEtaEnd",
    "variableName": "voyageEtaEnd"
  },
  {
    "kind": "Variable",
    "name": "voyageEtaStart",
    "variableName": "voyageEtaStart"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ExternalItemListFooterQuery",
    "selections": [
      {
        "alias": null,
        "args": (v10/*: any*/),
        "concreteType": "ExternalItemList",
        "kind": "LinkedField",
        "name": "externalItemList",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ExternalItemListFooter_data"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v7/*: any*/),
      (v2/*: any*/),
      (v6/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v9/*: any*/),
      (v8/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ExternalItemListFooterQuery",
    "selections": [
      {
        "alias": null,
        "args": (v10/*: any*/),
        "concreteType": "ExternalItemList",
        "kind": "LinkedField",
        "name": "externalItemList",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Paginate",
            "kind": "LinkedField",
            "name": "paginationMeta",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasPrevPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "page",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "perPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalPages",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b58e76ef980fd2fea6c3f756ef8c74f7",
    "id": null,
    "metadata": {},
    "name": "ExternalItemListFooterQuery",
    "operationKind": "query",
    "text": "query ExternalItemListFooterQuery(\n  $type: ExternalListItemTypeEnum\n  $filters: [FilterInput!]\n  $sortKey: String\n  $page: Int\n  $perPage: Int\n  $searchQuery: String\n  $voyageEtaStart: Date\n  $voyageEtaEnd: Date\n  $dischargeDateStart: Date\n  $dischargeDateEnd: Date\n) {\n  externalItemList(type: $type, filters: $filters, sortKey: $sortKey, page: $page, perPage: $perPage, searchQuery: $searchQuery, voyageEtaStart: $voyageEtaStart, voyageEtaEnd: $voyageEtaEnd, dischargeDateStart: $dischargeDateStart, dischargeDateEnd: $dischargeDateEnd) {\n    ...ExternalItemListFooter_data\n  }\n}\n\nfragment ExternalItemListFooter_data on ExternalItemList {\n  paginationMeta {\n    hasNextPage\n    hasPrevPage\n    page\n    perPage\n    totalPages\n  }\n}\n"
  }
};
})();
(node as any).hash = '9011ed3a572cb3ca7db2b1d0be9c4a90';
export default node;
