import React, { useState, FormEvent } from 'react'
import CTAButton from './CTAButton'
import LoadingDots from './LoadingDots'
import { graphql } from 'babel-plugin-relay/macro'
import StyledInput from './StyledInput'
import useNiceMutation from '../mutations/useNiceMutation'
import { ForgetPasswordFormMutation } from '../__generated__/ForgetPasswordFormMutation.graphql'
import styled from 'styled-components'
import { Typography } from '@material-ui/core'

const InfoContainer = styled.div`
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 16px;
  background-color: #deedff;
`
const InfoSubtext = styled(Typography)`
  white-space: pre;
`

const ForgetPasswordForm: React.FC = () => {
  const [usernameEmail, setUsernameEmail] = useState('')
  const [status, setStatus] = useState<boolean>(false)
  const [commit_pass, loading] = useNiceMutation<ForgetPasswordFormMutation>(reset_token_muatation)

  const sendEmail = (e: FormEvent) => {
    e.preventDefault()
    commit_pass({
      variables: { input: { usernameEmail: usernameEmail } },
      onCompleted: (_, err) => {
        if (!err) {
          setStatus(true)
        }
      },
    })
  }

  return (
    <>
      {status ? (
        <InfoContainer>
          <InfoSubtext variant='body2'>Please check your Email</InfoSubtext>
        </InfoContainer>
      ) : (
        <form onSubmit={sendEmail}>
          <StyledInput
            fullWidth
            placeholder='Username or Email id'
            type='text'
            value={usernameEmail}
            onChange={(e) => setUsernameEmail(e.target.value)}
          />
          <CTAButton type='submit' disabled={!usernameEmail}>
            {loading ? <LoadingDots /> : 'Submit'}
          </CTAButton>
        </form>
      )}
    </>
  )
}

const reset_token_muatation = graphql`
  mutation ForgetPasswordFormMutation($input: infoSendResetPasswordTokenInput!) {
    infoSendResetPasswordToken(input: $input) {
      clientMutationId
    }
  }
`

export default ForgetPasswordForm
