/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SettingTypeEnum = "config_settings" | "customs_types" | "default_folders" | "document_templates" | "document_types" | "email_templates" | "languages" | "miscellaneous_settings" | "ports" | "rate_cards" | "roles" | "shipment_types" | "shipping_lines" | "%future added value";
export type SettingsCardQueryVariables = {
    type: SettingTypeEnum;
    page?: number | null;
    perPage?: number | null;
};
export type SettingsCardQueryResponse = {
    readonly settingList: {
        readonly items: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly slug: string;
                    readonly " $fragmentRefs": FragmentRefs<"SettingsItem_settingsItem">;
                } | null;
            } | null> | null;
        };
        readonly totalCount: number;
        readonly paginate: {
            readonly hasNextPage: boolean;
            readonly hasPrevPage: boolean;
            readonly page: number;
            readonly perPage: number;
            readonly totalPages: number;
        } | null;
    };
};
export type SettingsCardQuery = {
    readonly response: SettingsCardQueryResponse;
    readonly variables: SettingsCardQueryVariables;
};



/*
query SettingsCardQuery(
  $type: SettingTypeEnum!
  $page: Int
  $perPage: Int
) {
  settingList(type: $type, page: $page, perPage: $perPage) {
    items {
      edges {
        node {
          slug
          ...SettingsItem_settingsItem
          id
        }
      }
    }
    totalCount
    paginate {
      hasNextPage
      hasPrevPage
      page
      perPage
      totalPages
    }
  }
}

fragment Attachment_attachment on Attachment {
  url
  name
  contentType
  previewUrl
}

fragment BodyField_data on BodyField {
  label
  value {
    key
    ...SemanticString_data
  }
}

fragment KeyField_data on KeyField {
  label
  value {
    key
    ...SemanticString_data
  }
}

fragment SemanticLine_data on SemanticLine {
  parts {
    key
    ...SemanticString_data
  }
}

fragment SemanticString_data on SemanticString {
  text
}

fragment SettingItemImage_data on Image {
  url
  shape
  overlay
}

fragment SettingsItem_settingsItem on SettingsItem {
  slug
  title
  settingType
  image {
    ...SettingItemImage_data
  }
  lines {
    key
    ...SemanticLine_data
  }
  keyFields {
    key
    ...KeyField_data
  }
  showFields {
    key
    ...ShowField_data
  }
  bodyFields {
    key
    ...BodyField_data
  }
  attachments {
    slug
    ...Attachment_attachment
  }
}

fragment ShowField_data on ShowField {
  label
  value {
    key
    ...SemanticString_data
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "page"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "perPage"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "type"
},
v3 = [
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page"
  },
  {
    "kind": "Variable",
    "name": "perPage",
    "variableName": "perPage"
  },
  {
    "kind": "Variable",
    "name": "type",
    "variableName": "type"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Paginate",
  "kind": "LinkedField",
  "name": "paginate",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasPrevPage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "page",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "perPage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalPages",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "key",
  "storageKey": null
},
v9 = [
  (v8/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "text",
    "storageKey": null
  }
],
v10 = [
  (v8/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "label",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "SemanticString",
    "kind": "LinkedField",
    "name": "value",
    "plural": true,
    "selections": (v9/*: any*/),
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SettingsCardQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "SettingsList",
        "kind": "LinkedField",
        "name": "settingList",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SettingsItemConnection",
            "kind": "LinkedField",
            "name": "items",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SettingsItemEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SettingsItem",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "SettingsItem_settingsItem"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v5/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "SettingsCardQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "SettingsList",
        "kind": "LinkedField",
        "name": "settingList",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SettingsItemConnection",
            "kind": "LinkedField",
            "name": "items",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SettingsItemEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SettingsItem",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "settingType",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Image",
                        "kind": "LinkedField",
                        "name": "image",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "shape",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "overlay",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SemanticLine",
                        "kind": "LinkedField",
                        "name": "lines",
                        "plural": true,
                        "selections": [
                          (v8/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SemanticString",
                            "kind": "LinkedField",
                            "name": "parts",
                            "plural": true,
                            "selections": (v9/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "KeyField",
                        "kind": "LinkedField",
                        "name": "keyFields",
                        "plural": true,
                        "selections": (v10/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ShowField",
                        "kind": "LinkedField",
                        "name": "showFields",
                        "plural": true,
                        "selections": (v10/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "BodyField",
                        "kind": "LinkedField",
                        "name": "bodyFields",
                        "plural": true,
                        "selections": (v10/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Attachment",
                        "kind": "LinkedField",
                        "name": "attachments",
                        "plural": true,
                        "selections": [
                          (v4/*: any*/),
                          (v7/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "contentType",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "previewUrl",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v5/*: any*/),
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b7a3c0c13b36932bd657389a4158fc1f",
    "id": null,
    "metadata": {},
    "name": "SettingsCardQuery",
    "operationKind": "query",
    "text": "query SettingsCardQuery(\n  $type: SettingTypeEnum!\n  $page: Int\n  $perPage: Int\n) {\n  settingList(type: $type, page: $page, perPage: $perPage) {\n    items {\n      edges {\n        node {\n          slug\n          ...SettingsItem_settingsItem\n          id\n        }\n      }\n    }\n    totalCount\n    paginate {\n      hasNextPage\n      hasPrevPage\n      page\n      perPage\n      totalPages\n    }\n  }\n}\n\nfragment Attachment_attachment on Attachment {\n  url\n  name\n  contentType\n  previewUrl\n}\n\nfragment BodyField_data on BodyField {\n  label\n  value {\n    key\n    ...SemanticString_data\n  }\n}\n\nfragment KeyField_data on KeyField {\n  label\n  value {\n    key\n    ...SemanticString_data\n  }\n}\n\nfragment SemanticLine_data on SemanticLine {\n  parts {\n    key\n    ...SemanticString_data\n  }\n}\n\nfragment SemanticString_data on SemanticString {\n  text\n}\n\nfragment SettingItemImage_data on Image {\n  url\n  shape\n  overlay\n}\n\nfragment SettingsItem_settingsItem on SettingsItem {\n  slug\n  title\n  settingType\n  image {\n    ...SettingItemImage_data\n  }\n  lines {\n    key\n    ...SemanticLine_data\n  }\n  keyFields {\n    key\n    ...KeyField_data\n  }\n  showFields {\n    key\n    ...ShowField_data\n  }\n  bodyFields {\n    key\n    ...BodyField_data\n  }\n  attachments {\n    slug\n    ...Attachment_attachment\n  }\n}\n\nfragment ShowField_data on ShowField {\n  label\n  value {\n    key\n    ...SemanticString_data\n  }\n}\n"
  }
};
})();
(node as any).hash = 'dd0aa6d2fe067918d2d2ab7797c0406d';
export default node;
