import React from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import { ContainerEmailsQuery } from '../../__generated__/ContainerEmailsQuery.graphql'
import EmailsTab from '../../components/Email/EmailsTab'
import useLazyLoadQueryWithSubscription from '../../hooks/useLazyLoadQueryWithSubscription'

const ContainerEmails: React.FC<{ slug: string }> & { Skeleton: React.FC } = ({ slug }) => {
  const queryData = useLazyLoadQueryWithSubscription<ContainerEmailsQuery>(
    containerQuery,
    { slug },
    { subscriptionOptions: { entityType: 'containers', entitySlug: slug } }
  )

  return <EmailsTab emails={queryData.containerShow.emails} entityType='containers' entitySlug={slug} />
}

const containerQuery = graphql`
  query ContainerEmailsQuery($slug: String!) {
    containerShow(slug: $slug) {
      emails {
        ...EmailsTab_emails
      }
    }
  }
`

ContainerEmails.Skeleton = () => <EmailsTab.Skeleton />

export default ContainerEmails
