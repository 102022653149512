/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type infoUpdateContainerFinanceInput = {
    clientMutationId?: string | null;
    slug: string;
    estimatedCostStorage?: number | null;
    estimatedCostDemurrage?: number | null;
    costStorage?: number | null;
    costDemurrage?: number | null;
    costTransport?: number | null;
    costXray?: number | null;
    costThc?: number | null;
    costOther?: number | null;
    costCommission?: number | null;
    costCleaning?: number | null;
};
export type EditContainerFinanceDialogMutationVariables = {
    input: infoUpdateContainerFinanceInput;
};
export type EditContainerFinanceDialogMutationResponse = {
    readonly infoUpdateContainerFinance: {
        readonly container: {
            readonly slug: string;
        };
    } | null;
};
export type EditContainerFinanceDialogMutation = {
    readonly response: EditContainerFinanceDialogMutationResponse;
    readonly variables: EditContainerFinanceDialogMutationVariables;
};



/*
mutation EditContainerFinanceDialogMutation(
  $input: infoUpdateContainerFinanceInput!
) {
  infoUpdateContainerFinance(input: $input) {
    container {
      slug
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditContainerFinanceDialogMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "infoUpdateContainerFinancePayload",
        "kind": "LinkedField",
        "name": "infoUpdateContainerFinance",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Container",
            "kind": "LinkedField",
            "name": "container",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ApplicationCommand",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditContainerFinanceDialogMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "infoUpdateContainerFinancePayload",
        "kind": "LinkedField",
        "name": "infoUpdateContainerFinance",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Container",
            "kind": "LinkedField",
            "name": "container",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7c2a863a846c5df02d9b82fe12607e06",
    "id": null,
    "metadata": {},
    "name": "EditContainerFinanceDialogMutation",
    "operationKind": "mutation",
    "text": "mutation EditContainerFinanceDialogMutation(\n  $input: infoUpdateContainerFinanceInput!\n) {\n  infoUpdateContainerFinance(input: $input) {\n    container {\n      slug\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'c3b2ce2eba47af3c23a6a442cfdd5859';
export default node;
