import React, { useState } from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import { useFragment } from 'react-relay/hooks'
import { ContainerFinance_container$key } from '../../__generated__/ContainerFinance_container.graphql'
import { Card, CardContent, Typography } from '@material-ui/core'
import styled from 'styled-components'
import { media } from '../../theme'
import KeyValueField from '../../components/KeyValueField'
import EditContainerFinanceDialog from './EditContainerFinanceDialog'
import Button from '../../components/Button'
import { Edit } from '@material-ui/icons'

interface Props {
  container: ContainerFinance_container$key
}

const KeyFieldsContainer = styled.div`
  margin: 4px 0 16px 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, 190px);
  grid-gap: 8px;
  width: 100%;

  ${media.small`
    grid-template-columns: repeat(auto-fill, 110px);
  `}
`

const withEuro = (val: string | null) => (val === null || val === '' ? val : `${val} €`)

const ContainerFinance: React.FC<Props> & { Skeleton: React.FC } = ({ container: data }) => {
  const container = useFragment(fragment, data)
  const [editDialogOpen, setEditDialogOpen] = useState(false)

  return (
    <Card variant='outlined'>
      <EditContainerFinanceDialog
        containerSlug={container.slug}
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        onEdit={() => setEditDialogOpen(false)}
        title='Edit Finance'
      />
      <CardContent>
        <Typography variant='h6' style={{ display: 'initial' }}>
          Finances
        </Typography>
        <Button style={{ float: 'right' }} endIcon={<Edit />} onClick={() => setEditDialogOpen(true)}>
          Edit
        </Button>
        <KeyFieldsContainer>
          {container.finances?.map((finance) => (
            <KeyValueField key={finance.label} label={finance.label} value={withEuro(finance.value)} />
          ))}
        </KeyFieldsContainer>
      </CardContent>
    </Card>
  )
}

ContainerFinance.Skeleton = () => <>Loading...</>

const fragment = graphql`
  fragment ContainerFinance_container on Container {
    slug
    finances {
      label
      value
    }
  }
`

export default ContainerFinance
