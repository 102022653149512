import * as React from 'react'
import { ListItemText, MenuItem, MenuList, Divider, InputBase, Popover, PopoverProps, Typography, Box } from '@material-ui/core'
import { SearchRounded, Close } from '@material-ui/icons'
import { Icon } from './Renderers/RendererHoc'
import styled from 'styled-components'
import { IconButton } from './DataTable'

export type OptionType = { label: string; value: string; icon?: string; disabled?: boolean; meta?: string }
interface DropdownProps extends Omit<PopoverProps, 'onSelect'> {
  options: OptionType[]
  search: string
  onSearch: (value: string) => void
  value?: string
  onSelect: (value: OptionType) => void
  fullWidth?: boolean
}

const Search = styled('div')(({ theme }) => ({
  height: '32px',
  display: 'flex',
  alignItems: 'center',
  borderRadius: '4px',
  padding: '8px',
  position: 'relative',
  border: `1px solid ${theme.palette.action.disabled}`,
  backgroundColor: 'transparent',
  '&:focus-within': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  width: '100%',
}))

const SearchIconWrapper = styled('div')({
  padding: '0px 4px',
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const StyledInputBase = styled(InputBase)({
  color: 'inherit',
  '& .MuiInputBase-input': {
    fontSize: '14px',
    padding: '4px 4px 4px 24px',
    paddingLeft: `calc(1em + 24px)`,
    width: '100%',
  },
})

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  height: '32px',
  fontSize: '14px',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.action.selected,
  },
}))

const StyledListItemText = styled(ListItemText)({
  paddingLeft: 12,
})

const StyledListItemMeta = styled(ListItemText)({
  paddingLeft: 12,
  textAlign: 'right',
  color: 'rgba(0, 0, 0, 0.54)',
})

interface StyledPopoverProps extends PopoverProps {
  fullWidth?: boolean
}

const StyledPopover = styled(Popover)<StyledPopoverProps>(({ fullWidth }) => ({
  '& .MuiPaper-root': {
    minWidth: fullWidth ? '200px' : 'auto',
  },
  '& .MuiPopover-paper': {
    padding: '4px',
  },
}))

function Dropdown({ options, search, onSearch, value, onSelect, fullWidth = false, title, ...props }: DropdownProps) {
  const filteredOptions = options.filter((option) => option.label.toLowerCase().includes(search.toLowerCase())).splice(0, 10)
  return (
    <StyledPopover
      {...props}
      fullWidth={fullWidth}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      {title && (
        <>
          <Box display='flex' justifyContent='space-between' alignItems='center' px={1} mb={1}>
            <Typography variant='subtitle1'>{title}</Typography>
            <IconButton onClick={props.onClose as any} size='small'>
              <Close fontSize='small' />
            </IconButton>
          </Box>
          <Divider style={{ margin: '4px 0' }} />
        </>
      )}

      <Search>
        <SearchIconWrapper>
          <SearchRounded fontSize='small' />
        </SearchIconWrapper>
        <StyledInputBase
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          placeholder='Search…'
          inputProps={{ 'aria-label': 'search' }}
          value={search}
          onChange={(e) => onSearch(e.target.value)}
        />
      </Search>
      <Divider style={{ margin: '4px 0' }} />
      <MenuList dense disablePadding={true}>
        {filteredOptions.map((option: OptionType) => (
          <StyledMenuItem
            key={option.value}
            value={option.value}
            disabled={option.disabled}
            selected={value === option.value}
            onClick={() => onSelect(option)}
          >
            <Icon src={option.icon} />
            <StyledListItemText>{option.label}</StyledListItemText>
            {option.meta && <StyledListItemMeta>{option.meta}</StyledListItemMeta>}
          </StyledMenuItem>
        ))}
      </MenuList>
    </StyledPopover>
  )
}

export { StyledPopover, Search, SearchIconWrapper, StyledInputBase }

export default Dropdown
