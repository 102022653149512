/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LiveShareShipmentShowHomeContentQuery_shipmentShow = {
    readonly " $fragmentRefs": FragmentRefs<"LiveShareFoldersCard_shipmentShow">;
    readonly " $refType": "LiveShareShipmentShowHomeContentQuery_shipmentShow";
};
export type LiveShareShipmentShowHomeContentQuery_shipmentShow$data = LiveShareShipmentShowHomeContentQuery_shipmentShow;
export type LiveShareShipmentShowHomeContentQuery_shipmentShow$key = {
    readonly " $data"?: LiveShareShipmentShowHomeContentQuery_shipmentShow$data;
    readonly " $fragmentRefs": FragmentRefs<"LiveShareShipmentShowHomeContentQuery_shipmentShow">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LiveShareShipmentShowHomeContentQuery_shipmentShow",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LiveShareFoldersCard_shipmentShow"
    }
  ],
  "type": "LiveShareShowShipment",
  "abstractKey": null
};
(node as any).hash = '22666bcd1b6464f4c74009c77afe0b53';
export default node;
