import { Typography, Divider } from '@material-ui/core'
import { graphql } from 'babel-plugin-relay/macro'
import React from 'react'
import { useFragment } from 'react-relay/hooks'
import styled from 'styled-components'
import { BodyField_data$key } from '../__generated__/BodyField_data.graphql'
import SemanticString from './SemanticString'

interface Props {
  data: BodyField_data$key
}

const Container = styled.div`
  max-width: 95%;
  margin: 8px 0;
`

const Label = styled(Typography)`
  font-size: 12px;
  color: #333;
`

const Value = styled(Typography)`
  color: #333;
  font-weight: 500;
  box-sizing: content-box;
  white-space: nowrap;
  overflow: hidden;
  line-height: 24px;
  white-space: pre-wrap;
`

const BodyField: React.FC<Props> = ({ data }) => {
  const field = useFragment(bodyFieldFragment, data)

  return (
    <>
      <Divider />
      <Container>
        <Label variant='caption' color='textSecondary'>
          {field.label}
        </Label>
        <Value variant='body1'>
          {field.value === null || field.value.length === 0
            ? '--'
            : field.value.map((value) => <SemanticString key={value.key} data={value} />)}
        </Value>
      </Container>
    </>
  )
}

const bodyFieldFragment = graphql`
  fragment BodyField_data on BodyField {
    label
    value {
      key
      ...SemanticString_data
    }
  }
`

export default BodyField
