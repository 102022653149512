/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type FormFieldTypeEnum = "array" | "boolean" | "boolean_switch" | "container" | "container_image_slug" | "date" | "datetime" | "doc_slug" | "file_slug" | "image_slug" | "linked_contacts" | "multi_select" | "number" | "parent_shipment" | "port" | "rate_card" | "rich_text" | "searchable" | "shipment_image_slug" | "shipping_line" | "string" | "vessel" | "voyage" | "%future added value";
export type LiveShareEntityTypeEnum = "shipments" | "%future added value";
export type LiveShareAddDialogQueryVariables = {
    entitySlug: string;
    entityType: LiveShareEntityTypeEnum;
};
export type LiveShareAddDialogQueryResponse = {
    readonly infoCreateLiveShareForm: {
        readonly formSchema: {
            readonly fieldSet: ReadonlyArray<{
                readonly name: string;
                readonly type: FormFieldTypeEnum;
                readonly required: boolean;
                readonly title: string | null;
                readonly description: string | null;
                readonly format: string | null;
                readonly enum: ReadonlyArray<string | null> | null;
                readonly enumTitles: ReadonlyArray<string | null> | null;
            }>;
        };
    };
};
export type LiveShareAddDialogQuery = {
    readonly response: LiveShareAddDialogQueryResponse;
    readonly variables: LiveShareAddDialogQueryVariables;
};



/*
query LiveShareAddDialogQuery(
  $entitySlug: String!
  $entityType: LiveShareEntityTypeEnum!
) {
  infoCreateLiveShareForm(entitySlug: $entitySlug, entityType: $entityType) {
    formSchema {
      fieldSet {
        name
        type
        required
        title
        description
        format
        enum
        enumTitles
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "entitySlug"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "entityType"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "entitySlug",
        "variableName": "entitySlug"
      },
      {
        "kind": "Variable",
        "name": "entityType",
        "variableName": "entityType"
      }
    ],
    "concreteType": "InfoCreateLiveShareFormResponse",
    "kind": "LinkedField",
    "name": "infoCreateLiveShareForm",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "FormSchema",
        "kind": "LinkedField",
        "name": "formSchema",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FormField",
            "kind": "LinkedField",
            "name": "fieldSet",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "required",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "format",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "enum",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "enumTitles",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LiveShareAddDialogQuery",
    "selections": (v1/*: any*/),
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LiveShareAddDialogQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8236310e4f83ff9a34c4acb13fb9e16b",
    "id": null,
    "metadata": {},
    "name": "LiveShareAddDialogQuery",
    "operationKind": "query",
    "text": "query LiveShareAddDialogQuery(\n  $entitySlug: String!\n  $entityType: LiveShareEntityTypeEnum!\n) {\n  infoCreateLiveShareForm(entitySlug: $entitySlug, entityType: $entityType) {\n    formSchema {\n      fieldSet {\n        name\n        type\n        required\n        title\n        description\n        format\n        enum\n        enumTitles\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'fc7e69bac7bf570f6d87c68798ccacd5';
export default node;
