import { Typography, Paper, Avatar } from '@material-ui/core'
import { graphql } from 'babel-plugin-relay/macro'
import React from 'react'
import { useFragment } from 'react-relay/hooks'
import styled from 'styled-components'
import { EntityAttachmentListItem_attachment$key } from '../../__generated__/EntityAttachmentListItem_attachment.graphql'
import { previewForAttachment } from './helpers'
import { AvatarGroup } from '@material-ui/lab'

interface Props {
  attachment: EntityAttachmentListItem_attachment$key
  style?: React.CSSProperties
  onPreview?: () => void
}

const RootPaper = styled(Paper).attrs({ variant: 'outlined' })`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  padding: 8px;
  border: 0;

  &:hover {
    filter: brightness(0.95);
  }
`

const HorizontalFlex = styled.div`
  display: flex;
  align-items: center;
`

const AttachmentAvatar = styled(Avatar)<{ $isIconPreview: boolean }>`
  margin-right: 16px;

  & > img {
    width: 70%;
    height: 70%;
  }
`

const StyledAvatarGroup = styled(AvatarGroup)`
  margin-right: 8px;

  & > .MuiAvatarGroup-avatar {
    height: 35px;
    width: 35px;
    font-size: 1rem;
  }
`

const EntityAttachmentListItem: React.FC<Props> = ({ attachment: data, style, onPreview }) => {
  const attachment = useFragment(fragment, data)
  const { previewUrl, isIconPreview } = previewForAttachment(attachment)
  const filteredRoleVisbilities = attachment.roleVisibilities.filter((roleVisibility) => roleVisibility.visible)

  return (
    <>
      <RootPaper
        style={style}
        onClick={(e) => {
          if (e.ctrlKey) {
            window.open(attachment.url)
          } else {
            onPreview && onPreview()
          }
        }}
      >
        <HorizontalFlex>
          <AttachmentAvatar src={previewUrl} variant='square' $isIconPreview={isIconPreview} />
          <div>
            <Typography variant='body1'>{attachment.name}</Typography>
            {attachment.documentType && (
              <Typography variant='subtitle2' color='textSecondary'>
                {attachment.documentType}
              </Typography>
            )}
          </div>
        </HorizontalFlex>

        <HorizontalFlex>
          <StyledAvatarGroup max={4}>
            {filteredRoleVisbilities.map((roleVisibility) => (
              <Avatar key={roleVisibility.role} alt={roleVisibility.roleTitle} title={roleVisibility.roleTitle}>
                {roleVisibility.roleShortTitle}
              </Avatar>
            ))}
          </StyledAvatarGroup>
          <Typography variant='caption' color='textSecondary' title={attachment.uploadedAt || ''}>
            {attachment.uploadedAtDisplay}
          </Typography>
        </HorizontalFlex>
      </RootPaper>
    </>
  )
}

const fragment = graphql`
  fragment EntityAttachmentListItem_attachment on EntityAttachment {
    url
    downloadUrl
    name
    contentType
    previewUrl
    documentType
    roleVisibilities {
      role
      roleShortTitle
      roleTitle
      visible
    }
    uploadedAtDisplay
    uploadedAt
  }
`

export default EntityAttachmentListItem
