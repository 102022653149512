import { Typography } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'

interface Props {
  label: string
  value: string | number | null | undefined
}

const Container = styled.div`
  width: 100%;
  margin: 2px 0;
  overflow: hidden;
`

const Label = styled(Typography)`
  font-size: 12px;
  // color: #212121;
`

const Value = styled(Typography)`
  // color: #212121;
  font-weight: 500;
  box-sizing: content-box;
  white-space: nowrap;
  overflow: hidden;
  line-height: 24px;
  text-overflow: ellipsis;
`

const Field: React.FC<Props> = ({ label, value }) => {
  return (
    <Container>
      <Label variant='caption' color='textSecondary'>
        {label}
      </Label>
      <Value variant='body1'>{value || '--'}</Value>
    </Container>
  )
}

export default Field
