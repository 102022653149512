import React from 'react'
import { graphql } from 'babel-plugin-relay/macro'

import styled from 'styled-components'
import {
  ExternalContainerWorkflow_container$key,
  ExternalContainerWorkflow_container,
} from '../../__generated__/ExternalContainerWorkflow_container.graphql'
import { useFragment } from 'react-relay/hooks'
import ExternalStage from './ExternalStage'

interface Props {
  container: ExternalContainerWorkflow_container$key
}

const Container = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  overflow-x: auto;
`

const ExternalContainerWorkflow: React.FC<Props> = ({ container: data }) => {
  const container = useFragment(fragment, data)
  const stages = container.workflowStages

  return (
    <>
      <Container>
        {stages.map(
          (stage, idx) =>
            stage && <ContainerStage key={stage.value} stage={stage} index={idx + 1} isFirst={idx === 0} isLast={idx === stages.length - 1} />
        )}
      </Container>
    </>
  )
}

interface ContainerStageProps {
  stage: ExternalContainerWorkflow_container['workflowStages'][number]
  index: number
  isFirst: boolean
  isLast: boolean
}

const ContainerStage: React.FC<ContainerStageProps> = ({ stage, index, isFirst, isLast }) => {
  return <ExternalStage stage={stage} index={index} isFirst={isFirst} isLast={isLast} />
}

const fragment = graphql`
  fragment ExternalContainerWorkflow_container on ExternalContainer {
    slug
    workflowStages {
      value
      label
      ownedBy
      status
      ...ExternalStage_stage
    }
  }
`

export default ExternalContainerWorkflow
