import { Button, DialogActions, DialogTitle, InputBase } from '@material-ui/core'
import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router'
import styled from 'styled-components'
import useWorkspace from '../hooks/useWorkspace'
import SessionContext from '../SessionContext'
import OdysseyDialog from './OdysseyDialog'
import { isMobile, isLarge } from '../theme'
import { Search } from '@material-ui/icons'

const Root = styled.div`
  display: flex;
  align-items: center;
  max-height: 44px;
  border-radius: 10px;
  border: 1px solid ${(props) => props.theme.palette.divider};
`

const SearchInput = styled(InputBase)`
  & > input {
    text-indent: 8px;
  }
`

const CategorySelect = styled.select`
  max-width: 150px;
  box-sizing: content-box;
  padding: 12px 8px;
  background: ${(props) => props.theme.palette.background.paper};
  margin-right: 4px;
  line-height: 24px;
  color: ${(props) => props.theme.palette.text.primary};
  border: none;
  border-right: 1px solid ${(props) => props.theme.palette.divider};
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  outline: none;
`
const SearchIcon = styled(Button)`
  box-sizing: content-box;
  margin-left: 4px;
  border-left: 1px solid ${(props) => props.theme.palette.divider};
`
const Searchbar: React.FC = () => {
  const { user } = useContext(SessionContext)
  const placeholder = 'Search…'
  const [query, setQuery] = useState('')
  const [category, setCategory] = useState('all')
  const navigate = useNavigate()
  const [workspace] = useWorkspace()
  const searchCategories = [
    { name: 'All', value: 'all' },
    { name: 'Containers', value: 'containers' },
    { name: 'Shipments', value: 'shipments' },
    { name: 'Vessels', value: 'vessels' },
    { name: 'Voyages', value: 'voyages' },
    { name: 'Contacts', value: 'contacts/external' },
  ]

  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const onSearch = () => {
    if (query === null || query === '') {
      handleClickOpen()
    } else {
      navigate(`${workspace}/${category === 'all' ? 'search' : category}?searchQuery=${query}`)
    }
  }
  return (
    <>
      <OdysseyDialog open={open} onClose={handleClose} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>{'Cannot search empty text.. '}</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Ok
          </Button>
        </DialogActions>
      </OdysseyDialog>

      <Root>
        {user?.contact?.contactType === 'internal' && !isMobile() && (
          <CategorySelect value={category} onChange={(e) => setCategory(e.target.value)}>
            {searchCategories.map((opt) => (
              <option key={opt.value} value={opt.value}>
                {opt.name}
              </option>
            ))}
          </CategorySelect>
        )}
        <form
          onSubmit={(e) => {
            e.preventDefault()
            onSearch()
          }}
        >
          <SearchInput fullWidth placeholder={placeholder} value={query} onChange={(e) => setQuery(e.target.value)} />
        </form>
        {isLarge() && (
          <SearchIcon onClick={onSearch}>
            <Search />
          </SearchIcon>
        )}
      </Root>
    </>
  )
}

export default Searchbar
