/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type infoUpdateContainerInput = {
    clientMutationId?: string | null;
    slug: string;
    profileImageSlug?: string | null;
    containerNumber: string;
    voyageSlug: string;
    containerType: string;
    containerSize?: string | null;
    procedure?: string | null;
    atb?: string | null;
    bookingNumber?: string | null;
    turninLocationSlug?: string | null;
    pickupLocationSlug?: string | null;
    pickupReference?: string | null;
    turninReference?: string | null;
    pickupComment?: string | null;
    billOfLadingNumber?: string | null;
    terminalDate?: string | null;
    dischargeDate?: string | null;
    latestPickupDate?: string | null;
    pickupDate?: string | null;
    latestTurninDate?: string | null;
    thcPaidDate?: string | null;
    linkedContacts: Array<LinkedContactInput>;
    shippingLineSlug: string;
};
export type LinkedContactInput = {
    role: string;
    contactSlug: string;
};
export type ContainerReleaseRequestMutationVariables = {
    input: infoUpdateContainerInput;
};
export type ContainerReleaseRequestMutationResponse = {
    readonly infoUpdateContainer: {
        readonly clientMutationId: string | null;
    } | null;
};
export type ContainerReleaseRequestMutation = {
    readonly response: ContainerReleaseRequestMutationResponse;
    readonly variables: ContainerReleaseRequestMutationVariables;
};



/*
mutation ContainerReleaseRequestMutation(
  $input: infoUpdateContainerInput!
) {
  infoUpdateContainer(input: $input) {
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "infoUpdateContainerPayload",
    "kind": "LinkedField",
    "name": "infoUpdateContainer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ContainerReleaseRequestMutation",
    "selections": (v1/*: any*/),
    "type": "ApplicationCommand",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ContainerReleaseRequestMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b684e751eb72d861a23df78e76e47e7c",
    "id": null,
    "metadata": {},
    "name": "ContainerReleaseRequestMutation",
    "operationKind": "mutation",
    "text": "mutation ContainerReleaseRequestMutation(\n  $input: infoUpdateContainerInput!\n) {\n  infoUpdateContainer(input: $input) {\n    clientMutationId\n  }\n}\n"
  }
};
})();
(node as any).hash = '493b7c304a6e12b3fa8a2a46bfb3fad9';
export default node;
