/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Folder_folder = {
    readonly slug: string;
    readonly name: string;
    readonly parentType: string;
    readonly entitySlug: string;
    readonly attachments: ReadonlyArray<{
        readonly attachmentSlug: string;
        readonly name: string;
        readonly " $fragmentRefs": FragmentRefs<"EntityAttachmentListPreviewer_attachment" | "EntityAttachment_attachment" | "EntityAttachmentListItem_attachment">;
    }>;
    readonly " $fragmentRefs": FragmentRefs<"FileCreationDialog_folder">;
    readonly " $refType": "Folder_folder";
};
export type Folder_folder$data = Folder_folder;
export type Folder_folder$key = {
    readonly " $data"?: Folder_folder$data;
    readonly " $fragmentRefs": FragmentRefs<"Folder_folder">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Folder_folder",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "parentType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entitySlug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EntityAttachment",
      "kind": "LinkedField",
      "name": "attachments",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "attachmentSlug",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EntityAttachmentListPreviewer_attachment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EntityAttachment_attachment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EntityAttachmentListItem_attachment"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FileCreationDialog_folder"
    }
  ],
  "type": "Folder",
  "abstractKey": null
};
})();
(node as any).hash = 'e3908d2db771eb5272a0cf7ce24c20fe';
export default node;
