import { Card, Typography, Select, MenuItem, LinearProgress } from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import { graphql } from 'babel-plugin-relay/macro'
import React, { SetStateAction, Suspense, useEffect, useState } from 'react'
import { useLazyLoadQuery } from 'react-relay/hooks'
import styled from 'styled-components'
import ExchangeRatesCard from '../../components/Finances/ExchangeRatesCard'
import Timeline from '../../components/Timeline/Timeline'
import { media } from '../../theme'
import { DateFilterEnum } from '../../__generated__/DashboardHeaderQuery.graphql'
import { ContainerEtaBar, ContainerLocationPie, ContainerStagePie } from './ContainerCharts'
import { OverdueMetrics } from './MiscCharts'
import { ShipmentEtaBar, ShipmentRevenueBar } from './ShipmentCharts'
import DataTable, { IDataTableColumn, IDataTableProps } from 'react-data-table-component'
import { useLocation } from 'react-router'
import { DashboardMainContentShipmentsQuery } from '../../__generated__/DashboardMainContentShipmentsQuery.graphql'
import { DashboardMainContentContainersQuery } from '../../__generated__/DashboardMainContentContainersQuery.graphql'
import { ErrorBoundaryWithBox } from '../../utils/ErrorBoundary'

interface Props {
  handle: string
  my: string
  includeArchived: string
  dateFilter: DateFilterEnum
  setDateFilter: React.Dispatch<SetStateAction<DateFilterEnum>>
}

interface DataType {
  slug: string
  amount: string
  payment_recieved: string
  billing_date: string
  status: string
  has_invoice: string
}

const MainGrid = styled.div`
  width: 1660px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(13, 1fr);
  grid-gap: 16px;

  ${media.medium`
    width: 1140px;
  `}

  ${media.small`
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
  `}
`

const MainContent = styled.div`
  grid-column: 1 / span 8;

  ${media.small`
    grid-column: 1 / span 1;
  `}
`

const TimelineContent = styled.div`
  grid-column: 9 / span 5;

  ${media.small`
    grid-column: 1 / span 1;
  `}
`

const FullColumn = styled.div`
  grid-column: 1 / span 2;

  ${media.small`
    grid-column: 1 / span 1;
  `}
`

const FirstColumn = styled.div`
  grid-column: 1 / span 1;

  ${media.small`
    grid-column: 1 / span 1;
  `}
`

const SecondColumn = styled.div`
  grid-column: 2 / span 1;

  ${media.small`
    grid-column: 1 / span 1;
  `}
`

const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;

  ${media.small`
    grid-template-columns: repeat(1, 1fr);
  `}
`

const ContentCard = styled(Card)`
  padding: 16px;
  margin-bottom: 8px;
`

const dateFilterOptions: { label: string; value: DateFilterEnum }[] = [
  { label: 'Today', value: 'today' },
  { label: 'Last 7 days', value: 'last_seven_days' },
  { label: 'Last month', value: 'last_month' },
  { label: 'Last 3 month', value: 'last_three_month' },
  { label: 'Last year', value: 'last_year' },
  { label: 'All time', value: 'all_time' },
]

const customSort: IDataTableProps<DataType>['sortFunction'] = (rows, field, direction) => {
  return rows.slice().sort((rowA, rowB) => {
    let aField: string | Date = rowA[field as keyof DataType]
    let bField: string | Date = rowB[field as keyof DataType]

    if (field === 'billing_at') {
      aField = new Date(aField)
      bField = new Date(bField)
    }

    let comparison = 0

    if (aField > bField) {
      comparison = 1
    } else if (aField < bField) {
      comparison = -1
    }

    return direction === 'desc' ? comparison * -1 : comparison
  })
}

const Heading: React.FC<{ title?: string }> = ({ title }) => (
  <Typography key='section-1' variant='h5'>
    {title ? title : <>&nbsp;</>}
  </Typography>
)

const ContainerQueryWrapper = ({ variables, onLoad }: any) => {
  const { dashboardContainersQuery } = useLazyLoadQuery<DashboardMainContentContainersQuery>(containersQuery, variables)

  useEffect(() => {
    onLoad(dashboardContainersQuery)
  }, [dashboardContainersQuery, onLoad])

  return null
}

const ShipmentQueryWrapper = ({ variables, onLoad }: any) => {
  const { dashboardShipmentsQuery } = useLazyLoadQuery<DashboardMainContentShipmentsQuery>(shipmentsQuery, variables)

  useEffect(() => {
    onLoad(dashboardShipmentsQuery)
  }, [dashboardShipmentsQuery, onLoad])

  return null
}

const DashboardMainContent: React.FC<Props> = ({ handle, my, includeArchived, dateFilter, setDateFilter }) => {
  const workSpace = useLocation().pathname.split('/')[1]
  const variables = {
    handle: handle,
    includeArchived: includeArchived,
    dateFilter,
  }

  const [dashboardContainers, setDashboardContainers] =
    useState<DashboardMainContentContainersQuery['response']['dashboardContainersQuery']>(null)
  const [dashboardShipments, setDashboardShipments] = useState<DashboardMainContentShipmentsQuery['response']['dashboardShipmentsQuery']>(null)

  return (
    <MainGrid>
      <Suspense
        fallback={
          <div style={{ gridColumn: '1/14', gridRow: 1 }}>
            <LinearProgress />
          </div>
        }
      >
        <ContainerQueryWrapper onLoad={setDashboardContainers} variables={variables} />
      </Suspense>
      <Suspense
        fallback={
          <div style={{ gridColumn: '1/14', gridRow: 1 }}>
            <LinearProgress />
          </div>
        }
      >
        <ShipmentQueryWrapper onLoad={setDashboardShipments} variables={variables} />
      </Suspense>
      <MainContent>
        <Select value={dateFilter} onChange={(e: any) => setDateFilter(e.target.value)}>
          {dateFilterOptions.map((filter) => (
            <MenuItem key={filter.value} value={filter.value}>
              {filter.label}
            </MenuItem>
          ))}
        </Select>
      </MainContent>
      <MainContent>
        <ContentGrid>
          <FullColumn>
            <Heading title={`${my} Active Containers`} />
          </FullColumn>
          <FirstColumn>
            <ContentCard variant='outlined'>
              <Typography variant='h6'>By stage</Typography>
              {dashboardContainers && <ContainerStagePie data={dashboardContainers.chartContainersByStage} />}
            </ContentCard>
          </FirstColumn>
          <SecondColumn>
            <ContentCard style={{ gridColumn: '2 / span 1' }} variant='outlined'>
              <Typography variant='h6'>By location</Typography>
              {dashboardContainers && <ContainerLocationPie data={dashboardContainers.chartContainersByLocation} />}
            </ContentCard>
          </SecondColumn>
          <FullColumn>
            <Heading title={`${my} Active Shipments`} />
          </FullColumn>
          <FirstColumn>
            <ContentCard variant='outlined'>
              <Typography variant='h6'>By stage</Typography>
              {dashboardShipments && <ContainerStagePie data={dashboardShipments.chartShipmentsByStage} />}
            </ContentCard>
          </FirstColumn>
          <SecondColumn>
            <ContentCard variant='outlined'>
              <Typography variant='h6'>Overdues</Typography>
              {dashboardShipments && <OverdueMetrics data={dashboardShipments.chartProcessOverdue} />}
            </ContentCard>
          </SecondColumn>
          <FullColumn>
            <Heading title='Payment overdues' />
          </FullColumn>
          <FullColumn>
            {dashboardShipments && (
              <DataTable
                data-tag='allowRowEvents'
                data={dashboardShipments.shipmentBillingOverdue.values as DataType[]}
                columns={dashboardShipments.shipmentBillingOverdue.labels.map((label: IDataTableColumn<DataType>) => {
                  if (label.id === 'status')
                    return {
                      ...label,
                      selector: 'status',
                      conditionalCellStyles: [
                        {
                          when: (row: DataType) => row.status === 'Paid',
                          style: { width: '100%', textAlign: 'center', backgroundColor: '#a9ffd7', color: '#2a8e5f', fontWeight: 500 },
                        },
                        {
                          when: (row: DataType) => row.status === 'UnPaid',
                          style: { width: '100%', textAlign: 'center', backgroundColor: red[100], color: red[500], fontWeight: 500 },
                        },
                      ],
                    }

                  return { ...label, selector: label.id }
                })}
                onRowClicked={(row: DataType) => window.open(`/${workSpace}/shipment/${row.slug}/home`)}
                sortFunction={customSort}
                pagination
                highlightOnHover
              />
            )}
          </FullColumn>
          <FullColumn>
            <Heading title={`${my} Upcoming Containers & Shipments`} />
          </FullColumn>
          <FullColumn>
            <ContentCard variant='outlined'>
              <Typography variant='h6'>Container ETA&apos;s</Typography>
              {dashboardContainers && <ContainerEtaBar data={dashboardContainers.tsContainersEta} />}
            </ContentCard>
          </FullColumn>
          <FullColumn>
            <ContentCard variant='outlined'>
              <Typography variant='h6'>Shipment ETA&apos;s</Typography>
              {dashboardShipments && <ShipmentEtaBar data={dashboardShipments.tsShipmentEta} />}
            </ContentCard>
          </FullColumn>
          <FullColumn>
            <Heading title={`${my} Finished and Archived Containers & Shipments`} />
          </FullColumn>
          <FullColumn>
            <ContentCard variant='outlined'>
              <Typography variant='h6'>Revenue and Cost by handover</Typography>
              {dashboardShipments && <ShipmentRevenueBar data={dashboardShipments.tsCostsAndRevenues} />}
            </ContentCard>
          </FullColumn>
          <FirstColumn>
            <ContentCard variant='outlined'>
              <Typography variant='h6'>Unpaid Shipments</Typography>
              {dashboardShipments && <ContainerStagePie data={dashboardShipments.chartShipmentUnpaidConsignee} />}
            </ContentCard>
          </FirstColumn>
        </ContentGrid>
      </MainContent>
      <TimelineContent>
        <div style={{ marginTop: '47px' }}>
          <ExchangeRatesCard />
        </div>
        <Typography variant='h5' style={{ marginBottom: '16px' }}>
          {my} containers & shipments
        </Typography>
        <ErrorBoundaryWithBox>
          <Suspense fallback={<LinearProgress />}>
            <Timeline handle={handle} type='all' dateFilter={dateFilter} external />
          </Suspense>
        </ErrorBoundaryWithBox>
      </TimelineContent>
    </MainGrid>
  )
}

export default DashboardMainContent

const shipmentsQuery = graphql`
  query DashboardMainContentShipmentsQuery($handle: String!, $includeArchived: String, $dateFilter: DateFilterEnum!) {
    dashboardShipmentsQuery(handle: $handle, includeArchived: $includeArchived, dateFilter: $dateFilter) {
      chartShipmentsByStage {
        labels
        values
        paths
      }

      chartProcessOverdue {
        labels
        values
        paths
      }

      shipmentBillingOverdue {
        labels
        values
      }

      tsShipmentEta {
        labels
        values
        paths
        startIndex
      }

      tsCostsAndRevenues {
        labels
        values {
          revenue
          containerCost
          shipmentCost
        }
        paths
        startIndex
      }
      chartShipmentUnpaidConsignee {
        labels
        values
        paths
      }
    }
  }
`

const containersQuery = graphql`
  query DashboardMainContentContainersQuery($handle: String!, $includeArchived: String, $dateFilter: DateFilterEnum!) {
    dashboardContainersQuery(handle: $handle, includeArchived: $includeArchived, dateFilter: $dateFilter) {
      chartContainersByLocation {
        labels
        values
        paths
      }
      chartContainersByStage {
        labels
        values
        paths
      }

      tsContainersEta {
        labels
        values
        paths
        startIndex
      }
    }
  }
`
