import React, { useEffect } from 'react'
import { DetailsFieldsContainer } from '../forms/Container/ContainerForm'
import InputField from './Form/InputField'
import styled from 'styled-components'
import { media } from '../theme'
import { FormFieldsContainer } from './ShipmentInfoCard'
import { Skeleton } from '@material-ui/lab'
import { FieldT } from './Form/types'
import { useFormikContext } from 'formik'

const FieldsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 16px;

  ${media.medium`
    grid-template-columns: repeat(1, 1fr);
  `}

  ${media.small`
    grid-template-columns: auto;
  `}
`

interface Props {
  fieldMaster: {
    [key: string]: FieldT
  }
  mode: 'add' | 'edit'
}

const DraftContactForm: React.FC<Props> & { Skeleton: React.FC } = ({ fieldMaster, mode }) => {
  const { values, setFieldValue } = useFormikContext<any>()
  useEffect(() => {
    if (values.email === '') {
      setFieldValue('email', null)
    }
  }, [values, setFieldValue])

  return (
    <>
      <FieldsContainer>
        {mode == 'add' && (
          <FieldsContainer>
            <InputField of='ticketType' field={fieldMaster['ticketType']} />
          </FieldsContainer>
        )}

        <DetailsFieldsContainer>
          <InputField of='salutation' field={fieldMaster['salutation']} />
          <InputField of='format' field={fieldMaster['format']} />
          <InputField of='firstName' field={fieldMaster['firstName']} />
          <InputField of='lastName' field={fieldMaster['lastName']} />
        </DetailsFieldsContainer>
        <FieldsContainer>
          <InputField of='address' field={fieldMaster['address']} />
        </FieldsContainer>
        <DetailsFieldsContainer>
          <InputField of='postcode' field={fieldMaster['postcode']} />
          <InputField of='place' field={fieldMaster['place']} />
          <InputField of='company' field={fieldMaster['company']} />
          <InputField of='state' field={fieldMaster['state']} />
          <InputField of='country' field={fieldMaster['country']} />
          <InputField of='language' field={fieldMaster['language']} />
          <InputField of='email' field={fieldMaster['email']} />
          <InputField of='businessPhone' field={fieldMaster['businessPhone']} />
          <InputField of='eori' field={fieldMaster['eori']} />
          <InputField of='vat' field={fieldMaster['vat']} />
        </DetailsFieldsContainer>
        <FieldsContainer>
          <InputField of='roles' field={fieldMaster['roles']} />
        </FieldsContainer>
      </FieldsContainer>
    </>
  )
}

DraftContactForm.Skeleton = () => (
  <>
    <FormFieldsContainer>
      <Skeleton variant='rect' height='56px' />
      <Skeleton variant='rect' height='56px' />
      <Skeleton variant='rect' height='56px' />
      <Skeleton variant='rect' height='56px' />
    </FormFieldsContainer>
  </>
)

export default DraftContactForm
