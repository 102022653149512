/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type infoAddDraftContactLinkInput = {
    clientMutationId?: string | null;
    entityType: string;
    entitySlug: string;
    role: string;
    contactSlug: string;
};
export type ExternalContactListAddMutationVariables = {
    input: infoAddDraftContactLinkInput;
};
export type ExternalContactListAddMutationResponse = {
    readonly infoAddDraftContactLink: {
        readonly clientMutationId: string | null;
    } | null;
};
export type ExternalContactListAddMutation = {
    readonly response: ExternalContactListAddMutationResponse;
    readonly variables: ExternalContactListAddMutationVariables;
};



/*
mutation ExternalContactListAddMutation(
  $input: infoAddDraftContactLinkInput!
) {
  infoAddDraftContactLink(input: $input) {
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "infoAddDraftContactLinkPayload",
    "kind": "LinkedField",
    "name": "infoAddDraftContactLink",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ExternalContactListAddMutation",
    "selections": (v1/*: any*/),
    "type": "ApplicationCommand",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ExternalContactListAddMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "6e817059a2d9e19c77479e164ff80480",
    "id": null,
    "metadata": {},
    "name": "ExternalContactListAddMutation",
    "operationKind": "mutation",
    "text": "mutation ExternalContactListAddMutation(\n  $input: infoAddDraftContactLinkInput!\n) {\n  infoAddDraftContactLink(input: $input) {\n    clientMutationId\n  }\n}\n"
  }
};
})();
(node as any).hash = 'fe81276a77281c8529d4b8450fb5d5b5';
export default node;
