/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type infoUpdateDraftContactInput = {
    clientMutationId?: string | null;
    ticketSlug: string;
    format: string;
    salutation: string;
    firstName: string;
    lastName: string;
    email?: string | null;
    businessPhone?: string | null;
    company?: string | null;
    country: string;
    place?: string | null;
    postcode?: string | null;
    address?: string | null;
    state?: string | null;
    language?: string | null;
    roles: Array<string>;
};
export type DraftContactEditMutationVariables = {
    input: infoUpdateDraftContactInput;
};
export type DraftContactEditMutationResponse = {
    readonly infoUpdateDraftContact: {
        readonly clientMutationId: string | null;
    } | null;
};
export type DraftContactEditMutation = {
    readonly response: DraftContactEditMutationResponse;
    readonly variables: DraftContactEditMutationVariables;
};



/*
mutation DraftContactEditMutation(
  $input: infoUpdateDraftContactInput!
) {
  infoUpdateDraftContact(input: $input) {
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "infoUpdateDraftContactPayload",
    "kind": "LinkedField",
    "name": "infoUpdateDraftContact",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DraftContactEditMutation",
    "selections": (v1/*: any*/),
    "type": "ApplicationCommand",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DraftContactEditMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "0c96e72836e442d301e9ee62c5516a57",
    "id": null,
    "metadata": {},
    "name": "DraftContactEditMutation",
    "operationKind": "mutation",
    "text": "mutation DraftContactEditMutation(\n  $input: infoUpdateDraftContactInput!\n) {\n  infoUpdateDraftContact(input: $input) {\n    clientMutationId\n  }\n}\n"
  }
};
})();
(node as any).hash = 'cde3f72d08bf6efe7d66f1fbea7ab477';
export default node;
