/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type infoWatchItemInput = {
    clientMutationId?: string | null;
    entitySlug: string;
    entityType: string;
};
export type WatchersWatchMutationVariables = {
    input: infoWatchItemInput;
};
export type WatchersWatchMutationResponse = {
    readonly infoWatchItem: {
        readonly clientMutationId: string | null;
    } | null;
};
export type WatchersWatchMutation = {
    readonly response: WatchersWatchMutationResponse;
    readonly variables: WatchersWatchMutationVariables;
};



/*
mutation WatchersWatchMutation(
  $input: infoWatchItemInput!
) {
  infoWatchItem(input: $input) {
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "infoWatchItemPayload",
    "kind": "LinkedField",
    "name": "infoWatchItem",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WatchersWatchMutation",
    "selections": (v1/*: any*/),
    "type": "ApplicationCommand",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WatchersWatchMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b294ffee1599cfc6fd4513ffbca9603f",
    "id": null,
    "metadata": {},
    "name": "WatchersWatchMutation",
    "operationKind": "mutation",
    "text": "mutation WatchersWatchMutation(\n  $input: infoWatchItemInput!\n) {\n  infoWatchItem(input: $input) {\n    clientMutationId\n  }\n}\n"
  }
};
})();
(node as any).hash = 'f3d1ff0b8a99f5cbe1ec22ee82752031';
export default node;
