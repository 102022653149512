/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ExternalFolder_folder = {
    readonly slug: string;
    readonly entitySlug: string;
    readonly entityType: string;
    readonly name: string;
    readonly parentType: string;
    readonly attachments: ReadonlyArray<{
        readonly attachmentSlug: string;
        readonly name: string;
        readonly isImage: boolean;
        readonly " $fragmentRefs": FragmentRefs<"EntityAttachmentListPreviewer_attachment" | "EntityAttachment_attachment" | "EntityAttachmentListItem_attachment">;
    }>;
    readonly " $fragmentRefs": FragmentRefs<"FileCreationDialog_folder">;
    readonly " $refType": "ExternalFolder_folder";
};
export type ExternalFolder_folder$data = ExternalFolder_folder;
export type ExternalFolder_folder$key = {
    readonly " $data"?: ExternalFolder_folder$data;
    readonly " $fragmentRefs": FragmentRefs<"ExternalFolder_folder">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExternalFolder_folder",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entitySlug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "entityType",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "parentType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EntityAttachment",
      "kind": "LinkedField",
      "name": "attachments",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "attachmentSlug",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isImage",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EntityAttachmentListPreviewer_attachment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EntityAttachment_attachment"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EntityAttachmentListItem_attachment"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FileCreationDialog_folder"
    }
  ],
  "type": "Folder",
  "abstractKey": null
};
})();
(node as any).hash = '967ab37c63ba1767262d1f5651723bcf';
export default node;
