/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ShipmentFinanceActionCard_shipment = {
    readonly slug: string;
    readonly shipmentType: string;
    readonly lexofficeInvoices: ReadonlyArray<{
        readonly attachmentSlug: string;
        readonly name: string;
        readonly isImage: boolean;
        readonly documentType: string | null;
        readonly " $fragmentRefs": FragmentRefs<"EntityAttachmentListPreviewer_attachment" | "EntityAttachment_attachment" | "EntityAttachmentListItem_attachment">;
    }> | null;
    readonly taxAssessments: ReadonlyArray<{
        readonly attachmentSlug: string;
        readonly name: string;
        readonly isImage: boolean;
        readonly documentType: string | null;
        readonly " $fragmentRefs": FragmentRefs<"EntityAttachmentListPreviewer_attachment" | "EntityAttachment_attachment" | "EntityAttachmentListItem_attachment">;
    }> | null;
    readonly " $refType": "ShipmentFinanceActionCard_shipment";
};
export type ShipmentFinanceActionCard_shipment$data = ShipmentFinanceActionCard_shipment;
export type ShipmentFinanceActionCard_shipment$key = {
    readonly " $data"?: ShipmentFinanceActionCard_shipment$data;
    readonly " $fragmentRefs": FragmentRefs<"ShipmentFinanceActionCard_shipment">;
};



const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "attachmentSlug",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isImage",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "documentType",
    "storageKey": null
  },
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "EntityAttachmentListPreviewer_attachment"
  },
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "EntityAttachment_attachment"
  },
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "EntityAttachmentListItem_attachment"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ShipmentFinanceActionCard_shipment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shipmentType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EntityAttachment",
      "kind": "LinkedField",
      "name": "lexofficeInvoices",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EntityAttachment",
      "kind": "LinkedField",
      "name": "taxAssessments",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Shipment",
  "abstractKey": null
};
})();
(node as any).hash = 'bf1bd7bfbcdd92df114ea4cf11bd4441';
export default node;
