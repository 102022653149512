import { select } from 'd3-selection'

export const LEGEND_LETTER_WIDTH = 7.5
export const LEGEND_LETTER_HEIGHT = 18
export const addLegend = (parent, legendItems, legendWidth, legendHeight, left) => {
  // allow custom left-padding where chart overlaps with y-axis
  const leftPadding = left === undefined ? -parent.margin.left + 5 : left
  const legendX =
    parent.legendPosition === 'left'
      ? leftPadding // left
      : parent.width + parent.margin.right - 2 - legendWidth // right
  const legendY = parent.height / 2 - legendHeight / 2

  const nodeLegend = parent.rc.rectangle(
    legendX,
    legendY,
    legendWidth, // width
    legendHeight, // height
    {
      fill: 'transparent',
      fillWeight: 0.1,
      strokeWidth: 0,
      roughness: 2,
    }
  )

  const legendClass = 'rough' + parent.el.substring(1, parent.el.length)
  nodeLegend.classList.add(legendClass)
  nodeLegend.classList.add('legend-container')
  parent.roughSvg.appendChild(nodeLegend)

  legendItems.forEach((item, i) => {
    const g = select('.' + legendClass)
      .append('g')
      .attr('attrIdx', i)
      .attr('class', 'legend-row')
      .attr(
        'transform',
        `translate(
        ${parent.legendPosition === 'left' ? 5 : parent.width - (legendWidth + 2)},
        ${legendY})`
      )

    g.append('rect')
      .style('fill', parent.colors[i])
      .attr('width', 20)
      .attr('height', 8)
      .attr('x', parent.legendPosition === 'left' ? leftPadding : parent.margin.right + 5)
      .attr('y', 6 + LEGEND_LETTER_HEIGHT * i - parent.margin.top / 3)

    g.append('text')
      .style('font-size', '1.1rem')
      .style('font-family', parent.fontFamily)
      .attr('x', parent.legendPosition === 'left' ? leftPadding + 25 : parent.margin.right + 30)
      .attr('y', 6 + LEGEND_LETTER_HEIGHT * i + 8 - parent.margin.top / 3)
      .text(item.text)
  })
}
