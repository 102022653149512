import { Card, Typography } from '@material-ui/core'
import { graphql } from 'babel-plugin-relay/macro'
import React, { Suspense } from 'react'
import { useLazyLoadQuery } from 'react-relay/hooks'
import styled from 'styled-components'
import Timeline from '../../components/ExternalTimeline/ExternalTimeline'
import { media } from '../../theme'
import { ExternalDashboardMainContentQuery } from '../../__generated__/ExternalDashboardMainContentQuery.graphql'
import { ContainerLocationPie, ContainerStagePie } from './ContainerCharts'

interface Props {
  my: string
}

const MainGrid = styled.div`
  width: 1660px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(13, 1fr);
  grid-gap: 16px;

  ${media.medium`
    width: 1140px;
  `}

  ${media.small`
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
  `}
`

const MainContent = styled.div`
  grid-column: 1 / span 8;

  ${media.small`
    grid-column: 1 / span 1;
  `}
`

const TimelineContent = styled.div`
  grid-column: 9 / span 5;

  ${media.small`
    grid-column: 1 / span 1;
  `}
`

const FullColumn = styled.div`
  grid-column: 1 / span 2;

  ${media.small`
    grid-column: 1 / span 1;
  `}
`

const FirstColumn = styled.div`
  grid-column: 1 / span 1;

  ${media.small`
    grid-column: 1 / span 1;
  `}
`

const SecondColumn = styled.div`
  grid-column: 2 / span 1;

  ${media.small`
    grid-column: 1 / span 1;
  `}
`

const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;

  ${media.small`
    grid-template-columns: repeat(1, 1fr);
  `}
`

const ContentCard = styled(Card)`
  padding: 16px;
  margin-bottom: 8px;
`

const Heading: React.FC<{ title?: string }> = ({ title }) => (
  <Typography key='section-1' variant='h5'>
    {title ? title : <>&nbsp;</>}
  </Typography>
)

const ExternalDashboardMainContent: React.FC<Props> = ({ my }) => {
  const { externalDashboardQuery: data } = useLazyLoadQuery<ExternalDashboardMainContentQuery>(query, {})
  if (!data) return <Typography variant='h5'>No Data</Typography>

  return (
    <MainGrid>
      <MainContent>
        <ContentGrid>
          <FullColumn>
            <Heading title={`${my} Active Containers`} />
          </FullColumn>
          <FirstColumn>
            <ContentCard variant='outlined'>
              <Typography variant='h6'>By stage</Typography>
              <ContainerStagePie data={data.chartContainersByStage} />
            </ContentCard>
          </FirstColumn>
          <SecondColumn>
            <ContentCard style={{ gridColumn: '2 / span 1' }} variant='outlined'>
              <Typography variant='h6'>By location</Typography>
              <ContainerLocationPie data={data.chartContainersByLocation} />
            </ContentCard>
          </SecondColumn>
          <FullColumn>
            <Heading title={`${my} Active Shipments`} />
          </FullColumn>
          <FirstColumn>
            <ContentCard variant='outlined'>
              <Typography variant='h6'>By stage</Typography>
              <ContainerStagePie data={data.chartShipmentsByStage} />
            </ContentCard>
          </FirstColumn>
        </ContentGrid>
      </MainContent>
      <TimelineContent>
        <Typography variant='h5' style={{ marginBottom: '16px' }}>
          {my} containers & shipments
        </Typography>
        <Suspense fallback={<Timeline.Skeleton />}>
          <Timeline type='all' />
        </Suspense>
      </TimelineContent>
    </MainGrid>
  )
}

export default ExternalDashboardMainContent

const query = graphql`
  query ExternalDashboardMainContentQuery {
    externalDashboardQuery {
      chartContainersByLocation {
        labels
        values
        paths
      }
      chartContainersByStage {
        labels
        values
        paths
      }

      chartShipmentsByStage {
        labels
        values
        paths
      }

      chartProcessOverdue {
        labels
        values
        paths
      }

      tsContainersEta {
        labels
        values
        paths
        startIndex
      }

      tsShipmentEta {
        labels
        values
        paths
        startIndex
      }

      tsCostsAndRevenues {
        labels
        values {
          revenue
          containerCost
          shipmentCost
        }
        paths
        startIndex
      }
    }
  }
`
