import React, { Suspense, useState } from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import { DialogTitle, DialogContent, InputLabel } from '@material-ui/core'
import { useLazyLoadQuery } from 'react-relay/hooks'
import { Skeleton, Alert } from '@material-ui/lab'
import { Form } from 'formik'
import styled from 'styled-components'
import { LiveShareAddDialogQuery } from '../__generated__/LiveShareAddDialogQuery.graphql'
import useNiceMutation from '../mutations/useNiceMutation'
import InputField from '../components/Form/InputField'
import { media } from '../theme'
import NiceFormik from '../components/Form/NiceFormik'
import { LiveShareAddDialogMutation } from '../__generated__/LiveShareAddDialogMutation.graphql'
import DialogFormActionBar from './Form/DialogFormActionBar'
import OdysseyDialog from './OdysseyDialog'
interface Props {
  shipmentSlug: string
  open: boolean
  title: string
  onClose: () => void
  onEdit: () => void
}

const StyledForm = styled(Form)`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`
const FieldsContainer = styled.div`
  margin-bottom: 16px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 16px;

  ${media.medium`
    grid-template-columns: repeat(1, 1fr);
  `}

  ${media.small`
    grid-template-columns: auto;
  `}
`
const ExpiryContainer = styled.div`
  margin-bottom: 16px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;

  ${media.medium`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${media.small`
    grid-template-columns: auto;
  `}
`

const LiveShareAddDialog: React.FC<Props> = (props) => {
  return (
    <OdysseyDialog
      open={props.open}
      maxWidth='sm'
      fullWidth
      onClose={props.onClose}
      scroll='paper'
      style={{ maxWidth: '640px', margin: 'auto' }}
    >
      <Suspense fallback={<FormSkeleton {...props} />}>
        <UpdateForm {...props} />
      </Suspense>
    </OdysseyDialog>
  )
}

const UpdateForm: React.FC<Props> = ({ shipmentSlug, title, onClose, onEdit }) => {
  const data = useLazyLoadQuery<LiveShareAddDialogQuery>(query, { entitySlug: shipmentSlug, entityType: 'shipments' })
  const fields = data.infoCreateLiveShareForm.formSchema.fieldSet
  const fieldMaster = Object.fromEntries(fields.map((field) => [field.name, field]))
  const [mutationError, setMutationError] = useState<null | string>(null)
  const [commit] = useNiceMutation<LiveShareAddDialogMutation>(mutation)
  return (
    <NiceFormik
      initialValues={{ entityType: 'shipments', entitySlug: shipmentSlug }}
      onSubmit={(values, actions) => {
        commit({
          variables: {
            input: values as any,
          },
          onError: () => actions.setSubmitting(false),
          onCompleted: (res, errors) => {
            actions.setSubmitting(false)
            if (errors) {
              setMutationError(errors.map((err) => err.message).join(', '))
            } else {
              onEdit()
              onClose()
            }
          },
        })
      }}
      formSchema={fields}
    >
      {({ submitForm }) => (
        <StyledForm>
          <DialogTitle>{title}</DialogTitle>
          {mutationError && <Alert severity='error'>{mutationError}</Alert>}
          <DialogContent dividers={true}>
            <FieldsContainer>
              <InputLabel>Share with</InputLabel>
              <InputField of={'contactSlug'} field={fieldMaster['contactSlug']} />
              <InputLabel>Expiration</InputLabel>
              <ExpiryContainer>
                <InputField of={'expiryType'} field={fieldMaster['expiryType']} />
                <InputField of={'expiryCount'} field={fieldMaster['expiryCount']} />
              </ExpiryContainer>
            </FieldsContainer>
          </DialogContent>
          <DialogFormActionBar
            onCancel={() => onClose()}
            onSubmit={() => {
              submitForm()
            }}
            cancelCta='Cancel'
            saveCta='Save'
          />
        </StyledForm>
      )}
    </NiceFormik>
  )
}

const FormSkeleton: React.FC<Props> = ({ title, onClose }) => (
  <>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <Skeleton variant='rect' height='50px' />
      <Skeleton variant='rect' height='50px' style={{ marginTop: '8px' }} />
    </DialogContent>
    <DialogFormActionBar.Skeleton onCancel={onClose} />
  </>
)

const mutation = graphql`
  mutation LiveShareAddDialogMutation($input: infoCreateLiveShareInput!) {
    infoCreateLiveShare(input: $input) {
      clientMutationId
    }
  }
`

const query = graphql`
  query LiveShareAddDialogQuery($entitySlug: String!, $entityType: LiveShareEntityTypeEnum!) {
    infoCreateLiveShareForm(entitySlug: $entitySlug, entityType: $entityType) {
      formSchema {
        fieldSet {
          name
          type
          required
          title
          description
          format
          enum
          enumTitles
        }
      }
    }
  }
`

export default LiveShareAddDialog
