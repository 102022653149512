/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type UnlinkedTaxAssessmentsQueryVariables = {
    page?: number | null;
    perPage?: number | null;
    mode?: string | null;
    sort: string;
};
export type UnlinkedTaxAssessmentsQueryResponse = {
    readonly unlinkedTaxAssessmentList: {
        readonly " $fragmentRefs": FragmentRefs<"UnlinkedTaxAssessmentsWithParams_list">;
    };
};
export type UnlinkedTaxAssessmentsQuery = {
    readonly response: UnlinkedTaxAssessmentsQueryResponse;
    readonly variables: UnlinkedTaxAssessmentsQueryVariables;
};



/*
query UnlinkedTaxAssessmentsQuery(
  $page: Int
  $perPage: Int
  $mode: String
  $sort: String!
) {
  unlinkedTaxAssessmentList(page: $page, perPage: $perPage, mode: $mode, sort: $sort) {
    ...UnlinkedTaxAssessmentsWithParams_list
  }
}

fragment UnlinkedTaxAssessmentsWithParams_list on UnlinkedTaxAssessmentList {
  unlinkedTaxAssessmentFiles {
    slug
    name
    createdAt
    callResponseUrl
    verified
    id
  }
  paginationMeta {
    totalPages
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "mode"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "page"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "perPage"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sort"
},
v4 = [
  {
    "kind": "Variable",
    "name": "mode",
    "variableName": "mode"
  },
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page"
  },
  {
    "kind": "Variable",
    "name": "perPage",
    "variableName": "perPage"
  },
  {
    "kind": "Variable",
    "name": "sort",
    "variableName": "sort"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UnlinkedTaxAssessmentsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "UnlinkedTaxAssessmentList",
        "kind": "LinkedField",
        "name": "unlinkedTaxAssessmentList",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UnlinkedTaxAssessmentsWithParams_list"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "UnlinkedTaxAssessmentsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "UnlinkedTaxAssessmentList",
        "kind": "LinkedField",
        "name": "unlinkedTaxAssessmentList",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UnlinkedTaxAssessment",
            "kind": "LinkedField",
            "name": "unlinkedTaxAssessmentFiles",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "slug",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "callResponseUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "verified",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Paginate",
            "kind": "LinkedField",
            "name": "paginationMeta",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "totalPages",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c0e3e89d2440427a5251468f52b4b159",
    "id": null,
    "metadata": {},
    "name": "UnlinkedTaxAssessmentsQuery",
    "operationKind": "query",
    "text": "query UnlinkedTaxAssessmentsQuery(\n  $page: Int\n  $perPage: Int\n  $mode: String\n  $sort: String!\n) {\n  unlinkedTaxAssessmentList(page: $page, perPage: $perPage, mode: $mode, sort: $sort) {\n    ...UnlinkedTaxAssessmentsWithParams_list\n  }\n}\n\nfragment UnlinkedTaxAssessmentsWithParams_list on UnlinkedTaxAssessmentList {\n  unlinkedTaxAssessmentFiles {\n    slug\n    name\n    createdAt\n    callResponseUrl\n    verified\n    id\n  }\n  paginationMeta {\n    totalPages\n  }\n}\n"
  }
};
})();
(node as any).hash = 'ae65648999eed74e530aacf02c931f39';
export default node;
