import { Avatar, IconButton, ListItemSecondaryAction, Menu, MenuItem, Switch, Typography } from '@material-ui/core'
import { Brightness4, Brightness7, ExitToApp, Person } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'
import React, { useContext, useRef, useState } from 'react'
import styled, { ThemeContext } from 'styled-components'
import useLogout from '../hooks/useLogout'
import useWorkspaceNavigate from '../hooks/useWorkspaceNavigate'
import SessionContext from '../SessionContext'
import LoadingDots from './LoadingDots'

const ProfileAvatar = styled(Avatar)`
  width: 24px;
  height: 24px;
`

const MenuItemIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
`

const ProfileContainer = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 12px 16px;
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
`

const ProfileContent = styled.div`
  margin-left: 8px;
`

const StyledMenuItem = styled(MenuItem)`
  display: flex;
  align-items: center;
`

const ProfileMenu: React.FC = () => {
  const { user, reloadSession } = useContext(SessionContext)
  const profileMenuRef = useRef(null)
  const [profileMenuOpen, setProfileMenuOpen] = useState(false)
  const wsNavigate = useWorkspaceNavigate()
  const handleProfileClick = () => {
    wsNavigate('/profile')
    setProfileMenuOpen(false)
  }
  const handleProfileMenuClose = () => setProfileMenuOpen(false)
  const [{ error: logoutError, loading: logoutIsInProgress }, { doLogout }] = useLogout({ reloadSession })
  const theme = useContext(ThemeContext)

  return (
    <>
      <IconButton ref={profileMenuRef} onClick={() => setProfileMenuOpen(true)}>
        <ProfileAvatar src={user?.contact?.profileUrl || ''} alt={user?.contact?.name} />
      </IconButton>
      <Menu
        id='profile-menu'
        anchorEl={profileMenuRef.current as HTMLDivElement | null}
        keepMounted
        open={profileMenuOpen}
        onClose={handleProfileMenuClose}
      >
        <ProfileContainer>
          <Avatar src={user?.contact?.profileUrl || ''} alt={user?.contact?.name} />
          <ProfileContent>
            <Typography variant='body1'>{user?.contact?.name}</Typography>
            <Typography variant='body2' color='textSecondary'>
              {user?.contact?.email}
            </Typography>
          </ProfileContent>
        </ProfileContainer>
        {logoutError && <Alert severity='error'>{logoutError.message}</Alert>}
        <StyledMenuItem onClick={handleProfileClick}>
          <MenuItemIcon>
            <Person fontSize='small' />
          </MenuItemIcon>
          <Typography variant='body1'>Profile</Typography>
        </StyledMenuItem>
        <StyledMenuItem onClick={() => theme.toggleTheme()}>
          <MenuItemIcon>{theme.palette.type === 'light' ? <Brightness4 fontSize='small' /> : <Brightness7 fontSize='small' />}</MenuItemIcon>
          <Typography variant='body1'>Dark Mode</Typography>
          <ListItemSecondaryAction>
            <Switch edge='end' onChange={() => theme.toggleTheme()} checked={theme.palette.type === 'dark'} />
          </ListItemSecondaryAction>
        </StyledMenuItem>
        <StyledMenuItem onClick={doLogout}>
          <MenuItemIcon>
            <ExitToApp fontSize='small' />
          </MenuItemIcon>
          <Typography variant='body1'>{logoutIsInProgress ? <LoadingDots /> : 'Logout'}</Typography>
        </StyledMenuItem>
      </Menu>
    </>
  )
}

export default ProfileMenu
