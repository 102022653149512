/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ShipmentInfoCard_shipment = {
    readonly slug: string;
    readonly stage: string;
    readonly shipmentValueCurrency: string | null;
    readonly shipmentCostCurrency: string | null;
    readonly customsClearanceInfoText: string;
    readonly customerName: string | null;
    readonly customerAddress: string | null;
    readonly customerState: string | null;
    readonly customerPostcode: string | null;
    readonly customerPlace: string | null;
    readonly customerCountry: string | null;
    readonly customerPhone: string | null;
    readonly customerCompany: string | null;
    readonly customerFormat: string | null;
    readonly containerSlug: string;
    readonly requiredDocuments: ReadonlyArray<string> | null;
    readonly infoGroupedByStage: ReadonlyArray<{
        readonly stageLabel: string;
        readonly stageValue: string;
        readonly fields: ReadonlyArray<{
            readonly label: string;
            readonly name: string;
            readonly value: string | null;
        }>;
    }>;
    readonly " $refType": "ShipmentInfoCard_shipment";
};
export type ShipmentInfoCard_shipment$data = ShipmentInfoCard_shipment;
export type ShipmentInfoCard_shipment$key = {
    readonly " $data"?: ShipmentInfoCard_shipment$data;
    readonly " $fragmentRefs": FragmentRefs<"ShipmentInfoCard_shipment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ShipmentInfoCard_shipment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "stage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shipmentValueCurrency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shipmentCostCurrency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customsClearanceInfoText",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customerName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customerAddress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customerState",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customerPostcode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customerPlace",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customerCountry",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customerPhone",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customerCompany",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customerFormat",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "containerSlug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "requiredDocuments",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "InfoGroup",
      "kind": "LinkedField",
      "name": "infoGroupedByStage",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "stageLabel",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "stageValue",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "InfoField",
          "kind": "LinkedField",
          "name": "fields",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "label",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "value",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Shipment",
  "abstractKey": null
};
(node as any).hash = '5d44544fad974c2db31b205ecf1f48bc';
export default node;
