import React, { useContext, useState } from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import { useFragment } from 'react-relay/hooks'
import { ContactShowHeroTabBar_contact$key } from '../__generated__/ContactShowHeroTabBar_contact.graphql'
import styled from 'styled-components'
import { tabs } from '../pages/Contact/tabs'
import useWorkspace from '../hooks/useWorkspace'
import { useNavigate, useParams } from 'react-router'
import { media } from '../theme'
import { useSnackbar } from 'notistack'
import useNiceMutation from '../mutations/useNiceMutation'
import { Typography, IconButton, MenuItem, Menu } from '@material-ui/core'
import { Link, Edit, MoreVert } from '@material-ui/icons'
import LoadingDots from './LoadingDots'
import { ContactShowHeroTabBarSendInviteMutation } from '../__generated__/ContactShowHeroTabBarSendInviteMutation.graphql'
import TabsUI from './TabsUI/TabsUI'
import Button from './Button'
import SessionContext from '../SessionContext'
import RevokeAccess from './RevokeAccess'
import ShareContact from './ShareContact'

interface Props {
  contact: ContactShowHeroTabBar_contact$key
  onEdit: () => void
}

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`

const InvitationCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  padding-bottom: 0;

  > * {
    margin-right: 8px;
  }
`
const NormalActions = styled(FlexContainer)`
  ${media.small`
    display: none;
  `}
`

const CollapsedActions = styled(FlexContainer)`
  display: none;

  ${media.small`
    display: flex;
  `}
`

const ContactShowHeroTabBar: React.FC<Props> = ({ contact: data, onEdit }) => {
  const { user } = useContext(SessionContext)
  const superAdmin = user?.contact?.displayRoles.filter((role) => role.value == 'super-admin').length == 1
  const [workspace] = useWorkspace()
  const navigate = useNavigate()
  const { tab } = useParams()
  const contact = useFragment(fragment, data)

  const value = tabs.findIndex((t) => t.value === tab)
  const { enqueueSnackbar } = useSnackbar()
  const [commitSendInvite, sendInviteIsInFlight] = useNiceMutation<ContactShowHeroTabBarSendInviteMutation>(sendInviteMutation)

  const onTabChange = (e: React.ChangeEvent<unknown>, newValue: number) => {
    navigate(`/${workspace}/contact/${contact.slug}/${tabs[newValue].value}`)
  }

  const handleInvitation = () => {
    if (sendInviteIsInFlight) return

    commitSendInvite({ variables: { input: { slug: contact.slug } } })
  }

  const copyInviteLink = () => {
    navigator.clipboard.writeText(contact?.invitation?.url || '')
    enqueueSnackbar('Link copied to clipboard')
  }

  const inviteActions =
    contact.registrationStatus === 'not_invited'
      ? ['send_invitation']
      : contact.registrationStatus === 'invited'
      ? ['copy_invitation_url', 'resend_invitation', 'delete_invitation']
      : []
  const invitationActionCopy =
    contact.registrationStatus === 'invited'
      ? `Invitation sent ${contact?.invitation?.sentAt}`
      : contact.registrationStatus === 'registered'
      ? `Member since ${contact?.user?.createdAt}`
      : null
  const [actionMenuAnchorEl, setActionMenuAnchorEl] = useState<null | HTMLElement>(null)
  const closeActionMenu = () => setActionMenuAnchorEl(null)
  const [shareContact, setShareContact] = useState<boolean>(false)

  return (
    <InvitationCard>
      <ShareContact open={shareContact} onClose={() => setShareContact(false)} slug={contact.slug}></ShareContact>
      <TabsUI isWhiteBackground tabs={tabs} value={value} onChange={onTabChange} />
      <FlexContainer>
        <CollapsedActions>
          <IconButton onClick={(e) => setActionMenuAnchorEl(e.currentTarget)}>
            <MoreVert />
          </IconButton>
          <Menu id='simple-menu' anchorEl={actionMenuAnchorEl} keepMounted open={Boolean(actionMenuAnchorEl)} onClose={closeActionMenu}>
            <MenuItem onClick={() => onEdit()} disabled={!contact.canEdit}>
              Edit
            </MenuItem>
            {superAdmin &&
              inviteActions.map((action) => {
                switch (action) {
                  case 'send_invitation':
                    return (
                      <MenuItem key={action} onClick={handleInvitation}>
                        {sendInviteIsInFlight ? <LoadingDots variant='primary' /> : 'Invite'}
                      </MenuItem>
                    )
                  case 'copy_invitation_url':
                    return (
                      <MenuItem key={action} onClick={copyInviteLink}>
                        Copy Invite Link
                      </MenuItem>
                    )
                  case 'resend_invitation':
                    return (
                      <MenuItem key={action} onClick={handleInvitation}>
                        {sendInviteIsInFlight ? <LoadingDots variant='primary' /> : 'Resend Invitation'}
                      </MenuItem>
                    )
                  case 'delete_invitation':
                    return <MenuItem key={action}>Delete Invitation</MenuItem>
                }

                return <></>
              })}
          </Menu>
        </CollapsedActions>
        <NormalActions>
          {invitationActionCopy && <Typography variant='body2'>{invitationActionCopy}</Typography>}
          {superAdmin &&
            inviteActions.map((action) => {
              switch (action) {
                case 'send_invitation':
                  return (
                    <Button key={action} variant='outlined' onClick={handleInvitation}>
                      {sendInviteIsInFlight ? <LoadingDots variant='primary' /> : 'Invite'}
                    </Button>
                  )
                case 'copy_invitation_url':
                  return (
                    <IconButton key={action} onClick={copyInviteLink}>
                      <Link />
                    </IconButton>
                  )
                case 'resend_invitation':
                  return (
                    <Button key={action} variant='outlined' onClick={handleInvitation}>
                      {sendInviteIsInFlight ? <LoadingDots variant='primary' /> : 'Resend'}
                    </Button>
                  )
                case 'delete_invitation':
                  return (
                    <Button key={action} variant='outlined'>
                      Delete
                    </Button>
                  )
              }

              return <></>
            })}
          <Button variant='contained' startIcon={<Edit />} onClick={() => onEdit()} disabled={!contact.canEdit}>
            Edit
          </Button>
          {contact.registrationStatus === 'registered' && superAdmin && (
            <RevokeAccess username={contact.slug} revokeAccess={contact.revokeAccess}></RevokeAccess>
          )}
          <Button variant='outlined' onClick={() => setShareContact(true)}>
            Share
          </Button>
        </NormalActions>
      </FlexContainer>
    </InvitationCard>
  )
}

const fragment = graphql`
  fragment ContactShowHeroTabBar_contact on Contact {
    slug
    canEdit
    registrationStatus
    invitation {
      url
      sentAt
    }
    user {
      createdAt
    }
    revokeAccess
  }
`

const sendInviteMutation = graphql`
  mutation ContactShowHeroTabBarSendInviteMutation($input: infoSendInvitationInput!) {
    infoSendInvitation(input: $input) {
      contact {
        slug
      }
    }
  }
`

export default ContactShowHeroTabBar
