import React from 'react'
import { graphql } from 'babel-plugin-relay/macro'

import styled from 'styled-components'
import { Paper, Typography } from '@material-ui/core'
import { useFragment } from 'react-relay/hooks'
import { ExternalFoldersCard_folders$key } from '../../__generated__/ExternalFoldersCard_folders.graphql'
import { FolderType, ViewModeT } from './types'
import { AttachmentEntityTypeEnum } from '../../__generated__/FoldersCardNewMutation.graphql'
import ExternalFolder from './ExternalFolder'

interface Props {
  title: string
  folders: ExternalFoldersCard_folders$key
  entityType: AttachmentEntityTypeEnum
  folderType: FolderType
  viewMode?: ViewModeT
  entitySlug: string
  documentType?: string
}

const Root = styled(Paper)`
  padding: 16px;
  margin-bottom: 16px;
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`

const ExternalFoldersCard: React.FC<Props> = ({ folders: data, title, folderType, viewMode = 'home', documentType }) => {
  const foldersConnection = useFragment(fragment, data)
  const displayMode = viewMode === 'tab' && folderType !== 'photos' ? 'list' : 'grid'

  return (
    <Root variant='outlined'>
      <TitleContainer>
        <Typography variant='h6'>{title}</Typography>
      </TitleContainer>
      {foldersConnection.nodes
        ?.filter((f) => f?.parentType === folderType)
        .map(
          (node) =>
            node && (
              <ExternalFolder
                key={node.slug}
                folder={node}
                viewMode={viewMode}
                displayMode={displayMode}
                activeTicket={node.activeTicket}
                folderType={folderType}
                documentType={documentType}
              />
            )
        )}
    </Root>
  )
}
const fragment = graphql`
  fragment ExternalFoldersCard_folders on FolderConnection {
    nodes {
      slug
      activeTicket
      parentType
      ...ExternalFolder_folder
    }
  }
`

export default ExternalFoldersCard
