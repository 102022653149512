import React from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import styled from 'styled-components'
import { ShipmentSiblingCompositionCard_shipment$key } from '../__generated__/ShipmentSiblingCompositionCard_shipment.graphql'
import { useFragment } from 'react-relay/hooks'
import { Paper, Typography, SvgIconTypeMap, Tooltip } from '@material-ui/core'
import {
  DriveEtaOutlined,
  LocalMallOutlined,
  ShoppingCartOutlined,
  MotorcycleOutlined,
  LocalShippingOutlined,
  DirectionsBoatOutlined,
} from '@material-ui/icons'
import { OverridableComponent } from '@material-ui/core/OverridableComponent'

interface Props {
  shipment: ShipmentSiblingCompositionCard_shipment$key
}

const RootPaper = styled(Paper)`
  display: grid;
  grid-template-columns: 8fr 1fr;
  margin-bottom: 16px;
`

const ShipmentCountsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  border-right: 1px solid ${(props) => props.theme.palette.divider};
`

const TypeCountContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 0;
`

const TotalCountContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const shipmentTypeIcons: { [key: string]: OverridableComponent<SvgIconTypeMap<Record<string, unknown>, 'svg'>> } = {
  car: DriveEtaOutlined,
  boat: DirectionsBoatOutlined,
  motor: MotorcycleOutlined,
  oldtimer: DriveEtaOutlined,
  other: LocalMallOutlined,
  package: ShoppingCartOutlined,
  quad: MotorcycleOutlined,
  truck: LocalShippingOutlined,
}

const ShipmentSiblingCompositionCard: React.FC<Props> = ({ shipment: data }) => {
  const shipment = useFragment(fragment, data)
  const composition = shipment.container.composition

  return (
    <RootPaper variant='outlined'>
      <ShipmentCountsContainer>
        {composition.shipmentCountsByType.map((countByType) => {
          const Icon = shipmentTypeIcons[countByType.type] || shipmentTypeIcons['other']
          return (
            <Tooltip key={countByType.type} title={countByType.typeLabel} placement='top' interactive>
              <TypeCountContainer>
                <Icon color={countByType.count === 0 ? 'disabled' : 'inherit'} />
                <Typography variant='h6' color={countByType.count === 0 ? 'textSecondary' : 'inherit'}>
                  {countByType.count}
                </Typography>
              </TypeCountContainer>
            </Tooltip>
          )
        })}
      </ShipmentCountsContainer>
      <TotalCountContainer>
        <Typography variant='h4'>{composition.totalShipments}</Typography>
      </TotalCountContainer>
    </RootPaper>
  )
}

const fragment = graphql`
  fragment ShipmentSiblingCompositionCard_shipment on Shipment {
    container {
      composition {
        totalShipments
        shipmentCountsByType {
          type
          typeLabel
          count
        }
      }
    }
  }
`

export default ShipmentSiblingCompositionCard
