/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type FoldersCard_folders = {
    readonly nodes: ReadonlyArray<{
        readonly slug: string;
        readonly parentType: string;
        readonly " $fragmentRefs": FragmentRefs<"Folder_folder">;
    } | null> | null;
    readonly " $refType": "FoldersCard_folders";
};
export type FoldersCard_folders$data = FoldersCard_folders;
export type FoldersCard_folders$key = {
    readonly " $data"?: FoldersCard_folders$data;
    readonly " $fragmentRefs": FragmentRefs<"FoldersCard_folders">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FoldersCard_folders",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Folder",
      "kind": "LinkedField",
      "name": "nodes",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "parentType",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Folder_folder"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "FolderConnection",
  "abstractKey": null
};
(node as any).hash = '189c0afd2596b319a80010cb1c693917';
export default node;
