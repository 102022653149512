import { Typography, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { graphql } from 'babel-plugin-relay/macro'
import React, { useState } from 'react'
import { useFragment } from 'react-relay/hooks'
import styled from 'styled-components'
import { media, isMedium, isSmall } from '../theme'
import { StageStatusEnum } from '../__generated__/ListPageItem_listItem.graphql'
import { ShowPageListItem_listItem$key, ShowPageListItem_listItem$data } from '../__generated__/ShowPageListItem_listItem.graphql'
import Badge from './Badge'
import ItemImage from './ItemImage'
import SemanticLine from './SemanticLine'
import SemanticString from './SemanticString'
import ShowPageInDrawer, { ShowPageInDrawer_EntityType } from './ShowPageInDrawer'
import WorkspacedLink from './WorkspacedLink'
import KeyField from './KeyField'
import StickyNote from './StickyNote'
import ArchivedBanner from './ArchivedBanner'
import LinkSet from './LinkSet/LinkSet'

interface Props {
  listItem: ShowPageListItem_listItem$key
}

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`

export const RootWorkSpacedLink = styled(WorkspacedLink)`
  display: block;
  position: relative;
  overflow: hidden;
  background-color: ${(props) => props.theme.palette.background.paper};

  &:hover {
    filter: brightness(0.95);
  }
`

export const Topbar = styled(FlexContainer)`
  ${media.small`
    justify-content: space-between;
  `}
`

interface StepProps {
  status: StageStatusEnum
}

const Step = styled.div<StepProps>`
  flex-grow: 1;
  display: flex;
  align-items: center;
  background-color: ${(props) => (props.status === 'active' ? '#BDE4F0' : props.status === 'completed' ? '#BDF0D1' : '#E8E8E8')};
  color: ${(props) => (props.status === 'active' ? '#1A4A77' : props.status === 'completed' ? '#1A7734' : '#646464')};
  position: relative;
  height: 16px;
  padding: 12px 8px;
  margin-right: 16px;

  &:before {
    content: '';
    position: absolute;
    left: -8px;
    display: block;
    width: 0;
    height: 0;
    color: ${(props) => (props.status === 'active' ? '#1A4A77' : props.status === 'completed' ? '#1A7734' : '#646464')};
    border-top: 12px solid ${(props) => (props.status === 'active' ? '#BDE4F0' : props.status === 'completed' ? '#BDF0D1' : '#E8E8E8')};
    border-bottom: 12px solid ${(props) => (props.status === 'active' ? '#BDE4F0' : props.status === 'completed' ? '#BDF0D1' : '#E8E8E8')};
    border-left: 8px solid transparent;
  }

  &:after {
    content: '';
    position: absolute;
    right: -8px;
    display: block;
    width: 0;
    height: 0;
    color: ${(props) => (props.status === 'active' ? '#1A4A77' : props.status === 'completed' ? '#1A7734' : '#646464')};
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-left: 8px solid ${(props) => (props.status === 'active' ? '#BDE4F0' : props.status === 'completed' ? '#BDF0D1' : '#E8E8E8')};
  }
`

const Stages = styled(FlexContainer)`
  margin-right: 16px;

  ${Step}:first-child {
    margin-left: 0;
    &:before {
      content: none;
    }
  }
`

export const Status = styled.div`
  padding: 12px 8px;
  display: flex;
  align-items: center;
  background-color: #e4e4e4;
  position: relative;
  height: 16px;

  &:before {
    content: '';
    position: absolute;
    left: -16px;
    display: block;
    width: 0;
    height: 0;
    border-bottom: 24px solid #e4e4e4;
    border-left: 16px solid transparent;
  }

  &:after {
    content: '';
    position: absolute;
    right: -16px;
    display: block;
    width: 0;
    height: 0;
    border-top: 24px solid #e4e4e4;
    border-right: 16px solid transparent;
  }
`

export const StatusContainer = styled(FlexContainer)`
  ${Status}:first-child {
    margin-left: 0;
    &:before {
      content: none;
    }
  }

  /* ${Status}:last-child {
    margin-right: 0;
    &:after {
      content: none;
    }
  } */
`

export const RootContainer = styled(FlexContainer)`
  padding: 16px;
  align-items: flex-start;
  background-color: ${(props) => props.theme.palette.background.paper};
  transition-duration: 300ms;
`

export const Content = styled(FlexContainer)`
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
`

export const TitleRow = styled(FlexContainer)`
  width: 100%;
  justify-content: space-between;
`

export const TitleContainer = styled(FlexContainer)`
  align-items: flex-start;
  width: 100%;
  margin-right: 8px;
`

export const TitleText = styled(Typography)`
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
`

export const TitleLink = styled(Typography)`
  margin-right: 8px;
  transition-duration: 200ms;
  color: ${(props) => props.theme.palette.text.primary};
  text-decoration: unset;
`

export const KeyFieldsContainer = styled.div`
  margin: 4px 0 16px 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, 32%);
  grid-gap: 8px;
  width: 100%;

  ${media.small`
    grid-template-columns: repeat(auto-fill, 47.5%);
  `}
`

const LinkSetContainer = styled.div`
  margin: 8px -8px;
  width: 100%;
`

export const Archivespan = styled.span`
  margin-top: 3px;
  color: ${(props) => props.theme.palette.warning.dark};
`

const ShowPageListItem: React.FC<Props> = ({ listItem }) => {
  const item = useFragment(listItemFragment, listItem)
  const maxFields = isSmall() ? 3 : isMedium() ? 6 : 9

  const [entity, setEntity] = useState<Pick<ShowPageListItem_listItem$data, 'slug' | 'itemType'>>()
  const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (e.ctrlKey) return

    e.preventDefault()
    setEntity(item)
  }

  return (
    <>
      <RootWorkSpacedLink to={`/${item.itemType}/${item.slug}`} onClick={handleLinkClick}>
        <ArchivedBanner isArchived={item.isArchived} variant='small' />
        <Topbar>
          {item.stages && item.stages.length > 0 && (
            <Stages>
              {item.stages.map((stage, idx) => (
                <Step key={stage.key} status={stage.status}>
                  {idx + 1}
                </Step>
              ))}
            </Stages>
          )}
          {item.statuses && item.statuses.length > 0 && (
            <StatusContainer>
              {item.statuses.map((status) => (
                <Status key={status.key}>
                  <SemanticString data={status.value} />
                </Status>
              ))}
            </StatusContainer>
          )}
        </Topbar>
        <RootContainer>
          <Content>
            <TitleRow title={item.title}>
              {item.image && <ItemImage variant='extraSmall' data={item.image} />}
              <TitleContainer>
                <TitleLink>
                  <TitleText variant='subtitle1' color='textPrimary'>
                    {item.title}
                  </TitleText>
                  {item.lines?.map((line) => (
                    <SemanticLine key={line.key} data={line} />
                  ))}
                </TitleLink>
                {item.badges?.map((badge) => (
                  <span key={badge.key} style={{ marginTop: '3px' }}>
                    <Badge key={badge.key} data={badge} />
                  </span>
                ))}
                {item.stickyNoteContent && <StickyNote note={item.stickyNoteContent} />}
              </TitleContainer>
            </TitleRow>
            {item.keyFields && (
              <KeyFieldsContainer>
                {item.keyFields?.slice(0, maxFields)?.map((field) => (
                  <KeyField key={field.key} data={field} />
                ))}
              </KeyFieldsContainer>
            )}
            <List component='div' style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', width: '100%' }} disablePadding>
              {item.children?.map((childItem) => (
                <ListItem
                  key={childItem.slug}
                  button
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    setEntity(childItem)
                  }}
                  style={{ paddingLeft: 0 }}
                >
                  <ListItemIcon>
                    {childItem.badges?.map((badge) => (
                      <Badge key={badge.key} data={badge} />
                    ))}{' '}
                  </ListItemIcon>
                  <ListItemText primary={childItem.title} />
                </ListItem>
              ))}
            </List>
            {item.linkSets?.map((linkSet) => (
              <LinkSetContainer onClick={(e) => e.stopPropagation()} key={linkSet.key}>
                <LinkSet type='contact' data={linkSet} />
              </LinkSetContainer>
            ))}
          </Content>
        </RootContainer>
      </RootWorkSpacedLink>
      <ShowPageInDrawer
        entityType={entity?.itemType as ShowPageInDrawer_EntityType}
        entitySlug={entity?.slug as string}
        open={!!entity}
        onClose={() => setEntity(undefined)}
      />
    </>
  )
}

const listItemFragment = graphql`
  fragment ShowPageListItem_listItem on ListItem {
    slug
    title
    isArchived
    itemType
    stickyNoteContent
    image {
      ...ItemImage_data
    }
    path
    statuses {
      key
      value {
        ...SemanticString_data
      }
    }
    stages {
      key
      status
      label
      value
    }
    badges {
      key
      ...Badge_data
    }
    lines {
      key
      ...SemanticLine_data
    }
    linkSets {
      key
      ...LinkSet_data
    }
    keyFields {
      key
      ...KeyField_data
    }
    children {
      slug
      title
      itemType
      badges {
        key
        ...Badge_data
      }
    }
  }
`

export default ShowPageListItem
