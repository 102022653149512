import React from 'react'
import styled from 'styled-components'
import { isLarge, isMedium, isSmall, media } from '../theme'

type ResponsiveGridType =
  | 'default-layout'
  | 'index-grid-layout'
  | 'index-list-layout'
  | 'show-layout'
  | 'mini-show-layout'
  | 'two-column-layout'
  | 'single-column-layout'
  | 'mini-single-column-layout'
  | 'dashboard-layout'

interface Props {
  type: ResponsiveGridType
  header?: React.ReactChild[]
  headerProfile?: React.ReactChild[]
  sidebar?: React.ReactChild[]
  left?: React.ReactChild[]
  right?: React.ReactChild[]
  middle?: React.ReactChild[]
  content?: React.ReactChild[]
  highlight?: React.ReactChild[]
}

// Always render hi only on show-layouts (Root9 and Root13) and never on others
// Always render fg and tg
// Always render sg only on large and not on medium and small

const Root9 = styled.div`
  margin: auto;
  width: 1660px;
  max-width: 95vw;
  padding-top: 8px;
  display: grid;
  grid-template-areas:
    'hi hi hi hi tg tg tg tg tg'
    'fg fg fg fg tg tg tg tg tg'
    'sg sg sg sg tg tg tg tg tg';
  grid-template-columns: repeat(9, 1fr);

  ${media.medium`
    width: 1140px;
    max-width: 90vw;
    padding-top: 0;
    grid-template-columns: auto;
    grid-template-areas: 'hi' 'fg' 'tg';
  `}

  ${media.small`
    width: 80vw;
    padding-top: 0;
    min-width: 320px;
    grid-template-columns: auto;
    grid-template-areas: 'hi' 'fg' 'tg';
  `}
`

const RootTwoColumn = styled.div`
  width: 1660px;
  margin: auto;
  display: grid;
  grid-template-areas:
    'fg fg fg fg fg fg tg tg tg tg tg tg'
    'sg sg sg sg sg sg tg tg tg tg tg tg';
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 30px;

  ${media.medium`
    width: 1140px;
    padding-top: 8px;
    grid-template-columns: auto;
    grid-template-areas: 'fg' 'tg';
  `}

  ${media.small`
    width: 100%;
    padding-top: 0;
    min-width: 320px;
    max-width: 625px;
    grid-template-columns: 100%;
    grid-template-areas: 'fg' 'tg';
  `}
`

const Root12 = styled.div`
  width: 1660px;
  margin: auto;
  display: grid;
  grid-template-areas: 'fg fg fg fg sg sg sg sg tg tg tg tg';
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 30px;

  ${media.medium`
    width: 1140px;
    padding-top: 8px;
    grid-template-areas:
    'fg fg fg fg tg tg tg tg';
    grid-template-columns: repeat(8, 1fr);
  `}

  ${media.small`
    width: 100%;
    padding-top: 0;
    min-width: 320px;
    max-width: 625px;
    grid-template-columns: 100%;
    grid-template-areas: 'fg' 'tg';
  `}
`

const Root13 = styled.div<{ 'data-with-highlight': boolean }>`
  width: 1660px;
  margin: auto;
  display: grid;

  grid-template-areas: ${(props) =>
    props['data-with-highlight'] === true
      ? `
    'hi hi hi hi hi hi hi hi tg tg tg tg tg'
    'fg fg fg fg sg sg sg sg tg tg tg tg tg'
    '.  .  .  .  .  .  .  .  tg tg tg tg tg'
  `
      : `
    'fg fg fg fg sg sg sg sg tg tg tg tg tg'
  `};
  grid-template-columns: repeat(13, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 30px;

  ${(props) => media.medium`
    width: 1140px;
    padding-top: 8px;
    grid-template-areas: ${
      props['data-with-highlight'] === true
        ? `
      'hi hi hi hi tg tg tg tg tg'
      'fg fg fg fg tg tg tg tg tg'
    `
        : `
      'fg fg fg fg tg tg tg tg tg'
    `
    };
    grid-template-columns: repeat(9, 1fr);
  `}

  ${(props) => media.small`
    width: 100%;
    padding-top: 0;
    min-width: 320px;
    max-width: 625px;
    grid-template-columns: minmax(320px, 625px);
    grid-template-areas: ${
      props['data-with-highlight'] === true
        ? `
      'hi' 'fg' 'tg'
    `
        : `
      'fg' 'tg'
    `
    };
  `}
`

const RootIndex = styled.div`
  width: 1660px;
  margin: auto;
  display: grid;
  grid-template-areas:
    'tg tg fg fg fg fg fg fg fg fg fg'
    'tg tg sg sg sg sg sg sg sg sg sg';
  grid-template-columns: repeat(11, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 30px;

  ${media.medium`
    width: 1140px;
    padding-top: 8px;
    grid-template-areas: 'tg tg tg fg fg fg fg fg fg fg fg';
    grid-template-columns: repeat(11, 1fr);
  `}

  ${media.small`
    width: 100%;
    padding-top: 0;
    min-width: 320px;
    max-width: 625px;
    grid-template-columns: 100%;
    grid-template-areas: 'fg' 'tg';
  `}
`

const RootMiniSingle = styled.div`
  margin: auto;
  width: 1660px;
  max-width: 95vw;
  padding-top: 8px;
  grid-template-areas: 'fg' 'sg' 'tg';
  grid-template-columns: auto;

  ${media.medium`
    width: 1140px;
    max-width: 90vw;
    padding-top: 0;
    grid-template-columns: auto;
    grid-template-areas: 'fg' 'tg';
  `}

  ${media.small`
    width: 80vw;
    padding-top: 0;
    min-width: 320px;
    grid-template-columns: auto;
    grid-template-areas: 'fg' 'tg';
  `}
`

const RootSingle = styled.div`
  width: 1660px;
  margin: auto;
  display: grid;
  grid-template-areas: 'fg' 'sg' 'tg';
  grid-template-columns: auto;
  grid-column-gap: 30px;
  grid-row-gap: 30px;

  ${media.medium`
    width: 1140px;
    padding-top: 8px;
    grid-template-areas: 'fg' 'tg';
    grid-template-columns: auto;
  `}

  ${media.small`
    width: 100%;
    padding-top: 0;
    min-width: 320px;
    max-width: 625px;
    grid-template-columns: auto;
    grid-template-areas: 'fg' 'tg';
  `}
`

const RootDashboard = styled.div`
  width: 1660px;
  margin: auto;
  display: grid;
  grid-template-areas:
    'hd hd hd hd hd hd hd hd hp hp'
    'fg fg fg fg fg fg tg tg tg tg';
  grid-template-columns: repeat(10, 1fr);
  grid-column-gap: 30px;
  grid-row-gap: 30px;

  ${media.medium`
    width: 1140px;
    padding-top: 8px;
    grid-template-areas: 
    'hd hd hd hd hd hd hd hd hp hp'
    'fg fg fg fg fg fg tg tg tg tg';
    grid-template-columns: repeat(10, 1fr);
  `}

  ${media.small`
    width: 100%;
    padding-top: 0;
    min-width: 320px;
    max-width: 625px;
    grid-template-columns: auto;
    grid-template-areas: 'hp' 'hd' 'fg' 'tg';
  `}
`

const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Highlight = styled.div`
  grid-area: hi;
`

const FirstGroup = styled.div`
  grid-area: fg;
`

const SecondGroup = styled.div`
  grid-area: sg;
`

const ThirdGroup = styled.div`
  grid-area: tg;
`

const Header = styled.div`
  grid-area: hd;
`

const HeaderProfile = styled.div`
  grid-area: hp;
`

const ResponsiveGrid: React.FC<Props> = ({
  type,
  header = [],
  headerProfile = [],
  sidebar = [],
  highlight = [],
  left = [],
  right = [],
  middle = [],
  content = [],
}) => {
  const cards = [[...left, ...content], [...middle], [...right, ...sidebar]]

  const zippedCards = () => [...Array(Math.max(cards[0].length, cards[1].length)).keys()].map((idx) => [cards[0][idx], cards[1][idx]]).flat()

  const firstColCards = () => {
    if (isSmall()) return [...zippedCards(), ...cards[2]]
    if (isMedium()) return zippedCards()
    if (isLarge() && type.startsWith('mini')) return zippedCards()
    return cards[0] || []
  }

  const secondColCards = () => {
    if (isSmall()) return []
    if (isMedium()) return []
    if (isLarge() && type.startsWith('mini')) return []
    return cards[1] || []
  }

  const thirdColCards = () => {
    if (isSmall()) return []
    if (isMedium()) return cards[2]
    return cards[2] || []
  }

  const roots = {
    'default-layout': Root12,
    'show-layout': Root13,
    'index-grid-layout': RootIndex,
    'index-list-layout': RootIndex,
    'mini-show-layout': Root9,
    'two-column-layout': RootTwoColumn,
    'single-column-layout': RootSingle,
    'mini-single-column-layout': RootMiniSingle,
    'dashboard-layout': RootDashboard,
  }

  const Root = roots[type] || Root12

  if (type === 'index-grid-layout')
    return (
      <Root>
        <FirstGroup className='group-1'>
          <FlexContainer>
            {firstColCards().map((card, idx) => (
              <React.Fragment key={idx}>{card}</React.Fragment>
            ))}
          </FlexContainer>
        </FirstGroup>
        {!isSmall() && <ThirdGroup className='group-3'>{thirdColCards()}</ThirdGroup>}
      </Root>
    )

  if (type === 'index-list-layout')
    return (
      <Root>
        <FirstGroup className='group-1'>{firstColCards()}</FirstGroup>
        {!isSmall() && <ThirdGroup className='group-3'>{thirdColCards()}</ThirdGroup>}
      </Root>
    )

  if (type === 'two-column-layout')
    return (
      <Root>
        <FirstGroup className='group-1'>{firstColCards()}</FirstGroup>
        <ThirdGroup className='group-3'>{thirdColCards()}</ThirdGroup>
      </Root>
    )

  if (type === 'dashboard-layout')
    return (
      <RootDashboard>
        <Header className='group-1'>{header}</Header>
        <HeaderProfile className='group-1'>{headerProfile}</HeaderProfile>
        <FirstGroup className='group-1'>{firstColCards()}</FirstGroup>
        <ThirdGroup className='group-3'>{thirdColCards()}</ThirdGroup>
      </RootDashboard>
    )

  const withHighlight = highlight.length > 0

  return (
    <Root className={`responsive-root-${type}`} data-with-highlight={withHighlight}>
      {withHighlight && <Highlight>{highlight}</Highlight>}
      <FirstGroup className={`group-1-${type}`}>{firstColCards()}</FirstGroup>
      {isLarge() && <SecondGroup className={`group-2-${type}`}>{secondColCards()}</SecondGroup>}
      {(isLarge() || isMedium()) && <ThirdGroup className={`group-3-${type}`}>{thirdColCards()}</ThirdGroup>}
    </Root>
  )
}

export default ResponsiveGrid
