import React, { useState, FormEvent } from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import styled from 'styled-components'
import { Card, Typography } from '@material-ui/core'
import { useParams, useNavigate } from 'react-router'
import { useLazyLoadQuery, useMutation } from 'react-relay/hooks'
import { Alert, Skeleton } from '@material-ui/lab'
import StyledInput from './StyledInput'
import CTAButton from './CTAButton'
import { InvitationAcceptanceFormTokenValidityQuery } from '../__generated__/InvitationAcceptanceFormTokenValidityQuery.graphql'
import { InvitationAcceptanceFormAcceptAccountMutation } from '../__generated__/InvitationAcceptanceFormAcceptAccountMutation.graphql'
import OdysseyLogoImage from '../assets/odyssey-logo.svg'
import LoadingDots from './LoadingDots'
import { ChevronRight } from '@material-ui/icons'
import { InvitationAcceptanceFormAcceptWorkspaceMutation } from '../__generated__/InvitationAcceptanceFormAcceptWorkspaceMutation.graphql'

const InvitationCard = styled(Card)`
  padding: 16px 24px;
  max-width: 500px;
  margin: auto;
`

const InvitationCopy = styled.div`
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 16px;
  background-color: #deedff;
`

const InvitationCopyTitle = styled(Typography)`
  margin-bottom: 8px;
`

const InvitationCopySubtext = styled(Typography)`
  white-space: pre;
`

const OdysseyLogoContainer = styled.div`
  width: 50%;
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
`

const InvitationAcceptanceForm: React.FC = () => {
  const { token } = useParams()
  const [password, setPassword] = useState('')
  const [error, setError] = useState<string | null>(null)
  const data = useLazyLoadQuery<InvitationAcceptanceFormTokenValidityQuery>(query, { token: token })
  const [accountCommit, accountAcceptanceIsInFlight] = useMutation<InvitationAcceptanceFormAcceptAccountMutation>(accountMutation)
  const [workspaceCommit, workspaceAcceptanceIsInFlight] = useMutation<InvitationAcceptanceFormAcceptWorkspaceMutation>(workspaceMutation)
  const navigate = useNavigate()
  const [acceptanceSucceeded, setAcceptanceSucceeded] = useState(false)
  const inviteValidity = data.inviteValidity

  const acceptAccountInvitation = (e: FormEvent) => {
    e.preventDefault()

    accountCommit({
      variables: {
        input: {
          token: token,
          password: password,
        },
      },
      onCompleted: (_, errors) => {
        if (errors) {
          setError(errors.map((err) => err.message).join(', '))
        } else {
          setAcceptanceSucceeded(true)
        }
      },
    })
  }

  const acceptWorkspaceInvitation = (e: FormEvent) => {
    e.preventDefault()

    workspaceCommit({
      variables: {
        input: {
          token: token,
        },
      },
      onCompleted: (_, errors) => {
        if (errors) {
          setError(errors.map((err) => err.message).join(', '))
        } else {
          setAcceptanceSucceeded(true)
        }
      },
    })
  }

  return (
    <InvitationCard>
      <OdysseyLogoContainer>
        <img src={OdysseyLogoImage} alt='odyssey-logo' width='100%' />
      </OdysseyLogoContainer>
      {!acceptanceSucceeded ? (
        inviteValidity.invitationType === 'account' ? (
          <>
            <InvitationCopy>
              <InvitationCopyTitle variant='h5'>{inviteValidity.copyTitle}</InvitationCopyTitle>
              <InvitationCopySubtext variant='body2'>{inviteValidity.copySubtext}</InvitationCopySubtext>
            </InvitationCopy>
            {error && <Alert severity='error'>{error}</Alert>}
            {inviteValidity.isValid && (
              <form onSubmit={acceptAccountInvitation}>
                <StyledInput disabled fullWidth placeholder='Username' type='text' value={inviteValidity.username} />
                <StyledInput fullWidth placeholder='Password' type='password' value={password} onChange={(e) => setPassword(e.target.value)} />
                <CTAButton type='submit'>{accountAcceptanceIsInFlight ? <LoadingDots /> : 'Accept Invitation'}</CTAButton>
              </form>
            )}
          </>
        ) : (
          <>
            <InvitationCopy>
              <InvitationCopyTitle variant='h5'>{inviteValidity.copyTitle}</InvitationCopyTitle>
              <InvitationCopySubtext variant='body2'>{inviteValidity.copySubtext}</InvitationCopySubtext>
            </InvitationCopy>
            {error && <Alert severity='error'>{error}</Alert>}
            {inviteValidity.isValid && (
              <form onSubmit={acceptWorkspaceInvitation}>
                <CTAButton type='submit'>{workspaceAcceptanceIsInFlight ? <LoadingDots /> : 'Accept Invitation'}</CTAButton>
              </form>
            )}
          </>
        )
      ) : (
        <>
          <InvitationCopy>
            <InvitationCopyTitle variant='h5'>All Done!</InvitationCopyTitle>
            <InvitationCopySubtext variant='body2'>Please login to continue</InvitationCopySubtext>
          </InvitationCopy>
          <CTAButton onClick={() => navigate('/')}>
            Login <ChevronRight />
          </CTAButton>
        </>
      )}
    </InvitationCard>
  )
}

const FormSkeleton = (
  <InvitationCard>
    <OdysseyLogoContainer>
      <img src={OdysseyLogoImage} alt='odyssey-logo' width='100%' />
    </OdysseyLogoContainer>
    <Skeleton variant='rect' height={100} style={{ marginBottom: '16px' }} />
    <Skeleton variant='rect' height={50} style={{ marginBottom: '8px' }} />
    <CTAButton disabled>Get Started</CTAButton>
  </InvitationCard>
)

const query = graphql`
  query InvitationAcceptanceFormTokenValidityQuery($token: String!) {
    inviteValidity(token: $token) {
      isValid
      copyTitle
      copySubtext
      username
      invitationType
    }
  }
`

const accountMutation = graphql`
  mutation InvitationAcceptanceFormAcceptAccountMutation($input: infoAcceptInvitationInput!) {
    infoAcceptInvitation(input: $input) {
      clientMutationId
    }
  }
`

const workspaceMutation = graphql`
  mutation InvitationAcceptanceFormAcceptWorkspaceMutation($input: infoAcceptWorkspaceInvitationInput!) {
    infoAcceptWorkspaceInvitation(input: $input) {
      clientMutationId
    }
  }
`

export default Object.assign(InvitationAcceptanceForm, { skeletion: FormSkeleton })
