import React, { useState } from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import styled from 'styled-components'

import { useFragment } from 'react-relay/hooks'
import NiceFormik from '../../components/Form/NiceFormik'
import { media } from '../../theme'
import { Divider, Typography, Paper, InputAdornment, IconButton } from '@material-ui/core'
import { useField } from 'formik'
import KeyValueField from '../../components/KeyValueField'
import Button from '../../components/Button'
import { Field as FormikField } from 'formik'
import { TextField as FormikMuiTextField } from 'formik-material-ui'
import useNiceMutation from '../../mutations/useNiceMutation'
import { ShipmentFinanceDetails_shipment$key } from '../../__generated__/ShipmentFinanceDetails_shipment.graphql'
import { ShipmentFinanceDetailsMutation } from '../../__generated__/ShipmentFinanceDetailsMutation.graphql'
import { asFormattedCurrency } from './utils'
import { Refresh } from '@material-ui/icons'
import SendEmailDialog from '../Email/SendEmailDialog'

interface Props {
  shipment: ShipmentFinanceDetails_shipment$key
}

const ContentContainer = styled.div`
  padding-top: 16px;
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 16px;
  align-items: baseline;

  ${media.medium`
    grid-template-columns: 1fr;
  `}

  ${media.small`
    grid-template-columns: 1fr;
  `}
`

const ActionBar = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 8px 0;
  background-color: ${(props) => props.theme.palette.background.paper};
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
  position: sticky;
  top: 0;
  z-index: 2;
`

const ThreeColumnTable = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(40px, 1fr);
  align-items: center;
  grid-column-gap: 16px;
  grid-row-gap: 2px;
`

const TwoColumnTable = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(40px, 1fr);
  align-items: center;
  grid-column-gap: 16px;
  grid-row-gap: 2px;
`

const InfoCardPaper = styled(Paper)`
  padding: 16px;
  border: 0;
  border-top: 1px solid ${(props) => props.theme.palette.divider};
  background-color: transparent;
`
const InfoCardTitleTypography = styled(Typography)`
  margin-bottom: 8px;
`
const InfoCardsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 8px;
  background-color: ${(props) => (props.theme.palette.type === 'light' ? '#f5f5f5' : '#1c1e1f')};

  ${InfoCardPaper}:nth-child(1) {
    border-top: 0;
  }
`

const SectionPaper = styled(Paper)`
  padding: 16px;
`
const Section: React.FC = (props) => <SectionPaper variant='outlined'>{props.children}</SectionPaper>

const SectionsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 8px;
`

const EmptyCell = styled.div``

const FieldsContainer = styled.div<{ $columns: number }>`
  display: grid;
  grid-template-columns: ${(props) => `repeat(${props.$columns}, 1fr)`};

  ${media.small`
    grid-template-columns: 1fr;
  `}
`

const FieldContainer = styled.div`
  display: flex;
  align-items: center;
`

const TotalsDivider = styled(Divider)`
  margin: 8px 0;
`

const InfoCard: React.FC = (props) => <InfoCardPaper variant='outlined'>{props.children}</InfoCardPaper>
const InfoCardTitle: React.FC = (props) => <InfoCardTitleTypography variant='body1'>{props.children}</InfoCardTitleTypography>
const THead: React.FC = (props) => <Typography variant='body1'>{props.children}</Typography>
const RHead: React.FC = (props) => <Typography variant='body1'>{props.children}</Typography>
const DateField: React.FC<{ name: string; editable?: boolean; estimatedValue?: string | Date | number | null }> = ({
  name,
  editable = true,
  estimatedValue,
}) => {
  const [, meta, helpers] = useField(name)
  const [, viewModeMeta] = useField('viewMode')
  const inputDate = new Date(estimatedValue as string)
  const year = inputDate.getFullYear()
  const day = String(inputDate.getDate()).padStart(2, '0')
  const month = String(inputDate.getMonth() + 1).padStart(2, '0')
  const customString = estimatedValue ? `${year}-${month}-${day}` : null
  const [date, setDate] = useState(customString)

  return viewModeMeta.value !== 'edit' || editable === false ? (
    <Typography variant='h6' color={meta.value ? 'textPrimary' : 'textSecondary'}>
      {estimatedValue ?? '--'}
    </Typography>
  ) : (
    <FieldContainer>
      <FormikField
        name={name}
        type='date'
        component={FormikMuiTextField}
        variant='outlined'
        size='small'
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setDate(e.target.value)
          helpers.setValue(e.target.value)
        }}
        value={date}
      />
    </FieldContainer>
  )
}

const Currency: React.FC<{ name: string; editable?: boolean; estimatedValue?: string | number | null }> = ({
  name,
  editable = true,
  estimatedValue,
}) => {
  const [, meta, helpers] = useField(name)
  const [, viewModeMeta] = useField('viewMode')

  return viewModeMeta.value !== 'edit' || editable === false ? (
    <Typography variant='h6' color={meta.value ? 'textPrimary' : 'textSecondary'}>
      {meta.value ?? '--'} €
    </Typography>
  ) : (
    <FieldContainer>
      <FormikField
        name={name}
        type='number'
        component={FormikMuiTextField}
        variant='outlined'
        size='small'
        InputProps={{
          endAdornment: <InputAdornment position='end'>€</InputAdornment>,
        }}
      />
      {estimatedValue && (
        <IconButton size='medium' onClick={() => helpers.setValue(estimatedValue)}>
          <Refresh fontSize='small' />
        </IconButton>
      )}
    </FieldContainer>
  )
}

const ShipmentFinanceDetails: React.FC<Props> = ({ shipment: data }) => {
  const [emailNewOpen, setEmailNewOpen] = useState(false)
  const shipment = useFragment(financeDetailsFragment, data)
  const [commit] = useNiceMutation<ShipmentFinanceDetailsMutation>(mutation)

  const shipmentFinancesOne: OtherFinanceRowT[] = [
    { label: 'Discharge', cost: 'costDischarge', revenue: 'revenueDischarge' },
    { label: 'Forklift', cost: 'costForklift', revenue: 'revenueForklift' },
    { label: 'Customs Clearance', cost: 'costCustomsClearance', revenue: 'revenueCustomsClearance' },
    { label: 'Customs Inspection', cost: 'costCustomsInspection', revenue: 'revenueCustomsInspection' },
    { label: 'Opinion', cost: 'costOpinion', revenue: 'revenueOpinion' },
    { label: 'Clima Certificate', cost: 'costClimaCertificate', revenue: 'revenueClimaCertificate' },
    { label: 'Cleaning', cost: 'costCleaning', revenue: 'revenueCleaning' },
    { label: 'Special Handling', cost: 'costSpecialHandling', revenue: 'revenueSpecialHandling' },
    { label: 'Shipping', cost: 'costShipping', revenue: 'revenueShipping' },
    { label: 'Transport', cost: 'costTransport', revenue: 'revenueTransport' },
  ]

  const rateCard = shipment.rateCardContact?.rateCard
  const rateCardTitle = shipment.rateCardContact?.rateCard
    ? `@${shipment.rateCardContact.slug}'s rate card`
    : `@--'s rate card (no rate card on shipment)`
  const cutomerTitle = shipment.customer?.slug ? `@${shipment.customer.slug}'s VAT` : `@--'s VAT (no customer on shipment)`

  const warehouseContact = shipment.container.warehouseContact
  const warehouseContactTitle = warehouseContact ? `Warehouse @${warehouseContact.slug}` : `Warehouse @-- (no warehouse on container)`

  return (
    <NiceFormik
      initialValues={{ ...(shipment || {}), viewMode: 'show' }}
      enableReinitialize
      onSubmit={(values, actions) => {
        const financeValues = Object.fromEntries(
          Object.entries(values)
            .filter(([key]) => mutationValueKeys.includes(key))
            .map(([key, value]) => (value === '' ? [key, null] : [key, value]))
        )
        commit({
          variables: {
            input: financeValues as any,
          },
          onError: () => actions.setSubmitting(false),
        })
      }}
    >
      {({ setFieldValue, values, submitForm, isSubmitting, resetForm }) => (
        <>
          <ActionBar>
            <Typography style={{ width: '75%' }} variant='body1'>
              Total revenue : {asFormattedCurrency(shipment.totalRevenue)}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Total cost :{' '}
              {asFormattedCurrency(shipment.totalCost)}
            </Typography>
            {(shipment.stage === 'billing' || shipment.stage === 'handover') && (
              <Button variant='outlined' onClick={() => setEmailNewOpen(true)}>
                Send Proforma
              </Button>
            )}
            <SendEmailDialog
              open={emailNewOpen}
              onClose={() => setEmailNewOpen(false)}
              entityType='shipments'
              entitySlug={shipment.slug}
              initialTemplateSlug='calculation-pro-forma'
            />
            {values.viewMode !== 'edit' ? (
              <Button variant='contained' onClick={() => setFieldValue('viewMode', 'edit')}>
                Edit finances
              </Button>
            ) : (
              <>
                <Button onClick={() => resetForm()}>Cancel</Button>
                <Button variant='contained' onClick={() => submitForm()} disabled={isSubmitting}>
                  Save finances
                </Button>
              </>
            )}
          </ActionBar>
          <ContentContainer>
            <SectionsContainer>
              <Section>
                <Typography variant='h6'>Shipment</Typography>
                <TwoColumnTable>
                  <THead></THead>
                  <THead>Date</THead>

                  <RHead>Expected handover</RHead>
                  <DateField name='expectedHandoverDate' estimatedValue={shipment.displayExpectedHandoverDate} />

                  <RHead>Handover</RHead>
                  <DateField name='handoverDate' estimatedValue={shipment.displayHandoverDate} />
                </TwoColumnTable>
              </Section>
              <Section>
                <Typography variant='h6'>Warehouse</Typography>
                <ThreeColumnTable>
                  <THead></THead>
                  <THead>Revenue</THead>
                  <THead>Cost</THead>

                  <RHead>Estimated</RHead>
                  <Currency name='estimatedRevenueWarehousing' editable={false} />
                  <Currency name='estimatedCostWarehousing' editable={false} />

                  <RHead>Actual</RHead>
                  <Currency name='revenueWarehousing' estimatedValue={values.estimatedRevenueWarehousing} />
                  <Currency name='costWarehousing' estimatedValue={values.estimatedCostWarehousing} />
                </ThreeColumnTable>
              </Section>

              <Section>
                <Typography variant='h6'>Shipment Costs</Typography>

                <ThreeColumnTable>
                  <THead></THead>
                  <THead>Revenue</THead>
                  <THead>Cost</THead>
                  {shipmentFinancesOne.map((row) => (
                    <OtherFinanceRow key={row.label} row={row} />
                  ))}
                </ThreeColumnTable>
                <TotalsDivider />
                <ThreeColumnTable>
                  <RHead>Total</RHead>
                  <Currency name='totalMiscRevenue' editable={false} />
                  <Currency name='totalMiscCost' editable={false} />
                </ThreeColumnTable>
              </Section>

              <Section>
                <Typography variant='h6'>Customs</Typography>
                <div>
                  <ThreeColumnTable>
                    <THead></THead>
                    <THead>Revenue / Cost</THead>
                    <THead>Estimated</THead>

                    <RHead>Customs Duties</RHead>
                    <Currency name='customsDuties' estimatedValue={values.estimatedCustomsDuties} />
                    <Currency name='estimatedCustomsDuties' editable={false} />

                    <RHead>Customs VAT</RHead>
                    <Currency name='customsVat' estimatedValue={values.estimatedCustomsVat} />
                    <Currency name='estimatedCustomsVat' editable={false} />
                  </ThreeColumnTable>
                  <TotalsDivider />
                  <ThreeColumnTable>
                    <RHead>Total</RHead>
                    <Currency name='totalCustomsDutiesAndVat' editable={false} />
                  </ThreeColumnTable>
                </div>
              </Section>

              <Section>
                <Typography variant='h6'>Container</Typography>
                <div>
                  <ThreeColumnTable>
                    <THead></THead>
                    <THead>Revenue</THead>
                    <THead>Cost</THead>

                    <RHead>Container Handling</RHead>
                    <Currency name='revenueContainerHandling' />
                    <Currency name='costContainerHandling' editable={false} />

                    <RHead>Storage</RHead>
                    <Currency name='revenueStorage' />
                    <Currency name='costStorage' editable={false} />

                    <RHead>Container Xray</RHead>
                    <Currency name='revenueContainerXray' />
                    <Currency name='costContainerXray' editable={false} />
                  </ThreeColumnTable>
                  <TotalsDivider />
                  <ThreeColumnTable>
                    <RHead>Total</RHead>
                    <Currency name='totalContainerRevenue' editable={false} />
                    <Currency name='totalContainerCost' editable={false} />
                  </ThreeColumnTable>
                </div>
              </Section>
            </SectionsContainer>

            <InfoCardsContainer>
              <InfoCard>
                <InfoCardTitle>Shipment</InfoCardTitle>
                <FieldsContainer $columns={2}>
                  <KeyValueField label='Discharge date' value={shipment.displayDischargeDate} />
                  <KeyValueField label='Customs Type' value={shipment.displayCustomsType} />
                  <KeyValueField label='Expected handover date' value={shipment.displayExpectedHandoverDate} />
                  <KeyValueField label='Handover date' value={shipment.displayHandoverDate} />
                  <KeyValueField label='Shipment value' value={asFormattedCurrency(shipment.shipmentValue, shipment.shipmentValueCurrency)} />
                  <KeyValueField label='Shipping cost' value={asFormattedCurrency(shipment.shippingCost, shipment.shipmentValueCurrency)} />
                  <KeyValueField label='Shipment currency' value={shipment.shipmentValueCurrency} />
                </FieldsContainer>
              </InfoCard>
              <InfoCard>
                <InfoCardTitle>{warehouseContactTitle}</InfoCardTitle>
                <FieldsContainer $columns={2}>
                  <KeyValueField label='Free warehouse days' value={warehouseContact?.freeWarehousingDays} />
                  <KeyValueField label='Cost warehousing per day' value={asFormattedCurrency(warehouseContact?.costWarehousingPerDay)} />
                  <KeyValueField label='Cost Shipment Discharge' value={asFormattedCurrency(warehouseContact?.costShipmentDischarge)} />
                </FieldsContainer>
              </InfoCard>
              <InfoCard>
                <InfoCardTitle>{rateCardTitle}</InfoCardTitle>
                <FieldsContainer $columns={2}>
                  <KeyValueField label='Three Pack Car Unload' value={asFormattedCurrency(rateCard?.threePackCarDischarge)} />
                  <KeyValueField label='Forklift' value={asFormattedCurrency(rateCard?.forklift)} />
                  <KeyValueField label='Standard Customs Clearance' value={asFormattedCurrency(rateCard?.standardCustomsClearance)} />
                  <KeyValueField label='Special Handling' value={asFormattedCurrency(rateCard?.specialHandling)} />
                  <KeyValueField label='Free Warehousing Days' value={rateCard?.freeWarehousingDays} />
                  <KeyValueField label='Cost Warehousing' value={asFormattedCurrency(rateCard?.costWarehousing)} />
                </FieldsContainer>
              </InfoCard>
              <InfoCard>
                <InfoCardTitle>{cutomerTitle}</InfoCardTitle>
                <FieldsContainer $columns={2}>
                  <KeyValueField label='Vat Warehousing' value={shipment.customer?.vatWarehousing.toString()} />
                  <KeyValueField label='Vat Revenue' value={shipment.customer?.vatRevenues.toString()} />
                </FieldsContainer>
              </InfoCard>
              <InfoCard>
                <InfoCardTitle>{shipment.shipmentType} (shipment type)</InfoCardTitle>
                <FieldsContainer $columns={3}>
                  <KeyValueField label='Handling in port' value={asFormattedCurrency(shipment.shipmentTypeDetails?.portHandlingCharge)} />
                  <KeyValueField label='VAT charges' value={`${shipment.shipmentTypeDetails?.vatChargePercent || '--'} %`} />
                  <KeyValueField label='Duties rate' value={`${shipment.shipmentTypeDetails?.dutiesRatePercent || '--'} %`} />
                </FieldsContainer>
              </InfoCard>
              <InfoCard>
                <InfoCardTitle>Container costs</InfoCardTitle>
                <FieldsContainer $columns={2}>
                  <KeyValueField label='X-Ray' value={asFormattedCurrency(shipment.container.costXray)} />
                  <KeyValueField label='Storage + Demurrage' value={asFormattedCurrency(shipment.container.costStoragePlusDemurrage)} />
                  <KeyValueField label='Cleaning' value={asFormattedCurrency(shipment.container.costCleaning)} />
                </FieldsContainer>
              </InfoCard>
            </InfoCardsContainer>
          </ContentContainer>
        </>
      )}
    </NiceFormik>
  )
}

interface OtherFinanceRowT {
  label: string
  cost?: string
  revenue?: string
}

const OtherFinanceRow: React.FC<{ row: OtherFinanceRowT }> = ({ row }) => (
  <>
    <RHead>{row.label}</RHead>
    {row.revenue ? <Currency name={row.revenue} /> : <EmptyCell />}
    {row.cost ? <Currency name={row.cost} /> : <EmptyCell />}
  </>
)

const financeDetailsFragment = graphql`
  fragment ShipmentFinanceDetails_shipment on Shipment {
    slug
    stage
    shipmentType
    displayCustomsType

    freeWarehousingDays
    costWarehousingPerDay
    estimatedCostWarehousing
    estimatedRevenueWarehousing

    costWarehousing
    revenueWarehousing

    estimatedCustomsDuties
    customsDuties
    estimatedCustomsVat
    customsVat

    costDischarge
    revenueDischarge

    costTransport
    revenueTransport

    costCustomsInspection
    revenueCustomsInspection

    costClimaCertificate
    revenueClimaCertificate

    costOpinion
    revenueOpinion

    costContainerXray
    revenueContainerXray

    costContainerHandling
    revenueContainerHandling

    costShipping
    revenueShipping

    costCleaning
    revenueCleaning

    costSpecialHandling
    revenueSpecialHandling

    costStorage
    revenueStorage

    costOther

    costForklift
    revenueForklift

    revenueCustomsClearance
    costCustomsClearance

    discount
    discountRemark
    receivedPayment

    totalCustomsDutiesAndVat
    totalMiscRevenue
    totalMiscCost
    totalContainerRevenue
    totalContainerCost
    totalCost
    totalRevenue
    displayExpectedHandoverDate
    displayHandoverDate
    displayDischargeDate

    shipmentValue
    shippingCost
    shipmentValueCurrency
    shipmentTypeDetails {
      portHandlingCharge
      vatChargePercent
      dutiesRatePercent
    }
    container {
      displayTerminalDate
      costXray
      costStoragePlusDemurrage
      costCleaning
      warehouseContact {
        slug
        name
        freeWarehousingDays
        costWarehousingPerDay
        costShipmentDischarge
      }
    }
    rateCardContact {
      slug
      name
      rateCard {
        threePackCarDischarge
        forklift
        standardCustomsClearance
        specialHandling
        freeWarehousingDays
        costWarehousing
      }
    }
    customer {
      slug
      vatWarehousing
      vatRevenues
    }
  }
`

const mutation = graphql`
  mutation ShipmentFinanceDetailsMutation($input: infoUpdateShipmentFinanceInput!) {
    infoUpdateShipmentFinance(input: $input) {
      clientMutationId
      shipment {
        ...ShipmentFinanceDetails_shipment
        ...ShipmentFinanceMetricCard_shipment
      }
    }
  }
`

const mutationValueKeys = [
  'slug',
  'costWarehousing',
  'revenueWarehousing',

  'customsDuties',
  'customsVat',

  'costDischarge',
  'revenueDischarge',

  'costForklift',
  'revenueForklift',

  'costCustomsInspection',
  'revenueCustomsInspection',

  'costOpinion',
  'revenueOpinion',

  'costClimaCertificate',
  'revenueClimaCertificate',

  'costCleaning',
  'revenueCleaning',

  'costSpecialHandling',
  'revenueSpecialHandling',

  'costShipping',
  'revenueShipping',

  'costTransport',
  'revenueTransport',

  'costContainerHandling',
  'revenueContainerHandling',

  'costStorage',
  'revenueStorage',

  'costContainerXray',
  'revenueContainerXray',
  'revenueCustomsClearance',
  'costCustomsClearance',

  'handoverDate',
  'expectedHandoverDate',

  // 'costOther',
  // 'discount',
  // 'discountRemark',
  // 'receivedPayment'
]

export default ShipmentFinanceDetails
