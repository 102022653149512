import { Typography, MenuItem, TextField } from '@material-ui/core'
import { graphql } from 'babel-plugin-relay/macro'
import { parse } from 'qs'
import React, { Suspense } from 'react'
import { useLazyLoadQuery } from 'react-relay/hooks'
import { useLocation } from 'react-router'
import styled from 'styled-components'
import ResponsiveGrid from '../../components/ResponsiveGrid'
import { isSmall } from '../../theme'
import { ItemType } from '../../viewTypes'
import { RelatedItemListQuery, RelatedItemListQueryVariables } from '../../__generated__/RelatedItemListQuery.graphql'
import ItemListFilters from './ItemListFilters'
import ItemListFooter from './ItemListFooter'
import useWorkspaceNavigate from '../../hooks/useWorkspaceNavigate'
import Timeline from '../../components/Timeline/Timeline'
import RelatedItemListContent from './RelatedItemListContent'
import { FeedItemListTypeEnum } from '../../__generated__/TimelineQuery.graphql'

interface Props {
  type: ItemType
  parentAbsoluteSlug?: string
  contactSlug?: string
  title?: string
  description?: string
  entity?: string
}

const Titlebar = styled.div`
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Sort = styled(TextField)`
  min-width: 200px;
`

const FiltersContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 8px;
`

const RelatedItemList: React.FC<Props> & { Skeleton: React.FC } = ({ type, parentAbsoluteSlug, contactSlug }) => {
  const location = useLocation()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {
    searchQuery,
    sortKey,
    perPage: perPageRaw,
    page: pageRaw,
    handoverDateStart,
    handoverDateEnd,
    voyageEtaStart,
    voyageEtaEnd,
    dischargeDateStart,
    dischargeDateEnd,
    ...queryVariables
  } = parse(location.search, {
    ignoreQueryPrefix: true,
  })

  const filters = Object.entries(queryVariables).map((entry) => ({ key: entry[0], values: entry[1] as string[] }))
  const page = Number.parseInt(pageRaw as string) || 1
  const perPage = Number.parseInt(perPageRaw as string) || 10
  const wsNavigate = useWorkspaceNavigate()
  const handleSortToggle = (key: string) => wsNavigate.relative({ sortKey: key })

  const variables: RelatedItemListQueryVariables = {
    type,
    parentAbsoluteSlug,
    contactSlug,
    searchQuery: `${searchQuery || ''}`,
    filters,
    sortKey: `${sortKey || ''}`,
    page,
    perPage,
    includeArchived: true,
    handoverDateStart: handoverDateStart ? handoverDateStart.toString() : undefined,
    handoverDateEnd: handoverDateEnd ? handoverDateEnd.toString() : undefined,
    voyageEtaStart: voyageEtaStart ? voyageEtaStart.toString() : undefined,
    voyageEtaEnd: voyageEtaEnd ? voyageEtaEnd.toString() : undefined,
    dischargeDateStart: dischargeDateStart ? dischargeDateStart.toString() : undefined,
    dischargeDateEnd: dischargeDateEnd ? dischargeDateEnd.toString() : undefined,
    requiredPagination: true,
  }
  const data = useLazyLoadQuery<RelatedItemListQuery>(query, variables)

  let timelineEntityType: FeedItemListTypeEnum | null = null
  switch (type) {
    case 'vessels':
    case 'voyages':
    case 'containers':
    case 'shipments':
      timelineEntityType = type
      break
    case 'internal_contacts':
    case 'external_contacts':
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      timelineEntityType = 'contacts'
      break
  }

  return (
    <>
      <ResponsiveGrid
        type='show-layout'
        highlight={[
          <RelatedItemListContent key='item-list' data={data.itemList} type={type} contactSlug={contactSlug as string} />,
          <ItemListFooter key='footer' data={data.itemList} onPageChange={(page) => wsNavigate.relative({ page: page })} />,
        ]}
        right={
          isSmall()
            ? []
            : [
                <Titlebar key='title'>
                  <Typography variant='body1'>1-{data.itemList.totalCount}</Typography>
                  <Sort
                    select
                    size='small'
                    variant='outlined'
                    value={variables.sortKey}
                    label='Sort By'
                    onChange={(e) => handleSortToggle(e.target.value)}
                  >
                    {data.itemList.sortKeys.map((option) => (
                      <MenuItem key={option.key} value={option.key}>
                        {option.title}
                      </MenuItem>
                    ))}
                  </Sort>
                </Titlebar>,
                <FiltersContainer key='filters'>
                  <Suspense fallback={<>Loading...</>}>
                    <ItemListFilters variables={variables} listType={type} contactSlug={contactSlug} />
                  </Suspense>
                </FiltersContainer>,
                <React.Fragment key='timeline'>
                  {timelineEntityType && (
                    <Suspense key='timeline' fallback={<Timeline.Skeleton />}>
                      <Timeline type={timelineEntityType} parentAbsoluteSlug={parentAbsoluteSlug} />
                    </Suspense>
                  )}
                </React.Fragment>,
              ]
        }
      />
    </>
  )
}

const query = graphql`
  query RelatedItemListQuery(
    $searchQuery: String
    $type: ListItemTypeEnum
    $parentAbsoluteSlug: String
    $contactSlug: String
    $filters: [FilterInput!]
    $includeArchived: Boolean
    $sortKey: String
    $page: Int
    $perPage: Int
    $handoverDateStart: Date
    $handoverDateEnd: Date
    $voyageEtaStart: Date
    $voyageEtaEnd: Date
    $dischargeDateStart: Date
    $dischargeDateEnd: Date
    $requiredPagination: Boolean
  ) {
    itemList(
      searchQuery: $searchQuery
      type: $type
      parentAbsoluteSlug: $parentAbsoluteSlug
      contactSlug: $contactSlug
      filters: $filters
      includeArchived: $includeArchived
      sortKey: $sortKey
      page: $page
      perPage: $perPage
      handoverDateStart: $handoverDateStart
      handoverDateEnd: $handoverDateEnd
      voyageEtaStart: $voyageEtaStart
      voyageEtaEnd: $voyageEtaEnd
      dischargeDateStart: $dischargeDateStart
      dischargeDateEnd: $dischargeDateEnd
      requiredPagination: $requiredPagination
    ) {
      ...ItemListFooter_data
      ...RelatedItemListContent_data
      sortKeys {
        key
        title
      }
      totalCount
      downloadToken
    }
  }
`

RelatedItemList.Skeleton = () => <>Loading...</>

export default RelatedItemList
