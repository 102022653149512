import { Button as MuiButton, ButtonProps, CircularProgress } from '@material-ui/core'
import { ErrorOutline } from '@material-ui/icons'
import React, { unstable_useTransition } from 'react'
import styled from 'styled-components'

interface Props {
  error?: boolean
  loading?: boolean
}

const StyledButton = styled(MuiButton)`
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  background-color: ${(props) => props.theme.palette.primary.main};
  text-transform: unset;
  border-radius: 8px;
  ${(props) =>
    props.variant === 'outlined'
      ? `
    background-color: inherit;
    border-color: ${props.theme.palette.divider};
    color: ${props.theme.palette.primary.main};
  `
      : props.variant === 'text'
      ? `
    background-color: inherit;
    color: ${props.theme.palette.primary.main};
  `
      : `
    background-color: ${props.theme.palette.primary.main};
    color: ${props.theme.palette.primary.contrastText};
  `}

  margin: 0 4px;
  text-transform: unset;
  padding-right: 16px;
  padding-left: 16px;

  &:hover {
    ${(props) =>
      props.variant === 'outlined'
        ? ``
        : props.variant === 'text'
        ? `
      background-color: rgb(0, 128, 246, 0.04);
      `
        : `
      background-color: #006fd6;
    `}
  }
`

const Button: React.FC<ButtonProps & Props> = ({ error, loading, onClick, ...props }) => {
  const [startTransition] = unstable_useTransition()
  return (
    <StyledButton
      // size={isSmallScreen() ? 'small' : 'medium'}
      variant='text'
      disableElevation
      startIcon={loading ? <CircularProgress size={18} /> : error ? <ErrorOutline /> : props.startIcon}
      {...props}
      onClick={(e) => {
        startTransition(() => {
          onClick && onClick(e)
        })
      }}
    />
  )
}

export default Button
