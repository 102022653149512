import { useState, useCallback } from 'react'
import { ApiError, ApiRequest, Data } from '../api/ApiTypes'
import server, { unsetToken } from '../api/server'
import { useNavigate } from 'react-router'

// use lifecycle
type LogoutState = Data<unknown>
interface LogoutHelpers {
  doLogout: () => void
}
interface Params {
  reloadSession: () => void
  onChange?: (state: LogoutState) => void
}

export const initialState: LogoutState = {
  data: null,
  error: null,
  loading: false,
}

const useLogout = ({ reloadSession, onChange }: Params): [LogoutState, LogoutHelpers] => {
  const [state, setState] = useState(initialState)
  const navigate = useNavigate()

  const doLogout = useCallback(() => {
    const changeState = (state: LogoutState): void => {
      setState(state)
      if (onChange) onChange(state)
    }
    const apiCall: ApiRequest<unknown, null> = {
      method: 'DELETE',
      path: 'users/sign_out',
      onSuccess: () => {
        changeState({ data: null, error: null, loading: false })
        unsetToken()
        reloadSession()
        navigate('/')
      },
      onFail: (error: ApiError) => changeState({ data: null, error, loading: false }),
    }
    setState({ ...initialState, loading: true })
    server(apiCall)
  }, [reloadSession, setState, onChange])

  return [state, { doLogout }]
}
export default useLogout
