import { AppBar, IconButton, List, ListItem, MenuItem, Toolbar, Menu, Typography } from '@material-ui/core'
import { ArrowDropDown, Menu as MenuIcon } from '@material-ui/icons'
import React, { useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import OdysseyLogo from '../assets/odyssey-logo.svg'
import ProfileMenu from '../components/ProfileMenu'
import { isSmall, isMedium, media } from '../theme'
import Searchbar from './Searchbar'
import { NavItemT } from '../layouts/Internal'

interface Props {
  navItems: NavItemT[]
  subNavItems?: NavItemT[]
  thirdNavItems?: NavItemT[]
  activeNavLabel?: string
  activeSubNavLabel?: string
  activeThirdNavLabel?: string
  workspace: string
  showSearch?: boolean
}

interface NavLinkProps {
  active?: 'active'
}

const NavLinkContainer = styled.div`
  padding: 6px 8px;
  margin: 0 8px;
`

const NavLink = styled(Link)<NavLinkProps>`
  color: ${(props) => (props.active ? props.theme.palette.primary.main : props.theme.palette.type === 'dark' ? '#fafafa' : '#3a3a3a')};
  padding: 4px 16px;
  font-weight: ${(props) => (props.active ? 'bold' : '400')};
  text-decoration: unset;
  margin-right: 8px;
  transition-duration: 100ms;

  &:hover {
    color: ${(props) => props.theme.palette.primary.main};
  }
`

const NavText = styled(Typography)<NavLinkProps>`
  cursor: pointer;
  color: ${(props) => (props.active ? props.theme.palette.primary.main : props.theme.palette.type === 'dark' ? '#fafafa' : '#3a3a3a')};
  padding: 4px 16px;
  font-weight: ${(props) => (props.active ? 'bold' : '400')};
  text-decoration: unset;
  margin-right: 8px;
  transition-duration: 100ms;

  &:hover {
    color: ${(props) => props.theme.palette.primary.main};
  }
`

const SubNavLink = styled(Link)<NavLinkProps>`
  color: ${(props) => (props.active ? props.theme.palette.primary.main : props.theme.palette.type === 'dark' ? '#fafafa' : '#3a3a3a')};
  padding: 4px 16px;
  font-weight: ${(props) => (props.active ? 'bold' : '400')};
  text-decoration: unset;
  margin-right: 8px;
  transition-duration: 100ms;

  &:hover {
    color: ${(props) => props.theme.palette.primary.main};
  }
`
interface StyledAppbarProps {
  subnav?: 'subnav'
}

const StyledAppBar = styled(AppBar)<StyledAppbarProps>`
  background-color: ${(props) => props.theme.palette.background.paper};
  color: ${(props) => props.theme.palette.getContrastText(props.theme.palette.background.paper)};
  border-bottom: ${(props) => (props.subnav ? `unset` : `1px solid ${props.theme.palette.divider}`)};
`

const GrowingDiv = styled.div`
  flex-grow: 1;
`

const LogoImage = styled.img`
  margin-right: 16px;
  height: 36px;

  ${media.small`
    height: 20px;
  `}
`
const SubNavToolbar = styled(Toolbar)`
  background-color: ${(props) => props.theme.customPalette.background.subNav};
`
const ThirdNavToolbar = styled(Toolbar)`
  background-color: ${(props) => props.theme.customPalette.background.subNav};
  min-height: 22px;
`
const SubNavContent = styled.div`
  width: 100%;
  overflow-x: auto;
  display: flex;
  align-items: center;
`

const MobileNavContainer = styled.div`
  /*
  position: absolute;
  top: 0;
  z-index: 10;
  max-width: 500px;
  margin-top: 56px;
  background: #fff;
  border: 1px solid #eee;
 */
`

const Appbar: React.FC<Props> = ({
  navItems,
  subNavItems,
  thirdNavItems,
  activeNavLabel,
  activeSubNavLabel,
  activeThirdNavLabel,
  workspace,
}) => {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const withWorkspace = (path: string) => `/${workspace}/${path.replace(/^\//, '')}`

  const maxItems = isSmall() ? 0 : Math.floor(window.innerWidth / 175)
  const hasMenu = isSmall() || isMedium() || navItems.length > maxItems

  const menu = () =>
    hasMenu ? (
      <IconButton edge='start' onClick={() => setOpen(!open)}>
        <MenuIcon />
      </IconButton>
    ) : null

  const menuDrawer = () =>
    hasMenu ? (
      <MobileNavContainer>
        <List>
          {navItems.slice(maxItems, 100).map((navItem) => (
            <ListItem
              button
              onClick={() => {
                navItem.path && navigate(withWorkspace(navItem.path))
                setOpen(false)
              }}
              key={navItem.label}
              selected={navItem.label === activeNavLabel}
            >
              {navItem.label}
            </ListItem>
          ))}
        </List>
      </MobileNavContainer>
    ) : null

  const nav = () => {
    return navItems.slice(0, maxItems).map((navItem) => <NavItem key={navItem.label} navItem={navItem} />)
  }

  const NavItem = ({ navItem }: { navItem: NavItemT }) => {
    const [openSubMenu, setOpenSubMenu] = useState(false)
    const subMenuRef = useRef(null)
    return (
      <>
        {navItem.subMenus?.length ? (
          <>
            {navItem.hasNavigation ? (
              <>
                <NavLink
                  key={navItem.label}
                  ref={subMenuRef}
                  to={withWorkspace(navItem.path)}
                  active={navItem.label === activeNavLabel ? 'active' : undefined}
                >
                  {navItem.label}
                </NavLink>
                <IconButton edge='start' onClick={() => setOpenSubMenu(!openSubMenu)}>
                  <ArrowDropDown />
                </IconButton>
              </>
            ) : (
              <>
                <NavText as='span' key={navItem.label} ref={subMenuRef} active={navItem.label === activeNavLabel ? 'active' : undefined}>
                  {navItem.label}
                </NavText>
                <IconButton edge='start' onClick={() => setOpenSubMenu(!openSubMenu)}>
                  <ArrowDropDown />
                </IconButton>
              </>
            )}
            <Menu
              id='nav-sub-menu'
              anchorEl={subMenuRef.current as HTMLDivElement | null}
              keepMounted
              open={openSubMenu}
              onClose={() => setOpenSubMenu(false)}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
              {navItem.subMenus.map((menu) => (
                <MenuItem key={menu.label} selected={menu.label === activeNavLabel}>
                  <NavLink to={withWorkspace(menu.path)}>{menu.label}</NavLink>
                </MenuItem>
              ))}
            </Menu>
          </>
        ) : (
          <NavLink key={navItem.label} to={withWorkspace(navItem.path)} active={navItem.label === activeNavLabel ? 'active' : undefined}>
            {navItem.label}
          </NavLink>
        )}
      </>
    )
  }

  const subnav = () =>
    subNavItems &&
    subNavItems.length > 0 && (
      <SubNavToolbar variant='dense'>
        <SubNavContent>
          {subNavItems.map((navItem) => (
            <SubNavLink
              key={navItem.label}
              to={withWorkspace(navItem.path)}
              active={navItem.label === activeSubNavLabel ? 'active' : undefined}
            >
              {navItem.label}
            </SubNavLink>
          ))}
        </SubNavContent>
      </SubNavToolbar>
    )

  const thirdNav = () =>
    thirdNavItems &&
    thirdNavItems.length > 0 && (
      <ThirdNavToolbar variant='dense'>
        <SubNavContent>
          {thirdNavItems.map((navItem) => (
            <SubNavLink
              key={navItem.label}
              to={withWorkspace(navItem.path)}
              active={navItem.label === activeThirdNavLabel ? 'active' : undefined}
            >
              {navItem.label}
            </SubNavLink>
          ))}
        </SubNavContent>
      </ThirdNavToolbar>
    )

  return (
    <>
      <StyledAppBar elevation={0} subnav={activeSubNavLabel !== '' ? 'subnav' : undefined}>
        <Toolbar>
          {menu()}
          <Link to='/'>
            <LogoImage src={OdysseyLogo} />
          </Link>
          {!isSmall() && <NavLinkContainer>{nav()}</NavLinkContainer>}
          <GrowingDiv />
          <Searchbar />
          <ProfileMenu />
        </Toolbar>
        {subnav()}
        {thirdNav()}
        {open && menuDrawer()}
      </StyledAppBar>
    </>
  )
}

export default Appbar
