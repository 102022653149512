import React, { useState } from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import styled from 'styled-components'
import {
  VoyageContainerReleaseWizard_voyage,
  VoyageContainerReleaseWizard_voyage$key,
} from '../__generated__/VoyageContainerReleaseWizard_voyage.graphql'
import { useFragment } from 'react-relay/hooks'
import {
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Step,
  StepButton,
  Stepper,
  TextField,
  Typography,
} from '@material-ui/core'
import useNiceMutation from '../mutations/useNiceMutation'
import { Alert } from '@material-ui/lab'

import Button from './Button'
import NiceFormik from './Form/NiceFormik'
import SendEmailDialog from './Email/SendEmailDialog'
import ItemImage from './ItemImage'
import { FieldArray } from 'formik'
import StickyNote from './StickyNote'
import KeyValueField from './KeyValueField'
import { KeyFieldsContainer } from './ShowPageListItem'
import { VoyageContainerReleaseWizardUpdateBookingMutation } from '../__generated__/VoyageContainerReleaseWizardUpdateBookingMutation.graphql'
import { VoyageContainerReleaseWizardReleaseContainerMutation } from '../__generated__/VoyageContainerReleaseWizardReleaseContainerMutation.graphql'
import FileCreationDialog from './File/FileCreationDialog'
import EntityAttachment from './File/EntityAttachment'
import EntityAttachmentListPreviewer from './File/EntityAttachmentListPreviewer'
import LoadingDots from './LoadingDots'
import VoyageReleaseRequestEmail from './VoyageReleaseRequestEmail'

interface Props {
  slug: string
  voyage: VoyageContainerReleaseWizard_voyage$key
  open: boolean
  onClose: () => void
}

const ListItemTitle = styled(Typography).attrs({ variant: 'h6' })`
  margin-right: 16px;
`

const NoDataText = styled(Typography).attrs({ variant: 'h6' })`
  color: ${(props) => props.theme.customPalette.text.noData};
  text-align: center;
  padding: 16px;
  font-weight: 400;
`
const DialogContenStyle = {
  padding: '2px 24px',
}

const steps = ['Update booking number', 'Send release request', 'Release containers', 'Generate Docs']

const VoyageContainerReleaseWizard: React.FC<Props> = ({ slug, voyage: data, open, onClose }) => {
  const voyage = useFragment(fragment, data)
  const completed = [
    voyage.confirmedContainers.length === 0,
    true,
    voyage.releasableContainers.length === 0,
    voyage.releaseConfirmedContainers.length === 0,
  ]

  const [activeStep, setActiveStep] = React.useState(0)

  return (
    <>
      <Dialog open={open} maxWidth='md' onClose={onClose} scroll='paper' style={{ margin: 'auto' }}>
        <Stepper nonLinear activeStep={activeStep}>
          {steps.map((label, index) => {
            return (
              <Step key={label}>
                <StepButton onClick={() => setActiveStep(index)} completed={completed[index]}>
                  {label}
                </StepButton>
              </Step>
            )
          })}
        </Stepper>
        {activeStep === 0 && (
          <UpdateBookingContent slug={slug} voyage={voyage} activeStep={activeStep} setActiveStep={setActiveStep} onClose={onClose} />
        )}
        {activeStep === 1 && (
          <SendEmailContent slug={slug} voyage={voyage} activeStep={activeStep} setActiveStep={setActiveStep} onClose={onClose} />
        )}
        {activeStep === 2 && (
          <ReleaseContainerContent slug={slug} voyage={voyage} activeStep={activeStep} setActiveStep={setActiveStep} onClose={onClose} />
        )}
        {activeStep === 3 && (
          <GenerateDocContent slug={slug} voyage={voyage} activeStep={activeStep} setActiveStep={setActiveStep} onClose={onClose} />
        )}
      </Dialog>
    </>
  )
}

interface StepContentProps {
  slug: string
  voyage: VoyageContainerReleaseWizard_voyage
  activeStep: number
  setActiveStep: (value: React.SetStateAction<number>) => void
  onClose: () => void
}

const UpdateBookingContent: React.FC<StepContentProps> = ({ voyage, activeStep, setActiveStep, onClose }) => {
  const [updateBookingCommit, updateBookingIsInFlight, updateBookingMutationError] =
    useNiceMutation<VoyageContainerReleaseWizardUpdateBookingMutation>(updateBookingMutation)
  const bookingInfoIntialValue: { containerSlug: string; bookingNumber: string }[] = []
  voyage.confirmedContainers.map((container) => {
    if (container.bookingNumber) {
      bookingInfoIntialValue.push({ containerSlug: container.slug, bookingNumber: container.bookingNumber as string })
    }
  })

  const [bookingInfo, setBookingInfo] = useState<{ containerSlug: string; bookingNumber: string }[]>(bookingInfoIntialValue)

  const removeContainerFromList = (containerSlug: string) => {
    setBookingInfo(bookingInfo.filter((cntr) => cntr.containerSlug !== containerSlug))
  }
  const [searchQuery, setSearchQuery] = useState('')
  return (
    <NiceFormik
      initialValues={{ voyageSlug: voyage.slug, containerInfo: bookingInfo }}
      onSubmit={(_, actions) => {
        updateBookingCommit({
          variables: {
            input: { voyageSlug: voyage.slug, containerInfo: bookingInfo.filter((cntr) => cntr.containerSlug !== '') },
          },
          onCompleted: (_, errors) => {
            actions.setSubmitting(false)

            if (!errors) {
              setBookingInfo([])
              setActiveStep((prev) => prev + 1)
            }
          },
        })
      }}
    >
      {({ submitForm, dirty, isSubmitting }) => (
        <>
          {updateBookingMutationError && <Alert severity='error'>{updateBookingMutationError}</Alert>}
          <DialogContent dividers={true} style={DialogContenStyle}>
            {voyage.confirmedContainers.length === 0 && <NoDataText>No releasable containers</NoDataText>}
            <List>
              {voyage.confirmedContainers.length !== 0 && (
                <TextField
                  size='small'
                  variant='standard'
                  value={searchQuery}
                  label='Search'
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value as string)}
                />
              )}
              {voyage.confirmedContainers
                ?.filter((item) => item.containerItem.title.toLowerCase().includes(searchQuery.toLowerCase()))
                .map((container) => {
                  const item = container?.containerItem
                  const bookingNumber = bookingInfo.filter((cntr) => cntr.containerSlug === container.slug)[0]?.bookingNumber
                  const idx = bookingInfo.findIndex((val) => val.containerSlug === container.slug)
                  if (idx !== -1 && (bookingNumber === undefined || bookingNumber === '')) {
                    removeContainerFromList(container.slug)
                  }
                  return (
                    <ListItem key={container.slug}>
                      <ListItemAvatar style={{ alignSelf: 'end', paddingTop: '8px' }}>
                        {item.image ? <Avatar variant='rounded' alt={item.image.alt || ''} src={item?.image?.url as string} /> : <></>}
                      </ListItemAvatar>
                      <ListItemText primary={item.title} secondary={`Warehouse: ${container.warehouseContact?.slug || '--'}`} />
                      <TextField
                        size='small'
                        placeholder='Booking Number'
                        variant='outlined'
                        label='Booking Number'
                        value={bookingNumber}
                        style={{ marginLeft: '8px' }}
                        onChange={(e) => {
                          if (idx !== -1) {
                            removeContainerFromList(container.slug)
                          }

                          setBookingInfo((info) => {
                            return [...info, { containerSlug: container.slug, bookingNumber: e.target.value }].filter(
                              (cntr) => cntr.containerSlug !== ''
                            )
                          })
                        }}
                      />
                    </ListItem>
                  )
                })}
            </List>
          </DialogContent>
          <DialogActions>
            <div style={{ width: '100%', display: 'flex' }}>
              <div style={{ flexGrow: 1 }}>
                <Button variant='outlined' onClick={onClose}>
                  Close
                </Button>
              </div>
              <div>
                <Button disabled={activeStep === 0} onClick={() => setActiveStep((prev) => prev - 1)}>
                  Back
                </Button>
                {voyage.confirmedContainers.length !== 0 ? (
                  <Button variant='contained' disabled={isSubmitting || !dirty} onClick={submitForm}>
                    {updateBookingIsInFlight ? <LoadingDots /> : 'Update'}
                  </Button>
                ) : (
                  <Button variant='contained' onClick={() => setActiveStep((prev) => prev + 1)}>
                    Next
                  </Button>
                )}
              </div>
            </div>
          </DialogActions>
        </>
      )}
    </NiceFormik>
  )
}

const SendEmailContent: React.FC<StepContentProps> = ({ slug, activeStep, setActiveStep, onClose, voyage }) => {
  return (
    <>
      <Divider />
      <VoyageReleaseRequestEmail voyage={voyage} slug={slug} emailSend={() => setActiveStep((prev) => prev + 1)} />
      <Divider />
      <DialogActions>
        <div style={{ width: '100%', display: 'flex' }}>
          <div style={{ flexGrow: 1 }}>
            <Button variant='outlined' onClick={onClose}>
              Close
            </Button>
          </div>
          <div>
            <Button disabled={activeStep === 0} onClick={() => setActiveStep((prev) => prev - 1)}>
              Back
            </Button>
            <Button variant='contained' onClick={() => setActiveStep((prev) => prev + 1)}>
              Skip
            </Button>
          </div>
        </div>
      </DialogActions>
    </>
  )
}

interface RelaseContainerInfo {
  containerSlug: string
  pickupReference: string
  pickupLocationSlug: string
  pickupDate: string
  turninReference: string
  turninLocationSlug: string
  latestTurninDate: string
  latestPickupDate: string
  containerItem?: any
  bookingNumber?: string
  index?: number
}

const ReleaseContainerContent: React.FC<StepContentProps> = ({ voyage, activeStep, setActiveStep, onClose }) => {
  const [releaseContainerCommit, releaseContainerIsInFlight, releaseContainerMutationError] =
    useNiceMutation<VoyageContainerReleaseWizardReleaseContainerMutation>(releaseContainerMutation)
  const [releaseInfo, setReleaseInfo] = useState<RelaseContainerInfo[]>(
    voyage.releasableContainers.map((ctr, index) => {
      return {
        containerSlug: ctr.slug,
        pickupReference: ctr.pickupReference,
        pickupLocationSlug: ctr.pickupLocationSlug,
        pickupDate: ctr.pickupDate,
        turninReference: ctr.turninReference,
        turninLocationSlug: ctr.turninLocationSlug,
        latestTurninDate: ctr.latestTurninDate,
        latestPickupDate: ctr.latestPickupDate,
        containerItem: ctr.containerItem,
        bookingNumber: ctr.bookingNumber,
        index: index,
      }
    }) as RelaseContainerInfo[]
  )

  const [submitReleaseInfo, setSubmitReleaseInfo] = useState(
    voyage.releasableContainers.map((ctr) => {
      return {
        containerSlug: ctr.slug,
        pickupReference: ctr.pickupReference,
        pickupLocationSlug: ctr.pickupLocationSlug,
        pickupDate: ctr.pickupDate,
        turninReference: ctr.turninReference,
        turninLocationSlug: ctr.turninLocationSlug,
        latestTurninDate: ctr.latestTurninDate,
        latestPickupDate: ctr.latestPickupDate,
      }
    })
  )

  function hasAllFields(container: any) {
    if (!container) return false

    return Object.keys(container)
      .map((key) => container[key] && container[key] !== '')
      .every((val) => val)
  }

  const removeContainerFromList = (containerSlug: string) => {
    setReleaseInfo(releaseInfo.filter((cntr) => cntr.containerSlug !== containerSlug))
  }

  type FieldTypeEnum =
    | 'containerSlug'
    | 'pickupReference'
    | 'pickupLocationSlug'
    | 'pickupDate'
    | 'turninReference'
    | 'turninLocationSlug'
    | 'latestTurninDate'
    | 'latestPickupDate'
  const handleOnChange = (idx: number, fieldname: FieldTypeEnum, val: string, containerSlug: string) => {
    if (idx >= 0) {
      const oldVal = releaseInfo[idx]
      const submitOldVal = submitReleaseInfo[idx]
      oldVal[fieldname] = val
      submitOldVal[fieldname] = val
      removeContainerFromList(containerSlug)
      setReleaseInfo((info) => {
        return [...info, oldVal].filter((cntr) => cntr.containerSlug !== '')
      })
      setSubmitReleaseInfo((info) => {
        return [...info].filter((cntr) => cntr.containerSlug !== '')
      })
    } else {
      setReleaseInfo((info) => {
        const tmpVal = {
          containerSlug: containerSlug,
          pickupReference: fieldname === 'pickupReference' ? val : '',
          pickupLocationSlug: fieldname === 'pickupLocationSlug' ? val : '',
          pickupDate: fieldname === 'pickupDate' ? val : '',
          turninReference: fieldname === 'turninReference' ? val : '',
          turninLocationSlug: fieldname === 'turninLocationSlug' ? val : '',
          latestTurninDate: fieldname === 'latestTurninDate' ? val : '',
          latestPickupDate: fieldname === 'latestPickupDate' ? val : '',
        }
        tmpVal.containerSlug = containerSlug
        return [...info, tmpVal].filter((cntr) => cntr.containerSlug !== '')
      })
    }
  }

  return (
    <>
      <NiceFormik
        initialValues={{ voyageSlug: voyage.slug, containerReleaseInfo: releaseInfo }}
        onSubmit={(_, actions) => {
          releaseContainerCommit({
            variables: {
              input: { voyageSlug: voyage.slug, containerReleaseInfo: submitReleaseInfo },
            },
            onCompleted: (_, errors) => {
              actions.setSubmitting(false)

              if (!errors) {
                setReleaseInfo([])
                setActiveStep((prev) => prev + 1)
              }
            },
          })
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <>
            {releaseContainerMutationError && <Alert severity='error'>{releaseContainerMutationError}</Alert>}
            <DialogContent dividers>
              {voyage.releasableContainers.length === 0 && <NoDataText>No releasable containers</NoDataText>}
              <List>
                <FieldArray
                  name='containerSlugs'
                  render={() => (
                    <>
                      {releaseInfo
                        .sort((v1, v2) => (v1.index as number) - (v2.index as number))
                        .map((container) => {
                          const item = container?.containerItem
                          const idx = releaseInfo.findIndex((val) => val.containerSlug === container.containerSlug)
                          const selected = hasAllFields(releaseInfo[idx])

                          return (
                            <ListItem key={container.containerSlug}>
                              <ListItemAvatar style={{ alignSelf: 'end', paddingTop: '8px' }}>
                                {item.image ? <ItemImage variant='small' data={item.image} selected={selected} /> : <></>}
                              </ListItemAvatar>
                              <div style={{ width: '100%' }}>
                                <ListItemTitle>{item.title}</ListItemTitle>
                                {item.stickyNoteContent && <StickyNote note={item.stickyNoteContent} />}

                                <KeyFieldsContainer>
                                  <KeyValueField key={'bookingNumber'} label={'Booking Number'} value={container.bookingNumber} />

                                  <TextField
                                    placeholder='Pickup Reference'
                                    variant='outlined'
                                    label='Pickup Reference'
                                    value={container.pickupReference}
                                    onChange={(e) => {
                                      handleOnChange(idx, 'pickupReference', e.target.value, container.containerSlug)
                                    }}
                                  />
                                  <TextField
                                    placeholder='Pickup Location'
                                    variant='outlined'
                                    label='Pickup Location'
                                    select
                                    value={container.pickupLocationSlug}
                                    onChange={(e) => {
                                      handleOnChange(idx, 'pickupLocationSlug', e.target.value, container.containerSlug)
                                    }}
                                  >
                                    {voyage.terminals.map((val) => (
                                      <MenuItem key={val} value={val}>
                                        {val}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                  <TextField
                                    placeholder='Pickup Date'
                                    variant='outlined'
                                    label='Pickup Date'
                                    type='date'
                                    value={container.pickupDate}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) => {
                                      handleOnChange(idx, 'pickupDate', e.target.value, container.containerSlug)
                                    }}
                                  />
                                  <TextField
                                    placeholder='Latest Pickup Date'
                                    variant='outlined'
                                    label='Latest Pickup Date'
                                    type='date'
                                    value={container.latestPickupDate}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) => {
                                      handleOnChange(idx, 'latestPickupDate', e.target.value, container.containerSlug)
                                    }}
                                  />
                                  <TextField
                                    placeholder='Turnin Reference'
                                    variant='outlined'
                                    label='Turnin Reference'
                                    value={container.turninReference}
                                    onChange={(e) => {
                                      handleOnChange(idx, 'turninReference', e.target.value, container.containerSlug)
                                    }}
                                  />
                                  <TextField
                                    placeholder='Turnin Location'
                                    variant='outlined'
                                    label='Turnin Location'
                                    select
                                    value={container.turninLocationSlug}
                                    onChange={(e) => {
                                      handleOnChange(idx, 'turninLocationSlug', e.target.value, container.containerSlug)
                                    }}
                                  >
                                    {voyage.depots.map((val) => (
                                      <MenuItem key={val} value={val}>
                                        {val}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                  <TextField
                                    placeholder='Latest Turnin Date'
                                    variant='outlined'
                                    label='Latest Turnin Date'
                                    type='date'
                                    InputLabelProps={{ shrink: true }}
                                    value={container.latestTurninDate}
                                    onChange={(e) => {
                                      handleOnChange(idx, 'latestTurninDate', e.target.value, container.containerSlug)
                                    }}
                                  />
                                </KeyFieldsContainer>
                              </div>
                            </ListItem>
                          )
                        })}
                    </>
                  )}
                />
              </List>
            </DialogContent>
            <DialogActions>
              <div style={{ width: '100%', display: 'flex' }}>
                <div style={{ flexGrow: 1 }}>
                  <Button variant='outlined' onClick={onClose}>
                    Close
                  </Button>
                </div>
                <div>
                  <Button disabled={activeStep === 0} onClick={() => setActiveStep((prev) => prev - 1)}>
                    Back
                  </Button>
                  <Button variant='contained' disabled={isSubmitting} onClick={submitForm}>
                    {releaseContainerIsInFlight ? <LoadingDots /> : 'Release'}
                  </Button>
                </div>
              </div>
            </DialogActions>
          </>
        )}
      </NiceFormik>
    </>
  )
}

const GenerateDocContent: React.FC<StepContentProps> = ({ voyage, activeStep, setActiveStep, onClose }) => {
  function hasAllFields(container: any) {
    return (
      container &&
      container.containerSlug !== '' &&
      container.pickupReference !== '' &&
      container.pickupLocationSlug !== '' &&
      container.pickupDate !== '' &&
      container.turninReference !== '' &&
      container.turninLocationSlug !== '' &&
      container.latestTurninDate !== '' &&
      container.latestPickupDate !== ''
    )
  }

  const [showPickupEmailDialog, setShowPickupEmailDialog] = useState('')
  const [showGeneratePickupDocDialog, setShowGeneratePickupDocDialog] = useState('')
  const [previewedAttachmentSlug, setPreviewedAttachmentSlug] = useState<null | string>(null)

  return (
    <>
      <DialogContent dividers={true}>
        {voyage.releaseConfirmedContainers.length === 0 && <NoDataText>No releasable containers</NoDataText>}
        <List>
          {voyage.releaseConfirmedContainers
            .filter((cntr) => hasAllFields(cntr))
            .map((container) => {
              const item = container.containerItem
              const billsFolderNode = container.folders?.edges?.filter((cntr) => cntr?.node?.name === 'Bills')[0]?.node
              return (
                <>
                  <ListItem key={container.slug}>
                    <ListItemAvatar style={{ alignSelf: 'end', paddingTop: '8px' }}>
                      {item.image ? <Avatar variant='rounded' alt={item.image.alt || ''} src={item?.image?.url as string} /> : <></>}
                    </ListItemAvatar>
                    <ListItemText
                      primary={item.title}
                      secondary={
                        container.pickupOrders &&
                        container.pickupOrders.length > 0 &&
                        container.pickupOrders.map((pickupOrder, idx) => (
                          <EntityAttachment
                            key={idx}
                            variant='chip'
                            attachment={pickupOrder}
                            onPreview={() => setPreviewedAttachmentSlug(pickupOrder.attachmentSlug)}
                          />
                        ))
                      }
                    ></ListItemText>
                    <div>
                      {billsFolderNode !== null ? (
                        <Button variant='outlined' onClick={() => setShowGeneratePickupDocDialog(container.slug)}>
                          Generate Pickup Doc
                        </Button>
                      ) : (
                        <NoDataText>No Folder</NoDataText>
                      )}
                      <Button variant='outlined' onClick={() => setShowPickupEmailDialog(container.slug)}>
                        Send Mail
                      </Button>
                    </div>
                  </ListItem>
                  <SendEmailDialog
                    entityType='containers'
                    entitySlug={container.slug}
                    open={showPickupEmailDialog === container.slug}
                    onClose={() => setShowPickupEmailDialog('')}
                    initialTemplateSlug='03-3-discharge-order'
                  ></SendEmailDialog>
                  {billsFolderNode !== null && billsFolderNode !== undefined && (
                    <FileCreationDialog
                      folder={billsFolderNode}
                      entityType='containers'
                      entitySlug={container.slug}
                      open={showGeneratePickupDocDialog === container.slug}
                      onClose={() => setShowGeneratePickupDocDialog('')}
                      folderType='documents'
                    />
                  )}
                  {container.pickupOrders && (
                    <EntityAttachmentListPreviewer
                      attachments={container.pickupOrders}
                      intialPreviewedAttachmentSlug={previewedAttachmentSlug}
                      open={!!container.pickupOrders?.map((po) => po.attachmentSlug).includes(previewedAttachmentSlug || '')}
                      onClose={() => setPreviewedAttachmentSlug(null)}
                    />
                  )}
                </>
              )
            })}
        </List>
      </DialogContent>
      <DialogActions>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          <div>
            <Button disabled={activeStep === 0} onClick={() => setActiveStep((prev) => prev - 1)}>
              Back
            </Button>
            <Button variant='contained' onClick={onClose}>
              Finish
            </Button>
          </div>
        </div>
      </DialogActions>
    </>
  )
}

const fragment = graphql`
  fragment VoyageContainerReleaseWizard_voyage on Voyage {
    slug
    depots
    terminals

    releaseConfirmedContainers {
      slug
      releaseInvoiceStatus
      pickupReference
      pickupLocationSlug
      pickupDate
      turninReference
      turninLocationSlug
      latestTurninDate
      latestPickupDate
      bookingNumber
      folders {
        edges {
          node {
            slug
            name
            ...FileCreationDialog_folder
          }
        }
      }
      pickupOrders {
        attachmentSlug
        name
        isImage
        ...EntityAttachmentListPreviewer_attachment
        ...EntityAttachment_attachment
        ...EntityAttachmentListItem_attachment
      }
      containerItem {
        title
        image {
          alt
          url
        }
        stickyNoteContent
      }
    }

    releasableContainers {
      slug
      releaseInvoiceStatus
      pickupReference
      pickupLocationSlug
      pickupDate
      turninReference
      turninLocationSlug
      latestTurninDate
      latestPickupDate
      bookingNumber

      containerItem {
        title
        image {
          ...ItemImage_data
        }
        stickyNoteContent
      }
    }

    confirmedContainers {
      slug
      bookingNumber
      warehouseContact {
        slug
      }

      containerItem {
        title
        image {
          url
          alt
        }
        stickyNoteContent
      }
    }
  }
`

const updateBookingMutation = graphql`
  mutation VoyageContainerReleaseWizardUpdateBookingMutation($input: infoVoyageContainersBookingNumberInput!) {
    infoVoyageContainersBookingNumber(input: $input) {
      clientMutationId
    }
  }
`

const releaseContainerMutation = graphql`
  mutation VoyageContainerReleaseWizardReleaseContainerMutation($input: infoReleaseVoyageContainersInput!) {
    infoReleaseVoyageContainers(input: $input) {
      clientMutationId
    }
  }
`

export default VoyageContainerReleaseWizard
