import React, { Suspense } from 'react'
import { DetailsFieldsContainer, NewButtonPaper } from '../forms/Container/ContainerForm'
import InputField from './Form/InputField'
import styled from 'styled-components'
import { media } from '../theme'
import { FormFieldsContainer } from './ShipmentInfoCard'
import { Skeleton } from '@material-ui/lab'
import { Add } from '@material-ui/icons'
import { Typography } from '@material-ui/core'
import { FieldT } from './Form/types'
import { useFormikContext } from 'formik'
import ExternalVoyageField from './Form/ExternalVoyageField'
import ExternalPortField from './Form/ExternalPortField'
import { useState } from 'react'
import ShippingLineField from './Form/ExternalShippingLineField'

const FieldsContainer = styled.div`
  display: grid
  grid-template-columns: repeat(1, 1fr)
  grid-gap: 16px

  ${media.medium`
    grid-template-columns: repeat(1, 1fr)
  `}

  ${media.small`
    grid-template-columns: auto
  `}
`

const Space = styled.div`
  margin: 20px 0;
`

export const Title = styled(Typography)`
  padding: 0px 16px
  margin-bottom: 16px
`

export const SubTitle = styled(Typography)`
  padding: 0px 16px;
`

interface Props {
  fieldMaster: {
    [key: string]: FieldT
  }
  mode: 'add' | 'edit'
}

const DraftContainerForm: React.FC<Props> & { Skeleton: React.FC } = ({ fieldMaster }) => {
  return (
    <>
      <FieldsContainer>
        <DetailsFieldsContainer>
          <InputField of='containerNumber' field={fieldMaster['containerNumber']} />
          <InputField of='procedure' field={fieldMaster['procedure']} />
          <InputField of='containerType' field={fieldMaster['containerType']} />
          <InputField of='terminalDate' field={fieldMaster['terminalDate']} />
          <InputField of='dischargeDate' field={fieldMaster['dischargeDate']} />
        </DetailsFieldsContainer>
        <VoyageQuickAddCard fieldMaster={fieldMaster} />
      </FieldsContainer>
    </>
  )
}

interface VoyageQuickAddProps {
  fieldMaster: {
    [key: string]: FieldT
  }
}

const VoyageQuickAddCard: React.FC<VoyageQuickAddProps> = ({ fieldMaster }) => {
  const {
    values: { shippingLineSlug },
  } = useFormikContext()
  const [voyageForm, setVoyageForm] = useState(false)
  const { setFieldValue } = useFormikContext()

  const handleNewButtonClick = () => {
    setFieldValue(fieldMaster['voyageSlug'].name, null)
    setFieldValue('shippingLineSlug', null)
    setVoyageForm(true)
  }
  return (
    <>
      <Space>
        <Title variant='h6'>Voyage</Title>
      </Space>
      {!voyageForm && (
        <>
          <Space>
            <Suspense fallback={<ExternalVoyageField.Skeleton />}>
              <ExternalVoyageField field={fieldMaster['voyageSlug']} withShippingLine={!shippingLineSlug} />
            </Suspense>
          </Space>
          <Space>
            <Suspense fallback={<ShippingLineField.Skeleton />}>
              <ShippingLineField field={fieldMaster['shippingLineSlug']} />
            </Suspense>
          </Space>
        </>
      )}
      <SubTitle>**Create a new Voyage below if not present in the list</SubTitle>
      {!voyageForm && (
        <NewButtonPaper variant='outlined' onClick={handleNewButtonClick}>
          <Add />
          {'Add Voyage'}
        </NewButtonPaper>
      )}
      {voyageForm && (
        <FieldsContainer>
          <Space>
            <InputField of='name' field={fieldMaster['voyageName']} />
          </Space>
          <DetailsFieldsContainer>
            <InputField of='etd' field={fieldMaster['etd']} />
            <InputField of='eta' field={fieldMaster['eta']} />
            <ExternalPortField field={fieldMaster['sourcePortCode']} />
            <ExternalPortField field={fieldMaster['destinationPortCode']} />
          </DetailsFieldsContainer>
          <VesselQuickAddCard fieldMaster={fieldMaster} />
        </FieldsContainer>
      )}
    </>
  )
}

interface VesselQuickAddProps {
  fieldMaster: {
    [key: string]: FieldT
  }
}

const VesselQuickAddCard: React.FC<VesselQuickAddProps> = ({ fieldMaster }) => {
  const [vesselForm, setVesselForm] = useState(false)
  const { setFieldValue } = useFormikContext()

  const handleNewButtonClick = () => {
    setFieldValue(fieldMaster['vesselSlug'].name, null)
    setVesselForm(true)
  }
  return (
    <>
      <Space>
        <Title variant='h6'>Vessel</Title>
      </Space>
      {!vesselForm && <InputField of='vesselSlug' field={fieldMaster['vesselSlug']} />}
      <SubTitle>**Create a new Vessel below if not present in the list</SubTitle>
      {!vesselForm && (
        <NewButtonPaper variant='outlined' onClick={handleNewButtonClick}>
          <Add />
          {'Add Vessel'}
        </NewButtonPaper>
      )}

      {vesselForm && (
        <FieldsContainer>
          <Space>
            <InputField of='name' field={fieldMaster['vesselName']} />
          </Space>
          <Space>
            <ShippingLineField field={fieldMaster['vesselShippingLineSlug']} />
          </Space>
          <Space>
            <InputField of='imoNumber' field={fieldMaster['imoNumber']} />
          </Space>
        </FieldsContainer>
      )}
    </>
  )
}

DraftContainerForm.Skeleton = () => (
  <>
    <FormFieldsContainer>
      <Skeleton variant='rect' height='56px' />
      <Skeleton variant='rect' height='56px' />
      <Skeleton variant='rect' height='56px' />
      <Skeleton variant='rect' height='56px' />
    </FormFieldsContainer>
  </>
)

export default DraftContainerForm
