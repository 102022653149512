/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type FoldersTab_folders = {
    readonly " $fragmentRefs": FragmentRefs<"FoldersCard_folders">;
    readonly " $refType": "FoldersTab_folders";
};
export type FoldersTab_folders$data = FoldersTab_folders;
export type FoldersTab_folders$key = {
    readonly " $data"?: FoldersTab_folders$data;
    readonly " $fragmentRefs": FragmentRefs<"FoldersTab_folders">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FoldersTab_folders",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FoldersCard_folders"
    }
  ],
  "type": "FolderConnection",
  "abstractKey": null
};
(node as any).hash = '897b26592c3f1974410af4b1e41d13f2';
export default node;
