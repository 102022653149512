import React from 'react'
import { useLazyLoadQuery } from 'react-relay/hooks'
import NiceFormik from './Form/NiceFormik'
import { graphql } from 'babel-plugin-relay/macro'
import { DraftContainerAddQuery } from '../__generated__/DraftContainerAddQuery.graphql'
import { DraftContainerAddMutation } from '../__generated__/DraftContainerAddMutation.graphql'
import useNiceMutation from '../mutations/useNiceMutation'
import { Form } from 'formik'
import DraftContainerForm from './DraftContainerForm'
import { DialogActions, DialogContent } from '@material-ui/core'
import LoadingDots from './LoadingDots'
import Button from './Button'

interface Props {
  open: boolean
  onClose: () => void
}

const DraftContainertAdd: React.FC<Props> = (props) => {
  const data = useLazyLoadQuery<DraftContainerAddQuery>(query, {})
  const fields = data.infoAddDraftContainerForm.formSchema.fieldSet
  const fieldMaster = Object.fromEntries(fields.map((field) => [field.name, field]))
  const [commit, loading] = useNiceMutation<DraftContainerAddMutation>(mutation)
  const initialValues = {
    ticketType: 'request_to_create_a_new_container',
    containerNumber: '',
    containerType: '',
    voyageSlug: null,
    shippingLineSlug: null,
  }

  return (
    <>
      <NiceFormik
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          commit({
            variables: {
              input: values as any,
            },
            onError: () => actions.setSubmitting(false),
            onCompleted: (res, errors) => {
              actions.setSubmitting(false)
              if (!errors) {
                props.onClose()
              }
            },
          })
        }}
        formSchema={fields}
      >
        {({ submitForm }) => (
          <Form>
            <DialogContent dividers={true}>
              <DraftContainerForm fieldMaster={fieldMaster} mode='add'></DraftContainerForm>
            </DialogContent>
            <DialogActions>
              <Button onClick={props.onClose} variant='contained'>
                Cancel
              </Button>
              <Button onClick={submitForm} variant='contained'>
                {loading ? <LoadingDots /> : 'Save'}
              </Button>
            </DialogActions>
          </Form>
        )}
      </NiceFormik>
    </>
  )
}

const query = graphql`
  query DraftContainerAddQuery {
    infoAddDraftContainerForm {
      formSchema {
        fieldSet {
          name
          type
          required
          title
          description
          format
          enum
          enumTitles
          placeholder
        }
      }
    }
  }
`
const mutation = graphql`
  mutation DraftContainerAddMutation($input: infoAddDraftContainerInput!) {
    infoAddDraftContainer(input: $input) {
      clientMutationId
    }
  }
`
export default DraftContainertAdd
