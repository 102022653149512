import { Avatar, Card, CardContent, CardHeader, Divider, Grow, Paper, TextField, Typography } from '@material-ui/core'
import { CallMade, CallReceived, Close } from '@material-ui/icons'
import { Alert, AlertTitle } from '@material-ui/lab'
import { graphql } from 'babel-plugin-relay/macro'
import { useSnackbar } from 'notistack'
import React, { useContext, useState } from 'react'
import { useParams } from 'react-router-dom'
import { TransitionGroup } from 'react-transition-group'
import styled from 'styled-components'
import Button from '../../components/Button'
import { previewForAttachment } from '../../components/File/helpers'
import ItemLink from '../../components/ItemLink'
import LiveRequestEditDialog from '../../components/LiveRequestEditDialog'
import useLazyLoadQueryWithSubscription from '../../hooks/useLazyLoadQueryWithSubscription'
import useNiceMutation from '../../mutations/useNiceMutation'
import SessionContext from '../../SessionContext'
import { media } from '../../theme'
import { ShowPageRenderContext } from '../../viewTypes'
import { TicketShowAddCommentMutation } from '../../__generated__/TicketShowAddCommentMutation.graphql'
import { TicketShowCloseMutation } from '../../__generated__/TicketShowCloseMutation.graphql'
import { TicketShowPickMutation } from '../../__generated__/TicketShowPickMutation.graphql'
import { TicketShowQuery } from '../../__generated__/TicketShowQuery.graphql'
import { TicketShowResolveMutation } from '../../__generated__/TicketShowResolveMutation.graphql'
import { KeyFieldsContainer } from '../ExternalShipment/ExternalShipmentInfoCard'
import { useDateTimeDisplay } from '../../hooks/timeZoneDisplay'

const TicketShow: React.FC = () => {
  const { slug } = useParams()
  return <TicketShowWithParams slug={slug} renderContext='page' />
}

const Layout = styled.div`
  width: 1440px;
  padding-top: 32px;
  margin: auto;
  display: grid;
  grid-template-columns: 2fr auto 1fr;
  grid-gap: 16px;

  ${media.small`
    width: 100%;
    padding-top: 0;
    min-width: 360px;
    max-width: 625px;
    grid-template-columns: auto;
    /* grid-template-areas: 'fg' 'tg'; */
  `}
`

const VerticalFlex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

const HorizontalFlex = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`

const IconContainer = styled.div`
  margin-right: 4px;
`

const Title = styled(Typography).attrs({ variant: 'h5' })`
  margin-right: 4px;
  font-weight: 500;
`

const Description = styled.pre`
  font-family: 'Muli', sans-serif;
  font-weight: 400;
  font-size: 16px;
  margin-top: 8px;
`

const TitleAvatar = styled(Avatar)`
  margin-right: 12px;
  height: 64px;
  width: 64px;
`

export const TicketStatus = styled.div<{ $status: string }>`
  padding: 8px 16px;
  background-color: ${(props) => {
    switch (props.$status) {
      case 'open':
        return props.theme.customPalette.ticketStatusOpen.backgroundColor
      case 'resolved':
        return props.theme.customPalette.ticketStatusResolved.backgroundColor
      case 'closed':
        return props.theme.customPalette.ticketStatusClosed.backgroundColor
      default:
        return props.theme.customPalette.background.statusChip
    }
  }};
  border-radius: 8px;
  color: ${(props) => {
    switch (props.$status) {
      case 'open':
        return props.theme.customPalette.ticketStatusOpen.color
      case 'resolved':
        return props.theme.customPalette.ticketStatusResolved.color
      case 'closed':
        return props.theme.customPalette.ticketStatusClosed.color
      default:
        return props.theme.palette.primary.main
    }
  }};
  font-weight: bold;
  text-align: center;
`

const ActivityAvatar = styled(Avatar)`
  width: 24px;
  height: 24px;
`

const PickButton = styled(Button)`
  min-width: 150px;
  cursor: pointer;
  align-items: center;
  border-radius: 24px;
  border: 2px solid ${(props) => props.theme.palette.divider};
  text-transform: unset;

  & .MuiButton-label {
    padding-right: 8px;
  }

  & .MuiButton-startIcon {
    margin-left: 0;
    margin-right: 0;
  }
`

const FilesContainer = styled.div`
  margin-top: 8px;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-auto-rows: 120px;
  grid-gap: 16px;

  ${media.medium`
    grid-template-columns: repeat(4, minmax(0, 1fr));
  `}

  ${media.small`
    grid-template-columns: repeat(3, minmax(0, 1fr));
  `}
`
const DataContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
`
const RootPaper = styled(Paper).attrs({ variant: 'outlined' })`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  height: 100%;

  &:hover {
    filter: brightness(0.95);
  }
`

const PreviewContainer = styled.div`
  width: 100%;
  height: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Preview = styled.img<{ $iconPreview?: boolean; $isChip?: boolean }>`
  width: ${(props) => (props.$iconPreview ? '40px' : '100%')};
  height: ${(props) => (props.$iconPreview ? '40px' : '100%')};
  border-radius: 50%;
  object-fit: cover;
`

const AttachmentName = styled(Typography)`
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
  text-overflow: ellipsis;
  width: 90%;
  padding: 4px;
`

interface Params {
  slug: string
  renderContext?: ShowPageRenderContext
}

export const TicketShowWithParams: React.FC<Params> = ({ slug }) => {
  const { user } = useContext(SessionContext)
  const { enqueueSnackbar } = useSnackbar()
  const [comment, setComment] = useState<string>('')
  const [editLiveRequest, setEditLiveRequest] = useState<boolean>(false)

  const { ticketShow: ticket } = useLazyLoadQueryWithSubscription<TicketShowQuery>(
    query,
    { slug: slug },
    { subscriptionOptions: { entityType: 'tickets', entitySlug: slug } }
  )

  const [pickText, setPickText] = useState(ticket.pickedBy ? `@${ticket.pickedBy?.slug}` : 'Not Picked')
  const [pickCommit] = useNiceMutation<TicketShowPickMutation>(pickMutation)
  const [closeCommit] = useNiceMutation<TicketShowCloseMutation>(closeMutation)
  const [resolveCommit] = useNiceMutation<TicketShowResolveMutation>(resolveMutation)
  const [addCommentCommit] = useNiceMutation<TicketShowAddCommentMutation>(addCommentMutation)

  const showResolveCardToUser = ticket.ticketType !== 'request_for_shipment_release'
  const canPick = user?.username !== ticket.pickedBy?.slug

  const onPick = () => {
    pickCommit({
      variables: {
        input: { slug: slug },
      },
      onError: () => enqueueSnackbar('Failed to pick ticket', { variant: 'error' }),
      onCompleted: () => {
        // if (errors) enqueueSnackbar(`Error: ${errors.map(e => e.message).join(',')}`, { variant: 'error' })
      },
    })
  }

  const onClose = () => {
    closeCommit({
      variables: {
        input: { slug: slug },
      },
      onError: () => enqueueSnackbar('Failed to close ticket', { variant: 'error' }),
      onCompleted: () => {
        // if (errors) enqueueSnackbar(`Error: ${errors.map(e => e.message).join(',')}`, { variant: 'error' })
      },
    })
  }

  const onResolve = () => {
    resolveCommit({
      variables: {
        input: { slug: slug },
      },
      onError: () => enqueueSnackbar('Failed to resolve ticket', { variant: 'error' }),
      onCompleted: () => {
        // if (errors) enqueueSnackbar(`Error: ${errors.map(e => e.message).join(',')}`, { variant: 'error' })
      },
    })
  }

  const onComment = () => {
    addCommentCommit({
      variables: {
        input: { ticketSlug: slug, comment: comment },
      },
      onError: () => enqueueSnackbar('Failed to comment on ticket', { variant: 'error' }),
      onCompleted: (_, errors) => {
        if (!errors) setComment('')
      },
    })
  }

  return (
    <>
      <Layout>
        <VerticalFlex>
          <HorizontalFlex>
            <TitleAvatar alt={ticket.raisedFor?.name || ''} src={ticket.raisedFor?.profileUrl || ''} />
            <div>
              <div>
                <HorizontalFlex style={{ alignItems: 'center' }}>
                  <IconContainer>{ticket.direction === 'outgoing' ? <CallMade /> : <CallReceived />}</IconContainer>
                  <Title>{ticket.title}</Title>
                  {ticket.entities.map((item) => (
                    <ItemLink key={item.slug} item={item} />
                  ))}
                </HorizontalFlex>
                <Typography variant='subtitle2' color='textSecondary'>
                  Raised by {ticket.raisedBy.name} {useDateTimeDisplay(ticket.raisedAt)} for {ticket?.raisedFor?.name}
                </Typography>
              </div>
              <Description>{ticket.description}</Description>
            </div>
          </HorizontalFlex>
          <Divider style={{ width: '100%' }} />
          <div style={{ width: '100%', height: '100%', padding: '16px 8px 8px 32px' }}>
            {ticket.activities?.map((activity) => {
              if (activity.activityType === 'raise')
                return (
                  <HorizontalFlex key={activity.sequence} style={{ width: '100%', marginTop: '4px' }}>
                    <VerticalFlex style={{ height: '100%', alignItems: 'center', marginRight: '8px' }}>
                      <ActivityAvatar src={activity.performedBy.contact?.profileUrl || activity.performedBy.username} />
                      <Divider style={{ height: '100%', minHeight: '24px', width: '2px', marginTop: '4px' }} orientation='vertical' />
                    </VerticalFlex>
                    <HorizontalFlex style={{ width: '100%', justifyContent: 'space-between' }}>
                      <Typography variant='subtitle2' color='textSecondary'>
                        <b>{activity.performedBy.username}</b> {activity.meta} {useDateTimeDisplay(activity.performedAt)}
                      </Typography>
                    </HorizontalFlex>
                  </HorizontalFlex>
                )
              if (activity.activityType === 'comment')
                return (
                  <HorizontalFlex key={activity.sequence} style={{ width: '100%', marginTop: '4px' }}>
                    <VerticalFlex style={{ alignItems: 'center', marginRight: '8px' }}>
                      <ActivityAvatar src={activity.performedBy.contact?.profileUrl || activity.performedBy.username} />
                      <Divider style={{ height: '100%', minHeight: '24px', width: '2px', marginTop: '4px' }} orientation='vertical' />
                    </VerticalFlex>
                    <VerticalFlex
                      style={{
                        width: '100%',
                        padding: '8px 16px',
                        marginBottom: '16px',
                        borderRadius: '4px',
                        justifyContent: 'space-between',
                        border: '1px solid #e4e4e4',
                      }}
                    >
                      <Typography variant='subtitle2' color='textSecondary'>
                        <b>{activity.performedBy.username}</b> {activity.meta} {useDateTimeDisplay(activity.performedAt)}
                      </Typography>
                      <Typography variant='h6'>{activity.body}</Typography>
                    </VerticalFlex>
                  </HorizontalFlex>
                )
              if (activity.activityType === 'status_change')
                return (
                  <HorizontalFlex key={activity.sequence} style={{ width: '100%', marginTop: '4px' }}>
                    <VerticalFlex style={{ alignItems: 'center', marginRight: '8px' }}>
                      <ActivityAvatar src={activity.performedBy.contact?.profileUrl || activity.performedBy.username} />
                      <Divider style={{ height: '100%', minHeight: '24px', width: '2px', marginTop: '4px' }} orientation='vertical' />
                    </VerticalFlex>
                    <HorizontalFlex style={{ width: '100%', justifyContent: 'space-between' }}>
                      <Typography variant='subtitle2' color='textSecondary'>
                        <b>{activity.performedBy.username}</b> {activity.meta} {useDateTimeDisplay(activity.performedAt)}
                      </Typography>
                    </HorizontalFlex>
                  </HorizontalFlex>
                )
              return <>{activity.meta}</>
            })}
            <HorizontalFlex style={{ width: '100%', marginTop: '4px' }}>
              <VerticalFlex style={{ alignItems: 'center', marginRight: '8px' }}>
                <ActivityAvatar src={user?.contact?.profileUrl || user?.username} />
              </VerticalFlex>
              <VerticalFlex style={{ width: '100%', alignItems: 'flex-end' }}>
                <TextField
                  fullWidth
                  variant='outlined'
                  placeholder='Leave a comment'
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
                <div style={{ padding: '8px 0' }}>
                  {ticket.status === 'open' && (
                    <Button onClick={onClose} variant='text' startIcon={<Close />}>
                      {comment.length > 0 ? 'Close with comment' : 'Close'}
                    </Button>
                  )}
                  <Button disabled={!comment} onClick={onComment} variant='text' color='primary'>
                    Comment
                  </Button>
                </div>
              </VerticalFlex>
            </HorizontalFlex>
          </div>
        </VerticalFlex>
        <Divider orientation='vertical' />
        <VerticalFlex>
          <HorizontalFlex style={{ width: '100%', alignItems: 'center', justifyContent: 'space-between', marginBottom: '16px' }}>
            <PickButton
              onClick={() => canPick && onPick()}
              onMouseOver={() => canPick && setPickText('Pick')}
              onMouseOut={() => setPickText(ticket.pickedBy ? `@${ticket.pickedBy?.slug}` : 'Not Picked')}
              startIcon={
                pickText !== 'Pick' && (
                  <ActivityAvatar style={{ marginRight: '8px' }} src={ticket.pickedBy?.profileUrl || ticket.pickedBy?.name} />
                )
              }
            >
              {pickText}
            </PickButton>
            <TicketStatus $status={ticket.status}>{ticket.displayStatus}</TicketStatus>
          </HorizontalFlex>
          {showResolveCardToUser && ticket.status === 'open' && (
            <Alert style={{ width: '100%', marginBottom: '16px' }} severity='success' color='warning'>
              <AlertTitle>{ticket.action.title}</AlertTitle>
              <VerticalFlex>
                {ticket.action.message}
                <Button color='inherit' onClick={onResolve}>
                  {ticket.action.actionText}
                </Button>
              </VerticalFlex>
            </Alert>
          )}
          {(ticket.ticketType === 'request_for_documents' ||
            ticket.ticketType === 'add_documents_and_photos' ||
            ticket.ticketType === 'request_to_create_a_new_poa') && (
            <Card variant='outlined' style={{ width: '100%' }}>
              <CardHeader title='Documents' />
              <CardContent>
                <FilesContainer>
                  <TransitionGroup component={null} appear={false}>
                    {ticket.attachments.map((attachment) => {
                      const { previewUrl, isIconPreview } = previewForAttachment(attachment)
                      const handleClick = () => {
                        window.open(attachment.url)
                      }
                      return (
                        <Grow in={true} key={attachment.attachmentSlug} style={{ transformOrigin: 'center top' }}>
                          <RootPaper title={attachment.name} onClick={handleClick}>
                            <PreviewContainer>
                              <Preview src={previewUrl} $iconPreview={isIconPreview} $isChip={false} />
                            </PreviewContainer>
                            <AttachmentName variant='body2'>{attachment.name}</AttachmentName>
                          </RootPaper>
                        </Grow>
                      )
                    })}
                  </TransitionGroup>
                </FilesContainer>
              </CardContent>
            </Card>
          )}
          {ticket.ticketType === 'signed_document_details' && (
            <Card variant='outlined' style={{ width: '100%' }}>
              <CardHeader title='Documents' />
              <CardContent>
                {ticket.signedAttachments.map((attachment) => {
                  const { previewUrl, isIconPreview } = previewForAttachment(attachment)
                  const handleClick = () => {
                    window.open(attachment.url)
                  }
                  return (
                    <>
                      <FilesContainer>
                        <Grow in={true} key={attachment.attachmentSlug} style={{ transformOrigin: 'center top' }}>
                          <RootPaper title={attachment.name} onClick={handleClick}>
                            <PreviewContainer>
                              <Preview src={previewUrl} $iconPreview={isIconPreview} $isChip={false} />
                            </PreviewContainer>
                            <AttachmentName variant='body2'>{attachment.name}</AttachmentName>
                          </RootPaper>
                        </Grow>
                      </FilesContainer>
                      <hr></hr>
                      <Typography variant='h6'>Sent Data</Typography>
                      <DataContainer>
                        {attachment.sentData.map((data) => (
                          <div key={data.key}>
                            <Typography variant='subtitle2' color='textSecondary'>
                              {data.label}
                            </Typography>
                            <Typography variant='body1'>{data.value}</Typography>
                          </div>
                        ))}
                      </DataContainer>
                      <hr></hr>
                      <Typography variant='h6'>Received Data</Typography>
                      <DataContainer>
                        {attachment.receivedData.map((data) => (
                          <div key={data.key}>
                            <Typography variant='subtitle2' color='textSecondary'>
                              {data.label}
                            </Typography>
                            <Typography variant='body1' color={data.isDifferent ? 'error' : 'textPrimary'}>
                              {data.value}
                            </Typography>
                          </div>
                        ))}
                      </DataContainer>
                    </>
                  )
                })}
              </CardContent>
            </Card>
          )}
          {ticket.ticketType === 'request_to_create_container_shipment' && ticket.container && (
            <Card variant='outlined' style={{ width: '100%' }}>
              <CardHeader title='Container' />
              <CardContent>
                <Typography variant='h6'>{ticket.container?.containerNumber}</Typography>
                <div>
                  <Typography variant='subtitle2' color='textSecondary'>
                    Number
                  </Typography>
                  <Typography variant='body1'>{ticket.container?.containerNumber}</Typography>
                </div>
                <div>
                  <Typography variant='subtitle2' color='textSecondary'>
                    Type
                  </Typography>
                  <Typography variant='body1'>{ticket.container?.containerType}</Typography>
                </div>
                <div>
                  <Typography variant='subtitle2' color='textSecondary'>
                    Voyage
                  </Typography>
                  <Typography variant='body1'>{ticket.container?.voyage.name}</Typography>
                </div>
              </CardContent>
            </Card>
          )}
          {ticket.ticketType === 'request_to_create_a_new_contact' && ticket.contact && (
            <Card variant='outlined' style={{ width: '100%' }}>
              <CardHeader title='Contact' action={<Button onClick={() => setEditLiveRequest(true)}>Edit</Button>} />
              <CardContent>
                <KeyFieldsContainer>
                  <div>
                    <Typography variant='subtitle2' color='textSecondary'>
                      Salutation
                    </Typography>
                    <Typography variant='body1'>{ticket.contact.salutation || '--'}</Typography>
                  </div>
                  <div>
                    <Typography variant='subtitle2' color='textSecondary'>
                      Format
                    </Typography>
                    <Typography variant='body1'>{ticket.contact.format || '--'}</Typography>
                  </div>
                  <div>
                    <Typography variant='subtitle2' color='textSecondary'>
                      First Name
                    </Typography>
                    <Typography variant='body1'>{ticket.contact.firstName || '--'}</Typography>
                  </div>
                  <div>
                    <Typography variant='subtitle2' color='textSecondary'>
                      Last Name
                    </Typography>
                    <Typography variant='body1'>{ticket.contact.lastName || '--'}</Typography>
                  </div>
                  <div>
                    <Typography variant='subtitle2' color='textSecondary'>
                      Address
                    </Typography>
                    <Typography variant='body1'>{ticket.contact.address || '--'}</Typography>
                  </div>
                  <div>
                    <Typography variant='subtitle2' color='textSecondary'>
                      Postcode
                    </Typography>
                    <Typography variant='body1'>{ticket.contact.postcode || '--'}</Typography>
                  </div>
                  <div>
                    <Typography variant='subtitle2' color='textSecondary'>
                      City
                    </Typography>
                    <Typography variant='body1'>{ticket.contact.place || '--'}</Typography>
                  </div>
                  <div>
                    <Typography variant='subtitle2' color='textSecondary'>
                      Company
                    </Typography>
                    <Typography variant='body1'>{ticket.contact.company || '--'}</Typography>
                  </div>
                  <div>
                    <Typography variant='subtitle2' color='textSecondary'>
                      State
                    </Typography>
                    <Typography variant='body1'>{ticket.contact.state || '--'}</Typography>
                  </div>
                  <div>
                    <Typography variant='subtitle2' color='textSecondary'>
                      Country
                    </Typography>
                    <Typography variant='body1'>{ticket.contact.country || '--'}</Typography>
                  </div>
                  <div>
                    <Typography variant='subtitle2' color='textSecondary'>
                      Language
                    </Typography>
                    <Typography variant='body1'>{ticket.contact.language || '--'}</Typography>
                  </div>
                  <div>
                    <Typography variant='subtitle2' color='textSecondary'>
                      Email
                    </Typography>
                    <Typography variant='body1'>{ticket.contact?.email || '--'}</Typography>
                  </div>
                  <div>
                    <Typography variant='subtitle2' color='textSecondary'>
                      Business Phone
                    </Typography>
                    <Typography variant='body1'>{ticket.contact.businessPhone || '--'}</Typography>
                  </div>
                  <div>
                    <Typography variant='subtitle2' color='textSecondary'>
                      Roles
                    </Typography>
                    <Typography variant='body1'>{ticket.contact.roles.join(', ') || '--'}</Typography>
                  </div>
                </KeyFieldsContainer>
              </CardContent>
            </Card>
          )}
          {ticket.ticketType === 'request_to_create_a_new_shipment' && (
            <Card variant='outlined' style={{ width: '100%' }}>
              <CardHeader title='Shipment' />
              <CardContent>
                <KeyFieldsContainer>
                  <div>
                    <Typography variant='subtitle2' color='textSecondary'>
                      Shipment Type
                    </Typography>
                    <Typography variant='body1'>{ticket.shipment.shipmentType || '--'}</Typography>
                  </div>
                  <div>
                    <Typography variant='subtitle2' color='textSecondary'>
                      Container Slug
                    </Typography>
                    <Typography variant='body1'>{ticket.shipment.containerSlug || '--'}</Typography>
                  </div>
                  <div>
                    <Typography variant='subtitle2' color='textSecondary'>
                      Description
                    </Typography>
                    <Typography variant='body1'>{ticket.shipment.description || '--'}</Typography>
                  </div>
                  <div>
                    <Typography variant='subtitle2' color='textSecondary'>
                      Weight
                    </Typography>
                    <Typography variant='body1'>{ticket.shipment.weight || '--'}</Typography>
                  </div>
                  <div>
                    <Typography variant='subtitle2' color='textSecondary'>
                      VIN
                    </Typography>
                    <Typography variant='body1'>{ticket.shipment.vin || '--'}</Typography>
                  </div>
                </KeyFieldsContainer>
              </CardContent>
            </Card>
          )}
          {ticket.ticketType === 'request_to_create_a_new_container' && (
            <Card variant='outlined' style={{ width: '100%' }}>
              <CardHeader title='Container' />
              <CardContent>
                <KeyFieldsContainer>
                  <div>
                    <Typography variant='subtitle2' color='textSecondary'>
                      Container Number
                    </Typography>
                    <Typography variant='body1'>{ticket.container.containerNumber || '--'}</Typography>
                  </div>
                  <div>
                    <Typography variant='subtitle2' color='textSecondary'>
                      Direction
                    </Typography>
                    <Typography variant='body1'>{ticket.container.procedure || '--'}</Typography>
                  </div>
                  <div>
                    <Typography variant='subtitle2' color='textSecondary'>
                      Container Type
                    </Typography>
                    <Typography variant='body1'>{ticket.container.containerType || '--'}</Typography>
                  </div>
                  <div>
                    <Typography variant='subtitle2' color='textSecondary'>
                      Terminal Date
                    </Typography>
                    <Typography variant='body1'>{ticket.container.terminalDate || '--'}</Typography>
                  </div>
                  <div>
                    <Typography variant='subtitle2' color='textSecondary'>
                      Discharge Date
                    </Typography>
                    <Typography variant='body1'>{ticket.container.dischargeDate || '--'}</Typography>
                  </div>
                </KeyFieldsContainer>
                <KeyFieldsContainer>
                  <div>
                    <Typography variant='subtitle2' color='textSecondary'>
                      Voyage Name
                    </Typography>
                    <Typography variant='body1'>{ticket.container.voyage.name || '--'}</Typography>
                  </div>
                  <div>
                    <Typography variant='subtitle2' color='textSecondary'>
                      ETD
                    </Typography>
                    <Typography variant='body1'>{ticket.container.voyage.etd || '--'}</Typography>
                  </div>
                  <div>
                    <Typography variant='subtitle2' color='textSecondary'>
                      ETA
                    </Typography>
                    <Typography variant='body1'>{ticket.container.voyage.eta || '--'}</Typography>
                  </div>
                  <div>
                    <Typography variant='subtitle2' color='textSecondary'>
                      Source Port
                    </Typography>
                    <Typography variant='body1'>{ticket.container.voyage.sourcePortCode || '--'}</Typography>
                  </div>
                  <div>
                    <Typography variant='subtitle2' color='textSecondary'>
                      Destination Port
                    </Typography>
                    <Typography variant='body1'>{ticket.container.voyage.destinationPortCode || '--'}</Typography>
                  </div>
                </KeyFieldsContainer>
                <KeyFieldsContainer>
                  <div>
                    <Typography variant='subtitle2' color='textSecondary'>
                      Vessel Name
                    </Typography>
                    <Typography variant='body1'>{ticket.container.voyage.vessel.name || '--'}</Typography>
                  </div>
                  <div>
                    <Typography variant='subtitle2' color='textSecondary'>
                      Imo Number
                    </Typography>
                    <Typography variant='body1'>{ticket.container.voyage.vessel.imoNumber || '--'}</Typography>
                  </div>
                </KeyFieldsContainer>
              </CardContent>
            </Card>
          )}
          {(ticket.ticketType === 'request_to_add_contact_link' || ticket.ticketType === 'request_to_create_a_new_shipment') &&
            ticket.contactLink && (
              <Card variant='outlined' style={{ width: '100%' }}>
                <CardHeader title='Contact' />
                <CardContent>
                  <KeyFieldsContainer>
                    <div>
                      <Typography variant='subtitle2' color='textSecondary'>
                        Salutation
                      </Typography>
                      <Typography variant='body1'>{ticket.contactLink.salutation || '--'}</Typography>
                    </div>
                    <div>
                      <Typography variant='subtitle2' color='textSecondary'>
                        Format
                      </Typography>
                      <Typography variant='body1'>{ticket.contactLink.format || '--'}</Typography>
                    </div>
                    <div>
                      <Typography variant='subtitle2' color='textSecondary'>
                        First Name
                      </Typography>
                      <Typography variant='body1'>{ticket.contactLink.firstName || '--'}</Typography>
                    </div>
                    <div>
                      <Typography variant='subtitle2' color='textSecondary'>
                        Lticast Name
                      </Typography>
                      <Typography variant='body1'>{ticket.contactLink.lastName || '--'}</Typography>
                    </div>
                    <div>
                      <Typography variant='subtitle2' color='textSecondary'>
                        Address
                      </Typography>
                      <Typography variant='body1'>{ticket.contactLink.address || '--'}</Typography>
                    </div>
                    <div>
                      <Typography variant='subtitle2' color='textSecondary'>
                        Postcode
                      </Typography>
                      <Typography variant='body1'>{ticket.contactLink.postcode || '--'}</Typography>
                    </div>
                    <div>
                      <Typography variant='subtitle2' color='textSecondary'>
                        City
                      </Typography>
                      <Typography variant='body1'>{ticket.contactLink.place || '--'}</Typography>
                    </div>
                    <div>
                      <Typography variant='subtitle2' color='textSecondary'>
                        Company
                      </Typography>
                      <Typography variant='body1'>{ticket.contactLink.company || '--'}</Typography>
                    </div>
                    <div>
                      <Typography variant='subtitle2' color='textSecondary'>
                        State
                      </Typography>
                      <Typography variant='body1'>{ticket.contactLink.state || '--'}</Typography>
                    </div>
                    <div>
                      <Typography variant='subtitle2' color='textSecondary'>
                        Country
                      </Typography>
                      <Typography variant='body1'>{ticket.contactLink.country || '--'}</Typography>
                    </div>
                    <div>
                      <Typography variant='subtitle2' color='textSecondary'>
                        Language
                      </Typography>
                      <Typography variant='body1'>{ticket.contactLink.language || '--'}</Typography>
                    </div>
                    <div>
                      <Typography variant='subtitle2' color='textSecondary'>
                        Email
                      </Typography>
                      <Typography variant='body1'>{ticket.contactLink?.email || '--'}</Typography>
                    </div>
                    <div>
                      <Typography variant='subtitle2' color='textSecondary'>
                        Business Phone
                      </Typography>
                      <Typography variant='body1'>{ticket.contactLink.businessPhone || '--'}</Typography>
                    </div>
                    <div>
                      <Typography variant='subtitle2' color='textSecondary'>
                        Roles
                      </Typography>
                      <Typography variant='body1'>{ticket.contactLink.roles.join(', ') || '--'}</Typography>
                    </div>
                  </KeyFieldsContainer>
                </CardContent>
              </Card>
            )}
          {(ticket.ticketType === 'request_to_create_a_new_contact' || ticket.ticketType === 'request_to_create_a_new_shipment') &&
            ticket.contact && (
              <Card variant='outlined' style={{ width: '100%' }}>
                <CardHeader title='Contact' action={<Button onClick={() => setEditLiveRequest(true)}>Edit</Button>} />
                <CardContent>
                  <KeyFieldsContainer>
                    <div>
                      <Typography variant='subtitle2' color='textSecondary'>
                        Salutation
                      </Typography>
                      <Typography variant='body1'>{ticket.contact.salutation || '--'}</Typography>
                    </div>
                    <div>
                      <Typography variant='subtitle2' color='textSecondary'>
                        Format
                      </Typography>
                      <Typography variant='body1'>{ticket.contact.format || '--'}</Typography>
                    </div>
                    <div>
                      <Typography variant='subtitle2' color='textSecondary'>
                        First Name
                      </Typography>
                      <Typography variant='body1'>{ticket.contact.firstName || '--'}</Typography>
                    </div>
                    <div>
                      <Typography variant='subtitle2' color='textSecondary'>
                        Last Name
                      </Typography>
                      <Typography variant='body1'>{ticket.contact.lastName || '--'}</Typography>
                    </div>
                    <div>
                      <Typography variant='subtitle2' color='textSecondary'>
                        Address
                      </Typography>
                      <Typography variant='body1'>{ticket.contact.address || '--'}</Typography>
                    </div>
                    <div>
                      <Typography variant='subtitle2' color='textSecondary'>
                        Postcode
                      </Typography>
                      <Typography variant='body1'>{ticket.contact.postcode || '--'}</Typography>
                    </div>
                    <div>
                      <Typography variant='subtitle2' color='textSecondary'>
                        City
                      </Typography>
                      <Typography variant='body1'>{ticket.contact.place || '--'}</Typography>
                    </div>
                    <div>
                      <Typography variant='subtitle2' color='textSecondary'>
                        Company
                      </Typography>
                      <Typography variant='body1'>{ticket.contact.company || '--'}</Typography>
                    </div>
                    <div>
                      <Typography variant='subtitle2' color='textSecondary'>
                        State
                      </Typography>
                      <Typography variant='body1'>{ticket.contact.state || '--'}</Typography>
                    </div>
                    <div>
                      <Typography variant='subtitle2' color='textSecondary'>
                        Country
                      </Typography>
                      <Typography variant='body1'>{ticket.contact.country || '--'}</Typography>
                    </div>
                    <div>
                      <Typography variant='subtitle2' color='textSecondary'>
                        Language
                      </Typography>
                      <Typography variant='body1'>{ticket.contact.language || '--'}</Typography>
                    </div>
                    <div>
                      <Typography variant='subtitle2' color='textSecondary'>
                        Email
                      </Typography>
                      <Typography variant='body1'>{ticket.contact?.email || '--'}</Typography>
                    </div>
                    <div>
                      <Typography variant='subtitle2' color='textSecondary'>
                        Business Phone
                      </Typography>
                      <Typography variant='body1'>{ticket.contact.businessPhone || '--'}</Typography>
                    </div>
                    <div>
                      <Typography variant='subtitle2' color='textSecondary'>
                        Roles
                      </Typography>
                      <Typography variant='body1'>{ticket.contact.roles.join(', ') || '--'}</Typography>
                    </div>
                  </KeyFieldsContainer>
                </CardContent>
              </Card>
            )}
        </VerticalFlex>
      </Layout>

      <LiveRequestEditDialog open={editLiveRequest} onClose={() => setEditLiveRequest(false)} ticketSlug={ticket.slug} />
    </>
  )
}

const query = graphql`
  query TicketShowQuery($slug: String!) {
    ticketShow(slug: $slug) {
      slug
      title
      description
      status
      displayStatus
      direction
      ticketType
      raisedAt
      activities {
        sequence
        activityType
        slug
        body
        statusChangedTo
        meta
        performedBy {
          username
          contact {
            name
            roles
            profileUrl
          }
        }
        performedAt
      }
      action {
        title
        message
        actionText
      }
      entities {
        slug
        ...ItemLink_item
      }
      attachments {
        attachmentSlug
        url
        downloadUrl
        previewUrl
        name
        contentType
      }
      container {
        slug
        containerNumber
        containerType
        terminalDate
        dischargeDate
        voyage {
          name
          etd
          eta
          sourcePortCode
          destinationPortCode
          vessel {
            name
            imoNumber
          }
        }
      }
      pickedBy {
        slug
        name
        profileUrl
      }
      raisedBy {
        slug
        name
        profileUrl
      }
      raisedFor {
        slug
        name
        profileUrl
      }
      contact {
        firstName
        lastName
        email
        format
        businessPhone
        language
        country
        company
        address
        postcode
        place
        salutation
        state
        roles
      }
      contactLink {
        firstName
        lastName
        email
        format
        businessPhone
        language
        country
        company
        address
        postcode
        place
        salutation
        state
        roles
      }
      shipment {
        shipmentType
        containerSlug
        description
        weight
        vin
      }
      signedAttachments {
        attachmentSlug
        url
        downloadUrl
        previewUrl
        name
        contentType
        sentData {
          key
          label
          value
          isDifferent
        }
        receivedData {
          key
          label
          value
          isDifferent
        }
      }
      ...TicketItem_ticket
    }
  }
`

const pickMutation = graphql`
  mutation TicketShowPickMutation($input: infoPickTicketInput!) {
    infoPickTicket(input: $input) {
      clientMutationId
    }
  }
`

const closeMutation = graphql`
  mutation TicketShowCloseMutation($input: infoCloseTicketInput!) {
    infoCloseTicket(input: $input) {
      clientMutationId
    }
  }
`

const resolveMutation = graphql`
  mutation TicketShowResolveMutation($input: infoResolveTicketInput!) {
    infoResolveTicket(input: $input) {
      clientMutationId
    }
  }
`

const addCommentMutation = graphql`
  mutation TicketShowAddCommentMutation($input: infoAddTicketCommentInput!) {
    infoAddTicketComment(input: $input) {
      clientMutationId
    }
  }
`

export default TicketShow
