/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SemanticLine_data = {
    readonly parts: ReadonlyArray<{
        readonly key: string;
        readonly " $fragmentRefs": FragmentRefs<"SemanticString_data">;
    }> | null;
    readonly " $refType": "SemanticLine_data";
};
export type SemanticLine_data$data = SemanticLine_data;
export type SemanticLine_data$key = {
    readonly " $data"?: SemanticLine_data$data;
    readonly " $fragmentRefs": FragmentRefs<"SemanticLine_data">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SemanticLine_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SemanticString",
      "kind": "LinkedField",
      "name": "parts",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "key",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SemanticString_data"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SemanticLine",
  "abstractKey": null
};
(node as any).hash = 'bca6b680b6da120c95aea1e62f58f338';
export default node;
