import { graphql } from 'babel-plugin-relay/macro'
import React from 'react'
import { useLazyLoadQuery } from 'react-relay/hooks'
import FormActionBar from '../../components/Form/FormActionBar'
import NiceFormik from '../../components/Form/NiceFormik'
import VesselForm from '../../forms/Vessel/VesselForm'
import useNiceMutation from '../../mutations/useNiceMutation'
import { VesselEditFormQuery } from '../../__generated__/VesselEditFormQuery.graphql'
import { VesselEditMutation } from '../../__generated__/VesselEditMutation.graphql'
import FormContainer from '../../forms/FormContainer'

interface Props {
  slug: string
  onClose: () => void
}

const VesselEdit: React.FC<Props> & { Skeleton: React.FC } = ({ slug, onClose }) => {
  const data = useLazyLoadQuery<VesselEditFormQuery>(query, { slug: slug })
  const fields = data.infoUpdateVesselForm.formSchema.fieldSet
  const fieldMaster = Object.fromEntries(fields.map((field) => [field.name, field]))

  const [commit] = useNiceMutation<VesselEditMutation>(mutation)

  return (
    <>
      <NiceFormik
        initialValues={data.infoUpdateVesselForm.initialValue || {}}
        onSubmit={(values, actions) => {
          commit({
            variables: {
              input: values as any,
            },
            onError: () => actions.setSubmitting(false),
            onCompleted: (res, errors) => {
              actions.setSubmitting(false)
              if (!errors) {
                onClose()
              }
            },
          })
        }}
        formSchema={fields}
      >
        {({ submitForm, values }) => (
          <FormContainer>
            <VesselForm fieldMaster={fieldMaster} />
            <FormActionBar title={`Editing vessel ${(values as any).name}`} onCancel={() => onClose()} onSubmit={() => submitForm()} />
          </FormContainer>
        )}
      </NiceFormik>
    </>
  )
}

const query = graphql`
  query VesselEditFormQuery($slug: String!) {
    infoUpdateVesselForm(slug: $slug) {
      formSchema {
        fieldSet {
          name
          type
          required
          title
          description
          format
          enum
          enumTitles
        }
      }
      initialValue {
        slug
        name
        shippingLineSlug
        imoNumber
      }
    }
  }
`

const mutation = graphql`
  mutation VesselEditMutation($input: infoUpdateVesselInput!) {
    infoUpdateVessel(input: $input) {
      vessel {
        slug
      }
    }
  }
`

VesselEdit.Skeleton = () => (
  <>
    <VesselForm.Skeleton />
    <FormActionBar.Skeleton />
  </>
)

export default VesselEdit
