/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LiveShareFoldersCard_shipmentShow = {
    readonly folders: ReadonlyArray<{
        readonly slug: string;
        readonly parentType: string;
        readonly " $fragmentRefs": FragmentRefs<"LiveShareFolder_folder">;
    }>;
    readonly " $refType": "LiveShareFoldersCard_shipmentShow";
};
export type LiveShareFoldersCard_shipmentShow$data = LiveShareFoldersCard_shipmentShow;
export type LiveShareFoldersCard_shipmentShow$key = {
    readonly " $data"?: LiveShareFoldersCard_shipmentShow$data;
    readonly " $fragmentRefs": FragmentRefs<"LiveShareFoldersCard_shipmentShow">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LiveShareFoldersCard_shipmentShow",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "LiveShareFolder",
      "kind": "LinkedField",
      "name": "folders",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "parentType",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "LiveShareFolder_folder"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "LiveShareShowShipment",
  "abstractKey": null
};
(node as any).hash = '9d3ed876c12bcd2e0e8ea0f97ba5af65';
export default node;
