import React from 'react'
import { FormControl, InputLabel, MenuItem } from '@material-ui/core'
import { Field as FormikField, ErrorMessage } from 'formik'
import { Select } from 'formik-material-ui'
import styled from 'styled-components'
import { FieldT, FieldVariantEnum } from './types'

interface Props {
  field: FieldT
  variant?: FieldVariantEnum
}

const StyledFormControl = styled(FormControl)`
  max-width: 800px;
`

const MultiSelectField: React.FC<Props> = ({ field }) => {
  const labelId = `${field.name}-label`
  const label = field.title
  return (
    <StyledFormControl key={field.name} fullWidth variant='outlined'>
      <InputLabel id={labelId}>{label}</InputLabel>
      <FormikField component={Select} multiple name={field.name} labelId={labelId} label={label}>
        {field.enum &&
          field.enum.map((val, idx) => (
            <MenuItem key={val} value={val}>
              {field.enumTitles ? field.enumTitles[idx] : val}
            </MenuItem>
          ))}
      </FormikField>
      <ErrorMessage name={field.name} />
    </StyledFormControl>
  )
}

export default MultiSelectField
