import React, { useState } from 'react'
import QRCode from 'react-qr-code'
import OdysseyDialog from '../../components/OdysseyDialog'
import { Button, DialogTitle, DialogContent, TextField, DialogActions } from '@material-ui/core'
import styled from 'styled-components'
import { graphql } from 'babel-plugin-relay/macro'
import useNiceMutation from '../../mutations/useNiceMutation'
import { BarcodeTwoFactorAuthMutation } from '../../__generated__/BarcodeTwoFactorAuthMutation.graphql'

interface Props {
  open: boolean
  otpRequiredForLogin: boolean
  barcodeValue: string
  onClose: () => void
}

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const PassInput = styled(TextField)`
  margin-bottom: 8px;
`
const Info = styled.p`
  margin: 0px;
  font-size: 15px;
`

const BarcodeTwoFactorAuth: React.FC<Props> = ({ open, onClose, otpRequiredForLogin, barcodeValue }) => {
  const [commit_pass] = useNiceMutation<BarcodeTwoFactorAuthMutation>(TwoFactorState)
  const [otp, setOtp] = useState<string | null>(null)

  const submitOtp = () => {
    commit_pass({
      variables: { input: { otp: otp as string, otpRequiredForLogin: !otpRequiredForLogin } },
      onCompleted: (_res, errors) => {
        if (!errors) {
          onClose()
        }
      },
    })
  }

  return (
    <>
      <OdysseyDialog
        fullWidth
        open={open}
        maxWidth='xs'
        onClose={() => {
          onClose()
        }}
      >
        <DialogTitle>
          Two Factor Authentication
          {!otpRequiredForLogin && (
            <>
              <Info>Kindly download the Google Authenticator app on your mobile.</Info>
              Link:&nbsp;
              <a
                href='https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US'
                target='_blank'
                rel='noreferrer'
              >
                Android
              </a>
              &nbsp;|&nbsp;
              <a href='https://apps.apple.com/us/app/google-authenticator/id388497605' target='_blank' rel='noreferrer'>
                IOS
              </a>
            </>
          )}
        </DialogTitle>
        <DialogContent>
          <FormContainer>
            {!otpRequiredForLogin && (
              <>
                <QRCode value={barcodeValue as string}></QRCode>
              </>
            )}
            <br></br>
            <PassInput required variant='outlined' value={otp} onChange={(e) => setOtp(e.target.value)} label='Verify Token' type='number' />
          </FormContainer>
          <DialogActions>
            <Button variant='text' onClick={() => onClose()}>
              Cancel
            </Button>
            <Button variant='contained' color='primary' disabled={!otp} onClick={() => submitOtp()}>
              Submit
            </Button>
          </DialogActions>
        </DialogContent>
      </OdysseyDialog>
    </>
  )
}

const TwoFactorState = graphql`
  mutation BarcodeTwoFactorAuthMutation($input: infoTwoFactorAutheticationInput!) {
    infoTwoFactorAuthetication(input: $input) {
      clientMutationId
    }
  }
`

export default BarcodeTwoFactorAuth
