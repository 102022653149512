import React from 'react'
import { graphql } from 'babel-plugin-relay/macro'

import styled from 'styled-components'
import { Typography } from '@material-ui/core'
import { useFragment } from 'react-relay/hooks'
import { Attachment_attachment$key } from '../../__generated__/Attachment_attachment.graphql'
import { previewUrlForContentType } from './helpers'

interface Props {
  attachment: Attachment_attachment$key
}

const Root = styled.a`
  border: 1px solid ${(props) => props.theme.customPalette.border.card};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  cursor: pointer;

  text-decoration: none;
  color: inherit;

  &:hover {
    filter: brightness(0.95);
  }
`

const PreviewContainer = styled.div`
  width: 100%;
  height: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Preview = styled.img<{ $iconPreview?: boolean }>`
  width: ${(props) => (props.$iconPreview ? '30px' : '100%')};
  height: ${(props) => (props.$iconPreview ? '30px' : '100%')};
  object-fit: cover;
`

const AttachmentName = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  max-width: 100px;
  text-align: center;
  padding: 4px;
`

const Attachment: React.FC<Props> = ({ attachment: data }) => {
  const attachment = useFragment(fragment, data)
  const previewUrl = attachment.previewUrl ? attachment.previewUrl : previewUrlForContentType(attachment.contentType)
  const isIconPreview = attachment.previewUrl ? false : true

  return (
    <Root href={attachment.url} target='_blank'>
      <PreviewContainer>
        <Preview src={previewUrl} $iconPreview={isIconPreview} />
      </PreviewContainer>
      <AttachmentName variant='body2'>{attachment.name}</AttachmentName>
    </Root>
  )
}

const fragment = graphql`
  fragment Attachment_attachment on Attachment {
    url
    name
    contentType
    previewUrl
  }
`

export default Attachment
