import React, { Suspense, useState, useEffect } from 'react'
import { useParams } from 'react-router'
import { NiceTabPanel } from '../../components/TabsUI/TabsUI'
import ExternalShipmentShowHomeContent, { shipmentHomeQuery } from './ExternalShipmentShowHomeContent'
import ExternalShipmentShowHeroCard from './ExternalShipmentShowHeroCard'
import NoteContent from '../../components/NoteTab'
import ExternalShipmentEdit from './ExternalShipmentEdit'
import { useRelayEnvironment } from 'react-relay/hooks'
import { getRequest, createOperationDescriptor } from 'relay-runtime'
import styled from 'styled-components'
import EntityShowOrchestrator from '../../components/EntityShowOrchestrator'
import { uploadableDocsQuery } from '../../components/File/helpers'
import { ShowPageRenderContext } from '../../viewTypes'
import { ShipmentLinkedContactsTab } from '../../components/LinkedContactsTab'

const ShipmentShow: React.FC = () => {
  const { slug, tab } = useParams()
  return <ShipmentShowWithParams slug={slug} tab={tab} renderContext='page' />
}

interface Params {
  slug: string
  tab: string
  withSkeleton?: boolean
  renderContext?: ShowPageRenderContext
}

const TabPanelsContainer = styled.div`
  min-height: 90vh;
`

export const ShipmentShowWithParams: React.FC<Params> = ({ slug, tab, withSkeleton = false, renderContext = 'drawer' }) => {
  const [viewMode, setViewMode] = useState<'show' | 'edit'>('show')
  const environment = useRelayEnvironment()

  useEffect(() => {
    // explicitly preloading finance on initial render
    // TODO: switch to `useQueryLoader` and proper preloaded queries once they are usable

    // telling relay to retain these queries in store even when their components are not mounted (until this component unmounts)
    const homeQueryDisposable = environment.retain(createOperationDescriptor(getRequest(shipmentHomeQuery), { slug: slug }))
    const uploadableDocsQueryDisposable = environment.retain(
      createOperationDescriptor(getRequest(uploadableDocsQuery), { entityType: 'shipments' })
    )

    return () => {
      homeQueryDisposable.dispose()
      uploadableDocsQueryDisposable.dispose()
    }
  }, [slug, environment])

  return (
    <EntityShowOrchestrator
      keyId={slug}
      showPage={
        <>
          {withSkeleton ? (
            <Suspense fallback={<ExternalShipmentShowHeroCard.Skeleton />}>
              <ExternalShipmentShowHeroCard slug={slug} activeTab={tab} onEdit={() => setViewMode('edit')} renderContext={renderContext} />
            </Suspense>
          ) : (
            <ExternalShipmentShowHeroCard slug={slug} activeTab={tab} onEdit={() => setViewMode('edit')} renderContext={renderContext} />
          )}

          <TabPanelsContainer>
            <NiceTabPanel forTab='home' activeTab={tab}>
              <Suspense fallback={<ExternalShipmentShowHomeContent.Skeleton />}>
                <ExternalShipmentShowHomeContent slug={slug} />
              </Suspense>
            </NiceTabPanel>

            <NiceTabPanel forTab='notes' activeTab={tab}>
              <Suspense fallback={<NoteContent.Skeleton />}>
                <NoteContent slug={slug} entityType='shipments' />
              </Suspense>
            </NiceTabPanel>

            <NiceTabPanel forTab='contacts' activeTab={tab}>
              <Suspense fallback={<ShipmentLinkedContactsTab.Skeleton />}>
                <ShipmentLinkedContactsTab slug={slug} userType={'external'} />
              </Suspense>
            </NiceTabPanel>
          </TabPanelsContainer>
        </>
      }
      editPage={<ExternalShipmentEdit slug={slug} onClose={() => setViewMode('show')} />}
      viewMode={viewMode}
    />
  )
}

export default ShipmentShow
