import { Typography } from '@material-ui/core'
import { graphql } from 'babel-plugin-relay/macro'
import React from 'react'
import { useFragment } from 'react-relay/hooks'
import styled from 'styled-components'
import { ShowField_data$key } from '../__generated__/ShowField_data.graphql'
import SemanticString from './SemanticString'

interface Props {
  data: ShowField_data$key
}

const Container = styled.div`
  max-width: 200px;
  width: 100%;
  margin: 2px 0;
`

const Label = styled(Typography)`
  font-size: 12px;
  color: #333;
`

const Value = styled(Typography)`
  color: #333;
  font-weight: 500;
  box-sizing: content-box;
  white-space: nowrap;
  overflow: hidden;
  line-height: 24px;
  text-overflow: ellipsis;
`

const ShowField: React.FC<Props> = ({ data }) => {
  const field = useFragment(showFieldFragment, data)

  return (
    <Container>
      <Label variant='caption' color='textSecondary'>
        {field.label}
      </Label>
      <Value variant='body1'>
        {field.value === null || field.value.length === 0 ? '--' : field.value.map((value) => <SemanticString key={value.key} data={value} />)}
      </Value>
    </Container>
  )
}

const showFieldFragment = graphql`
  fragment ShowField_data on ShowField {
    label
    value {
      key
      ...SemanticString_data
    }
  }
`

export default ShowField
