import { Typography } from '@material-ui/core'
import { graphql } from 'babel-plugin-relay/macro'
import React, { useCallback } from 'react'
import { useLazyLoadQuery, usePaginationFragment } from 'react-relay/hooks'
import styled from 'styled-components'
import { SubscribableEntityT, useEntitySubscriptionWithCallback } from '../../hooks/useEntitySubscription'
import { ExternalTimelinePaginationQuery } from '../../__generated__/ExternalTimelinePaginationQuery.graphql'
import { FeedItemListTypeEnum, ExternalTimelineQuery } from '../../__generated__/ExternalTimelineQuery.graphql'
import { ExternalTimeline_queryData$key } from '../../__generated__/ExternalTimeline_queryData.graphql'
import Button from '../Button'
import LoadingDots from '../LoadingDots'
import ExternalFeedItem from './ExternalFeedItem'

interface Props {
  slug?: string | null
  type: FeedItemListTypeEnum
  parentAbsoluteSlug?: string
  group?: string | null
  external?: boolean
}

const VerticalGrid = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-gap: 8px;
`

const DayLabelContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 1px;
    transform: translateY(-50%);
    background-color: ${(props) => (props.theme.palette.type === 'light' ? props.theme.palette.grey[300] : props.theme.palette.grey[700])};
  }
`

const DayLabelTypography = styled(Typography)`
  padding: 4px 12px;
  text-align: center;
  color: ${(props) => (props.theme.palette.type === 'light' ? props.theme.palette.grey[700] : props.theme.palette.grey[300])};
  z-index: 1;
  background-color: ${(props) => props.theme.palette.background.default};
`

const DayLabel: React.FC = (props) => (
  <DayLabelContainer>
    <DayLabelTypography variant='caption'>{props.children}</DayLabelTypography>
  </DayLabelContainer>
)

const LoadMoreButton = styled(Button)`
  margin: 0;
  border-radius: 4px;
`

const ExternalTimeline: React.FC<Props> & { Skeleton: React.FC } = ({ slug, type, group, parentAbsoluteSlug, external = false }) => {
  const queryData = useLazyLoadQuery<ExternalTimelineQuery>(query, {
    slug: slug,
    type: type,
    group: group,
    parentAbsoluteSlug: parentAbsoluteSlug,
    count: 5,
  })

  return <ExternalFeedItemList queryData={queryData} slug={slug} type={type} external={external} />
}

const ExternalFeedItemList: React.FC<{
  queryData: ExternalTimeline_queryData$key
  slug?: string | null
  type: FeedItemListTypeEnum
  external: boolean
}> = ({ queryData, type, slug, external }) => {
  const { data, hasNext, loadNext, isLoadingNext, refetch } = usePaginationFragment<
    ExternalTimelinePaginationQuery,
    ExternalTimeline_queryData$key
  >(paginatedExternalFeedItemsFragment, queryData)
  const feedItemsNodes = data.externalFeedItemList.feedItems?.edges?.map((edge) => edge?.node)
  const onEntityUpdate = useCallback(() => refetch({}, { fetchPolicy: 'store-and-network' }), [refetch])
  useEntitySubscriptionWithCallback({
    entityType: type as SubscribableEntityT,
    entitySlug: slug || '',
    onUpdate: onEntityUpdate,
  })

  return (
    <VerticalGrid>
      {feedItemsNodes?.map(
        (node, idx) =>
          node && (
            <>
              {idx === 0 || (feedItemsNodes && node.dayLabel !== feedItemsNodes[idx - 1]?.dayLabel) ? (
                <DayLabel>{node.dayLabel}</DayLabel>
              ) : null}
              <ExternalFeedItem key={node.slug} data={node} external={external} />
            </>
          )
      )}
      {hasNext && (
        <LoadMoreButton
          variant='outlined'
          onClick={(e) => {
            // if we don't remove focus from button, page snaps to it's new position (which can be off-screen)
            e.preventDefault()
            e.currentTarget.blur()

            loadNext(5)
          }}
          tabIndex={-1}
        >
          {isLoadingNext ? <LoadingDots variant='primary' /> : 'Show more activity'}
        </LoadMoreButton>
      )}
    </VerticalGrid>
  )
}

const query = graphql`
  query ExternalTimelineQuery(
    $slug: String
    $type: FeedItemListTypeEnum!
    $group: String
    $parentAbsoluteSlug: String
    $count: Int
    $cursor: String
  ) {
    ...ExternalTimeline_queryData
  }
`

const paginatedExternalFeedItemsFragment = graphql`
  fragment ExternalTimeline_queryData on ApplicationQuery @refetchable(queryName: "ExternalTimelinePaginationQuery") {
    externalFeedItemList(slug: $slug, type: $type, group: $group, parentAbsoluteSlug: $parentAbsoluteSlug) {
      feedItems(first: $count, after: $cursor) @connection(key: "ExternalTimeline_feedItemList_feedItems") {
        edges {
          node {
            slug
            dayLabel
            ...ExternalFeedItem_data
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`

ExternalTimeline.Skeleton = () => <>Loading...</>

export default ExternalTimeline
