import React from 'react'
import { graphql } from 'babel-plugin-relay/macro'

import styled from 'styled-components'
import {
  ExternalShipmentWorkflow_shipment$key,
  ExternalShipmentWorkflow_shipment,
} from '../../__generated__/ExternalShipmentWorkflow_shipment.graphql'
import { useFragment } from 'react-relay/hooks'
import ExternalStage from './ExternalStage'

interface Props {
  shipment: ExternalShipmentWorkflow_shipment$key
}

const Container = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  overflow-x: auto;
`

const ExternalShipmentWorkflow: React.FC<Props> = ({ shipment: data }) => {
  const shipment = useFragment(fragment, data)
  const stages = shipment.workflowStages

  return (
    <>
      <Container>
        {stages.map(
          (stage, idx) =>
            stage && <ShipmentStage key={stage.value} stage={stage} index={idx + 1} isFirst={idx === 0} isLast={idx === stages.length - 1} />
        )}
      </Container>
    </>
  )
}

interface ShipmentStageProps {
  stage: ExternalShipmentWorkflow_shipment['workflowStages'][number]
  index: number
  isFirst: boolean
  isLast: boolean
}

const ShipmentStage: React.FC<ShipmentStageProps> = ({ stage, index, isFirst, isLast }) => {
  return <ExternalStage stage={stage} index={index} isFirst={isFirst} isLast={isLast} />
}

const fragment = graphql`
  fragment ExternalShipmentWorkflow_shipment on ExternalShipment {
    slug
    workflowStages {
      value
      label
      ownedBy
      status
      ...ExternalStage_stage
    }
  }
`

export default ExternalShipmentWorkflow
