import { LinearProgress } from '@material-ui/core'
import { graphql } from 'babel-plugin-relay/macro'
import { parse } from 'qs'
import React, { RefObject, Suspense, useEffect, useState } from 'react'
import { useLazyLoadQuery, usePaginationFragment } from 'react-relay/hooks'
import { useLocation } from 'react-router'
import Hero from '../../components/Hero'
import ResponsiveGrid from '../../components/ResponsiveGrid'
import { HighlightT } from '../../components/TextHighlighter'
import { isSmall } from '../../theme'
import { ItemType, ViewMode } from '../../viewTypes'
import { ItemListPaginationQuery } from '../../__generated__/ItemListPaginationQuery.graphql'
import { ItemListQuery, ItemListQueryVariables } from '../../__generated__/ItemListQuery.graphql'
import { ItemList_data$key } from '../../__generated__/ItemList_data.graphql'
import ItemListContent from './ItemListContent'
import ItemListFilters from './ItemListFilters'
import ItemListHeader from './ItemListHeader'

interface Props {
  type?: ItemType
  title: string
  description?: string
  entity?: string
  addItemUrl?: string
  scrollRef: RefObject<HTMLDivElement>
}

const ItemListContentWrapper = ({ variables, scrollRef, viewMode, expand }: any) => {
  const perPage = Number.parseInt(variables.perPage as string) || 10
  const data = useLazyLoadQuery<ItemListQuery>(query, variables)
  const {
    data: { itemList },
    loadNext,
    hasNext,
    isLoadingNext,
  } = usePaginationFragment<ItemListPaginationQuery, ItemList_data$key>(paginatedItemsFragment, data)
  const highlights: HighlightT[] =
    variables.searchQuery && variables.searchQuery.length >= 3 ? [{ type: 'searchTerm', value: variables.searchQuery }] : []

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.onscroll = () => {
        if (
          hasNext &&
          Math.ceil((scrollRef.current?.scrollTop || 0) + (scrollRef.current?.offsetHeight || 0)) >= (scrollRef.current?.scrollHeight || 0)
        ) {
          loadNext(perPage)
        }
      }
    }
  }, [hasNext, loadNext])

  return <ItemListContent items={itemList.items} viewMode={viewMode} highlights={highlights} isLoadingNext={isLoadingNext} expandAll={expand} />
}

const ItemList: React.FC<Props> = ({ type, entity, addItemUrl, scrollRef }) => {
  const location = useLocation()

  const {
    searchQuery,
    sortKey,
    perPage: perPageRaw,
    page: pageRaw,
    includeArchived,
    handoverDateStart,
    handoverDateEnd,
    voyageEtaStart,
    voyageEtaEnd,
    dischargeDateStart,
    dischargeDateEnd,
    ...queryVariables
  } = parse(location.search, {
    ignoreQueryPrefix: true,
  })
  const filters = Object.entries(queryVariables).map((entry) => ({ key: entry[0], values: entry[1] as string[] }))
  const page = Number.parseInt(pageRaw as string) || 1
  const perPage = Number.parseInt(perPageRaw as string) || 10
  const [viewMode, setViewMode] = useState<ViewMode>(isSmall() ? 'grid' : 'list')
  const [expand, setExpand] = useState(true)

  const variables: ItemListQueryVariables = {
    type,
    searchQuery: `${searchQuery || ''}`,
    filters,
    sortKey: `${sortKey || ''}`,
    page,
    perPage,
    includeArchived: includeArchived === 'true',
    handoverDateStart: handoverDateStart !== undefined ? handoverDateStart.toString() : undefined,
    handoverDateEnd: handoverDateEnd !== undefined ? handoverDateEnd.toString() : undefined,
    voyageEtaStart: voyageEtaStart !== undefined ? voyageEtaStart.toString() : undefined,
    voyageEtaEnd: voyageEtaEnd !== undefined ? voyageEtaEnd.toString() : undefined,
    dischargeDateStart: dischargeDateStart !== undefined ? dischargeDateStart.toString() : undefined,
    dischargeDateEnd: dischargeDateEnd !== undefined ? dischargeDateEnd.toString() : undefined,
    count: perPage,
  }
  const layout = viewMode === 'list' ? 'index-list-layout' : 'index-grid-layout'

  return (
    <>
      <Hero backgroundColor='transparent'>
        <Suspense key={type + '-headers'} fallback={<LinearProgress />}>
          <ItemListHeader
            variables={variables}
            viewMode={viewMode}
            setViewMode={setViewMode}
            expand={expand}
            setExpand={() => setExpand(!expand)}
            type={type}
            entity={entity}
            addItemUrl={addItemUrl}
          />
        </Suspense>
      </Hero>
      <ResponsiveGrid
        type={layout}
        sidebar={
          isSmall()
            ? []
            : [
                <Suspense key={type + '-filters'} fallback={<>Loading...</>}>
                  <ItemListFilters variables={variables} listType={type} />
                </Suspense>,
              ]
        }
        content={[
          <Suspense key={type + '-item-list'} fallback={<>Loading...</>}>
            <ItemListContentWrapper {...{ variables, scrollRef, viewMode, expand }} />
          </Suspense>,
        ]}
      />
    </>
  )
}

const query = graphql`
  query ItemListQuery(
    $searchQuery: String
    $type: ListItemTypeEnum
    $filters: [FilterInput!]
    $includeArchived: Boolean
    $sortKey: String
    $page: Int
    $perPage: Int
    $handoverDateStart: Date
    $handoverDateEnd: Date
    $voyageEtaStart: Date
    $voyageEtaEnd: Date
    $dischargeDateStart: Date
    $dischargeDateEnd: Date
    $count: Int
    $cursor: String
  ) {
    ...ItemList_data
  }
`

const paginatedItemsFragment = graphql`
  fragment ItemList_data on ApplicationQuery @refetchable(queryName: "ItemListPaginationQuery") {
    itemList(
      searchQuery: $searchQuery
      type: $type
      filters: $filters
      includeArchived: $includeArchived
      sortKey: $sortKey
      page: $page
      perPage: $perPage
      handoverDateStart: $handoverDateStart
      handoverDateEnd: $handoverDateEnd
      voyageEtaStart: $voyageEtaStart
      voyageEtaEnd: $voyageEtaEnd
      dischargeDateStart: $dischargeDateStart
      dischargeDateEnd: $dischargeDateEnd
    ) {
      items(first: $count, after: $cursor) @connection(key: "ItemList_itemList_items") {
        edges {
          node {
            slug
            ...ListPageItem_listItem
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
      ...ItemListFooter_data
      ...ItemListSelectbar_data
    }
  }
`

export default ItemList
