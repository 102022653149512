import React from 'react'
import Highlighter, { HighlighterProps } from 'react-highlight-words'
import { useTheme } from 'styled-components'

export type HighlightT = {
  type: 'searchTerm'
  value: string
}

// weird type, since HighlighterProps contains an index signature
const TextHighlighter: React.FC<
  Pick<HighlighterProps, Exclude<KnownKeys<HighlighterProps>, 'searchWords'>> & {
    highlights?: HighlightT[]
  }
> = ({ highlights, ...restProps }) => {
  const theme = useTheme()
  return (
    <Highlighter
      {...restProps}
      autoEscape
      highlightStyle={{ color: 'inherit' }}
      highlightClassName={theme.palette.type === 'light' ? 'text-highlight--light' : 'text-highlight--dark'}
      searchWords={highlights?.filter((h) => h.type === 'searchTerm').map((h) => h.value) || []}
    />
  )
}

// refer https://stackoverflow.com/a/51955852/6692326
type KnownKeys<T> = {
  [K in keyof T]: string extends K ? never : number extends K ? never : K
} extends { [_ in keyof T]: infer U }
  ? U
  : never

export default TextHighlighter
