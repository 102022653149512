import React from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import { useFragment } from 'react-relay/hooks'
import { ExternalContainerLocation_container$key } from '../__generated__/ExternalContainerLocation_container.graphql'
import { Card, CardContent, Typography } from '@material-ui/core'
import styled from 'styled-components'
import portRight from '../assets/public/container_location_icons/port-right.png'
import portLeft from '../assets/public/container_location_icons/port-left.png'
import ship from '../assets/public/container_location_icons/ship.png'
import truck from '../assets/public/container_location_icons/truck.png'
import warehouse from '../assets/public/container_location_icons/warehouse.png'
import { PhysicalStatusEnum } from '../__generated__/ContainerLocation_container.graphql'
import discharge from '../assets/public/container_location_icons/discharge.png'

interface Props {
  container: ExternalContainerLocation_container$key
}

const StatusCardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-column-gap: 8px;
  margin-top: 16px;
`

const StatusCardLabel = styled(Typography)`
  text-align: center;
  font-size: 12px;
  font-weight: 700;
`

const StatusCardImage = styled.img``

const StatusCard = styled.div<{ $mode: 'finished' | 'current' | 'pending' }>`
  padding: 16px 4px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  cursor: pointer;
  border: 2px solid transparent;
  background-color: ${(props) => props.theme.customPalette.background.stageCard[props.$mode]};

  ${StatusCardLabel} {
    color: ${(props) => props.theme.customPalette.text.stageCard[props.$mode]};
  }

  ${StatusCardImage} {
    filter: ${(props) => (props.$mode === 'pending' ? 'grayscale(100%)' : 'unset')};
  }

  &:hover {
    filter: brightness(0.95);
  }
`

const StatusCardIconContainer = styled.div`
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
`

const ExternalContainerLocation: React.FC<Props> = ({ container: data }) => {
  const container = useFragment(fragment, data)

  const statusIconMap: { [key in PhysicalStatusEnum]: string } = {
    origin_port: portRight,
    shipped: ship,
    full_in_terminal: portLeft,
    full_in_transit: truck,
    in_warehouse: warehouse,
    empty_in_transit: truck,
    empty_in_terminal: portLeft,
    discharge: discharge,
    '%future added value': '',
    destination_port: portLeft,
    empty_in_depot: portRight,
  }

  const currentStatusIdx = container.validPhysicalStatuses.findIndex((st) => st.value === container.physicalStatus)

  return (
    <Card variant='outlined'>
      <CardContent>
        <Typography variant='h6'>Container Location</Typography>
        <StatusCardsContainer>
          {container.validPhysicalStatuses.map((status, idx) => (
            <StatusCard key={status.value} $mode={idx === currentStatusIdx ? 'current' : idx < currentStatusIdx ? 'finished' : 'pending'}>
              <StatusCardIconContainer>
                <StatusCardImage src={statusIconMap[status.value]} />
              </StatusCardIconContainer>
              <StatusCardLabel variant='body1'>{status.title}</StatusCardLabel>
            </StatusCard>
          ))}
        </StatusCardsContainer>
      </CardContent>
    </Card>
  )
}

const fragment = graphql`
  fragment ExternalContainerLocation_container on ExternalContainer {
    slug
    physicalStatus
    validPhysicalStatuses {
      value
      title
    }
  }
`

export default ExternalContainerLocation
