import React, { Suspense, useEffect } from 'react'
import { useParams } from 'react-router'
import Hero from '../../components/Hero'
import { NiceTabPanel } from '../../components/TabsUI/TabsUI'
import { useRelayEnvironment } from 'react-relay/hooks'
import { createOperationDescriptor, getRequest } from 'relay-runtime'
import { uploadableDocsQuery } from '../../components/File/helpers'
import ExternalContactShowHeroCard from './ExternalContactShowHeroCard'
import ExternalHomeContent from './ExternalHomeContent'
import ExternalRelatedItemList from '../ExternalItemList/ExternalRelatedItemList'

const ExternalContactShow: React.FC = () => {
  const { slug, tab } = useParams()
  return <ExternalContactShowWithParams slug={slug} tab={tab} />
}

interface Params {
  slug: string
  tab: string
}

export const ExternalContactShowWithParams: React.FC<Params> = ({ slug, tab }) => {
  const environment = useRelayEnvironment()

  useEffect(() => {
    // telling relay to retain these queries in store even when their components are not mounted (until this component unmounts)
    const uploadableDocsQueryDisposable = environment.retain(
      createOperationDescriptor(getRequest(uploadableDocsQuery), { entityType: 'contacts' })
    )

    return () => {
      uploadableDocsQueryDisposable.dispose()
    }
  }, [slug, environment])

  return (
    <>
      <Hero backgroundColor='transparent'>
        <ExternalContactShowHeroCard slug={slug} />
      </Hero>
      <NiceTabPanel forTab='home' activeTab={tab}>
        <Suspense fallback={<ExternalHomeContent.Skeleton />}>
          <ExternalHomeContent slug={slug} />
        </Suspense>
      </NiceTabPanel>
      <NiceTabPanel forTab='shipments' activeTab={tab}>
        <Suspense fallback={<ExternalRelatedItemList.Skeleton />}>
          <ExternalRelatedItemList type='shipments' contactSlug={slug} />
        </Suspense>
      </NiceTabPanel>
    </>
  )
}

export default ExternalContactShow
