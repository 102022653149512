import { useSnackbar } from 'notistack'
import { useState } from 'react'

type VinDetailsT = {
  description: string | null
  vinEngine: string | null
  vinFuelType: string | null
  vinBodyClass: string | null
  vinOrigin: string | null
}

type FetchVinT = (vin: string, { onSuccess }: { onSuccess: (vinDetails: VinDetailsT) => void }) => void

const useVinDetails = (): [FetchVinT, boolean] => {
  const { enqueueSnackbar } = useSnackbar()
  const [vinFetchIsInProgress, setVinFetchIsInProgress] = useState(false)

  const fetchVin: FetchVinT = (vin, { onSuccess }) => {
    setVinFetchIsInProgress(true)
    fetch(`https://vpic.nhtsa.dot.gov/api/vehicles/decodevinvaluesextended/${vin}?format=json`, {
      method: 'GET',
    })
      .then((response) => {
        setVinFetchIsInProgress(false)
        if (!response.ok) {
          throw Error(response.statusText)
        }
        return response
      })
      .then((response) =>
        response.json().catch((e) => {
          throw Error('Error parsing response as json' + e)
        })
      )
      .then((responseJson) => {
        const result = responseJson.Results ? responseJson.Results[0] : null
        if (!result) {
          throw Error('Result not found in response')
        } else if (result.ErrorCode !== '0') {
          throw Error(`Error: ${result.ErrorText}`)
        } else {
          const description = `${result.ModelYear} ${result.Make} ${result.Series}`
          onSuccess({
            description: description,
            vinEngine: result.DisplacementL,
            vinFuelType: result.FuelTypePrimary,
            vinBodyClass: result.BodyClass,
            vinOrigin: result.PlantCountry,
          })
        }
      })
      .catch((err) => {
        if (vin) {
          enqueueSnackbar(err.message, { variant: 'error' })
        } else {
          enqueueSnackbar('VIN cannot be blank.', { variant: 'error' })
        }
      })
  }

  return [fetchVin, vinFetchIsInProgress]
}

export default useVinDetails
