import { ButtonGroup } from '@material-ui/core'
import { ZoomIn, ZoomOut } from '@material-ui/icons'
import React from 'react'
import styled from 'styled-components'
import Button from '../../components/Button'
import { BarChart, PieChart } from '../../components/Charts/OdysseyCharts'
import { Data } from '../../components/Charts/types'
import usePaginatedBarChart from '../../components/Charts/utils/usePaginatedBarChart'

interface Props {
  data: Data
}

const BarActionsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

const stagesPieColorsPalette = ['#a6cee3', '#1f78b4', '#b2df8a', '#33a02c', '#fb9a99', '#e31a1c', '#fdbf6f']
const locationPieColorsPalette = ['#a6cee3', '#1f78b4', '#b2df8a', '#33a02c', '#fb9a99', '#e31a1c', '#fdbf6f']

export const ContainerStagePie: React.FC<Props> = ({ data }) => {
  return <PieChart data={data} colors={stagesPieColorsPalette} />
}

export const ContainerLocationPie: React.FC<Props> = ({ data }) => {
  return <PieChart data={data} colors={locationPieColorsPalette} />
}

export const ContainerEtaBar: React.FC<Props> = ({ data }) => {
  const { paginatedData, hasNextPage, hasPrevPage, canZoomIn, canZoomOut, paginate, zoom } = usePaginatedBarChart<Data>(data)

  return (
    <div>
      <BarChart data={paginatedData} xLabel='ETAs' yLabel='Containers' />
      <BarActionsContainer>
        <ButtonGroup>
          <Button onClick={() => zoom('out')} disabled={!canZoomOut}>
            <ZoomOut color={canZoomOut ? 'action' : 'disabled'} />
          </Button>
          <Button onClick={() => zoom('in')} disabled={!canZoomIn}>
            <ZoomIn color={canZoomIn ? 'action' : 'disabled'} />
          </Button>
        </ButtonGroup>
        <ButtonGroup>
          <Button onClick={() => paginate('backward')} disabled={!hasPrevPage}>
            Prev
          </Button>
          <Button onClick={() => paginate('forward')} disabled={!hasNextPage}>
            Next
          </Button>
        </ButtonGroup>
      </BarActionsContainer>
    </div>
  )
}
