/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ExternalDashboardHeaderQueryVariables = {};
export type ExternalDashboardHeaderQueryResponse = {
    readonly externalDashboardHeaderQuery: {
        readonly summary: ReadonlyArray<string>;
        readonly parentHandle: {
            readonly slug: string;
            readonly name: string;
            readonly image: {
                readonly url: string | null;
                readonly " $fragmentRefs": FragmentRefs<"ItemImage_data">;
            } | null;
        } | null;
        readonly selectedHandle: {
            readonly slug: string;
            readonly title: string | null;
            readonly image: {
                readonly url: string | null;
                readonly " $fragmentRefs": FragmentRefs<"ItemImage_data">;
            } | null;
            readonly badges: ReadonlyArray<{
                readonly " $fragmentRefs": FragmentRefs<"Badge_data">;
            }> | null;
            readonly lines: ReadonlyArray<{
                readonly " $fragmentRefs": FragmentRefs<"SemanticLine_data">;
            }> | null;
        } | null;
        readonly availableHandles: ReadonlyArray<{
            readonly slug: string;
            readonly title: string | null;
        }> | null;
    } | null;
};
export type ExternalDashboardHeaderQuery = {
    readonly response: ExternalDashboardHeaderQueryResponse;
    readonly variables: ExternalDashboardHeaderQueryVariables;
};



/*
query ExternalDashboardHeaderQuery {
  externalDashboardHeaderQuery {
    summary
    parentHandle {
      slug
      name
      image {
        url
        ...ItemImage_data
      }
    }
    selectedHandle {
      slug
      title
      image {
        url
        ...ItemImage_data
      }
      badges {
        ...Badge_data
      }
      lines {
        ...SemanticLine_data
      }
    }
    availableHandles {
      slug
      title
    }
  }
}

fragment Badge_data on Badge {
  type
}

fragment ItemImage_data on Image {
  url
  alt
  highlight
  shape
  overlay
  overlayIcon
}

fragment SemanticLine_data on SemanticLine {
  parts {
    key
    ...SemanticString_data
  }
}

fragment SemanticString_data on SemanticString {
  text
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "summary",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Image",
  "kind": "LinkedField",
  "name": "image",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ItemImage_data"
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Handle",
  "kind": "LinkedField",
  "name": "availableHandles",
  "plural": true,
  "selections": [
    (v1/*: any*/),
    (v5/*: any*/)
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "Image",
  "kind": "LinkedField",
  "name": "image",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "alt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "highlight",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shape",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "overlay",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "overlayIcon",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ExternalDashboardHeaderQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DashboardHeader",
        "kind": "LinkedField",
        "name": "externalDashboardHeaderQuery",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Handle",
            "kind": "LinkedField",
            "name": "parentHandle",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Handle",
            "kind": "LinkedField",
            "name": "selectedHandle",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v5/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Badge",
                "kind": "LinkedField",
                "name": "badges",
                "plural": true,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "Badge_data"
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SemanticLine",
                "kind": "LinkedField",
                "name": "lines",
                "plural": true,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "SemanticLine_data"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ExternalDashboardHeaderQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DashboardHeader",
        "kind": "LinkedField",
        "name": "externalDashboardHeaderQuery",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Handle",
            "kind": "LinkedField",
            "name": "parentHandle",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Handle",
            "kind": "LinkedField",
            "name": "selectedHandle",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v5/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Badge",
                "kind": "LinkedField",
                "name": "badges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SemanticLine",
                "kind": "LinkedField",
                "name": "lines",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SemanticString",
                    "kind": "LinkedField",
                    "name": "parts",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "key",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "text",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "10eee92021ae2c4b7cd3c7cb907bd887",
    "id": null,
    "metadata": {},
    "name": "ExternalDashboardHeaderQuery",
    "operationKind": "query",
    "text": "query ExternalDashboardHeaderQuery {\n  externalDashboardHeaderQuery {\n    summary\n    parentHandle {\n      slug\n      name\n      image {\n        url\n        ...ItemImage_data\n      }\n    }\n    selectedHandle {\n      slug\n      title\n      image {\n        url\n        ...ItemImage_data\n      }\n      badges {\n        ...Badge_data\n      }\n      lines {\n        ...SemanticLine_data\n      }\n    }\n    availableHandles {\n      slug\n      title\n    }\n  }\n}\n\nfragment Badge_data on Badge {\n  type\n}\n\nfragment ItemImage_data on Image {\n  url\n  alt\n  highlight\n  shape\n  overlay\n  overlayIcon\n}\n\nfragment SemanticLine_data on SemanticLine {\n  parts {\n    key\n    ...SemanticString_data\n  }\n}\n\nfragment SemanticString_data on SemanticString {\n  text\n}\n"
  }
};
})();
(node as any).hash = '5f8c320bd0702d12fedbbba731e88f0f';
export default node;
