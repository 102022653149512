import { Avatar, Badge, Typography } from '@material-ui/core'
import React, { Suspense, useContext } from 'react'
import { useParams } from 'react-router'
import styled from 'styled-components'
import ResponsiveGrid from '../../components/ResponsiveGrid'
import SessionContext from '../../SessionContext'
import ExternalDashboardHeader from './ExternalDashboardHeader'
import ExternalDashboardMainContent from './ExternalDashboardMainContent'

const UserBadge = styled(Badge)`
  display: flex;
  align-items: center;
  justify-content: center;
`

const UserCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 16px 0;
  border-bottom-right-radius: 36px;
  border-bottom-left-radius: 36px;
  border-color: ${(props) => props.theme.palette.divider};
  background-color: ${(props) => props.theme.palette.background.paper};
`

const UserAvatar = styled(Avatar)`
  width: 192px;
  height: 192px;
  margin-bottom: 16px;
  border: 4px solid white;
`

const ExternalDashboard: React.FC = () => {
  const { handle } = useParams()
  const { user } = useContext(SessionContext)
  const my = user?.username === handle.toLowerCase() ? 'My' : `@${handle}'s`

  return (
    <>
      <ResponsiveGrid
        type='dashboard-layout'
        header={[
          <Suspense key='header' fallback={'Loading...'}>
            <ExternalDashboardHeader my={my} />
          </Suspense>,
        ]}
        headerProfile={[
          <UserCard key='user-profile'>
            <UserBadge
              overlap='circle'
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              <UserAvatar src={user?.contact?.profileUrl || ''} />
            </UserBadge>
            <Typography variant='h6'>{user?.contact?.name}</Typography>
            <Typography variant='body2' style={{ maxWidth: '200px', textAlign: 'center' }}>
              {user?.contact?.displayRoles?.map((r) => r.name).join(', ')}{' '}
              {user?.contact?.workspace !== 'live' ? `(${user?.contact?.workspace})` : ''}
            </Typography>
          </UserCard>,
        ]}
      />
      <Suspense key='main' fallback={'Loading...'}>
        <ExternalDashboardMainContent my={my} />
      </Suspense>
    </>
  )
}

export default ExternalDashboard
