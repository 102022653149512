/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type infoAcceptWorkspaceInvitationInput = {
    clientMutationId?: string | null;
    token: string;
};
export type InvitationAcceptanceFormAcceptWorkspaceMutationVariables = {
    input: infoAcceptWorkspaceInvitationInput;
};
export type InvitationAcceptanceFormAcceptWorkspaceMutationResponse = {
    readonly infoAcceptWorkspaceInvitation: {
        readonly clientMutationId: string | null;
    } | null;
};
export type InvitationAcceptanceFormAcceptWorkspaceMutation = {
    readonly response: InvitationAcceptanceFormAcceptWorkspaceMutationResponse;
    readonly variables: InvitationAcceptanceFormAcceptWorkspaceMutationVariables;
};



/*
mutation InvitationAcceptanceFormAcceptWorkspaceMutation(
  $input: infoAcceptWorkspaceInvitationInput!
) {
  infoAcceptWorkspaceInvitation(input: $input) {
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "infoAcceptWorkspaceInvitationPayload",
    "kind": "LinkedField",
    "name": "infoAcceptWorkspaceInvitation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "InvitationAcceptanceFormAcceptWorkspaceMutation",
    "selections": (v1/*: any*/),
    "type": "ApplicationCommand",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "InvitationAcceptanceFormAcceptWorkspaceMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ba41cdc868724619ce113397f5f6fe14",
    "id": null,
    "metadata": {},
    "name": "InvitationAcceptanceFormAcceptWorkspaceMutation",
    "operationKind": "mutation",
    "text": "mutation InvitationAcceptanceFormAcceptWorkspaceMutation(\n  $input: infoAcceptWorkspaceInvitationInput!\n) {\n  infoAcceptWorkspaceInvitation(input: $input) {\n    clientMutationId\n  }\n}\n"
  }
};
})();
(node as any).hash = '0e7c160bab8630a0fc13cdac0f92d192';
export default node;
