// @ts-nocheck ts fails on typechecking for createMuiTheme

import { createMuiTheme, PaletteType } from '@material-ui/core'
import { red, blue, grey } from '@material-ui/core/colors'
import { css, DefaultTheme } from 'styled-components'

export const mediaQuery =
  (...query) =>
  (...rules) =>
    css`
      @media ${css(...query)} {
        ${css(`transition-duration: .1s`)};
        ${css(...rules)}
      }
    `

export const sizes = {
  mobile: 425,
  tablet: 768,
  laptop: 1024,
  desktop: 1400,

  largeStart: 1660,
  mediumStart: 1140,
}

export const media = {
  mobile: mediaQuery`(max-width: ${sizes.mobile - 0.1}px)`,
  tablet: mediaQuery`(min-width: ${sizes.mobile}px) and (max-width: ${sizes.tablet - 0.1}px)`,
  laptop: mediaQuery`(min-width: ${sizes.tablet}px) and (max-width: ${sizes.laptop - 0.1}px)`,
  desktop: mediaQuery`(min-width: ${sizes.laptop}px) and (max-width: ${sizes.desktop - 0.1}px)`,
  giant: mediaQuery`(min-width: ${sizes.desktop}px)`,

  large: mediaQuery`(min-width: ${sizes.largeStart}px)`,
  medium: mediaQuery`(min-width: ${sizes.mediumStart}px) and  (max-width: ${sizes.largeStart - 0.1}px)`,
  small: mediaQuery`(max-width: ${sizes.mediumStart - 0.1}px)`,
}

export const isMobile = () => window.innerWidth < sizes.mobile
export const isSmall = () => window.innerWidth < sizes.mediumStart
export const isMedium = () => window.innerWidth < sizes.largeStart && !isSmall()
export const isLarge = () => window.innerWidth >= sizes.largeStart

export const themeOfType = (type: PaletteType): DefaultTheme =>
  createMuiTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: sizes.small,
        md: sizes.mediumStart,
        lg: sizes.largeStart,
        xl: sizes.desktop,
      },
    },
    palette: {
      mode: type,
      type: type,
      primary: {
        main: '#0080f6',
      },
      secondary: {
        main: '#3676d2',
      },
      background: {
        default: type === 'light' ? '#fafafa' : '#303030',
      },
    },
    customPalette: {
      blue: blue,
      grey: grey,
      background: {
        subNav: type === 'light' ? '#f3f3f3' : '#3a3a3a',
        stickyNote: '#ffefb1',
        statusChip: '#deedff',
        stageCard: {
          finished: '#e2fff1',
          current: '#e2f1ff',
          pending: '#efefef',
        },
        selectedRow: type === 'light' ? '#eaeaea' : '#494949',
      },
      ticketStatusOpen: {
        backgroundColor: '#e2f1ff',
        color: '#2395FF',
      },
      ticketStatusClosed: {
        backgroundColor: red[100],
        color: red[500],
      },
      ticketStatusResolved: {
        backgroundColor: '#e2fff1',
        color: '#136A41',
      },
      selectionIcon: '#448aff',
      backgroundAlert: '#ffebee',
      archiveOrange: '#FF8F00',
      text: {
        stageCard: {
          finished: '#136A41',
          current: '#2395FF',
          pending: '#5E5E5E',
        },
        noData: type === 'light' ? '#e0e0e0' : '#fff',
      },
      border: {
        card: type === 'light' ? 'eaeaea' : 'rgba(255, 255, 255, 0.12)',
      },
    },
    typography: {
      fontFamily: ['Muli', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
      h3: {
        '@media (max-width: 1140px)': {
          fontSize: '2.2rem',
        },
      },
      // allVariants: {
      //   color: type === 'light' ? '#333' : '#fff'
      // }
      // label: {
      //   color:   type === 'light' ? '#333' : '#fff'
      // }
    },
  })
