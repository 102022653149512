import React, { Suspense, useState } from 'react'
import { graphql } from 'babel-plugin-relay/macro'

import { DialogTitle, DialogContent } from '@material-ui/core'
import { useLazyLoadQuery } from 'react-relay/hooks'
import { AddSettingDialogQuery, AddSettingDialogQueryVariables } from '../__generated__/AddSettingDialogQuery.graphql'
import { Skeleton, Alert } from '@material-ui/lab'
import { Form } from 'formik'
import styled from 'styled-components'
import { AddSettingDialogMutation } from '../__generated__/AddSettingDialogMutation.graphql'
import useNiceMutation from '../mutations/useNiceMutation'
import { media } from '../theme'
import InputField from './Form/InputField'
import NiceFormik from './Form/NiceFormik'
import DialogFormActionBar from './Form/DialogFormActionBar'
import OdysseyDialog from './OdysseyDialog'
interface Props {
  type: AddSettingDialogQueryVariables['type']
  title: string
  open: boolean
  onClose: () => void
  onAdd: () => void
}

const StyledForm = styled(Form)`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`
const FieldsContainer = styled.div`
  margin-bottom: 16px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 16px;

  ${media.medium`
    grid-template-columns: repeat(1, 1fr);
  `}

  ${media.small`
    grid-template-columns: auto;
  `}
`

const AddSettingDialog: React.FC<Props> = (props) => {
  return (
    <OdysseyDialog
      open={props.open}
      maxWidth='sm'
      fullWidth
      onClose={props.onClose}
      scroll='paper'
      style={{ maxWidth: '640px', margin: 'auto' }}
    >
      <Suspense fallback={<FormSkeleton {...props} />}>
        <AddForm {...props} />
      </Suspense>
    </OdysseyDialog>
  )
}

const AddForm: React.FC<Props> = ({ type, title, onClose, onAdd }) => {
  const data = useLazyLoadQuery<AddSettingDialogQuery>(query, { type: type })
  const fields = data.infoAddSettingForm.formSchema.fieldSet
  const [mutationError, setMutationError] = useState<null | string>(null)
  const [commit] = useNiceMutation<AddSettingDialogMutation>(mutation)

  return (
    <NiceFormik
      initialValues={{}}
      onSubmit={(values, actions) => {
        commit({
          variables: {
            input: {
              type: type,
              fields: Object.entries(values).map((value) => ({
                name: value[0],
                value: value[1] === null ? '' : (value[1] as string).toString(),
              })),
            },
          },
          onCompleted: (res, errors) => {
            actions.setSubmitting(false)
            if (errors) {
              setMutationError(errors.map((err) => err.message).join(', '))
            } else {
              onAdd()
              onClose()
            }
          },
        })
      }}
      formSchema={fields}
    >
      {({ submitForm }) => (
        <StyledForm>
          <DialogTitle>{title}</DialogTitle>
          {mutationError && <Alert severity='error'>{mutationError}</Alert>}
          <DialogContent dividers={true}>
            <FieldsContainer>
              {fields.map((field, index) => {
                return <InputField key={index} field={field} />
              })}
            </FieldsContainer>
          </DialogContent>
          <DialogFormActionBar
            onCancel={() => onClose()}
            onSubmit={() => {
              submitForm()
            }}
            cancelCta='Cancel'
            saveCta='Save'
          />
        </StyledForm>
      )}
    </NiceFormik>
  )
}

const FormSkeleton: React.FC<Props> = ({ title, onClose }) => (
  <>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <Skeleton variant='rect' height='50px' />
      <Skeleton variant='rect' height='50px' style={{ marginTop: '8px' }} />
    </DialogContent>
    <DialogFormActionBar.Skeleton onCancel={onClose} />
  </>
)

const mutation = graphql`
  mutation AddSettingDialogMutation($input: infoAddSettingInput!) {
    infoAddSetting(input: $input) {
      clientMutationId
    }
  }
`

const query = graphql`
  query AddSettingDialogQuery($type: SettingTypeEnum!) {
    infoAddSettingForm(type: $type) {
      formSchema {
        fieldSet {
          name
          type
          required
          title
          description
          format
          enum
          enumTitles
        }
      }
    }
  }
`

export default AddSettingDialog
