import React from 'react'
import { CheckboxWithLabel } from 'formik-material-ui'
import { ErrorMessage } from 'formik'
import styled from 'styled-components'
import { FieldT, FieldVariantEnum } from './types'
import { Typography } from '@material-ui/core'
import Autocomplete, { AutocompleteGetTagProps } from '@material-ui/lab/Autocomplete'
import Chip from '@material-ui/core/Chip'
import TextField from '@material-ui/core/TextField'
import { AutocompleteRenderInputParams } from '@material-ui/lab'
import { Field, useField } from 'formik'

interface Props {
  field: FieldT
  variant?: FieldVariantEnum
}

const Root = styled.div`
  & .arrayInputLabelRoot {
    margin-left: 0;
  }
`
const ErrorContainer = styled(Typography)`
  margin-top: -10px;
  color: red;
`
const ArrayInputField: React.FC<Props> = ({ field }) => {
  const [, meta, helpers] = useField(field.name)

  if (field.enum) {
    return (
      <>
        <Root>
          <Typography variant='body1'>{field.title}</Typography>
          {field.enum?.map((value, idx) => (
            <Field
              key={value}
              name={field.name}
              type='checkbox'
              label={field.title}
              Label={{ label: field.enumTitles ? field.enumTitles[idx] : value, classes: { root: 'arrayInputLabelRoot' } }}
              value={value}
              variant='outlined'
              component={CheckboxWithLabel}
            />
          ))}
        </Root>
        <ErrorMessage name={field.name} render={(msg) => <ErrorContainer variant='caption'>{msg}</ErrorContainer>} />
      </>
    )
  } else {
    const keys = field.enum === null ? ([] as string[]) : (field.enum as string[])
    const values = field.enumTitles === null ? ([] as string[]) : (field.enumTitles as string[])
    const titleMap = Object.fromEntries(keys.map((k, index) => [k, values[index]]))
    return (
      <>
        <Field
          variant='bordered'
          name={field.name}
          component={Autocomplete}
          id={field.title}
          options={keys.map((option) => option as string)}
          defaultValue={[]}
          value={meta.value}
          multiple
          freeSolo
          onChange={(_: any, value: any) => (value ? helpers.setValue(value) : helpers.setValue(null))}
          getOptionLabel={(option: string) => titleMap[option]}
          renderTags={(value: string[], getTagProps: AutocompleteGetTagProps) =>
            value.map((option: string, index: number) => <Chip key={option} color='primary' label={option} {...getTagProps({ index })} />)
          }
          renderInput={(params: AutocompleteRenderInputParams) => <TextField {...params} variant='outlined' label={field.title} />}
        />
        <ErrorMessage name={field.name} render={(msg) => <ErrorContainer variant='caption'>{msg}</ErrorContainer>} />
      </>
    )
  }
}

export default ArrayInputField
