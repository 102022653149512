/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type VoyageContainerReleaseWizard_voyage = {
    readonly slug: string;
    readonly depots: ReadonlyArray<string>;
    readonly terminals: ReadonlyArray<string>;
    readonly releaseConfirmedContainers: ReadonlyArray<{
        readonly slug: string;
        readonly releaseInvoiceStatus: string | null;
        readonly pickupReference: string | null;
        readonly pickupLocationSlug: string | null;
        readonly pickupDate: string | null;
        readonly turninReference: string | null;
        readonly turninLocationSlug: string | null;
        readonly latestTurninDate: string | null;
        readonly latestPickupDate: string | null;
        readonly bookingNumber: string | null;
        readonly folders: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly slug: string;
                    readonly name: string;
                    readonly " $fragmentRefs": FragmentRefs<"FileCreationDialog_folder">;
                } | null;
            } | null> | null;
        };
        readonly pickupOrders: ReadonlyArray<{
            readonly attachmentSlug: string;
            readonly name: string;
            readonly isImage: boolean;
            readonly " $fragmentRefs": FragmentRefs<"EntityAttachmentListPreviewer_attachment" | "EntityAttachment_attachment" | "EntityAttachmentListItem_attachment">;
        }> | null;
        readonly containerItem: {
            readonly title: string;
            readonly image: {
                readonly alt: string | null;
                readonly url: string | null;
            } | null;
            readonly stickyNoteContent: string | null;
        };
    }>;
    readonly releasableContainers: ReadonlyArray<{
        readonly slug: string;
        readonly releaseInvoiceStatus: string | null;
        readonly pickupReference: string | null;
        readonly pickupLocationSlug: string | null;
        readonly pickupDate: string | null;
        readonly turninReference: string | null;
        readonly turninLocationSlug: string | null;
        readonly latestTurninDate: string | null;
        readonly latestPickupDate: string | null;
        readonly bookingNumber: string | null;
        readonly containerItem: {
            readonly title: string;
            readonly image: {
                readonly " $fragmentRefs": FragmentRefs<"ItemImage_data">;
            } | null;
            readonly stickyNoteContent: string | null;
        };
    }>;
    readonly confirmedContainers: ReadonlyArray<{
        readonly slug: string;
        readonly bookingNumber: string | null;
        readonly warehouseContact: {
            readonly slug: string;
        } | null;
        readonly containerItem: {
            readonly title: string;
            readonly image: {
                readonly url: string | null;
                readonly alt: string | null;
            } | null;
            readonly stickyNoteContent: string | null;
        };
    }>;
    readonly " $refType": "VoyageContainerReleaseWizard_voyage";
};
export type VoyageContainerReleaseWizard_voyage$data = VoyageContainerReleaseWizard_voyage;
export type VoyageContainerReleaseWizard_voyage$key = {
    readonly " $data"?: VoyageContainerReleaseWizard_voyage$data;
    readonly " $fragmentRefs": FragmentRefs<"VoyageContainerReleaseWizard_voyage">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "releaseInvoiceStatus",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pickupReference",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pickupLocationSlug",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pickupDate",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "turninReference",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "turninLocationSlug",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "latestTurninDate",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "latestPickupDate",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bookingNumber",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "alt",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stickyNoteContent",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "VoyageContainerReleaseWizard_voyage",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "depots",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "terminals",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Container",
      "kind": "LinkedField",
      "name": "releaseConfirmedContainers",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/),
        (v6/*: any*/),
        (v7/*: any*/),
        (v8/*: any*/),
        (v9/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "FolderConnection",
          "kind": "LinkedField",
          "name": "folders",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "FolderEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Folder",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v10/*: any*/),
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "FileCreationDialog_folder"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "EntityAttachment",
          "kind": "LinkedField",
          "name": "pickupOrders",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "attachmentSlug",
              "storageKey": null
            },
            (v10/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isImage",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "EntityAttachmentListPreviewer_attachment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "EntityAttachment_attachment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "EntityAttachmentListItem_attachment"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ListItem",
          "kind": "LinkedField",
          "name": "containerItem",
          "plural": false,
          "selections": [
            (v11/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Image",
              "kind": "LinkedField",
              "name": "image",
              "plural": false,
              "selections": [
                (v12/*: any*/),
                (v13/*: any*/)
              ],
              "storageKey": null
            },
            (v14/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Container",
      "kind": "LinkedField",
      "name": "releasableContainers",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/),
        (v6/*: any*/),
        (v7/*: any*/),
        (v8/*: any*/),
        (v9/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ListItem",
          "kind": "LinkedField",
          "name": "containerItem",
          "plural": false,
          "selections": [
            (v11/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Image",
              "kind": "LinkedField",
              "name": "image",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ItemImage_data"
                }
              ],
              "storageKey": null
            },
            (v14/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Container",
      "kind": "LinkedField",
      "name": "confirmedContainers",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v9/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Contact",
          "kind": "LinkedField",
          "name": "warehouseContact",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ListItem",
          "kind": "LinkedField",
          "name": "containerItem",
          "plural": false,
          "selections": [
            (v11/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Image",
              "kind": "LinkedField",
              "name": "image",
              "plural": false,
              "selections": [
                (v13/*: any*/),
                (v12/*: any*/)
              ],
              "storageKey": null
            },
            (v14/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Voyage",
  "abstractKey": null
};
})();
(node as any).hash = '2c7f44f9c8eca5d44607c3dcff273cdf';
export default node;
