import React, { Suspense } from 'react'
import ResponsiveGrid from '../../components/ResponsiveGrid'
import SettingsCard from './SettingsCard'

const SuspendedSettingCard: React.FC = ({ children }) => <Suspense fallback={<>Loading...</>}>{children}</Suspense>

const HomeContent: React.FC = () => {
  const firstCol = [
    <SuspendedSettingCard key='shipping_line'>
      <SettingsCard type='shipping_lines' />
    </SuspendedSettingCard>,
    <SuspendedSettingCard key='port'>
      <SettingsCard type='ports' />
    </SuspendedSettingCard>,
    <SuspendedSettingCard key='document_type'>
      <SettingsCard type='document_types' />
    </SuspendedSettingCard>,
    <SuspendedSettingCard key='customs_type'>
      <SettingsCard type='customs_types' />
    </SuspendedSettingCard>,
  ]
  const secondCol = [
    <SuspendedSettingCard key='rate_card'>
      <SettingsCard type='rate_cards' />
    </SuspendedSettingCard>,
    // <SuspendedSettingCard key='language'>
    //   <SettingsCard type='languages' />
    // </SuspendedSettingCard>,
    <SuspendedSettingCard key='role'>
      <SettingsCard type='roles' />
    </SuspendedSettingCard>,
    <SuspendedSettingCard key='shipment_type'>
      <SettingsCard type='shipment_types' />
    </SuspendedSettingCard>,
  ]
  const thirdCol = [
    <SuspendedSettingCard key='document_template'>
      <SettingsCard type='document_templates' />
    </SuspendedSettingCard>,
    <SuspendedSettingCard key='email_template'>
      <SettingsCard type='email_templates' />
    </SuspendedSettingCard>,
    <SuspendedSettingCard key='default_folder'>
      <SettingsCard type='default_folders' />
    </SuspendedSettingCard>,
    <SuspendedSettingCard key='miscellaneous_settings'>
      <SettingsCard type='miscellaneous_settings' />
    </SuspendedSettingCard>,
  ]
  return <ResponsiveGrid type='default-layout' left={firstCol} middle={secondCol} right={thirdCol} />
}

export default HomeContent
