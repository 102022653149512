/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type EmailEntityTypeEnum = "contacts" | "containers" | "shipments" | "voyages" | "%future added value";
export type infoSendEmailInput = {
    clientMutationId?: string | null;
    subject: string;
    to: Array<string>;
    body: string;
    entityType: EmailEntityTypeEnum;
    entitySlug: string;
    templateSlug?: string | null;
    attachments?: Array<string> | null;
};
export type SendEmailDialogMutationVariables = {
    input: infoSendEmailInput;
};
export type SendEmailDialogMutationResponse = {
    readonly infoSendEmail: {
        readonly clientMutationId: string | null;
    } | null;
};
export type SendEmailDialogMutation = {
    readonly response: SendEmailDialogMutationResponse;
    readonly variables: SendEmailDialogMutationVariables;
};



/*
mutation SendEmailDialogMutation(
  $input: infoSendEmailInput!
) {
  infoSendEmail(input: $input) {
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "infoSendEmailPayload",
    "kind": "LinkedField",
    "name": "infoSendEmail",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SendEmailDialogMutation",
    "selections": (v1/*: any*/),
    "type": "ApplicationCommand",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SendEmailDialogMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5168d6162ce9dd9130a0299058482333",
    "id": null,
    "metadata": {},
    "name": "SendEmailDialogMutation",
    "operationKind": "mutation",
    "text": "mutation SendEmailDialogMutation(\n  $input: infoSendEmailInput!\n) {\n  infoSendEmail(input: $input) {\n    clientMutationId\n  }\n}\n"
  }
};
})();
(node as any).hash = '16dd273b82ce1a1447170c4498d23d99';
export default node;
