import { graphql } from 'babel-plugin-relay/macro'
import React, { Suspense } from 'react'
import { AddNotes } from '../../components/Notes'
import ResponsiveGrid from '../../components/ResponsiveGrid'
import Timeline from '../../components/Timeline/Timeline'
import { VoyageShowHomeContentQuery } from '../../__generated__/VoyageShowHomeContentQuery.graphql'
import ETACard from '../../components/ETACard'
import ItemListCard from '../../components/ItemListCard'
import useLazyLoadQueryWithSubscription from '../../hooks/useLazyLoadQueryWithSubscription'
import EmailsCard from '../../components/Email/EmailsCard'

interface Props {
  slug: string
}

const VoyageShowHomeContent: React.FC<Props> & { Skeleton: React.FC } = ({ slug }) => {
  const queryData = useLazyLoadQueryWithSubscription<VoyageShowHomeContentQuery>(
    query,
    { slug: slug },
    { subscriptionOptions: { entityType: 'voyages', entitySlug: slug } }
  )
  const { voyageShow: voyage } = queryData

  return (
    <ResponsiveGrid
      type='show-layout'
      highlight={[
        <Suspense key='containers-card' fallback={<ItemListCard.Skeleton />}>
          <ItemListCard type='containers' parentAbsoluteSlug={`voyages/${slug}`} title='Containers' entityTitle='Container' />
        </Suspense>,
      ]}
      right={[
        <ETACard key='eta' voyage={voyage} />,
        <Suspense key='notes' fallback={<AddNotes.Skeleton />}>
          <AddNotes entitySlug={voyage.slug} entityType='voyages' />
        </Suspense>,
        <EmailsCard key='emails' entityType='voyages' entitySlug={slug} emails={voyage.emails} />,
        <Suspense key='timeline' fallback={<Timeline.Skeleton />}>
          <Timeline slug={slug} type='voyages' />
        </Suspense>,
      ]}
    />
  )
}

const query = graphql`
  query VoyageShowHomeContentQuery($slug: String!) {
    voyageShow(slug: $slug) {
      slug
      ...ETACard_voyage

      containerItems {
        slug
        ...ShowPageListItem_listItem
      }
      emails {
        ...EmailsCard_emails
      }
    }
  }
`

VoyageShowHomeContent.Skeleton = () => <>Loading...</>

export default VoyageShowHomeContent
