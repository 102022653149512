/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ShipmentTypeEnum = "boat" | "car" | "motor" | "oldtimer" | "other" | "package" | "quad" | "truck" | "%future added value";
export type ShipmentSiblingCompositionCard_shipment = {
    readonly container: {
        readonly composition: {
            readonly totalShipments: number;
            readonly shipmentCountsByType: ReadonlyArray<{
                readonly type: ShipmentTypeEnum;
                readonly typeLabel: string;
                readonly count: number;
            }>;
        };
    };
    readonly " $refType": "ShipmentSiblingCompositionCard_shipment";
};
export type ShipmentSiblingCompositionCard_shipment$data = ShipmentSiblingCompositionCard_shipment;
export type ShipmentSiblingCompositionCard_shipment$key = {
    readonly " $data"?: ShipmentSiblingCompositionCard_shipment$data;
    readonly " $fragmentRefs": FragmentRefs<"ShipmentSiblingCompositionCard_shipment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ShipmentSiblingCompositionCard_shipment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Container",
      "kind": "LinkedField",
      "name": "container",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ContainerComposition",
          "kind": "LinkedField",
          "name": "composition",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalShipments",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ShipmentCountBy",
              "kind": "LinkedField",
              "name": "shipmentCountsByType",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "type",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "typeLabel",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "count",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Shipment",
  "abstractKey": null
};
(node as any).hash = '86c0c36ed4d9dffa3ae135de5aee6190';
export default node;
