import React, { useContext, useState } from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import styled from 'styled-components'
import { EntityAttachmentRoleVisibility_attachment$key } from '../../__generated__/EntityAttachmentRoleVisibility_attachment.graphql'
import { useFragment } from 'react-relay/hooks'
import { AvatarGroup, Alert } from '@material-ui/lab'
import {
  Avatar,
  Divider,
  IconButton,
  DialogTitle,
  DialogContent,
  List,
  ListItemIcon,
  Checkbox,
  ListItemText,
  ListItem,
  Typography,
} from '@material-ui/core'
import { Edit } from '@material-ui/icons'
import useNiceMutation from '../../mutations/useNiceMutation'
import OdysseyDialog from '../OdysseyDialog'
import { EntityAttachmentRoleVisibilityMutation } from '../../__generated__/EntityAttachmentRoleVisibilityMutation.graphql'
import NiceFormik from '../Form/NiceFormik'
import DialogFormActionBar from '../Form/DialogFormActionBar'
import SessionContext from '../../SessionContext'

interface Props {
  attachment: EntityAttachmentRoleVisibility_attachment$key
}

const Root = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${(props) => props.theme.palette.divider};
`

const StyledAvatarGroup = styled(AvatarGroup)`
  & > .MuiAvatarGroup-avatar {
    height: 35px;
    width: 35px;
    font-size: 1rem;
    background-color: ${(props) => props.theme.palette.grey[700]};
  }
`

const StyledDialogContent = styled(DialogContent)`
  padding: 0;
`

const EntityAttachmentRoleVisibility: React.FC<Props> = ({ attachment: data }) => {
  const { user } = useContext(SessionContext)
  const attachment = useFragment(fragment, data)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [commitVisibilityChange, _, visibilityChangeError] = useNiceMutation<EntityAttachmentRoleVisibilityMutation>(mutation)
  const [editOpen, setEditOpen] = useState(false)
  const handleEditClose = () => setEditOpen(false)
  const filteredRoleVisbilities = attachment.roleVisibilities.filter((roleVisibility) => roleVisibility.visible)

  return (
    <>
      <Root>
        {filteredRoleVisbilities.length > 0 ? (
          <StyledAvatarGroup max={4}>
            {filteredRoleVisbilities.map((roleVisibility) => (
              <Avatar key={roleVisibility.role} alt={roleVisibility.roleTitle} title={roleVisibility.roleTitle}>
                {roleVisibility.roleShortTitle}
              </Avatar>
            ))}
          </StyledAvatarGroup>
        ) : (
          <Typography variant='body2'>Private</Typography>
        )}
        <Divider orientation='vertical' />
        {user?.contact?.contactType === 'internal' && (
          <IconButton onClick={() => setEditOpen(true)} size='small'>
            <Edit htmlColor='white' />
          </IconButton>
        )}
      </Root>
      <OdysseyDialog open={editOpen} fullWidth style={{ maxWidth: '640px', margin: 'auto' }} scroll='paper' onClose={handleEditClose}>
        <NiceFormik
          initialValues={{ roleVisibilities: attachment.roleVisibilities }}
          onSubmit={(values, actions) => {
            commitVisibilityChange({
              variables: {
                input: {
                  entityType: attachment.entityType,
                  entitySlug: attachment.entitySlug,
                  attachmentSlug: attachment.attachmentSlug,
                  visibleToRoles: values.roleVisibilities
                    .filter((roleVisibility) => roleVisibility.visible)
                    .map((roleVisibility) => roleVisibility.role),
                },
              },
              onCompleted: (_, errors) => {
                actions.setSubmitting(false)
                if (!errors) {
                  handleEditClose()
                }
              },
            })
          }}
        >
          {({ values, setFieldValue, submitForm }) => (
            <>
              <DialogTitle>Visibility for {attachment.name}</DialogTitle>
              {visibilityChangeError && <Alert severity='error'>{visibilityChangeError}</Alert>}
              <StyledDialogContent dividers>
                <List>
                  {values.roleVisibilities.map((roleVisibility, idx) => (
                    <ListItem
                      key={roleVisibility.role}
                      role={undefined}
                      button
                      onClick={() => setFieldValue(`roleVisibilities[${idx}].visible`, !roleVisibility.visible)}
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge='start'
                          checked={roleVisibility.visible}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': `${roleVisibility.role}-${idx}` }}
                        />
                      </ListItemIcon>
                      <ListItemText id={`${roleVisibility.role}-${idx}`} primary={`${roleVisibility.roleTitle}`} />
                    </ListItem>
                  ))}
                </List>
              </StyledDialogContent>
              <DialogFormActionBar
                onCancel={() => handleEditClose()}
                onSubmit={() => {
                  submitForm()
                }}
                cancelCta='Cancel'
                saveCta='Save'
              />
            </>
          )}
        </NiceFormik>
      </OdysseyDialog>
    </>
  )
}

const fragment = graphql`
  fragment EntityAttachmentRoleVisibility_attachment on EntityAttachment {
    name
    attachmentSlug
    entityType
    entitySlug
    roleVisibilities {
      role
      roleShortTitle
      roleTitle
      visible
    }
  }
`

const mutation = graphql`
  mutation EntityAttachmentRoleVisibilityMutation($input: infoChangeAttachmentVisibilityInput!) {
    infoChangeAttachmentVisibility(input: $input) {
      attachment {
        ...EntityAttachment_attachment
      }
    }
  }
`

export default EntityAttachmentRoleVisibility
