/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type infoShareContactsInput = {
    clientMutationId?: string | null;
    contactSlugs: string;
    slug: string;
};
export type ShareContactMutationVariables = {
    input: infoShareContactsInput;
};
export type ShareContactMutationResponse = {
    readonly infoShareContacts: {
        readonly clientMutationId: string | null;
    } | null;
};
export type ShareContactMutation = {
    readonly response: ShareContactMutationResponse;
    readonly variables: ShareContactMutationVariables;
};



/*
mutation ShareContactMutation(
  $input: infoShareContactsInput!
) {
  infoShareContacts(input: $input) {
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "infoShareContactsPayload",
    "kind": "LinkedField",
    "name": "infoShareContacts",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ShareContactMutation",
    "selections": (v1/*: any*/),
    "type": "ApplicationCommand",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ShareContactMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "704fcb0b292d84fec4b13e7695c265c8",
    "id": null,
    "metadata": {},
    "name": "ShareContactMutation",
    "operationKind": "mutation",
    "text": "mutation ShareContactMutation(\n  $input: infoShareContactsInput!\n) {\n  infoShareContacts(input: $input) {\n    clientMutationId\n  }\n}\n"
  }
};
})();
(node as any).hash = '7db60a10487846d8ff8f4b88eb7593f3';
export default node;
