import { Typography } from '@material-ui/core'
import { graphql } from 'babel-plugin-relay/macro'
import React from 'react'
import styled from 'styled-components'
import Field from '../../components/Field'
import ResponsiveGrid from '../../components/ResponsiveGrid'
import { media } from '../../theme'
import { useLazyLoadQuery } from 'react-relay/hooks'
import { DraftContactShowQuery } from '../../__generated__/DraftContactShowQuery.graphql'
import { ContactHeader, HeroContainer, ProfileContainer, Title, TitleContainer } from '../../components/ShowPageItem'
import ItemImage from '../../components/ItemImage'
import Hero from '../../components/Hero'
import { NiceTabPanel } from '../../components/TabsUI/TabsUI'
import { DetailsCard, KeyFieldsContainer } from '../ExternalContact/ExternalContactShowHeroCard'
import KeyField from '../../components/KeyField'
import SemanticLine from '../../components/SemanticLine'

interface Props {
  slug: string
}

const BorderedCard = styled.div`
  border: 1px solid ${(props) => props.theme.customPalette.border.card};
  border-radius: 8px;
  margin-bottom: 16px;
  padding: 16px;

  :last-child {
    margin-bottom: unset;
  }
`

const DetailsContainer = styled.div`
  margin: 4px 0;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 8px;

  ${media.medium`
    grid-template-columns: repeat(3, 1fr);
  `}

  ${media.small`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${media.mobile`
    grid-template-columns: repeat(1, 1fr);
  `}
`

const DraftContactShow: React.FC<Props> & { Skeleton: React.FC } = ({ slug }) => {
  const data = useLazyLoadQuery<DraftContactShowQuery>(query, { slug: slug })
  const contact = data.draftContactShow
  return (
    <>
      <Hero backgroundColor='transparent'>
        <HeroContainer>
          <ProfileContainer>{contact.image && <ItemImage data={contact.image} />}</ProfileContainer>
          <ContactHeader>
            <TitleContainer>
              <Title variant='subtitle1' color='textPrimary'>
                {contact.name}
              </Title>
            </TitleContainer>
            {contact.lines?.map((line) => (
              <SemanticLine key={line.key} data={line} />
            ))}
            <DetailsCard variant='outlined'>
              {contact.keyFields && (
                <KeyFieldsContainer>
                  {contact.keyFields.map((field) => (
                    <KeyField key={field.key} data={field} />
                  ))}
                </KeyFieldsContainer>
              )}
            </DetailsCard>
          </ContactHeader>
        </HeroContainer>
      </Hero>
      <NiceTabPanel forTab='home' activeTab='home'>
        <ResponsiveGrid
          type='show-layout'
          highlight={[
            <BorderedCard key={0}>
              <Typography variant='h6'>Contact Details</Typography>
              <DetailsContainer>
                <Field label='Contact Form' value={contact.format} />
                <Field label='Company' value={contact.company} />
                <Field label='Address' value={contact.address} />
              </DetailsContainer>
              <DetailsContainer>
                <Field label='Postcode' value={contact.postcode} />
                <Field label='City' value={contact.place} />
                <Field label='State' value={contact.state} />
                <Field label='Business Phone' value={contact.businessPhone} />
                <Field label='Language' value={contact.language} />
                <Field label='Country' value={contact.country} />
              </DetailsContainer>
            </BorderedCard>,
          ]}
        />
      </NiceTabPanel>
    </>
  )
}

const query = graphql`
  query DraftContactShowQuery($slug: String!) {
    draftContactShow(slug: $slug) {
      format
      address
      postcode
      place
      company
      country
      language
      businessPhone
      state
      name
      image {
        ...ItemImage_data
      }
      keyFields {
        key
        ...KeyField_data
      }
      lines {
        key
        ...SemanticLine_data
      }
    }
  }
`

DraftContactShow.Skeleton = () => <>Loading...</>

export default DraftContactShow
