/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ImageShapeEnum = "circle" | "container" | "shipment" | "square" | "vessel" | "voyage" | "%future added value";
export type SettingItemImage_data = {
    readonly url: string | null;
    readonly shape: ImageShapeEnum | null;
    readonly overlay: string | null;
    readonly " $refType": "SettingItemImage_data";
};
export type SettingItemImage_data$data = SettingItemImage_data;
export type SettingItemImage_data$key = {
    readonly " $data"?: SettingItemImage_data$data;
    readonly " $fragmentRefs": FragmentRefs<"SettingItemImage_data">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SettingItemImage_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shape",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "overlay",
      "storageKey": null
    }
  ],
  "type": "Image",
  "abstractKey": null
};
(node as any).hash = '634098936fcb8bf785d08190e43f18ec';
export default node;
