import React from 'react'
import { useLazyLoadQuery } from 'react-relay/hooks'
import NiceFormik from './Form/NiceFormik'
import { graphql } from 'babel-plugin-relay/macro'
import useNiceMutation from '../mutations/useNiceMutation'
import { Form } from 'formik'
import DraftContactForm from './DraftContactForm'
import { DialogActions, DialogContent } from '@material-ui/core'
import LoadingDots from './LoadingDots'
import Button from './Button'
import { DraftContactEditQuery } from '../__generated__/DraftContactEditQuery.graphql'
import { DraftContactEditMutation } from '../__generated__/DraftContactEditMutation.graphql'
import { FormValues } from '../pages/Contact/ContactAdd'

interface Props {
  ticketSlug: string
  open: boolean
  onClose: () => void
}

const DraftContactEdit: React.FC<Props> = (props) => {
  const data = useLazyLoadQuery<DraftContactEditQuery>(query, { ticketSlug: props.ticketSlug })
  const fields = data.infoUpdateDraftContactForm.formSchema.fieldSet
  const fieldMaster = Object.fromEntries(fields.map((field) => [field.name, field]))
  const [commit, loading] = useNiceMutation<DraftContactEditMutation>(mutation)
  return (
    <>
      <NiceFormik
        initialValues={data.infoUpdateDraftContactForm.initialValue || { roles: [], address: '', businessPhone: '', place: '' }}
        validate={(values) => {
          const errors: Partial<FormValues> = {}
          if (!(values.roles as string[]).includes('transporter') && !values.address) {
            errors.address = 'Address is a required field'
          }
          if (!(values.roles as string[]).includes('transporter') && !values.place) {
            errors.place = 'City is a required field'
          }
          if ((values.roles as string[]).includes('transporter') && !values.businessPhone) {
            errors.businessPhone = 'businessPhone is a required field'
          }
          return errors
        }}
        onSubmit={(values, actions) => {
          commit({
            variables: {
              input: values as any,
            },
            onError: () => actions.setSubmitting(false),
            onCompleted: (res, errors) => {
              actions.setSubmitting(false)
              if (!errors) {
                props.onClose()
              }
            },
          })
        }}
        formSchema={fields}
      >
        {({ submitForm }) => (
          <Form>
            <DialogContent dividers={true}>
              <DraftContactForm fieldMaster={fieldMaster} mode='edit'></DraftContactForm>
            </DialogContent>
            <DialogActions>
              <Button onClick={props.onClose} variant='contained'>
                Cancel
              </Button>
              <Button onClick={submitForm} variant='contained'>
                {loading ? <LoadingDots /> : 'Update'}
              </Button>
            </DialogActions>
          </Form>
        )}
      </NiceFormik>
    </>
  )
}

const query = graphql`
  query DraftContactEditQuery($ticketSlug: String!) {
    infoUpdateDraftContactForm(ticketSlug: $ticketSlug) {
      formSchema {
        fieldSet {
          name
          type
          required
          title
          description
          format
          enum
          enumTitles
          placeholder
        }
      }
      initialValue {
        ticketSlug
        format
        salutation
        firstName
        lastName
        email
        businessPhone
        company
        country
        state
        place
        postcode
        address
        language
        roles
      }
    }
  }
`

const mutation = graphql`
  mutation DraftContactEditMutation($input: infoUpdateDraftContactInput!) {
    infoUpdateDraftContact(input: $input) {
      clientMutationId
    }
  }
`

export default DraftContactEdit
