import { Typography, MenuItem, TextField } from '@material-ui/core'
import { graphql } from 'babel-plugin-relay/macro'
import { parse } from 'qs'
import React, { Suspense } from 'react'
import { useLazyLoadQuery } from 'react-relay/hooks'
import { useLocation } from 'react-router'
import styled from 'styled-components'
import ResponsiveGrid from '../../components/ResponsiveGrid'
import { isSmall } from '../../theme'
import useWorkspaceNavigate from '../../hooks/useWorkspaceNavigate'
import { ExternalRelatedItemListQuery, ExternalRelatedItemListQueryVariables } from '../../__generated__/ExternalRelatedItemListQuery.graphql'
import ExternalRelatedItemListContent from './ExternalRelatedItemListContent'
import { ExternalItemType } from '../../viewTypes'
import { ExternalRelatedItemListFiltersQuery } from '../../__generated__/ExternalRelatedItemListFiltersQuery.graphql'
import ExternalItemListFilters from './ExternalItemListFilters'

interface Props {
  type: ExternalItemType
  parentAbsoluteSlug?: string
  contactSlug?: string
}

const Titlebar = styled.div`
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Sort = styled(TextField)`
  min-width: 200px;
`

const FiltersContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 8px;
`

const RelatedItemList: React.FC<Props> & { Skeleton: React.FC } = ({ type, parentAbsoluteSlug, contactSlug }) => {
  const location = useLocation()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {
    searchQuery,
    sortKey,
    perPage: perPageRaw,
    page: pageRaw,
    ...queryVariables
  } = parse(location.search, {
    ignoreQueryPrefix: true,
  })

  const filters = Object.entries(queryVariables).map((entry) => ({ key: entry[0], values: entry[1] as string[] }))
  const page = Number.parseInt(pageRaw as string) || 1
  const perPage = Number.parseInt(perPageRaw as string) || 10
  const wsNavigate = useWorkspaceNavigate()
  const handleSortToggle = (key: string) => wsNavigate.relative({ sortKey: key })

  const variables: ExternalRelatedItemListQueryVariables = {
    type,
    parentAbsoluteSlug,
    searchQuery: `${searchQuery || ''}`,
    filters,
    sortKey: `${sortKey || ''}`,
    page,
    perPage,
    includeArchived: true,
  }
  const data = useLazyLoadQuery<ExternalRelatedItemListQuery>(query, variables)
  const filtersData = useLazyLoadQuery<ExternalRelatedItemListFiltersQuery>(filtersQuery, variables)

  return (
    <>
      <ResponsiveGrid
        type='show-layout'
        highlight={[
          <ExternalRelatedItemListContent key='item-list' data={data.externalItemList} type={type} contactSlug={contactSlug as string} />,
        ]}
        right={
          isSmall()
            ? []
            : [
                <Titlebar key='title'>
                  <Typography variant='body1'>1-{data.externalItemList.totalCount}</Typography>
                  <Sort
                    select
                    size='small'
                    variant='outlined'
                    value={variables.sortKey}
                    label='Sort By'
                    onChange={(e) => handleSortToggle(e.target.value)}
                  >
                    {data.externalItemList.sortKeys.map((option) => (
                      <MenuItem key={option.key} value={option.key}>
                        {option.title}
                      </MenuItem>
                    ))}
                  </Sort>
                </Titlebar>,
                <FiltersContainer key='filters'>
                  <Suspense fallback={<>Loading...</>}>
                    <ExternalItemListFilters data={filtersData.externalItemList} listType={type} showDataFilter={false} />
                  </Suspense>
                </FiltersContainer>,
              ]
        }
      />
    </>
  )
}

const query = graphql`
  query ExternalRelatedItemListQuery(
    $searchQuery: String
    $type: ExternalListItemTypeEnum
    $parentAbsoluteSlug: String
    $filters: [FilterInput!]
    $includeArchived: Boolean
    $sortKey: String
    $page: Int
    $perPage: Int
  ) {
    externalItemList(
      searchQuery: $searchQuery
      type: $type
      parentAbsoluteSlug: $parentAbsoluteSlug
      filters: $filters
      includeArchived: $includeArchived
      sortKey: $sortKey
      page: $page
      perPage: $perPage
    ) {
      ...ExternalItemListFooter_data
      ...ExternalRelatedItemListContent_data
      sortKeys {
        key
        title
      }
      totalCount
      downloadToken
    }
  }
`

const filtersQuery = graphql`
  query ExternalRelatedItemListFiltersQuery(
    $searchQuery: String
    $type: ExternalListItemTypeEnum
    $parentAbsoluteSlug: String
    $filters: [FilterInput!]
    $includeArchived: Boolean
    $sortKey: String
    $page: Int
    $perPage: Int
  ) {
    externalItemList(
      searchQuery: $searchQuery
      type: $type
      parentAbsoluteSlug: $parentAbsoluteSlug
      filters: $filters
      includeArchived: $includeArchived
      sortKey: $sortKey
      page: $page
      perPage: $perPage
      showFilters: true
    ) {
      ...ExternalItemListFilters_data
    }
  }
`

RelatedItemList.Skeleton = () => <>Loading...</>

export default RelatedItemList
