/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LinkedContactsTab_linkedContacts = {
    readonly edges: ReadonlyArray<{
        readonly node: {
            readonly contactSlug: string;
            readonly role: string;
            readonly contactItem: {
                readonly title: string;
            };
            readonly " $fragmentRefs": FragmentRefs<"ShowLinkedContactsItem_linkedContact">;
        } | null;
    } | null> | null;
    readonly " $refType": "LinkedContactsTab_linkedContacts";
};
export type LinkedContactsTab_linkedContacts$data = LinkedContactsTab_linkedContacts;
export type LinkedContactsTab_linkedContacts$key = {
    readonly " $data"?: LinkedContactsTab_linkedContacts$data;
    readonly " $fragmentRefs": FragmentRefs<"LinkedContactsTab_linkedContacts">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LinkedContactsTab_linkedContacts",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ContactLinkEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ContactLink",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "contactSlug",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "role",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ListItem",
              "kind": "LinkedField",
              "name": "contactItem",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ShowLinkedContactsItem_linkedContact"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ContactLinkConnection",
  "abstractKey": null
};
(node as any).hash = '15ea4311006ccb93df582cb7ce6bbb24';
export default node;
