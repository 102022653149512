/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type infoAddContainerInput = {
    clientMutationId?: string | null;
    containerNumber: string;
    profileImageSlug?: string | null;
    voyageSlug: string;
    containerType: string;
    containerSize?: string | null;
    procedure?: string | null;
    atb?: string | null;
    bookingNumber?: string | null;
    turninLocationSlug?: string | null;
    pickupLocationSlug?: string | null;
    pickupReference?: string | null;
    turninReference?: string | null;
    billOfLadingNumber?: string | null;
    terminalDate?: string | null;
    dischargeDate?: string | null;
    latestPickupDate?: string | null;
    pickupDate?: string | null;
    latestTurninDate?: string | null;
    thcPaidDate?: string | null;
    pickupComment?: string | null;
    linkedContacts: Array<LinkedContactInput>;
    shippingLineSlug?: string | null;
    shipments?: Array<ShipmentQuickAddInput> | null;
};
export type LinkedContactInput = {
    role: string;
    contactSlug: string;
};
export type ShipmentQuickAddInput = {
    shipmentType: string;
    vin?: string | null;
    description: string;
    weight?: number | null;
    vinEngine?: string | null;
    vinFuelType?: string | null;
    vinBodyClass?: string | null;
    vinOrigin?: string | null;
};
export type ContainerAddMutationVariables = {
    input: infoAddContainerInput;
};
export type ContainerAddMutationResponse = {
    readonly infoAddContainer: {
        readonly container: {
            readonly slug: string;
        };
    } | null;
};
export type ContainerAddMutation = {
    readonly response: ContainerAddMutationResponse;
    readonly variables: ContainerAddMutationVariables;
};



/*
mutation ContainerAddMutation(
  $input: infoAddContainerInput!
) {
  infoAddContainer(input: $input) {
    container {
      slug
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ContainerAddMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "infoAddContainerPayload",
        "kind": "LinkedField",
        "name": "infoAddContainer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Container",
            "kind": "LinkedField",
            "name": "container",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ApplicationCommand",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ContainerAddMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "infoAddContainerPayload",
        "kind": "LinkedField",
        "name": "infoAddContainer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Container",
            "kind": "LinkedField",
            "name": "container",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e76c8652231ceb35f2187e1a20f1f01f",
    "id": null,
    "metadata": {},
    "name": "ContainerAddMutation",
    "operationKind": "mutation",
    "text": "mutation ContainerAddMutation(\n  $input: infoAddContainerInput!\n) {\n  infoAddContainer(input: $input) {\n    container {\n      slug\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '236a9eb005918c2a2db158b75bf38978';
export default node;
