/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type StageStatusEnum = "active" | "completed" | "incomplete" | "%future added value";
export type Stage_stage = {
    readonly value: string;
    readonly status: StageStatusEnum;
    readonly label: string;
    readonly completedAt: string | null;
    readonly ownedBy: string | null;
    readonly " $refType": "Stage_stage";
};
export type Stage_stage$data = Stage_stage;
export type Stage_stage$key = {
    readonly " $data"?: Stage_stage$data;
    readonly " $fragmentRefs": FragmentRefs<"Stage_stage">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Stage_stage",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ownedBy",
      "storageKey": null
    }
  ],
  "type": "WorkflowStage",
  "abstractKey": null
};
(node as any).hash = '49d5ca5cecc62decef4476392fe90d05';
export default node;
