import { graphql } from 'babel-plugin-relay/macro'
import React from 'react'
import { useFragment } from 'react-relay/hooks'
import styled from 'styled-components'
import Pagination from '../../components/Pagination'
import { ViewMode } from '../../viewTypes'
import { ItemListFooter_data$key } from '../../__generated__/ItemListFooter_data.graphql'

interface Props {
  data: ItemListFooter_data$key
  viewMode?: ViewMode
  onPageChange: (page: number) => void
}

const RootContainer = styled.div`
  width: 100%;
  height: 100%;
`

const ItemListFooter: React.FC<Props> = ({ data, onPageChange }) => {
  const { paginationMeta } = useFragment(fragment, data)

  return (
    <RootContainer>
      <>
        {paginationMeta && (
          <Pagination
            isFirst={!paginationMeta.hasPrevPage}
            isLast={!paginationMeta.hasNextPage}
            currentPage={paginationMeta.page}
            pageCount={paginationMeta.totalPages}
            onChange={(page) => onPageChange(page)}
          />
        )}
      </>
    </RootContainer>
  )
}

const fragment = graphql`
  fragment ItemListFooter_data on ItemList {
    paginationMeta {
      hasNextPage
      hasPrevPage
      page
      perPage
      totalPages
    }
  }
`

export default ItemListFooter
