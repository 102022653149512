/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type TemplateEntityTypeEnum = "contacts" | "containers" | "shipments" | "%future added value";
export type ShipmentAutomaticHandoverQueryVariables = {
    entityType: TemplateEntityTypeEnum;
    entitySlug: string;
    templateSlug: string;
};
export type ShipmentAutomaticHandoverQueryResponse = {
    readonly generatableDocumentValidation: {
        readonly templateSlug: string;
        readonly title: string;
        readonly docType: string;
        readonly errors: ReadonlyArray<string>;
        readonly handoverDatePresent: boolean;
        readonly information: {
            readonly handoverDate: string | null;
            readonly transporterName: string | null;
            readonly warehouseEmail: string | null;
        } | null;
    };
};
export type ShipmentAutomaticHandoverQuery = {
    readonly response: ShipmentAutomaticHandoverQueryResponse;
    readonly variables: ShipmentAutomaticHandoverQueryVariables;
};



/*
query ShipmentAutomaticHandoverQuery(
  $entityType: TemplateEntityTypeEnum!
  $entitySlug: String!
  $templateSlug: String!
) {
  generatableDocumentValidation(entityType: $entityType, entitySlug: $entitySlug, templateSlug: $templateSlug) {
    templateSlug
    title
    docType
    errors
    handoverDatePresent
    information {
      handoverDate
      transporterName
      warehouseEmail
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "entitySlug"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "entityType"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "templateSlug"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "entitySlug",
        "variableName": "entitySlug"
      },
      {
        "kind": "Variable",
        "name": "entityType",
        "variableName": "entityType"
      },
      {
        "kind": "Variable",
        "name": "templateSlug",
        "variableName": "templateSlug"
      }
    ],
    "concreteType": "GeneratableDocument",
    "kind": "LinkedField",
    "name": "generatableDocumentValidation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "templateSlug",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "docType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "handoverDatePresent",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AutomaticHandoverInformation",
        "kind": "LinkedField",
        "name": "information",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "handoverDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "transporterName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "warehouseEmail",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ShipmentAutomaticHandoverQuery",
    "selections": (v3/*: any*/),
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "ShipmentAutomaticHandoverQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "074352cb1fc4a21da3de0e202ea97c80",
    "id": null,
    "metadata": {},
    "name": "ShipmentAutomaticHandoverQuery",
    "operationKind": "query",
    "text": "query ShipmentAutomaticHandoverQuery(\n  $entityType: TemplateEntityTypeEnum!\n  $entitySlug: String!\n  $templateSlug: String!\n) {\n  generatableDocumentValidation(entityType: $entityType, entitySlug: $entitySlug, templateSlug: $templateSlug) {\n    templateSlug\n    title\n    docType\n    errors\n    handoverDatePresent\n    information {\n      handoverDate\n      transporterName\n      warehouseEmail\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '859044b18316285f107d21f4cebe0dac';
export default node;
