import React, { useState, useContext } from 'react'
import { graphql } from 'babel-plugin-relay/macro'

import { DialogTitle, DialogActions } from '@material-ui/core'
import styled from 'styled-components'
import { ContainerWorkflow_container$key, ContainerWorkflow_container } from '../../__generated__/ContainerWorkflow_container.graphql'
import { useFragment } from 'react-relay/hooks'
import Button from '../Button'
import { Alert } from '@material-ui/lab'
import useNiceMutation from '../../mutations/useNiceMutation'
import { ContainerWorkflowChangeStageMutation } from '../../__generated__/ContainerWorkflowChangeStageMutation.graphql'
import Stage from './Stage'
import OdysseyDialog from '../OdysseyDialog'
import LoadingDots from '../LoadingDots'
import SessionContext from '../../SessionContext'
import { ContainerWorkflowPickStageMutation } from '../../__generated__/ContainerWorkflowPickStageMutation.graphql'
import { ContainerWorkflowUnpickStageMutation } from '../../__generated__/ContainerWorkflowUnpickStageMutation.graphql'
import ContainerReleaseRequest from './ContainerReleaseRequest'
import ContainerReleaseConfirm from './ContainerReleaseConfirm'
import ContainerPickSent from './ContainerPickSent'

interface Props {
  container: ContainerWorkflow_container$key
}

const Container = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  overflow-x: auto;
`

const ContainerWorkflow: React.FC<Props> = ({ container: data }) => {
  const container = useFragment(fragment, data)
  const stages = container.workflowStages

  return (
    <>
      <Container>
        {stages.map(
          (stage, idx) =>
            stage && (
              <ContainerStage
                key={stage.value}
                container={data}
                stage={stage}
                index={idx + 1}
                isFirst={idx === 0}
                isLast={idx === stages.length - 1}
              />
            )
        )}
      </Container>
    </>
  )
}

interface ContainerStageProps {
  container: ContainerWorkflow_container$key
  stage: ContainerWorkflow_container['workflowStages'][number]
  index: number
  isFirst: boolean
  isLast: boolean
}

const ContainerStage: React.FC<ContainerStageProps> = ({ container: data, stage, index, isFirst, isLast }) => {
  const container = useFragment(fragment, data)
  const { user } = useContext(SessionContext)
  const [forcePickConfirmationOpen, setForcePickConfirmationOpen] = useState(false)
  const [commitStageChange, stageChangeIsInFlight] = useNiceMutation<ContainerWorkflowChangeStageMutation>(changeStageMutation)
  const [commitStagePick, stagePickIsInFlight, stagePickError] = useNiceMutation<ContainerWorkflowPickStageMutation>(pickStageMutation)
  const [commitStageUnpick, stageUnpickIsInFlight] = useNiceMutation<ContainerWorkflowUnpickStageMutation>(unpickStageMutation)

  const [releaseRequestedDialoq, setReleaseRequestedDialoq] = useState<boolean>(false)
  const [releaseConfirmDialoq, setReleaseConfirmDialoq] = useState<boolean>(false)
  const [pickSentDialoq, setPickSentDialoq] = useState<boolean>(false)

  const handleStageChange = () => {
    if (stage.value === 'release_requested' && stage.status === 'incomplete') {
      setReleaseRequestedDialoq(true)
    } else if (stage.value === 'release_confirmed' && stage.status === 'incomplete') {
      setReleaseConfirmDialoq(true)
    } else if (stage.value === 'pickup_sent' && stage.status === 'incomplete') {
      setPickSentDialoq(true)
    } else {
      if (stageChangeIsInFlight || stage.status === 'active') return
      commitStageChange({
        variables: {
          input: {
            slug: container.slug,
            stage: stage.value,
          },
        },
      })
    }
  }
  const isActive = stage.status === 'active'
  const isPickedByMe = stage.ownedBy === user?.username
  const actionType: 'pick' | 'unpick' | null = isActive && isPickedByMe ? 'unpick' : isActive ? 'pick' : null
  const handleActionClick = () => {
    if (actionType === 'pick') {
      if (stage.ownedBy && stage.ownedBy !== user?.username) {
        setForcePickConfirmationOpen(true)
      } else {
        pickStage()
      }
    } else if (actionType === 'unpick') {
      commitStageUnpick({
        variables: {
          input: {
            entityType: 'containers',
            entitySlug: container.slug,
          },
        },
      })
    }
  }

  const pickStage = () => {
    if (!user) return
    commitStagePick({
      variables: {
        input: {
          entityType: 'containers',
          entitySlug: container.slug,
          pickedBy: user.username,
        },
      },
      onCompleted: (res, errors) => {
        if (!errors) {
          setForcePickConfirmationOpen(false)
        }
      },
    })
  }

  return (
    <>
      <ContainerReleaseRequest
        open={releaseRequestedDialoq}
        onClose={() => setReleaseRequestedDialoq(false)}
        slug={container.slug}
      ></ContainerReleaseRequest>
      <ContainerReleaseConfirm
        open={releaseConfirmDialoq}
        onClose={() => setReleaseConfirmDialoq(false)}
        slug={container.slug}
      ></ContainerReleaseConfirm>
      <ContainerPickSent open={pickSentDialoq} onClose={() => setPickSentDialoq(false)} slug={container.slug}></ContainerPickSent>
      <Stage
        stage={stage}
        index={index}
        isFirst={isFirst}
        isLast={isLast}
        onClick={handleStageChange}
        stageChangeIsLoading={stageChangeIsInFlight}
        actionText={actionType === 'pick' ? 'Pick All' : actionType === 'unpick' ? 'Unpick All' : null}
        onActionClick={handleActionClick}
        actionIsLoading={stagePickIsInFlight || stageUnpickIsInFlight}
      />
      <OdysseyDialog open={forcePickConfirmationOpen} onClose={() => setForcePickConfirmationOpen(false)}>
        <DialogTitle>Unassign {stage.ownedBy} and pick Container?</DialogTitle>
        {stagePickError && <Alert severity='error'>{stagePickError}</Alert>}
        <DialogActions>
          <Button onClick={() => setForcePickConfirmationOpen(false)}>Cancel</Button>
          <Button variant='contained' onClick={() => pickStage()} disabled={stagePickIsInFlight}>
            {stagePickIsInFlight ? <LoadingDots variant='light' /> : 'OK'}
          </Button>
        </DialogActions>
      </OdysseyDialog>
    </>
  )
}

const fragment = graphql`
  fragment ContainerWorkflow_container on Container {
    slug
    workflowStages {
      value
      label
      ownedBy
      status
      ...Stage_stage
    }
  }
`

const changeStageMutation = graphql`
  mutation ContainerWorkflowChangeStageMutation($input: infoChangeContainerStageInput!) {
    infoChangeContainerStage(input: $input) {
      container {
        ...ContainerWorkflow_container
      }
    }
  }
`

const pickStageMutation = graphql`
  mutation ContainerWorkflowPickStageMutation($input: infoPickEntityStageInput!) {
    infoPickEntityStage(input: $input) {
      clientMutationId
    }
  }
`

const unpickStageMutation = graphql`
  mutation ContainerWorkflowUnpickStageMutation($input: infoUnpickEntityStageInput!) {
    infoUnpickEntityStage(input: $input) {
      clientMutationId
    }
  }
`

export default ContainerWorkflow
