/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LiveShareTabQueryVariables = {
    slug: string;
};
export type LiveShareTabQueryResponse = {
    readonly shipmentShow: {
        readonly liveShares: {
            readonly " $fragmentRefs": FragmentRefs<"LiveShare_liveShares">;
        };
    };
};
export type LiveShareTabQuery = {
    readonly response: LiveShareTabQueryResponse;
    readonly variables: LiveShareTabQueryVariables;
};



/*
query LiveShareTabQuery(
  $slug: String!
) {
  shipmentShow(slug: $slug) {
    liveShares {
      ...LiveShare_liveShares
    }
    id
  }
}

fragment LiveShareDrawer_liveshare on LiveShare {
  name
  slug
  password
  url
  contact {
    firstName
    lastName
    email
    id
  }
  email
  generatedBy
  generatedAt
  requestedBy
  requestedAt
  viewedAt
  expiresAt
}

fragment LiveShare_liveShares on LiveShareConnection {
  nodes {
    name
    slug
    password
    url
    contact {
      firstName
      lastName
      email
      id
    }
    email
    expiresAt
    generatedBy
    generatedAt
    requestedBy
    requestedAt
    expired
    ...LiveShareDrawer_liveshare
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LiveShareTabQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Shipment",
        "kind": "LinkedField",
        "name": "shipmentShow",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LiveShareConnection",
            "kind": "LinkedField",
            "name": "liveShares",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "LiveShare_liveShares"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ApplicationQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LiveShareTabQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Shipment",
        "kind": "LinkedField",
        "name": "shipmentShow",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LiveShareConnection",
            "kind": "LinkedField",
            "name": "liveShares",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "LiveShare",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "slug",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "password",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "url",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Contact",
                    "kind": "LinkedField",
                    "name": "contact",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "firstName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastName",
                        "storageKey": null
                      },
                      (v2/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "expiresAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "generatedBy",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "generatedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "requestedBy",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "requestedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "expired",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "viewedAt",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6d0e5320ad00fcb7e06c48688a630865",
    "id": null,
    "metadata": {},
    "name": "LiveShareTabQuery",
    "operationKind": "query",
    "text": "query LiveShareTabQuery(\n  $slug: String!\n) {\n  shipmentShow(slug: $slug) {\n    liveShares {\n      ...LiveShare_liveShares\n    }\n    id\n  }\n}\n\nfragment LiveShareDrawer_liveshare on LiveShare {\n  name\n  slug\n  password\n  url\n  contact {\n    firstName\n    lastName\n    email\n    id\n  }\n  email\n  generatedBy\n  generatedAt\n  requestedBy\n  requestedAt\n  viewedAt\n  expiresAt\n}\n\nfragment LiveShare_liveShares on LiveShareConnection {\n  nodes {\n    name\n    slug\n    password\n    url\n    contact {\n      firstName\n      lastName\n      email\n      id\n    }\n    email\n    expiresAt\n    generatedBy\n    generatedAt\n    requestedBy\n    requestedAt\n    expired\n    ...LiveShareDrawer_liveshare\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = 'ccbfce122d880291f818afb013c786c0';
export default node;
