import get from 'lodash/get'
import React, { useEffect, useMemo, useState } from 'react'

import styled from 'styled-components'
import Dropdown, { OptionType } from '../Dropdown'
import { RendererOptions } from '../DataTable'
import { Typography } from '@material-ui/core'
import { startCase } from 'lodash'
import { useQuery } from 'urql'
import ContainerTypeIcon from '../../assets/public/v2/type.svg'
import ContainerSizeIcon from '../../assets/public/v2/size.svg'
import PhysicalStatusIcon from '../../assets/public/v2/location.svg'
import { Icon } from './RendererHoc'
import { RendererRoot } from './RendererHoc'

const StyledText = styled(Typography)`
  font-size: inherit;
  color: ${(props) => props.theme.palette.text.primary};

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const ICON_ENUM_MAP: Record<string, string> = {
  containerSize: ContainerSizeIcon,
  containerType: ContainerTypeIcon,
  physicalStatus: PhysicalStatusIcon,
}

function OptionsRenderer<T extends Record<string, any>>({
  anchorEl: outerAnchorEl,
  identifier,
  name: columnName,
  rowData,
  value: externalValue,
  icon,
  onSubmit,
  editable,
}: RendererOptions<T>) {
  const name = startCase(`${identifier}Enum`).replaceAll(' ', '')
  const [{ data }] = useQuery({
    query: OptionsQuery,
    variables: { name },
  })

  const enumValues = get(data, '__type.enumValues', [])

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(outerAnchorEl)
  const [search, setSearch] = React.useState((externalValue as string) || '')
  const open = Boolean(anchorEl)

  const options = useMemo(
    () =>
      enumValues.map((en: any) => ({
        label: en.description || startCase(en.name),
        value: en.name,
        icon: ICON_ENUM_MAP[identifier],
      })),
    [enumValues, name]
  )

  const rendererValue = get(rowData, identifier)

  const optionValueOption: OptionType = useMemo(
    () =>
      options.find((opt: OptionType) => opt.value === rendererValue) || {
        label: startCase(rendererValue),
        value: rendererValue,
        icon: icon,
      },
    [rendererValue]
  )

  const [_value, setValue] = useState<OptionType>(optionValueOption)
  const value = options.find((opt: OptionType) => opt.value === externalValue) || _value

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    editable && setAnchorEl(event.currentTarget)
  }

  const handleOnChange = (value: OptionType) => {
    setValue(value)
    onSubmit?.({ ...rowData, [identifier]: value.value })
    handleClose()
  }

  useEffect(() => {
    if (options.length > 0) {
      if (identifier === 'physicalStatus') setValue(options.find((opt: OptionType) => opt.value === rendererValue) || optionValueOption)
    }
  }, [options, rowData, identifier])

  return (
    <>
      <RendererRoot
        role='button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        data-disabled={rowData.isArchived}
      >
        <Icon src={ICON_ENUM_MAP[identifier]} />
        <StyledText title={value?.label}>{value?.label || '--'}</StyledText>
      </RendererRoot>
      <Dropdown
        title={columnName}
        search={search}
        onSearch={(value) => setSearch(value)}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        options={options}
        onSelect={handleOnChange}
        value={value?.value}
      />
    </>
  )
}

const OptionsQuery = `
  query OptionsRendererQuery($name: String!) {
    __type(name: $name) {
      name
      enumValues {
        name
        description
      }
    }
  }
`

OptionsRenderer.operators = ['eq', 'neq', 'is_null', 'not_null']

export default OptionsRenderer
