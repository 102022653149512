import { Typography, Paper, IconButton, Link as IconLink } from '@material-ui/core'
import DraftsIcon from '@material-ui/icons/Drafts'
import { graphql } from 'babel-plugin-relay/macro'
import React, { useState } from 'react'
import { useFragment } from 'react-relay/hooks'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router'
import styled from 'styled-components'
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt'
import SendEmailDialog from '../../components/Email/SendEmailDialog'
import axios from 'axios'
import fileDownload from 'js-file-download'
import moment from 'moment'
import { getToken } from '../../api/server'
import { useSnackbar } from 'notistack'
import LoadingDots from '../../components/LoadingDots'
import { ExternalRelatedItemListContent_data$key } from '../../__generated__/ExternalRelatedItemListContent_data.graphql'
import ExternalShowPageListItem from '../../components/ExternalShowPageListItem'

interface Props {
  data: ExternalRelatedItemListContent_data$key
  type: string
  contactSlug: string
}

const NoResultsText = styled(Typography)`
  color: ${(props) => props.theme.palette.grey[300]};
  text-align: center;
  padding: 32px;
  padding-bottom: 8px;
  font-weight: 700;
`
const ResetFiltersLink = styled(Link)`
  text-decoration: none;
  display: block;
  text-align: center;
`
const ActionLinks = styled.div`
  display: flex;
  justify-content: flex-end;
`

const ExternalRelatedItemListContent: React.FC<Props> = ({ data, type, contactSlug }) => {
  const workSpace = useLocation().pathname.split('/')[1]
  const { items, downloadToken } = useFragment(fragment, data)
  const { enqueueSnackbar } = useSnackbar()
  const [emailNewOpen, setEmailNewOpen] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)
  const url = contactSlug
    ? `${process.env.REACT_APP_API_URL}/${type}/download?download_token=${downloadToken}&workspace=${workSpace}&contact=${contactSlug}`
    : `${process.env.REACT_APP_API_URL}/${type}/download?download_token=${downloadToken}&workspace=${workSpace}`

  const authorizeDownload = () => {
    setLoading(true)
    const filename = `${contactSlug}-${type}-${moment().format('YYYY-MM-DD-HH:mm:ss')}.xlsx`
    const token = getToken()
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(res.data, filename)
        setLoading(false)
      })
      .catch((error) => {
        error.response.data.text().then(function (text: string) {
          enqueueSnackbar(JSON.parse(text as string).errors, { variant: 'error' })
        })
        setLoading(false)
      })
  }

  return (
    <>
      <ActionLinks>
        {(type === 'shipments' || type === 'containers') && (
          <IconButton color='primary'>
            <IconLink onClick={authorizeDownload}>{loading ? <LoadingDots variant='dark' /> : <SystemUpdateAltIcon />}</IconLink>
          </IconButton>
        )}
        {type === 'containers' && contactSlug && (
          <>
            <IconButton color='primary'>
              <IconLink onClick={() => setEmailNewOpen(true)}>
                <DraftsIcon />
              </IconLink>
            </IconButton>
            <SendEmailDialog
              open={emailNewOpen}
              onClose={() => setEmailNewOpen(false)}
              entityType='contacts'
              entitySlug={contactSlug}
              initialTemplateSlug='notify-warehouse'
            />
          </>
        )}
      </ActionLinks>
      <Paper variant='outlined'>
        {items && items.edges && items.edges.length > 0 ? (
          items.edges.map((edge) => {
            const item = edge && edge.node
            if (item) return <ExternalShowPageListItem key={item.slug} listItem={item} />
            return <></>
          })
        ) : (
          <>
            <NoResultsText variant='h5'>No results</NoResultsText>
            <ResetFiltersLink to={`${window.location.pathname}`}>Reset Filters</ResetFiltersLink>
          </>
        )}
      </Paper>
    </>
  )
}

const fragment = graphql`
  fragment ExternalRelatedItemListContent_data on ExternalItemList {
    items {
      edges {
        node {
          slug
          ...ExternalShowPageListItem_listItem
        }
      }
    }
    downloadToken
  }
`

export default ExternalRelatedItemListContent
