import React from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import FoldersTab from '../../components/File/FoldersTab'
import { ShipmentFoldersQuery } from '../../__generated__/ShipmentFoldersQuery.graphql'
import useLazyLoadQueryWithSubscription from '../../hooks/useLazyLoadQueryWithSubscription'

const ShipmentFolders: React.FC<{ slug: string }> & { Skeleton: React.FC } = ({ slug }) => {
  const queryData = useLazyLoadQueryWithSubscription<ShipmentFoldersQuery>(
    shipmentQuery,
    { slug },
    { subscriptionOptions: { entityType: 'shipments', entitySlug: slug } }
  )

  return <FoldersTab folders={queryData.shipmentShow.folders} entityType='shipments' entitySlug={slug} />
}

const shipmentQuery = graphql`
  query ShipmentFoldersQuery($slug: String!) {
    shipmentShow(slug: $slug) {
      folders {
        ...FoldersTab_folders
      }
    }
  }
`

ShipmentFolders.Skeleton = () => <FoldersTab.Skeleton />

export default ShipmentFolders
