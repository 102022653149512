import React, { Suspense, useContext } from 'react'
import { isSmall } from '../theme'
import ResponsiveGrid from './ResponsiveGrid'
import Timeline from './Timeline/Timeline'
import { useFragment } from 'react-relay/hooks'
import { LinkedContactsTab_linkedContacts$key } from '../__generated__/LinkedContactsTab_linkedContacts.graphql'
import { graphql } from 'babel-plugin-relay/macro'
import styled from 'styled-components'
import { Typography, Paper } from '@material-ui/core'
import LinkedContactsItem from './ShowLinkedContactsItem'
import useLazyLoadQueryWithSubscription from '../hooks/useLazyLoadQueryWithSubscription'
import { LinkedContactsTabShipmentQuery } from '../__generated__/LinkedContactsTabShipmentQuery.graphql'
import { LinkedContactsTabContainerQuery } from '../__generated__/LinkedContactsTabContainerQuery.graphql'
import AddContactToRole, { AssignedRoles } from './AddContactToRole'
import SessionContext from '../SessionContext'

interface ContactProps {
  entitySlug: string
  entityType: string
  linkedContacts: LinkedContactsTab_linkedContacts$key
  userType: string | null
}
interface Props {
  slug: string
  userType: string | null
}

const NoResultsText = styled(Typography)`
  color: ${(props) => props.theme.palette.grey[300]};
  text-align: center;
  padding: 32px;
  padding-bottom: 8px;
  font-weight: 700;
`
interface Roles {
  [slug: string]: string[]
}

const LinkedContactsTab: React.FC<ContactProps> = ({ linkedContacts: data, entityType, entitySlug, userType = null }) => {
  const linkedContacts = useFragment(fragment, data)
  const { user } = useContext(SessionContext)

  const rolesArr =
    linkedContacts && linkedContacts.edges && linkedContacts.edges.length > 0
      ? linkedContacts.edges.map((edge) => {
          const item = edge && edge.node
          if (item) return { role: item.role, slug: item.contactSlug, title: item.contactItem.title }
          return null
        })
      : []

  const added: string[] = []
  const roles = rolesArr.reduce<Roles>(function (obj, el) {
    if (el !== null) {
      obj[el.slug] = [...(obj[el.slug] || []), el.role]
    }
    return obj
  }, {})
  const assignedRoles = rolesArr.reduce<AssignedRoles>(function (obj, el) {
    if (el !== null) {
      obj[el.role] = { slug: el.slug, title: el.title }
    }
    return obj
  }, {})

  return (
    <>
      <ResponsiveGrid
        type='show-layout'
        highlight={[
          <Paper key='contacts' variant='outlined'>
            {linkedContacts && linkedContacts.edges && linkedContacts.edges.length > 0 ? (
              linkedContacts.edges.map((edge) => {
                const item = edge && edge.node
                if (item) {
                  if (added.includes(item.contactSlug)) return <></>
                  added.push(item.contactSlug)
                  return <LinkedContactsItem roles={roles[item.contactSlug]} key={item.contactSlug} contactLink={item} userType={userType} />
                }
                return <></>
              })
            ) : (
              <>
                <NoResultsText variant='h5'>No results</NoResultsText>
              </>
            )}
          </Paper>,
        ]}
        right={
          isSmall()
            ? []
            : [
                <>
                  {user?.contact?.contactType == 'internal' && (
                    <>
                      <AddContactToRole key='search' entityType={entityType} entitySlug={entitySlug} assignedRoles={assignedRoles} />
                      <React.Fragment key='timeline'>
                        {
                          <Suspense key='timeline' fallback={<Timeline.Skeleton />}>
                            <Timeline type='contacts' parentAbsoluteSlug={`${entityType}/${entitySlug}`} />
                          </Suspense>
                        }
                      </React.Fragment>
                    </>
                  )}
                </>,
              ]
        }
      />
    </>
  )
}

export const ShipmentLinkedContactsTab: React.FC<Props> & { Skeleton: React.FC } = ({ slug, userType = null }) => {
  const { shipmentShow: shipment } = useLazyLoadQueryWithSubscription<LinkedContactsTabShipmentQuery>(
    shipmentQuery,
    { slug: slug },
    { fetchPolicy: 'store-and-network', subscriptionOptions: { entityType: 'shipments', entitySlug: slug } }
  )

  return <LinkedContactsTab linkedContacts={shipment.linkedContacts} entityType='shipments' entitySlug={slug} userType={userType} />
}

export const ContainerLinkedContactsTab: React.FC<Props> & { Skeleton: React.FC } = ({ slug, userType = null }) => {
  const { containerShow: container } = useLazyLoadQueryWithSubscription<LinkedContactsTabContainerQuery>(
    containerQuery,
    { slug: slug },
    { fetchPolicy: 'store-and-network', subscriptionOptions: { entityType: 'containers', entitySlug: slug } }
  )

  return <LinkedContactsTab linkedContacts={container.linkedContacts} entityType='containers' entitySlug={slug} userType={userType} />
}

ShipmentLinkedContactsTab.Skeleton = () => <>Loading...</>
ContainerLinkedContactsTab.Skeleton = () => <>Loading...</>

const shipmentQuery = graphql`
  query LinkedContactsTabShipmentQuery($slug: String!) {
    shipmentShow(slug: $slug) {
      linkedContacts {
        ...LinkedContactsTab_linkedContacts
      }
    }
  }
`

const containerQuery = graphql`
  query LinkedContactsTabContainerQuery($slug: String!) {
    containerShow(slug: $slug) {
      linkedContacts {
        ...LinkedContactsTab_linkedContacts
      }
    }
  }
`

const fragment = graphql`
  fragment LinkedContactsTab_linkedContacts on ContactLinkConnection {
    edges {
      node {
        contactSlug
        role
        contactItem {
          title
        }
        ...ShowLinkedContactsItem_linkedContact
      }
    }
  }
`

export default LinkedContactsTab
