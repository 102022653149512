/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LinkedContactsCard_draftLinkedContactRoles = {
    readonly nodes: ReadonlyArray<{
        readonly role: string;
        readonly ticketStatus: string | null;
        readonly contactLink: {
            readonly displayRole: string;
            readonly displayName: string;
            readonly contactItem: {
                readonly slug: string;
                readonly title: string;
                readonly image: {
                    readonly url: string | null;
                    readonly alt: string | null;
                } | null;
            };
        } | null;
    } | null> | null;
    readonly " $refType": "LinkedContactsCard_draftLinkedContactRoles";
};
export type LinkedContactsCard_draftLinkedContactRoles$data = LinkedContactsCard_draftLinkedContactRoles;
export type LinkedContactsCard_draftLinkedContactRoles$key = {
    readonly " $data"?: LinkedContactsCard_draftLinkedContactRoles$data;
    readonly " $fragmentRefs": FragmentRefs<"LinkedContactsCard_draftLinkedContactRoles">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LinkedContactsCard_draftLinkedContactRoles",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "DraftContactLinkRole",
      "kind": "LinkedField",
      "name": "nodes",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "role",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ticketStatus",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ContactLink",
          "kind": "LinkedField",
          "name": "contactLink",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "displayRole",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "displayName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ListItem",
              "kind": "LinkedField",
              "name": "contactItem",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "slug",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "title",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Image",
                  "kind": "LinkedField",
                  "name": "image",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "url",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "alt",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "DraftContactLinkRoleConnection",
  "abstractKey": null
};
(node as any).hash = 'a057b4c456993864a6834fca6ed310d0';
export default node;
