import { FieldT, FieldTypeEnum } from './types'
import { buildYup } from 'schema-to-yup'

const yupType = (type: FieldTypeEnum) => {
  switch (type) {
    case 'shipment_image_slug':
    case 'container_image_slug':
    case 'image_slug':
    case 'file_slug':
    case 'doc_slug':
    case 'shipping_line':
    case 'vessel':
    case 'date':
    case 'datetime':
    case 'port':
    case 'voyage':
    case 'container':
    case 'rate_card':
    case 'rich_text':
    case 'searchable':
    case 'parent_shipment':
      return 'string'
    case 'boolean_switch':
      return 'boolean'
    case 'multi_select':
      return 'array'
    case 'linked_contacts':
      return 'object'
    default:
      return type
  }
}

export const formSchemaToYup = (fields: ReadonlyArray<FieldT>) => {
  return buildYup(formSchemToYupJSONSchema(fields), {})
}

const formSchemToYupJSONSchema = (fields: ReadonlyArray<FieldT>) => {
  const formProperties = Object.fromEntries(
    fields.map((field) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { enum: enumValues, enumTitles, type, arrayItems, ...rest } = field
      const common = { nullable: true }
      const yupSchema: any = { type: yupType(type), ...common, ...rest }

      return [field.name, yupSchema]
    })
  )
  return { type: 'object', properties: formProperties }
}

export const nonNullInitialValues = (obj: Record<string, unknown>) => Object.entries(obj).map(([k, v]) => [k, v === null ? '' : v])
