/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ApiLogItem_apiLog = {
    readonly title: string;
    readonly displayCallType: string;
    readonly httpStatus: number;
    readonly createdAt: string;
    readonly callResponseUrl: string | null;
    readonly callStatus: string;
    readonly callError: string | null;
    readonly " $refType": "ApiLogItem_apiLog";
};
export type ApiLogItem_apiLog$data = ApiLogItem_apiLog;
export type ApiLogItem_apiLog$key = {
    readonly " $data"?: ApiLogItem_apiLog$data;
    readonly " $fragmentRefs": FragmentRefs<"ApiLogItem_apiLog">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ApiLogItem_apiLog",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayCallType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "httpStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "callResponseUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "callStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "callError",
      "storageKey": null
    }
  ],
  "type": "ApiLog",
  "abstractKey": null
};
(node as any).hash = '5db0b4a057ed484d1b743778b10e8a6d';
export default node;
