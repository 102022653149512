import get from 'lodash/get'
import React, { useState } from 'react'
// import styled from 'styled-components'

// import WorkspacedLink from '../../components/WorkspacedLink'
import { RendererOptions } from '../DataTable'
import { RendererRoot, InfoContainer } from './RendererHoc'
import { TextRenderer } from './TextRenderer'
import { IconButton } from '@material-ui/core'
import ShowPageInDrawer from '../ShowPageInDrawer'
import { InfoOutlined } from '@material-ui/icons'

// const StyledWorkspaceLink = styled(WorkspacedLink)({
//   display: 'flex !important',
//   alignItems: 'center',
//   gap: '8px',
// })

function LinkRenderer<T extends any>(props: RendererOptions<T>) {
  const slug = get(props.rowData, 'slug')
  const type = get(props.rowData, 'type')
  // const link = to || `/container/${slug}/home`
  const [show, setShow] = useState(false)

  return (
    <>
      <RendererRoot>
        {/* <StyledWorkspaceLink to={link} target='__blank'> */}
        <TextRenderer {...props} />
        {/* </StyledWorkspaceLink> */}
        <InfoContainer data-hoverable>
          <IconButton
            size='small'
            onClick={(e) => {
              e.stopPropagation()
              setShow(true)
            }}
          >
            <InfoOutlined fontSize='small' />
          </IconButton>
        </InfoContainer>
      </RendererRoot>
      <ShowPageInDrawer entityType={type} entitySlug={slug} open={show} onClose={() => setShow(false)} />
    </>
  )
}

export default LinkRenderer
