import { MenuItem, TextField, Typography, IconButton, Link, Tooltip } from '@material-ui/core'
import { graphql } from 'babel-plugin-relay/macro'
import React, { useState, useEffect, unstable_useTransition } from 'react'
import { useFragment, useLazyLoadQuery } from 'react-relay/hooks'
import styled from 'styled-components'
import { media, isSmall } from '../../theme'
import { ViewModule, ViewList, TableChart, Archive, Unarchive, Close } from '@material-ui/icons'
import useWorkspaceNavigate from '../../hooks/useWorkspaceNavigate'
import { ViewMode } from '../../viewTypes'
import { ItemListQueryVariables } from '../../__generated__/ItemListQuery.graphql'
import Button from '../../components/Button'
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt'
import { useLocation, useNavigate } from 'react-router'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Add } from '@material-ui/icons'
import { Link as RouterLink } from 'react-router-dom'
import axios from 'axios'
import fileDownload from 'js-file-download'
import { useSnackbar } from 'notistack'
import moment from 'moment'
import LoadingDots from '../../components/LoadingDots'
import { getToken } from '../../api/server'
import WorkspacedLink from '../../components/WorkspacedLink'
import { ItemListHeaderQuery } from '../../__generated__/ItemListHeaderQuery.graphql'
import pluralize from 'pluralize'
import { StyledIconButton } from '../../components/DataTable'
import { parse, stringify } from 'qs'
import DateRangePickerFilter from '../../components/DateRangePickerFilter'

interface Props {
  variables: ItemListQueryVariables
  viewMode: ViewMode
  setViewMode?: (mode: ViewMode) => void
  expand: boolean
  setExpand: () => void
  type?: string
  entity?: string
  addItemUrl?: string
}

const Root = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;

  ${media.mobile`
    align-items: flex-start;
    flex-direction: column;
  `}
`

const ResultText = styled(Typography)`
  flex-grow: 2;
  ${media.mobile`
    margin-bottom: 8px;
  `}
`

const SortContainer = styled.div`
  overflow: auto;
  display: flex;
  align-items: center;

  ${media.small`
    width: 100%;
  `}
`

const Sort = styled(TextField)`
  min-width: 100px;
`
const Arrow = styled.div`
  display: flex;
  justify-content: flex-end;
`
const StyledLink = styled(RouterLink)`
  text-decoration: none;
`

const DateRangeStyle = styled.div`
  display: flex;
  float: right;
  margin: 8px 20px 5px;
`

const archivableEntityTypes = ['shipments', 'containers', 'item']

const ItemListHeader: React.FC<Props> = ({ variables, viewMode, setViewMode, expand, setExpand, type, entity, addItemUrl }) => {
  const data = useLazyLoadQuery<ItemListHeaderQuery>(query, variables)
  // @ts-ignore
  const { sortKeys, totalCount, downloadToken } = useFragment(fragment, data.itemList)
  const wsNavigate = useWorkspaceNavigate()

  const handleSortToggle = (key: string) => wsNavigate.relative({ sortKey: key })
  const toggleArchivedVisibility = () =>
    wsNavigate.relative({
      includeArchived: variables.includeArchived ? 'false' : 'true',
    })

  return (
    <Root>
      <ResultText variant='body1'>
        {!isSmall() && <HeaderResult totalCount={totalCount} downloadToken={downloadToken} type={type as string} entity={entity as string} />}
      </ResultText>
      <SortContainer>
        {isSmall() && <HeaderResult totalCount={totalCount} downloadToken={downloadToken} type={type as string} entity={entity as string} />}
        {type && (
          <Tooltip interactive title={`New ${entity}`}>
            <StyledLink to={addItemUrl || '#'}>
              <Button variant='contained'>
                <Add />
              </Button>
            </StyledLink>
          </Tooltip>
        )}
        {variables.type && archivableEntityTypes.includes(variables.type) && (
          <Tooltip title={variables.includeArchived ? 'Hide Archived' : 'Show Archived'} interactive>
            <StyledIconButton $active={variables.includeArchived as boolean} size='normal' onClick={toggleArchivedVisibility}>
              {!variables.includeArchived ? <Archive style={{ color: 'inherit' }} fontSize='small' /> : <Unarchive fontSize='small' />}
            </StyledIconButton>
          </Tooltip>
        )}
        <Sort
          select
          size='small'
          variant='outlined'
          value={variables.sortKey}
          label='Sort By'
          onChange={(e) => handleSortToggle(e.target.value)}
        >
          {sortKeys.map((option: any) => (
            <MenuItem key={option.key} value={option.key}>
              {option.title}
            </MenuItem>
          ))}
        </Sort>
        {!isSmall() && viewMode !== 'mini-list' && (
          <>
            <IconButton color={viewMode === 'grid' ? 'primary' : 'default'} onClick={() => setViewMode && setViewMode('grid')}>
              <ViewModule />
            </IconButton>
            <IconButton color={viewMode === 'list' ? 'primary' : 'default'} onClick={() => setViewMode && setViewMode('list')}>
              <ViewList />
            </IconButton>
          </>
        )}
        {type && ['containers', 'shipments'].includes(type) && (
          <WorkspacedLink to={'/' + type + '/v2'}>
            <Tooltip title={'Table View'} interactive>
              <IconButton>
                <TableChart />
              </IconButton>
            </Tooltip>
          </WorkspacedLink>
        )}
        <Arrow>
          <Tooltip title={expand ? 'Collapse all' : 'Expand All'} placement='top' interactive>
            <IconButton onClick={setExpand}>{expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}</IconButton>
          </Tooltip>
        </Arrow>
      </SortContainer>
    </Root>
  )
}

interface resultProps {
  totalCount: number
  downloadToken: string
  type: string
  entity: string
}

const HeaderResult: React.FC<resultProps> = ({ totalCount, downloadToken, type, entity }) => {
  const [loading, setLoading] = useState<boolean>(false)
  const workSpace = useLocation().pathname.split('/')[1]
  const location = useLocation()
  const navigate = useNavigate()
  const queryVariables = parse(location.search, { ignoreQueryPrefix: true })
  const [startTransition] = unstable_useTransition()
  const [voyageDateRange, setVoyageDateRange] = React.useState([])
  const [handoverDateRange, setHandoverDateRange] = React.useState([])
  const [dischargeDateRange, setDischargeDateRange] = React.useState([])
  const [filterType, setFilterType] = React.useState(null)

  const { enqueueSnackbar } = useSnackbar()
  const url =
    entity &&
    `${process.env.REACT_APP_API_URL}/${pluralize(entity.toLowerCase())}/download?download_token=${downloadToken}&workspace=${workSpace}`
  const authorizeDownload = () => {
    setLoading(true)
    const filename = `${entity}-${moment().format('YYYY-MM-DD-HH:mm:ss')}.xlsx`
    const token = getToken()
    url &&
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          responseType: 'blob',
        })
        .then((res) => {
          fileDownload(res.data, filename)
          setLoading(false)
        })
        .catch((error) => {
          error.response.data.text().then(function (text: string) {
            enqueueSnackbar(JSON.parse(text as string).errors, { variant: 'error' })
          })
          setLoading(false)
        })
  }

  useEffect(() => {
    const { voyageEtaStart, voyageEtaEnd, handoverDateStart, handoverDateEnd, dischargeDateStart, dischargeDateEnd } = queryVariables

    if (voyageEtaStart && voyageEtaEnd) {
      const startDate = moment(voyageEtaStart as string, 'YYYY-MM-DD').toDate()
      const endDate = moment(voyageEtaEnd as string, 'YYYY-MM-DD').toDate()
      if (startDate && endDate) {
        setVoyageDateRange([startDate, endDate])
      }
    }

    if (handoverDateStart && handoverDateEnd) {
      const startDate = moment(handoverDateStart as string, 'YYYY-MM-DD').toDate()
      const endDate = moment(handoverDateEnd as string, 'YYYY-MM-DD').toDate()
      if (startDate && endDate) {
        setHandoverDateRange([startDate, endDate])
      }
    }

    if (dischargeDateStart && dischargeDateEnd) {
      const startDate = moment(dischargeDateEnd as string, 'YYYY-MM-DD').toDate()
      const endDate = moment(dischargeDateEnd as string, 'YYYY-MM-DD').toDate()
      if (startDate && endDate) {
        setDischargeDateRange([startDate, endDate])
      }
    }
  }, [])

  useEffect(() => {
    dateFilter()
  }, [voyageDateRange, handoverDateRange, dischargeDateRange])

  const dateFilter = () => {
    const tempVars = Object.assign({}, queryVariables)
    const startKey = filterType === 'voyage' ? 'voyageEtaStart' : `${filterType}DateStart`
    const endKey = filterType === 'voyage' ? 'voyageEtaEnd' : `${filterType}DateEnd`
    let dateRange
    if (filterType == 'voyage') {
      dateRange = voyageDateRange
    } else if (filterType == 'handover') {
      dateRange = handoverDateRange
    } else if (filterType == 'discharge') {
      dateRange = dischargeDateRange
    }

    if (dateRange && Array.isArray(dateRange) && dateRange.length == 2) {
      const validateStart = moment(dateRange[0], 'YYYY-MM-DD', true).isValid()
      const validateEnd = moment(dateRange[1], 'YYYY-MM-DD', true).isValid()

      if (validateStart && validateEnd) {
        tempVars[startKey] = moment(dateRange[0]).format('YYYY-MM-DD')
        tempVars[endKey] = moment(dateRange[1]).format('YYYY-MM-DD')
      } else {
        delete tempVars[startKey]
        delete tempVars[endKey]
      }
    } else {
      delete tempVars[startKey]
      delete tempVars[endKey]
    }
    startTransition(() => {
      navigate(`?${stringify(tempVars)}`)
    })
  }

  const handleDateChange = (filterType, newDateRange) => {
    setFilterType(filterType)
    if (filterType == 'voyage') {
      setVoyageDateRange(newDateRange)
    } else if (filterType == 'handover') {
      setHandoverDateRange(newDateRange)
    } else if (filterType == 'discharge') {
      setDischargeDateRange(newDateRange)
    }
  }

  const handleClear = () => {
    const entityNavigate = type === 'external_contacts' || type === 'internal_contacts' ? `${type.split('_')[1]}/${type.split('_')[0]}` : type
    navigate(`/${workSpace}/${entityNavigate}`)

    delete queryVariables.voyageEtaStart
    delete queryVariables.voyageEtaEnd
    delete queryVariables.handoverDateEnd
    delete queryVariables.handoverDateStart
    delete queryVariables.dischargeDateEnd
    delete queryVariables.dischargeDateStart

    setVoyageDateRange([])
    setHandoverDateRange([])
    setDischargeDateRange([])
  }

  return (
    <>
      {isSmall() && (
        <>
          <div>1-{totalCount}</div>
          <div>
            {type && (type === 'shipments' || type === 'containers') && (
              <IconButton color='primary'>
                <Link onClick={authorizeDownload}>{loading ? <LoadingDots variant='dark' /> : <SystemUpdateAltIcon />}</Link>
              </IconButton>
            )}
          </div>
          <div>
            {Object.keys(queryVariables).length > 0 && (
              <Button variant='outlined' onClick={handleClear}>
                <Close fontSize='small' />
              </Button>
            )}
          </div>
        </>
      )}
      {!isSmall() && (
        <div>
          1-{totalCount}
          {type && (type === 'shipments' || type === 'containers') && (
            <IconButton color='primary'>
              <Link onClick={authorizeDownload}>{loading ? <LoadingDots variant='dark' /> : <SystemUpdateAltIcon />}</Link>
            </IconButton>
          )}
          {Object.keys(queryVariables).length > 0 && (
            <Button style={{ flexShrink: 0 }} variant='outlined' size='small' endIcon={<Close fontSize='small' />} onClick={handleClear}>
              Clear filters
            </Button>
          )}
          <DateRangeStyle className='date-range-filters'>
            {['containers', 'shipments', 'voyages'].includes(type || '') && (
              <DateRangePickerFilter filterType='voyage' handleDateChange={handleDateChange} heading='Voyage ETA' dateRange={voyageDateRange} />
            )}
            {type === 'shipments' && (
              <>
                <DateRangePickerFilter
                  filterType='handover'
                  handleDateChange={handleDateChange}
                  heading='Handover Date'
                  dateRange={handoverDateRange}
                />
                <DateRangePickerFilter
                  filterType='discharge'
                  handleDateChange={handleDateChange}
                  heading='Discharge Date'
                  dateRange={dischargeDateRange}
                />
              </>
            )}
          </DateRangeStyle>
        </div>
      )}
    </>
  )
}

const query = graphql`
  query ItemListHeaderQuery(
    $searchQuery: String
    $type: ListItemTypeEnum
    $filters: [FilterInput!]
    $includeArchived: Boolean
    $sortKey: String
    $page: Int
    $perPage: Int
    $handoverDateStart: Date
    $handoverDateEnd: Date
    $voyageEtaStart: Date
    $voyageEtaEnd: Date
    $dischargeDateStart: Date
    $dischargeDateEnd: Date
  ) {
    itemList(
      searchQuery: $searchQuery
      type: $type
      filters: $filters
      includeArchived: $includeArchived
      sortKey: $sortKey
      page: $page
      perPage: $perPage
      handoverDateStart: $handoverDateStart
      handoverDateEnd: $handoverDateEnd
      voyageEtaStart: $voyageEtaStart
      voyageEtaEnd: $voyageEtaEnd
      dischargeDateStart: $dischargeDateStart
      dischargeDateEnd: $dischargeDateEnd
    ) {
      ...ItemListHeader_data
    }
  }
`

const fragment = graphql`
  fragment ItemListHeader_data on ItemList {
    sortKeys {
      key
      title
    }
    totalCount
    downloadToken
  }
`

export default ItemListHeader
