import React from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import { useFragment } from 'react-relay/hooks'
import { ApiLogItem_apiLog$key } from '../__generated__/ApiLogItem_apiLog.graphql'
import styled from 'styled-components'
import { Typography, IconButton } from '@material-ui/core'
import { Description } from '@material-ui/icons'
import { Skeleton } from '@material-ui/lab'
import { useDateTimeDisplay } from '../hooks/timeZoneDisplay'

interface Props {
  apiLog: ApiLogItem_apiLog$key
}

const Root = styled.div<{ $callStatus: string }>`
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${(props) => props.theme.customPalette.border.card};
  background-color: ${(props) =>
    props.$callStatus === 'error' ? (props.theme.palette.type === 'light' ? '#fff0f0' : '#463434') : props.theme.palette.background.paper};
`

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`

const VertFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const HttpStatusIcon = styled.div<{ $status: number }>`
  height: 15px;
  width: 15px;
  position: relative;
  border-radius: 50%;
  background-color: ${(props) => {
    switch (props.$status) {
      case 200:
        return props.theme.palette.primary.light
      case 201:
        return props.theme.palette.primary.light
      case 404:
        return props.theme.palette.error.light
      case 403:
        return props.theme.palette.error.light
      case 429:
        return props.theme.palette.error.light
      default:
        return props.theme.palette.warning.light
    }
  }};
  margin-right: 8px;

  ::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    height: 50%;
    width: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background-color: ${(props) => props.theme.palette.background.paper};
  }
`

const HttpStatusContainer = styled(FlexContainer)`
  margin-right: 16px;
`

const CallTypeText = styled(Typography)`
  margin-right: 8px;
`

const ApiLogItem: React.FC<Props> & { Skeleton: React.FC } = ({ apiLog: data }) => {
  const apiLog = useFragment(fragment, data)

  const openResponse = () => {
    if (!apiLog.callResponseUrl) return

    window.open(apiLog.callResponseUrl)
  }

  return (
    <Root $callStatus={apiLog.callStatus}>
      <FlexContainer>
        <HttpStatusContainer>
          <HttpStatusIcon $status={apiLog.httpStatus} />
          <Typography variant='body1'>{apiLog.httpStatus}</Typography>
        </HttpStatusContainer>
        <VertFlexContainer>
          <CallTypeText variant='body1'>{apiLog.displayCallType}</CallTypeText>
          <Typography variant='body2' color='textSecondary'>
            {apiLog.title}
          </Typography>
          {apiLog.callStatus === 'error' && (
            <Typography variant='body2' color='error'>
              {apiLog.callError}
            </Typography>
          )}
        </VertFlexContainer>
      </FlexContainer>

      <FlexContainer>
        <IconButton onClick={openResponse} disabled={!apiLog.callResponseUrl}>
          <Description />
        </IconButton>
        <Typography variant='body2' color='textSecondary'>
          {useDateTimeDisplay(apiLog.createdAt)}
        </Typography>
      </FlexContainer>
    </Root>
  )
}

ApiLogItem.Skeleton = () => (
  <Root $callStatus='success'>
    <FlexContainer>
      <HttpStatusContainer>
        <Skeleton variant='rect' height='15px' width='60px'></Skeleton>
      </HttpStatusContainer>
      <VertFlexContainer>
        <Skeleton>
          <CallTypeText variant='body1'>---------------------</CallTypeText>
        </Skeleton>
        <Skeleton>
          <Typography variant='body2'>------------------------------</Typography>
        </Skeleton>
      </VertFlexContainer>
    </FlexContainer>

    <FlexContainer>
      <IconButton disabled={true}>
        <Description />
      </IconButton>
      <Skeleton>
        <Typography variant='body2' color='textSecondary'>
          --/--/----
        </Typography>
      </Skeleton>
    </FlexContainer>
  </Root>
)

const fragment = graphql`
  fragment ApiLogItem_apiLog on ApiLog {
    title
    displayCallType
    httpStatus
    createdAt
    callResponseUrl
    callStatus
    callError
  }
`

export default ApiLogItem
