import { graphql } from 'babel-plugin-relay/macro'
import React, { Suspense } from 'react'
import { AddNotes } from '../../components/Notes'
import ResponsiveGrid from '../../components/ResponsiveGrid'
import Timeline from '../../components/Timeline/Timeline'
import { VesselShowHomeContentQuery } from '../../__generated__/VesselShowHomeContentQuery.graphql'
import ItemListCard from '../../components/ItemListCard'
import useLazyLoadQueryWithSubscription from '../../hooks/useLazyLoadQueryWithSubscription'

interface Props {
  slug: string
}

const VesselShowHomeContent: React.FC<Props> & { Skeleton: React.FC } = ({ slug }) => {
  const queryData = useLazyLoadQueryWithSubscription<VesselShowHomeContentQuery>(
    query,
    { slug: slug },
    { subscriptionOptions: { entityType: 'vessels', entitySlug: slug } }
  )
  const { vesselShow: vessel } = queryData

  return (
    <ResponsiveGrid
      type='show-layout'
      highlight={[
        <Suspense key='voyages-card' fallback={<ItemListCard.Skeleton />}>
          <ItemListCard type='voyages' parentAbsoluteSlug={`vessels/${slug}`} title='Voyages' entityTitle='Voyage' />
        </Suspense>,
      ]}
      right={[
        <Suspense key='notes' fallback={<AddNotes.Skeleton />}>
          <AddNotes entitySlug={vessel.slug} entityType='vessels' />
        </Suspense>,
        <Suspense key='timeline' fallback={<Timeline.Skeleton />}>
          <Timeline slug={slug} type='vessels' />
        </Suspense>,
      ]}
    />
  )
}

const query = graphql`
  query VesselShowHomeContentQuery($slug: String!) {
    vesselShow(slug: $slug) {
      slug

      voyageItems {
        slug
        ...ShowPageListItem_listItem
      }
    }
  }
`

VesselShowHomeContent.Skeleton = () => <>Loading...</>

export default VesselShowHomeContent
