/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type infoAddVesselInput = {
    clientMutationId?: string | null;
    name: string;
    shippingLineSlug: string;
    imoNumber?: string | null;
};
export type VesselAddMutationVariables = {
    input: infoAddVesselInput;
};
export type VesselAddMutationResponse = {
    readonly infoAddVessel: {
        readonly vessel: {
            readonly slug: string;
        };
    } | null;
};
export type VesselAddMutation = {
    readonly response: VesselAddMutationResponse;
    readonly variables: VesselAddMutationVariables;
};



/*
mutation VesselAddMutation(
  $input: infoAddVesselInput!
) {
  infoAddVessel(input: $input) {
    vessel {
      slug
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "VesselAddMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "infoAddVesselPayload",
        "kind": "LinkedField",
        "name": "infoAddVessel",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Vessel",
            "kind": "LinkedField",
            "name": "vessel",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ApplicationCommand",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "VesselAddMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "infoAddVesselPayload",
        "kind": "LinkedField",
        "name": "infoAddVessel",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Vessel",
            "kind": "LinkedField",
            "name": "vessel",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c680efdd3ba721dc32c4784888fbf7da",
    "id": null,
    "metadata": {},
    "name": "VesselAddMutation",
    "operationKind": "mutation",
    "text": "mutation VesselAddMutation(\n  $input: infoAddVesselInput!\n) {\n  infoAddVessel(input: $input) {\n    vessel {\n      slug\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '0bab1ed55f5bf2f57c9a1b875f16cc76';
export default node;
