/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type infoUpdateContainerInput = {
    clientMutationId?: string | null;
    slug: string;
    profileImageSlug?: string | null;
    containerNumber: string;
    voyageSlug: string;
    containerType: string;
    containerSize?: string | null;
    procedure?: string | null;
    atb?: string | null;
    bookingNumber?: string | null;
    turninLocationSlug?: string | null;
    pickupLocationSlug?: string | null;
    pickupReference?: string | null;
    turninReference?: string | null;
    pickupComment?: string | null;
    billOfLadingNumber?: string | null;
    terminalDate?: string | null;
    dischargeDate?: string | null;
    latestPickupDate?: string | null;
    pickupDate?: string | null;
    latestTurninDate?: string | null;
    thcPaidDate?: string | null;
    linkedContacts: Array<LinkedContactInput>;
    shippingLineSlug: string;
};
export type LinkedContactInput = {
    role: string;
    contactSlug: string;
};
export type ContainerEditMutationVariables = {
    input: infoUpdateContainerInput;
};
export type ContainerEditMutationResponse = {
    readonly infoUpdateContainer: {
        readonly container: {
            readonly slug: string;
        };
    } | null;
};
export type ContainerEditMutation = {
    readonly response: ContainerEditMutationResponse;
    readonly variables: ContainerEditMutationVariables;
};



/*
mutation ContainerEditMutation(
  $input: infoUpdateContainerInput!
) {
  infoUpdateContainer(input: $input) {
    container {
      slug
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ContainerEditMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "infoUpdateContainerPayload",
        "kind": "LinkedField",
        "name": "infoUpdateContainer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Container",
            "kind": "LinkedField",
            "name": "container",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "ApplicationCommand",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ContainerEditMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "infoUpdateContainerPayload",
        "kind": "LinkedField",
        "name": "infoUpdateContainer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Container",
            "kind": "LinkedField",
            "name": "container",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5dda268b568711a3185668c1a13003d6",
    "id": null,
    "metadata": {},
    "name": "ContainerEditMutation",
    "operationKind": "mutation",
    "text": "mutation ContainerEditMutation(\n  $input: infoUpdateContainerInput!\n) {\n  infoUpdateContainer(input: $input) {\n    container {\n      slug\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '2e366d0c293d3d9b450d99246fd946a9';
export default node;
