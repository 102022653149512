/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type StageStatusEnum = "active" | "completed" | "incomplete" | "%future added value";
export type ExternalStage_stage = {
    readonly value: string;
    readonly status: StageStatusEnum;
    readonly label: string;
    readonly completedAt: string | null;
    readonly ownedBy: string | null;
    readonly " $refType": "ExternalStage_stage";
};
export type ExternalStage_stage$data = ExternalStage_stage;
export type ExternalStage_stage$key = {
    readonly " $data"?: ExternalStage_stage$data;
    readonly " $fragmentRefs": FragmentRefs<"ExternalStage_stage">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExternalStage_stage",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ownedBy",
      "storageKey": null
    }
  ],
  "type": "WorkflowStage",
  "abstractKey": null
};
(node as any).hash = '1f3a5afce6b0e76938e9435b41de385c';
export default node;
