/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type EntityAttachmentListItem_attachment = {
    readonly url: string;
    readonly downloadUrl: string;
    readonly name: string;
    readonly contentType: string;
    readonly previewUrl: string | null;
    readonly documentType: string | null;
    readonly roleVisibilities: ReadonlyArray<{
        readonly role: string;
        readonly roleShortTitle: string;
        readonly roleTitle: string;
        readonly visible: boolean;
    }>;
    readonly uploadedAtDisplay: string | null;
    readonly uploadedAt: string | null;
    readonly " $refType": "EntityAttachmentListItem_attachment";
};
export type EntityAttachmentListItem_attachment$data = EntityAttachmentListItem_attachment;
export type EntityAttachmentListItem_attachment$key = {
    readonly " $data"?: EntityAttachmentListItem_attachment$data;
    readonly " $fragmentRefs": FragmentRefs<"EntityAttachmentListItem_attachment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EntityAttachmentListItem_attachment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "downloadUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contentType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "previewUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "documentType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RoleVisibility",
      "kind": "LinkedField",
      "name": "roleVisibilities",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "role",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "roleShortTitle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "roleTitle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "visible",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uploadedAtDisplay",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uploadedAt",
      "storageKey": null
    }
  ],
  "type": "EntityAttachment",
  "abstractKey": null
};
(node as any).hash = '4abd28bb5d373ec948ae1c8f3f84c831';
export default node;
