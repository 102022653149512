import { Card, IconButton, Typography } from '@material-ui/core'
import { Apps } from '@material-ui/icons'
import { Skeleton } from '@material-ui/lab'
import { useFormikContext } from 'formik'
import React from 'react'
import styled from 'styled-components'
import InputField from '../../components/Form/InputField'
import { FieldT } from '../../components/Form/types'
import Hero from '../../components/Hero'
import { media } from '../../theme'
import LoadingDots from '../../components/LoadingDots'
import useVinDetails from '../../hooks/useVinDetails'

const HeroContentRoot = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 900px;
  margin: auto;
`

const HeroDetailsCard = styled(Card)`
  width: 100%;
  padding: 16px;
  margin-top: 16px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;

  ${media.small`
    margin-left: 0px;
    margin-top: 8px;
  `}
`

const DetailsCard = styled(Card)`
  margin-top: 16px;
  padding: 16px;
`

const DetailsCardTitle = styled(Typography)`
  margin-bottom: 16px;
`

const HeroFieldsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;

  ${media.medium`
    grid-template-columns: repeat(3, 1fr);
  `}

  ${media.small`
    grid-template-columns: auto;
  `}
`

const FieldsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 16px;

  ${media.medium`
    grid-template-columns: repeat(3, 1fr);
  `}

  ${media.small`
    grid-template-columns: auto;
  `}
`

export const VinContainer = styled.div`
  display: flex;
`

export const VinDetailsTypography = styled(Typography)`
  padding-left: 8px;
`

interface Props {
  mode: 'add' | 'edit'
  fieldMaster: {
    [key: string]: FieldT
  }
}

const ShipmentForm: React.FC<Props> & { Skeleton: React.FC } = ({ mode, fieldMaster }) => {
  const { values, setFieldValue, initialValues } = useFormikContext()
  const [fetchVin, vinFetchIsInProgress] = useVinDetails()
  const vinFields = ['vinOrigin', 'vinEngine', 'vinFuelType', 'vinBodyClass']
  const niceValues = values as any
  const niceinitialValues = initialValues as any

  const validateVin = (vin: string) => {
    let error
    if (!vin && niceValues.shipmentType !== 'package') {
      error = 'VIN is required'
    }
    return error
  }
  const handleVinFetch = () => {
    fetchVin((values as any).vin, {
      onSuccess: (vinDetails) => {
        setFieldValue('description', vinDetails.description)
        setFieldValue('vinEngine', vinDetails.vinEngine)
        setFieldValue('vinFuelType', vinDetails.vinFuelType)
        setFieldValue('vinBodyClass', vinDetails.vinBodyClass)
        setFieldValue('vinOrigin', vinDetails.vinOrigin)
      },
    })
  }

  return (
    <>
      <Hero backgroundColor='#7d7d7d'>
        <HeroContentRoot>
          <InputField of='profileImageSlug' field={fieldMaster['profileImageSlug']} />
          <HeroDetailsCard variant='outlined'>
            <HeroFieldsContainer>
              <InputField of='shipmentType' field={fieldMaster['shipmentType']} />
              <InputField of='containerSlug' field={fieldMaster['containerSlug']} disabled={niceinitialValues.containerSlug !== undefined} />
            </HeroFieldsContainer>
            {niceValues.shipmentType === 'package' ? (
              <HeroFieldsContainer>
                <InputField of='parentShipment' field={fieldMaster['parentShipment']} />
                <InputField of='description' field={fieldMaster['description']} />
                <InputField of='weight' field={fieldMaster['weight']} />
              </HeroFieldsContainer>
            ) : (
              <div>
                <>
                  <HeroFieldsContainer>
                    <VinContainer>
                      <InputField of='vin' field={fieldMaster['vin']} validate={validateVin} />
                      <IconButton>
                        {vinFetchIsInProgress ? <LoadingDots variant='dark' /> : <Apps fontSize='large' onClick={handleVinFetch} />}
                      </IconButton>
                    </VinContainer>
                    <InputField of='description' field={fieldMaster['description']} />
                    <InputField of='weight' field={fieldMaster['weight']} />
                  </HeroFieldsContainer>
                  <VinDetailsTypography variant='subtitle2'>
                    {vinFields.map((fieldName, idx) => (
                      <>
                        {fieldMaster[fieldName]?.title}:{' '}
                        {niceValues[fieldName] === undefined || niceValues[fieldName] === null || niceValues[fieldName] === ''
                          ? '__'
                          : niceValues[fieldName]}
                        {idx === vinFields.length - 1 ? '' : ', '}
                      </>
                    ))}
                  </VinDetailsTypography>
                </>
              </div>
            )}
            <HeroFieldsContainer>
              <InputField of='dischargeDate' field={fieldMaster['dischargeDate']} />
              <InputField of='atbPosition' field={fieldMaster['atbPosition']} />
              <InputField of='shipperReleaseCheck' field={fieldMaster['shipperReleaseCheck']} />
              <InputField of='customsType' field={fieldMaster['customsType']} />
              <InputField of='customsReference' field={fieldMaster['customsReference']} />
              <InputField of='finalReleaseCheck' field={fieldMaster['finalReleaseCheck']} />
              <InputField of='runs' field={fieldMaster['runs']} />
              {mode === 'edit' && <InputField of='shipmentKey' disabled={true} field={fieldMaster['shipmentKey']} />}
            </HeroFieldsContainer>
          </HeroDetailsCard>
        </HeroContentRoot>
      </Hero>
      <Hero backgroundColor='transparent'>
        <DetailsCard variant='outlined'>
          <DetailsCardTitle variant='h6'>Contacts</DetailsCardTitle>
          <FieldsContainer>
            <InputField of='linkedContacts' field={fieldMaster['linkedContacts']} />
          </FieldsContainer>
        </DetailsCard>
      </Hero>
    </>
  )
}

ShipmentForm.Skeleton = () => (
  <>
    <Hero backgroundColor='transparent'>
      <HeroContentRoot>
        <Skeleton variant='circle' height='200px' width='200px' />
        <HeroDetailsCard variant='outlined'>
          <HeroFieldsContainer>
            <Skeleton variant='rect' height='30px' />
            <Skeleton variant='rect' height='30px' />
            <Skeleton variant='rect' height='30px' />
          </HeroFieldsContainer>
          <HeroFieldsContainer>
            <Skeleton variant='rect' height='30px' />
            <Skeleton variant='rect' height='30px' />
            <Skeleton variant='rect' height='30px' />
          </HeroFieldsContainer>
        </HeroDetailsCard>
      </HeroContentRoot>
    </Hero>
    <Hero backgroundColor='transparent'>
      <DetailsCard variant='outlined'>
        <Skeleton>
          <DetailsCardTitle variant='h6'>................</DetailsCardTitle>
        </Skeleton>
        <FieldsContainer>
          <Skeleton variant='rect' height='30px' />
          <Skeleton variant='rect' height='30px' />
          <Skeleton variant='rect' height='30px' />
        </FieldsContainer>
      </DetailsCard>
    </Hero>
  </>
)

export default ShipmentForm
