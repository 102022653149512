import { Card, Grow, IconButton, Paper, Typography } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'
import { media } from '../../theme'
import InputField from '../../components/Form/InputField'
import { FieldT } from '../../components/Form/types'
import { FieldArray, useField, useFormikContext } from 'formik'
import Hero from '../../components/Hero'
import { Skeleton } from '@material-ui/lab'
import ResponsiveGrid from '../../components/ResponsiveGrid'
import { Add, Apps, Clear } from '@material-ui/icons'
import useVinDetails from '../../hooks/useVinDetails'
import LoadingDots from '../../components/LoadingDots'
import { TransitionGroup } from 'react-transition-group'
import VoyageField from '../../components/Form/VoyageField'
import { PickupComment } from '../../components/Workflows/ContainerPickSent'

const HeroContentRoot = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 900px;
  margin: auto;
`

const HeroDetailsCard = styled(Card)`
  width: 100%;
  padding: 16px;
  margin-top: 16px;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;

  ${media.small`
    margin-left: 0px;
    margin-top: 8px;
  `}
`

const HeroFieldsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;

  ${media.medium`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${media.small`
    grid-template-columns: auto;
  `}
`

const DetailsCard = styled(Card)`
  width: 100%;
  padding: 16px;
  margin-top: 16px;
`

const DetailsCardTitle = styled(Typography)`
  margin-bottom: 16px;
`

export const DetailsFieldsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;

  ${media.small`
    grid-template-columns: auto;
  `}
`

const ShipmentsCard = styled(Card)`
  width: 100%;
  margin-top: 16px;
  padding: 16px 0px;
`

const ShipmentsCardTitle = styled(Typography)`
  padding: 0px 16px;
  margin-bottom: 16px;
`

export const ShipmentItem = styled.div`
  position: relative;
  padding: 16px;
  padding-top: 32px;
  border-top: 5px solid ${(props) => props.theme.customPalette.border.card};
`

export const RemoveShipmentButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
`

export const NewButtonPaper = styled(Paper).attrs({ variant: 'outlined' })`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #cacaca;
  min-height: 62px;
  cursor: pointer;
  border-style: dashed;
  margin: 16px;

  &:hover {
    filter: brightness(0.95);
  }
`

export const VinContainer = styled.div`
  display: flex;
`

export const VinDetailsTypography = styled(Typography).attrs({ variant: 'subtitle2' })`
  padding-left: 8px;
`

// const Empty = styled.div``

interface Props {
  mode: 'add' | 'edit'
  fieldMaster: {
    [key: string]: FieldT
  }
}

const ContainerForm: React.FC<Props> & { Skeleton: React.FC } = ({ fieldMaster, mode }) => {
  const {
    values: { containerType, shippingLineSlug },
  } = useFormikContext()

  return (
    <>
      <Hero backgroundColor='#7d7d7d'>
        <HeroContentRoot>
          <InputField of='profileImageSlug' field={fieldMaster['profileImageSlug']} />
          <HeroDetailsCard variant='outlined'>
            <HeroFieldsContainer>
              <InputField of='containerNumber' field={fieldMaster['containerNumber']} />
              <InputField of='procedure' field={fieldMaster['procedure']} />
              <InputField of=', ' field={fieldMaster['containerType']} />
            </HeroFieldsContainer>
            <HeroFieldsContainer>
              {['Regular', 'Inspection', 'Transloading', 'Stock'].includes(containerType) && (
                <InputField of='containerSize' field={fieldMaster['containerSize']} />
              )}
              <InputField of='terminalDate' field={fieldMaster['terminalDate']} />
              <InputField of='dischargeDate' field={fieldMaster['dischargeDate']} />
              <InputField of='atb' field={fieldMaster['atb']} />
              <VoyageField field={fieldMaster['voyageSlug']} withShippingLine={!shippingLineSlug} />
              <InputField of='shippingLineSlug' field={fieldMaster['shippingLineSlug']} />
            </HeroFieldsContainer>
          </HeroDetailsCard>
        </HeroContentRoot>
      </Hero>
      <Hero backgroundColor='transparent'>
        <ResponsiveGrid
          type='two-column-layout'
          left={[
            <DetailsCard key='info' variant='outlined'>
              <DetailsCardTitle variant='h6'>Info</DetailsCardTitle>
              <DetailsFieldsContainer>
                <InputField of='bookingNumber' field={fieldMaster['bookingNumber']} />
                <InputField of='billOfLadingNumber' field={fieldMaster['billOfLadingNumber']} />
                <InputField of='pickupReference' field={fieldMaster['pickupReference']} />
                <InputField of='pickupLocationSlug' field={fieldMaster['pickupLocationSlug']} />
                <InputField of='turninReference' field={fieldMaster['turninReference']} />
                <InputField of='turninLocationSlug' field={fieldMaster['turninLocationSlug']} />
                <InputField of='latestPickupDate' field={fieldMaster['latestPickupDate']} />
                <InputField of='pickupDate' field={fieldMaster['pickupDate']} />
                <InputField of='latestTurninDate' field={fieldMaster['latestTurninDate']} />
                <InputField of='thcPaidDate' field={fieldMaster['thcPaidDate']} />
              </DetailsFieldsContainer>
              <PickupComment>
                <InputField of='pickupComment' field={fieldMaster['pickupComment']} />
              </PickupComment>
            </DetailsCard>,
          ]}
          right={[
            <DetailsCard key='contacts' variant='outlined'>
              <DetailsCardTitle variant='h6'>Contact Details</DetailsCardTitle>
              <DetailsFieldsContainer>
                <InputField field={fieldMaster['linkedContacts']} />
              </DetailsFieldsContainer>
            </DetailsCard>,
            <React.Fragment key='shipments'>{mode === 'add' && <ShipmentQuickAddCard fieldMaster={fieldMaster} />}</React.Fragment>,
          ]}
        />
      </Hero>
    </>
  )
}

interface ShipmentQuickAddProps {
  fieldMaster: {
    [key: string]: FieldT
  }
}

const ShipmentQuickAddCard: React.FC<ShipmentQuickAddProps> = ({ fieldMaster }) => {
  const { values }: { values: any } = useFormikContext()
  const shipmentFieldMaster = fieldMaster['shipments'].arrayItems
    ? Object.fromEntries(fieldMaster['shipments'].arrayItems?.map((field) => [field.name, field]))
    : {}
  const vinFields = ['vinOrigin', 'vinEngine', 'vinFuelType', 'vinBodyClass']

  return (
    <ShipmentsCard key='shipments' variant='outlined'>
      <ShipmentsCardTitle variant='h6'>Shipments</ShipmentsCardTitle>
      <FieldArray
        name='shipments'
        render={(arrayHelpers) => (
          <>
            <TransitionGroup component={null}>
              {values.shipments &&
                values.shipments.length > 0 &&
                values.shipments.map((shipment: any, idx: any) => {
                  const prefix = `shipments[${idx}]`
                  const prefixed = (name: string) => `shipments[${idx}].${name}`
                  return (
                    <Grow in={true} key={shipment.key} style={{ transformOrigin: 'center top' }}>
                      <ShipmentItem>
                        <DetailsFieldsContainer>
                          <InputField
                            of={prefixed('shipmentType')}
                            field={{ ...shipmentFieldMaster['shipmentType'], name: prefixed('shipmentType') }}
                          />
                          {shipment.shipmentType !== 'package' ? (
                            <VinField shipmentFieldMaster={shipmentFieldMaster} namePrefix={prefix} />
                          ) : (
                            <div />
                          )}
                          <InputField
                            of={prefixed('description')}
                            field={{ ...shipmentFieldMaster['description'], name: prefixed('description') }}
                          />
                          <InputField of={prefixed('weight')} field={{ ...shipmentFieldMaster['weight'], name: prefixed('weight') }} />
                        </DetailsFieldsContainer>
                        {shipment.shipmentType !== 'package' && (
                          <VinDetailsTypography>
                            {vinFields.map((fieldName, idx) => (
                              <>
                                {shipmentFieldMaster[fieldName]?.title}:{' '}
                                {shipment[fieldName] === undefined || shipment[fieldName] === null || shipment[fieldName] === ''
                                  ? '__'
                                  : shipment[fieldName]}
                                {idx === vinFields.length - 1 ? '' : ', '}
                              </>
                            ))}
                          </VinDetailsTypography>
                        )}
                        <RemoveShipmentButton size='small' onClick={() => arrayHelpers.remove(idx)}>
                          <Clear fontSize='small' />
                        </RemoveShipmentButton>
                      </ShipmentItem>
                    </Grow>
                  )
                })}
            </TransitionGroup>
            <NewButtonPaper
              variant='outlined'
              onClick={() =>
                arrayHelpers.push({
                  key: Math.random().toString().slice(2, 8),
                  description: '',
                })
              }
            >
              <Add />
            </NewButtonPaper>
          </>
        )}
      />
    </ShipmentsCard>
  )
}

interface VinFieldProps {
  shipmentFieldMaster: {
    [key: string]: FieldT
  }
  namePrefix: string
}

const VinField: React.FC<VinFieldProps> = ({ shipmentFieldMaster, namePrefix }) => {
  const { setFieldValue } = useFormikContext()
  const [, meta] = useField(namePrefix)
  const [fetchVin, vinFetchIsInProgress] = useVinDetails()
  const handleVinFetch = () => {
    fetchVin(meta.value.vin, {
      onSuccess: (vinDetails) => {
        setFieldValue(`${namePrefix}.description`, vinDetails.description)
        setFieldValue(`${namePrefix}.vinEngine`, vinDetails.vinEngine)
        setFieldValue(`${namePrefix}.vinFuelType`, vinDetails.vinFuelType)
        setFieldValue(`${namePrefix}.vinBodyClass`, vinDetails.vinBodyClass)
        setFieldValue(`${namePrefix}.vinOrigin`, vinDetails.vinOrigin)
      },
    })
  }
  const validateVin = (vin: string) => {
    let error
    if (!vin && meta.value.shipmentType !== 'package') {
      error = 'VIN is required'
    }
    return error
  }

  return (
    <VinContainer>
      <InputField of='vin' field={{ ...shipmentFieldMaster['vin'], name: `${namePrefix}.vin` }} validate={validateVin} />
      <IconButton>{vinFetchIsInProgress ? <LoadingDots variant='dark' /> : <Apps fontSize='large' onClick={handleVinFetch} />}</IconButton>
    </VinContainer>
  )
}

ContainerForm.Skeleton = () => (
  <>
    <Hero backgroundColor='transparent'>
      <HeroContentRoot>
        <Skeleton variant='rect' height='200px' width='200px' />
        <HeroDetailsCard variant='outlined'>
          <HeroFieldsContainer>
            <Skeleton variant='rect' height='30px' />
            <Skeleton variant='rect' height='30px' />
            <Skeleton variant='rect' height='30px' />
          </HeroFieldsContainer>
          <HeroFieldsContainer>
            <Skeleton variant='rect' height='30px' />
            <Skeleton variant='rect' height='30px' />
            <Skeleton variant='rect' height='30px' />
          </HeroFieldsContainer>
          <HeroFieldsContainer>
            <Skeleton variant='rect' height='30px' />
            <Skeleton variant='rect' height='30px' />
          </HeroFieldsContainer>
        </HeroDetailsCard>
      </HeroContentRoot>
    </Hero>
    <Hero backgroundColor='transparent'>
      <DetailsCard variant='outlined'>
        <Skeleton>
          <DetailsCardTitle variant='h6'>................</DetailsCardTitle>
        </Skeleton>
        <DetailsFieldsContainer>
          <Skeleton variant='rect' height='30px' />
          <Skeleton variant='rect' height='30px' />
          <Skeleton variant='rect' height='30px' />
        </DetailsFieldsContainer>
      </DetailsCard>
    </Hero>
  </>
)

export default ContainerForm
