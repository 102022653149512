import React from 'react'
import styled from 'styled-components'
import UploadCard from '../../components/UploadCard'
import { media } from '../../theme'

const RootContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 8px;

  ${media.medium`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${media.small`
    grid-template-columns: repeat(1, 1fr);
  `}
`

const UploadsTab: React.FC = () => {
  return (
    <RootContainer>
      <UploadCard title='Contacts' onUpload={(fileData) => console.log(fileData)} />
      <UploadCard title='Voyage' onUpload={(fileData) => console.log(fileData)} />
      <UploadCard title='Vessel' onUpload={(fileData) => console.log(fileData)} />
    </RootContainer>
  )
}

export default UploadsTab
