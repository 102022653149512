import { Box, Tab, Tabs } from '@material-ui/core'
import React, { useEffect } from 'react'
import styled from 'styled-components'

interface Props {
  isWhiteBackground?: boolean
  tabs: { label: string; value: string }[]
  value: number
  onChange: (e: React.ChangeEvent<unknown>, newValue: number) => void
}

interface TabProps {
  isWhiteBg: boolean
}

const CustomTabs = styled(Tabs)<TabProps>`
  .MuiTabs-indicator {
    background-color: ${(props) => (props.isWhiteBg ? props.theme.palette.primary.main : 'white')};
  }
`

const CustomTab = styled(Tab)<TabProps>`
  text-transform: none;
  min-width: 72px;
  color: ${(props) => !props.isWhiteBg && 'white'};
`

interface TabPanelProps {
  value: number
  index: number
  children: React.ReactNode
  noPadding?: boolean
}

export const TabPanel = (props: TabPanelProps) => {
  const { children, value, index } = props

  return (
    <div role='tabpanel' hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`}>
      {value === index && <Box style={{ paddingTop: props.noPadding === true ? 'unset' : '16px' }}>{children}</Box>}
    </div>
  )
}

interface NiceTabPanelProps {
  activeTab: string
  forTab: string
  children: React.ReactNode
  noPadding?: boolean
}

export const NiceTabPanel = (props: NiceTabPanelProps) => {
  const { children, activeTab, forTab } = props

  return (
    <div role='tabpanel' hidden={activeTab !== forTab} id={`vertical-tabpanel-${forTab}`} aria-labelledby={`vertical-tab-${forTab}`}>
      {activeTab === forTab && <Box style={{ paddingTop: props.noPadding === true ? 'unset' : '16px' }}>{children}</Box>}
    </div>
  )
}

interface NiceTabsUIProps {
  isWhiteBackground?: boolean
  tabs: { label: string; value: string }[]
  value: string | null
  onChange: (newValue: string) => void
}

export const NiceTabsUI: React.FC<NiceTabsUIProps> = ({ isWhiteBackground = true, tabs, value, onChange }) => {
  const activeIdx = tabs.findIndex((tab) => tab.value === value)
  return (
    <CustomTabs indicatorColor='primary' isWhiteBg={isWhiteBackground} value={activeIdx} variant='scrollable' scrollButtons='off'>
      {tabs.map((tab) => (
        <CustomTab isWhiteBg={isWhiteBackground} key={tab.value} label={tab.label} onClick={() => onChange(tab.value)} />
      ))}
    </CustomTabs>
  )
}

const TabsUI: React.FC<Props> = ({ isWhiteBackground = true, tabs, value, onChange }) => {
  useEffect(() => {
    window.dispatchEvent(new CustomEvent('resize'))
  }, [])
  return (
    <CustomTabs
      indicatorColor='primary'
      isWhiteBg={isWhiteBackground}
      value={value}
      onChange={onChange}
      variant='scrollable'
      scrollButtons='off'
    >
      {tabs.map((tab) => (
        <CustomTab isWhiteBg={isWhiteBackground} key={tab.value} label={tab.label} />
      ))}
    </CustomTabs>
  )
}

export default TabsUI
