import { useState } from 'react'

type UsePagerProps = {
  initialPage?: number
  initialPageSize?: number
  pageSizeOptions?: number[]
}

type UsePagerResult = [number, number, (nextPage: number) => void, (nextPage: number) => void]

function usePager({
  pageSizeOptions = [10, 20, 30, 50],
  initialPage = 1,
  initialPageSize = pageSizeOptions[0],
}: UsePagerProps = {}): UsePagerResult {
  const [page, setPage] = useState<number>(initialPage)
  const [pageSize, setPageSize] = useState<number>(initialPageSize)

  const handlePageChange = (nextPage: number) => {
    setPage(nextPage)
  }

  const handlePageSizeChange = (nextPageSize: number) => {
    setPage(1)
    setPageSize(nextPageSize)
  }

  return [page, pageSize, handlePageChange, handlePageSizeChange]
}

// eslint-disable-next-line
export type { UsePagerResult }

export default usePager
