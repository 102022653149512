import React, { Suspense, useState } from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import { DialogTitle, DialogContent, InputLabel } from '@material-ui/core'
import { Skeleton, Alert } from '@material-ui/lab'
import { Form } from 'formik'
import styled from 'styled-components'
import useNiceMutation from '../mutations/useNiceMutation'
import InputField from '../components/Form/InputField'
import { media } from '../theme'
import NiceFormik from '../components/Form/NiceFormik'
import DialogFormActionBar from './Form/DialogFormActionBar'
import OdysseyDialog from './OdysseyDialog'
import { LiveRequestAddDialogQuery } from '../__generated__/LiveRequestAddDialogQuery.graphql'
import { LiveRequestAddDialogMutation, TicketEntityTypeEnum } from '../__generated__/LiveRequestAddDialogMutation.graphql'
import useLazyLoadQueryWithSubscription from '../hooks/useLazyLoadQueryWithSubscription'
import { SubscribableEntityT } from '../hooks/useEntitySubscription'

interface Props {
  entitySlug: string
  entityType: TicketEntityTypeEnum
  open: boolean
  title: string
  onClose: () => void
  onEdit: () => void
}

const StyledForm = styled(Form)`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`
const FieldsContainer = styled.div`
  margin-bottom: 16px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 16px;

  ${media.medium`
    grid-template-columns: repeat(1, 1fr);
  `}

  ${media.small`
    grid-template-columns: auto;
  `}
`

const LiveRequestAddDialog: React.FC<Props> = (props) => {
  return (
    <OdysseyDialog
      open={props.open}
      maxWidth='sm'
      fullWidth
      onClose={props.onClose}
      scroll='paper'
      style={{ maxWidth: '640px', margin: 'auto' }}
    >
      <Suspense fallback={<FormSkeleton {...props} />}>
        <UpdateForm {...props} />
      </Suspense>
    </OdysseyDialog>
  )
}

const UpdateForm: React.FC<Props> = ({ entitySlug, entityType, title, onClose, onEdit }) => {
  const data = useLazyLoadQueryWithSubscription<LiveRequestAddDialogQuery>(
    query,
    { entitySlug: entitySlug, entityType: entityType },
    { subscriptionOptions: { entityType: entityType as SubscribableEntityT, entitySlug: entitySlug } }
  )
  const fields = data.infoRaiseTicketForm.formSchema.fieldSet
  const fieldMaster = Object.fromEntries(fields.map((field) => [field.name, field]))
  const [mutationError, setMutationError] = useState<null | string>(null)
  const [commit] = useNiceMutation<LiveRequestAddDialogMutation>(mutation)

  return (
    <NiceFormik
      initialValues={{ entityType: entityType, entitySlug: entitySlug, title: '', ticketType: '' }}
      onSubmit={(values, actions) => {
        commit({
          variables: {
            input: values as any,
          },
          onError: () => actions.setSubmitting(false),
          onCompleted: (res, errors) => {
            actions.setSubmitting(false)
            if (errors) {
              setMutationError(errors.map((err) => err.message).join(', '))
            } else {
              onEdit()
              onClose()
            }
          },
        })
      }}
      formSchema={fields}
    >
      {({ submitForm, values }) => (
        <StyledForm>
          <DialogTitle>{title}1</DialogTitle>
          {mutationError && <Alert severity='error'>{mutationError}</Alert>}
          <DialogContent dividers={true}>
            <FieldsContainer>
              <InputLabel>Ticket Type</InputLabel>
              <InputField of={'ticketType'} field={fieldMaster['ticketType']} />
              {values.ticketType === 'misc' && (
                <>
                  <InputLabel>Title</InputLabel>
                  <InputField of={'title'} field={fieldMaster['title']} />
                </>
              )}
              <InputLabel>Request from</InputLabel>
              <InputField of={'contactSlug'} field={fieldMaster['contactSlug']} />
              <InputLabel>Provide a small description</InputLabel>
              <InputField variant='large' of={'description'} field={fieldMaster['description']} />
            </FieldsContainer>
          </DialogContent>
          <DialogFormActionBar
            onCancel={() => onClose()}
            onSubmit={() => {
              submitForm()
            }}
            cancelCta='Cancel'
            saveCta='Save'
          />
        </StyledForm>
      )}
    </NiceFormik>
  )
}

const FormSkeleton: React.FC<Props> = ({ title, onClose }) => (
  <>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <Skeleton variant='rect' height='50px' />
      <Skeleton variant='rect' height='50px' style={{ marginTop: '8px' }} />
    </DialogContent>
    <DialogFormActionBar.Skeleton onCancel={onClose} />
  </>
)

const mutation = graphql`
  mutation LiveRequestAddDialogMutation($input: infoRaiseTicketInput!) {
    infoRaiseTicket(input: $input) {
      clientMutationId
    }
  }
`

const query = graphql`
  query LiveRequestAddDialogQuery($entitySlug: String!, $entityType: TicketEntityTypeEnum!, $ticketType: TicketTypeEnum) {
    infoRaiseTicketForm(entitySlug: $entitySlug, entityType: $entityType, ticketType: $ticketType) {
      formSchema {
        fieldSet {
          name
          type
          required
          title
          description
          format
          enum
          enumTitles
        }
      }
    }
  }
`

export default LiveRequestAddDialog
