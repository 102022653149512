import React from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import styled from 'styled-components'
import useNiceMutation from '../mutations/useNiceMutation'
import { DialogTitle, DialogActions, Typography } from '@material-ui/core'
import ArchiveIcon from '@material-ui/icons/Archive'
import UnarchiveIcon from '@material-ui/icons/Unarchive'
import Button from './Button'
import { ArchiveActionsUnarchiveMutation } from '../__generated__/ArchiveActionsUnarchiveMutation.graphql'
import { ArchiveActionsArchiveMutation, ArchiveEntityTypeEnum } from '../__generated__/ArchiveActionsArchiveMutation.graphql'
import { Button as MuiButton } from '@material-ui/core'
import OdysseyDialog from './OdysseyDialog'
import LoadingDots from './LoadingDots'

interface Props {
  isArchive: boolean
  entityType: ArchiveEntityTypeEnum
  entitySlug: string
}

const StyledButton = styled(MuiButton)`
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  text-transform: unset;
  border-radius: 8px;
  margin: 0 4px;
  text-transform: unset;
  padding-right: 16px;
  padding-left: 16px;
  background-color: inherit;
  ${(props) =>
    props.variant === 'outlined'
      ? `
    border-color: ${props.theme.customPalette.archiveOrange};
    color: ${props.theme.customPalette.archiveOrange};
  `
      : `
    background-color: ${props.theme.customPalette.archiveOrange};
    color: white;
  `}

  &:hover {
    ${(props) =>
      props.variant === 'outlined'
        ? `
      border-color: ${props.theme.customPalette.archiveOrange};
      `
        : props.variant === 'text'
        ? `
      background-color: rgb(0, 128, 246, 0.04);
      border-color: ${props.theme.customPalette.archiveOrange};
      `
        : `
      background-color: ${props.theme.customPalette.archiveOrange};
      filter: brightness(0.95);
    `}
  }
`

const unarchiveConfirmationTitle: { [key in ArchiveEntityTypeEnum]: string } = {
  containers: 'Remove this container from archive?',
  shipments: 'Remove this shipment from archive?',
  '%future added value': '',
}

const unarchiveConfirmationSubTitle: { [key in ArchiveEntityTypeEnum]: string } = {
  containers: '',
  shipments: 'This action will also unarchive parent container',
  '%future added value': '',
}

const ArchiveActions: React.FC<Props> = ({ isArchive, entityType, entitySlug }) => {
  const [commitArchive, archiveIsInProgress] = useNiceMutation<ArchiveActionsArchiveMutation>(archiveMutation)
  const [commitUnarchive, unarchiveIsInProgress] = useNiceMutation<ArchiveActionsUnarchiveMutation>(unarchiveMutation)
  const handleArchive = () => {
    commitArchive({
      variables: {
        input: {
          entitySlug: entitySlug,
          entityType: entityType,
        },
      },
      onCompleted: () => {
        // setOpen(false)
      },
    })
  }

  const handleUnarchive = () => {
    commitUnarchive({
      variables: {
        input: {
          entitySlug: entitySlug,
          entityType: entityType,
        },
      },
      onCompleted: () => {
        setUnarchiveConfirmationOpen(false)
      },
    })
  }

  const [unarchiveConfirmationOpen, setUnarchiveConfirmationOpen] = React.useState(false)

  const handleUnarchiveConfirmationClose = () => {
    setUnarchiveConfirmationOpen(false)
  }

  return (
    <>
      {isArchive && (
        <StyledButton variant='contained' disableElevation startIcon={<UnarchiveIcon />} onClick={() => setUnarchiveConfirmationOpen(true)}>
          Unarchive
        </StyledButton>
      )}
      {!isArchive && (
        <StyledButton variant='outlined' startIcon={<ArchiveIcon />} onClick={handleArchive} disabled={archiveIsInProgress}>
          {archiveIsInProgress ? <LoadingDots variant='dark' /> : 'Archive'}
        </StyledButton>
      )}

      <OdysseyDialog open={unarchiveConfirmationOpen} onClose={handleUnarchiveConfirmationClose}>
        <DialogTitle>
          {unarchiveConfirmationTitle[entityType]}
          <Typography variant='body1' color='textSecondary'>
            {unarchiveConfirmationSubTitle[entityType]}
          </Typography>
        </DialogTitle>
        <DialogActions>
          <Button variant='text' onClick={handleUnarchiveConfirmationClose}>
            Cancel
          </Button>
          <Button onClick={handleUnarchive} variant='contained' disabled={unarchiveIsInProgress}>
            {unarchiveIsInProgress ? <LoadingDots /> : 'Yes'}
          </Button>
        </DialogActions>
      </OdysseyDialog>
    </>
  )
}

const archiveMutation = graphql`
  mutation ArchiveActionsArchiveMutation($input: infoArchiveItemInput!) {
    infoArchiveItem(input: $input) {
      clientMutationId
    }
  }
`

const unarchiveMutation = graphql`
  mutation ArchiveActionsUnarchiveMutation($input: infoUnarchiveItemInput!) {
    infoUnarchiveItem(input: $input) {
      clientMutationId
    }
  }
`

export default ArchiveActions
