/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Timeline_queryData = {
    readonly feedItemList: {
        readonly feedItems: ReadonlyArray<{
            readonly slug: string;
            readonly dayLabel: string;
            readonly " $fragmentRefs": FragmentRefs<"FeedItem_data">;
        }> | null;
        readonly totalCount: number;
    };
    readonly " $refType": "Timeline_queryData";
};
export type Timeline_queryData$data = Timeline_queryData;
export type Timeline_queryData$key = {
    readonly " $data"?: Timeline_queryData$data;
    readonly " $fragmentRefs": FragmentRefs<"Timeline_queryData">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "after"
    },
    {
      "kind": "RootArgument",
      "name": "dateFilter"
    },
    {
      "kind": "RootArgument",
      "name": "first"
    },
    {
      "kind": "RootArgument",
      "name": "group"
    },
    {
      "kind": "RootArgument",
      "name": "handle"
    },
    {
      "kind": "RootArgument",
      "name": "parentAbsoluteSlug"
    },
    {
      "kind": "RootArgument",
      "name": "slug"
    },
    {
      "kind": "RootArgument",
      "name": "type"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./TimelinePaginationQuery.graphql.ts')
    }
  },
  "name": "Timeline_queryData",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "after",
          "variableName": "after"
        },
        {
          "kind": "Variable",
          "name": "dateFilter",
          "variableName": "dateFilter"
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "first"
        },
        {
          "kind": "Variable",
          "name": "group",
          "variableName": "group"
        },
        {
          "kind": "Variable",
          "name": "handle",
          "variableName": "handle"
        },
        {
          "kind": "Variable",
          "name": "parentAbsoluteSlug",
          "variableName": "parentAbsoluteSlug"
        },
        {
          "kind": "Variable",
          "name": "slug",
          "variableName": "slug"
        },
        {
          "kind": "Variable",
          "name": "type",
          "variableName": "type"
        }
      ],
      "concreteType": "FeedItemList",
      "kind": "LinkedField",
      "name": "feedItemList",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "FeedItem",
          "kind": "LinkedField",
          "name": "feedItems",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "slug",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "dayLabel",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "FeedItem_data"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ApplicationQuery",
  "abstractKey": null
};
(node as any).hash = '192fe870bd341a781c20544d1b47ccee';
export default node;
