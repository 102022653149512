/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type LiveShareShipmentShow_shipmentShow = {
    readonly shipment: {
        readonly slug: string;
        readonly " $fragmentRefs": FragmentRefs<"LiveShareShipmentShowHeroCardQuery_shipment">;
    };
    readonly item: {
        readonly slug: string;
        readonly " $fragmentRefs": FragmentRefs<"LiveShareShipmentShowHeroCardQuery_item">;
    };
    readonly " $fragmentRefs": FragmentRefs<"LiveShareShipmentShowHomeContentQuery_shipmentShow">;
    readonly " $refType": "LiveShareShipmentShow_shipmentShow";
};
export type LiveShareShipmentShow_shipmentShow$data = LiveShareShipmentShow_shipmentShow;
export type LiveShareShipmentShow_shipmentShow$key = {
    readonly " $data"?: LiveShareShipmentShow_shipmentShow$data;
    readonly " $fragmentRefs": FragmentRefs<"LiveShareShipmentShow_shipmentShow">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LiveShareShipmentShow_shipmentShow",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "LiveShareShipment",
      "kind": "LinkedField",
      "name": "shipment",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "LiveShareShipmentShowHeroCardQuery_shipment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ListItem",
      "kind": "LinkedField",
      "name": "item",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "LiveShareShipmentShowHeroCardQuery_item"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LiveShareShipmentShowHomeContentQuery_shipmentShow"
    }
  ],
  "type": "LiveShareShowShipment",
  "abstractKey": null
};
})();
(node as any).hash = '3415344fe556493823e0afea9d90e377';
export default node;
