import React, { Suspense } from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import { FoldersTab_folders$key } from '../../__generated__/FoldersTab_folders.graphql'
import { useFragment } from 'react-relay/hooks'
import FoldersCard from './FoldersCard'
import { AttachmentEntityTypeEnum } from '../../__generated__/FoldersCardNewMutation.graphql'
import ResponsiveGrid from '../ResponsiveGrid'
import Timeline from '../Timeline/Timeline'
import { isSmall } from '../../theme'

interface Props {
  entityType: AttachmentEntityTypeEnum
  entitySlug: string
  folders: FoldersTab_folders$key
}

const FoldersTab: React.FC<Props> & { Skeleton: React.FC } = ({ folders: data, entityType, entitySlug }) => {
  const folders = useFragment(fragment, data)
  return (
    <ResponsiveGrid
      type='show-layout'
      highlight={[
        <FoldersCard
          key='documents'
          folders={folders}
          title='Documents'
          folderType='documents'
          entityType={entityType}
          entitySlug={entitySlug}
          viewMode='tab'
        />,
        <FoldersCard
          key='photos'
          folders={folders}
          title='Photos'
          folderType='photos'
          entityType={entityType}
          entitySlug={entitySlug}
          viewMode='tab'
        />,
      ]}
      right={
        isSmall()
          ? []
          : [
              <Suspense key='timeline' fallback={<Timeline.Skeleton />}>
                <Timeline type={entityType} slug={entitySlug} group='attachments' />
              </Suspense>,
            ]
      }
    />
  )
}

const fragment = graphql`
  fragment FoldersTab_folders on FolderConnection {
    ...FoldersCard_folders
  }
`

FoldersTab.Skeleton = () => <>Loading...</>

export default FoldersTab
